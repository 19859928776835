import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TooltipV2 from '../../../components/tooltipV2/tooltipV2';
import { RouterConfig } from '../../../config';
import { Resource } from '../../../resource';
import { DirectionTool, StringTool, UrlTool } from '../../../tool';
import './mobileDirectionButtonV2.scss';

const ICON_DIRECTION =
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path fill='none' d="M0,0H18V18H0Z" />
    <path fill='#FFFFFF' d="M16.721,8.937,10,2.216a.744.744,0,0,0-1.053,0L2.226,8.937a.744.744,0,0,0,0,1.053l6.721,6.721a.744.744,0,0,0,1.053,0L16.721,9.99a.744.744,0,0,0,0-1.053Zm-5.758,2.4V9.467H7.976v2.24H6.483V8.72a.749.749,0,0,1,.747-.747h3.734V6.107L13.577,8.72Z" transform="translate(-0.47 -0.467)" />
  </svg>

const SIZE = 36
function MobileDirectionButtonV2({ latlng, size = SIZE, isEllipse }) {
  const history = useHistory()

  const handleDirection = (e) => {
    e.stopPropagation();
    let data = DirectionTool.encodeDirection({ positions: [null, latlng] })
    let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
    if (window.top == window.self) {
      history.push({
        pathname: RouterConfig.direction,
        search: search
      })
    }
    else {
      let url = window.location.origin + pathName + search
      window.top.open(url, "_blank")
    }
  }

  return (
    <TooltipV2 description={Resource.common.direction} anchor='top'>
      <div className={StringTool.mergeClassName('mobileDirectionButtonCpn', isEllipse ? 'isEllipse' : '')}>
        <div
          className='circleBtn'
          onClick={handleDirection}
          style={{
            borderRadius: size / 2
          }}

        >
          <div
            className='icon'
            style={{
              width: size,
              height: size,
            }}>
            {ICON_DIRECTION}
          </div>

          <div className='caption2'>
            <div>
              {Resource.common.direction}
            </div>

          </div>
        </div>
        <div className='caption'>
          <div>
            {Resource.common.direction}
          </div>
        </div>

      </div>
    </TooltipV2>
  )
}

MobileDirectionButtonV2.propTypes = {
  isEllipse: PropTypes.bool,
  latlng: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
};

export default MobileDirectionButtonV2;
