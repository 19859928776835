import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { PlaceRelatedState, PlaceTypeState } from '../../appState';
import { MapTypeAppState } from '../../appState/mapTypeAppState';
import { LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { CodeEnum, MapTypeEnum, TypeRequestUpdateEnum } from '../../enum';
import { Resource } from '../../resource';
import { AddressTool, ApiTool, UrlTool, UserTool } from '../../tool';
import { useQuery } from '../../useHooks';
import DescriptionDisplay from '../common/descriptionDisplay/descriptionDisplay';
import DirectionButton from '../common/directionButton/directionButton';
import Error from '../common/error';
import RequestUpdateBtn from '../common/requestUpdateBtn/requestUpdateBtn';
import ShareButton from '../common/shareButton/shareButton';
import SwipeableViewsImages from '../common/swipeableViewsImages/swipeableViewsImages';
import ListPlaceRelated from '../listPlaceRelated/listPlaceRelated';
import { AppTool } from './../../tool/appTool';
import PlaceRelatedV2 from './../placeRelatedV2/placeRelatedV2';
import './object3dDetail.scss';

function Object3dDetail(props) {
  const { id, type } = useParams()

  const { text } = useQuery()
  const history = useHistory()
  const location = useLocation()

  const [object3D, setObject3D] = useState(null)
  const [loading, setLoading] = useState(true)

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)
  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const placeRelatedState = useRecoilValue(PlaceRelatedState)

  useEffect(() => {
    setMapTypeAppState(MapTypeEnum.map3d)
    return () => {
      AppData.map.setSelectedBuildings([])
    }
  }, [])

  useEffect(() => {
    AppTool.setSearchBarText(object3D?.name || "")
  }, [object3D?.name])

  useEffect(() => {
    var sourcePlaces
    AppTool.setSearchBarLoading(true)
    setLoading(true)
    sourcePlaces = ApiTool.get(UrlConfig.object.detail.replace("{id}", id), (res) => {
      setLoading(false)
      AppTool.setSearchBarLoading(false)
      if (res?.code == CodeEnum.ok) {
        AppData.map.setSelectedBuildings([res?.result.id])
        if (!location.state?.disableMoveMap) {
          moveCamera(res?.result)
        }
        setObject3D(res?.result)
      }
      else {
        setObject3D(null)
      }
    })
    return () => {
      sourcePlaces?.cancel()
    }
  }, [id])

  const moveCamera = (object3D) => {
    let camera = AppData.map.getCamera()
    if (object3D?.camera) {
      camera.setBearing(object3D?.camera?.bearing)
      camera.setTilt(object3D?.camera?.tilt)
      camera.setZoom(object3D?.camera?.zoom)
    }
    camera.setTarget(object3D?.location)
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleBackToResult = () => {
    history.push({
      pathname: RouterConfig.place.search.replace("/:type?", type ? "/" + type : ""),
      search: UrlTool.createSearch({ text: text })
    })
  }

  let addressFull = AddressTool.getFullAddress(object3D?.addressComponents || [])
  const user = UserTool.getUser()

  return (
    <div className='object3dDetailCpn'>
      <div className='boxContainer' key={object3D?.id || v4()}>
        <div className='boxInfo'>
          {
            !loading &&
            (
              !object3D ?
                <div className='boxDataNotFound'>
                  <Error message={Resource.message.dataNotFound} subMessage={Resource.message.dataDoesNotExist} />
                </div>
                :
                <>
                  <div className='boxAvatarPlace'>
                    <SwipeableViewsImages disableSwitch place={object3D} paddingImages={0} sizeButton={30} />
                  </div>

                  <div className='contentDetail'>
                    <div className='mainInfo'>
                      <div className='textName' title={object3D?.name}>{object3D?.name}</div>
                      {
                        object3D?.types?.length > 0 &&
                        <div className='textDescription'>{AllPlaceTypeState[object3D?.types[0]]?.name || ''}</div>
                      }
                    </div>

                    <div className='lineBorder'></div>

                    <div className='placeExtend'>
                      <div className='itemOption'>
                        <DirectionButton latlng={object3D?.location} />
                      </div>
                      <div className='itemOption'>
                        <ShareButton url={location.href} />
                      </div>
                    </div>

                    <div className='lineBorder'></div>

                    <div className='detailInfo'>
                      {
                        object3D?.addressComponents?.length > 0 &&
                        <div className='listItemInfo'>
                          <div className='listItemIcon'>
                            <img src={LinkIconConfig.placeDetail.location} alt='icon location' width={24} height={24} />
                          </div>
                          <div className='listItemText'>
                            {addressFull}
                          </div>
                        </div>
                      }

                      {
                        user && object3D?.id &&
                        <div className='boxRequestUpdate'>
                          <RequestUpdateBtn id={object3D?.id} type={TypeRequestUpdateEnum.building} placeName={object3D?.name} isButton />
                        </div>
                      }
                    </div>
                    {
                      object3D?.description &&
                      <>
                        <div className='lineBorder'></div>
                        <div className='boxDescription'>
                          <div className='title'>
                            {Resource.common.introduce.toUpperCase()}
                          </div>
                          <DescriptionDisplay value={object3D?.description} />
                        </div>
                      </>
                    }

                    {
                      object3D?.placeDetails.length > 0 &&
                      <>
                        <div className='lineBorder'></div>
                        <div className='boxItemInfo boxDetailPlaceRelated'>
                          <PlaceRelatedV2 data={object3D?.placeDetails} object={object3D} />
                        </div>
                      </>
                    }
                  </div>
                </>
            )
          }
        </div>
      </div>
      {
        placeRelatedState.show &&
        <ListPlaceRelated />
      }
    </div>
  )
}

Object3dDetail.propTypes = {
  //
};

export default Object3dDetail;
