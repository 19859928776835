import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import { useRecoilState } from 'recoil';
import { PreviewDataState } from '../../../appState';
import { Resource } from '../../../resource';
import "./showPreviewData.scss";
import dialogPolyfill from 'dialog-polyfill'

const CLOSE_ICON =
  <svg id="Component_776_4" data-name="Component 776 – 4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Path_8100" data-name="Path 8100" d="M0,0H24V24H0Z" fill="none" />
    <path id="Path_8101" data-name="Path 8101" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" fill="#fff" />
  </svg>

const REGEX_YOUTUBE_URL = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/

function ShowPreviewData() {

  const [previewDataState, setPreviewDataState] = useRecoilState(PreviewDataState)
  const [valueState, setValueState] = useState(null)

  const dialogHtml = useRef()

  useEffect(() => {
    if (previewDataState?.data) {
      setValueState(previewDataState?.data)
    }

    if (previewDataState?.show) {
      dialogHtml.current?.showModal()
    }
  }, [previewDataState])


  const onCloseDialog = () => {
    dialogHtml.current?.open && dialogHtml.current?.close?.()
    setPreviewDataState({
      show: false,
      data: null
    })
  }

  const handleRef = (r)=>{
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    previewDataState?.show &&
    ReactDOM.createPortal(
      <dialog className='showPreviewDataCpn' ref={handleRef}>

        <div className='closeBtn' onClick={onCloseDialog} title={Resource.common.close}>
          {CLOSE_ICON}
        </div>

        <div className='containerPreview'>
          <div className='contentPreview'>
            {
              REGEX_YOUTUBE_URL?.test(valueState?.content) ?
                <iframe src={valueState?.content?.replace("watch?v=", "embed/")} allowFullScreen ></iframe>
                :
                <iframe src={valueState?.content} allowFullScreen ></iframe>
            }
          </div>
        </div>

      </dialog>
      , document.body)
  )
};

export default ShowPreviewData;
