import PropTypes from 'prop-types';
import React, { useEffect, useId, useState } from 'react';
import { useRecoilState } from 'recoil';
import { OpeningDialogIdStates } from '../../appState';
import { useRef } from 'react';
import "./dialog.scss"
import { DialogContext } from '../../context';
import { StringTool } from '../../tool';
import * as ReactDOM from "react-dom";
import dialogPolyfill from 'dialog-polyfill'


const Dialog = ({ open, onClose, onBack, keepAnother = false, disableCloseOnClickBackdrop = false, children, fullScreen, ...props }) => {

  const id = useId()
  const [openingDialogIds, setOpeningDialogIds] = useRecoilState(OpeningDialogIdStates)
  const dialogHtml = useRef([])
  const [hide, setHide] = useState()
  const [valueContext, setValueContext] = useState({})

  const closeFlagRef = useRef(false)

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName("dialogCpn", props.className)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName("dialogCpn", props.className))
  }, [props.className])

  useEffect(() => {
    if (!keepAnother) {
      if (open) {
        setOpeningDialogIds(prev => {
          let newValue = JSON.parse(JSON.stringify(prev || []))
          newValue.push(id)
          return newValue
        })
      }
      else {
        setOpeningDialogIds(prev => {
          let newValue = JSON.parse(JSON.stringify(prev || []))
          newValue = newValue.filter((t) => t != id)
          return newValue
        })
      }
    }
  }, [open, keepAnother])

  useEffect(() => {
    if (open) {
      dialogHtml.current?.showModal()
    }
    else {
      closeFlagRef.current = true
      dialogHtml.current?.open && dialogHtml.current?.close?.()
    }
  }, [open])
  useEffect(() => {
    if (!keepAnother) {
      if (openingDialogIds?.[openingDialogIds?.length - 1] == id) {
        setHide(false)
      }
      else {
        setHide(true)
      }
    }

  }, [openingDialogIds, keepAnother])
  useEffect(() => {
    return () => {
      setOpeningDialogIds(prev => {
        let newValue = JSON.parse(JSON.stringify(prev || []))
        newValue = newValue.filter((t) => t != id)
        return newValue
      })
    }
  }, [])

  useEffect(() => {
    setValueContext(prev => ({
      ...prev,
      onClose,
      onBack
    }))
  }, [onClose, onBack])

  const onClickDialog = (event) => {
    event?.stopPropagation()
    var rect = dialogHtml.current?.getBoundingClientRect();
    var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog && !disableCloseOnClickBackdrop) {
      onClose && onClose()
    }
  }

  useEffect(() => {
    const handleClose = (e) => {
      if (closeFlagRef.current) {
        closeFlagRef.current = false
      }
      else {
        dialogHtml?.current?.showModal?.()
      }
    }
    dialogHtml.current?.addEventListener('close', handleClose)
    return () => {
      dialogHtml.current?.removeEventListener('close', handleClose)
    }
  }, [])

  const onCancel = (e) => {
    e.preventDefault();
  }

  const handleRef = (r) => {
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    ReactDOM.createPortal(
      <dialog
        {...props}
        onClick={onClickDialog}
        className={StringTool.mergeClassName(className, hide && "hide", fullScreen && 'fullScreenDialog')}
        ref={handleRef}
        onCancel={onCancel}
      >
        <DialogContext.Provider value={valueContext}>
          {children}
        </DialogContext.Provider>
      </dialog>,
      document.body
    )
  )
};

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  keepAnother: PropTypes.bool,
  className: PropTypes.string,
  onBack: PropTypes.func,
  disableCloseOnClickBackdrop: PropTypes.bool,
  fullScreen: PropTypes.bool
};

export default Dialog;
