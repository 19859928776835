import React, { useEffect, useState } from 'react';
import DatePickerV2 from './../../../components/datePickerV2/datePickerV2';
import TimePickerV2 from './../../../components/timePickerV2/timePickerV2';
import { DirectionDepartEnum } from './../../../enum/directionDepartEnum';
import { Resource } from './../../../resource';
import './mobileDepartAtView.scss';
import PropTypes from 'prop-types';

const ARROW_DOWN =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-150 -464)">
      <path d="M14,7,9,12l5,5Z" transform="translate(150 487) rotate(-90)" fill="#505050" />
    </g>
  </svg>

const convertDateWithObjTime = (date) => {
  if (date) {
    let newDate = new Date(date)
    let hourValue = newDate?.getHours()
    let minuteValue = newDate?.getMinutes()
    return {
      hour: hourValue,
      minute: minuteValue
    }
  }
}

function MobileDepartAtView(props) {
  const { value, onClick, onClickOption, disableDepartAt } = props

  const [dateState, setDateState] = useState(null)
  const [timeState, setTimeState] = useState(null)
  const [typeDepart, setTypeDepart] = useState(DirectionDepartEnum.now)
  const [disDepartAt, setDisDepartAt] = useState(false)

  useEffect(() => {
    setDisDepartAt(disableDepartAt)
  }, [disableDepartAt])

  useEffect(() => {
    if (value) {
      setTypeDepart(DirectionDepartEnum.at)
    }
    setDateState(value)
    setTimeState(convertDateWithObjTime(value))
  }, [value])

  const onClickContainer = () => {
    !disDepartAt && onClick?.()
  }

  const onClickOptionAction = (e) => {
    onClickOption?.()
    e?.stopPropagation()
  }

  const OPTION_DEPART_LABEL = {
    [DirectionDepartEnum.now]: Resource.common.departNow,
    [DirectionDepartEnum.at]: Resource.common.departAt,
  }

  return (
    <div className='mobileDepartAtViewCpn' onClick={onClickContainer}>
      <div className='headerAction'>
        {
          !disDepartAt && <div className='departSelect'>
            <div className='text'>
              {OPTION_DEPART_LABEL[typeDepart]}
            </div>
            <div className='icon'>
              {ARROW_DOWN}
            </div>
          </div>
        }
        <button className='optionBtn' onClick={onClickOptionAction}>
          {Resource.common.option}
        </button>
      </div>
      {
        typeDepart == DirectionDepartEnum.at &&
        <div className='contentDateTime'>
          <div className='time'>
            <TimePickerV2 value={timeState} readOnly />
          </div>
          <div className='date'>
            <DatePickerV2 value={dateState} readOnly />
          </div>
        </div>
      }
    </div>
  );
};

MobileDepartAtView.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  onClickOption: PropTypes.func,
  disDepartAt: PropTypes.bool
}

export default MobileDepartAtView;
