import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Chip } from 'ui';
import { UserState } from '../../appState';
import { LinkIconConfig, SvgIconConfig, UrlConfig } from '../../config';
import { CodeEnum, PlaceTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool } from '../../tool';
import SearchBarV3 from '../searchBarV3/searchBarV3';
import TooltipV2 from '../tooltipV2/tooltipV2';
import './listSearchRoute.scss';

const OPTION_TYPE_DEFAULT = [
  {
    id: 'OPTION_TYPE_DEFAULT_1',
    isDefaultType: true,
    name: Resource.common.restaurantAlongRoute,
    icon: SvgIconConfig.search.restaurant,
    text: Resource.common.restaurant,
    type: PlaceTypeEnum.restaurant
  },
  {
    id: 'OPTION_TYPE_DEFAULT_2',
    isDefaultType: true,
    name: Resource.common.hotelAlongRoute,
    icon: SvgIconConfig.search.hotel,
    text: Resource.common.hotel,
    type: PlaceTypeEnum.hotel
  },
  {
    id: 'OPTION_TYPE_DEFAULT_3',
    isDefaultType: true,
    name: Resource.common.tourismAlongRoute,
    icon: SvgIconConfig.search.tourism,
    text: Resource.common.tourism,
    type: PlaceTypeEnum.touristAttract
  }
]
const ListSearchRoute = (props) => {
  const placeTypes = [
    {
      title: Resource.aroundRoute.stay,
      children: [
        {
          name: Resource.suggestPlaceChip.hotel,
          icon: LinkIconConfig.suggestPlaceChip.unActive.hotel,
          type: PlaceTypeEnum.hotel
        },
        {
          name: Resource.suggestPlaceChip.homestay,
          icon: LinkIconConfig.suggestPlaceChip.unActive.hotel,
          type: PlaceTypeEnum.homestay
        }
      ]
    },
    {
      title: Resource.aroundRoute.foodAndDrink,
      children: [
        {
          name: Resource.suggestPlaceChip.restaurant,
          icon: LinkIconConfig.suggestPlaceChip.unActive.restaurant,
          type: PlaceTypeEnum.restaurant
        },
        {
          name: Resource.suggestPlaceChip.coffee,
          icon: LinkIconConfig.suggestPlaceChip.unActive.coffee,
          type: PlaceTypeEnum.coffee
        },
        {
          name: Resource.suggestPlaceChip.grocery,
          icon: LinkIconConfig.suggestPlaceChip.unActive.grocery,
          type: PlaceTypeEnum.grocery
        }
      ]
    },
    {
      title: Resource.aroundRoute.service,
      children: [
        {
          name: Resource.suggestPlaceChip.gasStation,
          icon: LinkIconConfig.suggestPlaceChip.unActive.gasStation,
          type: PlaceTypeEnum.gas
        },
        {
          name: Resource.suggestPlaceChip.charging,
          icon: LinkIconConfig.suggestPlaceChip.unActive.charging,
          type: PlaceTypeEnum.charging
        },
        {
          name: Resource.suggestPlaceChip.bank,
          icon: LinkIconConfig.suggestPlaceChip.unActive.bank,
          type: PlaceTypeEnum.bank
        }
      ]
    },
    {
      title: Resource.aroundRoute.visit,
      children: [
        {
          name: Resource.suggestPlaceChip.museum,
          icon: LinkIconConfig.suggestPlaceChip.unActive.museum,
          type: PlaceTypeEnum.museum
        },
        {
          name: Resource.suggestPlaceChip.park,
          icon: LinkIconConfig.suggestPlaceChip.unActive.park,
          type: PlaceTypeEnum.park
        },
        {
          name: Resource.suggestPlaceChip.thingsToDo,
          icon: LinkIconConfig.suggestPlaceChip.unActive.museum,
          type: PlaceTypeEnum.touristAttract
        }
      ]
    }
  ]

  const { onClick, onBack, onChange} = props
  const listHistoryRef = useRef()
  const listPoiRef = useRef()

  const user = useRecoilValue(UserState)

  const [searchState, setSearchState] = useState(null)
  const [optionPoiState, setOptionPoiState] = useState([])
  const [optionHistoryState, setOptionHistoryState] = useState([])

  useEffect(() => {
    setSearchState(searchState)
    searchAutoSuggest(searchState)
    return () => {
      listPoiRef.current?.cancel()
    }
  }, [searchState])
  
  useEffect(()=>{
      getHistories()
      return () => {
        listHistoryRef.current?.cancel()
      } 
  },[])

  const searchAutoSuggest = (keyword) => {
    let body = {
      text: keyword,
    }
    listPoiRef.current = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let data = res?.result?.splice(0, 5)
        let dataCus = data?.map(poi => {
          return {
            ...poi,
            icon: SvgIconConfig.search.poi
          }
        })
        setOptionPoiState(dataCus)
      }
    })
  }

  const getHistories = () => {
    if (user?.id) {
    let body = {
      count: 5,
      isPlace: true
    }
    listHistoryRef.current = ApiTool.queryGetFromJson(UrlConfig.searchHistory.get, body, (res) => {
      let data = res?.result?.map(poiHis => {
        return {
          ...poiHis?.place,
          id: poiHis?.id,
          icon: SvgIconConfig.search.history
        }
      })
      let dataOption = OPTION_TYPE_DEFAULT.concat(data)
      setOptionHistoryState(dataOption)
    }, true)
  }
  else {
    setOptionHistoryState(OPTION_TYPE_DEFAULT)
  }
  }

  const onChangeInput = (value) => {
    setSearchState(value)
  }

  const onChangeOption = (option) => {
    setSearchState(option)
    onChange && onChange(option)
  }

  const handleChooseSuggest = (placeType) => () => {
    onClick && onClick(placeType)
  }
  const handleBack = () => {
    onBack && onBack()
  }
  const onEnterSearch = (value) => {
    onChange && onChange(value)
  }
  const onSearchIcon = () => {
    onChange && onChange(searchState)
  }
  return (
    <div className='listSearchRouteMaster'>
      <div className='searchBarRouteCpn'>
        <SearchBarV3
          clearable
          isFreeSolo
          isServer
          value={searchState}
          placeholder={Resource.suggestPlaceChip.search}
          maxLengthOfScroll={!searchState ? 8 : 5}
          options={!searchState ? optionHistoryState : optionPoiState}
          prefix={
            <TooltipV2 title={Resource.common.back} anchor="bottom" >
              <img src={LinkIconConfig.common.back} alt="icon-back" onClick={handleBack} />
            </TooltipV2>
          }
          iconInside={
            <TooltipV2 title={Resource.common.search} anchor="bottom" >
              <img src={LinkIconConfig.common.searchGray} alt="icon-search" onClick={onSearchIcon} />
            </TooltipV2>}
          suffix={
            <TooltipV2 title={Resource.common.close} anchor="right" >
              <img src={LinkIconConfig.searchBar.close} width={24} height={24} alt="icon-close" onClick={handleBack} />
            </TooltipV2>
          }
          onChange={onChangeOption}
          onInputChange={onChangeInput}
          onEnter={onEnterSearch}
        />
      </div>
      <div className='listSearchRouteCpn'>
        <div className='title'>{Resource.aroundRoute.title}</div>
        <div className='information'>{Resource.aroundRoute.information}</div>
        {
          placeTypes?.map((item, index) => {
            return (
              <div className='itemContainer' key={item?.title}>
                <div className='itemTitle'>{item?.title}</div>
                <div className='itemContent'>
                  {
                    item?.children?.map((placeType, number) => {
                      return (
                        <Chip
                          key={placeType?.type + number}
                          icon={<img src={placeType?.icon} alt={`${'icon-' + placeType?.type}`} />}
                          onClick={handleChooseSuggest(placeType)}
                        >
                          {placeType?.name}
                        </Chip>
                      )
                    })
                  }
                </div>
                <div></div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

ListSearchRoute.propTypes = {
  onClick: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func
};

export default ListSearchRoute;
