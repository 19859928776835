import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React,{ useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AppConfig, CommonConfig } from '../../../config';
import { UrlConfig } from '../../../config/urlConfig';
import { AppData } from '../../../data';
import { CodeEnum, SessionEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool } from '../../../tool';
import { ShowLayerSettingState, ShowSettingState } from './../../../appState/settingState';

const useStyles = makeStyles({
  dialog: {
    width: '559px'
  },
  display: {
    justifyContent: 'center',
    marginBottom: '16px',
  },
  btnAction: {
    width: `${CommonConfig.widthBtnAction}px`,
    height: `${CommonConfig.heightBtnAction}px`
  },
  boxList: {
    '& .MuiFormLabel-root': {
      background: '#FFFFFF'
    },
    '& .MuiInputBase-root': {
      maxHeight: '278px',
      overflow: 'auto',
      border: '1px solid #BBBBBB',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)'
      }
    },
    '& fieldset': {
      display: 'none'
    },
  }
})

function LayerSetting() {
  const classes = useStyles()
  const theme = useTheme()

  const [check, setChecked] = useState(() => {
    let layerStatus = (sessionStorage.getItem(SessionEnum.layer) == 'true')
    return layerStatus
  })

  const [listOption, setListOption] = useState(null)
  const [value, setValue] = useState([])
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const setShowSettingState= useSetRecoilState(ShowSettingState)

  const [showLayerSettingState, setShowLayerSettingState]= useRecoilState(ShowLayerSettingState)

  useEffect(() => {
    var sourcePlaceType
    sourcePlaceType = ApiTool.get(UrlConfig.placeType.getListSearch, (res) => {
      if (res?.code == CodeEnum.ok) {
        let options = res?.result
        let valueSelected = []
        try {
          valueSelected = JSON.parse(sessionStorage.getItem(SessionEnum.selectedLayers))
        } catch (error) {

        }
        let values = options.filter(t => {
          let checkOption = valueSelected?.find(id => { return id == t.id })
          return checkOption
        })
        AppData.map.setFilterPOIs(valueSelected || [])
        AppData.map.setFilterBuildings(valueSelected || [])
        setValue(values || [])
        setListOption(options || [])
      }
    })
    return () => {
      sourcePlaceType?.cancel()
    }
  }, [])

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setValue(listOption)
      setChecked(true)
    }
    else {
      setValue([])
      setChecked(false)
    }
  };

  const handleClose = () => {
    let valueSelected = []
    let check2 = false
    try {
      valueSelected = JSON.parse(sessionStorage.getItem(SessionEnum.selectedLayers))
      check2 = (sessionStorage.getItem(SessionEnum.layer) == 'true')

    } catch (error) {

    }
    let values = listOption?.filter(t => {
      let checkOption = valueSelected?.find(id => { return id == t.id })
      return checkOption
    })
    setValue(values)
    setChecked(check2)
    setShowLayerSettingState(false)
  }

  const handleChange = (e, valueChange) => {
    if (valueChange?.length == listOption?.length) {
      setChecked(true)
    }
    else {
      setChecked(false)
    }
    setValue(valueChange)
  }

  const handleApplyOption = () => {
    let optionSelected = []
    for (let index = 0; index < value.length; index++) {
      optionSelected.push(value[index].id)
    }
    sessionStorage.setItem(SessionEnum.selectedLayers, JSON.stringify(optionSelected));
    AppData.map.setFilterPOIs(optionSelected)
    AppData.map.setFilterBuildings(optionSelected)

    setShowLayerSettingState(false)
    setShowSettingState(false)

    sessionStorage.setItem(SessionEnum.layer, check)
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={showLayerSettingState}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: (!fullScreen) ? classes.dialog : ""
          }
        }
      >
        <DialogTitle id="responsive-dialog-title">{Resource.objectLayer.category}</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            className={classes.boxList}
            closeText={Resource.common.close}
            openText={Resource.common.open}
            clearText={Resource.common.clear}
            multiple
            id="tags-outlined"
            onChange={handleChange}
            value={value}
            options={listOption}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={Resource.objectLayer.chooseObjectLayer}
              />)}
            noOptionsText={Resource.common.noOption}
          />
          <FormControlLabel
            control={<Checkbox checked={check} name="checkbox" color='primary' onChange={handleChangeCheckbox} />}
            label={Resource.objectLayer.chooseAll}
          />
        </DialogContent>
        <DialogActions className={classes.display}>
          <Button className={classes.btnAction} onClick={handleClose} color='inherit' variant="text">
            {Resource.button.cancel}
          </Button>
          <Button className={classes.btnAction} variant="contained" color="primary" autoFocus onClick={handleApplyOption}>
            {Resource.common.apply}
           </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LayerSetting;
