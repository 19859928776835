import { atom } from 'recoil';

export const MyLocationState = atom({
    key: 'MyLocationState',
    default: null,
});
export const OfficeAddressState = atom({
    key: 'OfficeAddressState',
    default: null,
});
export const HomeAddressState = atom({
    key: 'HomeAddressState',
    default: null,
});
export const UserState = atom({
    key: "UserState",
    default: null
}) 