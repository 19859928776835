import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './tooltip.scss';
import * as ReactDOM from 'react-dom';

const Tooltip = ({ children, description }) => {

    const [valueTop, setValueTop] = useState(0)
    const [valueLeft, setValueLeft] = useState(0)
    const [maxHeight, setMaxHeight] = useState(0)
    const [maxWidth, setMaxWidth] = useState(0)
    const [anchorArow, setAnchorArow] = useState('right')
    const [isOpen, setOpen] = useState(false)

    const childrenRef = useRef(null)

    const handleResizeScroll = () => {
        const { innerWidth, innerHeight } = window;
        const { top, bottom, left, right, width, height } = childrenRef?.current?.getBoundingClientRect() || {}
        // Khoảng cách boder ngoài so với màn hình
        const distanceTop = { anchor: 'top', distance: top }
        const distancebottom = { anchor: 'bottom', distance: innerHeight - bottom }
        const distanceLeft = { anchor: 'left', distance: left }
        const distanceRight = { anchor: 'right', distance: innerWidth - right }

        const distanceMax =
            [distanceTop, distancebottom, distanceLeft, distanceRight]
                .reduce((previous, current) => {
                    return previous.distance > current.distance ? previous : current
                })
        const newObjetc = {
            ...distanceMax, top, bottom, left, right, midpointElement: height / 2,
            midpointX: width / 2,
            pointBetweenY_WithScreen: height / 2 + top,
            pointBetweenX_WithScreen: width / 2 + left,
        }
        setAnchorArow(newObjetc.anchor)

        const maxWidth_TopAndBotom = newObjetc.pointBetweenX_WithScreen < (innerWidth / 2)
            ? (newObjetc.pointBetweenX_WithScreen) * 2
            : ((innerWidth - newObjetc.pointBetweenX_WithScreen) * 2)


        const maxHeight_LeftAndRight = newObjetc.pointBetweenY_WithScreen < (innerHeight / 2)
            ? (newObjetc.pointBetweenY_WithScreen) * 2
            : ((innerHeight - newObjetc.pointBetweenY_WithScreen) * 2)


        switch (newObjetc.anchor) {
            case 'top':
                setValueTop(newObjetc.top - 15)
                setValueLeft(newObjetc.pointBetweenX_WithScreen)
                setMaxHeight(newObjetc.top - 15 - 6)
                setMaxWidth(maxWidth_TopAndBotom - 12)

                break;
            case 'bottom':
                setValueTop(newObjetc.bottom + 15)
                setValueLeft(newObjetc.pointBetweenX_WithScreen)
                setMaxHeight(innerHeight - newObjetc.bottom - 15 - 6)
                setMaxWidth(maxWidth_TopAndBotom - 12)
                break;
            case 'left':
                setValueTop(newObjetc.pointBetweenY_WithScreen)
                setValueLeft(newObjetc.left - 15)
                setMaxHeight(maxHeight_LeftAndRight - 12)
                setMaxWidth(newObjetc.left - 15 - 6)
                break;
            default: {
                setValueTop(newObjetc.pointBetweenY_WithScreen)
                setValueLeft(newObjetc.right + 15)
                setMaxHeight(maxHeight_LeftAndRight - 12)
                setMaxWidth(innerWidth - newObjetc.right - 15 - 6)
            }
        }
    }
    useEffect(() => {
        if (isOpen) {
            handleResizeScroll()
            window.addEventListener('resize', handleResizeScroll, true);
            document.addEventListener('scroll', handleResizeScroll, true);
        }
        return () => {
            window.removeEventListener('resize', handleResizeScroll, true);
            document.removeEventListener('scroll', handleResizeScroll, true);
        }
    }, [isOpen])

    const onMouseOver = () => {
        if (!isOpen) {
            setOpen(true)
        }
    }
    const onMouseOut = () => {
        isOpen && setOpen(false)
    }
    const cloneChildren = React.cloneElement(children, { ref: childrenRef, onMouseOver, onMouseOut })
    return (
        <>
            {cloneChildren}
            {
                ReactDOM.createPortal(
                    isOpen && <div
                        className={`tooltipMap4d description description-${anchorArow}`}
                        style={{
                            top: valueTop,
                            left: valueLeft,
                        }}
                    >
                        <div
                            style={{
                                maxHeight,
                                maxWidth,
                            }}>
                            {description}
                        </div>
                        < span className={`arow ${anchorArow}`} />
                    </div>,
                    document.body
                )
            }
        </>

    );
};
Tooltip.propTypes = {
    description: PropTypes.any
};
export default Tooltip;