import React, { Fragment, useEffect, useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { v4 } from "uuid"
import { CanAddDestinationState, CanDirectFromHereState, CanDirectToHereState, HomeAddressState, OfficeAddressState, ShowSettingState, UserState } from "../../appState"
import { RouterConfig, SvgIconConfig, UrlConfig } from "../../config"
import { AppData } from "../../data"
import { AlertTypeEnum, AppEventEnum, CodeEnum, CookieEnum, DirectionDepartEnum, DirectionOptionTypeEnum, MobileDirectionMenuEnum, PositionDirectionTypeEnum, RouteStatusEnum, VehicleEnum, WeightingEnum } from "../../enum"
import MobileRouteDetailV2 from "../../mobileComponents/common/mobileRouteDetailV2/mobileRouteDetailV2"
import MobileLocationInput from "../../mobileComponents/mobileLocationInput/mobileLocationInput"
import { Resource } from "../../resource"
import { ApiTool, AppEventTool, AppTool, CookieTool, DirectionTool, LocationTool, MapTool, MobileDraggableBarTool, StringTool, UrlTool } from "../../tool"
import { useQuery, useWindowSize } from "../../useHooks"
import DrawDirection from "../drawDirection/drawDirection"
import GetInfoForPositionDirection from "../getInfoForPositionDirection/getInfoForPositionDirection"
import HiddenAddClick from "../hiddenAddClick/hiddenAddClick"
import HideCircleMenu from "../hideCircleMenu/hideCircleMenu"
import HideLocationControl from "../hideLocationControl/hideLocationControl"
import HidePlaceControl from "../hidePlaceControl/hidePlaceControl"
import HidePoiControl from "../hidePoiControl/hidePoiControl"
import MarkerDirection from "../markerDirection/markerDirection"
import MobileDirectionPositionsInput from "../mobileDirectionPositionsInput/mobileDirectionPositionsInput"
import MobileDragBody from "../mobileDragBody/mobileDragBody"
import RoutesInfor from "../routesInfor/routesInfor"
import "./mobileDirectionV2.scss"
import MobileDirectionMenu from "../../mobileComponents/common/mobileDirectionMenu/mobileDirectionMenu"
import MobileDirectionOption from "../../mobileComponents/common/mobileDirectionOption/mobileDirectionOption"
import MobileSwipStepsV2 from "../../mobileComponents/mobileSwipStepsV2/mobileSwipStepsV2"
import NotFoundSearch from "../../mobileComponents/notFoundSearch/notFoundSearch"
import VehicleSelect from "../vehicleSelect/vehicleSelect"
import NotFoundRoute from "../../mobileComponents/notFoundRoute/notFoundRoute"
import MobileDepartAt from "../mobileDepartAt/mobileDepartAt"
import MobileDepartAtView from "../../mobileComponents/common/mobileDepartAtView/mobileDepartAtView"
import LoadingCircle from "../../mobileComponents/loadingCircle/loadingCircle"
import UserHomeAddressInput from "../userHomeAddressInput/userHomeAddressInput"
import UserWorkAddressInput from "../userWorkAddressInput/userWorkAddressInput"
import WatchPosition from "../watchPosition/watchPosition"

const IconDict = {
    [DirectionOptionTypeEnum.poi]: SvgIconConfig.direction.poi,
    [DirectionOptionTypeEnum.home]: SvgIconConfig.direction.home,
    [DirectionOptionTypeEnum.work]: SvgIconConfig.direction.office,
    [DirectionOptionTypeEnum.history]: SvgIconConfig.direction.history,
    [DirectionOptionTypeEnum.yourPosition]: SvgIconConfig.direction.yourLocation,
    [DirectionOptionTypeEnum.position]: SvgIconConfig.direction.viewMap
}

const createDefaultPositions = () => {
    return [
        {
            id: v4(),
            index: 0
        },
        {
            id: v4(),
            index: 1
        }
    ]
}

const resetIndexForPositions = (positions) => {
    positions?.forEach((pos, index) => {
        pos.index = index
    })
}

const getTextInput = (poi) => {
    let text = poi?.name && poi?.address ? `${poi?.name}, ${poi?.address}` : (poi?.name || poi?.address)
    if (!text) {
        text = poi?.position ? `${poi?.position.lat?.toFixed(6)}, ${poi?.position.lng?.toFixed(6)}` : ""
    }
    return text
}

const checkAlreadyOptimize = (waypointOrder) => {
    let check = false
    if (waypointOrder?.length == 0) {
        check = true
    }
    else {
        check = !waypointOrder?.some((order, idx) => order > waypointOrder[idx + 1]) //check arr is sorted
    }
    return check
}
const getPositionsFromEncodedData = (encodedData) => {
    let dataJson = DirectionTool.decodeDirection(encodedData)
    let positionsNew = dataJson.positions?.map((pos, index) => {
        let poiId = null
        let position = null
        let optionType = DirectionOptionTypeEnum.position
        if (pos) {
            if (LocationTool.isValid(pos)) {
                position = pos
            }
            else {
                poiId = pos
                optionType = DirectionOptionTypeEnum.poi
            }
        }
        let p = {
            index: index,
            id: v4(),
            position: position,
            poiId: poiId,
            optionType: optionType
        }
        p.textInput = getTextInput(p)
        return p
    })
    if (!(positionsNew?.length >= 2)) {
        positionsNew = createDefaultPositions()
    }
    return positionsNew
}

const checkTwoDateEqual = (dateValue, current) => {
    let currentDate = new Date(current)
    let dateState = new Date(dateValue)
    let check = false

    let cuDate = currentDate?.getDate()
    let cuMonth = currentDate?.getMonth()
    let cuYear = currentDate?.getFullYear()

    let date = dateState?.getDate()
    let month = dateState?.getMonth()
    let year = dateState?.getFullYear()
    if (cuDate == date && cuMonth == month && cuYear == year) {
        check = true
    }
    return check
}

const getPositionsString = (positions) => {
    let arr = [...positions]
    let locStrings = arr?.sort((a, b) => (a.index - b.index)).map(pos => {
        let text = pos?.poiId || (pos?.position ? `${pos?.position?.lat},${pos?.position?.lng}` : "null")
        return text
    }) || []
    return locStrings?.join(";")
}

const MIN_HEIGHT_VIEW = 155
const MobileDirectionV2 = () => {
    const [heightView, setHeightView] = useState(MIN_HEIGHT_VIEW)
    const [hiddenHeader, setHiddenHeader] = useState(false)
    const { data } = useQuery()
    const windowSize = useWindowSize()

    const user = useRecoilValue(UserState)
    const home = useRecoilValue(HomeAddressState)
    const work = useRecoilValue(OfficeAddressState)

    const setCanAddDestination = useSetRecoilState(CanAddDestinationState)
    const setCanDirectFromHere = useSetRecoilState(CanDirectFromHereState)
    const setCanDirectToHere = useSetRecoilState(CanDirectToHereState)
    const setShowSettingState = useSetRecoilState(ShowSettingState)

    const [vehicle, setVehicle] = useState(VehicleEnum.car)
    const [textInput, setTextInput] = useState("")
    const [searchPOIs, setSearchPOIs] = useState([])
    const [textState, setTextState] = useState("")
    const [typeState, setTypeState] = useState("")
    const [suggests, setSuggests] = useState([])
    const [positions, setPositions] = useState(() => {
        return getPositionsFromEncodedData(data)
    })
    const [showSuggest, setShowSuggest] = useState(false)
    const [positionsString, setPositionsString] = useState(() => {
        return getPositionsString(positions)
    })
    const [canDirection, setCanDirection] = useState(false)
    const [routes, setRoutes] = useState(null)
    const [indexState, setIndexState] = useState(0)
    const [history, setHistory] = useState([])
    const [departAt, setDepartAt] = useState()
    const [showHomeInput, setShowHomeInput] = useState(false)
    const [showWorkInput, setShowWorkInput] = useState(false)
    const [showChooseLocationByMap, setShowLocationByMap] = useState(false)
    const [weighting, setWeighting] = useState(WeightingEnum.fastest)
    const [avoidRoads, setAvoidRoads] = useState([])
    const [disableAddDestination, setDisableAddDestination] = useState(false)
    const [showDepartAtView, setShowDepartAtView] = useState(false)
    const [showDepartAtInput, setShowDepartAtInput] = useState(false)
    const [isOptimize, setIsOptimize] = useState(false)
    const [routeStatus, setRouteStatus] = useState(RouteStatusEnum.none)
    const [durationVehicle, setDurationVehicle] = useState(null)
    const [isSearchEnter, setIsSearchEnter] = useState(false)
    const [errorSearchEnter, setErrorSearchEnter] = useState(false)
    const [showSteps, setShowSteps] = useState(false)
    const [showSettings, setShowSetting] = useState(false)
    const [legIndex, setLegIndex] = useState(0)
    const [stepIndex, setStepIndex] = useState(0)
    const [isFullHeight, setIsFullHeight] = useState()
    const [disableDepartAt, setDisableDepartAt] = useState(false)

    const focusIndexRef = useRef()
    const initRef = useRef(false)
    const routesDirectionRenderRef = useRef()
    const headerHtml = useRef()
    const errorSearchText = useRef()

    useEffect(() => {
        MobileDraggableBarTool.setHeight(0)
    }, [])

    const handleOnDragStart = (value) => {
        setHiddenHeader(true)
    }

    const handleDragEnd = (value) => {
        if (value == MIN_HEIGHT_VIEW) {
            setHiddenHeader(false)
            setIsFullHeight(false)
        }
        else {
            setIsFullHeight(true)
        }
    }

    const addLoc = (loc) => {
        setShowLocationByMap(false)
        setPositions(prev => {
            let newPositions = JSON.parse(JSON.stringify(prev || []))
            let pos = newPositions?.[focusIndexRef.current]
            if (pos) {
                delete pos.poiId
                pos.position = loc
                pos.textInput = getTextInput(pos)
                pos.optionType = DirectionOptionTypeEnum.position
                return newPositions
            }
            else {
                return prev
            }
        })
    }

    useEffect(() => {
        setPositions
        let dataJson = DirectionTool.decodeDirection(data)
        if (dataJson?.avoidRoads != undefined) {
            setAvoidRoads(dataJson?.avoidRoads || [])
        }
        if (dataJson.departAt != undefined) {
            setDepartAt(dataJson.departAt)
            setShowDepartAtView(true)
        }
        if (dataJson.vehicle != undefined) {
            setVehicle(dataJson.vehicle)
        }
        if (dataJson.weighting != undefined) {
            setWeighting(dataJson.weighting)
        }
        if (dataJson.routeIndex != undefined) {
            setIndexState(dataJson.routeIndex)
        }
        if (dataJson.keyword != "" || dataJson.keyword != undefined) {
            setTextState(dataJson.keyword)
        }
        if (dataJson.type != "" || dataJson.type != undefined) {
            setTypeState(dataJson.type)
        }
        if (dataJson.isOptimize != "" || dataJson.isOptimize != undefined) {
            setIsOptimize(dataJson.isOptimize)
        }
    }, [data])

    useEffect(() => {
        let sortPositions = JSON.parse(JSON.stringify(positions))

        let dataJson = {
            avoidRoads: avoidRoads,
            departAt: departAt ? departAt?.getTime() : undefined,
            keyword: textState,
            positions: sortPositions.sort((a, b) => (a.index - b.index)).map(p => p?.poiId || p?.position || null),
            routeIndex: indexState,
            vehicle: vehicle,
            weighting: weighting,
            type: typeState,
            isOptimize: isOptimize

        }
        let dataString = DirectionTool.encodeDirection(dataJson)
        let search = UrlTool.createSearch({ data: dataString }, { extendOldQuery: true })
        window.history.replaceState(null, null, RouterConfig.direction + search);

    }, [positions, avoidRoads, departAt, vehicle, weighting, indexState, textState, isOptimize])

    // đăng ký sự kiện được trigger
    useEffect(() => {
        const event1 = AppEventTool.addListener(AppEventEnum.directFromHere, (position) => {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                let pos = arr[0]
                if (!pos?.position) {
                    pos.position = position
                    pos.textInput = getTextInput(pos)
                    pos.optionType = DirectionOptionTypeEnum.position
                }
                return arr
            })
        })
        const event2 = AppEventTool.addListener(AppEventEnum.directToHere, (position) => {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                let pos = arr[1]
                if (!pos?.position) {
                    pos.position = position
                    pos.textInput = getTextInput(pos)
                    pos.optionType = DirectionOptionTypeEnum.position
                }
                return arr
            })
        })
        const event3 = AppEventTool.addListener(AppEventEnum.addDestination, (position) => {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                let pos = {
                    position: position,
                    optionType: DirectionOptionTypeEnum.position,
                    id: v4()
                }
                pos.textInput = getTextInput(pos)
                arr.push(pos)
                return arr
            })
        })
        const event4 = AppEventTool.addListener(AppEventEnum.changeSearchInput, (value) => {
            let { text, type } = value
            setTextState(text)
            setTypeState(type)
        })

        const event5 = AppEventTool.addListener("addDirection", (position) => {
            if (position) {
                let pos = getPositionsFromEncodedData(position)
                setPositions(pos)
                setRoutes(null)
            }
        })
        return () => {
            event1?.remove()
            event2.remove()
            event3.remove()
            event4?.remove()
            event5?.remove()
            setCanAddDestination(false)
            setCanDirectFromHere(true)
            setCanDirectToHere(true)
        }
    }, [])

    // kiểm tra xem có thể chỉ đường từ đây hay chỉ đường đến đây không
    useEffect(() => {
        if (positions?.length == 2 && !positions[0]?.position) {
            setCanDirectFromHere(true)
        }
        else {
            setCanDirectFromHere(false)
        }

        if (positions?.length == 2 && !positions[1]?.position) {
            setCanDirectToHere(true)
        }
        else {
            setCanDirectToHere(false)
        }
    }, [positions])

    useEffect(() => {
        if (disableAddDestination || !canDirection || positions?.length >= 4) {
            setCanAddDestination(false)
        }
        else {
            setCanAddDestination(true)
        }

    }, [positions, disableAddDestination, canDirection])

    useEffect(() => {
        if (showSuggest) {
            let chooseByMapOption = {
                id: v4(),
                optionType: DirectionOptionTypeEnum.position,
                text: "Chọn trên bản đồ"
            }
            if (textInput) {
                let suggestsNew = searchPOIs.map(POI => {
                    return {
                        text: POI?.name && POI?.address ? `${POI?.name}, ${POI?.address}` : (POI?.name || POI?.address),
                        poiId: POI?.id,
                        id: POI?.id,
                        optionType: DirectionOptionTypeEnum.poi,
                        name: POI?.name,
                        address: POI?.address,
                        position: POI?.location
                    }
                })
                setSuggests([chooseByMapOption, ...suggestsNew])
            }
            else {
                let suggests = [
                    {
                        id: v4(),
                        optionType: DirectionOptionTypeEnum.yourPosition,
                        text: Resource.common.yourLocation
                    },
                    chooseByMapOption
                ]
                if (user?.id) {
                    suggests = suggests.concat([
                        {
                            id: v4(),
                            optionType: DirectionOptionTypeEnum.home,
                            text: Resource.common.homePrivate,
                            caption: home?.address || "",
                            position: home?.location,
                            buttonName: home?.location ? Resource.common.edit : Resource.personalAddress.setLocation,
                            onClick: (e) => {
                                e.stopPropagation()
                                setShowSuggest(false)
                                setShowHomeInput(true)
                            }
                        },
                        {
                            id: v4(),
                            optionType: DirectionOptionTypeEnum.work,
                            text: Resource.common.office,
                            caption: work?.address || "",
                            position: work?.location,
                            buttonName: work?.location ? Resource.common.edit : Resource.personalAddress.setLocation,
                            onClick: (e) => {
                                e.stopPropagation()
                                setShowSuggest(false)
                                setShowWorkInput(true)
                            }
                        }
                    ])
                    let listHistory = history?.map(his => {
                        let h = {
                            ...his?.place || {},
                            id: v4(),
                            poiId: his?.place?.placeId,
                            position: his?.place?.location
                        }
                        h.optionType = DirectionOptionTypeEnum.history
                        h.text = h?.name && h?.address ? `${h?.name}, ${h?.address}` : (h?.name || h?.address)
                        return h
                    }) || []
                    suggests = suggests?.concat(listHistory)
                }

                let prev = positions?.[focusIndexRef.current - 1]
                let next = positions?.[focusIndexRef.current + 1]
                let kq = suggests.filter(s => {
                    return (
                        (s?.optionType != DirectionOptionTypeEnum.home && s?.optionType != DirectionOptionTypeEnum.work && s?.optionType != DirectionOptionTypeEnum.yourPosition) ||
                        (s?.optionType != prev?.optionType && s.optionType != next?.optionType)
                    )
                })

                setSuggests(kq)
            }
        }
        else {
            setSuggests([])
        }


    }, [searchPOIs, textInput, home, user?.id, history, work, positions, showSuggest])

    //thay đổi string position
    useEffect(() => {
        setPositionsString(getPositionsString(positions))

        let canDirectionNew = positions?.length >= 2 && !positions?.some(pos => !(pos?.position || pos?.poiId))
        setCanDirection(canDirectionNew)
        let disableDepartAt = !(positions?.length == 2 && !positions?.some(pos => !(pos?.position || pos?.poiId)))
        setDisableDepartAt(disableDepartAt)
    }, [positions])

    useEffect(() => {
        if (initRef.current) {
            setIsOptimize(false)
            setIndexState(0)
        }
        else {
            initRef.current = true
        }
        setPositions((prev) => {
            let arr = JSON.parse(JSON.stringify(prev || []))
            resetIndexForPositions(arr)
            return arr
        })
    }, [positionsString])

    useEffect(() => {
        let timeout
        let api
        if (canDirection) {
            setShowSuggest(false)
            let lang = CookieTool.get(CookieEnum.language) || "vi";
            let positionStrings = positionsString?.split(";")
            let isDuplicateAdjacentPosition = positionStrings?.some((p, index) => {
                return (index > 0 && p == positionStrings[index - 1])
            })
            if (isDuplicateAdjacentPosition) {
                setRouteStatus(RouteStatusEnum.errorPoi)
                setRoutes(null)
                setDurationVehicle(null)
            }
            else {
                let start = positionStrings[0]
                if (!start?.includes(",")) {
                    start = `placeId:${start}`
                }
                let end = positionStrings?.[positionStrings?.length - 1]
                if (!end?.includes(",")) {
                    end = `placeId:${end}`
                }
                positionStrings?.shift()
                positionStrings?.pop()
                let middle = ""
                if (positionStrings?.length > 0) {
                    let middles = positionStrings?.map(s => {
                        let m = s;
                        if (!m?.includes(",")) {
                            m = `placeId:${m}`
                        }
                        return m
                    })
                    middle = middles?.join(";")
                }

                let body = {
                    origin: start,
                    destination: end,
                    points: middle,
                    language: lang,
                    mode: vehicle,
                    isOptimize: isOptimize,
                    weighting: weighting,
                    avoidRoads: avoidRoads?.join(",") || "",
                    departureTime: departAt ? parseInt(departAt?.getTime() / 1000) : undefined
                };
                setRouteStatus(RouteStatusEnum.loading)
                timeout = setTimeout(() => {
                    api = ApiTool.queryGetFromJson(
                        UrlConfig.direction.route,
                        body,
                        (res) => {
                            if (res?.code == CodeEnum.ok) {
                                setRouteStatus(RouteStatusEnum.ok)
                                let result = res?.result
                                if (body.isOptimize) {
                                    setPositions(prev => {
                                        let arr = JSON.parse(JSON.stringify(prev || []))
                                        arr.sort((a, b) => (a.index - b.index))
                                        let kq = JSON.parse(JSON.stringify(arr))
                                        result?.waypointOrder?.forEach((order, index) => {
                                            kq[index + 1] = arr[order + 1]
                                        })
                                        return kq
                                    })
                                    let isAlreadyOptimize = checkAlreadyOptimize(result?.waypointOrder)
                                    if (isAlreadyOptimize) {
                                        AppTool.alert(
                                            Resource.direction.alreadyOptimize,
                                            AlertTypeEnum.info
                                        )
                                    }
                                }
                                if (result?.routes?.length > 0) {
                                    setRoutes(result?.routes)
                                }
                                else {
                                    setRoutes(null)
                                    setRouteStatus(RouteStatusEnum.errorVehicle)
                                }
                                setDurationVehicle(result?.durationByVehicles)
                            } else {
                                setRoutes(null)
                                setRouteStatus(RouteStatusEnum.errorApi)
                            }
                        }
                    )

                }, 300)
            }
        }
        else {
            setRouteStatus(RouteStatusEnum.none)
            setDurationVehicle(null)
        }
        return () => {
            timeout != undefined && clearTimeout(timeout)
            api?.cancel()
        }
    }, [canDirection, positionsString, weighting, avoidRoads, vehicle, departAt, isOptimize])

    useEffect(() => {
        if (!textInput && user?.id) {
            let body = {
                count: 5,
                isPlace: true
            }
            ApiTool.queryGetFromJson(UrlConfig.searchHistory.get, body, (res) => {
                setHistory(res?.result || [])
            }, true)
        }
    }, [textInput, user?.id])

    useEffect(() => {
        if (canDirection && positions?.length == 2 && departAt) {
            setDisableAddDestination(true)
        }
        else {
            setDisableAddDestination(false)
        }

    }, [canDirection, departAt, positions])

    const fitBoundCamera = (routes) => {

        let bounds = new map4d.LatLngBounds();
        routes?.forEach(route => {
            route?.forEach(pos => {
                bounds.extend([pos.lng, pos.lat]);
            })
        })
        positions?.forEach(pos => {
            pos?.position && bounds.extend([pos?.position.lng, pos?.position.lat]);
        })

        let paddingOptions = {
            top: (headerHtml.current?.offsetHeight || 0) + 10,
            bottom: heightView || 10,
            left: 10,
            right: 10,
        };
        let camera = AppData.map.getCameraWithBounds(bounds, paddingOptions);
        if (camera.getZoom() > 19) {
            camera.setZoom(19);
        }
        AppTool.moveCamera(camera, { animate: true });

    }

    const onDrawEnd = (routes) => {
        routesDirectionRenderRef.current = routes
        fitBoundCamera(routes)
    }

    const onFinishGeoCode = (index) => (poi) => {
        setPositions(prev => {
            let newPositions = JSON.parse(JSON.stringify(prev || []))
            let pos = newPositions?.find(n => n.index == index) || {}
            pos.name = poi?.name
            pos.address = poi?.address
            pos.textInput = getTextInput(pos)
            if (pos?.optionType != DirectionOptionTypeEnum.position) {
                pos.position = poi?.location
                if (poi?.id == home?.placeId) {
                    pos.text = Resource.common.homePrivate
                    pos.optionType = DirectionOptionTypeEnum.home
                    pos.textInput = Resource.common.homePrivate
                    pos.name = Resource.common.homePrivate
                }
                else if (poi?.id == work?.placeId) {
                    pos.text = Resource.common.office
                    pos.optionType = DirectionOptionTypeEnum.work
                    pos.textInput = Resource.common.office
                    pos.name = Resource.common.office
                }
            }

            return newPositions
        })
    }

    const onFocusInput = (text, index) => {
        setTextInput(text)
        focusIndexRef.current = index
        setShowSuggest(true)
        text != errorSearchText.current ? setErrorSearchEnter(false) : setErrorSearchEnter(true)
    }

    const getAutoSuggest = (text, index, isEnter = false) => {
        setTextInput(text || "")
        setPositions(prev => {
            let newArr = JSON.parse(JSON.stringify(prev || []))
            delete newArr[index]?.position
            delete newArr[index]?.optionType
            delete newArr[index]?.poiId
            newArr[index].textInput = text
            return newArr
        })
        setShowSuggest(true)
        if (text) {
            let center = AppData.map.getCamera().getTarget();
            let location = `${center.lat},${center.lng}`
            let body = {
                text: text || "",
                location: location,
                isEnter: isEnter
            }
            ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
                if (res?.code == CodeEnum.ok) {
                    let data = res?.result
                    if (isEnter) {
                        selectOptionMatchTextPercent(data, text)
                    }
                    else {
                        setErrorSearchEnter(false)
                    }
                    setSearchPOIs(res?.result?.slice(0, 5) || [])
                }
            })
        }
    }

    const selectOptionMatchTextPercent = (suggests, text) => {
        if (suggests?.length > 0) {
            if (suggests?.length == 1) {
                let firstOption = suggests[0]
                firstOption.position = firstOption?.location
                action[DirectionOptionTypeEnum.poi](firstOption)
            }
            else {
                setIsSearchEnter(true)
            }
        }
        else {
            setErrorSearchEnter(true)
            errorSearchText.current = text
        }
    }

    const onSearch = (text, index) => {
        if (!text) {
            setRoutes(null)
            errorSearchEnter && setErrorSearchEnter(false)
        }
        getAutoSuggest(text, index)
        setIsSearchEnter(false)
    }

    const onSearchEnter = (text, index, isEnter) => {
        let position = LocationTool.checkTextIsLocation(text)
        if (position) {
            setPositions(prev => {
                let newPositions = [...prev || []]
                let pos = newPositions[index]
                if (!pos?.position) {
                    pos.position = position
                    pos.textInput = getTextInput(pos)
                    pos.optionType = DirectionOptionTypeEnum.position
                    return newPositions
                }
                else {
                    return prev
                }
            })
        }
        else {
            getAutoSuggest(text, index, isEnter)
        }
    }

    const onSwap = () => {
        setPositions(prev => {
            let arr = JSON.parse(JSON.stringify(prev || []))
            arr.reverse()
            resetIndexForPositions(arr)
            return arr
        })
    }

    const onRemove = (index) => {
        setPositions(prev => {
            let arr = JSON.parse(JSON.stringify(prev || []))
            arr.splice(index, 1)
            return arr
        })
    }

    const onAdd = () => {
        setPositions(prev => {
            let arr = JSON.parse(JSON.stringify(prev || []))
            arr.push({
                id: v4()
            })
            return arr
        })
    }

    const onChangeVehicle = (newValue) => {
        setVehicle(newValue)
    }

    const action = {
        [DirectionOptionTypeEnum.poi]: (data) => {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                let pos = arr[focusIndexRef.current]
                if (!pos?.position) {
                    pos = {
                        ...data,
                        id: pos?.id
                    }
                    pos.textInput = getTextInput(data)
                    pos.optionType = DirectionOptionTypeEnum.poi
                    arr[focusIndexRef.current] = pos
                }
                if (arr?.some(p => !p?.position)) {
                    MapTool.moveToLocation(data?.position)
                }
                return arr
            })
            setShowSuggest(false)
        },
        [DirectionOptionTypeEnum.history]: (data) => {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                let pos = arr[focusIndexRef.current]
                if (!pos?.position) {
                    pos = {
                        ...data,
                        id: pos?.id
                    }
                    pos.textInput = getTextInput(data)
                    pos.optionType = DirectionOptionTypeEnum.history
                    pos.poiId = data.poiId
                    arr[focusIndexRef.current] = pos
                }
                if (arr?.some(p => !p?.position)) {
                    MapTool.moveToLocation(data?.position)
                }
                return arr
            })
            setShowSuggest(false)
        },
        [DirectionOptionTypeEnum.yourPosition]: (data) => {
            AppTool.getMyLocation((res) => {
                if (res?.code == CodeEnum.ok) {
                    // alert("lấy xong")
                    let position = res?.result
                    setPositions(prev => {
                        let arr = JSON.parse(JSON.stringify(prev || []))
                        if (!arr[focusIndexRef.current]?.position) {
                            let pos = arr[focusIndexRef.current]
                            delete pos.poiId
                            pos.position = position
                            pos.optionType = DirectionOptionTypeEnum.yourPosition
                            pos.textInput = Resource.common.yourLocation
                            pos.name = Resource.common.yourLocation
                        }
                        if (arr?.some(p => !p?.position)) {
                            MapTool.moveToLocation(position)
                        }
                        return arr
                    })
                    setShowSuggest(false)
                }
                else {
                    AppTool.alert(
                        Resource.message.notUsedLocation,
                        AlertTypeEnum.warning
                    )
                }
            })
            setShowSuggest(false)
        },
        [DirectionOptionTypeEnum.home]: (data) => {
            if (home?.location) {
                setPositions(prev => {
                    let arr = JSON.parse(JSON.stringify(prev || []))
                    if (!arr[focusIndexRef.current]?.position) {
                        let pos = arr[focusIndexRef.current]
                        pos.optionType = DirectionOptionTypeEnum.home
                        pos.position = home?.location
                        pos.textInput = Resource.common.homePrivate
                        pos.name = Resource.common.homePrivate
                        pos.poiId = home?.placeId
                    }
                    if (arr?.some(p => !p?.position)) {
                        MapTool.moveToLocation(home?.location)
                    }
                    return arr
                })
                setShowSuggest(false)
            }
            else {
                setShowHomeInput(true)
            }
        },
        [DirectionOptionTypeEnum.work]: (data) => {
            if (work?.location) {
                setPositions(prev => {
                    let arr = JSON.parse(JSON.stringify(prev || []))
                    if (!arr[focusIndexRef.current]?.position) {
                        let pos = arr[focusIndexRef.current]
                        pos.optionType = DirectionOptionTypeEnum.work
                        pos.position = work?.location
                        pos.textInput = Resource.common.office
                        pos.name = Resource.common.office
                        pos.poiId = work?.placeId
                    }
                    if (arr?.some(p => !p?.position)) {
                        MapTool.moveToLocation(work?.location)
                    }
                    return arr
                })
                setShowSuggest(false)
            }
            else {
                setShowWorkInput(true)
            }
        },
        [DirectionOptionTypeEnum.position]: (data) => {
            setShowLocationByMap(true)
        }
    }

    const onClickSuggest = (index) => (e) => {
        let suggest = suggests[index]
        action[suggest?.optionType]?.(suggest)
    }

    const onBlur = () => {
        setShowSuggest(false)
    }

    const onCloseHomeInput = () => {
        setShowHomeInput(false)
    }

    const onCloseWorkInput = () => {
        setShowWorkInput(false)
    }

    const onChangeHomeInput = (poi) => {
        setShowSuggest(true)
        setShowHomeInput(false)
    }
    const onChangeWorkInput = (poi) => {

        setShowSuggest(true)
        setShowHomeInput(false)
    }

    const onChangeRoute = (index) => {
        setIndexState(index)
    }

    const onBlurInput = (text, index) => {
        let position = LocationTool.checkTextIsLocation(text)
        if (position) {
            setPositions(prev => {
                let newPositions = [...prev || []]
                let pos = newPositions[index]
                if (!pos?.position) {
                    pos.position = position
                    pos.textInput = getTextInput(pos)
                    pos.optionType = DirectionOptionTypeEnum.position
                    return newPositions
                }
                else {
                    return prev
                }
            })
        }
    }

    const onChangeLocationByMap = (loc) => {
        addLoc(loc)
    }

    const onClickMenu = (type) => {
        const actionMenu = {
            [MobileDirectionMenuEnum.addPoi]: () => {
                onAdd()
            },
            [MobileDirectionMenuEnum.moreOption]: () => {
                setShowSetting(true)
            },
            [MobileDirectionMenuEnum.settingTime]: () => {
                setShowDepartAtView(true)
                setShowDepartAtInput(true)
            }
        }
        actionMenu[type]?.()
    }

    const onChangeSetting = (isOptimizeNew, weightingNew, avoidRoadsNew) => {
        if (!isOptimizeNew) {
            setPositions(prev => {
                let arr = JSON.parse(JSON.stringify(prev || []))
                arr.sort((a, b) => (a.index - b.index))
                return arr
            })
        }
        setIsOptimize(isOptimizeNew)
        setWeighting(weightingNew)
        setAvoidRoads(avoidRoadsNew)
        setShowSetting(false)
    }

    const onCloseSetting = () => {
        setShowSetting(false)
    }

    const onShowListStep = (legIndex, stepIndex) => {
        setShowSteps(true)
        setLegIndex(legIndex)
        setStepIndex(stepIndex)
    }

    const onBackSteps = () => {
        setShowSteps(false)
    }

    const onChangeHeightView = (heightViewNew) => {
        setHeightView(heightViewNew)
    }

    const onChangeDepartAt = (newDepartAt) => {
        setDepartAt(newDepartAt)
        setShowDepartAtInput(false)
    }

    const onCancelDepartAtInput = () => {
        setShowDepartAtInput(false)
    }

    const onShowDetail = () => {
        setHeightView(windowSize.height)
    }

    const onViewMap = () => {
        setHeightView(MIN_HEIGHT_VIEW)
        setIsFullHeight(false)
    }

    const onClickHeaderDetail = () => {
        let hideHeader = false
        setHeightView((prev) => {
            if (prev == MIN_HEIGHT_VIEW) {
                hideHeader = true
                return windowSize.height
            }
            else {
                return MIN_HEIGHT_VIEW
            }
        })
        setHiddenHeader(hideHeader)
        setIsFullHeight(hideHeader)
    }

    const onClickOptionInDepartAtView = () => {
        setShowSetting(true)
    }

    const onClickDepartAtView = () => {
        setShowDepartAtInput(true)
    }
    const onBackDirection = () => {
        setShowLocationByMap(false)
    }

    let isShowErrorRoute = (routeStatus == RouteStatusEnum.errorApi || routeStatus == RouteStatusEnum.errorVehicle)
    let isShowOption = !(routeStatus == RouteStatusEnum.none || routeStatus == RouteStatusEnum.errorApi || routeStatus == RouteStatusEnum.errorPoi)

    let showActionBackDrop = (routeStatus == RouteStatusEnum.loading || showSuggest || errorSearchEnter || isShowErrorRoute) && !showSettings && !showDepartAtInput

    return (
        <>
            {
                !showSteps &&
                <div
                    className={
                        StringTool.mergeClassName(
                            "directionHeaderCpn",
                            (hiddenHeader) && "hiddenHeader",
                            (showActionBackDrop) && "fullHeight"
                        )
                    }
                    ref={headerHtml}
                >
                    <div className="top">
                        <div className="positionsCtn">
                            <MobileDirectionPositionsInput
                                onFocus={onFocusInput}
                                onSearch={onSearch}
                                value={positions}
                                onSwap={onSwap}
                                onRemove={onRemove}
                                onAdd={onAdd}
                                onBlur={onBlur}
                                onBlurInput={onBlurInput}
                                onEnter={onSearchEnter}
                                disableAddDestination={disableAddDestination}
                            />
                        </div>
                        {
                            <div className='vehicles'>
                                <VehicleSelect
                                    value={vehicle}
                                    onChange={onChangeVehicle}
                                    durationByVehicles={durationVehicle}
                                />
                            </div>
                        }

                        {/* absolute */}
                        <div className="menu">
                            <MobileDirectionMenu
                                onChange={onClickMenu}
                                disableDepartAt={disableDepartAt}
                            />
                        </div>
                    </div>
                    {
                        showDepartAtView && !showActionBackDrop &&
                        <div className="departAtView">
                            <MobileDepartAtView onClick={onClickDepartAtView} value={departAt} onClickOption={onClickOptionInDepartAtView} disableDepartAt={disableDepartAt} />
                        </div>
                    }
                    {
                        (showActionBackDrop) &&
                        <div className="actionBackdrop">
                            {
                                errorSearchEnter &&
                                <NotFoundSearch
                                    searchKey={textInput}
                                />
                            }
                            {
                                isShowErrorRoute &&
                                <NotFoundRoute vehicle={vehicle} />
                            }
                            {
                                routeStatus == RouteStatusEnum.loading &&
                                <div className="loading">
                                    <LoadingCircle />
                                </div>
                            }
                            {
                                (showSuggest && !errorSearchEnter) &&
                                <div className='suggests'>
                                    {
                                        isSearchEnter &&
                                        <div className='textSuggest'>{Resource.direction.suggestMessage}</div>
                                    }
                                    {
                                        suggests?.map((suggest, index) => {
                                            return (
                                                <div
                                                    onClick={onClickSuggest(index)}
                                                    key={suggest?.id}
                                                    className="option"
                                                    title={suggest.text}
                                                >
                                                    <span className='icon'>
                                                        {IconDict[suggest.optionType]}
                                                    </span>
                                                    <span className='optionLabel'>
                                                        <span className='text'>
                                                            {suggest?.text}
                                                        </span>
                                                        {
                                                            suggest?.caption &&
                                                            <span className='caption'>
                                                                {suggest?.caption}
                                                            </span>
                                                        }
                                                    </span>
                                                    {
                                                        suggest?.buttonName &&
                                                        <span className='action' onClick={suggest?.onClick}>
                                                            {suggest?.buttonName}
                                                        </span>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {

                            }
                        </div>
                    }

                </div>
            }

            {
                routes?.[indexState] && !showSteps &&
                <MobileDragBody
                    heightView={heightView}
                    minHeighView={MIN_HEIGHT_VIEW}
                    onChange={onChangeHeightView}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleOnDragStart}
                >
                    <div
                        className="bodyMobileDirection"
                    >
                        <div className='routeDetailCtn'>
                            <MobileRouteDetailV2
                                isFullHeight={isFullHeight}
                                onShowSteps={onShowListStep}
                                route={routes?.[indexState]}
                                points={positions}
                                vehicle={vehicle}
                                weighting={weighting}
                                routeIndex={indexState}
                                onShowDetail={onShowDetail}
                                onViewMap={onViewMap}
                                onClickHeader={onClickHeaderDetail}
                            />
                        </div>
                    </div>
                </MobileDragBody>

            }

            {/* marker and get info for position */}
            {
                positions?.map((pos, index) => {
                    let type = PositionDirectionTypeEnum.middle
                    if (index == 0) {
                        type = PositionDirectionTypeEnum.start
                    }
                    else if (index == (positions?.length - 1)) {
                        type = PositionDirectionTypeEnum.end
                    }
                    return (

                        <Fragment key={pos?.id}>
                            {
                                pos?.position &&
                                <MarkerDirection
                                    index={index}
                                    type={type}
                                    name={pos?.name}
                                    position={pos?.position}
                                    sortIndex={pos?.index}
                                    disableDrag={true}
                                />
                            }

                            {
                                // (pos?.optionType == DirectionOptionTypeEnum.position || pos?.optionType == DirectionOptionTypeEnum.poi) &&
                                <GetInfoForPositionDirection
                                    onFinishGeoCode={onFinishGeoCode(pos.index)}
                                    position={pos?.position}
                                    id={pos?.poiId}
                                    type={pos?.optionType}
                                />
                            }
                        </Fragment>

                    )
                })
            }

            {
                routes?.length > 0 &&
                <>
                    <RoutesInfor routes={routes} vehicle={vehicle} activeRoute={indexState} onChange={onChangeRoute} />

                    <DrawDirection
                        currentIdx={indexState}
                        vehicle={vehicle}
                        positions={positions}
                        routes={routes}
                        onDrawEnd={onDrawEnd}
                        onChange={onChangeRoute}
                    />
                </>
            }

            {
                showChooseLocationByMap &&
                <div className="chooseMobileDirectionLocationByMap">
                    <MobileLocationInput
                        label="Chọn điểm xuất phát"
                        onChange={onChangeLocationByMap}
                        onBack={onBackDirection}
                    />
                </div>

            }
            {
                showSettings &&
                <MobileDirectionOption
                    positions={positions}
                    avoids={avoidRoads}
                    isOptimize={isOptimize}
                    weighting={weighting}
                    onChange={onChangeSetting}
                    onClose={onCloseSetting}
                />
            }

            {
                showSteps &&
                <MobileSwipStepsV2
                    indexRoute={indexState}
                    indexLeg={legIndex}
                    indexStep={stepIndex}
                    route={routes?.[indexState]}
                    onBack={onBackSteps}
                />
            }

            {
                showDepartAtInput &&
                <MobileDepartAt
                    departTime={departAt}
                    onDone={onChangeDepartAt}
                    onCancel={onCancelDepartAtInput}
                />
            }

            {
                showHomeInput &&
                <UserHomeAddressInput onClose={onCloseHomeInput} onChange={onChangeHomeInput} />
            }
            {
                showWorkInput &&
                <UserWorkAddressInput onClose={onCloseWorkInput} onChange={onChangeWorkInput} />
            }
            {
                routes?.length > 0 &&
                <WatchPosition map={AppData.map} />
            }


            <HidePoiControl />
            <HidePlaceControl />
            <HideLocationControl />
            <HideCircleMenu />
            <HiddenAddClick />
        </>
    )
}

export default MobileDirectionV2