import PropTypes from 'prop-types';
import React from 'react';
import TooltipV2 from '../../../components/tooltipV2/tooltipV2';
import { Resource } from '../../../resource';
import { AppTool, StringTool } from '../../../tool';
import './mobileShareButton.scss';

const ICON_SHARE_IN_ELLIPSE =
  <svg width="15" height="15" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M10,3V5H5V19H19V14h2v6a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3Zm8.586,1H14V2h8v8H20V5.414l-7,7L11.586,11Z" fill="#1c75bc" />
  </svg>

const ICON_SHARE =
  <svg width="15" height="15" viewBox="0 0 18 18">
    <path fill='none' d="M0,0H18V18H0Z" />
    <path fill='#1C75BC' d="M14.222,12.544a2.177,2.177,0,0,0-1.466.577L7.421,10.013a2.454,2.454,0,0,0,.067-.524,2.454,2.454,0,0,0-.067-.524L12.7,5.887a2.242,2.242,0,1,0-.718-1.64,2.454,2.454,0,0,0,.067.524L6.771,7.849a2.247,2.247,0,1,0,0,3.28L12.1,14.244a2.115,2.115,0,0,0-.06.487,2.185,2.185,0,1,0,2.185-2.187Zm0-9.046a.749.749,0,1,1-.748.749A.751.751,0,0,1,14.222,3.5Zm-8.978,6.74a.749.749,0,1,1,.748-.749A.751.751,0,0,1,5.244,10.237Zm8.978,5.257a.749.749,0,1,1,.748-.749A.751.751,0,0,1,14.222,15.494Z" transform="translate(-0.733 -0.489)" />
  </svg>

const SIZE = 36
function MobileShareButton({ url, size = SIZE, isEllipse }) {

  const handleShare = (e) => {
    AppTool.share(url)
  }

  return (
    <TooltipV2 description={Resource.common.share} anchor='top'>
      <div className={StringTool.mergeClassName('mobileShareButtonCpn', isEllipse ? 'isEllipse' : '')}>
        <div
          className='circleBtn'
          onClick={handleShare}
          style={{
            borderRadius: size / 2
          }}

        >
          <div
            className='icon'
            style={{
              width: size,
              height: size,
            }}>
            {isEllipse ? ICON_SHARE_IN_ELLIPSE : ICON_SHARE}
          </div>

          <div className='caption2'>
            <div>
              {Resource.common.share}
            </div>

          </div>
        </div>
        <div className='caption'>
          <div>
            {Resource.common.share}
          </div>
        </div>

      </div>
    </TooltipV2>
  )
}

MobileShareButton.propTypes = {
  isEllipse: PropTypes.bool,
  url: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
};

export default MobileShareButton;
