import moment from "moment";
import React, {
  forwardRef,
  Suspense,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import App from "./app";
import { LanguageState, PlaceTypeState } from "./appState";
import { LocationCanBackState } from "./appState/locationState";
import {
  HomeAddressState, OfficeAddressState, UserState
} from "./appState/userState";
import { UrlConfig } from "./config/urlConfig";
import { AppData } from "./data/appData";
import { CodeEnum, CookieEnum, StorageEnum } from "./enum";
import MobileApp from "./mobileComponents/mobileApp";
import { Resource } from "./resource";
import { ApiTool, AppTool, CookieTool } from "./tool";
import { defaultToken } from "./tool/userTool";
import { useIsMobileScreen } from "./useHooks/useIsMobileScreen";
import ErrorControl from "./components/errorControl/errorControl";
import "dialog-polyfill/dist/dialog-polyfill.css"
import "./polyfill/replaceAll"
import "./polyfill/arrayAt"

moment.locale("vi");
function AppRoot(props, ref) {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobileScreen();

  const setOfficeAddressState = useSetRecoilState(OfficeAddressState);
  const setHomeAddressState = useSetRecoilState(HomeAddressState);
  const setLocationCanBackState = useSetRecoilState(LocationCanBackState);
  const setPlaceTypeState = useSetRecoilState(PlaceTypeState);

  const languageState = useRecoilValue(LanguageState);

  const [user, setUser] = useRecoilState(UserState);

  const pathChangeCountRef = useRef(0);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useImperativeHandle(ref, () => ({
    reload: () => {
      forceUpdate();
    },
  }));

  useEffect(() => {
    // Resource.setLanguage(languageState);
    forceUpdate();
  }, [languageState]);

  const getUserData = () => {
    let resource = null;
    let token = CookieTool.get(CookieEnum.token);
    if (AppTool.isLocal()) {
      token = defaultToken;
    }

    if (token) {
      resource = ApiTool.get(
        UrlConfig.user.detail,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            AppData.user = res?.result;
            setUser(res?.result || {})
          }
          else {
            setUser({})
          }
        },
        true
      );
    } else {
      setUser({});
    }

    return resource;
  };

  useEffect(() => {
    history.replace({ ...history.location, state: undefined });

    window.addEventListener(
      "storage",
      function (event) {
        if (event.key == StorageEnum.logout) {
          window.location.href = `${UrlConfig.auth.logout}?returnUrl=${window.location.href}`;
        }
      },
      false
    );

    window.addEventListener(
      "storage",
      function (event) {
        if (event.key == StorageEnum.changeRole) {
          window.location.reload();
        }
      },
      false
    );

    let apiUser = getUserData();

    return () => {
      apiUser?.cancel();
    };
  }, []);

  useEffect(() => {
    if (pathChangeCountRef.current == 1) {
      setLocationCanBackState(true);
    }
    pathChangeCountRef.current++;
  }, [location.pathname]);

  useEffect(() => {
    var sourceUserLabel;
    var sourceMyLocation;
    let token = CookieTool.get(CookieEnum.token);
    if (AppTool.isLocal()) {
      token = defaultToken;
    }
    if (token) {
      sourceUserLabel = ApiTool.get(
        UrlConfig.userLabel.get,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            let userLabel = res?.result;
            if (userLabel?.home) {
              let homePrivate = {
                ...userLabel?.home,
                name: Resource.common.homePrivate,
              };
              setHomeAddressState(homePrivate);
            }
            if (userLabel?.company) {
              let office = {
                ...userLabel?.company,
                name: Resource.common.office,
              };
              setOfficeAddressState(office);
            }
          }
        },
        true
      );
    }

    const sourcePlaceType = ApiTool.get(
      UrlConfig.placeType.getListSearch,
      (res) => {
        if (res?.code == CodeEnum.ok) {
          let placeTypes = res?.result;
          let all = {};
          placeTypes?.forEach((placeType, index) => {
            all[placeType?.id] = placeType;
          });
          setPlaceTypeState(all);
        }
      }
    );
    return () => {
      sourceMyLocation?.cancel();
      sourceUserLabel?.cancel();
      sourcePlaceType?.cancel();
    };
  }, []);

  return user ? (
    <ErrorControl>
      <Suspense fallback={null}>
        {isMobile ? <MobileApp /> : <App />}
        {/* <Firework /> */}
      </Suspense>
    </ErrorControl>

  ) : null;
}
AppRoot = forwardRef(AppRoot);
export default AppRoot;
