import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ShowSideBarState } from './../../appState/showSideBarState';

function HideSideBar() {
  const setShowSideBarState = useSetRecoilState(ShowSideBarState)

  useEffect(() => {
    setShowSideBarState(false)
    return () => {
      setShowSideBarState(true)
    }
  }, [])

  return (
    null
  )
};

export default HideSideBar;
