import { ModeEnum, WeightingEnum } from "../enum"
import { LocationTool } from "./locationTool"

const EndcodeDirectionEnum = {
    locs: "l",
    mode: "m",
    weighting: "w",
}
const DecodeDirectionEnum = {
    l: "locs",
    m: "mode",
    w: "weighting"
}
const EndcodeModeEnum = {
    [ModeEnum.bike]: "b",
    [ModeEnum.car]: "c",
    [ModeEnum.foot]: "f",
    [ModeEnum.motorcycle]: "m"
}
const DecodeModeEnum = {
    b: ModeEnum.bike,
    c: ModeEnum.car,
    f: ModeEnum.foot,
    m: ModeEnum.motorcycle
}
const EndcodeWeightingEnum = {
    [WeightingEnum.balance]: "b",
    [WeightingEnum.fastest]: "f",
    [WeightingEnum.shortest]: "s"
}
const DecodeWeightingEnum = {
    b: [WeightingEnum.balance],
    f: [WeightingEnum.fastest],
    s: [WeightingEnum.shortest]
}
export class EncryptionTool {
    static encodeDataDirection = (data) => {
        let arr = []
        Object.keys(data)?.forEach(key => {
            let itemText = null
            if (key == "locs") {

                let value = []
                let locs = data?.locs || []
                locs?.forEach(item => {
                    let loc = `null`
                    if (item && LocationTool.isValid(item)) {
                        loc = `${item.lat},${item.lng}`
                    }
                    value.push(loc)
                })
                if (value.length >= 2) {
                    itemText = `${EndcodeDirectionEnum[key]}${value.join("@")}`
                }
                else {
                    itemText = `${EndcodeDirectionEnum[key]}null@null`
                }
            }
            else if (key == "mode") {
                itemText = `${EndcodeDirectionEnum[key]}${EndcodeModeEnum[data[key]]}`
            }
            else if (key == "weighting") {
                itemText = `${EndcodeDirectionEnum[key]}${EndcodeWeightingEnum[data[key]]}`
            }
            if (itemText) {
                arr.push(itemText)
            }
        })
        if (arr.length > 0) {
            return arr.join("&")
        }
        else return ""
    }
    static decodeDataDirection = (dataString) => {
        let arr = dataString?.split("&")
        let data = {}
        let result = {}
        arr?.forEach(item => {
            if (item?.length >= 2) {
                let name = item[0]
                let value = item.substr(1, item?.length-1)
                data[name] = value
            }
        })
        if (data.hasOwnProperty(EndcodeDirectionEnum.locs)) {
            let locsString = data[EndcodeDirectionEnum.locs]
            let arrLocs = locsString?.split("@")
            let locs=[]
            arrLocs?.forEach(loc => {
                let newLoc = LocationTool.checkTextIsLocation(loc)
                locs.push(newLoc||null)
            })
            if(locs.length>=2){
                result.locs=locs
            }
            else{
                result.locs=[null, null]
            }
        }
        if (data.hasOwnProperty(EndcodeDirectionEnum.mode)) {
            result.mode=DecodeModeEnum[data[EndcodeDirectionEnum.mode]]
        }
        if (data.hasOwnProperty(EndcodeDirectionEnum.weighting)) {
            result.weighting=DecodeWeightingEnum[data[EndcodeDirectionEnum.weighting]]
        }
        if(Object.keys(result)?.length>0){
            return result
        }
        else{
            return null
        }
    }
}