import { CircularProgress, ClickAwayListener, Link, ListItem, Paper } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import DirectionsIcon from '@material-ui/icons/Directions';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { TextInput } from 'ui';
import { v4 as uuidv4 } from 'uuid';
import { CommonConfig, LinkIconConfig } from '../../../config';
import { AppConfig } from '../../../config/appConfig';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { AppData } from '../../../data/appData';
import { CodeEnum, TypeGeoJsonEnum, TypeHistoryEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AppTool, UrlTool } from '../../../tool';
import { LocationTool } from '../../../tool/locationTool';
import Tooltip from '../../tooltip/tooltip';
import TooltipV2 from '../../tooltipV2/tooltipV2';
import { ShowSettingState } from './../../../appState/settingState';
import { FocusInputState } from './../../../appState/sidebarState';
import { ApiTool } from './../../../tool/apiTool';
import { HistoryTool } from './../../../tool/historyTool';
import "./searchBar.scss";
import { CanBackToResultState, PlaceRelatedState } from '../../../appState';
import { useQuery } from '../../../useHooks';
import { CanBackToPlaceRelatedState } from '../../../appState/canBackToPlaceRelatedState';
const IconDict = {
  line:
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-2236 -99)" clipPath="url(#clip-path)">
        <g transform="translate(2209.496 -39.504)">
          <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
          <g transform="translate(14303.259 15472.259)">
            <g transform="translate(-14270.419 -15327.419)">
              <g>
                <g>
                  <path d="M8.8,0H6.023V1.853H5.1V0H2.317L0,11.12H11.12ZM6.023,10.193H5.1V8.34h.927Zm0-2.78H5.1V5.56h.927Zm0-2.78H5.1V2.78h.927Z" fill="#869195" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>,
  building:
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-2236 -99)" clipPath="url(#clip-path)">
        <g transform="translate(2209.496 -39.504)">
          <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
          <g transform="translate(31.504 143.504)">
            <path d="M0,0H14V14H0Z" fill="none" />
            <path d="M12.659,12.31h1.166v1.164H1V12.31H2.166V3.582A.582.582,0,0,1,2.749,3H8.578a.582.582,0,0,1,.583.582V12.31h2.332V7.655H10.327V6.491h1.749a.582.582,0,0,1,.583.582ZM3.332,4.164V12.31H8V4.164ZM4.5,7.655H6.829V8.819H4.5Zm0-2.328H6.829V6.491H4.5Z" transform="translate(-0.412 -1.236)" fill="#727272" />
          </g>
        </g>
      </g>
    </svg>,
  area:
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-2236 -99)" >
        <g transform="translate(2209.496 -39.504)">
          <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
          <g transform="translate(32.28 144.28)">
            <path d="M0,0H12.637V12.637H0Z" fill="none" />
            <path d="M2,2H4.633V4.633H2ZM2,9.9H4.633v2.633H2ZM9.9,2h2.633V4.633H9.9Zm0,7.9h2.633v2.633H9.9ZM5.159,3.053H9.371V4.106H5.159ZM3.053,5.159H4.106V9.371H3.053Zm7.371,0h1.053V9.371H10.424ZM5.159,10.424H9.371v1.053H5.159Z" transform="translate(-0.947 -0.947)" fill="#869195" />
          </g>
        </g>
      </g>
    </svg>,
  history:
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" fill="none" />
      <g transform="translate(-26.505 -138.504)">
        <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
        <g transform="translate(31.504 143.504)">
          <pat d="M0,0H13.879V13.879H0Z" fill="none" />
          <path d="M7.783,13.566a5.783,5.783,0,1,1,5.783-5.783A5.783,5.783,0,0,1,7.783,13.566Zm0-1.157A4.626,4.626,0,1,0,3.157,7.783,4.626,4.626,0,0,0,7.783,12.409Zm.578-4.626h2.313V8.939H7.2V4.891H8.361Z" transform="translate(-0.843 -0.843)" fill="#869195" />
        </g>
      </g>
    </svg>,
  place:
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-429 -564)">
        <g transform="translate(402.497 425.496)">
          <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
          <g transform="translate(33.836 144.669)">
            <g transform="translate(0 0)">
              <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="#869195" />
              <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="none" />
            </g>
            <circle cx="1.941" cy="1.941" r="1.941" transform="translate(2.553 2.553)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
}

const TypeItem = {
  history: "history",
  place: "place",
  addPlace: "addPlace"
}
const TypeRightBtn = {
  close: "back",
  direct: "direct"
}
const IconPlaceEnum = {
  place: "place",
  home: "home",
  line: "line",
  area: "area",
  history: "history",
  building: "building"
}

function SearchBar(props, ref) {
  const history = useHistory()
  const { pathname } = useLocation()
  const { type, id } = useParams()
  const { text } = useQuery()

  const [isLoading, setIsLoading] = useState(false)
  const [isShowLoading, setIsShowLoading] = useState(false)
  const [places, setPlaces] = useState([])
  const [textState, setTextState] = useState("")
  const [currentIndexAutocomplete, setCurrentIndexAutocomplete] = useState(-1)
  const [isShowAutoComplete, setIsShowAutoComplete] = useState(false)

  const refInputBase = useRef(null)
  const refRoot = useRef(null)
  const timerRef = useRef()
  const sourceAutocompleteRef = useRef()

  const setShowSettingState = useSetRecoilState(ShowSettingState)
  const setFocusInputState = useSetRecoilState(FocusInputState)
  const canBackToResultState = useRecoilValue(CanBackToResultState)
  const canBackToPlaceRelatedState = useRecoilValue(CanBackToPlaceRelatedState)

  const placeRelatedState = useRecoilValue(PlaceRelatedState)

  const handleClickAway = (e) => {
    if (document.activeElement != ReactDOM.findDOMNode(refInputBase.current)) {
      setIsShowAutoComplete(false)
    }
  }

  const handleShowSetting = () => {
    setShowSettingState(true)
  }

  const handleShowDirection = (e) => {
    history?.push({
      pathname: RouterConfig.direction,
      search: UrlTool.createSearch({}, { extendOldQuery: true })
    })
  }

  const handleOnClickPlaceSearch = (placeSearch) => (e) => {
    if (placeSearch.typeItem == TypeItem.place || (placeSearch.typeItem == TypeItem.history && placeSearch.typeHistory == TypeHistoryEnum.place)) {
      if (placeSearch?.id) {
        HistoryTool.addPlaceToHistory(placeSearch)
      }
      showDetail(placeSearch)
    }
    if (placeSearch.typeItem == TypeItem.search || (placeSearch.typeItem == TypeItem.history && placeSearch.typeHistory == TypeHistoryEnum.search)) {
      searchPlace(placeSearch.name)
    }
    if (placeSearch.typeItem == TypeItem.addPlace) {
      handleAddNewPlace()
    }
    refRoot.current?.focus()
    setIsShowAutoComplete(false)

  }

  const handleAutoCompleteKeypress = (e) => {
    if (e.key.toLowerCase() == "arrowup" || e.key.toLowerCase() == "arrowdown") {
      let index = currentIndexAutocomplete
      if (e.key.toLowerCase() == "arrowup") {
        index--;
      }
      else if (e.key.toLowerCase() == "arrowdown") {
        index++;
      }
      if (index >= places?.length) {
        index = -1;
      }
      if (index < -1) {
        index = places?.length - 1
      }
      // if (index > -1) {
      //   showDetail(places[index])
      // }
      // else {
      //   showHome()
      // }
      if (index > -1) {
        refRoot.current?.focus()
      }
      else {
        refInputBase.current?.focus()
      }
      setCurrentIndexAutocomplete(index)
    }
    if (e.key.toLowerCase() == "enter") {
      if (0 <= currentIndexAutocomplete && currentIndexAutocomplete < places?.length) {
        let item = places[currentIndexAutocomplete]
        handleOnClickPlaceSearch(item)()
      }
      if (currentIndexAutocomplete == -1) {
        if (textState) {
          HistoryTool.addSearchToHistory(textState)
          searchPlace(textState)
        }
      }
    }
  }

  const handleClickBtnSearch = (textSearch) => (e) => {
    if (textSearch) {
      HistoryTool.addSearchToHistory(textSearch)
      searchPlace(textSearch)
    }
  }

  const searchPlace = (textSearch, type = null) => {
    if (textSearch && LocationTool.checkTextIsLocation(textSearch)) {
      let loc = LocationTool.checkTextIsLocation(textSearch)
      history?.push({
        pathname: RouterConfig.place.detailWithLocation.replace(":lat", loc.lat).replace(":lng", loc.lng)
      })
    }
    else {
      AppTool.search(textSearch, type, 0, AppData.map.getCamera().getTarget())
      setIsShowAutoComplete(false)
      setIsLoading(false)
      refRoot.current?.focus()
      if (textSearch) {
        history.push({
          pathname: RouterConfig.place.search,
          search: UrlTool.createSearch({ text: textSearch, type: type })
        })
      }
    }
  }

  const showHome = () => {
    history.push({
      pathname: RouterConfig.home
    })
  }

  const showDetail = (place) => {
    if (place.id) {
      history.push({
        pathname: RouterConfig.place.detail.replace(":id", place?.id)
      })
    }
    else {
      let placeCus = {
        name: place?.name,
        address: place?.address,
        location: place?.location
      }
      let data = encodeURIComponent(JSON.stringify(placeCus))
      history.push({
        pathname: RouterConfig.place.detailCustom.replace(":data", data)
      })
    }
  }

  const handleAddNewPlace = () => {
    // let location = AppData.map.getCamera().getTarget()
    // history.push({
    //   pathname: RouterConfig.place.add.replace(":lat", location?.lat).replace(":lng", location?.lng).replace("/:placeId?/:placeName?", "")
    // })

    history.push({
      pathname: location.pathname,
      search: UrlTool.createSearch({ cp: 1 }, { extendOldQuery: true })
    })
  }

  const searchAutocomplete = (newText) => {
    let histories = HistoryTool.searchHistory(newText).reverse()
    histories?.forEach(history => {
      history.typeItem = TypeItem.history
      history.typeIcon = IconPlaceEnum.history
      history.name = history.text || history.name
    });
    timerRef.current && clearTimeout(timerRef.current)
    if (newText) {
      // timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        let center = AppData.map.getBounds().getCenter();
        let location = `${center.lat},${center.lng}`
        if (AppData.map.getCamera().getZoom() < 11) {
          location = null
        }
        let body = {
          datetime: new Date().getTime(),
          text: newText,
          location: location
        }
        setIsLoading(true)
        sourceAutocompleteRef.current?.cancel()
        sourceAutocompleteRef.current = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
          let list = [];
          setIsLoading(false)
          if (res.code == CodeEnum.ok) {
            if (res?.result?.length > 0) {
              const places = (res?.result || []).slice(0, 5)
              for (let i = 0; i < places.length; i++) {
                let iconClass = IconPlaceEnum.place
                if (places[i].objectId) {
                  iconClass = IconPlaceEnum.building
                }
                else {
                  if (places[i].geometry && places[i].geometry.type) {
                    switch (places[i].geometry.type) {
                      case TypeGeoJsonEnum.lineString:
                      case TypeGeoJsonEnum.multiLineString:
                        iconClass = IconPlaceEnum.line
                        break;
                      case TypeGeoJsonEnum.polygon:
                      case TypeGeoJsonEnum.multiPolygon:
                        iconClass = IconPlaceEnum.area
                        break
                      default:
                        break;
                    }
                  }
                }
                let a = {
                  ...places[i],
                  typeIcon: iconClass,
                  isPlace: true,//để phân biệt với item đề xuất địa điểm khi mãng rống
                  typeItem: TypeItem.place,
                }
                list.push(a);
              }
            }
            list = histories.concat(list)
            if (list.length == 0) {
              list.push({
                name: Resource.common.noPlaceFound,
                address: Resource.common.addMissingPlace,
                typeIcon: IconPlaceEnum.place,
                isPlace: false,
                typeItem: TypeItem.addPlace
              })
            }
            setPlaces(list)
          }
        })
      }, 300)

    }
    else {
      sourceAutocompleteRef.current?.cancel()
      setPlaces(histories)
    }
  }
  useEffect(() => {
    if (canBackToResultState?.text) {
      let search = decodeURIComponent(canBackToResultState?.text)
      setTextState(search)
    }
  }, [canBackToResultState])

  useEffect(() => {
    return () => {
      setFocusInputState(false)
      timerRef.current && clearTimeout(timerRef.current)
      sourceAutocompleteRef.current?.cancel()
    }
  }, [])

  useEffect(() => {
    setCurrentIndexAutocomplete(-1)
    if (pathname == RouterConfig.home) {
      refInputBase.current?.focus()
    }
  }, [pathname])

  useEffect(() => {
    if (isLoading) {
      setIsShowLoading(true)
    }
    else {
      setTimeout(() => {
        setIsShowLoading(false)
      }, 300)
    }
  }, [isLoading])

  const handleCloseSearch = () => {
    showHome()
    setTextState('')
  }
  const renderDirectionButton = () => {
    if (pathname == RouterConfig.home) {
      return (
        <TooltipV2 title={Resource.common.direction} placement="bottom">
          <button className='iconButton' onClick={handleShowDirection} >
            <DirectionsIcon color='primary' />
          </button>
        </TooltipV2>
      )
    }
    else {
      return (
        <TooltipV2 title={Resource.common.close} placement="bottom">
          <button className='iconButton' onClick={handleCloseSearch}>
            {/* <CloseIcon color='#FFFFFF'/> */}
            <img src={LinkIconConfig.searchBar.close} width={24} height={24} />
          </button>
        </TooltipV2>
      )
    }
  }

  useImperativeHandle(ref, () => ({
    setText: (textInput) => {
      setTextState(textInput)
    },
    setLoading: (isLoading) => {
      setIsLoading(isLoading)
    }
  }))

  const handleBackToResult = () => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: canBackToResultState?.text, type: canBackToResultState?.type })
    })
  }

  const handleBackToPlaceRelated = () => {
    history.push({
      pathname: location.pathname,
    })
  }

  return (
    <Paper ref={refRoot} tabIndex={0} className='searchBarCpn' style={{ maxWidth: `calc(100% - ${CommonConfig.padding * 2}px)` }} onKeyDown={handleAutoCompleteKeypress}>
      <div className='content'>
        {
          canBackToResultState ?
            <TooltipV2 title={Resource.common.back} placement="bottom">
              <button className='iconButton' onClick={handleBackToResult}>
                <ArrowBack />
              </button>
            </TooltipV2>
            :
            <TooltipV2 title={Resource.common.menu} placement="bottom">
              <button className='iconButton' onClick={handleShowSetting}>
                <img src={LinkIconConfig.menu} width={24} height={24} />
              </button>
            </TooltipV2>
        }

        <ClickAwayListener onClickAway={e => setFocusInputState(false)}>
          <div style={{ flexGrow: 1 }}>
            <TextInput
              inputRef={refInputBase}
              className='input'
              placeholder={Resource.common.search + " " + AppConfig.name}
              value={textState}
              onChange={e => {
                setTextState(e.target.value)
                searchAutocomplete(e.target.value)
              }}
              onFocus={e => {
                searchAutocomplete(e.target.value)
                setFocusInputState(true)
                setIsShowAutoComplete(true)
              }}

            />
          </div>
        </ClickAwayListener>
        <TooltipV2 title={Resource.common.search} placement="bottom">
          <button className='iconButton' onClick={handleClickBtnSearch(textState)}>
            {/* <SearchIcon color={text ? "primary" : 'inherit'} /> */}
            <img src={LinkIconConfig.searchBar.search} width={24} height={24} />
          </button>
        </TooltipV2>
        <div className='divider' style={{ margin: `${(CommonConfig.heightSearchBar - 24) / 2}px 0px`, height: '24px', width: '1px', backgroundColor: '#00000061' }} />
        {
          isShowLoading ?
            <button className='iconButton' style={{ margin: '12px' }}><CircularProgress size='24px' className='loading' /></button>
            :
            renderDirectionButton()
        }
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          {
            isShowAutoComplete ?
              places?.length > 0 &&
              <div className='list'>
                <div className='divider'></div>
                {
                  places?.map((place, index) => {
                    let text = (place?.name && place?.address) ? place?.name + " - " + place?.address : (place?.name || place?.address)
                    return (
                      <Fragment key={uuidv4()}>
                        <ListItem className='listItem' selected={index == currentIndexAutocomplete} button onClick={handleOnClickPlaceSearch(place)}>
                          <div className='listItemIcon'>
                            {
                              place?.typeIcon == IconPlaceEnum.line &&
                              IconDict.line
                            }
                            {
                              place?.typeIcon == IconPlaceEnum.building &&
                              IconDict.building
                            }
                            {
                              place?.typeIcon == IconPlaceEnum.area &&
                              IconDict.area
                            }
                            {
                              place?.typeIcon == IconPlaceEnum.history &&
                              IconDict.history
                            }
                            {
                              place?.typeIcon == IconPlaceEnum.place &&
                              IconDict.place
                            }
                          </div>
                          <div className='listItemText' title={text}>
                            <span className='itemText'>
                              {
                                place.typeItem !== "addPlace" ? text :
                                  <Link href="#" color="primary">
                                    {text}
                                  </Link>
                              }
                            </span>
                          </div>
                        </ListItem>
                      </Fragment>
                    )
                  })
                }
              </div>
              :
              null
              // (canBackToPlaceRelatedState &&
              //   <div className='backToPlaceRelated' onClick={handleBackToPlaceRelated}>
              //     Quay lại địa điểm cùng tòa nhà
              //   </div>

              // )
          }
        </div>
      </ClickAwayListener>
    </Paper >
  )
}

SearchBar = forwardRef(SearchBar)
export default SearchBar;
