import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { HorizontalLine, TextInput } from 'ui';
import { PlaceTypeState, ShowPopoverMoreState } from '../../../appState';
import { LinkIconConfig, RouterConfig, UrlConfig } from '../../../config';
import { CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool, StringTool, UrlTool } from '../../../tool';
import './showMoreSuggestPlace.scss';

const ICON_DETAIL_MORE = <svg id="Component_761_1" data-name="Component 761 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,0H24V24H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z" transform="translate(0 24) rotate(-90)" fill="#869195" />
</svg>
const ICON_BACK = <svg id="Component_763_1" data-name="Component 763 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_8132" data-name="Path 8132" d="M0,0H24V24H0Z" fill="none" />
  <path id="Path_11786" data-name="Path 11786" d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#505050" />
</svg>

const ShowMoreSuggestPlace = () => {
  const history = useHistory()
  const apiGroupPlaceType = useRef()

  const placeTypeDict = useRecoilValue(PlaceTypeState)
  const setClosePopoverState = useSetRecoilState(ShowPopoverMoreState)
  const [listGroupPlaceType, setListGroupPlaceType] = useState([])
  const [textInput, setTextInput] = useState("")
  const [idsOfSearchAll, setIdsOfSearchAll] = useState(null)
  const [idsOfGroup, setIdsOfGroup] = useState(null)
  const [idsSearchOfGroup, setIdsSearchOfGroup] = useState(null)
  const [groupId, setGroupId] = useState("")

  useEffect(() => {
    if (groupId) {
      let idsOfGroupNew = Object.values(placeTypeDict)?.filter(placeType => placeType?.group == groupId)?.map(item => item?.id)
      setIdsOfGroup(idsOfGroupNew)
    }
    else {
      setIdsOfGroup([])
    }
  }, [groupId])

  useEffect(() => {
    if (groupId) {
      if (textInput) {// search với nhóm danh mục
        let textKey = StringTool.removeUnikey(textInput)?.toLowerCase()
        let idsOfGroupNew = Object.values(placeTypeDict)?.filter(placeType => placeType?.group == groupId)?.filter(
          (type) => {
            let nameKey = StringTool.removeUnikey(type?.name || "")?.toLowerCase()
            let idKey = StringTool.removeUnikey(type?.id || "")?.toLowerCase()
            return (nameKey?.includes(textKey) || idKey?.includes(textKey))
          }
        )?.map(p => p.id)
        setIdsSearchOfGroup(idsOfGroupNew)
      }
      else {
        let idsOfGroup = Object.values(placeTypeDict)?.filter(placeType => placeType?.group == groupId)?.map(p => p.id)
        setIdsSearchOfGroup(idsOfGroup)
      }
    }
    else {
      if (textInput) { //search all type
        let text = StringTool.removeUnikey(textInput)?.toUpperCase()
        let idsSearchAllNew = Object.values(placeTypeDict)?.filter(type => {
          let checkName = StringTool.removeUnikey(type?.name)?.toUpperCase()
          let checkId = StringTool.removeUnikey(type?.id)?.toUpperCase()
          return (checkName?.includes(text) || checkId?.includes(text))
        })?.map(p => p?.id)
        setIdsOfSearchAll(idsSearchAllNew)
      }
      else {
        setIdsOfSearchAll(null)
      }
    }
  }, [textInput, groupId, idsOfGroup])
  const handleChangeKeyword = (e) => {
    let value = e?.target?.value
    setTextInput(value)
  }
  const handleActiveSuggestGroup = (value) => {
    setGroupId(value)
  }

  const GetListGroupPlaceType = () => {
    apiGroupPlaceType.current = ApiTool.get(UrlConfig.placeTypeGroup.listAll, (res) => {
      let placeTypeGroups = [];
      if (res?.code == CodeEnum.ok) {
        placeTypeGroups = res?.result
      }
      else {
        AppTool.alert(res?.message)
      }
      setListGroupPlaceType(placeTypeGroups)
    })
  }
  useEffect(() => {
    GetListGroupPlaceType()
    return () => apiGroupPlaceType.current.cancel()
  }, [])

  const handleSearchSuggestPlace = (suggest) => (e) => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: suggest?.name, type: suggest?.id })
    })
    setClosePopoverState(true)
  }

  const handleToBackListGroupPlaceType = () => {
    setTextInput("")
    setGroupId("")
  }

  return (
    <div className="showMoreSuggestPlaceCpn">
      <div className='boxSearchContainer'>
        <TextInput
          name="searchGroupPlaceType"
          value={textInput}
          placeholder={Resource.categoryList.searchType}
          suffix={<img src={LinkIconConfig.suggestPlaceChip.searchIcon} alt="icon-search"></img>}
          prefix={groupId ? <div className="iconBackShowMoreSuggest" onClick={handleToBackListGroupPlaceType}>{ICON_BACK}</div> : null}
          noneBorder
          onChange={handleChangeKeyword}
          className="searchInput"
        />
        <HorizontalLine className="horizontalLine" />
      </div>
      {
        groupId ?
          <>
            {
              idsSearchOfGroup && idsSearchOfGroup?.length == 0 &&
              <div className="notFoundSearch">{Resource.common.notFound}</div>
            }
            <div className="listPlaceType">
              {
                idsSearchOfGroup?.length > 0 && idsSearchOfGroup.map((id, index) => {
                  return (
                    <div key={id} className="itemPlaceType" onClick={handleSearchSuggestPlace(placeTypeDict[id])}>{placeTypeDict[id]?.name}</div>
                  )
                })
              }
            </div>
          </>
          :
          <>
            {
              textInput ?
                <>
                  {
                    idsOfSearchAll && idsOfSearchAll?.length == 0 &&
                    <div className="notFoundSearch">{Resource.common.notFound}</div>
                  }
                  <div className="listPlaceType">
                    {
                      idsOfSearchAll?.length > 0 && idsOfSearchAll.map((id, index) => {
                        return (
                          <div key={id} className="itemPlaceType" onClick={handleSearchSuggestPlace(placeTypeDict[id])}>{placeTypeDict[id]?.name}</div>
                        )
                      })
                    }
                  </div>
                </>
                :
                <div className="listGroupPlaceType">
                  {
                    listGroupPlaceType?.map((item, index) => {
                      return (
                        <div
                          key={item?.id}
                          className="itemGroupPlaceType"
                          onClick={() => {
                            handleActiveSuggestGroup(item?.id)
                          }}
                        >
                          <img className="itemIcon" src={item?.icon} alt={item?.id} />
                          <div className="itemName">
                            <div>{item?.name}</div>
                            <div>{ICON_DETAIL_MORE}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
            }
          </>
      }
    </div>
  );
};

ShowMoreSuggestPlace.propTypes = {
  //
};

export default ShowMoreSuggestPlace;
