import { AppConfig } from "./appConfig";

export const UrlConfig = {
    ip: {
        info: AppConfig.map4dApiDomain + "/map/ip/info",
    },

    auth: {
        logout: `${window.location.origin}/Auth/Logout`,
        login: `${window.location.origin}/Auth/Login`,
    },

    poiEdit: {
        create: `${AppConfig.map4dApiDomain}/map/v3/place-edit/createV3`,
        requestDelete: AppConfig.map4dApiDomain + '/map/v3/place-edit/delete-place-v3',
    },

    roadEdit: {
        create: `${AppConfig.map4dApiDomain}/map/v2/road-edit/update-basic`,
        requestDelete: `${AppConfig.map4dApiDomain}/map/v2/road-edit/delete`,
    },

    boundaryEdit: {
        create: `${AppConfig.map4dApiDomain}/map/region-edit`,
        requestDelete: `${AppConfig.map4dApiDomain}/map/region-edit/delete`,
    },

    poi: {
        autosuggest: AppConfig.map4dApiDomain + "/map/autosuggest",
        detail: AppConfig.map4dApiDomain + "/map/place/detail/{id}",
        search: AppConfig.map4dApiDomain + "/map/v2/search/place",
        basicDetail: AppConfig.map4dApiDomain + "/map/place/{id}",
        getListByTag: `${AppConfig.map4dApiDomain}/map/place/get-place-by-tags-paging`,
        searchByText: `${AppConfig.map4dApiDomain}/map/place/text-search`,
        aroundLocation: `${AppConfig.map4dApiDomain}/map/place/around-locations`,
    },

    place: {
        request: AppConfig.map4dApiDomain + '/map/v3/place-edit',
        nearbySearch: AppConfig.map4dApiDomain + "/map/place/nearby-search",
        alongRoute: AppConfig.map4dApiDomain + "/map/place/along-route",
    },

    placeGroup: {
        getByUser: AppConfig.map4dApiDomain + '/map/place-group',
        create: AppConfig.map4dApiDomain + '/map/place-group',
        saveToGroup: AppConfig.map4dApiDomain + '/map/place-group/save-place-to-list-group',
        updatePlaceDescription: AppConfig.map4dApiDomain + '/map/place-group/update-place-description',
        groupDetail: AppConfig.map4dApiDomain + '/map/place-group/detail/{id}',
        updateEntirety: AppConfig.map4dApiDomain + '/map/place-group/update-entirety-place-group',
        getSavedGroupsOfPlace: AppConfig.map4dApiDomain + "/map/place-group/get-list-by-placeid",
        deleteGroup: AppConfig.map4dApiDomain + '/map/place-group/{id}'
    },

    objectEdit: {
        create: `${AppConfig.map4dApiDomain}/map/v3/object-edit`,
        requestDelete: AppConfig.map4dApiDomain + "/map/v3/object-edit/delete-object"
    },

    object: {
        detail: AppConfig.map4dApiDomain + "/map/object/{id}",
        search: `${AppConfig.map4dApiDomain}/map/object/search`,
        route: `${AppConfig.map4dApiDomain}` + "/map/route",
        createMulti: AppConfig.map4dApiDomain + "/map/manage/object/list",
    },

    placeType: {
        detail: `${AppConfig.map4dApiDomain}/map/place-type/{id}`,
        getListSearch: `${AppConfig.map4dApiDomain}/map/place-type/list`,
        getList: `${AppConfig.map4dApiDomain}/map/place-type/get-list`,
        getListByGroupId: `${AppConfig.map4dApiDomain}/map/place-type/list-of-group/{groupId}`,
    },

    model: {
        list: `${AppConfig.map4dApiDomain}/map/model/all`,
    },

    tag: {
        filter: `${AppConfig.map4dApiDomain}/map/hash-tag/filter`,
        create: `${AppConfig.map4dApiDomain}/map/hash-tag`,
        createMany: `${AppConfig.map4dApiDomain}/map/hash-tag/create-many`,
        getByName: `${AppConfig.map4dApiDomain}/map/hash-tag/get-hashtag-by-names`,
    },

    file: {
        image: `${AppConfig.map4dApiDomain}/map/file/image`,
        listImage: `${AppConfig.map4dApiDomain}/map/file/list-images`,
        uploadModelFile: `${AppConfig.map4dApiDomain}/map/file/obj`,
        uploadTextureFile: `${AppConfig.map4dApiDomain}/map/file/texture`,
        listMedia: `${AppConfig.map4dApiDomain}/map/file/upload/list-media`,
    },

    geocode: {
        geocode: `${AppConfig.map4dApiDomain}/map/geocode`,
        getAddressComponents: `${AppConfig.map4dApiDomain}/map/geocode/suggest-address-component`
    },

    userLabel: {
        get: AppConfig.map4dApiDomain + '/map/user-label',
        update: AppConfig.map4dApiDomain + '/map/user-label/update-default-label',
        delete: AppConfig.map4dApiDomain + '/map/user-label/delete-default-label/{userLabelType}'
    },

    reportError: {
        add: AppConfig.map4dApiDomain + '/map/report-error'
    },

    country: {
        getAllProvince: AppConfig.map4dApiDomain + "/map/country/province/all",
        getAllDistrict: AppConfig.map4dApiDomain + "/map/country/district",
        getAllSubdistrict: AppConfig.map4dApiDomain + "/map/country/subdistrict",
        getListCenterProvince: AppConfig.map4dApiDomain + "/map/country/province/get-list"
    },

    essentialService: {
        detail: AppConfig.map4dApiDomain + "/map/pec/{id}",
        search: AppConfig.map4dApiDomain + "/map/pec/search"
    },

    user: {
        detail: AppConfig.map4dApiDomain + "/map/user/info",
    },

    placeTypeGroup: {
        listAll: AppConfig.map4dApiDomain + "/map/place-type-group/all",
        search: AppConfig.map4dApiDomain + "/map/place-type-group/list",
    },
    direction: {
        route: AppConfig.map4dApiDomain + "/map/route/route-duration-vehicle",
    },
    searchHistory: {
        save: AppConfig.map4dApiDomain + "/map/place/history-search",
        get: AppConfig.map4dApiDomain + "/map/place/history-search"
    }
};
