import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'ui';
import { ImageConfig, UrlConfig } from '../../../config';
import { CodeEnum, MapEventEnum, MapTypeEnum } from '../../../enum';
import { ApiTool, GeoJsonTool, StringTool } from '../../../tool';
import DialogAction from '../../dialogAction/dialogAction';
import DialogBody from '../../dialogBody/dialogBody';
import DialogHeader from '../../dialogHeader/dialogHeader';
import MapView from '../mapView/mapView';
import { LinkIconConfig } from './../../../config/linkIconConfig';
import { Resource } from './../../../resource';
import Dialog from './../../dialog/dialog';
import "./locationInputV2.scss";
import TooltipV2 from './../../tooltipV2/tooltipV2';
import { GeometryTypeEnum } from './../../../enum/geometryTypeEnum';

const ICON_CAN_FULLSCREEN =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M15,3h6V8H19V5H15ZM9,3V5H5V8H3V3Zm6,18V19h4V16h2v5ZM9,21H3V16H5v3H9Z" fill="#505050" />
  </svg>

const ICON_EXIST_FULLSCREEN =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M15.926,7h4V9h-6V3h2ZM8,9H2V7H6V3H8Zm7.926,8v4h-2V15h6v2ZM8,15v6H6V17H2V15Z" transform="translate(1.037)" fill="#505050" />
  </svg>

const MapTypeOption = [
  {
    name: "Roadmap",
    value: MapTypeEnum.roadmap,
  },
  {
    name: "Satellite",
    value: MapTypeEnum.satellite,
  },
  {
    name: "Map3D",
    value: MapTypeEnum.map3d,
  }
]

const STROKE_WIDTH_POLYGON = 1
const STROKE_WIDTH_POLYLINE = 7
const STROKE_COLOR_POLYLINE = '#508FF4'
const STROKE_COLOR_POLYGON = '#E40000'
const FILL_COLOR = '#000000'
const FILL_OPACITY = 0.2
const STROKE_OPACITY = 1

const worldCoords = [[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]
function LocationInputV2(props) {
  const { value, onChange, onChangeSuggest, isShowMapType, isShowControl, canFullScreen, geometry } = props

  const htmlMapDialog = useRef()
  const htmlMapInput = useRef()
  const suggestApi = useRef()
  const multiPolygonDialogRef = useRef([])
  const multiPolylineDialogRef = useRef([])

  const multiPolygonInputRef = useRef([])
  const multiPolylineInputRef = useRef([])

  const [openDialog, setOpenDialog] = useState(false)
  const [valueState, setValueState] = useState(null)

  const [initMapInput, setInitMapInput] = useState(false)
  const [initMapDialog, setInitMapDialog] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [mapTypeSelected, setMapTypeSelected] = useState(MapTypeEnum.roadmap)

  useEffect(() => {
    setValueState(value)
  }, [value])

  useEffect(() => {
    suggestAddress(valueState)
    return () => {
      suggestApi.current?.cancel()
    }
  }, [valueState])

  useEffect(() => {
    if (initMapDialog && geometry) {
      drawPoly(geometry, htmlMapDialog.current)
    }
  }, [geometry, initMapDialog])

  useEffect(() => {
    if (initMapInput && geometry) {
      drawPoly(geometry, htmlMapInput.current)
    }
  }, [geometry, initMapInput])

  useEffect(() => {
    return () => {
      removePoly(multiPolygonDialogRef.current, multiPolylineDialogRef.current)
      removePoly(multiPolygonInputRef.current, multiPolylineInputRef.current)
    }
  }, [geometry])

  useEffect(() => {
    if (valueState && initMapInput) {
      onSetCenter(valueState, htmlMapInput?.current)
    }
  }, [valueState, initMapInput])

  useEffect(() => {
    if (valueState && initMapDialog) {
      onSetCenter(valueState, htmlMapDialog?.current)
    }
  }, [valueState, initMapDialog])

  const suggestAddress = (location) => {
    if (location) {
      let body = {
        lat: location?.lat,
        lng: location?.lng
      }
      suggestApi.current = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          onChangeSuggest && onChangeSuggest(res?.result?.address)
        }
        else {
          onChangeSuggest && onChangeSuggest('')
        }
      })
    }
  }

  const onSetCenter = (location, mapRef) => {
    let camera = mapRef?.getCamera()
    camera?.setTarget(location)
    mapRef?.moveCamera(camera, { animate: true })
  }

  const onOpenDialog = () => {
    setOpenDialog(true)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const onCreateMapInput = (map) => {
    htmlMapInput.current = map
    setInitMapInput(true)
    let camera = map?.getCamera()
    camera?.setZoom(17)
    map?.moveCamera(camera)
  }

  const onCreateMapDialog = (map) => {
    htmlMapDialog.current = map
    setInitMapDialog(true)
  }

  const onSave = () => {
    let center = htmlMapDialog.current?.getCamera()?.getTarget()
    let locationNew = {
      lat: center?.lat,
      lng: center?.lng
    }
    onSetCenter(locationNew, htmlMapInput.current)
    setValueState(locationNew)
    setOpenDialog(false)
    suggestAddress(locationNew)
    onChange && onChange(locationNew)
  }

  const onClickFullscreen = () => {
    setIsFullScreen((prev) => {
      return !prev
    })
  }

  const onClickMapTypeOption = (option) => (e) => {
    setMapTypeSelected(option?.value)
    htmlMapDialog?.current?.setMapType(option?.value)
  }

  const handleZoomOut = (e) => {
    let camera = htmlMapDialog.current?.getCamera();
    camera.setZoom(camera.getZoom() - 1);
    htmlMapDialog.current?.moveCamera(camera, { animate: true })
  }

  const handleZoomIn = (e) => {
    let camera = htmlMapDialog.current?.getCamera();
    camera.setZoom(camera.getZoom() + 1);
    htmlMapDialog.current?.moveCamera(camera, { animate: true })
  }

  const removePoly = (multiPolygon, multiPolyline) => {
    multiPolygon?.forEach(path => {
      path?.setMap(null)
    })
    multiPolyline?.forEach(path => {
      path?.setMap(null)
    })
  }

  const drawPoly = (geometry, mapRef) => {
    if (geometry) {
      switch (geometry.type) {
        case GeometryTypeEnum.lineString:
          drawMultiPolyline(geometry, mapRef)
          break
        case GeometryTypeEnum.polygon:
          drawMultiPolygon(geometry || [], mapRef)
          break
        case GeometryTypeEnum.multiLineString:
          drawMultiPolyline(geometry || [], mapRef)
          break
        case GeometryTypeEnum.multiPolygon:
          drawMultiPolygon(geometry || [], mapRef)
          break
      }
    }
  }

  const drawMultiPolyline = (data, mapRef) => {
    if (data.coordinates && data.coordinates.length > 0) {
      if (data?.type == GeometryTypeEnum.multiLineString) {
        multiPolylineDialogRef.current = []
        multiPolylineInputRef.current = []
        data.coordinates?.forEach(path => {
          let polyline = new map4d.Polyline({
            path: path,
            strokeColor: STROKE_COLOR_POLYLINE,
            strokeOpacity: STROKE_OPACITY,
            strokeWidth: STROKE_WIDTH_POLYLINE,
          })
          multiPolylineDialogRef.current.push(polyline)
          multiPolylineInputRef.current.push(polyline)
          polyline?.setMap(mapRef)
        })
      }

      else {
        multiPolylineDialogRef.current = []
        multiPolylineInputRef.current = []
        let polyline = new map4d.Polyline({
          path: data?.coordinates,
          strokeColor: STROKE_COLOR_POLYLINE,
          strokeOpacity: STROKE_OPACITY,
          strokeWidth: STROKE_WIDTH_POLYLINE,
        })
        multiPolylineDialogRef.current.push(polyline)
        multiPolylineInputRef.current.push(polyline)
        polyline?.setMap(mapRef)
      }

    }
  }

  const drawMultiPolygon = (data, mapRef) => {
    if (data?.coordinates && data?.coordinates.length > 0) {
      if (data?.type == GeometryTypeEnum.multiPolygon) {
        multiPolygonDialogRef.current = []
        multiPolygonInputRef.current = []
        let holePaths = []
        data.coordinates?.forEach(path => {
          holePaths.push(path[0])
        })
        let polygon = new map4d.Polygon({
          paths: [worldCoords, ...holePaths],
          fillColor: FILL_COLOR,
          fillOpacity: FILL_OPACITY,
          strokeWidth: STROKE_WIDTH_POLYGON,
          strokeColor: STROKE_COLOR_POLYGON,
        })
        multiPolygonDialogRef.current.push(polygon)
        multiPolygonInputRef.current.push(polygon)
        polygon?.setMap(mapRef)
      }
      else {
        multiPolygonDialogRef.current = []
        multiPolygonInputRef.current = []
        let polygon = new map4d.Polygon({
          paths: [worldCoords, data?.coordinates],
          fillColor: FILL_COLOR,
          fillOpacity: FILL_OPACITY,
          strokeWidth: STROKE_WIDTH_POLYGON,
          strokeColor: STROKE_COLOR_POLYGON,
        })
        multiPolygonDialogRef.current.push(polygon)
        multiPolygonInputRef.current.push(polygon)
        polygon?.setMap(mapRef)
      }
    }
  }

  return (
    <>
      <div className='locationInputCpn'>
        <div className={valueState ? 'boxInputMap' : 'boxInputMapOpacity'}>
          <MapView width='100%' height='100%' onLoadedMap={onCreateMapInput} />
          {
            valueState ?
              <>
                <div className='editLocationBtn' onClick={onOpenDialog}>
                  <img src={LinkIconConfig.editGrey} alt='edit' width={18} height={18} />
                  <span className='text'>{Resource.common.edit}</span>
                </div>
                <div className='markerCenter'>
                  <img src={ImageConfig.location.marker} width={30} height={36} />
                </div>
              </>
              :
              <div className='editLocationBtn' onClick={onOpenDialog}>
                {Resource.location.editMap}
              </div>
          }
        </div>
      </div>

      <Dialog
        className='locationInputDialog'
        open={openDialog}
        onBack={onCloseDialog}
        disableCloseOnClickBackdrop
      >
        <DialogHeader
          showBack
          hideClose
        >
          {Resource.location.title}
        </DialogHeader>

        <DialogBody>
          <div className={StringTool.mergeClassName('popupMap', isFullScreen ? 'popupFullScreen' : '')}>
            <MapView width='100%' height='100%' onLoadedMap={onCreateMapDialog} />
            <div className='markerCenter'>
              <img src={ImageConfig.location.marker} width={30} height={36} />
            </div>
            {
              canFullScreen &&
              <div className='canFullScreen'>
                <TooltipV2 description={isFullScreen ? 'Exist Fullscreen' : 'Fullscreen'} anchor='left'>
                  <div className='btnAction' onClick={onClickFullscreen}>
                    {isFullScreen ? ICON_EXIST_FULLSCREEN : ICON_CAN_FULLSCREEN}
                  </div>
                </TooltipV2>
              </div>
            }
            {
              isShowMapType &&
              <div className='mapTypeSelect'>
                {
                  MapTypeOption.map((option) => {
                    let isActive = (mapTypeSelected == option?.value)
                    return (
                      <div
                        key={option?.value}
                        className={StringTool.mergeClassName('mapTypeOption', isActive ? 'mapTypeActive' : '')}
                        onClick={onClickMapTypeOption(option)}
                      >
                        {option?.name}
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              isShowControl &&
              <div className='mapControl'>
                <TooltipV2 description={Resource.common.zoomIn}>
                  <div className='btnZoomAction' onClick={handleZoomIn}>
                    <img src={LinkIconConfig.zoomIn} height={24} width={24} />
                  </div>
                </TooltipV2>

                <TooltipV2 description={Resource.common.zoomOut}>
                  <div className='btnZoomAction' onClick={handleZoomOut}>
                    <img src={LinkIconConfig.zoomOut} height={24} width={24} />
                  </div>
                </TooltipV2>
              </div>
            }
          </div>
        </DialogBody>

        <DialogAction
          style={{
            display: 'flex',
            gap: '0.75rem'
          }}
        >
          <Button onClick={onCloseDialog} variant='cancel' size='small'>
            {Resource.button.cancel}
          </Button>
          <Button onClick={onSave} variant='done' size='small'>
            {Resource.button.done}
          </Button>
        </DialogAction>
      </Dialog>
    </>
  )
};

LocationInputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isShowMapType: PropTypes.bool,
  isShowControl: PropTypes.bool,
  canFullScreen: PropTypes.bool,
  geometry: PropTypes.any
};

export default LocationInputV2;
