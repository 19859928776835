import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import './collapse.scss';

const TIMEOUT_DEFAULT = 400

const Collapse = (props) => {
  const { open, timeout, children, minHeight = 0 } = props
  const [openState, setOpenState] = useState(open)
  const [duration, setDuration] = useState(TIMEOUT_DEFAULT)
  const containerRef = useRef()
  const [height, setHeight] = useState(minHeight)

  useEffect(() => {
    setOpenState(open)
  }, [open])

  useEffect(() => {
    let timeoutNew = TIMEOUT_DEFAULT
    if (timeout || timeout == 0) {
      timeoutNew = timeout
    }
    setDuration(timeout)
  }, [timeout])

  useEffect(() => {
    const handle = () => {
      if (openState) {
        let heightNew = containerRef.current?.getBoundingClientRect()?.height || 0
        setHeight(heightNew)
      }
      else {
        setHeight(minHeight)
      }
    }
    handle()
    let event = new ResizeObserver(handle)
    event.observe(containerRef.current)
    return () => {
      containerRef.current && event.unobserve(containerRef.current)
      event.disconnect()
    }
  }, [openState, minHeight])

  return (
    <div
      className='collapseCpn'
      style={{
        transition: `all ${duration}ms ease`,
        height: height
      }}
    >
      <div
        className='collapseWrap'
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  )
}

Collapse.propTypes = {
  open: PropTypes.bool,
  timeout: PropTypes.number,
  minHeight: PropTypes.any
};

export default Collapse;
