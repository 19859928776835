import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import "./tooltipV2.scss";
import * as ReactDOM from "react-dom";
import { useState } from 'react';
import dialogPolyfill from 'dialog-polyfill'
import { useIsMobileScreen } from './../../useHooks/useIsMobileScreen';
import { StringTool } from '../../tool';

const TooltipV2 = (props) => {
  const { children, description, title, anchor, isWap } = props

  const isMobile = useIsMobileScreen()

  const childHtml = useRef()
  const dialogHtml = useRef()
  const [parent, setParent] = useState()
  const [styleDialog, setStyleDialog] = useState({
    left: '-1000px',
    top: '-1000px',
  })
  const calculate = () => {
    const { innerWidth, innerHeight } = window;
    const { top, bottom, left, right, width, height } = childHtml.current?.getBoundingClientRect() || {}
    const center = {
      x: left + width / 2,
      y: top + height / 2
    }

    const padding = {
      top,
      bottom: innerHeight - bottom,
      left,
      right: innerWidth - right
    }
    const sortPositions = Object.keys(padding).sort((a, b) => {
      return (padding[a] - padding[b])
    })
    let position = sortPositions?.[sortPositions?.length - 1]
    const space = 2
    const action = {
      "top": {
        bottom: `calc(100% - ${top}px)`,
        left: center.x + "px",
        transform: `translate(-50%, -${space}px)`,
        maxWidth: `calc(100% - ${Math.abs(left - padding.right)}px)`,
        maxHeight: `${top - space}px`
      },
      "bottom": {
        top: bottom + "px",
        left: center.x + "px",
        transform: `translate(-50%, ${space}px)`,
        maxWidth: `calc(100% - ${Math.abs(left - padding.right)}px)`,
        maxHeight: `${padding.bottom - space}px`,
      },
      "left": {
        left: `${left}px`,
        top: center.y + "px",
        transform: `translate(calc(-100% - ${space}px), -50%)`,
        maxHeight: `calc(100% - ${Math.abs(top - padding.bottom)}px)`,
        maxWidth: `${left - space}px`
      },
      "right": {
        left: right + "px",
        top: center.y + "px",
        transform: `translate(${space}px, -50%)`,
        maxHeight: `calc(100% - ${Math.abs(top - padding.bottom)}px)`,
        maxWidth: `${padding.right - space}px`
      }
    }
    setStyleDialog(action[anchor || position])
  }

  useEffect(() => {
    let observer
    calculate()
    if (childHtml.current) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            calculate()
          }
        }
      });

      // Thiết lập các tùy chọn cho observer và bắt đầu quan sát phần tử
      const observerOptions = {
        attributes: true,
        attributeFilter: ['style'],
        childList: false,
        subtree: false,
      };
      observer.observe(childHtml.current, observerOptions);
    }

    return () => {
      observer?.disconnect();
    }
  }, [children])

  const onMouseEnter = () => {
    children?.props?.onMouseEnter && children?.props?.onMouseEnter()
    dialogHtml?.current?.show()
    calculate()
  }

  const ref = (r) => {
    children?.props?.ref && children?.props?.ref()
    if (r instanceof HTMLElement) {
      childHtml.current = r
      let parentNew = r?.closest("dialog")
      if (!parentNew) {
        parentNew = document.body
      }
      setParent(parentNew)
    }

  }

  const onMouseLeave = () => {
    children?.props?.onMouseLeave && children?.props?.onMouseLeave()
    dialogHtml.current?.open && dialogHtml?.current?.close?.()
    setStyleDialog({
      left: '-1000px',
      top: '-1000px',
    })
  }

  const cloneChild = React.cloneElement(children, {
    onMouseEnter: onMouseEnter,
    ref: ref,
    onMouseLeave: onMouseLeave,
  })

  const handleRef = (r) => {
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    <>
      {cloneChild}
      {
        parent &&
        ReactDOM.createPortal(
          <dialog
            ref={handleRef}
            className={StringTool.mergeClassName('tooltipV2', isMobile ? 'tooltipMobile' : '')}
            style={styleDialog}
          >
            <div className='descriptionTooltip' style={{ whiteSpace: isWap ? 'pre-wrap' : 'nowrap' }}>{description || title || ""}</div>
          </dialog>
          , parent
        )
      }
    </>
  )
};

TooltipV2.propTypes = {
  description: PropTypes.any,
  title: PropTypes.any,
  anchor: PropTypes.oneOf(["top", "bottom", "right", "left"]),
  isWap: PropTypes.bool
};

export default TooltipV2;
