import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { UrlConfig } from '../../config';
import { CodeEnum, DirectionOptionTypeEnum } from '../../enum';
import { ApiTool } from '../../tool';

const GetInfoForPositionDirection = (props) => {
  const { position, onFinishGeoCode, id, type } = props
  useEffect(() => {
    let timeOut
    let api
    if (type == DirectionOptionTypeEnum.position && position) {
      let body = {
        lat: position?.lat,
        lng: position?.lng
      }
      timeOut = setTimeout(() => {
        api = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            onFinishGeoCode && onFinishGeoCode(res?.result)
          }
          else {
            onFinishGeoCode && onFinishGeoCode({})
          }
        })
      }, 300)
    }

    return () => {
      (timeOut != undefined) && clearTimeout(timeOut)
      api?.cancel()
    }

  }, [position?.lat, position?.lng, type])
  useEffect(() => {
    let timeOut
    let api
    if ((type == DirectionOptionTypeEnum.poi||type == DirectionOptionTypeEnum.history) && id) {

      timeOut = setTimeout(() => {
        api = ApiTool.get(UrlConfig.poi.detail.replace("{id}", id), (res) => {
          if (res?.code == CodeEnum.ok) {
            onFinishGeoCode && onFinishGeoCode(res?.result)
          }
          else {
            onFinishGeoCode && onFinishGeoCode({})
          }
        })
      }, 300)
    }

    return () => {
      (timeOut != undefined) && clearTimeout(timeOut)
      api?.cancel()
    }

  }, [type, id])
  return null
};

GetInfoForPositionDirection.propTypes = {
  position: PropTypes.any,
  onFinishGeoCode: PropTypes.func,
  id: PropTypes.any,
  type: PropTypes.any
};

export default GetInfoForPositionDirection;
