import React from 'react';
import HideSideBar from '../hideSideBar/hideSideBar';
import RequestUpdatePoi from '../requestUpdatePoi/requestUpdatePoi';
import { useQuery } from './../../useHooks';
import AddPoi from './../addPoi/addPoi';
import "./addPoiControl.scss";
import StreetRequestUpdate from '../streetRequestUpdate/streetRequestUpdate'
import BoundaryRequestUpdate from '../boundaryRequestUpdate/boundaryRequestUpdate';

const AddPoiControl = () => {

  const { cp, ep, typeGeometry } = useQuery()

  return (

    <>
      {
        (cp || ep) &&
        <HideSideBar />
      }
      {
        cp &&
        <AddPoi />
      }
      {
        ep &&
        <>
          {
            (typeGeometry == 1 || typeGeometry == 2)
              ? <>
                {
                  typeGeometry == 1 && <StreetRequestUpdate />
                }
                {
                  typeGeometry == 2 && <BoundaryRequestUpdate />
                }
              </>
              : <RequestUpdatePoi />
          }
        </>
      }

    </>
  )
};

AddPoiControl.propTypes = {
  //
};

export default AddPoiControl;
