import { CookieTool } from "./cookieTool";
import axios from "axios";
import { CookieEnum, EnvironmentEnum, StorageEnum } from "../enum";
import { AppTool } from "./appTool";
import { UrlConfig } from "../config/urlConfig";
import { AppData } from "../data";
import { RoleEnum } from "./../enum/roleEnum";

export const defaultToken =
  "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI1WkpaUl9rYVZPX2lPNlU5eWEzOFZnMkVwREpZWEhqdC1Fb1k4WjRQX2xNIn0.eyJqdGkiOiJlNjJjMzVlMS1kYjQ5LTRlYzEtOTk3MS0wNjMyOGY5YzYwNGMiLCJleHAiOjE2OTEwMzM1NDUsIm5iZiI6MCwiaWF0IjoxNjkwMTY5NTQ1LCJpc3MiOiJodHRwczovL2FjY291bnRzLWRldi5tYXA0ZC52bi9hdXRoL3JlYWxtcy9tYXA0ZC1kZXYiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiYzNiYjg3YjAtZjE0Yy00NGE5LWE4NGMtNTc3NWYyMWUyOGQ2IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibWFwNGQtZGV2IiwiYXV0aF90aW1lIjoxNjkwMTY5NTQ1LCJzZXNzaW9uX3N0YXRlIjoiYTQyNTlhMWItYzA2My00MGU5LWEzMmQtNzIxOGU5MzFiMzBhIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL3Rlc3QubWFwNGQudm4iLCJodHRwOi8vbG9jYWxob3N0OjUzNDUwIiwiaHR0cDovL2xvY2FsaG9zdDozMDAwIiwiaHR0cHM6Ly9kZXYubWFwNGQudm4iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGhvbmUiOiIwODMyMDgxNzk5IiwibmFtZSI6IkRldiBMw6oiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJkZXZAZ21haWwuY29tIiwibG9jYWxlIjoidmkiLCJnaXZlbl9uYW1lIjoiRGV2IiwiZmFtaWx5X25hbWUiOiJMw6oiLCJlbWFpbCI6ImRldkBnbWFpbC5jb20ifQ.eEajClCeNIxF2PAyM4_QRo6apzcfAUeGUnkiLVqKdePCRuxkUQNeX-USY86Kn6-k62jy79TEmhUjdl5StaobTAxuMEaaLiDcHnJRT_dwoKBD4n-_xSji0BYQIriprgFYKA2plKhwfNrBjVwDgK4xOYqI-2Da7Cb_GJGmI1jccRjDKDpP_lVn4qSNXAb9GQrND24cjKyB_TPsoheZQcJm7zJbG-weK3rddofMTKkNKD7nzhN5wC_Cfdcdv6tCI4FYf1YZ9VjWpFlVu8don14KIac0HiZIKVkSMBrO6svC82_6Ke79M0Mu5EJGKE47qcDIaF84YXco0d0XYixQPHAVpg";

export class UserTool {
  static getToken = (callback) => {
    if (AppTool.isLocal()) {
      callback(defaultToken);
      return;
    }
    let source = axios.CancelToken.source();
    let token = CookieTool.get(CookieEnum.token);
    if (token) {
      callback(token);
    } else {
      let returnUrl = encodeURI(location.href);
      location.href = UrlConfig.auth.login + "?returnUrl=" + returnUrl;
      callback(null);
    }
    return source;
  };

  static getUser = () => {
    let user = AppData.user;
    return user;
  };

  static isOneOfRoles = (roleCodes) => {
    return roleCodes.some((code) =>
      UserTool.getUser()?.roles?.some((role) => role.code == code)
    );
  };

  static isAdmin = () => {
    return AppData.user?.roles?.some((role) => role?.code == RoleEnum.admin);
  };

  static isPermission = (permission) => {
    let check = AppData?.user?.roles?.some((role) => {
      return role?.permissionGroups?.includes(permission);
    });
    return check || UserTool.isAdmin();
  };
  static hasPermission = () => {
    let pers = [];
    AppData?.user?.roles?.forEach((role) => {
      pers = pers.concat(role.permissionGroups || []);
    });
    return pers?.length >= 1 || UserTool.isAdmin();
  };
}
