import React from "react";
import { Button, Test } from "ui";
import StreetInput from "./streetInput/streetInput";
import StreetInputV2 from "./streetInputV2/streetInputV2";

const Toan = () => {
  return (
    <StreetInputV2 />
  );
};
export default Toan;
