import { atom } from 'recoil';
import { PlaceExtensionTypeEnum } from './../enum/placeExtensionTypeEnum';

export const PlaceExtendState = atom({
    key: 'PlaceExtendState',
    default: {
        show: false,
        metaData: []
    },
});

export const ImageGalleryState = atom({
    key: 'ImageGalleryState',
    default: {
        show: false,
        photosData: []
    },
});

export const DataExtendState = atom({
    key: 'DataExtendState',
    default: {
        show: false,
        dataExtend: [],
        indexSelect: 0,
        typeData: PlaceExtensionTypeEnum.none
    },
});

export const DisableMyLocationState = atom({
    key: 'DisableMyLocationState',
    default: false
});

export const ShowCameraControlState = atom({
    key: "ShowCameraControlState",
    default: false
})

export const PlaceRelatedState = atom({
    key: "PlaceRelatedState",
    default: { show: false, data: [] }
})