import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ShowPopoverMoreState } from '../../appState';
import { StringTool } from '../../tool';
import { useWindowSize } from '../../useHooks';
import './dragToScrollV2.scss';

const ICON_NEXT = <svg id="Component_761_1" data-name="Component 761 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,0H24V24H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z" transform="translate(0 24) rotate(-90)" fill="#869195" />
</svg>
const ICON_PREV = <svg id="Component_759_2" data-name="Component 759 – 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,24H24V0H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M1.41,7.41,6,2.83l4.59,4.58L12,6,6,0,0,6Z" transform="translate(8 18) rotate(-90)" fill="#869195" />
</svg>
const DragToScrollV2 = (props) => {
  const { children, distance = 200, delta} = props;
  const containerRef = useRef()
  const isDownRef = useRef()
  const startX = useRef()
  const startScrollLeft = useRef()
  const windowSize = useWindowSize()
  const setIsClose = useSetRecoilState(ShowPopoverMoreState)

  const [isNext, setIsNext] = useState(false)
  const [isPrev, setIsPrev] = useState(false)

  useEffect(() => {
    const handleMouseUp = () => {
      if (isDownRef.current) {
        isDownRef.current = false
      }
    }
    document.addEventListener("mouseup", handleMouseUp)
    return () => {
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])
  useEffect(() => {
    checkIsNext()
  }, [windowSize])

  useEffect(() => {
    isNext && setIsClose(true)
  }, [isNext])

  const checkIsNext = () => {
    let width = containerRef.current?.scrollLeft + containerRef.current?.clientWidth
    let next = width < containerRef.current?.scrollWidth
    setIsNext(next)
  }
  const handleMouseDown = (e) => {
    let position = e?.clientX
    isDownRef.current = true
    startX.current = position
    startScrollLeft.current = containerRef.current.scrollLeft
  }

  const handleMouseMove = (e) => {
    if (isDownRef.current) {
      let delta = e?.clientX - startX.current
      containerRef.current.scrollLeft = (startScrollLeft.current - delta)
    }
  }

  const onNext = () => {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
      containerRef.current.scrollLeft += 10
      scrollAmount += 10
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, 10)
  }

  const onPrev = () => {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
      containerRef.current.scrollLeft -= 10
      scrollAmount += 10
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, 10)
  }

  const onScroll = () => {
    checkIsNext()
    let pre = containerRef.current?.scrollLeft > 0
    setIsPrev(pre)
  }

  const onTransitionEnd=()=>{
    checkIsNext()
  }

  return (
    <div onTransitionEnd={onTransitionEnd} className='dragToScrollCpnV2' style={{ display: windowSize?.width < delta ? "none" : ""}}>
      <div
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onScroll={onScroll}
        className="dragToScrollContainer"
      >
        {children}
      </div>

      {
        isPrev &&
        <div
          className={StringTool.mergeClassName('btnCircleSuggestPlace', 'prevSuggestPlace')}
          onClick={onPrev}
        >
          {ICON_PREV}
        </div>
      }

      {
        isNext &&
        <div
          className={StringTool.mergeClassName('btnCircleSuggestPlace', 'nextSuggestPlace')}
          onClick={onNext}
        >
          {ICON_NEXT}
        </div>
      }
    </div>
  )
};

DragToScrollV2.propTypes = {
  distance: PropTypes.number,
  delta: PropTypes.number
};

export default DragToScrollV2;
