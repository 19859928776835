import React from 'react';
import { Resource } from '../../../resource';
import './mobileNavigationButton.scss';
import PropTypes from 'prop-types';

const NAVIGATION_ICON =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <g><path id="Path_2620" data-name="Path 2620" d="M12,2.5,4.5,20.8l.7.7,6.8-3,6.8,3,.7-.7Z" fill="#FFFFFF" /></g>
  </svg>

function MobileNavigationButton(props) {
  const { onClick } = props

  const onClickBtn = () => {
    onClick && onClick()
  }

  return (
    <div className='mobileNavigationButtonCpn' onClick={onClickBtn}>
      <div className='icon'>
        {NAVIGATION_ICON}
      </div>
      <div className='caption'>
        {Resource.common.navigation}
      </div>
    </div>
  )
};

MobileNavigationButton.propTypes = {
  onClick: PropTypes.func,
};
export default MobileNavigationButton;
