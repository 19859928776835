import styles from "./congratsBanner.css";
import React, { useEffect, useState } from "react";
import { StringTool } from "../../tool";

const ICON_CLOSE =
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Component_843_12" data-name="Component 843 – 12" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_28146" data-name="Rectangle 28146" width="16" height="16" transform="translate(4074 427)" fill="#fff" stroke="#707070" strokeWidth="1" />
      </clipPath>
    </defs>
    <g id="Mask_Group_44260" data-name="Mask Group 44260" transform="translate(-4074 -427)" clipPath="url(#clip-path)">
      <path id="Path_12963" data-name="Path 12963" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(4070 423.143)" fill="#fff" />
    </g>
  </svg>

export interface CongratsBannerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: 'error' | 'success' | 'warning' | 'info' | undefined,
  title?: string,
  subTitle?: string,
  icon?: any,
  open?: boolean | undefined,
  onClose?: (event: any) => void,
  action?: any
};

const CongratsBanner: React.FC<CongratsBannerProps> = ({
  type,
  title,
  subTitle,
  icon,
  open,
  onClose,
  action,
  ...props
}) => {

  const [loadingEnd, setLoadingEnd] = useState(false)
  const [openState, setOpenState] = useState(false)

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.congratsBannerCpn, props.className as string)
  })

  useEffect(() => {
    let isOpen = open as boolean
    setOpenState(isOpen)
    if (isOpen) {
      setLoadingEnd(false)
    }
  }, [open])

  const handleClose = (e: any) => {
    e.stopPropagation()
    setTimeout(() => {
      setOpenState(false)
      onClose && onClose(e)
    }, 300);
    setLoadingEnd(true)
  }

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.congratsBannerCpn, props.className as string))
  }, [props.className])

  return (
    openState ?
      <div {...props} className={StringTool.mergeClassName(className, loadingEnd ? styles.hideAnimation : '')}>
        <div className={styles.backdrop}></div>
        <div className={styles.bannerPopup}>
          <div className={styles.bannerContainer}>
            {
              onClose &&
              <div
                title="Đóng"
                onClick={handleClose}
                className={StringTool.mergeClassName(styles.close, type ? styles[type] : '')}
              >
                {ICON_CLOSE}
              </div>
            }
            <div className={StringTool.mergeClassName(styles.bannerContent, type ? styles[type] : '')}>
              <div className={styles.headerImg}>
                <img src={'/mapAppRoot/image/bgBanner.png'} />
              </div>
              <div className={styles.content}>
                {
                  icon &&
                  <div className={styles.icon}>
                    {icon}
                  </div>
                }
                {
                  title &&
                  <div className={styles.title}>
                    {title}
                  </div>
                }
                {
                  subTitle &&
                  <div className={styles.subTitle}>
                    {subTitle}
                  </div>
                }
              </div>
            </div>
            {
              action &&
              <div className={styles.bannerFooter}>
                {action}
              </div>
            }
          </div>
        </div>
      </div>
      :
      null
  );
};

export default CongratsBanner;
