import { atom } from "recoil";

export const CurrentHeightDraggableBarState = atom({
    key: 'CurrentHeightDraggableBarState',
    default: 0,
});

export const DraggingDraggableBarState = atom({
    key: 'DraggingDraggableBarState',
    default: false,
});

export const FinalHeightDraggableBarState = atom({
    key: 'FinalHeightDraggableBarState',
    default: 0,
});