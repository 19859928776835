import { RouterConfig } from "./routerConfig";

let embedRouter = RouterConfig
embedRouter = JSON.parse(JSON.stringify(embedRouter))


const updateRouter = (config) => {
    Object.keys(config)?.forEach((key) => {
        if ((typeof config[key]) == "string") {
            config[key] = config[key]?.replace("/map", "/embed")
        }
        else {
            updateRouter(config[key])
        }
    })
}

updateRouter(embedRouter)

export const EmbedRouterConfig = embedRouter