import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, createMuiTheme, Drawer, makeStyles, ThemeProvider } from '@material-ui/core';
import { CommonConfig } from '../../../config';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import clsx from "clsx";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    }

  }
})
const drawerWidth = 420;
const useStyles = makeStyles((theme) => ({
  toggleBtn: {
    position: "absolute",
    left: "100%",
    top: `${CommonConfig.padding}px`,
    height: `${CommonConfig.heightSearchBar}px`,
    width: "20px",
    padding: 0,
    minWidth: 0,
    marginLeft: `${CommonConfig.padding}px`,
    boxShadow: theme.shadows[1]
  },
  root: {
    width: `${drawerWidth}px`,
    border: "none",
    overflowY: "unset",
    visibility: "unset !important",
    height: "100%",
    backgroundColor: "transparent"
  },
  drawer: {
    width: "0px",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawShift: {
    width: `${drawerWidth}px`,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

function SideBarDetail(props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  const handleClickToggle = (e) => {
    setIsOpen(!isOpen)
  }
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isOpen}
      PaperProps={{
        elevation: 18
      }}
      className={clsx(classes.drawer, { [classes.drawShift]: isOpen })}
      classes={{
        paperAnchorLeft: classes.root
      }}
    >
      {props.children}

      <ThemeProvider theme={theme}>
        <Box
          boxShadow={1}
          clone
        >
          <Button color="primary" variant="contained" onClick={handleClickToggle} className={classes.toggleBtn}>
            {
              isOpen ?
                <ArrowLeft />
                :
                <ArrowRight />
            }
          </Button>
        </Box>
      </ThemeProvider>
    </Drawer>
  )
}

SideBarDetail.propTypes = {
  children: PropTypes.any
};

export default SideBarDetail;
