import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Resource } from '../../../resource';
import './descriptionDisplay.scss'
import PropTypes from 'prop-types';

const fontSizeDefault = 14
const lineHeightDefault = 18
const lineDefault = 3
const heightDefault = lineHeightDefault * lineDefault

function DescriptionDisplay(props) {
  const { value, onShowAll, onShowEnd , onShowStart} = props

  const containerRef = useRef()
  const heightContentRef = useRef()

  const [valueState, setValueState] = useState()
  const [showMore, setShowMore] = useState(false)
  const [height, setHeight] = useState()

  useEffect(() => {
    setValueState(value)
  }, [value])

  useEffect(() => {
    let currentHeight = containerRef.current?.getBoundingClientRect()?.height
    heightContentRef.current = currentHeight
    if (currentHeight > heightDefault) {
      setShowMore(true)
      setHeight(heightDefault)
    }
  }, [valueState])

  const onClickShowMore = () => {
    setHeight(heightContentRef.current)
    setShowMore(false)
    onShowAll?.()
    onShowStart?.()
    setTimeout(() => {
      onShowEnd?.()
    }, 400);
  }

  const onClickHideLess = () => {
    setHeight(heightDefault)
    setShowMore(true)
  }

  return (
    <div className='descriptionDisplayCpn'>
      <div
        className={'containerContent' + ` ${showMore ? 'textLineClamp' : ''}`}
        ref={containerRef}
        style={{
          transition: `all 400ms ease`,
          fontSize: `${fontSizeDefault}px`,
          lineHeight: `${lineHeightDefault}px`,
          overflow: 'hidden',
          height: height,
        }}
      >
        {valueState}
      </div>
      {
        showMore &&
        <div className='textShowHide' onClick={onClickShowMore}>
          {Resource.button.viewAll}
        </div>
      }
      {
        !showMore && heightContentRef.current > heightDefault &&
        <div className='textShowHide' onClick={onClickHideLess}>
          {Resource.button.hideLess}
        </div>
      }
    </div>
  )
};

DescriptionDisplay.propTypes = {
  onShowAll: PropTypes.func,
  value: PropTypes.any,
  onShowEnd: PropTypes.func,
  onShowStart:PropTypes.func
}

export default DescriptionDisplay;
