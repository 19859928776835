import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'ui';
import { Resource } from '../../resource';
import Dialog from './../dialog/dialog';
import DialogAction from './../dialogAction/dialogAction';
import DialogBody from './../dialogBody/dialogBody';
import './confirmV2.scss'
import { LinkIconConfig } from './../../config/linkIconConfig';

const TYPE_ENUM = {
  error: 'error',
  success: 'success',
  warning: 'warning'
}

const ICON_TYPE = {
  [TYPE_ENUM.error]: LinkIconConfig.confirm.error,
  [TYPE_ENUM.success]: LinkIconConfig.confirm.success,
  [TYPE_ENUM.warning]: LinkIconConfig.confirm.warning
}
function ConfirmV2({ type, open, title, onConfirm, message, onClose, closeOnConfirm = true }) {

  const handleConfirm = () => {
    if (closeOnConfirm) {
      onClose && onClose()
    }
    onConfirm && onConfirm()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepAnother
      className='confirmCpn'
    >
      <DialogBody className='confirmBody'>
        <div className='confirmContainer'>
          {
            type &&
            <div className='iconType'>
              <img src={ICON_TYPE[type]} alt='icon' />
            </div>
          }

          <div className='message'>
            {message}
          </div>
        </div>
      </DialogBody>

      <DialogAction className='confirmAction'>
        <Button variant='closeConfirm' onClick={onClose}>{Resource.button.close}</Button>
        <Button variant='confirm' onClick={handleConfirm}>{Resource.common.confirm}</Button>
      </DialogAction>
    </Dialog>
  )
}

ConfirmV2.propTypes = {
  children: PropTypes.any,
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  closeOnConfirm: PropTypes.bool,
  type: PropTypes.oneOf(['error', 'success', 'warning']),
};

export default ConfirmV2;

