import React, { Component, forwardRef, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { MapTypeAppState } from './../../appState/mapTypeAppState';
import { useEffect } from 'react';
import { AppData } from './../../data/appData';
import { CookieTool } from "../../tool";
import { CookieEnum, MapEventEnum, MapTypeEnum } from '../../enum';
import { CommonConfig } from './../../config/commonConfig';

function MapTypeControl(props, ref) {

  const [mapTypeAppState, setMapTypeAppState] = useRecoilState(MapTypeAppState)

  const isLimitRef = useRef(false)

  useEffect(() => {
    if (mapTypeAppState) {
      AppData.map.setMapType(mapTypeAppState)
      CookieTool.set(CookieEnum.mapType, mapTypeAppState)
    }

  }, [mapTypeAppState])

  useLayoutEffect(() => {
    if (mapTypeAppState != MapTypeEnum.map3d && isLimitRef.current) {
      isLimitRef.current = false
    }
    if(mapTypeAppState==MapTypeEnum.raster){
      AppData.map.setPOIsEnabled(false)
    }
    else{
      AppData.map.setPOIsEnabled(true)
    }
  }, [mapTypeAppState])

  useEffect(() => {
    const eventLimitedZoom = AppData.map.addListener(MapEventEnum.limitedZoom, (args) => {
      let mapType = AppData.map.getMapType()
      if (mapType == MapTypeEnum.map3d && args.zoom == CommonConfig.minZoom3d) {
        if (!isLimitRef.current) {
          isLimitRef.current = true
        }
        else {
          isLimitRef.current = false
          setMapTypeAppState(MapTypeEnum.roadmap)
        }
      }

    })

    const eventIdle = AppData.map.addListener(MapEventEnum.idle, (args) => {
      let mapType = AppData.map.getMapType()
      if ((mapType != MapTypeEnum.map3d || args.camera.getZoom() > 17) && isLimitRef.current) {
        isLimitRef.current = false
      }
    })

    return () => {
      eventLimitedZoom?.remove()
      eventIdle?.remove()
    }
  }, [])

  useImperativeHandle(ref, () => {
    return {
      moveCamera: (camera, option = null) => {
        if (camera?.getZoom() < CommonConfig.minZoom3d && mapTypeAppState == MapTypeEnum.map3d) {
          AppData.map.setMapType(map4d.MapType.roadmap)
          setMapTypeAppState(MapTypeEnum.roadmap)

        }
        AppData.map.moveCamera(camera, option)
      }
    }
  })

  return (
    null
  )
}
MapTypeControl = forwardRef(MapTypeControl)
MapTypeControl.propTypes = {
  //
};
export default MapTypeControl;
