import { JsonTool } from "./jsonTool";
import axios from "axios";
import { CodeEnum } from "../enum";
import { UserTool } from "./userTool";
const cancelRes = {
    code: CodeEnum.cancel,
};
const out = 30000
export class ApiTool {
    static get = (url, callback, isToken = false, timeout = null) => {
        let source = axios.CancelToken.source();
        let config = {
            cancelToken: source.token,
            timeout: timeout || out
        };
        if (isToken) {
            UserTool.getToken((token) => {
                config.headers = { Authorization: `Bearer ${token}` };
                axios
                    .get(url, config)
                    .then(function (response) {
                        setTimeout(() => {
                            callback(response.data);
                        }, 0);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            
                        } else {
                            callback(null);
                        }
                    });
            });
        } else {
            axios
                .get(url, config)
                .then(function (response) {
                    setTimeout(() => {
                        callback(response.data);
                    }, 0);
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        
                    } else {
                        callback(null);
                    }
                });
        }
        return source;
    };
    static queryGetFromJson = (url, body, callback, isToken = false, timeout = null) => {
        let source = axios.CancelToken.source();
        let query = JsonTool.convertJsonToQueryString(body);
        let config = {
            cancelToken: source.token,
            timeout: timeout || out
        };
        if (isToken) {
            UserTool.getToken((token) => {
                config.headers = { Authorization: `Bearer ${token}` };
                axios
                    .get(url + "?" + query, config)
                    .then(function (response) {
                        setTimeout(() => {
                            callback(response.data);
                        }, 0);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            // callback();
                        } else {
                            callback(null);
                        }
                    });
            });
        } else {
            axios
                .get(url + "?" + query, config)
                .then(function (response) {
                    setTimeout(() => {
                        callback(response.data);
                    }, 0);
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                    } else {
                        callback(null);
                    }
                });
        }
        return source;
    };

    static post = (url, body, callback, isToken = false, timeout = null) => {
        let source = axios.CancelToken.source();
        let config = {
            cancelToken: source.token,
            timeout: timeout || out
        };
        if (isToken) {
            UserTool.getToken((token) => {
                config.headers = { Authorization: `Bearer ${token}` };
                axios
                    .post(url, body, config)
                    .then(function (response) {
                        setTimeout(() => {
                            callback(response.data);
                        }, 0);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            
                        } else {
                            callback(null);
                        }
                    });
            });
        } else {
            axios
                .post(url, body, config)
                .then(function (response) {
                    setTimeout(() => {
                        callback(response.data);
                    }, 0);
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        return;
                    } else {
                        callback(null);
                    }
                });
        }
        return source;
    };
    static delete = (url, callback, isToken = false, timeout = null) => {
        let source = axios.CancelToken.source();
        let config = {
            cancelToken: source.token,
            timeout: timeout || out
        };
        if (isToken) {
            UserTool.getToken((token) => {
                config.headers = { Authorization: `Bearer ${token}` };
                axios
                    .delete(url, config)
                    .then(function (response) {
                        setTimeout(() => {
                            callback(response.data);
                        }, 0);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            
                        } else {
                            callback(null);
                        }
                    });
            });
        } else {
            axios
                .delete(url, config)
                .then(function (response) {
                    setTimeout(() => {
                        callback(response.data);
                    }, 0);
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        return;
                    } else {
                        callback(null);
                    }
                });
        }
        return source;
    };

    static postFormFromJson = (url, body, callback, isToken = false, timeout = null) => {
        let source = axios.CancelToken.source();
        let formData = JsonTool.convertJsonToFormData(body);
        let config = {
            headers: {
                "content-type": "multipart/form-data",
            },
            cancelToken: source.token,
            timeout: timeout || out
        };
        if (isToken) {
            UserTool.getToken((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                axios
                    .post(url, formData, config)
                    .then(function (response) {
                        setTimeout(() => {
                            callback(response.data);
                        }, 0);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            
                        } else {
                            callback(null);
                        }
                    });
            });
        } else {
            axios
                .post(url, formData, config)
                .then(function (response) {
                    setTimeout(() => {
                        callback(response.data);
                    }, 0);
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        
                    } else {
                        callback(null);
                    }
                });
        }
        return source;
    };
}
