import {
  AdministrativeBoundariesEnum,
  GeometryTypeEnum,
  PlaceExtensionTypeEnum,
  TypeMetaDataEnum,
} from "../enum";
import { Resource } from "../resource";

export class PlaceTool {
  static classifyMetaData = (metaDatas) => {
    let result = {};
    Object.keys(TypeMetaDataEnum)?.forEach((key) => {
      result[TypeMetaDataEnum[key]] = [...(metaDatas || [])]?.filter(
        (metaData) => {
          return metaData?.type == TypeMetaDataEnum[key];
        }
      );
    });
    return result;
  };

  static convertClassifyMetaDataToPlaceExTend = (classifyMetaData) => {
    let result = {};
    result[PlaceExtensionTypeEnum.article] =
      classifyMetaData[TypeMetaDataEnum.article];
    result[PlaceExtensionTypeEnum.link] =
      classifyMetaData[TypeMetaDataEnum.link];
    result[PlaceExtensionTypeEnum.popup] =
      classifyMetaData[TypeMetaDataEnum.popup];
    result[PlaceExtensionTypeEnum.vr360] =
      classifyMetaData[TypeMetaDataEnum.vr360];
    result[PlaceExtensionTypeEnum.video] = [
      ...(classifyMetaData[TypeMetaDataEnum.videoLink] || []),
      ...(classifyMetaData[TypeMetaDataEnum.videoEmbed] || []),
    ];
    return result;
  };

  static isArea = (place) => {
    let typeCheck = place?.geometry?.type;
    switch (typeCheck) {
      case GeometryTypeEnum.multiPolygon:
      case GeometryTypeEnum.polygon:
        return true;
      default:
        return false;
    }
  };

  static isStreet = (place) => {
    let typeCheck = place?.geometry?.type;
    switch (typeCheck) {
      case GeometryTypeEnum.lineString:
      case GeometryTypeEnum.multiLineString:
        return true;
      default:
        return false;
    }
  };

  static isAreaOrStreet = (place) => {
    let typeCheck = place?.geometry?.type;
    switch (typeCheck) {
      case GeometryTypeEnum.lineString:
      case GeometryTypeEnum.multiLineString:
      // case GeometryTypeEnum.multiPoint:
      case GeometryTypeEnum.multiPolygon:
      case GeometryTypeEnum.polygon:
        return true;
      default:
        return false;
    }
  };

  static isAdministrativeBoundaries = (poi) => {
    let check = false;
    Object?.values(AdministrativeBoundariesEnum).forEach((type) => {
      if (poi?.types?.includes(type)) {
        check = true;
      }
    });
    return check;
  };

  static getPoiLabel = (poi) => {
    if (typeof poi === "string") {
      return poi;
    }
    return (
      poi?.address ||
      poi?.name ||
      `${poi?.location.lat}, ${poi?.location.lng}` ||
      Resource.common.notYetAddress
    );
  };

  static isArea = (poi) => {
    return (
      poi?.geometry?.type === GeometryTypeEnum.multiPolygon ||
      poi?.geometry?.type === GeometryTypeEnum.polygon
    );
  };

  static displayPlaceType = (typesInfos) => {
    let arrType = [];
    typesInfos?.forEach((type) => {
      arrType.push(type?.name);
    });
    return arrType.join(", ");
  };

  static comparedData = (currentData, updatedData) => {
    if (
      ((!currentData && currentData != 0) || currentData?.length == 0) &&
      ((!updatedData && updatedData != 0) || updatedData?.length == 0)
    ) {
      return true;
    } else if (currentData == 0 && updatedData == 0) {
      return true;
    } else if (
      (currentData || updatedData) &&
      typeof currentData == typeof updatedData
    ) {
      if (Array.isArray(currentData) || Array.isArray(updatedData)) {
        if (currentData?.length > 0) {
          if (currentData?.length != updatedData?.length) {
            return false;
          } else {
            let check = true;
            currentData?.forEach((t, i) => {
              check =
                check && PlaceTool.comparedData(currentData[i], updatedData[i]);
            });
            return check;
          }
        } else {
          if (updatedData?.length > 0) {
            return false;
          } else return true;
        }
      } else if (
        typeof currentData == "object" &&
        typeof updatedData == "object"
      ) {
        let check = true;
        if (currentData instanceof Date || updatedData instanceof Date) {
          check = currentData?.getTime() == updatedData?.getTime();
        } else if (
          Object.keys(currentData || {})?.length !=
          Object.keys(updatedData || {})?.length
        ) {
          check = false;
        } else {
          Object.keys(currentData || {})?.forEach((k) => {
            check =
              check && PlaceTool.comparedData(currentData[k], updatedData[k]);
          });
        }
        return check;
      } else {
        return currentData == updatedData;
      }
    } else {
      return false;
    }
  };

  static clearDataPlace = (data) => {
    delete data.typeInfos;
    delete data.createdBy;
    delete data.createdDate;
    delete data.updatedBy;
    delete data.updatedDate;
    delete data.status;
    delete data.requestType;
    delete data.address;
    delete data.id;
    delete data.mediaFiles;
    delete data.object;
    delete data.otherPlaceInObjects;
    delete data.placeTypes;
    delete data.rank;
    return data;
  };

  static convertDataRequestUpdate = (currentData, updatedData, arrKeys) => {
    let dataRequest = {};
    let deleteFields = [];
    arrKeys?.forEach((key) => {
      if (!PlaceTool.comparedData(currentData[key], updatedData[key])) {
        if (currentData[key] != updatedData[key] && !updatedData[key]) {
          deleteFields.push(key);
        } else {
          dataRequest[key] = updatedData[key];
        }
      }
    });

    let result = {
      ...dataRequest,
      listFieldDel: deleteFields,
    };

    if (!Object.keys(dataRequest)?.length > 0 && deleteFields?.length == 0) {
      result = null;
    }
    return result;
  };

  static createRequestPoiV4 = (currentData, updatedData) => {
    const noneAddOrRemoveKeys = [
      "geometry",
      "name",
      "address",
      "location",
      "objectId",
      "businessHours",
      "startDate",
      "endDate",
      "phoneNumber",
      "website",
      "description",
      "mainType"
    ];
    // "mediaFiles","tags","metadata",auxiliaryTypes
    let dataRequest = {};
    noneAddOrRemoveKeys?.forEach((key) => {
      if (!PlaceTool.comparedData2(currentData?.[key], updatedData?.[key])) {
        dataRequest[key] = {
          newValue: updatedData[key]
        }
      }
    });

    // mediaFiles
    let mediaFiles = {

    }
    mediaFiles.remove = currentData?.mediaFiles?.filter(t => {
      return !updatedData?.mediaFiles?.some(u => u?.url == t?.url)
    })
    mediaFiles.add = updatedData?.mediaFiles?.filter(u => {
      return !currentData?.mediaFiles?.some(c => c?.url == u?.url)
    })
    if (mediaFiles?.remove?.length > 0 || mediaFiles?.add?.length > 0) {
      dataRequest.mediaFiles = mediaFiles
    }

    // metadata
    let metadata = {

    }
    metadata.remove = currentData?.metadata?.filter(t => {
      return !updatedData?.metadata?.some(u => u?.id == t?.id)
    })
    metadata.add = updatedData?.metadata?.filter(u => {
      return (!u?.id)||(!currentData?.metadata?.some(c => c?.id == u?.id))
    })
    if (metadata?.remove?.length > 0 || metadata?.add?.length > 0) {
      dataRequest.metadata = metadata
    }


    //tags
    let tags={
    }
    tags.remove = currentData?.tags?.filter(c=>!updatedData.tags?.includes(c))
    tags.add = updatedData?.tags?.filter(c=>!currentData.tags?.includes(c))
    if(tags.remove?.length>0||tags.add?.length>0){
      dataRequest.tags = tags
    }

     //auxiliaryTypes
     let auxiliaryTypes={
    }
    auxiliaryTypes.remove = currentData?.auxiliaryTypes?.filter(c=>!updatedData.auxiliaryTypes?.includes(c))
    auxiliaryTypes.add = updatedData?.auxiliaryTypes?.filter(c=>!currentData.auxiliaryTypes?.includes(c))
    if(auxiliaryTypes.remove?.length>0||auxiliaryTypes.add?.length>0){
      dataRequest.auxiliaryTypes = auxiliaryTypes
    }

    return dataRequest;
  }
  static comparedData2 = (a, b) => {
    if (a === 0 || b === 0) {
      if (a == b) {
        return true
      }
      else {
        return false
      }
    }
    else if ((!a || a?.length == 0) && (!b || b?.length == 0)) {
      return true
    }
    else if ((!a || a?.length == 0) || (!b || b?.length == 0)) {
      return false
    }
    else if (a instanceof Date || b instanceof Date) {
      return a?.getTime() == b?.getTime();
    }
    else if ((typeof a == "object") && (typeof a == "object")) {
      if (Object.keys(a)?.length != Object.keys(b)?.length) {
        return false
      }
      else {
        let check = true
        Object.keys(a)?.forEach(k => {
          check = check && PlaceTool.comparedData2(a[k], b[k])
        })
        return check
      }
    }
    else {
      return a == b
    }
  }
}
