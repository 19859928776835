import { Box, List, ListItem, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouterConfig } from '../../config/routerConfig';
import { AppData } from '../../data';
import { MapEventEnum, TypeRequestUpdateEnum } from '../../enum';
import { Resource } from '../../resource';
import RequestUpdateBtn from '../common/requestUpdateBtn/requestUpdateBtn';
import RightChildMenu from '../common/rightChildMenu/rightChildMenu';

const maxHeight = 320
const width = 180

const useStyles = makeStyles({
  paper: {
    maxWidth: '100%',
    minWidth: width + "px",
    maxWidth: width + "px",
    position: 'absolute',
    backgroundColor: 'white',
  }
})

function BuildingMenu() {
  const history = useHistory()
  const classes = useStyles()
  const [building, setBuilding] = useState(null)
  const [menu, setMenu] = useState({
    show: false,
    position: {
      left: 0,
      top: 0
    },
    anchor: {
      x: "left",
      y: "top"
    }
  })
  useEffect(() => {
    const eventRightClick = AppData.map.addListener(MapEventEnum.rightClick, (args) => {
      const point = args?.pixel;
      let dataBuilding = args?.building;
      setBuilding(dataBuilding)
      let pointTemp = {
        left: point.x,
        top: point.y
      }
      let anchorTemp = {
        x: "left",
        y: "top"
      }
      if (pointTemp.left + width >= window.innerWidth) {
        anchorTemp.x = "right"
      }
      if (pointTemp.top + maxHeight >= window.innerHeight) {
        anchorTemp.y = "bottom"
      }
      setMenu({
        show: true,
        position: pointTemp,
        anchor: anchorTemp
      })
    }, { mapbuilding: true })

    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      setMenu({
        show: false,
      })
    },
      {
        location: true,
        mapbuilding: true,
        place: true
      })

    const eventClickLocation = AppData.map.addListener(MapEventEnum.rightClick, (args) => {
      setMenu({
        show: false,
      })
    },
      {
        location: true,
        mappoi: true
      })

    const eventBoundsChanged = AppData.map.addListener(MapEventEnum.boundsChanged, (args) => {
      setMenu({
        show: false,
      })

    })

    return () => {
      eventClickLocation?.remove()
      eventRightClick?.remove()
      eventClick?.remove()
      eventBoundsChanged?.remove()
    }
  }, [])

  const handleShowObjectDetail = () => {
    history.push({
      pathname: RouterConfig.object3d.detail.replace(":id", building?.id),
      state: {
        disableMoveMap: true
      }
    })
    setMenu({
      show: false,
    })
  }

  const contextMenu = (e) => {
    e.preventDefault()
  }

  const onClickCloseMenu = () => {
    setMenu({
      show: false,
    })
  }


  return (
    menu.show &&
    <Box position="absolute" left={menu.position?.left + "px"} top={menu.position?.top + "px"}>
      <Paper
        onContextMenu={contextMenu}
        style={{
          [menu.anchor.x]: "0",
          [menu.anchor.y]: "0"
        }}
        className={classes.paper}>

        <List aria-label="main mailbox folders">
          {/* <Box m={3}> */}
            <RequestUpdateBtn id={building?.id} type={TypeRequestUpdateEnum.building} placeName={building?.name} />
          {/* </Box> */}
          <RightChildMenu latlng={building?.location} onClick={onClickCloseMenu} />
          <ListItem button onClick={handleShowObjectDetail}>
            <Typography variant="body2">{Resource.common.buildingDetail}</Typography>
          </ListItem>
        </List>
      </Paper>
    </Box>
  )
}

BuildingMenu.propTypes = {
  //
};

export default BuildingMenu;
