import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PersonalAddress from '../common/personalAddress/personalAddress';


function MyLabels() {
  return (
    <PersonalAddress />
  )
}

MyLabels.propTypes = {
  //
};

export default MyLabels;
