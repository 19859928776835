import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragToScroll } from 'ui';
import { Resource } from '../../../resource';
import { AppTool } from '../../../tool';
import { MediaTypeEnum } from './../../../enum/mediaTypeEnum';
import './displayMedia.scss';


const useStyles = makeStyles({
  itemImg: {
    height: '100%',
    width: '100%',
  },

  backgroundOpacity: {
    backgroundColor: '#00000040',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    '& .MuiTypography-root': {
      color: '#FFFFFF',
      position: 'absolute',
      left: '10px',
      bottom: '5px'
    }
  },
  item: {
    position: 'relative',
    width: '130px',
    minWidth: '130px',
    borderRadius: '8px',
    overflow: 'hidden',
    height: '150px',
    cursor: 'pointer',
  },
})

function DisplayMedia({ place, isMobile }) {
  const classes = useStyles()

  const [mediaFiles, setMediaFile] = useState({})
  const [photos, setPhotos] = useState([])
  const [videos, setVideos] = useState([])

  useEffect(() => {
    if (place) {
      let videos = place?.mediaFiles?.filter((item) => { return item?.typeFile == MediaTypeEnum.video })
      let photos = place?.photos || []
      setPhotos(photos)
      setVideos(videos)
      setMediaFile(place?.mediaFiles)
    }
  }, [place])

  const handleShowMedia = () => {
    AppTool.showMedia(mediaFiles, '', place?.name)
  }

  const handleShowPhotoGallery = () => {
    AppTool.showMedia(mediaFiles, MediaTypeEnum.image, place?.name)
  }

  const handleShowVideoGallery = () => {
    AppTool.showMedia(mediaFiles, MediaTypeEnum.video, place?.name)
  }

  return (
    mediaFiles.length > 0 &&
    <div className='displayMediaCpn'>
      {
        isMobile ?
          <div className='displayMediaContainer'>
            <div className='listDisplayMedia'>
              {
                mediaFiles?.length > 0 &&
                <Box className={classes.item} >
                  {
                    mediaFiles[0]?.typeFile == MediaTypeEnum.image ?
                      <img width='100%' height='100%' src={mediaFiles[0]?.url} alt={mediaFiles[0].name} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                      :
                      <video width="100%" height='100%' preload='metadata' src={mediaFiles[0]?.url+'#t=0.001'} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  }
                  <Box top={0} position='absolute' width='100%' className={classes.backgroundOpacity} onClick={handleShowMedia}>
                    <Typography variant='subtitle2'>{Resource.common.all}</Typography>
                  </Box>
                </Box>
              }

              {
                photos?.length > 0 &&
                <Box className={classes.item} >
                  <img width='100%' height='100%' src={photos[0].url} alt={photos[0].name} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  <Box top={0} position='absolute' width='100%' className={classes.backgroundOpacity} onClick={handleShowPhotoGallery}>
                    <Typography variant='subtitle2'>{Resource.common.images}</Typography>
                  </Box>
                </Box>
              }

              {
                videos?.length > 0 &&
                <Box className={classes.item} >
                  <video width="100%" height='100%' preload='metadata' src={videos[0]?.url+'#t=0.001'} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  <Box position='absolute' top={0} className={classes.backgroundOpacity} onClick={handleShowVideoGallery}>
                    <Typography variant='subtitle2'>{Resource.common.video}</Typography>
                  </Box>
                </Box>
              }
            </div>
          </div>
          :
          <DragToScroll style={{ width: '100%' }}>
            <Box
              display='flex'
              style={{ gap: '16px', width: 'max-content' }}
              width='100%'
              paddingRight={'24px'}
            >
              {
                mediaFiles?.length > 0 &&
                <Box className={classes.item} >
                  {
                    mediaFiles[0]?.typeFile == MediaTypeEnum.image ?
                      <img width='100%' height='100%' src={mediaFiles[0]?.url} alt={mediaFiles[0].name} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                      :
                      <video width="100%" height='100%' preload='metadata' src={mediaFiles[0]?.url} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  }
                  <Box top={0} position='absolute' width='100%' className={classes.backgroundOpacity} onClick={handleShowMedia}>
                    <Typography variant='subtitle2'>{Resource.common.all}</Typography>
                  </Box>
                </Box>
              }

              {
                photos?.length > 0 &&
                <Box className={classes.item} >
                  <img width='100%' height='100%' src={photos[0].url} alt={photos[0].name} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  <Box top={0} position='absolute' width='100%' className={classes.backgroundOpacity} onClick={handleShowPhotoGallery}>
                    <Typography variant='subtitle2'>{Resource.common.images}</Typography>
                  </Box>
                </Box>
              }

              {
                videos?.length > 0 &&
                <Box className={classes.item} >
                  <video width="100%" height='100%' preload='metadata' src={videos[0]?.url} style={{ objectFit: 'cover' }} className={classes.itemImg} />
                  <Box position='absolute' top={0} className={classes.backgroundOpacity} onClick={handleShowVideoGallery}>
                    <Typography variant='subtitle2'>{Resource.common.video}</Typography>
                  </Box>
                </Box>
              }
            </Box>
          </DragToScroll>
      }
    </div >
  )
}

DisplayMedia.propTypes = {
  data: PropTypes.any,
  isMobile: PropTypes.bool
};

export default DisplayMedia;
