import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LanguageState, TimeDurationState } from '../appState';
import { Resource } from '../resource';

export function useTime() {

    const [text, setText] = useState({})
    const language = useRecoilValue(LanguageState)
    const dataTime = useRecoilValue(TimeDurationState)

    useEffect(()=>{
        if (dataTime) {
            dataTime?.forEach((item)=>{
                let time = convertDuration(item?.value)
                let text = displayTextTime(time)
                setText((prev)=>({
                    ...prev,
                    [item?.id]: text
                }))
            })
        }
    },[dataTime, language])

    const actionConvertTime = {
        "month": (seconds) => {
          const months = Math.floor(seconds / 2592000)
          const days = Math.round((seconds % 2592000) / 86400)
          return {
            isMonth: true,
            month: months,
            day: days
          }
        },
        "day": (seconds) => {
          const days = Math.floor(seconds / 86400)
          const hour = Math.round((seconds % 86400) / 3600)
          return {
            isDay: true,
            day: days,
            hour: hour
          }
        },
        "hour": (seconds) => {
          const hour = Math.floor(seconds / 3600)
          const minutes = Math.round((seconds % 3600) / 60)
          return {
            isHour: true,
            hour: hour,
            minutes: minutes
          }
        },
        "minute": (seconds) => {
          const minutes = Math.floor(seconds / 60)
          const s = Math.round(seconds % 60)
          return {
            isMinute: true,
            minutes: minutes,
            second: s
          }
        },
        "second": (seconds) => {
          return {
            isSecond: true,
            second: seconds
          }
        }
      }
      const convertDuration = (seconds) => {
        if (seconds > 2592000) {
          return actionConvertTime['month']
        }
    
        if (seconds >= 86400) {
          return actionConvertTime["day"](seconds)
        }
        else if (seconds >= 3600) {
          return actionConvertTime["hour"](seconds)
        }
        else if (seconds >= 60) {
          return actionConvertTime["minute"](seconds)
        }
        else {
          return actionConvertTime["second"](seconds)
        }
      }
      const displayTextTime = (timeObject) => {
        if (timeObject) {
          let text = ''
          if (timeObject?.isMonth) {
            text = `${timeObject?.month} ${Resource.common.month?.toLocaleLowerCase()}`
            if (timeObject?.day > 0) {
              text += ` ${timeObject?.day} ${Resource.common.day?.toLocaleLowerCase()}`
            }
          }
          else if (timeObject?.isDay) {
            text = `${timeObject?.day} ${Resource.common.day?.toLocaleLowerCase()}`
            if (timeObject?.hour > 0) {
              text += ` ${timeObject?.hour} ${Resource.common.hour?.toLocaleLowerCase()}`
            }
          }
          else if (timeObject?.isHour) {
            text = `${timeObject?.hour} ${Resource.common.hour?.toLocaleLowerCase()}`
            if (timeObject?.minutes > 0) {
              text += ` ${timeObject?.minutes} ${Resource.common.minutes?.toLocaleLowerCase()}`
            }
          }
          else if (timeObject?.isMinute) {
            text = `${timeObject?.minutes} ${Resource.common.minutes?.toLocaleLowerCase()}`
            if (timeObject?.second > 0) {
                text += ` ${timeObject?.second} ${Resource.common.seconds?.toLocaleLowerCase()}`
              }
        }
          else {
            text = `${timeObject?.second} ${Resource.common.seconds?.toLocaleLowerCase()}`
          }
          return text
        }
      }
    return text;
}