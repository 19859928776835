import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "ui";
import * as yup from "yup";
import { HomeAddressState, UserState } from "../../appState";
import { SvgIconConfig } from "../../config";
import { UrlConfig } from "../../config/urlConfig";
import { AppData } from "../../data";
import { AlertTypeEnum, CodeEnum, PersonalAddressEnum } from "../../enum";
import { Resource } from "../../resource";
import { ApiTool, AppTool, LocationTool } from "../../tool";
import Dialog from "../dialog/dialog";
import DialogAction from "../dialogAction/dialogAction";
import DialogBody from "../dialogBody/dialogBody";
import DialogHeader from "../dialogHeader/dialogHeader";
import SuggestV3 from "../suggestV3/suggestV3";
import TextView from "../textView/textView";
import "./userHomeAddressInput.scss";

const IconDict = {
  place: SvgIconConfig.direction.suggestPoi.place,
  history: SvgIconConfig.direction.suggestPoi.history,
};



function UserHomeAddressInput({ onChange, onClose }) {
  const [homeAddressState, setHomeAddressState] = useRecoilState(HomeAddressState)
  const schema = yup.object().shape({
    poi: yup
      .mixed()
      .nullable()
      .test(
        "required",
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.address,
        }),
        function (poi) {
          if (!poi && !homeAddressState) {
            return false;
          }
          return true;
        }
      ),
  });

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const [poiState, setPoiState] = useState();
  const [listPoiState, setListPoiState] = useState([]);
  const [error, setError] = useState(false);
  const user = useRecoilValue(UserState)

  useEffect(() => {
    setPoiState(homeAddressState);
  }, [homeAddressState]);

  const getTextInput = (poi) => {
    let text = (poi?.name && poi?.address) ? `${poi?.name}, ${poi?.address}` : (poi?.name || poi?.address)
    if (text && poi?.name === ("Nhà riêng" || "Home")) {
      text = poi?.placeName && poi?.address ? `${poi?.placeName}, ${poi?.address}` : (poi?.placeName || poi?.address)
    }
    if (!text) {
      text = poi?.location ? `${poi?.location.lat?.toFixed(6)}, ${poi?.location.lng?.toFixed(6)}` : ""
    }
    return text
  }

  const selectOptionMatchTextPercent = (suggests) => {
    if (suggests?.length == 1) {
        setPoiState(suggests[0])
    }
    else {
      setError(true)
    }
  }

  const handleOnchange = (newPoi) => {
    setError(false)
    setValue("poi", newPoi, { shouldValidate: formState.isSubmitted });
    setPoiState(newPoi);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const userHomeAddress = Resource.common.homePrivate;

  const handleSave = (data) => {
    if (poiState) {
      if (poiState?.location) {
        let body = {
          placeId: poiState?.id ? poiState?.id : "",
          address: poiState?.address,
          location: poiState?.location,
          updateType: PersonalAddressEnum.homePrivate,
          name: poiState?.name,
        };
        ApiTool.post(
          UrlConfig.userLabel.update,
          body,
          (res) => {
            if (res?.code == CodeEnum.ok) {
              let dataHome = {
                ...res?.result?.home,
                name: Resource.common.homePrivate,
              };
              setHomeAddressState(dataHome);
              AppTool.alert(
                Resource.formatString(
                  homeAddressState
                    ? Resource.alert.success.editAddress
                    : Resource.alert.success.saveAddress,
                  {
                    object: userHomeAddress.toLowerCase(),
                  }
                ),
                AlertTypeEnum.success
              );
              onChange && onChange(res?.result?.home);
            } else {
              AppTool.alert(
                Resource.formatString(
                  homeAddressState
                    ? Resource.alert.error.editAddress
                    : Resource.alert.error.saveAddress,
                  {
                    object: userHomeAddress.toLowerCase(),
                  }
                ),
                AlertTypeEnum.error
              );
            }
            onClose && onClose();
          },
          true
        );
      }
    } else {
      ApiTool.delete(
        UrlConfig.userLabel.delete.replace(
          "{userLabelType}",
          PersonalAddressEnum.homePrivate
        ),
        (res) => {
          if (res?.code == CodeEnum.ok) {
            setHomeAddressState(null);
            AppTool.alert(
              Resource.formatString(Resource.alert.success.delete, {
                object: userHomeAddress.toLowerCase(),
              }),
              AlertTypeEnum.success
            );
            onChange && onChange(res?.result?.home);
          } else {
            AppTool.alert(
              Resource.formatString(Resource.alert.error.delete, {
                object: userHomeAddress.toLowerCase(),
              }),
              AlertTypeEnum.error
            );
          }
          onClose && onClose();
        },
        true
      );
    }
  };
  //----------------------

  useEffect(() => {
    getListPoi();
  }, []);

  const getListPoi = (keyword = "", isEnter = false) => {
    let locationNew = AppData.map?.getCamera().getTarget();
    let locString = `${locationNew?.lat},${locationNew?.lng}`;
    let body = {
      text: keyword,
      location: locString,
      isEnter: isEnter
    };
    let param = {
      count: 5,
      isPlace: true,
    };
    if (keyword == "" && user?.id) {
      ApiTool.queryGetFromJson(
        UrlConfig.searchHistory.get,
        param,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            let list = res?.result?.map((value) => {
              let val = { ...value?.place };
              val.isHistory = true;
              val.id = val?.placeId;
              return val;
            });
            setListPoiState(list);
          }
        },
        true
      );
    } else {
      ApiTool.queryGetFromJson(
        UrlConfig.poi.autosuggest,
        body,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            let data = res?.result
            if (isEnter) {
              selectOptionMatchTextPercent(data)
            }
            else {
              setError(false)
              setListPoiState(res?.result?.slice(0, 5) || [])
            }
          }
        },
        true
      );
    }
  };

  const onChangeTextInput = (keyword) => {
    getListPoi(keyword);
  }

  const getOptionLabel = (option) => {
    return getTextInput(option)
  };

  const renderOption = (option) => {
    return (
      <div key={option?.id} style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "14px", width: "24px", height: "24px" }}>
          {option?.isHistory == true ? IconDict.history : IconDict.place}
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {getTextInput(option)}
        </div>
      </div>
    );
  };
  register({ name: "poi", type: "custom" });

  const onSearchEnter = (text, isEnter) => {
    let position = LocationTool.checkTextIsLocation(text)
    if (position) {
      getAddressFromLocation(position)
    }
    else {
      getListPoi(text, isEnter)
    }
  }

  const getAddressFromLocation = (location) => {
    ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, location, (res) => {
      if (res?.code == CodeEnum.ok) {
        setPoiState(res?.result)
      }
    })
  }

  return (
    <Dialog open={true} onClose={handleClose} className="userHomeDialog" disableCloseOnClickBackdrop>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogHeader className="userHomeTitle">
          {Resource.formatString(Resource.personalAddress.updateAddress, {
            object: userHomeAddress.toLowerCase(),
          })}
        </DialogHeader>
        <DialogBody className="userHomeContent">
          <SuggestV3
            label={Resource.common.search}
            placeholder={Resource.address.add}
            options={listPoiState}
            value={poiState}
            onChange={handleOnchange}
            onInputChange={onChangeTextInput}
            maxLengthOfScroll={6}
            isServer
            isFreeSolo
            clearable
            getTextForOption={getOptionLabel}
            renderOption={renderOption}
            onEnter={onSearchEnter}
            error={error}
            helperText={error ? Resource.direction.errorMessage.homeOrWork : null}
          />
        </DialogBody>
        <DialogAction className="userHomeAction">
          <div
            style={{
              display: "flex",
            }}
          >
            <Button variant="close" onClick={handleClose}>
              {Resource.button.cancel.toUpperCase()}
            </Button>
            <Button variant="save" type="submit">
              {Resource.button.save.toUpperCase()}
            </Button>
          </div>
        </DialogAction>
      </form>
    </Dialog>
  );
}

UserHomeAddressInput.propTypes = {
  onChange: PropTypes.any,
  onClose: PropTypes.any,
};

export default UserHomeAddressInput;
