import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { LinkIconConfig } from '../../../config';
import { useHistory } from 'react-router-dom';
import { RouterConfig } from '../../../config/routerConfig';

const useStyles = makeStyles({
  root: {
    border: '1px solid #1C75BC',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1C75BC',
    boxShadow: '0 1px 3px rgb(0 0 0 / 30%)',
  }
})

function MobileCircleDirectionButton() {
  const classes = useStyles()
  const history = useHistory()

  const handleDirection = () => {
    history.push({
      pathname: RouterConfig.direction
    })
  }
  return (
    <Box width={56} height={56} className={classes.root} onClick={handleDirection}>
      <img src={LinkIconConfig.placeExtend.direction} alt='icon direction' width={24} height={24} />
    </Box>
  )
}

MobileCircleDirectionButton.propTypes = {
  //
};

export default MobileCircleDirectionButton;
