import { AppData } from "../data"

export class SidebarTool {
    static setShowToggle = (show) => {
        AppData.sidebar?.setShowToggle(show)
    }
    static setFullHeight = (full) => {
        AppData.sidebar?.setFullHeight(full)
    }
    static setFullBar = (full) => {
        AppData.sidebar?.setFullBar(full)
    }
    static setOpen = (isOpen) => {
        AppData.sidebar?.setOpen(isOpen)
    }
}