import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ShowEffectSettingState } from '../../appState';
import { AppConfig, LinkIconConfig } from '../../config';
import { Button } from 'ui';
import { AppData } from '../../data';
import { CookieEnum, DisplayEffectEnum, TimeEffectEnum, WeatherEffectEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool } from '../../tool';
import { CookieTool } from "../../tool";

const useStyles = makeStyles((theme) => {
  return ({
    dialog: {
      width: "568px"
    },

    toggleBox: {
      border: 'none !important',
      textAlign: 'center',
      width: '64px'
    },

    subTitle: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginBottom: '1.5rem'
    },

    toggleDisplayWeather: {
      backgroundColor: "#E6F4FF",
      marginBottom: '5px',
      border: '1px solid transparent !important',
      boxShadow: 'inset 4px 4px 8px #0000001A',
      borderRadius: '8px',
      padding: '8px',
      '&:hover': {
        backgroundColor: '#E6F4FF !important',
      }
    },

    toggleDisplayTime: {
      backgroundColor: '#E6F4FF',
      marginBottom: '5px',
      padding: '8px',
      border: '1px solid transparent !important',
      boxShadow: 'inset 4px 4px 8px #0000001A',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#E6F4FF !important',
      }
    },

    toggleDisable: {
      padding: '8px',
      marginBottom: '5px',
      opacity: 0,
    },
    title: {
      padding: '28px 24px',
      textAlign: 'center',
      '& h2': {
        fontSize: '20px !important'
      }
    },
    toggleSelectedWeather: {
      borderColor: '#508FF4 !important',
      backgroundColor: '#E6F4FF !important',
      boxShadow: 'unset'
    },

    toggleSelectedTime: {
      borderColor: '#508FF4 !important',
      backgroundColor: '#E6F4FF !important',
      boxShadow: 'unset'
    },

    disableBtn: {
      opacity: 0.5
    },

    boxContent: {
      boxShadow: '0px 0px 2px #00000029',
      borderRadius: '4px',
    },

    selectedText: {
      color: "#508FF4",
      fontWeight: 600
    },

    fontWeight: {
      fontWeight: '600 !important',
      '& h2': {
        fontWeight: '600 !important',
      }

    },

    textDisplayOptions: {
      '& .MuiTypography-root': {
        fontSize: '14px'
      }
    },
    cancelButton: {
      marginRight: '1.5rem'
    }
  })
});

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: '1px solid transparent',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
      borderLeft: '1px solid transparent',
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

}))(ToggleButtonGroup);


function EffectSettingV2() {

  const weatherDefault = [
    {
      icon: LinkIconConfig.effect.adjustV2,
      name: Resource.effect.auto,
      value: WeatherEffectEnum.live

    },
    {
      icon: LinkIconConfig.effect.noonV2,
      name: Resource.effect.weatherOptions.sunny,
      value: WeatherEffectEnum.sunny

    },
    {
      icon: LinkIconConfig.effect.rainV2,
      name: Resource.effect.weatherOptions.rainy,
      value: WeatherEffectEnum.rainy

    },
    {
      icon: LinkIconConfig.effect.snowV2,
      name: Resource.effect.weatherOptions.snowy,
      value: WeatherEffectEnum.snowy

    },
  ]

  const timeDefault = [
    {
      icon: LinkIconConfig.effect.morningV2,
      name: Resource.effect.time.morning,
      value: TimeEffectEnum.morning,
      background: '#E6F4FF 0% 0% no-repeat padding-box'

    },
    {
      icon: LinkIconConfig.effect.noonV2,
      name: Resource.effect.time.noon,
      value: TimeEffectEnum.noon,
      background: '#E6F4FF 0% 0% no-repeat padding-box'
    },
    {
      icon: LinkIconConfig.effect.afternoonV2,
      name: Resource.effect.time.afternoon,
      value: TimeEffectEnum.afternoon,
      background: '#E6F4FF 0% 0% no-repeat padding-box',
    },
    {
      icon: LinkIconConfig.effect.moonV2,
      name: Resource.effect.time.moon,
      value: TimeEffectEnum.evening,
      background: '#E6F4FF 0% 0% no-repeat padding-box',
    },
  ]

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [weather, setWeatherEffect] = useState(() => {
    let weatherCurrent = CookieTool.get(CookieEnum.weather) ? parseInt(CookieTool.get(CookieEnum.weather)) : WeatherEffectEnum.live
    return weatherCurrent
  })

  const [time, setTime] = useState(() => {
    let timeCurrent = CookieTool.get(CookieEnum.time) ? parseInt(CookieTool.get(CookieEnum.time)) : TimeEffectEnum.live
    return timeCurrent
  })

  const [disableEffect, setDisableEffect] = useState(() => {
    let disable = CookieTool.get(CookieEnum.disableEffect) === 'true'
    return disable
  })

  const [check, setCheck] = useState({
    shadow: true,
    weatherTime: true,
    object3D: true,
    water: true,
    poi: false
  })

  const [showEffectSettingState, setShowEffectSettingState] = useRecoilState(ShowEffectSettingState)

  const handleChangeCheckBox = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
    switch (event.target.name) {
      case DisplayEffectEnum.shadow:
        AppData.map.setShadowEffect(event.target.checked)
        CookieTool.set(CookieEnum.shadow, event.target.checked)
        break;

      case DisplayEffectEnum.water:
        AppData.map.setWaterEffect(event.target.checked)
        CookieTool.set(CookieEnum.water, event.target.checked)
        break;

      case DisplayEffectEnum.object3D:
        AppData.map.setBuildingsEnabled(event.target.checked)
        CookieTool.set(CookieEnum.object3D, event.target.checked)
        break;

      case DisplayEffectEnum.weatherTime:
        let disableEffectNew = !event.target.checked
        if (disableEffectNew) {
          AppData.map.setWeather(WeatherEffectEnum.none)
          AppData.map.setTimeEffect(TimeEffectEnum.none)
        }
        else {
          AppData.map.setWeather(WeatherEffectEnum.live)
          AppData.map.setTimeEffect(TimeEffectEnum.live)
        }
        CookieTool.set(CookieEnum.disableEffect, disableEffectNew)
        setDisableEffect(disableEffectNew)
        setWeatherEffect(disableEffectNew ? WeatherEffectEnum.none : WeatherEffectEnum.live)
        setTime(disableEffectNew ? TimeEffectEnum.none : TimeEffectEnum.live)
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    AppData.map.setTimeEffect(time)
    CookieTool.set(CookieEnum.time, time)
  }, [time])

  useEffect(() => {
    AppData.map.setWaterEffect(check.water)

    CookieTool.set(CookieEnum.water, check.water)
  }, [check.water])

  useEffect(() => {
    if (weather == WeatherEffectEnum.live) {
      let weatherProvider = {
        refreshTime: 120,//second
        getWeather: function (location, callback) {
          ApiTool.get(`${AppConfig.map4dApiDomain}/map/weather?lat=${location.lat}&lng=${location.lng}`, data => {
            let ret = data;
            let type = +((ret || {}).result || {}).type || 3;
            let weather = WeatherEffectEnum.sunny
            switch (type) {
              case 0:
                weather = WeatherEffectEnum.sunny
                break
              case 1:
                weather = WeatherEffectEnum.rainy
                break
              case 2:
                weather = WeatherEffectEnum.snowy
                break
              case 3:
                weather = WeatherEffectEnum.cloudy
                break
              default:
                weather = WeatherEffectEnum.none
            }
            callback(weather);
          })
        }
      }
      AppData.map.setWeather(weather, weatherProvider)
    }
    else {
      AppData.map.setWeather(weather)
    }
    // alert(`mưa:  thời tiết đc set: ${weather} , thời tiết lấy ra từ map: ${AppData.map.getWeather()} ${AppData.map}`)
    CookieTool.set(CookieEnum.weather, weather)
  }, [weather])

  const handleClose = () => {
    setShowEffectSettingState(false)
  }

  const handleChangeToggleWeather = (event, newWeather) => {
    if (newWeather == null) {
      setWeatherEffect(WeatherEffectEnum.none)
    }
    else {
      setWeatherEffect(newWeather);
    }

  };

  const handleChangeToggleTime = (event, newTime) => {
    if (newTime == null) {
      setTime(TimeEffectEnum.none)
    }
    else {
      setTime(newTime);
    }
  };

  const { shadow, object3D, water, poi } = check;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={showEffectSettingState}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth='xl'
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <DialogTitle id="responsive-dialog-title" className={classes.fontWeight + ' ' + classes.title}>{Resource.effect.displaySettings}</DialogTitle>
      <DialogTitle className={classes.fontWeight}>{Resource.effect.display3D}</DialogTitle>
      <DialogContent>

        <Box p={2} className={classes.boxContent}>
          <Typography variant="body1" className={classes.fontWeight}>{Resource.effect.weather}</Typography>
          <Box pt={3} pb={2}>
            <StyledToggleButtonGroup
              size="large"
              value={weather}
              exclusive
              onChange={handleChangeToggleWeather}
              aria-label="text alignment"
            >
              {
                weatherDefault?.map((itemWeather, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      classes={{
                        selected: classes.toggleSelectedWeather,
                        root: classes.toggleDisplayWeather,
                        disabled: classes.disableBtn
                      }}
                      value={itemWeather?.value}
                      disabled={disableEffect}
                    >
                      <img
                        src={itemWeather?.icon}
                        alt="icon weather"
                        width={48}
                        height={48} />
                    </ToggleButton>

                  )
                })
              }
            </StyledToggleButtonGroup>
          </Box>

          <Box className={classes.subTitle}>
            {
              weatherDefault?.map((item, index) => {
                return (
                  <Box key={item.value} className={classes.toggleBox} clone>
                    <Typography className={item.value == weather ? classes.selectedText : ''} variant="body2" >{item.name}</Typography>
                  </Box>
                )
              })
            }
          </Box>
          <Typography variant="body1" className={classes.fontWeight}>{Resource.effect.currentTime}</Typography>
          <Box pt={3} pb={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
            <ToggleButton
              selected={TimeEffectEnum.live == time}
              onChange={handleChangeToggleTime}
              classes={{
                selected: classes.toggleSelectedTime,
                root: classes.toggleDisplayTime,
                disabled: classes.disableBtn
              }}
              value={TimeEffectEnum.live}
              disabled={disableEffect}>
              <img
                src={LinkIconConfig.effect.adjustV2}
                alt="live"
                width={48}
                height={48}
              />
            </ToggleButton>
            {
              [2, 3, 4].map((item, index) => {
                return (
                  <ToggleButton
                    value={""}
                    key={item}
                    selected={TimeEffectEnum.morning == time}
                    classes={{
                      root: classes.toggleDisable,
                    }}
                    disabled={true}>
                    <img
                      alt="morning icon"
                      width={48}
                      height={48} />
                  </ToggleButton>
                )

              })
            }
          </Box>
          <Box className={classes.subTitle}>
            <Box className={classes.toggleBox} clone>
              <Typography className={TimeEffectEnum.live == time ? classes.selectedText : ''} variant="body2" >{Resource.effect.auto}</Typography>
            </Box>
            {
              [2, 3, 4].map((item, index) => {
                return (
                  <Box key={item} className={classes.toggleBox} clone>
                    <Typography style={{ opacity: 0 }} variant="body2" >{Resource.effect.time.noon}</Typography>
                  </Box>
                )
              })
            }
          </Box>
          <Box p='10px 0' display="flex" justifyContent="space-evenly" flexWrap="wrap">
            {
              timeDefault?.map((itemTime, index) => {
                return (
                  <ToggleButton
                    key={itemTime.value}
                    style={{ background: itemTime.background }}
                    onChange={handleChangeToggleTime}
                    selected={itemTime.value == time}
                    classes={{
                      selected: classes.toggleSelectedTime,
                      root: classes.toggleDisplayTime,
                      disabled: classes.disableBtn
                    }}
                    value={itemTime.value}
                    disabled={disableEffect}>
                    <img
                      src={itemTime.icon}
                      alt="morning icon"
                      width={48}
                      height={48} />
                  </ToggleButton>
                )
              })
            }

            {/* </StyledToggleButtonGroup> */}
          </Box>

          <Box className={classes.subTitle}>
            {
              timeDefault?.map((item, index) => {
                return (
                  <Box key={item.value} className={classes.toggleBox} clone>
                    <Typography className={item.value == time ? classes.selectedText : ''} variant="body2" >{item.name}</Typography>
                  </Box>
                )
              })
            }
          </Box>
          <Typography variant="body1" className={classes.fontWeight}>{Resource.effect.display}</Typography>
          <Box pt={3} pb={2}>
            <FormGroup>
              <FormControlLabel
                className={classes.textDisplayOptions}
                control={<Checkbox checked={shadow} color='primary' onChange={handleChangeCheckBox} name={DisplayEffectEnum.shadow} />}
                label={Resource.effect.options.shadowEffect}
              />
              <FormControlLabel
                className={classes.textDisplayOptions}
                control={<Checkbox checked={water} color='primary' onChange={handleChangeCheckBox} name={DisplayEffectEnum.water} />}
                label={Resource.effect.options.waterSurfaceEffect}
              />
            </FormGroup>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'end', boxShadow: '0px 0px 4px #00000029' }}>
        <Button onClick={handleClose} variant='cancel' className={classes.cancelButton}>
          {Resource.common.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EffectSettingV2.propTypes = {
  //
};

export default EffectSettingV2;
