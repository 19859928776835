import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';

export const useForm = ({ resolver }) => {

  const refDict = useRef({})
  const dataRef = useRef({})
  const timeOutRef = useRef()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isRequiredError, setIsRequiredError] = useState(false)
  const [errors, setErrors] = useState({})
  const [isValid, setIsValid] = useState(false)
  const validate = (callback) => {
    timeOutRef.current && clearTimeout(timeOutRef.current)
    timeOutRef.current = setTimeout(() => {
      resolverRef.current?.validate(dataRef.current, { abortEarly: false })
        .then(function (value) {
          callback({
            isValid: true,
            value: value
          })
        })
        .catch(function (err) {
          let inner = err?.inner
          let errors = {

          }
          inner?.forEach(e => {
            errors[e?.path] = e
          });
          callback({
            isValid: false,
            value: err?.value,
            errors: errors,
            inner: inner
          })
        });
    }, 100)
  }
  const [result, setResult] = useState(
    {
      register: ({ name }) => (ref) => {
        refDict.current[name] = ref
      },
      setValue: (name, value, option = {}) => {
        const { shouldValidate } = option
        dataRef.current[name] = value
        validate((res) => {
          let isRequired = Object.values(res?.errors || {})?.some(v => (v.type == "optionality" || v.type == "required"))
          setIsRequiredError(isRequired)
          setIsValid(res?.isValid)
          if (shouldValidate) {
            setErrors(res?.errors)
          }
  
        })
      },
      errors: errors,
      formState: {
        isSubmitted: isSubmitted,
        isValid: isValid
      },
      isRequiredError: isRequiredError,
      handleSubmit: (onSubmit) => (e) => {
        e?.preventDefault()
        e?.stopPropagation()
        setIsSubmitted(true)
        validate((res) => {
          let isRequired = Object.values(res?.errors || {})?.some(v => (v.type == "optionality" || v.type == "required"))
          setIsRequiredError(isRequired)
          setErrors(res?.errors)
          setIsValid(res?.isValid)
          if (res?.isValid) {
            onSubmit(res?.value)
          }
          else {
            refDict.current[res?.inner?.[0]?.path]?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        })
      }
    }
  )

  const resolverRef = useRef()
  useEffect(()=>{
    resolverRef.current = resolver
  },[resolver])
  useEffect(()=>{
    setResult(
      {
        register: ({ name }) => (ref) => {
          refDict.current[name] = ref
        },
        setValue: (name, value, option = {}) => {
          const { shouldValidate } = option
          dataRef.current[name] = value
          validate((res) => {
            let isRequired = Object.values(res?.errors || {})?.some(v => (v.type == "optionality" || v.type == "required"))
            setIsRequiredError(isRequired)
            setIsValid(res?.isValid)
            if (shouldValidate) {
              setErrors(res?.errors)
            }
    
          })
        },
        errors: errors || {},
        formState: {
          isSubmitted: isSubmitted,
          isValid: isValid
        },
        isRequiredError: isRequiredError,
        handleSubmit: (onSubmit) => (e) => {
          e?.preventDefault()
          e?.stopPropagation()
          setIsSubmitted(true)
          validate((res) => {
            let isRequired = Object.values(res?.errors || {})?.some(v => (v.type == "optionality" || v.type == "required"))
            setIsRequiredError(isRequired)
            setErrors(res?.errors)
            setIsValid(res?.isValid)
            if (res?.isValid) {
              onSubmit(res?.value)
            }
            else {
              refDict.current[res?.inner?.[0]?.path]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          })
        }
      }
    )
  },[isSubmitted,isRequiredError,errors,isValid])




  return  result
}
// useForm.propTypes = {
//   //
// };
