import { Button, ButtonGroup, createMuiTheme, Tooltip } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { MapTypeAppState } from '../../../appState';
import { LinkIconConfig } from '../../../config';
import { AppData } from '../../../data';
import { MapEventEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { MapTypeEnum } from './../../../enum/mapTypeEnum';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})
const useStyles = makeStyles({
  root: {
    padding: "4px",
    height: "32px",
    minWidth: "32px",
    width: '32px'
  }
});

function BtnControl() {
  const [bearing, stBearing] = useState(() => {
    return AppData.map.getCamera().getBearing()
  })

  const [mapTypeAppState, setMapTypeAppState] = useRecoilState(MapTypeAppState)

  useEffect(() => {
    const event = AppData.map.addListener(MapEventEnum.modeChanged, (args) => {
      setIs3D(AppData.map.is3dMode())
    })
    const eventBoundChange = AppData.map.addListener(MapEventEnum.boundsChanged, (args) => {
      stBearing(AppData.map.getCamera().getBearing())
    })
    return () => {
      event?.remove()
      eventBoundChange?.remove()
    }
  }, [])

  const classes = useStyles();
  const handleDirection = (e) => {
    const camera = AppData.map.getCamera();
    camera.setBearing(0)
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleTypeMap = (e) => {
    const is3DMode = (mapTypeAppState == MapTypeEnum.map3d)
    setMapTypeAppState(is3DMode ? MapTypeEnum.roadmap : MapTypeEnum.map3d)
    let zoom = AppData.map.getCamera().getZoom()
    if (!is3DMode && zoom < 16) {
      zoom = 17
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <ButtonGroup
        orientation="vertical"
      >
        <Tooltip title={Resource.common.navigation}>
          <Button color="primary" className={classes.root} variant="contained" onClick={handleDirection}>
            <img style={{ transform: `rotate(${0 - bearing}deg)` }} src={LinkIconConfig.navigation} height={20} width={20} />
          </Button>
        </Tooltip>

        <Tooltip title={Resource.common.status}>
          {!(mapTypeAppState == MapTypeEnum.map3d) ?
            <Button color="primary"
              onClick={handleTypeMap} className={classes.root} variant="contained" >
              <img src={LinkIconConfig.map3D} height={20} width={20} />
            </Button>
            :
            <Button color="primary"
              onClick={handleTypeMap} className={classes.root} variant="contained" >
              <img src={LinkIconConfig.map2D} height={20} width={20} />
            </Button>
          }
        </Tooltip>
      </ButtonGroup>
    </ThemeProvider>

  )

}

BtnControl.propTypes = {
  //
};

export default BtnControl;
