export class JsonTool {
    static convertJsonToQueryString = (data) => {
        let arr = []
        Object.keys(data || {})?.forEach((key) => {
            if (data[key] != null && data[key] != undefined && data[key] != "null" && data[key] != "undefined") {
                if (Array.isArray(data[key])) {
                    let values = data[key]
                    values?.forEach(value => {
                        if (value != null && value != undefined && value != "null" && value != "undefined") {
                            arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
                        }
                    })
                }
                else {
                    arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                }
            }
        })
        return arr.join('&');
    }
    static convertJsonToFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => {
            if (Array.isArray(object[key])) {
                if (object[key].length > 0) {
                    object[key].forEach((item) => {
                        formData.append(key, item)
                    })
                }
            }
            else {
                formData.append(key, object[key])
            }

        });
        return formData;
    }
}