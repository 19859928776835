import { Box, Card, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig, ImageConfig, LinkIconConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { AppData } from '../../../data';
import { CodeEnum, MapEventEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, UrlTool } from '../../../tool';
import './locationControl.css';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: 350,
    height: 90,
    zIndex: 69,
    position: 'fixed',
    left: '50%',
    bottom: '10px',
    transform: 'translateX(-50%)'
  },
  root2: {
    position: 'absolute',
  },
  details: {
    width: 250
  },
  cover: {
    width: 100,
  },
  text: {
    fontSize: '14px',
    fontWeight: 700,
    "&:hover": {
      color: '#4a8af4',
      cursor: 'pointer'
    }
  },
  hoverText: {
    "&:hover": {
      color: '#4a8af4',
      cursor: 'pointer'
    }
  },
  hoverPoint: {
    "&:hover": {
      color: '#4a8af4',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  btnclose: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    width: '20px',
    height: '20px',
    borderRadius: 0
  },
  skeleton: {
    height: 10,
    marginBottom: '10px'
  },
});

function LocationControl() {
  const { text, type } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [place, setPlace] = useState(null)
  const [loading, setLoading] = useState(false)
  const marRef = useRef(null)
  const showRef = useRef(false)
  const pinRef = useRef(null)

  useEffect(() => {
    var sourceLocation
    const eventClickLocation = AppData.map.addListener(MapEventEnum.click, (args) => {
      if (showRef.current) {
        showRef.current = false
        marRef.current?.setMap(null)
        setShow(false)
      }
      else if (args?.location?.lat != Infinity && args?.location?.lng != Infinity) {
        let body = {
          lat: args?.location?.lat,
          lng: args?.location?.lng
        }
        let location = args?.location
        marRef.current?.setMap(null)
        marRef.current = new map4d.Marker({
          position: location,
          icon: new map4d.Icon(36, 36, LinkIconConfig.pin),
          anchor: [0.5, 0.5]
        });
        marRef.current?.setMap(AppData.map)
        showRef.current = true
        drawPinHighlight(location)
        setPlace({
          location
        })
        setShow(true)
        setLoading(true)
        sourceLocation = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          setLoading(false)
          if (res?.code == CodeEnum.ok) {
            drawPinHighlight(res?.result?.location)
            setPlace(res?.result)
          }
        })
      }
    }, { location: true })
    return () => {
      eventClickLocation?.remove()
      marRef.current?.setMap(null)
      sourceLocation?.cancel()
    }
  }, [])

  const handleClose = (e) => {
    showRef.current = false
    marRef.current?.setMap(null)
    setPlace(null)
    setShow(false)
    setLoading(false)
  }

  const drawPinHighlight = (location) => {
    if (location) {
      pinRef.current = new map4d.Marker({
        position: location,
        iconView: `<div class="pinHighlight"><div class="highlight"></div><img src="${LinkIconConfig.pinHighlight}"/></div>`
      });
    }
  }

  const handleMouseEnter = (e) => {
    if (place?.location && place?.location?.lat != Infinity && place?.location?.lng != Infinity) {
      pinRef.current?.setMap(AppData.map)
    }
  }
  const handleMouseLeave = (e) => {
    pinRef.current?.setMap(null)
  }
  const handleShowDetail = () => {
    if (text) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, id: place?.id })
      })
    }
    else {
      history.push({
        pathname: RouterConfig.place.detail.replace(":id", place?.id || "custom"),
      });
    }
    pinRef.current?.setMap(null)
    marRef.current?.setMap(null)
    setShow(false)
  }

  return (
    show && (
      !loading ?
        <Card className={classes.root}>
          <CardMedia
            className={classes.cover}
            image={place?.photos?.length > 0 ? place?.photos[0].url : ImageConfig.avaterDefault}
            title={place?.name}
          />
          <IconButton className={classes.btnclose} aria-label="close" onClick={handleClose} >
            <CloseIcon fontSize='small' />
          </IconButton>
          <div className={classes.details} onClick={handleShowDetail} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <CardContent className={classes.content}>
              <Box>
                <Typography className={classes.text} title={place?.name} noWrap variant="body2" component="p">
                  {place?.name || Resource.common.noPlaceFound}
                </Typography>
              </Box>
              <Typography className={classes.hoverText} title={place?.address} noWrap variant="body2" component="p">
                {place?.address}
              </Typography>
              {
                place?.location &&
                <Typography className={classes.hoverPoint} variant="body2" color="textSecondary" component="p">
                  {place?.location.lat.toFixed(6) + ','} {place?.location.lng.toFixed(6)}
                </Typography>
              }
            </CardContent>
          </div>
        </Card>
        :
        <Card className={classes.root}>
          <Skeleton animation="wave" variant="rect" className={classes.cover} height={90} />
          <IconButton className={classes.btnclose} aria-label="close" onClick={handleClose} >
            <CloseIcon fontSize='small' />
          </IconButton>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Box>
                <Skeleton animation="wave" variant="rect" width={50 + '%'} className={classes.skeleton} />
              </Box>
              <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
              <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
            </CardContent>
          </div>
        </Card>
    )
  );
}

LocationControl.propTypes = {
  //
};

export default LocationControl;
