import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { DragToScroll } from 'ui';
import { DataExtendState } from '../../../appState';
import { PlaceExtensionTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import './displayLink.scss';
import PropTypes from 'prop-types';
import { StringTool } from '../../../tool';

const LinkGetIcon = 'https://s2.googleusercontent.com/s2/favicons?domain_url='
function DisplayLink({ data, isMobile }) {
  const [dataState, setDataState] = useState([])
  const setDataExtendState = useSetRecoilState(DataExtendState)

  useEffect(() => {
    if (data) {
      setDataState(data)
    }
  }, [data])

  const handleShowLinksDetail = (index) => (e) => {
    if (dataState?.length == 1) {
      window.open(dataState[0]?.content, '_blank')
    }
    else {
      setDataExtendState({
        show: true,
        dataExtend: dataState,
        indexSelect: index,
        typeData: PlaceExtensionTypeEnum.link
      })
    }
  }

  return (
    dataState.length > 0 &&
    <div className='displayLinkCpn'>
      <div className='headerTitle'>
        <div className='textTitle'>
          {Resource.common.link}
        </div>
        {
          dataState?.length > 2 &&
          <div className='textShowMore' onClick={handleShowLinksDetail(0)}>
            {Resource.button.viewAll}
          </div>
        }
      </div>
      {
        isMobile ?
          <div className='boxOverFlowX'>
            <div className={StringTool.mergeClassName('listItemLink', dataState?.length == 1 ? 'fullWidth' : '')}>
              {
                dataState?.map((item, idx) => {
                  if (idx < 3) {
                    return (
                      <div title={item?.name} key={idx} className='item' onClick={handleShowLinksDetail(idx)}>
                        <div className='textLink'>
                          <div style={{ width: 18, height: 18 }}>
                            <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                          </div>
                          <span className='text'>{item?.content}</span>
                        </div>
                        <span className='textName'>{item.name}</span>
                      </div>
                    )
                  }
                })
              }
            </div>
          </div>
          :
          <DragToScroll >
            <div className={`listItemLink ${dataState?.length == 1 ? 'fullWidth' : ''}`}>
              {
                dataState?.map((item, idx) => {
                  if (idx < 3) {
                    return (
                      <div title={item?.name} key={idx} className='item' onClick={handleShowLinksDetail(idx)}>
                        <div className='textLink'>
                          <div style={{ width: 18, height: 18 }}>
                            <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                          </div>
                          <span className='text'>{item?.content}</span>
                        </div>
                        <span className='textName'>{item.name}</span>
                      </div>
                    )
                  }
                })
              }
            </div>
          </DragToScroll>
      }

    </div >
  )
}

DisplayLink.propTypes = {
  data: PropTypes.any,
  isMobile: PropTypes.bool
};

export default DisplayLink;
