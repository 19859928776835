import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LinkIconConfig } from '../../config';
import { ModeEnum, WeightingEnum } from '../../enum';
import { Resource } from '../../resource';
import { useTime } from '../../useHooks';
import { LanguageState } from './../../appState/settingState';
import { DirectionTool } from './../../tool/directionTool';
import './listRoute.scss';

const typeModeEnum = {
  [ModeEnum.bike]: LinkIconConfig.routes.bike,
  [ModeEnum.car]: LinkIconConfig.routes.car,
  [ModeEnum.foot]: LinkIconConfig.routes.foot,
  [ModeEnum.motorcycle]: LinkIconConfig.routes.motorcycle
}

const ListRoute = (props) => {
  const typeWeightingEnum = {
    [WeightingEnum.fastest]: Resource.direction.route.fastest,
    [WeightingEnum.shortest]: Resource.direction.route.shortest,
    [WeightingEnum.balance]: Resource.direction.route.balance
  }

  const { routes, mode, weighting, onChangeSelect, onChangeDetail, index } = props

  const [selectIndex, setSelectIndex] = useState(0)
  const [detailIndex, setDetailIndex] = useState(-1)

  const languageState = useRecoilValue(LanguageState)
  const valueTime = useTime()

  useEffect(() => {
    return () => setSelectIndex(0)
  }, [routes])

  useEffect(() => {
    setSelectIndex(index)
  }, [index])

  const onChangeSelectIndex = (index) => (e) => {
    setSelectIndex(index)
    onChangeSelect && onChangeSelect(index)
  }
  const handleShowDetail = (index) => (e) => {
    setDetailIndex(index)
    onChangeDetail && onChangeDetail(index)
  }

  return (
    <div className='listRouteCpn'>
      {
       routes?.map((item, index) => {
          let textDistance = DirectionTool.convertDistanceLocalize(item?.distance?.value, languageState)
          let textDuration = valueTime[index]

          return (
            <div
              key={item?.overviewPolyline}
              className="itemRoute"
              style={{
                borderLeftColor: selectIndex == index && '#1C75BC'
              }}
              onClick={selectIndex == index ? handleShowDetail(index) : onChangeSelectIndex(index)}
            >
              <div className='inforItem'>
                <div className='iconItem'>
                  <img src={typeModeEnum[mode]} alt="icon-mode" />
                </div>
                <div className='contentItem'>
                  <div className='nameItem'>{item?.summary || Resource.direction.noName}</div>
                  {index == 0 && <div>{typeWeightingEnum[weighting]}</div>}
                  {selectIndex == index ? <button className='buttonDetail' onClick={handleShowDetail(index)}>{Resource.common.detail}</button> : <></>}
                </div>
              </div>
              <div className='distanceTimeItem'>
                <div className='timeItem'>{textDuration}</div>
                <div className='distanceItem'>{textDistance}</div>
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

ListRoute.propTypes = {
  routes: PropTypes.array,
  mode: PropTypes.string,
  weighting: PropTypes.any,
  onChangeSelect: PropTypes.func,
  onChangeDetail: PropTypes.func,
  index: PropTypes.any
};

export default ListRoute;
