import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, createMuiTheme, Tooltip } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Resource } from '../../../resource';
import { AppData } from '../../../data';
import { LinkIconConfig } from '../../../config';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})
const useStyles = makeStyles({
  root: {
    padding: "4px",
    height: "32px",
    minWidth: "32px",
    width: '32px'
  },
});

function ZoomControl() {
  const classes = useStyles();

  const handleZoomOut = (e) => {
    let camera = AppData.map.getCamera();
    camera.setZoom(camera.getZoom() - 1);
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleZoomIn = (e) => {
    let camera = AppData.map.getCamera();
    camera.setZoom(camera.getZoom() + 1);
    AppData.map.moveCamera(camera, { animate: true })
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <ButtonGroup
          orientation="vertical"
          color="primary"
        >
          <Tooltip title={Resource.common.zoomIn} >
            <Button className={classes.root} variant="contained" onClick={handleZoomIn}>
              <img src={LinkIconConfig.zoomIn} height={20} width={20} />
            </Button>
          </Tooltip>

          <Tooltip title={Resource.common.zoomOut}>
            <Button className={classes.root} variant="contained" onClick={handleZoomOut}>
              <img src={LinkIconConfig.zoomOut} height={20} width={20} />
            </Button>
          </Tooltip>

        </ButtonGroup>
      </ThemeProvider>
    </>
  )
}

ZoomControl.propTypes = {
  //
};

export default ZoomControl;
