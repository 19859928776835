import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApiTool } from '../../../tool';
import { CodeEnum } from '../../../enum';
import { Autocomplete } from '@material-ui/lab';
import { Resource } from '../../../resource';
import { UrlConfig } from '../../../config/urlConfig';
import { TextField } from '@material-ui/core';
import { CommonConfig } from '../../../config';

function ModelInput(props) {
  const { defaultValue, value, onChange, disableClearable = false } = props
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateValue, setStateValue] = useState(defaultValue || null);
  const [text, setText] = useState('')

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const getOptions = (searchText) => {
    let body = {
      searchKey: searchText,
      pageNumber: 0,
      pageSize: CommonConfig.pageSizeSearch,
    };
    setLoading(true);
    ApiTool.queryGetFromJson(UrlConfig.model.list, body, (res) => {
      setLoading(false);
      if (res?.code == CodeEnum.ok) {
        setOptions(res?.result || []);
      }
    }, true);
    setText(searchText)
  };

  const handleChange = (e, value) => {
    setStateValue(value);
    onChange && onChange(value)
  };
  const handleInputChange = (e, value) => {
    getOptions(value)
  }

  return (
    <Autocomplete
      closeText={Resource.common.close}
      openText={Resource.common.open}
      clearText={Resource.common.clear}
      disableClearable={disableClearable}
      fullWidth
      value={(("value" in props) ? value : stateValue) || null}
      getOptionLabel={(option) => {
        return option?.name || ''
      }}
      renderOption={(option) => {
        return option.name || ''
      }}
      getOptionSelected={(option, value) => { return true }}
      options={options}
      loading={loading}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) =>
        <TextField
          {...params}
          id=""
          label={Resource.model.choose}
          variant="outlined"
        />
      }
      noOptionsText={
        text ? Resource.common.noOption : Resource.common.enterKeyword
      }
    />
  )
}


ModelInput.propTypes = {
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  disableClearable: PropTypes.bool
};

export default ModelInput;
