import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Chip, TextInput } from 'ui';
import { UrlConfig } from '../../../config';
import { CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool } from '../../../tool';
import DialogHeader from '../../dialogHeader/dialogHeader';
import { CommonConfig } from './../../../config/commonConfig';
import DialogAction from './../../dialogAction/dialogAction';
import DialogBody from './../../dialogBody/dialogBody';
import "./tagInputV2.scss";
import Dialog from './../../dialog/dialog';
import { LinkIconConfig } from './../../../config/linkIconConfig';
import MultiInput from './../multiInput/multiInput';

const MAX_LENGTH_SEARCH = 256
const REGEX_TAG_NAME = /^[0-9a-zA-Z_]*$/
function TagInputV2(props) {
  const { value, onChange, placeholder } = props

  const sourceTag = useRef()
  const htmlInput = useRef()

  const [optionTags, setOptionTags] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const [listTagSelect, setListTagSelect] = useState([])
  const [text, setText] = useState('')
  const [listTags, setListTag] = useState([])
  const [listFilterTag, setListFilterTag] = useState([])

  const [itemNew, setItemNew] = useState(null)
  const [isChange, setIsChange] = useState(false)

  useEffect(() => {
    getTags()
    return () => {
      sourceTag?.current?.cancel()
    }
  }, [])

  useEffect(() => {
    if (value && value?.length > 0) {
      let newTags = []
      value?.forEach((tag) => {
        newTags.push({
          name: tag,
          id: tag
        })
      })
      setListTag(newTags)
      setListTagSelect(newTags)
    }
  }, [value])

  useEffect(() => {
    setListTagSelect((prev) => {
      let list = [...(listTags || [])]
      return list
    })
  }, [listTags])

  useEffect(() => {
    getTags(text , listTagSelect)
  }, [text, listTagSelect])

  useEffect(() => {
    if (text) {
      let isExitsSelect = listTagSelect?.some((tag) => tag?.name?.toLocaleLowerCase() === text?.toLocaleLowerCase())
      let isExitsFilter = listFilterTag?.some((tag) => tag?.name?.toLocaleLowerCase() === text?.toLocaleLowerCase())

      if ((!isExitsSelect && !isExitsFilter && REGEX_TAG_NAME.test(text))) {
        let newTag = {
          id: text,
          name: text,
          isNew: true
        }
        setItemNew(newTag)
      }
      else {
        setItemNew(null)
      }
    }
    else {
      setItemNew(null)
    }
  }, [text, listFilterTag, listTagSelect])

  useEffect(() => {
    let dataFilter = optionTags?.filter((option) => {
      return !listTags?.find((tag) => tag.name === option?.name)
    })
    setListFilterTag(dataFilter)
  }, [optionTags, listTags])

  useEffect(() => {
    let dataFilter = optionTags?.filter((option) => {
      return !listTagSelect?.find((tag) => tag.name === option?.name)
    })
    setListFilterTag(dataFilter)
  }, [listTagSelect, optionTags])

  const onFocusTextInput = () => {
    htmlInput?.current?.focus()
  }

  const onOpenDialog = () => {
    setOpenDialog(true)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
    setListTagSelect((prev) => {
      let list = [...(listTags || [])]
      return list
    })
    setText('')
  }

  const onBack = () => {
    setOpenDialog(false)
    setListTagSelect((prev) => {
      let list = [...(listTags || [])]
      return list
    })
    setText('')
  }

  const getTags = (text, arrSelect) => {
    let textSearch = text?.trim()
    let arrName = arrSelect?.map((tag, index) => { return tag?.name })
    let body = {
      keyword: textSearch,
      excludeHashTag: arrName
    }
    sourceTag.current = ApiTool.queryGetFromJson(UrlConfig.tag.filter, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let data = res?.result || []
        setOptionTags(data)
      }
    }, true)
  }

  const onCreateTag = (tags) => {
    let param = tags?.map((tag) => { return tag?.name })
    ApiTool.post(`${UrlConfig.tag.createMany}`, param, (res) => {
      if (res?.code == CodeEnum.ok) {
        setListTag(prev => {
          let list = [...(listTagSelect || [])]
          let arrTagName = list?.map((tag) => { return tag?.name })
          onChange && onChange(arrTagName)
          return list
        })
        setItemNew(null)
      }
    }, true)
  }

  const onChangeListTags = (value) => {
    setListTag(value)
    let arrTagName = value?.map((tag) => { return tag?.name })
    onChange && onChange(arrTagName)
  }

  const onChangeTextInput = (e) => {
    let value = e?.target.value
    setText(value)
  }

  const onSelectTag = (tag) => (e) => {
    setListTagSelect((prev) => {
      let list = [...prev || []]
      list?.push(tag)
      return list
    })
    setText('')
    setIsChange(true)
  }

  const onDeleteItem = (index) => (e) => {
    setListTagSelect(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      return list
    })
    setIsChange(true)
  }

  const onSave = () => {
    let listTag = [...(listTagSelect || [])]
    let listNewTag = listTag?.filter((tag) => tag?.isNew)
    if (listNewTag?.length > 0) {
      onCreateTag(listNewTag)
    }
    else {
      setListTag(prev => {
        let list = [...(listTagSelect || [])]
        let arrTagName = list?.map((tag) => { return tag?.name })
        onChange && onChange(arrTagName)
        return list
      })
    }
    setOpenDialog(false)
    setText('')
    setIsChange(false)
  }

  return (
    <>
      <div className='tagInputCpn'>
        <MultiInput
          value={listTags}
          label={Resource.hashTag.tag}
          placeholder={placeholder || Resource.hashTag.add}
          onChange={onChangeListTags}
          onClick={onOpenDialog}

        />
      </div>

      <Dialog
        className='dialogTagInput'
        open={openDialog}
        onClose={onCloseDialog}
        onBack={onBack}
        disableCloseOnClickBackdrop
      >
        <DialogHeader showBack hideClose>
          {Resource.hashTag.add}
        </DialogHeader>
        <div className='tagHeader'>
          {
            listTagSelect?.length > 0 ?
              <div className='inputSelect' onClick={onFocusTextInput}>
                <div className='listTagSelect'>
                  {
                    listTagSelect?.map((tagSelect, index) => {
                      return (
                        <Chip
                          style={{
                            padding: '5px 7px 5px 10px',
                            wordBreak: 'break-word'
                          }}
                          onDelete={onDeleteItem(index)}
                          key={tagSelect?.id}
                        >
                          {tagSelect?.name}
                        </Chip>
                      )
                    })
                  }
                  <div className='boxSearch'>
                    <input
                      className='inputSearch'
                      value={text}
                      onChange={onChangeTextInput}
                      ref={htmlInput}
                      maxLength={MAX_LENGTH_SEARCH}
                    />
                  </div>
                </div>
              </div>
              :
              <TextInput
                value={text}
                onChange={onChangeTextInput}
                suffix={<img src={LinkIconConfig.common.search} alt="icon-search" />}
                placeholder={Resource.hashTag.search}
                maxLength={MAX_LENGTH_SEARCH}
                showCount
              />
          }
        </div>

        <div className='lineHorizontal'></div>

        <DialogBody>
          <div className='tagDialogBody'>
            <div className='tagInputContainer'>
              <div className='tagContent'>
                {
                  itemNew &&
                  <div className='listTagNew'>
                    <div className='tagItem' key={itemNew?.id} onClick={onSelectTag(itemNew)}>
                      <div className='tagName'>
                        {itemNew?.name}
                      </div>
                      <div className='iconAdd' title={Resource.common.addNew}>
                        <img src={LinkIconConfig.add} alt='add' />
                      </div>
                    </div>
                  </div>
                }
                {
                  listFilterTag?.length > 0 ?
                    <div className='listTag'>
                      {
                        listFilterTag?.map((tag, index) => {
                          return (
                            <div className='tagItem' key={tag?.id} onClick={onSelectTag(tag)}>
                              <div className='tagName'>
                                {tag?.name}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    (
                      !itemNew ?
                        <div className='boxNoData'>
                          {Resource.common.noData}
                        </div>
                        : ''
                    )
                }
              </div>
            </div>
          </div>

        </DialogBody>

        <DialogAction
          style={{
            display: 'flex',
            gap: '0.75rem'
          }}
        >
          <Button onClick={onCloseDialog} variant='cancel' size='small'>
            {Resource.button.cancel}
          </Button>
          {
            isChange &&
            <Button onClick={onSave} variant='done' size='small'>
              {Resource.button.done}
            </Button>
          }
        </DialogAction>
      </Dialog>
    </>
  )
};

TagInputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};
export default TagInputV2;
