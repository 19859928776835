import { Box, Button, CardMedia, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { v4 } from 'uuid';
import { ImageConfig } from '../../../config';
import { MediaTypeEnum } from '../../../enum';
import { AppTool, PlaceTool } from '../../../tool';

function SwipeableViewsImages({ place, paddingImages, sizeButton, disableSwitch }) {

  const styles = {
    root: {
      width: '100%',
      height: '100%',
      overflow: "hidden"
    },
    root1: {
      paddingRight: `${paddingImages}px`,
    },
    root2: {
      paddingLeft: `${paddingImages}px`,
    },
    slideContainer: {
      width: "100%",
      height: "100%"
    },
    slide: {
      color: '#000000',
      width: '100%',
      height: "100%"
    },
    borderRight: {
      borderRight: '1px solid #FFFFFF'
    },
    borderLeft: {
      borderLeft: '1px solid #FFFFFF'
    },
  }

  const useStyles = makeStyles({
    boxRelative: {
      '&:hover .arrowButton': {
        display: 'block'
      }
    },
    preBtn: {
      position: 'absolute',
      top: '0',
      left: '0',
      color: "#FFFFFF",
      width: `${sizeButton}px`,
      height: '100%',
      minWidth: `${sizeButton}px`,
      padding: 0,
      display: 'none',
      background: 'linear-gradient(to right,rgba(0,0,0,0.7),transparent)'
    },
    nextBtn: {
      position: 'absolute',
      top: '0',
      right: '0',
      color: "#FFFFFF",
      width: `${sizeButton}px`,
      height: '100%',
      minWidth: `${sizeButton}px`,
      padding: 0,
      display: 'none',
      background: 'linear-gradient(to left,rgba(0,0,0,0.7),transparent)'
    },
    media: {
      height: '100%',
      width: '100%',
      overflow: 'hidden'
    },
    boxShowImage: {
      width: '100%',
      height: '100%',
    }
  })

  const [currentIndex, setCurrentIndex] = useState(0)
  const classes = useStyles()
  const [photos, setPhotos] = useState(null)
  const [videos, setVideos] = useState(null)

  useEffect(() => {
    let classifyMetaData = PlaceTool.classifyMetaData(place?.metadata)
    let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
    let sortVideos = placeExtend?.video.sort((a, b) => a.order - b.order)
    let photoFilter = place?.photos || []
    setPhotos(photoFilter || [])
    setVideos(sortVideos)
  }, [place])

  const handleChangeCurrentIndex = (index) => {
    setCurrentIndex(index)
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1)
  }

  const handleBack = () => {
    setCurrentIndex(currentIndex - 1)
  }

  const handleShowPhotoGallery = () => {
    if (photos?.length > 1) {
      AppTool.showMedia(place?.mediaFiles, MediaTypeEnum.image, place?.name)
    }
  }

  return (
    (photos?.length > 0) ?
      <Box width='100%' height='100%' position='relative' overflow='hidden' className={classes.boxRelative}>

        {
          !disableSwitch ?
            <>
              <SwipeableViews
                onClick={handleShowPhotoGallery}
                index={currentIndex}
                onChangeIndex={handleChangeCurrentIndex}
                style={{ ...styles.root, ...(currentIndex < (photos?.length - 1) ? styles.root1 : styles.root2) }}
                containerStyle={styles.slideContainer}
                slideStyle={currentIndex < (photos?.length - 1) ? styles.borderRight : styles.borderLeft}
              >
                {
                  photos?.map((image, index) => {
                    return (
                      <div key={v4()} style={Object.assign({}, styles.slide)}>
                        <img src={image?.url} alt={image?.name ? image?.name : 'photo'} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                      </div>
                    )
                  })
                }
              </SwipeableViews>

              {
                currentIndex > 0 &&
                <Button className={classes.preBtn + ' arrowButton'} onClick={handleBack} >
                  <ArrowBackIosIcon />
                </Button>
              }

              {
                currentIndex < photos?.length - 1 &&
                <Button className={classes.nextBtn + ' arrowButton'} onClick={handleNext} >
                  <ArrowForwardIosIcon />
                </Button>
              }

            </>
            :
            <Box className={classes.boxShowImage} onClick={handleShowPhotoGallery}>
              <img src={photos[0]?.url} alt={'photo'} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
            </Box>
        }
      </Box>
      :
      <CardMedia
        className={classes.media}
        image={ImageConfig.thumbnailDefault}
        title="photo"
      />
  )
}

SwipeableViewsImages.propTypes = {
  //
};

export default SwipeableViewsImages;
