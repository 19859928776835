import React, { Children, Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RouterConfig } from '../../../config/routerConfig';
import { useRecoilValue } from 'recoil';
import { LocationCanBackState } from '../../../appState';

function Back({ children }) {

  const history = useHistory()
  const stackRef = useRef(history.length)
  const locationCanBackState = useRecoilValue(LocationCanBackState)

  const onClick = () => {
    if (locationCanBackState) {
      history.go(stackRef.current - history.length - 1)
    }
    else {
      history.push({
        pathname: RouterConfig.home
      })
    }
  }
  return (
    Children.map(children, child => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: onClick });
      }
      return child;
    })
  )
}

Back.propTypes = {
  //
};

export default Back;
