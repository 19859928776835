import React, {
  useEffect,
  useRef,
  useState
} from "react"
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from "recoil"
import { RatingInput } from "ui"
import { PlaceTypeState, PositionState, TextSearchState } from "../../appState"
import { ImageConfig, RouterConfig, SvgIconConfig, UrlConfig } from "../../config"
import { CodeEnum, MapEventEnum, PositionTypeEnum } from "../../enum"
import { Resource } from "../../resource"
import { ApiTool, AppTool, PlaceTool, UrlTool } from "../../tool"
import { useQuery } from "../../useHooks"
import BusinessHoursStatus from "../businessHoursStatus/businessHoursStatus"
import SearchBar from "../common/searchBar/searchBar"
import HideSidebarShadow from "../hideSidebarShadow/hideSidebarShadow"
import IsLoading from "../isLoading/isLoading"
import PoiDetail from "../poiDetail/poiDetail"
import Skeleton from "../skeleton/skeleton"
import TextView from "../textView/textView"
import { AppData } from './../../data/appData'
import "./placeSearchV2.scss"
import ShowSuggestPlace from './../showSuggestPlace/showSuggestPlace';
import SuggestPlaceV2 from './../suggestPlaceV2/suggestPlaceV2';
import PositionSelect from './../positionSelect/positionSelect';
import ShowPositionSelect from './../showPositionSelect/showPositionSelect';

const PAGE_SIZE = 10
const COUNT_POI_SHOW = 10

const getListDraw = (list) => {
  let result = []
  if (list?.length > COUNT_POI_SHOW) {
    let index = list?.length - COUNT_POI_SHOW
    result = list?.slice((index), (list?.length + 1))
  }
  else {
    result = list
  }
  return result
}

function PlaceSearchV2() {
  const { type, text, id, lat, lng, data, ep, cp } = useQuery()

  const history = useHistory()

  const _indexRef = useRef()
  const _listMarkerRef = useRef()
  const _markerHoverRef = useRef()
  const _apiGetListSearch = useRef()
  const _topRef = useRef(null)
  const _pageNumberRef = useRef(0)

  const [count, setCount] = useState(0)
  const [listPoi, setListPoi] = useState(null)
  const [textState, setTextState] = useState(text)
  const [typeState, setTypeState] = useState(type)
  const [textNotFound, setTextNotFound] = useState('')

  const [scrollTop, setScrollTop] = useState(0)
  const [loadingData, setLoadingData] = useState(true)
  const [detailId, setDetailId] = useState(null)
  const [dataState, setDataState] = useState(null)

  const setTextSearchState = useSetRecoilState(TextSearchState)
  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const position = useRecoilValue(PositionState)

  useEffect(() => {
    setTextState(text)
    AppTool.setSearchBarText(text)
  }, [text])

  useEffect(() => {
    setDataState(data)
  }, [data])

  useEffect(() => {
    setDetailId(id)
  }, [id])

  useEffect(() => {
    setTypeState(type)
  }, [type])

  useEffect(() => {
    _topRef.current?.scrollIntoView()
    if (textState) {
      getListPoi(textState, typeState, _pageNumberRef.current, { isReset: true })
      _pageNumberRef.current = 0
    }
    return () => {
      _apiGetListSearch.current?.cancel()
    }
  }, [textState, typeState])

  useEffect(() => {
    const clickMarker = AppData.map.addListener(MapEventEnum.click, (args) => {
      let data = args.poi?.getUserData()
      history.push({
        pathname: location?.pathname,
        search: UrlTool.createSearch({ id: data?.id }, { extendOldQuery: true })
      })
    }, { poi: true })
    return () => {
      clickMarker?.remove()
      removeMarker()
    }
  }, [])

  useEffect(() => {
    if (listPoi?.length > 0) {
      let listDraw = getListDraw(listPoi)
      drawMarker(listDraw)
      !id && moveCamera(listDraw[0]?.location)
    }
  }, [listPoi, id])

  const removeMarker = () => {
    _listMarkerRef?.current?.forEach((marker) => {
      marker?.setMap(null)
    })
    _listMarkerRef.current = null
  }

  const drawMarker = (data) => {
    removeMarker()
    if (data?.length > 0) {
      let listMarker = []
      data?.forEach(poi => {
        let marker = new map4d.POI({
          type: poi?.types?.length > 0 ? poi?.types[0] : "nocat",
          position: poi?.location,
          title: poi?.name
        })
        marker.setUserData({
          id: poi?.id || "",
          name: poi?.name,
          address: poi?.address,
          location: poi?.location,
        })
        marker.setMap(AppData.map)
        listMarker.push(marker)
        _listMarkerRef.current = listMarker
      })
    }
  }

  const getListPoi = (text, type, pageNumber, option = { isReset: false }) => {
    setTextSearchState(text)
    const { isReset } = option
    let body = {
      types: [type],
      text: type ? null : text,
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE,
    }
    if (position?.positionType == PositionTypeEnum.current) {
      let center = AppData.map?.getCamera()?.getTarget()
      body.location = `${center.lat},${center.lng}`
    }
    else if (position?.positionType == PositionTypeEnum.basic) {
      body.location = `${position?.location.lat},${position?.location.lng}`
    }
    else if (position?.positionType == PositionTypeEnum.realtime) {
      body.location = `${position?.location.lat},${position?.location.lng}`
    }

    let prevTime = new Date().getTime()
    ApiTool.queryGetFromJson(UrlConfig.poi.search, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let newCount = res?.result?.count
        let dataPOI = res?.result?.data
        if (dataPOI?.length > 0 && PlaceTool.isAreaOrStreet(dataPOI[0]) && pageNumber == 0) {
          history.push({
            pathname: RouterConfig.place.detail.replace(":id", dataPOI[0]?.id),
          })
        }
        else {
          if (count !== newCount) {
            setCount(newCount)
          }
          if (isReset) {
            setLoadingData(false)
            setListPoi(dataPOI)
          }
          else {
            let time = new Date().getTime()
            let realDelta = time - prevTime
            let timeOut = 0
            if (realDelta < 1000) {
              timeOut = 1000 - realDelta
            }
            setTimeout(() => {
              setLoadingData(false)
              setListPoi((prev) => {
                let newValue = prev || []
                newValue = newValue?.concat(dataPOI || [])
                return newValue
              })
            }, timeOut)
          }
        }
      }
    })
  }

  const onMouseEnterItem = (item, index) => (e) => {
    _indexRef.current = index
    _markerHoverRef.current = new map4d.Marker({
      position: item?.location,
      iconView:
        `
      <div class="markerHover">
        <svg width="32" height="39.385" viewBox="0 0 32 39.385">
          <g>
            <g transform="translate(3.08 1.816)">
              <path d="M78.491,37.135a13.279,13.279,0,0,0-5.844-4.877A12.989,12.989,0,0,0,67.485,31.2h-.026A12.989,12.989,0,0,0,62.3,32.259a13.317,13.317,0,0,0-5.831,4.877,13.6,13.6,0,0,0-2.266,7.53v.026h0v.065h0a13.479,13.479,0,0,0,2.291,7.465c.438.706,2.37,2.628,4.584,5.138a18.081,18.081,0,0,1,3.708,6.06l.359.837a36.878,36.878,0,0,1,1.364,4.19,1.025,1.025,0,0,0,1.957,0,26.406,26.406,0,0,1,1.208-4.014l.3-.772a19.428,19.428,0,0,1,3.833-6.3c2.214-2.51,4.233-4.432,4.67-5.138a13.511,13.511,0,0,0,2.291-7.465h0v-.065h0v-.026A13.711,13.711,0,0,0,78.491,37.135Z" transform="translate(-54.2 -31.2)" fill="#e40000" stroke="#b00020" stroke-width="1" />
            </g>
            <ellipse cx="4.5" cy="4.57" rx="4.5" ry="4.57" transform="translate(12 10.156)" fill="#b00020" />
          </g>
        </svg>
      </div>
      `,
      anchor: [0.5, 1]
    })
    _listMarkerRef.current && _listMarkerRef.current[index]?.setMap(null)
    _markerHoverRef.current.setMap(AppData.map)
  }

  const onMouseLeaveItem = (e) => {
    if (_listMarkerRef?.current?.length > 0) {
      _listMarkerRef?.current[_indexRef?.current]?.setMap(AppData.map)
      _markerHoverRef?.current?.setMap(null)
    }
  }

  const handleScroll = (event) => {
    if (
      Math.round(
        event.currentTarget.scrollTop + event.currentTarget.offsetHeight + 1
      ) >= Math.round(event.currentTarget.scrollHeight) && listPoi?.length !== count
    ) {
      setLoadingData(true)
      _pageNumberRef.current = _pageNumberRef.current + 1
      getListPoi(textState, typeState, _pageNumberRef.current)
    }
    setScrollTop(event.currentTarget.scrollTop)
  }

  const scrollToTop = () => {
    _topRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleShowDetail = (item) => () => {
    if (item?.id) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: textState, type: typeState, id: item?.id })
      })
    }
    else {
      let placeCustom = {
        name: item?.name,
        address: item?.address,
        location: item?.location,
      }
      let data = encodeURIComponent(JSON.stringify(placeCustom))
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, data: data })
      })
    }
  }

  const moveCamera = (location) => {
    let camera = AppData.map?.getCamera()
    camera.setTarget(location)
    if (camera.getZoom() !== 15) {
      camera.setZoom(15)
    }
    AppData.map?.moveCamera(camera, { animate: true })
  }

  const handleCloseDetail = () => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: textState, type: typeState })
    })
  }

  const handleSearchBarRef = (r) => {
    AppData.searchBar = r;
  }

  return (
    <div className="placeSearchV2Cpn">
      <div className="listPoiSearchContainer">
        <div className='listPoiHeader'>
          <SearchBar ref={handleSearchBarRef} />
        </div>
        <div className='listPoiSearch' onScroll={handleScroll}>
          <span ref={_topRef}></span>
          {
            (loadingData && _pageNumberRef.current == 0) ? (
              Array.from({ length: 10 }, (_, i) => i).map((item) => {
                return (
                  <div className="itemLoading" key={item}>
                    <div className="content">
                      <Skeleton width='40%' height='10px' />
                      <Skeleton width='60%' height='10px' />
                      <Skeleton width='30%' height='10px' />
                      <Skeleton width='90%' height='10px' />
                    </div>
                    <div className="avatar">
                      <Skeleton width='100%' height='100%' borderRadius='10px' />
                    </div>
                  </div>

                );
              })
            )
              :
              listPoi?.length > 0 && listPoi?.map((poiItem, index) => {
                let avatar = poiItem?.photos?.length > 0 ? poiItem?.photos[0].url : ImageConfig.avaterDefaultGray
                let name = poiItem?.name || ''
                let address = poiItem?.address || ''
                let type = poiItem?.types?.length > 0 ? AllPlaceTypeState[poiItem?.types[0]]?.name : ""
                let rateNum = poiItem?.ratingReport?.avgStars
                let totalComment = poiItem?.ratingReport?.totalComments
                let businessHour = poiItem?.businessHours

                return (
                  <div
                    className='poiItem'
                    key={poiItem?.id + index}
                    id={poiItem?.id + index}
                    title={name}
                    onClick={handleShowDetail(poiItem)}
                    onMouseEnter={onMouseEnterItem(poiItem, index)}
                    onMouseLeave={onMouseLeaveItem}
                  >
                    <div className='poiItemInfo'>
                      <div className='name'>{name}</div>
                      <div className='type'>{type}</div>
                      {
                        poiItem?.ratingReport &&
                        <div className='rate'>
                          <div className='rateNumber'>{rateNum}</div>
                          <RatingInput size={13} avgRating={rateNum} />
                          <div className='totalComment'>{`(${totalComment})`}</div>
                        </div>
                      }
                      <div className='address'>{address}</div>
                      <div className='businessHourStatus'>
                        <BusinessHoursStatus value={businessHour} />
                      </div>
                    </div>
                    <div className='poiItemAvatar'>
                      <img src={avatar} alt='avatar' />
                    </div>
                  </div>
                )
              })
          }
          <div
            className="boxContent_list_item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0 3.5rem 0",
            }}
          >
            {
              (loadingData && _pageNumberRef.current > 0) && <IsLoading />
            }
            {
              listPoi?.length > 6 && listPoi?.length === count && <div>{Resource.common.endData}</div>
            }
            {
              listPoi && listPoi?.length == 0 && <div style={{ padding: "0 1rem" }}>
                {Resource.formatString(
                  Resource.message.resultsNotFoundInTheCurrentRegion + " " + Resource.message.pleaseCheckYourSearchKeywords,
                  { text: `"${textState || textNotFound}"` }
                )}</div>
            }
          </div>
          {
            (scrollTop > 100) &&
            <div className="moveToTop_btn" onClick={scrollToTop}>
              {SvgIconConfig.direction.verticalArrow}
              <TextView variant="body1" style={{ fontWeight: 600 }}>
                {Resource.common.backToTop}
              </TextView>
            </div>

          }

        </div>
      </div>

      {
        (detailId || dataState) && !(cp || ep) &&
        <>
          <HideSidebarShadow />
          <div className='detailPoiCpn'>
            <PoiDetail disableTextSearch id={detailId} onClose={handleCloseDetail} data={dataState} type={typeState} text={textState} />
          </div>
        </>
      }
      <ShowPositionSelect />
      <ShowSuggestPlace />
    </div>
  )
}

export default PlaceSearchV2
