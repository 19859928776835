import React, { useEffect, useState } from "react";
import { StringTool } from "../../tool";
import TextInput, { TextInputProps } from "../textInput/textInput";
import styles from "./timeInput.css";

export interface TimeInputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value?: any,
  onChange: (event: any) => void,
  className?: string,
  label?: string,
  helperText?: string,
  error?: boolean,
  disabled?: boolean
}

const REGEX_NUMBER = /^\d+$/

const createCurrentTime = () => {
  let currentDate = new Date()
  let hour = currentDate?.getHours()
  let minute = currentDate?.getMinutes()
  return {
    hour: hour < 10 ? `0${hour}` : hour,
    minute: minute < 10 ? `0${minute}` : minute,
  }
}

const createValueTime = (time: any) => {
  let arrTime = time?.split(":")
  let hour = arrTime[0]
  let minute = arrTime[1]
  return {
    hour: (hour?.length < 2) ? `0${hour}` : hour,
    minute: (minute?.length < 2) ? `0${minute}` : minute
  }
}

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  className,
  label,
  disabled,
  helperText,
  error,
  ...props
}) => {

  const [open, setOpen] = useState(false)
  const [stateValue, setStateValue] = useState('')
  const [loadingEnd, setLoadingEnd] = useState(false)

  const [timeValue, setTimeValue] = useState(() => {
    let currentTime = createCurrentTime()
    return {
      hour: currentTime?.hour,
      minute: currentTime?.minute
    }
  })

  const [classNameCustom, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.timeInputCpn, className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.timeInputCpn, className as string))
  }, [className])

  useEffect(() => {
    if (value) {
      let valueTime = createValueTime(value)
      if (valueTime?.hour?.match(REGEX_NUMBER) && valueTime?.minute?.match(REGEX_NUMBER)) {
        let valueCustom = `${valueTime?.hour}:${valueTime?.minute}`
        setTimeValue({
          hour: valueTime?.hour,
          minute: valueTime?.minute
        })
        setStateValue(valueCustom)
      }
    }
    else {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          hour: currentTime?.hour,
          minute: currentTime?.minute
        }
      })
    }
  }, [value])

  const onClickInput = (e: any) => {
    setOpen(true)
    setLoadingEnd(false)
  }

  const onChangeHour = (e: any) => {
    let value = e?.target.value
    setTimeValue((prev) => {
      return {
        ...prev,
        hour: value
      }
    })
  }

  const onChangeMinute = (e: any) => {
    let value = e?.target.value
    setTimeValue((prev) => {
      return {
        ...prev,
        minute: value
      }
    })
  }

  const onSave = () => {
    let value = `${timeValue?.hour}:${timeValue?.minute}`
    setStateValue(value)
    setOpen(false)
    onChange && onChange(value)
  }

  const onCancel = (e: any) => {
    setTimeout(() => {
      setOpen(false)
    }, 300)
    setLoadingEnd(true)
  }

  const onBlurHour = (e: any) => {
    let value = e?.target.value
    if (value > 23 || !value?.match(REGEX_NUMBER)) {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          hour: currentTime?.hour,
        }
      })
    }
  }

  const onBlurMinute = (e: any) => {
    let value = e?.target.value
    if (value > 59 || !value?.match(REGEX_NUMBER)) {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          minute: currentTime?.minute,
        }
      })
    }
  }

  return (
    <div
      className={classNameCustom}
      {...props}
    >
      <TextInput
        label={label}
        value={stateValue ? stateValue : '--:--'}
        readOnly
        onClick={onClickInput}
        disabled={disabled}
        helperText={helperText}
        error={error}
      />
      {
        open &&
        <div className={StringTool.mergeClassName(styles.selectTime, loadingEnd ? styles.hideAnimation : '')}>
          <div className={styles.backdrop}></div>
          <div className={styles.selectTimePopup}>
            <div className={styles.selectTimeContainer}>
              <div className={styles.title}>
                Chọn giờ
              </div>

              <div className={styles.content}>
                <div className={styles.inputHour}>
                  <TextInput
                    value={timeValue?.hour}
                    onChange={onChangeHour}
                    hideClear
                    maxLength={2}
                    className={styles.inputTime}
                    onBlur={onBlurHour}
                  />
                  <div className={styles.label}>
                    Giờ
                  </div>
                </div>

                <div className={styles.colons}>
                  :
                </div>

                <div className={styles.inputMinute}>
                  <TextInput
                    value={timeValue?.minute}
                    onChange={onChangeMinute}
                    hideClear
                    maxLength={2}
                    className={styles.inputTime}
                    onBlur={onBlurMinute}
                  />
                  <div className={styles.label}>
                    Phút
                  </div>
                </div>

              </div>

              <div className={styles.action}>
                <button onClick={onCancel} className={StringTool.mergeClassName(styles.button)}>
                  Đóng
                </button>
                <button onClick={onSave} className={StringTool.mergeClassName(styles.button, styles.primary)}>
                  Lưu
                </button>
              </div>

            </div>
          </div>
        </div>
      }
    </div>
  );

}

export default TimeInput;
