import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { UrlConfig } from '../../../config/urlConfig';
import { AlertTypeEnum, CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool } from '../../../tool';
import ActionButton from '../actionButton/actionButton';

const useStyles = makeStyles({
  dialog: {
    width: '391px'
  },
  textArea: {
    width: '100%',
    '& .MuiInputBase-root textarea': {
      fontSize: '12px'
    }
  },
  textTitle: {
    font: '22px'
  },
  textSaved: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#00000060'
  }
})
function UpdatePlaceNote({ onClose, savedPlaceGroup, onChange }) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const schema = yup.object().shape({
    description: yup.string()
  })

  const { register, handleSubmit, errors, } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    let params = {
      groupId: savedPlaceGroup?.groupId,
      placeId: savedPlaceGroup?.placeId,
      description: data?.description
    }
    ApiTool.post(UrlConfig.placeGroup.updatePlaceDescription, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.edit, {
            object: Resource.common.note.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        onClose && onClose()
        onChange && onChange()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.edit, {
            object: Resource.common.note.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <Box textAlign='center' p={2} width='100%'>
        <Typography variant='h6' className={classes.textTitle}>{savedPlaceGroup?.note ? Resource.note.edit : Resource.note.add}</Typography>
        <Typography variant='subtitle2'>{savedPlaceGroup.placeName}</Typography>
        <Box width='100%' title={savedPlaceGroup?.groupName} className={classes.textSaved}>
          {`${Resource.common.savedInto} ` + `${savedPlaceGroup?.groupName}`}
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <TextField
            name='description'
            className={classes.textArea}
            variant='outlined'
            multiline
            rows={5}
            rowsMax={10}
            inputRef={register}
            defaultValue={savedPlaceGroup?.note}
            placeholder={savedPlaceGroup?.note ? '' : Resource.note.addNoteForThisPlace}
          />

        </DialogContent>
        <DialogActions>
          <Box width='100%' textAlign='center'>
            <ActionButton name={Resource.button.cancel} onClick={onClose} isSecondary />
            <ActionButton name={Resource.button.done} type='submit' />
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

UpdatePlaceNote.propTypes = {
  //
};

export default UpdatePlaceNote;
