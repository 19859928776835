import PropTypes from 'prop-types';
import React from 'react';
import { Resource } from '../../../resource';
import './mobileListStepButton.scss';

const LIST_STEP_ICON =
<svg width="18" height="18" viewBox="0 0 24 24">
    <path id="Path_6825" data-name="Path 6825" d="M0,0H24V24H0Z" fill="none"/>
    <path id="Path_11856" data-name="Path 11856" d="M8,4H21V6.015H8ZM4.5,6.518A1.509,1.509,0,1,1,6,5.009,1.5,1.5,0,0,1,4.5,6.518Zm0,7.041A1.509,1.509,0,1,1,6,12.05,1.5,1.5,0,0,1,4.5,13.559Zm0,6.941A1.509,1.509,0,1,1,6,18.991,1.5,1.5,0,0,1,4.5,20.5ZM8,11.044H21v2.012H8Zm0,7.041H21V20.1H8Z" transform="translate(0 0.5)" fill="#1c75bc"/>
</svg>

function MobileListStepButton(props) {
  const { onClick } = props

  const onClickBtn = () => {
    onClick && onClick()
  }

  return (
    <div className='mobileListStepButtonCpn' onClick={onClickBtn}>
      <div className='icon'>
        {LIST_STEP_ICON}
      </div>
      <div className='caption'>
        {Resource.common.steps}
      </div>
    </div>
  )
};

MobileListStepButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobileListStepButton;
