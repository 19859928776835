import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, RadioButton } from 'ui';
import { DirectionDepartEnum } from '../../enum';
import { Resource } from '../../resource';
import DatePickerV2 from '../datePickerV2/datePickerV2';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import MobileTimePicker from '../mobileTimePicker/mobileTimePicker';
import './mobileDepartAt.scss';

const MobileDepartAt = (props) => {

  const { departTime, onDone, onCancel } = props

  const DepartSelect = [
    {
      name: Resource.common.departNow,
      value: DirectionDepartEnum.now
    },
    {
      name: Resource.common.departAt,
      value: DirectionDepartEnum.at
    },
  ]

  const [departState, setDepartState] = useState(DirectionDepartEnum.now)
  const [dateState, setDateState] = useState(new Date())

  useEffect(() => {
    if (departTime) {
      let departAt = new Date(departTime)
      setDateState(departAt)
      setDepartState(DirectionDepartEnum.at)
    }
    else {
      setDepartState(DirectionDepartEnum.now)
    }
  }, [departTime])

  const onChangeHour = (value) => {
    setDateState(value)
  }
  const handleChangeDepart = (e) => {
    let value = e?.target?.value
    setDepartState(value)
  }

  const handleChangeDate = (newValue) => {
    setDateState(newValue)
  }
  const onClosePopup = () => {
    onCancel?.()
  }
  const onSave = () => {
    onDone?.(departState == DirectionDepartEnum.at ? dateState : null)
  }
  return (
    <Dialog open={true} style={{ minWidth: "15.625rem" }}>
      <DialogBody>
        <div className='mobileDepartAtContainer'>
          <div className='title'>{Resource.hour.chosen}</div>
          <div className='departSelect'>
            {
              DepartSelect?.map((item) => {
                return (
                  <RadioButton
                    checked={departState === item?.value}
                    value={item?.value}
                    label={item?.name}
                    onChange={handleChangeDepart}
                    key={item?.value}
                    className='radioButtonDepartAt'
                  />
                )
              })
            }
          </div>
          <div className='chosenBoxTime'>
            <div className='label'>{Resource.common.hour}</div>
            <div style={{ width: "5.75rem" }}>
              <MobileTimePicker value={dateState} onChange={onChangeHour} disabled={departState == DirectionDepartEnum.now} />
            </div>
          </div>
          <div className='chosenBoxDate'>
            <div className='label'>{Resource.common.day}</div>
            <div style={{ width: "9.25rem" }}>
              <DatePickerV2
                value={dateState}
                isMobile
                disabled={departState == DirectionDepartEnum.now}
                onChange={handleChangeDate}
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogAction>
        <Button variant='close' onClick={onClosePopup}>{Resource.button.cancel.toUpperCase()}</Button>
        <Button variant='save' onClick={onSave}>{Resource.button.done.toUpperCase()}</Button>
      </DialogAction>
    </Dialog>
  );
};

MobileDepartAt.propTypes = {
  departTime: PropTypes.any,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
};

export default MobileDepartAt;
