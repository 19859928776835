import { Box, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { AppData } from '../../data';
import { GeometryTypeEnum, MapEventEnum } from '../../enum';
import { Resource } from '../../resource';
import './editRoad.css';

const maxHeight = 320
const width = 180
const useStyles = makeStyles({
  listMenu: {
    position: 'absolute',
    zIndex: 1000,
  },
  paper: {
    width: 'max-content',
    maxWidth: width + "px",
    position: 'absolute',
    backgroundColor: 'white',
  }
})

const EditRoad = ({ geometry, onChange }) => {
  const classes = useStyles()

  const ICON_MARKER = `<div class="marker-container"><div class="marker"></div></div>`
  const ICON_VIRTUAL_MARKER = `<div class="marker-container virtual"><div class="marker virtual"></div></div>`

  const [menu, setMenu] = useState({
    show: false,
    position: {
      left: 0,
      top: 0
    },
    anchor: {
      x: "left",
      y: "top"
    }
  })
  const [rightClickingMarker, setRightClickingMarker] = useState({})

  const geometryStateRef = useRef(null)
  const polylineChangeRef = useRef(null)
  const markerListRef = useRef([[]])
  const virtualPolylineRef = useRef(null)
  const isHideMarkersRef = useRef(true)
  const geometryTypeRef = useRef(GeometryTypeEnum.lineString)

  useEffect(() => {
    if (geometry != geometryStateRef.current) {
      const { coordinates } = convertToMultiLineString(geometry)
      coordinates.map(
        (polyline, indexPolyline) => polyline.map(
          (point, index) =>
            addMarker
              (
                point,
                indexPolyline,
                index
              )
        )
      )
      geometryStateRef.current = convertToMultiLineString(geometry)
      geometryTypeRef.current = geometry.type
      drawPoly(convertToMultiLineString(geometry).coordinates)
    }
    return () => {
      removePolyline()
      removeMarker()
    }
  }, [geometry])

  const removePolyline = () => {
    polylineChangeRef.current.map(
      polyline => polyline.setMap(null)
    )
  }

  const convertToMultiLineString = (data) => {
    if (data.type === GeometryTypeEnum.lineString) {
      return {
        type: GeometryTypeEnum.multiLineString,
        coordinates: [data.coordinates]
      }
    }
    return data
  }

  const convertDataOnChange = (data) => {
    if (geometryTypeRef.current === GeometryTypeEnum.lineString) {
      return {
        type: GeometryTypeEnum.lineString,
        coordinates: data.coordinates[0]
      }
    }
    return data
  }

  const removeMarker = () => {
    markerListRef.current.map(
      polyline => polyline.map(
        marker => marker.setMap(null)
      )
    )
    markerListRef.current.length = 0
  }

  useEffect(() => {
    let idle = AppData.map.addListener(
      MapEventEnum.idle,
      args => {
        if (!isHideMarkersRef.current) {
          isHideMarkersRef.current = true

          markerListRef.current.map(
            polyline => polyline.map(
              marker => marker.setMap(AppData.map)
            )
          )
        }
      }
    )

    let cameraWillChange = AppData.map.addListener(
      MapEventEnum.cameraWillChange,
      args => {
        if (isHideMarkersRef.current) {
          isHideMarkersRef.current = false

          markerListRef.current.map(
            polyline => polyline.map(
              marker => marker.setMap(null)
            )
          )
        }
      }
    )

    let clickMarker = AppData.map.addListener(
      MapEventEnum.click,
      args => {
        const { type, polylineIndex, index, isVirtual } = args.marker.getUserData()
        if (type === 'boundaryMarker' && !isVirtual) {
          removeAllVirtualMarkers()
          let newAreaState = JSON.parse(JSON.stringify(geometryStateRef.current))
          const currentPoly = newAreaState.coordinates[polylineIndex]
          addNewVirtualMarkers(polylineIndex, index, currentPoly)
        }
        setMenu({ show: false })
      },
      { marker: true }
    )

    let arg = null

    let draggingMarker = AppData.map.addListener(
      MapEventEnum.drag,
      args => {
        const { type } = args.marker.getUserData()
        if (type === 'boundaryMarker') {
          onDraggingMarker(args)
        }
        setMenu({ show: false })
        arg = args
      },
      { marker: true }
    )

    let dragMarkerEnd = AppData.map.addListener(
      MapEventEnum.dragEnd,
      args => {
        const { type } = args.marker.getUserData()
        setMenu(prev => ({ ...prev, show: false }))
        if (type === 'boundaryMarker') {
          onDragMarkerEnd(args)
          virtualPolylineRef?.current?.setMap(null)
          virtualPolylineRef.current = null
        }
        setMenu({ show: false })
        arg = args
      },
      { marker: true }
    )

    document.onmouseup = e => {
      if (arg) {
        const { type } = arg?.marker?.getUserData()
        setMenu(prev => ({ ...prev, show: false }))
        if (type === 'boundaryMarker') {
          onDragMarkerEnd(arg)
          virtualPolylineRef?.current?.setMap(null)
          virtualPolylineRef.current = null
        }
        setMenu({ show: false })
        arg = null
      }
    }

    let rightClickMarker = AppData.map.addListener(
      MapEventEnum.rightClick,
      args => {
        const { type } = args.marker.getUserData()
        if (type === 'boundaryMarker') {
          let length = 0
          geometryStateRef.current.coordinates.map(
            polyline => {
              if (polyline.length > length) {
                length = polyline.length
              }
            }
          )
          if (length > 2) {
            onRightClick(args)
          }
        }
      },
      { marker: true }
    )

    let eventClick = AppData.map.addListener(MapEventEnum.click,
      () => {
        setMenu({ show: false })
        setRightClickingMarker({})
      }
    )

    let eventBoundsChanged = AppData.map.addListener(MapEventEnum.boundsChanged,
      () => {
        setMenu({ show: false })
        setRightClickingMarker({})
      }
    )

    return () => {
      cameraWillChange?.remove()
      idle?.remove()
      clickMarker?.remove()
      draggingMarker?.remove()
      eventBoundsChanged?.remove()
      eventClick?.remove()
      dragMarkerEnd?.remove()
      rightClickMarker?.remove()
      polylineChangeRef?.current?.map(
        polyline => polyline.setMap(null)
      )
      document.onmouseup = () => { }
    }
  }, [])


  const drawPoly = (data) => {
    if (data && data.length > 0) {
      polylineChangeRef.current = []
      for (let i = 0; i < data.length; i++) {
        let polyline = new map4d.Polyline({
          path: data[i],
          strokeColor: '#ff0000',
          strokeOpacity: 1,
          strokeWidth: 7,
          userInteractionEnabled: false,
        })
        polyline.setMap(AppData.map)
        polylineChangeRef.current.push(polyline)
      }
    }
  }

  const addMarker = (point, polylineIndex, index, isVirtual = false) => {
    if (!markerListRef.current[polylineIndex]) {
      markerListRef.current[polylineIndex] = []
    }

    const currentPoly = markerListRef.current[polylineIndex]
    const marker = new map4d.Marker({
      position: { lat: point[1], lng: point[0] },
      draggable: true,
      iconView: ICON_MARKER,
      anchor: [0.5, 0.5]
    })
    marker.setUserData(
      {
        type: 'boundaryMarker',
        polylineIndex,
        index,
        isVirtual
      }
    )

    marker.setMap(AppData.map)

    if (polylineIndex !== undefined && index !== undefined) {
      currentPoly.splice(index, 0, marker)
    } else {
      markerListRef?.current?.push(marker)
    }
  }

  const addNewVirtualMarkers = (polylineIndex, index, currentRingPoly) => {
    const nextNote = index === currentRingPoly.length - 1 ? null : currentRingPoly[index + 1]
    const backNote = index === 0 ? null : currentRingPoly[index - 1]
    const currentNote = currentRingPoly[index]

    if (nextNote) {
      const newMarker = new map4d.Marker({
        position: { lat: (nextNote[1] + currentNote[1]) / 2, lng: (nextNote[0] + currentNote[0]) / 2 },
        draggable: true,
        iconView: ICON_VIRTUAL_MARKER,
        anchor: [0.5, 0.5]
      })
      newMarker.setUserData(
        {
          type: 'boundaryMarker',
          polylineIndex,
          index,
          isVirtual: true,
          position: 'next'
        }
      )
      newMarker.setMap(AppData.map)
      markerListRef.current[polylineIndex].splice(index + 1, 0, newMarker)
    }

    if (backNote) {
      const newMarker = new map4d.Marker({
        position: { lat: (backNote[1] + currentNote[1]) / 2, lng: (backNote[0] + currentNote[0]) / 2 },
        draggable: true,
        iconView: ICON_VIRTUAL_MARKER,
        anchor: [0.5, 0.5]
      })
      newMarker.setUserData(
        {
          type: 'boundaryMarker',
          polylineIndex,
          index,
          isVirtual: true,
          position: 'back',
        }
      )
      newMarker.setMap(AppData.map)
      markerListRef.current[polylineIndex].splice(index, 0, newMarker)
    }
  }

  const removeAllVirtualMarkers = () => {
    markerListRef.current.map(
      (polyline, polylineIndex) => {
        for (let i = polyline.length - 1; i >= 0; i--) {
          const { isVirtual } = polyline[i].getUserData()
          if (isVirtual) {
            polyline[i].setMap(null)
            markerListRef.current[polylineIndex].splice(i, 1)
          }
        }
      }
    )
  }

  const changeMarkersIndex = (impactIndex, currentPoly, action = 'add') => {
    if (action === 'add') {
      for (let i = currentPoly.length - 1; i > impactIndex; i--) {
        const { polylineIndex, index, type } = currentPoly[i].getUserData()
        currentPoly[i].setUserData(
          {
            type,
            polylineIndex,
            index: index + 1,
          }
        )
      }
    }

    if (action === 'delete') {
      for (let i = currentPoly.length - 1; i >= impactIndex; i--) {
        const { polylineIndex, index, type } = currentPoly[i].getUserData()
        currentPoly[i].setUserData(
          {
            type,
            polylineIndex,
            index: index - 1,
          }
        )
      }
    }
  }

  const onDragMarkerEnd = (args) => {
    const { polylineIndex, index, isVirtual, position } = args.marker.getUserData()
    const { lat, lng } = args.marker.getPosition()
    let newAreaState = JSON.parse(JSON.stringify(geometryStateRef.current))
    const currentRingPoly = newAreaState.coordinates[polylineIndex]
    const currentPoly = markerListRef.current[polylineIndex]

    if (!isVirtual) {
      currentRingPoly[index] = [lng, lat]
      removeAllVirtualMarkers()
      addNewVirtualMarkers(polylineIndex, index, currentRingPoly)
    } else {
      const newNote = [lng, lat]

      if (position === 'back') {
        currentPoly[index].setUserData(
          {
            type: 'boundaryMarker',
            polylineIndex,
            index,
            isVirtual: false,
          }
        )
        currentPoly[index].setIconView(ICON_MARKER)
        removeAllVirtualMarkers()
        currentRingPoly.splice(index, 0, newNote)
        changeMarkersIndex(index, currentPoly)
      } else {
        const { isVirtual } = currentPoly[index].getUserData()
        let newIndex = index + 1
        if (isVirtual) {
          newIndex = index + 2
        }
        currentPoly[newIndex].setUserData(
          {
            type: 'boundaryMarker',
            polylineIndex,
            index: index + 1,
            isVirtual: false,
          }
        )
        currentPoly[newIndex].setIconView(ICON_MARKER)
        removeAllVirtualMarkers()
        currentRingPoly.splice(index + 1, 0, newNote)
        changeMarkersIndex(index + 1, currentPoly)
      }
      addNewVirtualMarkers(polylineIndex, position === 'back' ? index : index + 1, currentRingPoly)
    }

    polylineChangeRef?.current?.map(
      polyline => polyline.setMap(null)
    )
    geometryStateRef.current = newAreaState
    markerListRef.current[polylineIndex] = currentPoly
    drawPoly(newAreaState.coordinates)
    onChange && onChange(convertDataOnChange(newAreaState))
  }

  const onDraggingMarker = (args) => {
    const { polylineIndex, index, isVirtual, position } = args.marker.getUserData()
    const { lat, lng } = args.marker.getPosition()
    let newAreaState = JSON.parse(JSON.stringify(geometryStateRef.current))
    const currentRingPoly = newAreaState.coordinates[polylineIndex]
    const currentPoint = [lng, lat]
    const backPoint = index === 0 ? null : currentRingPoly[index - 1]
    const nextPoint = index === currentRingPoly.length - 1 ? null : currentRingPoly[index + 1]
    let path = []

    virtualPolylineRef?.current?.setMap(null)

    if (!isVirtual) {
      backPoint && path.push(backPoint)
      path.push(currentPoint)
      nextPoint && path.push(nextPoint)
    } else {
      const parentPoint = currentRingPoly[index]
      if (position === 'next') {
        nextPoint && path.push(nextPoint)
        path.push(currentPoint)
        path.push(parentPoint)
      } else {
        backPoint && path.push(backPoint)
        path.push(currentPoint)
        path.push(parentPoint)
      }
    }
    virtualPolylineRef.current = new map4d.Polyline({
      path,
      style: 'dotted',
      strokeColor: '#576574',
      strokeWidth: 2
    })

    virtualPolylineRef?.current?.setMap(AppData.map)
  }

  const handleRemoveNode = () => {
    const { polylineIndex, index, isVirtual, position } = rightClickingMarker.marker.getUserData()
    const currentPoly = markerListRef.current[polylineIndex]

    if (isVirtual) {

      const virtualMarkerIndex = currentPoly.findIndex(
        marker => {
          const info = marker.getUserData()
          return (
            info.polylineIndex === polylineIndex &&
            info.index === index &&
            isVirtual &&
            info.position === position
          )
        }
      )
      currentPoly[virtualMarkerIndex].setMap(null)
      currentPoly.splice(virtualMarkerIndex, 1)
    } else {
      removeAllVirtualMarkers()
      let newAreaState = JSON.parse(JSON.stringify(geometryStateRef.current))
      const currentRingPoly = newAreaState.coordinates[polylineIndex]
      const currentPoly = markerListRef.current[polylineIndex]
      currentRingPoly.splice(index, 1)
      currentPoly[index].setMap(null)
      currentPoly.splice(index, 1)
      changeMarkersIndex(index, currentPoly, 'delete')
      markerListRef.current[polylineIndex] = currentPoly
      geometryStateRef.current = newAreaState
      polylineChangeRef?.current?.map(
        polyline => polyline.setMap(null)
      )
      drawPoly(newAreaState.coordinates)
      onChange && onChange(convertDataOnChange(newAreaState))
    }
    setMenu(prev => ({ ...prev, show: false }))
  }

  const onRightClick = (args) => {
    const point = args?.pixel;
    let pointTemp = {
      left: point.x,
      top: point.y + 10
    }
    let anchorTemp = {
      x: "left",
      y: "top"
    }

    if (pointTemp.left + width >= window.innerWidth) {
      anchorTemp.x = "right"
    }

    if (pointTemp.top + maxHeight >= window.innerHeight) {
      anchorTemp.y = "bottom"
    }

    setMenu({
      show: true,
      position: pointTemp,
      anchor: anchorTemp
    })

    setRightClickingMarker(args)
  }

  return (
    <>
      {
        menu.show &&
        <Box className={classes.listMenu} left={menu.position?.left + "px"} top={menu.position?.top + "px"}>
          <Paper
            onContextMenu={e => e.preventDefault()}
            style={{
              [menu?.anchor?.x]: "0",
              [menu?.anchor?.y]: "0"
            }}
            className={classes.paper}
          >
            <ListItem button onClick={handleRemoveNode}>
              {Resource.common.removeNode}
            </ListItem>
          </Paper>
        </Box>
      }
    </>
  )
};

export default EditRoad;
