import { AppConfig } from "./appConfig";

export const CommonConfig = {
  minZoom3d: 17,
  sizeIcon: 26,
  nearbyRadius: 1000,
  source: {
    name: "vmp",
  },
  building: {
    defaultType: "decoration",
  },
  maxDate: new Date("1/1/2100"),
  minDate: new Date("1/1/1900"),
  maxPoi: 6,
  defaultLocation: {
    lat: 16.061445,
    lng: 108.23678,
  },
  detailPlace2DZoom: 16,
  maxHistory: 20,
  widthSearchBar: 408,
  padding: 6,
  heightSearchBar: 48,
  widthBtnAction: 120,
  heightBtnAction: 48,
  strokeBlue: "#508FF4",
  strokeGrey: "#CCCCCC",
  outlineBlue: "#1A73E8",
  outlineGrey: "#9E9E9E",
  radiusSearchBuilding: 1000,
  pageSizeSearch: 10,
  searchBar: {
    height: 48,
  },
  lat: {
    step: 0.00001,
  },
  lng: {
    step: 0.00001,
  },
  field: {
    spacingY: 24,
  },
  defaultMapOptions: {
    search: "",
    center: [108.23678, 16.061445],
    zoom: 6,
    tilt: 0,
    bearing: 0,
    accessKey: AppConfig.mapKey,
    geolocate: true,
  },
  mobile: {
    home: {
      padding: 16,
    },
    searchBar: {
      height: 48,
    },
    topBar: {
      height: 50,
    },
    draggableBar: {
      middleHeight: {
        home: 304,
        place: {
          search: 304,
          detail: 300,
        },
      },
      minHeight: {
        home: 50,
        place: {
          search: 50,
        },
        direction: 50,
      },
    },
    placeDetail: {
      padding: 16,
      heightIframeItem: 25.28,
      heightIframeDetail: 56.25,
    },
  },
  maxAccuracy: 100,
};
