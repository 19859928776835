import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowPoiControlState } from '../../appState';

const HidePoiControl = () => {
  const setShow = useSetRecoilState(ShowPoiControlState)

  useEffect(() => {
    setShow(false)
    return () => {
      setShow(true)
    }
  }, [])

  return null
};

HidePoiControl.propTypes = {
  //
};

export default HidePoiControl;
