import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowLocationControlState } from '../../appState';

const HideLocationControl = () => {
  const setShow = useSetRecoilState(ShowLocationControlState)

  useEffect(() => {
    setShow(false)
    return () => {
      setShow(true)
    }
  }, [])

  return null
};

HideLocationControl.propTypes = {
  //
};

export default HideLocationControl;
