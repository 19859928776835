export class AppEventTool {
    static eventDict = {}
    static addListener = (eventEnum, func) => {
        let event = { ...this.eventDict };
        let newArrayFunction = [...(event[eventEnum] || [])];
        newArrayFunction.push(func);
        event[eventEnum] = newArrayFunction;
        this.eventDict = event;
        return {
            remove: () => { this.removeListener(eventEnum, func) }
        };
    }
    static triggerEvent = (eventEnum, data) => {
        this.eventDict[eventEnum]?.forEach(func => {
            func(data)
        });
    }
    static removeListener = (eventEnum, func) => {
        let event = { ...this.eventDict };
        let newArrayFunction = event[eventEnum]?.filter(item => item !== func) || [];
        event[eventEnum] = newArrayFunction;
        this.eventDict = event;
    }
}