import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItemText, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
}))

function Error({ message, subMessage, isItalic }) {
  const classes = useStyles()
  return (
    <>
      <Box p={1} className={classes.borderShadow}>
        <Typography variant='subtitle2'>{message}</Typography>
        <Typography variant='body2' style={isItalic ? { fontStyle: 'italic', color: 'rgb(0 0 0 / 54%)', fontSize: '13px', lineHeight: '20px' } : { fontStyle: 'inherit' }}>{subMessage}</Typography>
      </Box>
    </>
  )
}

Error.propTypes = {
  message: PropTypes.node,
  subMessage: PropTypes.node
};

export default Error;
