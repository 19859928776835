import { TypeAddressEnum } from "../enum";

export class AddressTool {
    static getFullAddress = (addressComponents) => {
        let provinceName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.province))?.name;
        let districtName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.district))?.name;
        let subDistrictName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.subDistrict))?.name;
        let streetName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.street))?.name;
        let hamletName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.hamlet))?.name;
        let houseNumberName = addressComponents?.find((x) => x.types?.includes(TypeAddressEnum.houseNumber))?.name;
        let arrAddress = [streetName, hamletName, subDistrictName, districtName, provinceName];
        let address = arrAddress.filter((t) => {
            return t;
        });
        let fullAddress = houseNumberName ? (houseNumberName + ' ').concat(address?.join(", ")) : address?.join(", ")
        return fullAddress
    };
}