
import { Tooltip } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  FacebookShareButton, TwitterShareButton
} from "react-share";
import { useRecoilValue } from 'recoil';
import { Button, Tab, Tabs, TextInput } from 'ui';
import { DirectionDataState, SummaryDirectionState } from '../../../appState';
import { LinkIconConfig } from '../../../config';
import { AlertTypeEnum, ModeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AppTool, DirectionTool, LocationTool } from '../../../tool';
import Dialog from '../../dialog/dialog';
import DialogBody from '../../dialogBody/dialogBody';
import DialogHeader from '../../dialogHeader/dialogHeader';
import { useIsMobileScreen } from './../../../useHooks/useIsMobileScreen';
import './shareControl.scss';

const TypeIconModeEnum = {
  [ModeEnum.bike]: LinkIconConfig.routes.bikeWhite,
  [ModeEnum.car]: LinkIconConfig.routes.carWhite,
  [ModeEnum.foot]: LinkIconConfig.routes.footWhite,
  [ModeEnum.motorcycle]: LinkIconConfig.routes.motorcycleWhite
}

const getTextPoint = (point) => {
  let text = point?.poiId ? point?.poiId : point?.id
  let { name, address, textInput } = point || {}
  if (name) {
    text = name
  }
  else if (address) {
    text = parseInt(address?.split(',')[0]) ? address?.split(',')[0] + ", " + address?.split(',')[1] : address?.split(',')[0]
  }
  else if (textInput) {
    let checkLoation = LocationTool.checkTextIsLocation(textInput)
    text = checkLoation ? textInput : (parseInt(textInput?.split(',')[0]) ? textInput?.split(',')[0] + ", " + textInput?.split(',')[1] : textInput?.split(',')[0])
  }
  return text
}

function ShareControl(props, ref) {
  const isMobileScreen = useIsMobileScreen()
  const urlShareRef = useRef(location.href)
  const inputRef = useRef()

  const [openDialog, setOpenDialog] = useState(false)
  const [tabState, setTabState] = useState(Resource.common.path)
  const directionData = useRecoilValue(DirectionDataState)
  const summaryDirection = useRecoilValue(SummaryDirectionState)

  const onCloseDialog = () => {
    setOpenDialog(false)
    setTabState(Resource.common.path)
  }
  const handleChangeTab = (index, tab) => {
    setTabState(tab)
  }

  const handleCopy = () => {
    // inputRef.current.select();
    // document.execCommand('copy')
    var copyText = inputRef.current?.value
    navigator.clipboard.writeText(copyText)
      .then(() => {
        AppTool.alert(Resource.alert.success.copied, AlertTypeEnum.success)
      })
      .catch((error) => { });
  }

  useImperativeHandle(ref, () => {
    return {
      share: (url = null) => {
        urlShareRef.current = url || location.href
        if (isMobileScreen) {
          if (navigator.share) {
            // alert("ok")
            navigator.share({
              title: 'Map4d',
              // text: 'Check out web.dev.',
              url: urlShareRef.current,
            })
              .then(() => { })
              .catch((error) => { });
          }
          else {
            setOpenDialog(true)
          }
        }
        else {
          setOpenDialog(true)
        }
      }
    }
  })

  let linkFrame = urlShareRef.current || window.location?.href
  if (!linkFrame.includes("object3d/detail")) {
    linkFrame = linkFrame?.replace("/map/", "/embed/")
  }
  const iFrameCode = '<iframe src="' + linkFrame + '" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>'

  return (
    <Dialog
      onClose={onCloseDialog}
      open={openDialog}
    >
      <div style={{ boxShadow: "0px 0px 6px #00000029" }}>
        <DialogHeader>
          {Resource.common.share}
        </DialogHeader>
      </div>
      <DialogBody>
        <div className='shareControlCpn'>
          <Tabs items={[Resource.common.path, Resource.common.embed]} value={tabState} onChange={handleChangeTab} className="padding">
            <Tab>
              <div className='padding'>
                {summaryDirection && <div className='boxInfor'>
                  <div className='iconMode'><img src={TypeIconModeEnum[directionData?.mode]} width={24} height={24} alt="icon-mode" /></div>
                  <div className='contentDirection'>
                    <div className='title'>
                      {getTextPoint(directionData?.points?.[0]) + " đến " + getTextPoint(directionData?.points?.[1])}
                    </div>
                    <div className='subTitle'>{"Qua " + summaryDirection}</div>
                  </div>
                </div>}
                <div className='boxLinkShare'>
                  <TextInput
                    value={urlShareRef.current || location.href}
                    label={Resource.common.linkShare}
                    readOnly
                    inputRef={inputRef}
                    style={{
                      minWidth: "310px"
                    }}
                  />
                  <Button variant='done' onClick={handleCopy} className="iconCopy" style={{ borderRadius: "0.625rem" }}>{Resource.common.copy}</Button>
                </div>
              </div>
              <div className='boxShareButton'>
                <FacebookShareButton url={urlShareRef.current || window.location.href}>
                  <img src={LinkIconConfig.common.facebook} />
                  <div className='iconText'>Facebook</div>
                </FacebookShareButton>
                <TwitterShareButton url={urlShareRef.current || window.location?.href}>
                  <img src={LinkIconConfig.common.twitter} />
                  <div className='iconText'>Twitter</div>
                </TwitterShareButton>
              </div>
            </Tab>
            <Tab>
              <div className='boxEmbed'>
                <div className='boxLinkShare'>
                  <TextInput
                    value={iFrameCode || ''}
                    label={Resource.common.linkShare}
                    readOnly
                    inputRef={inputRef}
                    style={{
                      minWidth: "482px"
                    }}
                  />
                  <Button variant='done' onClick={handleCopy} className="iconCopy" style={{ borderRadius: "0.625rem" }}>{Resource.common.copy}</Button>
                </div>
                <iframe style={{ maxWidth: "100%" }} src={linkFrame} height="450" width='600' frameBorder="0"
                  allowFullScreen={true} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </DialogBody>
    </Dialog>
  )
}
ShareControl = forwardRef(ShareControl)
export default ShareControl;
