import { Box, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { AppData } from '../../data';
import { GeometryTypeEnum, MapEventEnum } from '../../enum';
import { Resource } from '../../resource';
import { GeoJsonTool } from '../../tool';
import './editArea.css';

const maxHeight = 320
const width = 180
const useStyles = makeStyles({
  listMenu: {
    position: 'absolute',
    zIndex: 1000,
  },
  paper: {
    width: 'max-content',
    maxWidth: width + "px",
    position: 'absolute',
    backgroundColor: 'white',
  },
})

const EditArea = ({ geometry, onChange }) => {
  const classes = useStyles()

  const ICON_MARKER = `<div class="editAreaMarker"><div class="point"></div></div>`
  const ICON_VIRTUAL_MARKER = `<div class="editAreaMarker virtual"><div class="point"></div></div>`

  const [menu, setMenu] = useState({
    show: false,
    position: {
      left: 0,
      top: 0
    },
    anchor: {
      x: "left",
      y: "top"
    }
  })
  const [rightClickingMarker, setRightClickingMarker] = useState({})

  const geometryRef = useRef(null)
  const polygonChangeRef = useRef([])
  const markerListRef = useRef([[[]]])
  const virtualPolylineRef = useRef(null)
  const isHideMarkersRef = useRef(true)
  const geometryTypeRef = useRef('Polygon')

  useEffect(() => {
    if (geometry != geometryRef.current) {
      const newGeometry = GeoJsonTool.convertPolygonToPoints(geometry)
      newGeometry.coordinates.map(
        (polygon, indexPolygon) => polygon.map(
          (points, indexRing) => points.map(
            (point, index) =>
              addMarker
                (
                  point,
                  indexPolygon,
                  indexRing,
                  index
                )
          )
        )
      )

      geometryTypeRef.current = geometry.type
      geometryRef.current = geometry
      drawPoly(geometry)
    }

    return () => {
      markerListRef.current.map(
        polygons => polygons.map(
          rings => rings.map(
            marker => marker.setMap(null)
          )
        )
      )
      markerListRef.current = [[[]]]
      removePolygon()
    }
  }, [geometry])

  useEffect(() => {
    let idle = AppData.map.addListener(
      MapEventEnum.idle,
      args => {
        if (!isHideMarkersRef.current) {
          isHideMarkersRef.current = true

          markerListRef.current.map(
            polygon => polygon.map(
              ring => ring.map(
                marker => marker.setMap(AppData.map)
              )
            )
          )
        }
      }
    )

    let cameraWillChange = AppData.map.addListener(
      MapEventEnum.cameraWillChange,
      args => {
        if (isHideMarkersRef.current) {
          isHideMarkersRef.current = false

          markerListRef.current.map(
            polygon => polygon.map(
              ring => ring.map(
                marker => marker.setMap(null)
              )
            )
          )
        }
      }
    )

    let clickMarker = AppData.map.addListener(
      MapEventEnum.click,
      args => {
        const { type, polygonIndex, ringIndex, index, isVirtual } = args.marker.getUserData()
        if (type === 'boundaryMarker' && !isVirtual) {
          removeAllVirtualMarkers()
          let newAreaState = GeoJsonTool.convertPolygonToPoints(JSON.parse(JSON.stringify(geometryRef.current)))
          const currentRingPoly = newAreaState.coordinates[polygonIndex][ringIndex]
          addNewVirtualMarkers(polygonIndex, ringIndex, index, currentRingPoly)
        }
        setMenu({ show: false })
      },
      { marker: true }
    )

    let arg = null

    let draggingMarker = AppData.map.addListener(
      MapEventEnum.drag,
      args => {
        const { type } = args.marker.getUserData()
        if (type === 'boundaryMarker') {
          onDraggingMarker(args)
        }
        setMenu({ show: false })
        arg = args
      },
      { marker: true }
    )

    let dragMarkerEnd = AppData.map.addListener(
      MapEventEnum.dragEnd,
      args => {
        const { type } = args.marker.getUserData()
        setMenu(prev => ({ ...prev, show: false }))
        if (type === 'boundaryMarker') {
          onDragMarkerEnd(args)
          virtualPolylineRef?.current?.setMap(null)
          virtualPolylineRef.current = null
        }
        setMenu({ show: false })
        arg = args
      },
      { marker: true }
    )

    document.onmouseup = () => {
      if (arg) {
        const { type } = arg?.marker?.getUserData()
        setMenu(prev => ({ ...prev, show: false }))
        if (type === 'boundaryMarker') {
          onDragMarkerEnd(arg)
          virtualPolylineRef?.current?.setMap(null)
          virtualPolylineRef.current = null
        }
        arg = arg
        setMenu({ show: false })
        arg = null
      }
    }

    let rightClickMarker = AppData.map.addListener(
      MapEventEnum.rightClick,
      args => {
        const { type, polygonIndex, ringIndex } = args.marker.getUserData()
        if (type === 'boundaryMarker') {
          let length = 0
          if (geometryRef.current.type === GeometryTypeEnum.polygon) {
            length = geometryRef.current.coordinates[0].length
          }
          if (geometryRef.current.type === GeometryTypeEnum.multiPolygon) {
            length = geometryRef.current.coordinates[polygonIndex][ringIndex].length
          }
          if (length > 4) {
            onRightClick(args)
          }
        }
      },
      { marker: true }
    )

    let eventClick = AppData.map.addListener(MapEventEnum.click,
      () => {
        setMenu({ show: false })
        setRightClickingMarker({})
      }
    )

    let eventBoundsChanged = AppData.map.addListener(MapEventEnum.boundsChanged,
      () => {
        setMenu({ show: false })
        setRightClickingMarker({})
      }
    )

    return () => {
      cameraWillChange?.remove()
      idle?.remove()
      clickMarker?.remove()
      draggingMarker?.remove()
      eventBoundsChanged?.remove()
      eventClick?.remove()
      dragMarkerEnd?.remove()
      rightClickMarker?.remove()
      document.onmouseup = () => { }
      removePolygon()
    }
  }, [])

  const removePolygon = () => {
    polygonChangeRef?.current?.setMap ?
      polygonChangeRef?.current?.setMap(null) :
      polygonChangeRef?.current?.map(
        polygon => polygon.setMap(null)
      )
  }


  const drawPolygon = (data) => {
    polygonChangeRef.current = new map4d.Polygon({
      paths: [data[0]],
      fillColor: "#000000",
      fillOpacity: 0.1,
      strokeWidth: 1,
      userInteractionEnabled: true,
    })
    polygonChangeRef?.current?.setMap(AppData.map)
  }

  const drawMultiPolygon = (data) => {
    if (data && data.length > 0) {
      polygonChangeRef.current = []
      data.map(
        poly => {
          let polygon = new map4d.Polygon({
            paths: poly,
            fillColor: "#000000",
            fillOpacity: 0.2,
            strokeWidth: 1,
            userInteractionEnabled: false,
          })
          polygon.setMap(AppData.map)
          polygonChangeRef.current.push(polygon)
        }
      )
    }
  }

  const drawPoly = (geometry) => {
    if (geometry) {
      if (geometry?.type) {
        switch (geometry.type) {
          case GeometryTypeEnum.polygon:
            drawPolygon(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiPolygon:
            drawMultiPolygon(geometry.coordinates || [])
            break
        }
      }
    }
  }

  const addMarker = (point, polygonIndex, ringIndex, index, isVirtual = false) => {
    if (!markerListRef.current[polygonIndex]) {
      markerListRef.current[polygonIndex] = []
    }
    if (!markerListRef.current[polygonIndex][ringIndex]) {
      markerListRef.current[polygonIndex][ringIndex] = []
    }
    const currentRingMarker = markerListRef.current[polygonIndex][ringIndex]
    const marker = new map4d.Marker({
      position: { lat: point[1], lng: point[0] },
      draggable: true,
      iconView: ICON_MARKER,
      anchor: [0.5, 0.5]
    })
    marker.setUserData(
      {
        type: 'boundaryMarker',
        polygonIndex,
        ringIndex,
        index,
        isVirtual
      }
    )

    marker.setMap(AppData.map)

    if (polygonIndex !== undefined && ringIndex !== undefined && index !== undefined) {
      currentRingMarker.splice(index, 0, marker)
    } else {
      markerListRef?.current?.push(marker)
    }
  }

  const addNewVirtualMarkers = (polygonIndex, ringIndex, index, currentRingPoly) => {
    const nextNote = index === currentRingPoly.length - 1 ? currentRingPoly[0] : currentRingPoly[index + 1]
    const backNote = index === 0 ? currentRingPoly[currentRingPoly.length - 1] : currentRingPoly[index - 1]
    const currentNote = currentRingPoly[index]

    if (nextNote) {
      const newMarker = new map4d.Marker({
        position: { lat: (nextNote[1] + currentNote[1]) / 2, lng: (nextNote[0] + currentNote[0]) / 2 },
        draggable: true,
        iconView: ICON_VIRTUAL_MARKER,
        anchor: [0.5, 0.5]
      })
      newMarker.setUserData(
        {
          type: 'boundaryMarker',
          polygonIndex,
          ringIndex,
          index,
          isVirtual: true,
          position: 'next'
        }
      )
      newMarker.setMap(AppData.map)
      markerListRef.current[polygonIndex][ringIndex].splice(index + 1, 0, newMarker)
    }

    if (backNote) {
      const newMarker = new map4d.Marker({
        position: { lat: (backNote[1] + currentNote[1]) / 2, lng: (backNote[0] + currentNote[0]) / 2 },
        draggable: true,
        iconView: ICON_VIRTUAL_MARKER,
        anchor: [0.5, 0.5]
      })
      newMarker.setUserData(
        {
          type: 'boundaryMarker',
          polygonIndex,
          ringIndex,
          index,
          isVirtual: true,
          position: 'back',
        }
      )
      newMarker.setMap(AppData.map)
      markerListRef.current[polygonIndex][ringIndex].splice(index, 0, newMarker)
    }
  }

  const removeAllVirtualMarkers = () => {
    markerListRef.current.map(
      (polygons, polygonsIndex) => polygons.map(
        (rings, ringsIndex) => {
          for (let i = rings.length - 1; i >= 0; i--) {
            const { isVirtual } = rings[i].getUserData()
            if (isVirtual) {
              rings[i].setMap(null)
              markerListRef.current[polygonsIndex][ringsIndex].splice(i, 1)
            }
          }
        }
      )
    )
  }

  const changeMarkersIndex = (impactIndex, currentRingMarker, action = 'add') => {
    if (action === 'add') {
      for (let i = currentRingMarker.length - 1; i > impactIndex; i--) {
        const { polygonIndex, ringIndex, index, type } = currentRingMarker[i].getUserData()
        currentRingMarker[i].setUserData(
          {
            type,
            polygonIndex,
            ringIndex,
            index: index + 1,
          }
        )
      }
    }

    if (action === 'delete') {
      for (let i = currentRingMarker.length - 1; i >= impactIndex; i--) {
        const { polygonIndex, ringIndex, index, type } = currentRingMarker[i].getUserData()
        currentRingMarker[i].setUserData(
          {
            type,
            polygonIndex,
            ringIndex,
            index: index - 1,
          }
        )
      }
    }
  }

  const convertDataOnChange = (data) => {
    if (geometryTypeRef.current === GeometryTypeEnum.polygon) {
      return {
        type: GeometryTypeEnum.polygon,
        coordinates: data.coordinates[0]
      }
    }
    return data
  }

  const onDragMarkerEnd = (args) => {
    const { polygonIndex, ringIndex, index, isVirtual, position } = args.marker.getUserData()
    const { lat, lng } = args.marker.getPosition()
    let newAreaState = GeoJsonTool.convertPolygonToPoints(JSON.parse(JSON.stringify(geometryRef.current)))
    const currentRingPoly = newAreaState.coordinates[polygonIndex][ringIndex]
    const currentRingMarker = markerListRef.current[polygonIndex][ringIndex]
    if (!isVirtual) {
      currentRingPoly.splice(index, 1, [lng, lat])

      removeAllVirtualMarkers()
      addNewVirtualMarkers(polygonIndex, ringIndex, index, currentRingPoly)
    } else {
      const newNote = [lng, lat]

      if (position === 'back') {
        currentRingMarker[index].setUserData(
          {
            type: 'boundaryMarker',
            polygonIndex,
            ringIndex,
            index,
            isVirtual: false,
          }
        )
        currentRingMarker[index].setIconView(ICON_MARKER)
        removeAllVirtualMarkers()
        currentRingPoly.splice(index, 0, newNote)
        changeMarkersIndex(index, currentRingMarker)
      } else {
        const { isVirtual } = currentRingMarker[index].getUserData()
        let newIndex = index + 1
        if (isVirtual) {
          newIndex = index + 2
        }
        currentRingMarker[newIndex].setUserData(
          {
            type: 'boundaryMarker',
            polygonIndex,
            ringIndex,
            index: index + 1,
            isVirtual: false,
          }
        )
        currentRingMarker[newIndex].setIconView(ICON_MARKER)
        removeAllVirtualMarkers()
        currentRingPoly.splice(index + 1, 0, newNote)
        changeMarkersIndex(index + 1, currentRingMarker)
      }
      addNewVirtualMarkers(polygonIndex, ringIndex, position === 'back' ? index : index + 1, currentRingPoly)
    }

    removePolygon()
    newAreaState.coordinates.map(
      polygons => polygons.map(
        rings => {
          rings[0][0] !== rings[rings.length - 1][0] && rings[1] !== rings[rings.length - 1][1] && rings.push(rings[0])
        }
      )
    )
    geometryRef.current = newAreaState
    markerListRef.current[polygonIndex][ringIndex] = currentRingMarker
    drawPoly(newAreaState)
    onChange && onChange(convertDataOnChange(newAreaState))
  }

  const onDraggingMarker = (args) => {
    const { polygonIndex, ringIndex, index, isVirtual, position } = args.marker.getUserData()
    const { lat, lng } = args.marker.getPosition()
    let newAreaState = GeoJsonTool.convertPolygonToPoints(JSON.parse(JSON.stringify(geometryRef.current)))
    const currentRingPoly = newAreaState.coordinates[polygonIndex][ringIndex]
    const currentPoint = [lng, lat]
    const backPoint = currentRingPoly[index === 0 ? currentRingPoly.length - 1 : index - 1]
    const nextPoint = currentRingPoly[index === currentRingPoly.length - 1 ? 0 : index + 1]
    let path = []
    virtualPolylineRef?.current?.setMap(null)

    if (!isVirtual) {
      path = [
        backPoint,
        currentPoint,
        nextPoint
      ]
    } else {
      const parentPoint = currentRingPoly[index]
      if (position === 'next') {
        path = [
          nextPoint,
          currentPoint,
          parentPoint
        ]
      } else {
        path = [
          backPoint,
          currentPoint,
          parentPoint
        ]
      }
    }
    virtualPolylineRef.current = new map4d.Polyline({
      path,
      style: 'dotted',
      strokeColor: '#576574',
      strokeWidth: 2
    })

    virtualPolylineRef?.current?.setMap(AppData.map)
  }

  const handleRemoveNode = () => {
    const { polygonIndex, ringIndex, index, isVirtual, position } = rightClickingMarker.marker.getUserData()
    const currentRingMarker = markerListRef.current[polygonIndex][ringIndex]

    if (currentRingMarker.length > 3) {
      if (isVirtual) {

        const virtualMarkerIndex = currentRingMarker.findIndex(
          marker => {
            const info = marker.getUserData()
            return (
              info.polygonIndex === polygonIndex &&
              info.ringIndex === ringIndex &&
              info.index === index &&
              isVirtual &&
              info.position === position
            )
          }
        )
        currentRingMarker[virtualMarkerIndex].setMap(null)
        currentRingMarker.splice(virtualMarkerIndex, 1)
      } else {
        removeAllVirtualMarkers()
        let newAreaState = GeoJsonTool.convertPolygonToPoints(JSON.parse(JSON.stringify(geometryRef.current)))
        const currentRingPoly = newAreaState.coordinates[polygonIndex][ringIndex]
        const currentRingMarker = markerListRef.current[polygonIndex][ringIndex]
        currentRingPoly.splice(index, 1)
        currentRingMarker[index].setMap(null)
        currentRingMarker.splice(index, 1)
        changeMarkersIndex(index, currentRingMarker, 'delete')
        markerListRef.current[polygonIndex][ringIndex] = currentRingMarker
        newAreaState.coordinates.map(
          polygons => polygons.map(
            rings => rings.push(rings[0])
          )
        )
        geometryRef.current = newAreaState
        removePolygon()
        drawPoly(newAreaState)
        onChange && onChange(convertDataOnChange(newAreaState))
      }
    }
    setMenu(prev => ({ ...prev, show: false }))
  }

  const onRightClick = (args) => {
    const point = args?.pixel;
    let pointTemp = {
      left: point.x,
      top: point.y + 10
    }
    let anchorTemp = {
      x: "left",
      y: "top"
    }

    if (pointTemp.left + width >= window.innerWidth) {
      anchorTemp.x = "right"
    }

    if (pointTemp.top + maxHeight >= window.innerHeight) {
      anchorTemp.y = "bottom"
    }

    setMenu({
      show: true,
      position: pointTemp,
      anchor: anchorTemp
    })

    setRightClickingMarker(args)
  }

  return (
    <>
      {
        menu.show &&
        <Box className={classes.listMenu} left={menu.position?.left + "px"} top={menu.position?.top + "px"}>
          <Paper
            onContextMenu={e => e.preventDefault()}
            style={{
              [menu?.anchor?.x]: "0",
              [menu?.anchor?.y]: "0"
            }}
            className={classes.paper}
          >
            <ListItem button onClick={handleRemoveNode}>
              {Resource.common.removeNode}
            </ListItem>
          </Paper>
        </Box>
      }
    </>
  )
};

export default EditArea;
