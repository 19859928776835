import React, { useEffect, useRef, useState } from 'react';
import './avatar.css';
import PropTypes from 'prop-types';

const RATIO = 0.375
const AVATAR_TYPE = {
    text: 'text',
    image: 'image'
}

const Avatar = ({ firstName = "", lastName = "", email = "", image = "", size = "" }) => {
    const containerRef = useRef()
    const [avatarState, setAvatarState] = useState({
        content: '',
        type: AVATAR_TYPE.text
    })
    const [fontSize, setFontSize] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [sizeState, setSizeState] = useState('')

    const imgRef = useRef()

    const removeAccents = (str) => {
        return str.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    useEffect(() => {
        if (image) {
            setAvatarState(
                {
                    content: image,
                    type: AVATAR_TYPE.image
                }
            )
        }
        else if (lastName || firstName) {
            setAvatarState(
                {
                    content: removeAccents(lastName ? lastName?.substring(0, 1) : '').toUpperCase() + removeAccents(firstName ? firstName?.substring(0, 1) : '').toUpperCase(),
                    type: AVATAR_TYPE.text
                }
            )
        } else if (email) {
            setAvatarState(
                {
                    content: removeAccents(email ? email?.substring(0, 1) : '')?.toUpperCase(),
                    type: AVATAR_TYPE.text
                }
            )
        } else {
            setAvatarState(
                {
                    content: '',
                    type: AVATAR_TYPE.text
                }
            )
        }
    }, [firstName, lastName, email, image])

    useEffect(() => {
        if (size) {
            isNaN(+size) ? setSizeState(size) : setSizeState(size + 'px')
        }
    }, [size])

    useEffect(() => {
        const ro = new ResizeObserver(entries => {
            const containerWidth = entries[0].contentRect.width
            const newFontSize = containerWidth * RATIO
            setFontSize(newFontSize)
        })
        ro.observe(containerRef.current)
        return () => {
            ro?.disconnect()
        }
    }, [])

    return <div className='avatarContainer' style={{ width: sizeState, height: sizeState }} ref={containerRef}>
        {
            avatarState.type === AVATAR_TYPE.text ?
                <div className='avatarText' style={{ fontSize }} ref={imgRef}>
                    {avatarState.content}
                </div>
                :
                <div className='avatarImgContainer'>
                    <img src={avatarState.content} className="avatarImg" />
                </div>
        }
    </div>;
};

Avatar.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Avatar;
