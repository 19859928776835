import React from 'react';
import { Resource } from '../../../resource';
import { AppTool } from '../../../tool';
import './shareButton.scss';
import TooltipV2 from '../../tooltipV2/tooltipV2';

const ICON_SHARE =
<svg id="share-24px" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
<path id="Path_8159" data-name="Path 8159" d="M0,0H18V18H0Z" fill="none"/>
<path id="Path_8160" data-name="Path 8160" d="M14.222,12.544a2.177,2.177,0,0,0-1.466.577L7.421,10.013a2.454,2.454,0,0,0,.067-.524,2.454,2.454,0,0,0-.067-.524L12.7,5.887a2.242,2.242,0,1,0-.718-1.64,2.454,2.454,0,0,0,.067.524L6.771,7.849a2.247,2.247,0,1,0,0,3.28L12.1,14.244a2.115,2.115,0,0,0-.06.487,2.185,2.185,0,1,0,2.185-2.187Zm0-9.046a.749.749,0,1,1-.748.749A.751.751,0,0,1,14.222,3.5Zm-8.978,6.74a.749.749,0,1,1,.748-.749A.751.751,0,0,1,5.244,10.237Zm8.978,5.257a.749.749,0,1,1,.748-.749A.751.751,0,0,1,14.222,15.494Z" transform="translate(-0.732 -0.488)" fill="#1c75bc"/>
</svg>

function ShareButton({ url, hiddenText }) {
  const handleSave = (e) => {
    e.stopPropagation();
    AppTool.share(url)
  }

  return (
    <TooltipV2 description={Resource.common.share} anchor='top'>
    <div className='shareButtonCpn'>
      <div className='circleBtn' onClick={handleSave}>
        {ICON_SHARE}
      </div>
      {
        !hiddenText &&
        <div className='caption'>{Resource.common.share}</div>
      }
    </div>
    </TooltipV2>
  )
}

ShareButton.propTypes = {
  //
};

export default ShareButton;
