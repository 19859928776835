import {
  Box,
  Button,
  createMuiTheme,
  Drawer,
  ThemeProvider,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ShowPositionState, ShowSearchBarState, ShowSideBarState, ShowSuggestPlaceState, SidebarShadowState } from "../../../appState";
import { OpenSideBarState } from "../../../appState/openSideBar";
import { CommonConfig } from "../../../config";
import { AppData } from "../../../data";
import { Resource } from "../../../resource";
import { StringTool } from "../../../tool";
import SuggestPlaceV2 from "../../suggestPlaceV2/suggestPlaceV2";
import MapTypeSelect from "../mapTypeSelect/mapTypeSelect";
import PositionSelect from "./../../positionSelect/positionSelect";
import SearchBar from './../searchBar/searchBar';
import "./sidebar.scss";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});

function Sidebar(props, ref) {
  const [isShowToggle, setIsShowToggle] = useState(true);
  const [isFullHeight, setIsFullHeight] = useState(true);
  const showPossitionSelect = useRecoilValue(ShowPositionState);
  const showSuggestPlace = useRecoilValue(ShowSuggestPlaceState)
  const [isOpen, setIsOpen] = useState(true)
  const showSideBarState = useRecoilValue(ShowSideBarState)
  const showSearchBar = useRecoilValue(ShowSearchBarState)
  const setIsOpenSideBar = useSetRecoilState(OpenSideBarState)
  const showShadow = useRecoilValue(SidebarShadowState)


  const handleClickToggle = (e) => {
    setIsOpen((prev) => !prev);
  };

  const useStyles = makeStyles((theme) => ({
    toggleBtn: {
      position: "absolute",
      left: "100%",
      top: `50%`,
      transform: `translateY(-50%)`,
      height: `${CommonConfig.heightSearchBar}px`,
      width: "20px",
      padding: 0,
      minWidth: 0,
      marginLeft: `${CommonConfig.padding}px`,
      boxShadow: theme.shadows[1],
    },
    root: {
      boxShadow: '0px 3px 6px #00000029',
      border: "none",
      overflowY: "unset",
      visibility: "unset !important",
      height: isFullHeight ? "100%" : 0,
      backgroundColor: "transparent",
    },
  }));
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    setShowToggle: (show) => {
      setIsShowToggle(show);
    },
    setFullHeight: (full) => {
      setIsFullHeight(full);
    },
    setFullBar: (full) => {
      setIsFullHeight(full);
      setIsShowToggle(full);
    },
    setOpen: (isOpen) => {
      setIsOpen(isOpen);
    },
  }));

  useEffect(() => {
    setIsOpenSideBar(isOpen)
  }, [isOpen])

  const handleSearchBarRef = (r) => {
    AppData.searchBar = r;
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={showSideBarState && isOpen}
        PaperProps={{
          elevation: 18,
        }}
        classes={{
          paperAnchorLeft: StringTool.mergeClassName(classes.root, !showShadow && "hideSidebarShadow"),
        }}
      >
        {
          showSearchBar &&
          <SearchBar ref={handleSearchBarRef} />
        }
        {props.children}
        {isShowToggle && showSideBarState && (
          <>
            <ThemeProvider theme={theme}>
              <Box boxShadow={1} clone>
                <Tooltip
                  title={
                    isOpen
                      ? Resource.common.collapseThePanel
                      : Resource.common.expandThePanel
                  }
                  arrow
                  placement="right"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClickToggle}
                    className={classes.toggleBtn}
                  >
                    {isOpen ? <ArrowLeft /> : <ArrowRight />}
                  </Button>
                </Tooltip>
              </Box>
            </ThemeProvider>
          </>
        )}

        {showPossitionSelect && showSideBarState && (
          <div className={StringTool.mergeClassName("possitionSelectCpn", isShowToggle && "hasToggle")}>
            <PositionSelect />
          </div>
        )}
        {showSuggestPlace && showSideBarState && (
          <div className={StringTool.mergeClassName("showSuggestPlace", isShowToggle && "hasToggle")}>
            <SuggestPlaceV2 />
          </div>
        )}

      </Drawer>

      {
        showSideBarState &&
        <Box
          position="absolute"
          bottom="41px"
          left={isOpen && isFullHeight ? "430px" : "10px"}
          zIndex="1"
          style={{
            transition: "all 200ms",
          }}
        >
          <MapTypeSelect />
        </Box>
      }
    </>
  );
}
Sidebar = forwardRef(Sidebar);
Sidebar.propTypes = {
  test: PropTypes.string,
};

export default Sidebar;
