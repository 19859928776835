import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import React, { Fragment, useState } from "react";
import { AppConfig, LinkIconConfig } from "../../../config";
import { Resource } from "../../../resource";
import { AppTool, UserTool } from "../../../tool";
import { StringTool } from "../../../tool/stringTool";
import Avatar from "../avatar/avatar";
import ReportError from "../reportError/reportError";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 99,
    cursor: "pointer",
  },

  avatar: {
    backgroundColor: "#4285f4",
    cursor: "pointer",
    border: "1px solid #ffffff",
    width: "36px",
    height: "36px",
    fontSize: "14px",
  },

  card: {
    width: 330,
  },

  cardHeader: {
    "& .MuiCardHeader-content .MuiCardHeader-subheader, .MuiCardHeader-title": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "250px",
    },
  },

  btnLogin: {
    fontSize: "14px",
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#508FF4",
  },
  itemHover: {
    "&:hover": {
      backgroundColor: "#F1F6FF",
    },
  },
});
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function UserControl() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [showReportError, setShowReportError] = useState(false);

  const [currentHoverName, setCurrentHoverName] = useState("");

  const user = UserTool.getUser();

  const openManagePage = () => {
    window.location.href = window.location.origin + "/manager";
  };

  const openProfilePage = () => {
    window.location.href = window.location.origin + "/user";
  };

  const openDeveloperPage = () => {
    window.location.href = window.location.origin + "/developer";
  };

  const handleLogout = () => {
    AppTool.logout();
  };

  const handleShowReportError = () => {
    setShowReportError(true);
  };

  const menuUserControl = [
    {
      icon: LinkIconConfig.userControl.account,
      iconHover: LinkIconConfig.userControl.accountActive,
      name: Resource.user.manage,
      onClick: openProfilePage,
    },
    {
      icon: LinkIconConfig.userControl.developer,
      iconHover: LinkIconConfig.userControl.developerActive,
      name: Resource.common.developerPage,
      isHidden: !user?.isDeveloper,
      onClick: openDeveloperPage,
    },
    {
      icon: LinkIconConfig.userControl.manage,
      iconHover: LinkIconConfig.userControl.manageActive,
      name: Resource.common.managePage,
      onClick: openManagePage,
      hidden: !UserTool.hasPermission(),
    },
    // {
    //   icon: LinkIconConfig.userControl.gov,
    //   iconHover: LinkIconConfig.userControl.govActive,
    //   name: Resource.common.govPage,
    //   isHidden: !(user?.customerType == CustomerTypeEnum.government),
    //   onClick: openGovernmentPage
    // },
    {
      icon: LinkIconConfig.userControl.report,
      iconHover: LinkIconConfig.userControl.reportActive,
      name: Resource.common.reportError,
      onClick: handleShowReportError,
    },
    {
      icon: LinkIconConfig.userControl.logout,
      iconHover: LinkIconConfig.userControl.logoutActive,
      name: Resource.common.logout,
      onClick: handleLogout,
    },
  ];

  const handleShowPopup = (e) => {
    setAnchorEl(e?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseReportError = () => {
    setShowReportError(false);
  };

  const onMouseOver = (name) => (e) => {
    setCurrentHoverName(name);
  };

  const onMouseLeave = () => {
    setCurrentHoverName("");
  };

  return user ? (
    <>
      <Box
        className={classes.root}
        title={
          `${Resource.common.account}: ` +
          StringTool.titleAccount(user.firstName, user.lastName, user.email)
        }
        onClick={handleShowPopup}
      >
        <Avatar
          size="48"
          image={user.avatar}
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Avatar
                size="36"
                image={user.avatar}
                firstName={user.firstName}
                lastName={user.lastName}
                email={user.email}
              />
            }
            title={
              <span
                title={StringTool.titleAccount(
                  user.firstName,
                  user.lastName,
                  user.email
                )}
              >
                {StringTool.titleAccount(
                  user.firstName,
                  user.lastName,
                  user.email
                )}
              </span>
            }
            subheader={<span title={user?.email}>{user?.email}</span>}
          />
          <Divider />
          <List aria-label="Menu User" disablePadding>
            {menuUserControl?.map((item, index) => {
              return !item?.hidden ? (
                <Fragment key={index}>
                  {index == menuUserControl?.length - 1 && <Divider />}
                  {!item?.isHidden && (
                    <ListItem
                      className={classes.itemHover}
                      button
                      onClick={item.onClick}
                      onMouseOver={onMouseOver(item?.name)}
                      onMouseLeave={onMouseLeave}
                    >
                      <ListItemIcon>
                        {currentHoverName == item?.name ? (
                          <img
                            src={item?.iconHover}
                            alt="icon"
                            width={24}
                            height={24}
                          />
                        ) : (
                          <img
                            src={item?.icon}
                            alt="icon"
                            width={24}
                            height={24}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          color={
                            currentHoverName == item?.name
                              ? "primary"
                              : "inherit"
                          }
                        >
                          {item?.name}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </Fragment>
              ) : (
                <></>
              );
            })}
          </List>
        </Card>
      </Popover>
      {showReportError && <ReportError onClose={handleCloseReportError} />}
    </>
  ) : (
    <Button
      color="primary"
      className={classes.btnLogin}
      variant="contained"
      href={`/auth/login?returnUrl=${window.location.href}`}
    >
      {Resource.common.login}
    </Button>
  );
}

UserControl.propTypes = {
  //
};

export default UserControl;
