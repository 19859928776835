import { Box, createMuiTheme, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Chip } from 'ui';
import { ShowPopoverMoreState } from '../../appState';
import { OpenSideBarState } from '../../appState/openSideBar';
import { CommonConfig, LinkIconConfig, RouterConfig } from '../../config';
import { PlaceTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { useIsMobileScreen, useQuery, useWindowSize } from '../../useHooks';
import Popover from '../common/popover/popover';
import ShowMoreSuggestPlace from '../common/showMoreSuggestPlace/showMoreSuggestPlace';
import DragToScrollV2 from '../dragToScrollV2/dragToScrollV2';
import './suggestPlaceV2.scss';
import { UrlTool } from '../../tool';

const themeSuggestSecondary = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#5C6E8A',
    }
  }
})

const minWidth = '70px'
const useStyle = makeStyles(theme => ({
  root: {
    opacity: 1,
  },
  grid: {
    paddingTop: `${CommonConfig.padding}px`,
    justifyContent: 'center'
  },
  borderSecondary: {
    boxShadow: 'none',
    border: '1px solid #5C6E8A',
    minWidth: '48px'
  },
  boxShadow: {
    boxShadow: 'none',
  },
  gutterBottom: {
    marginBottom: '20px',
  },
  cursor: {
    cursor: 'pointer'
  },

}))
const SuggestPlaceV2 = () => {
  const primarySuggestPlaces = [
    {
      name: Resource.suggestPlaceChip.restaurant,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.restaurant,
      iconActive: LinkIconConfig.suggestPlaceChip.active.restaurant,
      type: PlaceTypeEnum.restaurant
    },
    {
      name: Resource.suggestPlaceChip.coffee,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.coffee,
      iconActive: LinkIconConfig.suggestPlaceChip.active.coffee,
      type: PlaceTypeEnum.coffee
    },
    {
      name: Resource.suggestPlaceChip.gasStation,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.gasStation,
      iconActive: LinkIconConfig.suggestPlaceChip.active.gasStation,
      type: PlaceTypeEnum.gas
    },
    {
      name: Resource.suggestPlaceChip.grocery,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.grocery,
      iconActive: LinkIconConfig.suggestPlaceChip.active.grocery,
      type: PlaceTypeEnum.grocery
    },
    {
      name: Resource.suggestPlaceChip.school,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.school,
      iconActive: LinkIconConfig.suggestPlaceChip.active.school,
      type: PlaceTypeEnum.school
    }
  ]

  const primarySuggests = [
    {
      name: Resource.common.restaurant,
      color: "#5F9500",
      iconUnactive: LinkIconConfig.suggestPlace.restaurant,
      type: 'restaurant'
    },
    {
      name: Resource.common.atm,
      color: "#008CCB",
      iconUnactive: LinkIconConfig.suggestPlace.atm,
      type: 'atm'
    },
    {
      name: Resource.common.coffee,
      color: "#CB4500",
      iconUnactive: LinkIconConfig.suggestPlace.coffee,
      type: 'cafe'
    },
    {
      name: Resource.common.park,
      color: "#E39F00",
      iconUnactive: LinkIconConfig.suggestPlace.parking,
      type: 'parking'
    },
  ]
  const secondarySuggests = [
    {
      name: Resource.common.bar,
      iconUnactive: LinkIconConfig.suggestPlace.bar,
      type: 'bar'
    },
    {
      name: Resource.common.grocery,
      iconUnactive: LinkIconConfig.suggestPlace.shopping,
      type: 'grocery_store'
    },
    {
      name: Resource.common.gasStation,
      iconUnactive: LinkIconConfig.suggestPlace.gasStation,
      type: 'gas_station'
    },
    {
      name: Resource.common.hotel,
      iconUnactive: LinkIconConfig.suggestPlace.hotel,
      type: 'hotel'
    },
    {
      name: Resource.common.hospital,
      iconUnactive: LinkIconConfig.suggestPlace.hospital,
      type: 'hospital'
    },
    {
      name: Resource.common.school,
      iconUnactive: LinkIconConfig.suggestPlace.education,
      type: 'education'
    },
    {
      name: Resource.common.postOffice,
      iconUnactive: LinkIconConfig.suggestPlace.postOffice,
      type: 'post_office'
    },
    {
      name: Resource.common.bank,
      iconUnactive: LinkIconConfig.suggestPlace.bank,
      type: 'bank'
    },
  ]

  const { type } = useQuery()
  const isMobile = useIsMobileScreen()
  const classes = useStyle()
  const history = useHistory()
  const windowSize = useWindowSize()

  const isOpenSideBar = useRecoilValue(OpenSideBarState)

  const [openMore, setOpenMore] = useState(false)
  const [isClose, setIsClose] = useRecoilState(ShowPopoverMoreState)
  const [activeSuggestPlace, setActiveSuggestPlace] = useState(null)

  useEffect(() => {
    isClose && setOpenMore(false)
  }, [isClose])

  useEffect(() => {
   setActiveSuggestPlace(type)
  }, [type])

  const handleSearchSuggestPlace = (suggest) => (e) => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: suggest?.name, type: suggest?.type })
    })
  }
  const handleClosePopover = () => {
    setOpenMore(false)
    setIsClose(true)
  }
  const handleOpenPopover = () => {
    setOpenMore(true)
    setActiveSuggestPlace('more')
    setIsClose(false)
  }
  return (

    <>
      {!isMobile &&
        <>
          <DragToScrollV2 delta={isOpenSideBar ? 1003 : 777}>
            <div className='suggestPlaceV2' style={{ maxWidth: isOpenSideBar ? `${windowSize?.width - 842}px` : `${windowSize?.width - 426}px` }}>
              {
                primarySuggestPlaces?.map((suggest, index) => {
                  return (
                    <Chip
                      key={suggest.iconActive + `${index}` + 'chip'}
                      icon={<img src={activeSuggestPlace == suggest.type ? suggest?.iconActive : suggest?.iconUnactive} alt={`icon ${suggest.type}`} />}
                      onClick={handleSearchSuggestPlace(suggest)}
                      className={`itemSuggestPlace ${activeSuggestPlace == suggest.type ? 'active' : ''}`}
                    >
                      <span className='chipName'>{suggest.name}</span>
                    </Chip>
                  )
                })
              }
              <div className="popoverMore">
                <Popover
                  open={openMore}
                  onClose={handleClosePopover}
                  onOpen={handleOpenPopover}
                  actionButton={
                    <Chip
                      key="iconSugesstMore"
                      icon={<img src={openMore && activeSuggestPlace == 'more' ? LinkIconConfig.suggestPlaceChip.active.more : LinkIconConfig.suggestPlaceChip.unActive.more} />}
                      className={openMore && activeSuggestPlace == 'more' ? 'activeMoreSuggest' : 'unactive'}
                    >
                      {Resource.suggestPlaceChip.more}
                    </Chip>
                  }
                >
                  <ShowMoreSuggestPlace />
                </Popover>
              </div>

            </div>
          </DragToScrollV2>
        </>
      }
      {
        isMobile && <Box>
          <Grid container justify="space-between">
            {
              primarySuggests.map((suggest, index) => {
                let theme = createMuiTheme({
                  palette: {
                    primary: {
                      main: suggest.color,
                    }
                  }
                })
                return (
                  <Box
                    key={suggest.iconUnactive}
                    minWidth={minWidth}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    onClick={handleSearchSuggestPlace(suggest)}
                  >
                    <ThemeProvider theme={theme}>
                      <Fab
                        color="primary"
                        size="medium"
                        className={classes.boxShadow}
                      >
                        <img
                          src={suggest.iconUnactive}
                          height={24}
                          width={24}
                        />
                      </Fab>
                    </ThemeProvider>
                    <Typography variant="caption">
                      <Box mt={1}>
                        {suggest.name}
                      </Box>
                    </Typography>
                  </Box>
                )
              })
            }
          </Grid>

          <>
            <Grid
              className={classes.grid}
              container
            >
              {
                secondarySuggests.map((suggest, index) => {
                  return (
                    <Box
                      flexBasis="45%"
                      key={suggest.iconUnactive}
                      className={classes.cursor}
                      display="flex" p='10px'
                      alignItems="center"
                      onClick={handleSearchSuggestPlace(suggest)}
                    >
                      <ThemeProvider theme={themeSuggestSecondary}>
                        <Fab
                          color="primary"
                          size="medium"
                          className={classes.borderSecondary}
                        >
                          <img
                            src={suggest.iconUnactive}
                            height={24}
                            width={24}
                          />
                        </Fab>
                      </ThemeProvider>
                      <Typography variant="caption">
                        <Box padding={1}>
                          {suggest.name}
                        </Box>
                      </Typography>
                    </Box>
                  )
                })}
            </Grid>
          </>
        </Box>
      }
    </>
  );
};

SuggestPlaceV2.propTypes = {
  //
};

export default SuggestPlaceV2;
