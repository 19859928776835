import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useId, useState } from 'react';
import { useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button, TextInput } from 'ui';
import * as yup from "yup";
import { DataVr360State, PreviewDataState, ShowMetaDataState } from '../../../appState';
import { TypeMetaDataEnum } from '../../../enum';
import { Resource } from '../../../resource';
import Dialog from '../../dialog/dialog';
import DialogAction from '../../dialogAction/dialogAction';
import DialogBody from '../../dialogBody/dialogBody';
import DialogHeader from '../../dialogHeader/dialogHeader';
import { ShowVr360State } from './../../../appState/showVr360State';

const MAX_LENGTH_NAME = 256

const AddVr360 = () => {
  const FORM_ID = useId()

  const [valueState, setValueState] = useState()

  const [isPreview, setIsPreview] = useState(false)

  const setPreviewDataState = useSetRecoilState(PreviewDataState)
  const setShowMetaDataState = useSetRecoilState(ShowMetaDataState)

  const [showVr360State, setShowVr360State] = useRecoilState(ShowVr360State)
  const [dataVr360State, setDataVr360State] = useRecoilState(DataVr360State)

  const schema = yup.object().shape({
    name: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.common.name
    })),

    content: yup.string()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.path }))
      .url(Resource.formatString(Resource.validate.typeError, {
        field: Resource.common.path,
        type: Resource.common.link.toLocaleLowerCase()
      }))
      .test("testDuplicate", Resource.formatString(Resource.validate.duplicate, { field: Resource.common.path }), function (value) {
        let check = true
        dataVr360State?.forEach((item, idx) => {
          if (value && value == item?.content) {
            check = false
          }
        })
        return check;
      }),
  })

  const { register, setValue, errors, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  })

  register({ name: 'name' })
  register({ name: 'content' })

  const onCloseDialog = () => {
    setShowVr360State((prev) => {
      return {
        ...prev,
        show: false,
      }
    })
    reset()
    setValueState(null)
    setIsPreview(false)
  }

  const onChangeValueInput = (name) => (e) => {
    let value = e?.target.value
    setValueState((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
    setValue(name, value, { shouldValidate: formState.isSubmitted })
    if (name == 'content') {
      yup.string().url().required().validate(value)
        .then(() => {
          setIsPreview(true)
        })
        .catch(() => {
          setIsPreview(false)
        })
    }
  }

  const onSubmit = (data) => {
    let newList = [...(dataVr360State || [])]
    newList?.unshift({
      ...data,
      type: TypeMetaDataEnum.vr360
    })
    setShowMetaDataState({
      type: TypeMetaDataEnum.vr360,
      show: true
    })
    setDataVr360State(newList)
    onCloseDialog()
  }

  const onShowPreviewData = () => {
    setPreviewDataState({
      show: true,
      data: valueState
    })
  }

  return (
    <Dialog
      open={showVr360State?.show}
      onBack={onCloseDialog}
    >
      <DialogHeader showBack hideClose>
        <div style={{ fontWeight: 600 }}>{Resource.metadata.virtual}</div>
        {
          showVr360State?.subTitle &&
          <div className='subTitle' style={{ fontSize: '0.875rem', lineHeight: '1.125rem' }}>
            {showVr360State?.subTitle}
          </div>
        }
      </DialogHeader>

      <DialogBody>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          id={FORM_ID}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.6875rem',
            padding: '1rem',
            paddingBottom: '2.875rem'
          }}
        >
          <div
            className='description'
            style={{
              fontSize: '1rem',
              lineHeight: '1.25rem',
              fontWeight: '600',
              color: '#121212'
            }}
          >
            {Resource.virtual.info}
          </div>

          <TextInput
            required
            label={Resource.common.name}
            value={valueState?.name}
            onChange={onChangeValueInput('name')}
            error={errors.name ? true : false}
            helperText={errors.name?.message}
            maxLength={MAX_LENGTH_NAME}
            showCount
          />

          <TextInput
            required
            label={Resource.common.path}
            value={valueState?.content}
            onChange={onChangeValueInput('content')}
            error={errors.content ? true : false}
            helperText={errors.content?.message}
          />

          <div className='preview'>
            <Button size='small' variant='preview' disabled={!isPreview} onClick={onShowPreviewData}>
              {Resource.common.preview}
            </Button>
          </div>
        </form>
      </DialogBody>

      <DialogAction
        style={{
          display: 'flex',
          gap: '0.75rem'
        }}
      >
        <Button onClick={onCloseDialog} size='small' variant='cancel'>
          {Resource.button.cancel}
        </Button>
        <Button type='submit' variant='add' size='small' form={FORM_ID} disabled={(!valueState?.name || !isPreview)}>
          {Resource.button.add}
        </Button>
      </DialogAction>
    </Dialog>
  )
};

export default AddVr360;
