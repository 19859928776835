if (!Array.prototype.at) {
    Object.defineProperty(Array.prototype, 'at', {
        value: function (index) {
            // Chuyển đổi giá trị của index thành số nguyên
            index = Math.trunc(index) || 0;

            // Xử lý index âm
            if (index < 0) {
                index = this.length + index;
                if (index < 0) {
                    index = this.length + 1;
                }
            }

            // Trả về phần tử tại vị trí index hoặc undefined nếu vượt quá độ dài của mảng
            return this[index];
        },
        configurable: true,
        writable: true
    });
}


