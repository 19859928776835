import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RouterConfig } from '../../../config/routerConfig';
import { Resource } from '../../../resource';
import { DirectionTool, StringTool, UrlTool } from '../../../tool';
import TooltipV2 from '../../tooltipV2/tooltipV2';
import './directionButton.scss';

const ICON_DIRECTION =
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path fill='none' d="M0,0H18V18H0Z" />
    <path fill='#FFFFFF' d="M16.721,8.937,10,2.216a.744.744,0,0,0-1.053,0L2.226,8.937a.744.744,0,0,0,0,1.053l6.721,6.721a.744.744,0,0,0,1.053,0L16.721,9.99a.744.744,0,0,0,0-1.053Zm-5.758,2.4V9.467H7.976v2.24H6.483V8.72a.749.749,0,0,1,.747-.747h3.734V6.107L13.577,8.72Z" transform="translate(-0.47 -0.467)" />
  </svg>

const SIZE = 36
const SIZE_EMBED = 48
function DirectionButton({ latlng, disableText, height, width, isMobile }) {
  const history = useHistory()

  const handleDirection = (e) => {
    e.stopPropagation();
    let data = DirectionTool.encodeDirection({ positions: [null, latlng] })
    let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
    if (window.top == window.self) {
      history.push({
        pathname: RouterConfig.direction,
        search: search
      })
    }
    else {
      let url = window.location.origin + pathName + search
      window.top.open(url, "_blank")
    }

  }

  let data = DirectionTool.encodeDirection({ positions: [null, latlng] })
  let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
  let pathName = RouterConfig.direction
  let url = window.location.origin + pathName + search

  return (
    <TooltipV2 description={Resource.common.direction} anchor='top'>
      <div className={StringTool.mergeClassName('directionButtonCpn')}>
        {
          isMobile ?
            <div className='mobileBtn'>
              <div className='icon'>{ICON_DIRECTION}</div>
              <div className='caption'>{Resource.common.way}</div>
            </div>
            :
            <div className='webBtn'>
              {
                window.top == window.self ?
                  <div
                    className='circleBtn'
                    onClick={handleDirection}
                    style={{
                      width: width ? width : SIZE,
                      height: height ? height : SIZE,
                    }}
                  >
                    {ICON_DIRECTION}
                  </div>
                  :
                  <a className='circleBtn'
                    style={{
                      marginBottom: "6px",
                      width: SIZE_EMBED,
                      height: SIZE_EMBED
                    }}
                    target='_blank'
                    href={url}
                  >
                    {ICON_DIRECTION}
                  </a>
              }
              {
                !disableText &&
                <div className='caption'>{Resource.common.direction}</div>
              }
            </div>
        }
      </div>
    </TooltipV2>
  )
}

DirectionButton.propTypes = {
  isMobile: PropTypes.bool,
  latlng: PropTypes.any,
  width:PropTypes.any,
  height:PropTypes.any,
  disableText:PropTypes.bool
};

export default DirectionButton;
