import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { RatingInput } from 'ui';
import { PlaceRelatedState, PlaceTypeState } from '../../appState';
import { ImageConfig, RouterConfig } from '../../config';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';
import BusinessHoursStatus from '../businessHoursStatus/businessHoursStatus';
import './placeRelatedV2.scss';
import { CanBackToPlaceRelatedState } from '../../appState/canBackToPlaceRelatedState';

function PlaceRelatedV2(props) {
  const { data, object} = props

  const history = useHistory()

  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const setPlaceRelatedState = useSetRecoilState(PlaceRelatedState)
  const setCanBackToPlaceRelatedState = useSetRecoilState(CanBackToPlaceRelatedState)

  const handleShowObjectDetail = (e) => {
    history.push({
      pathname: RouterConfig.object3d.detail.replace(":id", object.id)
    })
  }

  const handleOnclickItem = (place) => (e) => {
    history.push({
      pathname: RouterConfig.place.detail.replace(":id", place?.id || "custom"),
    })
    setCanBackToPlaceRelatedState(true)
  };

  const showListPlaceRelated = () => {
    setPlaceRelatedState({
      show: true,
      data: data || []
    })
  }

  return (
    object &&
    <div className='placeRelatedV2Cpn'>
      <div className={StringTool.mergeClassName('sameObjectInfo')}>
        <span className='textTitle' title={object?.name}>
          {Resource.common.sameLocation}
        </span>
        <span className='objectName' onClick={handleShowObjectDetail} title={object?.name}>
          {` "${object?.name || ''}" `}
        </span>
      </div>
      {
        data?.length > 0 &&
        <div className='listPlaceRelated'>
          {
            data?.map((poiItem, index) => {
              if (index < 3) {
                let avatar = poiItem?.photos?.length > 0 ? poiItem?.photos[0].url : ImageConfig.avaterDefaultGray
                let name = poiItem?.name || ''
                let address = poiItem?.address || ''
                let type = poiItem?.types?.length > 0 ? AllPlaceTypeState[poiItem?.types[0]]?.name : ""
                let rateNum = poiItem?.ratingReport?.avgStars
                let totalComment = poiItem?.ratingReport?.totalComments
                let businessHour = poiItem?.businessHours

                return (
                  <div
                    className='poiItem'
                    key={poiItem?.id + index}
                    id={poiItem?.id + index}
                    title={name}
                    onClick={handleOnclickItem(poiItem)}
                  >
                    <div className='poiItemInfo'>
                      <div className='name'>{name}</div>
                      <div className='type'>{type}</div>
                      {
                        poiItem?.ratingReport &&
                        <div className='rate'>
                          <div className='rateNumber'>{rateNum}</div>
                          <RatingInput size={13} avgRating={rateNum} />
                          <div className='totalComment'>{`(${totalComment})`}</div>
                        </div>
                      }
                      <div className='address'>{address}</div>
                      <div className='businessHourStatus'>
                        <BusinessHoursStatus value={businessHour} />
                      </div>
                    </div>
                    <div className='poiItemAvatar'>
                      <img src={avatar} alt='avatar' />
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
      }

      {
        data?.length > 3 &&
        <div className='showMoreBtn'>
          <div className='text' onClick={showListPlaceRelated}>
            {Resource.button.viewAll}
          </div>
        </div>
      }
    </div>
  )
}

PlaceRelatedV2.propTypes = {
  data: PropTypes.any,
  object: PropTypes.any,
  isMobile: PropTypes.bool,
  isObjectDetail: PropTypes.bool,
};

export default PlaceRelatedV2;
