import { Box, Card, CardContent, CardHeader, Divider, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { DataExtendState } from '../../../appState';
import { PlaceExtensionTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { StringTool } from '../../../tool';
import DisplayItemContent from '../displayItemContent/displayItemContent';
import SideBarDetail from '../sideBarDetail/sideBarDetail';
import { useRecoilState } from 'recoil';

const LinkGetIcon = 'https://s2.googleusercontent.com/s2/favicons?domain_url='

const useStyles = makeStyles({
  root: {
    color: '#508ff4'
  },
  card: {
    height: 'calc(100% - 200px)',
    backgroundColor: '#FFFFFF',
    margin: '100px 8%',
  },
  cardContent: {
    height: 'calc(100% - 65px)',
    overflow: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    background: '#FFFFFF',
    top: '20px',
    right: '8%',
    backgroundColor: '#101010c7',
    color: '#FFFFFF'
  },
  itemEmbed: {
    width: '100%',
    height: '100%',
    "& > iframe": {
      width: '100% !important',
      height: '100% !important'
    }
  },
  cardHeader: {
    textAlign: 'center',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '20px',
    fontWeight: '600',
  },
  item: {
    fontSize: '14px',
    lineHeight: '18px',
    minHeight: '70px',
    width: '100%',
    padding: '14px 16px',
    border: '1px solid #dadce0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    gap: '8px',
    ' &:hover': {
      boxShadow: '0px 2px 4px #00000029',
    }
  },
  textLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  text: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },

  textName: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '#121212',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
  selectItem: {
    border: '1px solid #1c75bc',
  }
})

const typeResource = {
  [PlaceExtensionTypeEnum.article]: Resource.common.article,
  [PlaceExtensionTypeEnum.link]: Resource.common.link,
  [PlaceExtensionTypeEnum.popup]: Resource.common.popup,
  [PlaceExtensionTypeEnum.vr360]: Resource.common.vr360,
}
function ShowDataExtend() {

  const classes = useStyles()

  const [dataExtendState, setDataExtendState] = useRecoilState(DataExtendState)

  const [indexItemShow, setIndexItemShow] = useState(0)



  useEffect(() => {
    setIndexItemShow(dataExtendState.indexSelect)
  }, [dataExtendState.indexSelect])

  const handleClose = () => {
    setDataExtendState({
      show: false, dataExtend: [],
      indexSelect: 0,
      typeData: PlaceExtensionTypeEnum.none
    })
    setIndexItemShow(0)
  }

  const handleOnClickItem = (index, item) => (e) => {
    setIndexItemShow(index)
    if (!item?.content?.includes('https') && !PlaceExtensionTypeEnum.article) {
      window.open(item?.content, '_blank')
    }
  }

  return (
    dataExtendState.show && dataExtendState.typeData != PlaceExtensionTypeEnum.link &&
    <Box display='flex' position="fixed" left={0} top={0} width="100%" height="100%" bgcolor="#000000DE" zIndex={2000}>
      <SideBarDetail>
        <Box width='100%' height='100%' bgcolor='#FFFFFF' display='flex' flexDirection='column'>
          <Box p={2} display='flex' alignItems='center' justifyContent='space-around'>
            <IconButton onClick={handleClose}><ArrowBack /></IconButton>
            <Box textAlign='center' width='100%'>
              <Typography noWrap variant='h6'>{typeResource[dataExtendState.typeData]}</Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            overflow='auto'
            width='100%'
            p={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            {
              dataExtendState.dataExtend?.map((item, idx) => {
                return (
                  <Box
                    title={item?.name}
                    key={idx}
                    className={`${classes.item} ${idx == indexItemShow ? classes.selectItem : ''}`}
                    onClick={handleOnClickItem(idx)}
                  >
                    <Box className={classes.textLink}>
                      <Box style={{ width: 18, height: 18 }}>
                        <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                      </Box>
                      <span className={classes.text}>{item?.content}</span>
                    </Box>
                    <span className={classes.textName}>{item.name}</span>
                  </Box>

                )
              })
            }
          </Box>
        </Box>
      </SideBarDetail>
      <Box flexGrow='1' position='relative' overflow='hidden'>
        <Card className={classes.card}>
          <Box p={3} className={classes.cardHeader} title={dataExtendState.dataExtend[indexItemShow]?.name}>
            {dataExtendState?.dataExtend[indexItemShow]?.name}
          </Box>
          <Divider />
          <CardContent className={classes.cardContent}>
            {
              dataExtendState.typeData == PlaceExtensionTypeEnum.article &&
              <Box width='100%' dangerouslySetInnerHTML={{ __html: dataExtendState?.dataExtend[indexItemShow]?.content }} />
            }

            {
              dataExtendState.typeData == PlaceExtensionTypeEnum.vr360 &&
              <Box className={classes.itemEmbed}>
                <iframe src={dataExtendState?.dataExtend[indexItemShow]?.content?.replace("watch?v=", "embed/")} allowFullScreen ></iframe>
              </Box>
            }

            {
              dataExtendState.typeData == PlaceExtensionTypeEnum.popup && StringTool.validURL(dataExtendState?.dataExtend[indexItemShow]?.content) &&
              <iframe className={classes.itemVideoLink} width='100%' height='100%' src={dataExtendState?.dataExtend[indexItemShow]?.content?.replace("watch?v=", "embed/")} allowFullScreen ></iframe>
            }

            {
              dataExtendState.typeData == PlaceExtensionTypeEnum.popup && StringTool.validIframe(dataExtendState?.dataExtend[indexItemShow]?.content) &&
              <Box className={classes.itemEmbed} dangerouslySetInnerHTML={{ __html: dataExtendState?.dataExtend[indexItemShow]?.content?.replace(/(<? *script)/gi, 'illegalscript') }} />
            }

          </CardContent>
        </Card>
        <IconButton onClick={handleClose} size='medium' className={classes.closeBtn}><CloseIcon /></IconButton>
      </Box>
    </Box>
  )
}

ShowDataExtend.propTypes = {
  //
};

export default ShowDataExtend;
