import React, { useEffect, useState, useRef } from "react";
import "./suggest.scss";
import PropTypes from "prop-types";
import * as ReactDOM from "react-dom";
import { StringTool } from "../../tool";

const anchorEnum = {
  top: "bottom",
  bottom: "top",
};
const RemoveUnikey = (str) => {
  if (str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\W+/g, " ");
  }
  return str;
};

const Highligh = ({ text, valueInput, caption }) => {
  let position = RemoveUnikey(text).indexOf(RemoveUnikey(valueInput));
  return (
    <div className="wrapHighligh">
      {position === -1 || valueInput === "" ? (
        <div className="text">
          {text}
          {caption && <span className="caption">{caption}</span>}
        </div>
      ) : (
        <div className="textHighligh">
          <span className="highligh notHighligh">
            {text.substring(0, position)}
          </span>
          <span className="highligh isHighligh">
            {text.substring(position, position + valueInput.length)}
          </span>
          <span className="highligh notHighligh">
            {text.substring(position + valueInput.length)}
          </span>
          {caption && <span className="caption">{caption}</span>}
        </div>
      )}
    </div>
  );
};

const Suggest = ({
  options,
  value,
  getOptionLabel,
  onChange,
  onInputChange,
  disabled,
  helperText,
  error,
  label,
  isServer,
  isLoading,
  isFreeSolo,
  maxLengthOfScroll,
  placeholder,
  searchPlaceHolder,
  clearable,
  required,
  isGreyTheme,
  getOptionDisabled,
  getCaptionLabel,
}) => {
  const [optionsState, setOptionsState] = useState();
  const [valueState, setValueState] = useState(null);
  const [valueInput, setValueInput] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [filterOptions, setFilterOption] = useState();
  const [index, setIndex] = useState(-1);
  const [active, setActive] = useState();

  const [anchor, setAnchor] = useState(anchorEnum.bottom);
  const [distance, setDistance] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [optionHeight, setOptionHeight] = useState(0);

  const inputRef = useRef(null);
  const valueBackupRef = useRef(null);
  const scrollRef = useRef(null);
  const isFirstFocusRef = useRef(false);

  const ref = useRef(null);
  const listRef = useRef(null);
  const keyUporDowRef = useRef({ key: null });
  // ============================================== Set Data =========================================
  useEffect(() => {
    if (options) {
      setOptionsState(options);
    }
  }, [options]);

  useEffect(() => {
    const newFilterOption = optionsState?.filter(
      (option) => valueState?.id !== option?.id
    );
    if (
      valueInput &&
      valueInput !== handleOptionLabel(valueState) &&
      !isServer
    ) {
      const search = optionsState?.filter((value) =>
        RemoveUnikey(handleOptionLabel(value))?.includes(
          RemoveUnikey(valueInput?.trim())
        )
      );
      setFilterOption(search);
    } else {
      setFilterOption(newFilterOption);
    }
    setIndex(-1);
  }, [optionsState, valueState, valueInput]);

  useEffect(() => {
    let newValue = value;
    if (!value?.id) {
      newValue = options?.find((option) => value == option?.id) || value;
    }
    setValueState(newValue);
    setValueInput("")
  }, [value, optionsState]);

  // ===================================== addEventListener =====================================================
  const handleScrollAndResize = () => {
    const cpn = ref.current?.getBoundingClientRect() || {};
    const innerHeight = window.innerHeight;
    const innerHeightBetween = innerHeight / 2;
    const isTop = (innerHeightBetween + cpn?.top) / 2 > innerHeightBetween;
    setMaxHeight(innerHeight - (cpn?.top + cpn?.height));
    setAnchor(anchorEnum.bottom);
    setDistance(cpn?.top + cpn?.height);
    setLeft(cpn?.left);
    if (isTop) {
      setDistance(innerHeight - cpn?.top);
      setMaxHeight(cpn?.top);
      setAnchor(anchorEnum.top);
    }
  };
  useEffect(() => {
    isOpen && handleScrollAndResize();
  }, [isOpen, disabled]);

  useEffect(() => {
    handleScrollAndResize();

    const elements = ref?.current;
    let observer;
    let resize;

    const trackWitdth = (entries) => {
      setWidth(entries[0].contentRect.width);
    };
    if (elements) {
      observer = new IntersectionObserver(onIntersection);
      function onIntersection(entries, opts) {
        if (!entries[0].isIntersecting) {
          if (!isFirstFocusRef.current) {
            setOpen(false);
          } else {
            ref.current?.scrollIntoView();
            isFirstFocusRef.current = false;
          }
        }
      }
      resize = new ResizeObserver(trackWitdth);

      observer.observe(elements);
      resize.observe(elements);
    }

    window.addEventListener("resize", handleScrollAndResize, true);
    document.addEventListener("scroll", handleScrollAndResize, true);

    return () => {
      resize?.disconnect();
      observer?.disconnect();
      window.removeEventListener("resize", handleScrollAndResize, true);
      document.removeEventListener("scroll", handleScrollAndResize, true);
    };
  }, []);

  useEffect(() => {
    let resize;
    if (inputRef.current && isOpen) {
      const getHeightOption = (entries) => {
        setOptionHeight(inputRef.current?.getBoundingClientRect()?.height || 0);
      };
      resize = new ResizeObserver(getHeightOption);
      resize.observe(inputRef.current);
    }
    return () => {
      resize?.disconnect();
    };
  }, [isOpen]);
  useEffect(() => {
    if (valueState == null) {
      setValueInput("");
    }
  }, [valueState]);
  useEffect(() => {
    const handleClose = (event) => {
      if (isOpen) {
        var isClickInsideElement = ref.current.contains(event.target);
        var isClickInsideElementListRef = listRef.current.contains(
          event.target
        );
        if (!isClickInsideElement && !isClickInsideElementListRef) {
          if (isFreeSolo) {
            setValueState(valueInput);
            onChange && onChange(valueInput);
          }
          setOpen(false);
        }
      }
    };
    window.addEventListener("mousedown", handleClose);
    return () => window.removeEventListener("mousedown", handleClose);
  }, [valueInput, isFreeSolo, isOpen]);

  useEffect(() => {
    if (valueBackupRef?.current?.id && !isOpen && !isFreeSolo) {
      setValueInput("");
    }
  }, [isOpen]);
  // ========================================== Handle Funciton  ==========================================================
  const handleOptionLabel = (value) => {
    let text = "";
    if (getOptionLabel) {
      text = getOptionLabel(value);
    } else if (typeof value == "object") {
      text = value?.name || "";
    } else {
      text = value?.toString();
    }

    return text;
  };
  const handleGetValue = (event) => {
    const value = event.target.value;
    setValueInput(value);
    onInputChange && onInputChange(event, value);
  };
  const handleSelected = (option) => () => {
    setValueState(option);
    valueBackupRef.current = option;
    // setValueInput(handleOptionLabel(option));
    onChange && onChange(option);
    setIndex(-1);
    setOpen(false);
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpen(!isOpen);
      setIndex(-1);
    }
  };
  const handleClickOnInput = () => {
    if (!disabled) {
      setOpen(true);
    }
  };
  const handleRemoveAll = () => {
    setValueState(null);
    valueBackupRef.current = null;
    setValueInput("");
    onChange && onChange(null);
    setIndex(-1);
    setOpen(false);
  };

  useEffect(() => {
    if (filterOptions) {
      setActive(filterOptions[index]);
    }

    const scrollCPn = scrollRef?.current;
    const height = scrollCPn?.getBoundingClientRect()?.height;
    const distance = scrollCPn?.getBoundingClientRect()?.top;

    const children = scrollCPn?.children[index]?.getBoundingClientRect();
    const distanceChildren = (children?.top - distance).toFixed();
    const distanceOption = index * optionHeight;

    if (keyUporDowRef.current.key === 38) {
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = distanceOption;
      }
      if (distanceChildren > height) {
        scrollCPn.scrollTop = distanceChildren;
      }
    }
    if (keyUporDowRef.current.key === 40) {
      if (distanceChildren > height - optionHeight) {
        scrollCPn.scrollTop = distanceOption - height + optionHeight;
      }
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = 0;
      }
    }
  }, [index, optionHeight]);
  const onKeyDownEventFired = (event) => {
    const filterOptionsLength = filterOptions?.length - 1;

    if (isOpen && !disabled) {
      switch (event.which) {
        case 38:
          setIndex((prev) => getIndex(prev, true));
          keyUporDowRef.current.key = 38;
          event.preventDefault();
          break;
        case 40:
          setIndex((prev) => getIndex(prev));
          keyUporDowRef.current.key = 40;
          event.preventDefault();
          break;
        case 13:
          if (active?.id != undefined) {
            onChange && onChange(active);
            setOpen(false);
            setIndex(-1);
            setValueState(active);
            valueBackupRef.current = active;
            // setValueInput(handleOptionLabel(active));
          }
          if (isFreeSolo && active?.id === undefined) {
            const newObject = { name: valueInput };
            setOpen(false);
            setIndex(-1);
            setValueInput(valueInput);
            valueInput === "" ? setValueState(null) : setValueState(newObject);
            onChange && onChange(valueInput);
          }
          break;
        default:
          return;
      }
    }
    if (event.which === 40 || (38 && !isOpen && !disabled)) {
      setOpen(true);
    }
  };
  const optionsLength = filterOptions?.length > 0; //tìm kiếm có ít nhất 1 phần tử
  const correctLoading = isLoading && isOpen;
  const isBottom = anchor === "top";
  let maxHeightOfList = maxHeight - 9;
  if (maxLengthOfScroll) {
    let max2 = optionHeight * maxLengthOfScroll + optionHeight;
    if (max2 < maxHeightOfList) {
      maxHeightOfList = max2;
    }
  }
  const onFocusInput = () => {
    if (/Android/i.test(navigator.userAgent)) {
      isFirstFocusRef.current = true;
    }
  };
  const displayValueState = valueState
    ? handleOptionLabel(valueState)
    : placeholder || "Chọn";

  const getIndex = (currentIndex, decrease = false) => {
    let count = filterOptions?.length;
    let i = 1;
    let ok = false;
    let result = currentIndex;
    while (!(ok || i > count)) {
      let nextIndex = (currentIndex + i) % count;
      if (decrease) {
        if (currentIndex > -1) {
          nextIndex = (currentIndex - i + count) % count;
        } else {
          nextIndex = (-i + count) % count;
        }
      }
      if (
        !getOptionDisabled ||
        (getOptionDisabled && !getOptionDisabled(filterOptions[nextIndex]))
      ) {
        result = nextIndex;
        ok = true;
      } else {
        i = i + 1;
      }
    }
    return result;
  };
  return (
    <div
      ref={ref}
      onKeyDown={onKeyDownEventFired}
      tabIndex={0}
      className={`cpnSelections ${disabled ? "disabled" : ""} ${
        isGreyTheme ? "greyTheme" : ""
      }`}
    >
      <div className="selectWrap">
        <fieldset
          className={`fieldset   ${label ? "" : "fieldsetNotLabel"}  ${
            isOpen ? "fieldsetFocus" : "fieldsetNotFocus"
          }`}
          style={{ border: error ? "2px solid #D93535" : "" }}
        >
          {label && (
            <legend
              style={{ color: error ? " #D93535" : "" }}
              className={`legend ${isOpen ? "legendFocus" : "legendText"}`}
            >
              {label}
              {required && <span className="required">*</span>}
            </legend>
          )}
        </fieldset>
        <div className="selectRoot">
          <div className="select" onClick={handleClickOnInput}>
            {
              <div
                className={`name ${disabled ? "disabled" : ""}`}
                style={{
                  color: valueState && !disabled ? "#121212" : "#D5D5D5",
                }}
                title={
                  handleOptionLabel(valueState) || valueState?.name || null
                }
              >
                {displayValueState}
              </div>
            }
          </div>
          <div
            className={`selectAndClear ${disabled ? "justifycontentEnd" : ""}`}
          >
            {!disabled && clearable && handleOptionLabel(valueState) && (
              <div className="clear" onClick={handleRemoveAll}>
                <svg
                  id="Component_3_2"
                  data-name="Component 3 – 2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_6362"
                    data-name="Path 6362"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_6363"
                    data-name="Path 6363"
                    d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
                    fill="#869195"
                  />
                </svg>
              </div>
            )}
            <div
              className={`open ${disabled ? "disabled" : ""}`}
              onClick={handleOpen}
            >
              {isOpen ? (
                <svg
                  id="Group_14729"
                  data-name="Group 14729"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_8102"
                    data-name="Path 8102"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_8103"
                    data-name="Path 8103"
                    d="M12,11.828,9.172,14.657,7.757,13.243,12,9l4.243,4.243-1.415,1.414Z"
                    fill="#869195"
                  />
                </svg>
              ) : (
                <svg
                  id="Group_14730"
                  data-name="Group 14730"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_8104"
                    data-name="Path 8104"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_8105"
                    data-name="Path 8105"
                    d="M12,15,7.757,10.757,9.172,9.343,12,12.172l2.828-2.829,1.415,1.414Z"
                    fill="#869195"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
      {error && !disabled && <div className="helperText">{helperText}</div>}
      {ReactDOM.createPortal(
        isOpen && (
          <div
            className={`listWrapOfSuggestMap4d ${
              isGreyTheme ? "greyTheme" : ""
            }`}
            style={{
              [anchor]: distance + 6,
              width: width,
              maxHeight: maxHeightOfList,
              left: left,
              flexDirection: isBottom ? "column" : "column-reverse",
            }}
            ref={listRef}
          >
            {!disabled && (
              <div className="search" style={{ width: width }}>
                <input
                  onFocus={onFocusInput}
                  autoFocus
                  ref={inputRef}
                  onChange={handleGetValue}
                  autoComplete="off"
                  value={valueInput || ""}
                  placeholder={
                    searchPlaceHolder ? searchPlaceHolder : "Tìm kiếm"
                  }
                />
                {correctLoading ? (
                  <div className="loaded">
                    <svg
                      id="Group_17501"
                      data-name="Group 17501"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_8149"
                        data-name="Path 8149"
                        d="M0,0H24V24H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_8150"
                        data-name="Path 8150"
                        d="M18.364,5.636,16.95,7.05A7,7,0,1,0,19,12h2a9,9,0,1,1-2.636-6.364Z"
                        fill="#1c75bc"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <path
                            id="Path_8137"
                            data-name="Path 8137"
                            d="M0,0H24V24H0Z"
                            transform="translate(1941 636)"
                            fill="none"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Mask_Group_47"
                        data-name="Mask Group 47"
                        transform="translate(-1941 -636)"
                      >
                        <path
                          id="Path_8138"
                          data-name="Path 8138"
                          d="M9.2,2A7.2,7.2,0,1,1,2,9.2,7.2,7.2,0,0,1,9.2,2Zm0,12.8A5.6,5.6,0,1,0,3.6,9.2,5.6,5.6,0,0,0,9.2,14.8Zm6.787.057,2.263,2.262-1.132,1.132-2.262-2.263,1.131-1.131Z"
                          transform="translate(1943.033 638.032)"
                          fill="#c4c4c4"
                        />
                      </g>
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div style={{ border: "1px solid #F1F9FF" }} />

            {optionsLength ? (
              <div className="renderList" ref={scrollRef}>
                {filterOptions?.map((option, index) => {
                  const styleActive = active?.id == option.id;
                  const text = handleOptionLabel(option);
                  let caption = "";
                  let captionLabel = getCaptionLabel && getCaptionLabel(option);
                  if (captionLabel) {
                    caption = ` (${captionLabel})`;
                  }
                  let disableOption = false;
                  if (getOptionDisabled && getOptionDisabled(option)) {
                    disableOption = true;
                  }
                  return (
                    <div
                      key={option.id}
                      className={StringTool.mergeClassName(
                        "option",
                        disableOption && "disabled"
                      )}
                      onClick={handleSelected(option)}
                      tabIndex={index}
                      style={{
                        backgroundColor: styleActive ? "#9bc4d7" : "",
                        fontWeight: styleActive ? "500" : "",
                      }}
                      title={text + caption}
                    >
                      <Highligh
                        valueInput={valueInput}
                        text={text}
                        caption={caption}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="notRenderList">
                <span>
                  {" "}
                  {valueInput === ""
                    ? "Không có dữ liệu"
                    : "Không tìm thấy dữ liệu"}
                </span>
              </div>
            )}
          </div>
        ),
        document.body
      )}
    </div>
  );
};

Suggest.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isServer: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFreeSolo: PropTypes.bool,
  maxLengthOfScroll: PropTypes.number,
  placeholder: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  isGreyTheme: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  getCaptionLabel: PropTypes.func,
};

export default Suggest;
