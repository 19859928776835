import { atom } from 'recoil';
import { CookieEnum, LanguageCodeEnum } from '../enum';
import { CookieTool } from '../tool';

const language = CookieTool.get(CookieEnum.language) || LanguageCodeEnum.vi

export const ShowSettingState = atom({
    key: 'ShowSettingState', 
    default: false, 
});
export const ShowLayerSettingState = atom({
    key: 'ShowLayerSettingState', 
    default: false, 
});
export const ShowLanguageSettingState = atom({
    key: 'ShowLanguageSettingState', 
    default: false, 
});
export const ShowEffectSettingState = atom({
    key: 'ShowEffectSettingState', 
    default: false, 
});
export const LanguageState = atom({
    key: 'LanguageState', 
    default: language, 
});
