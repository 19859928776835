import * as React from 'react';
import { useEffect, useState } from 'react';
import { StringTool } from '../../tool';
import styles from './checkBox.css';

export interface CheckBoxProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  name?: string,
  ref?: any,
  onChange?: (e: any) => void,
  disabled?: boolean,
  value?: string,
  checked?: boolean,
  label?: string,
  readOnly?: boolean,
  isIndeterminate?: boolean,
  positionCheck?: "left" | "right"
}

const CheckBox: React.FC<CheckBoxProps> = ({
  name,
  ref,
  onChange,
  disabled = false,
  value,
  checked = false,
  label,
  readOnly = false,
  isIndeterminate = false,
  positionCheck = "left",
  ...props

}) => {

  const [checkedState, setCheckedState] = useState(false)
  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.container, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.container, props.className as string))
  }, [props.className])

  useEffect(() => {
    checked !== checkedState && setCheckedState(checked)
  }, [checked])

  const handleChange = (e: any) => {
    if (!readOnly) {
      const { checked } = e.target
      setCheckedState(checked)
      onChange && onChange(e)
    }
  }

  return (
    <label {...props} className={StringTool.mergeClassName(className, disabled && styles.disabled, positionCheck == "right" && styles.containerRight)}>
      {positionCheck == "right" && <span className={StringTool.mergeClassName(styles.labelRight, disabled && styles.disabled)}>{label}</span>}
      <div className={styles.checkBoxInside} style={{ left: positionCheck == "right" ? "-18px" : ""}}>
        <input
          type='checkBox'
          checked={checkedState}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          ref={ref}
          name={name}
          className={StringTool.mergeClassName(styles.checkbox, disabled && styles.disabledCheckbox, readOnly && styles.readOnlyCheckbox, isIndeterminate && styles.indeterminate)}
        />
        <span className={StringTool.mergeClassName(styles.checkmark, disabled && styles.disabledCheckmark)}></span>
      </div>
      {positionCheck == "left" && <span className={StringTool.mergeClassName(styles.label, disabled && styles.disabled)}>{label}</span>}
    </label>
  );

};

export default CheckBox;
