const AppConfigLocal = {
    "mapKey": "208e1c99aa440d8bc2847aafa3bc0669",
    "map4dApiDomain": "https://api-app-dev.map4d.vn",
    "name": "Map4D",
    "appDomain": "http://localhost:3001",
    "homePage": "https://map4d.vn",
    "sdkDomain":  "https://api-dev.map4d.vn",
    "environment": "dev"
}
let AppConfig2 = AppConfigLocal
try {
    if (appSetting) {
        AppConfig2 = appSetting
    }
} catch (error) {

}

export const AppConfig = AppConfig2