import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Resource } from '../../../resource';

function DescriptionInput({ value = "", onChange }) {
  const [stateValue, setStateValue] = useState(null)

  useEffect(() => {
    if (value) {
      setStateValue(value)
    }
  }, [value])

  const handleChangeDescription = (event) => {
    let value = event?.target.value
    setStateValue(value)
    onChange && onChange(value)
  }

  return (
    <TextField
      name="description"
      variant="outlined"
      placeholder={Resource.updatePlace.addDescriptionForLocation}
      onChange={handleChangeDescription}
      defaultValue={stateValue}
      multiline
    />
  )
}

DescriptionInput.propTypes = {
};

export default DescriptionInput;
