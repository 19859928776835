import { AppConfig, CommonConfig } from '../config';
import { SessionEnum, TypeHistoryEnum } from '../enum';
import { StringTool } from './stringTool';
export class HistoryTool {
    static searchHistory = (text) => {
        let search = StringTool.xoaDau(text)?.toLowerCase() || ""
        let histories = HistoryTool.getHistory();
        let searchResults = []
        if (!text) {
            let start = histories.length - 3
            start = start < 0 ? 0 : start
            searchResults = histories?.slice(start, histories?.length)
        }
        else {
            searchResults = histories?.filter(item => {
                let name = StringTool.xoaDau(item?.text || item?.name)?.toLowerCase()
                return name?.includes(search)
            })
            let start = searchResults?.length - 3
            start = start < 0 ? 0 : start
            searchResults = searchResults?.slice(start, searchResults.length)
        }
        return searchResults
    }
    static getHistory = () => {
        let historyString = sessionStorage.getItem(SessionEnum.history)
        let history = []
        if (historyString) {
            history = JSON.parse(historyString)
        }
        return history
    }
    static positionItemInHistory = (item) => {
        let history = HistoryTool.getHistory();
        let index = -1
        if (item.typeHistory == TypeHistoryEnum.search) {
            index = history.findIndex(itemHistory => { return itemHistory.placeType == item.placeType && itemHistory.text == item.text && itemHistory.typeHistory == TypeHistoryEnum.search })
        }
        if (item.typeHistory == TypeHistoryEnum.place) {
            index = history.findIndex(itemHistory => { return itemHistory.id == item.id && itemHistory.typeHistory == TypeHistoryEnum.place })
        }
        return index
    }

    static insertItemToHistory = (item) => {
        let positionItemInHis = HistoryTool.positionItemInHistory(item)
        let history = HistoryTool.getHistory();
        if (positionItemInHis > -1) {
            let first = history.slice(0, positionItemInHis)
            let last = history.slice(positionItemInHis + 1, history.length)
            let itemIndex = history[positionItemInHis]
            history = first.concat(last)
            history.push(itemIndex)
        }
        else {
            if (history.length < CommonConfig.maxHistory) {
                history.push(item)
            }
            else {
                history = history.slice(history.length - (CommonConfig.maxHistory - 1), history.length)
                history.push(item)
            }
        }
        sessionStorage.setItem(SessionEnum.history, JSON.stringify(history))
    }

    static addPlaceToHistory = (place) => {
        let historyPlaceSearch = {
            id: place?.id,
            name: place?.name,
            address: place?.address,
            typeHistory: TypeHistoryEnum.place
        }
        HistoryTool.insertItemToHistory(historyPlaceSearch)
    }

    static addSearchToHistory = (text, type = null) => {
        let historyItem = {
            text: text,
            placeType: type,
            typeHistory: TypeHistoryEnum.search,
        }
        HistoryTool.insertItemToHistory(historyItem)
    }
}