import { DecodeMapTypeEnum, UriEnum } from "../enum";

export class UrlTool {
    static paramsToObject = (entries) => {
        const result = {}
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }
    static queryToJson = (search) => {
        // const jsonData = new Proxy(new URLSearchParams(search), {
        //     get: (searchParams, prop) => searchParams.get(prop),
        // });
        const urlParams = new URLSearchParams(search);
        const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
        const params = UrlTool.paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}
        return params;
    };

    static jsonToQuery = (obj) => {
        let queries = [];
        Object.keys(obj)?.forEach((item) => {
            if (obj[item] != null && obj[item] != undefined && obj[item] != "null" && obj[item] != "undefined") {
                if (item.toLowerCase() != "camera") {
                    queries.push(encodeURIComponent(item) + "=" + encodeURIComponent(obj[item]) );
                } else {
                    queries.push(item + "=" + obj[item]);
                }
            }
        });
        return "?" + queries?.join("&") || "";
    };
    static getCameraInfo = () => {
        let mapParams = {};
        let parameters = [];
        let mapSearchParams = new URLSearchParams(location.search);
        let camera = mapSearchParams.get(UriEnum.camera);

        if (camera) {
            parameters = camera.split(",");
        }
        if (parameters.length == 6) {
            mapParams.center = [parseFloat(parameters[1]), parseFloat(parameters[0])];
            mapParams.zoom = parseFloat(parameters[2]);
            mapParams.tilt = parseFloat(parameters[3]);
            mapParams.bearing = parseFloat(parameters[4]);
            mapParams.mapType = DecodeMapTypeEnum[parameters[5]]
        }
        return mapParams;
    };

    static createSearch=(query, option={extendOldQuery: false})=>{
        let q = query || {}
        if(option?.extendOldQuery){
          let old = UrlTool.queryToJson(location.search)
          q = {
            ...old,
            ...query || {}
          }
        }
        let searchString = UrlTool.jsonToQuery(q)
        return searchString
    }
}

