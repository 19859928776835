import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApiTool } from '../../../tool';
import { CodeEnum } from '../../../enum';
import { Autocomplete } from '@material-ui/lab';
import { Resource } from '../../../resource';
import { UrlConfig } from '../../../config/urlConfig';
import { TextField } from '@material-ui/core';
import { CommonConfig } from '../../../config';

function CategoryInput(props) {
  const { defaultValue, value, onChange, error } = props
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categories, setCategories] = useState(defaultValue || []);
  const [text, setText] = useState('')

  // useEffect(() => {
  //   if (!("value" in props)) {
  //     onChange && onChange(categories)
  //   }
  // }, [categories, props])

  const getCategories = (placeTypes) => {
    let body = {
      searchKey: placeTypes,
    };
    setLoadingCategory(true);
    ApiTool.queryGetFromJson(UrlConfig.placeType.getListSearch, body, (res) => {
      setLoadingCategory(false);
      if (res?.code == CodeEnum.ok && res?.result) {
        let cateOption = res?.result?.map((cate) => ({ id: cate.id, name: cate.name }))
        let selectedItems = categories || []
        if ("value" in props) {
          selectedItems = value || []
        }
        let options = cateOption?.filter(cate => {
          let find = selectedItems?.find(t => t.id == cate.id)
          return !find
        })
        setCategoryOptions(options);
      }
    }, true,
    );
    setText(placeTypes)
  };

  const handleChangeCategory = (e, value) => {
    setCategories(value);
    onChange && onChange(value)
  };

  return (
    <Autocomplete
      closeText={Resource.common.close}
      openText={Resource.common.open}
      clearText={Resource.common.clear}
      multiple
      fullWidth
      value={(("value" in props) ? value : categories) || []}
      getOptionLabel={(option) => {
        return option.name
      }}
      filterSelectedOptions
      renderOption={(option) => {
        return option.name
      }}
      options={categoryOptions}
      loading={loadingCategory}
      onChange={handleChangeCategory}
      onInputChange={(e, value) => getCategories(value)}
      renderInput={(params) =>
        <TextField
          {...params}
          id=""
          label={Resource.updatePlace.addCategory}
          variant="outlined"
          error={error}
        />
      }
      noOptionsText={
        text ? Resource.common.noOption : Resource.common.enterKeyword
      }
    />
  )
}


CategoryInput.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool
};

export default CategoryInput;
