import React, { useEffect, useState } from 'react';
import { ImageConfig } from '../../../config';
import { MediaTypeEnum } from './../../../enum/mediaTypeEnum';
import { AppTool } from './../../../tool/appTool';
import './mobileSwipeableViewsImages.scss';

function MobileSwipeableViewsImages({ place, paddingImages }) {

  const [photos, setPhotos] = useState(null)
  const [mediaFile, setMediaFile] = useState(null)

  useEffect(() => {
    let photos = place?.photos || []
    setPhotos(photos)
    setMediaFile(place?.mediaFiles)
  }, [place])

  const handleShowPhotoGallery = () => {
    AppTool.showMedia(mediaFile, MediaTypeEnum.image, place?.name)
  }

  return (
    (photos?.length > 0) ?
      <div className='mobileSwipeableViewsImagesCpn'>
        <div
          onClick={handleShowPhotoGallery}
          style={{
            width: `calc(100% - ${paddingImages}px)`,
            height: '100%',
            minWidth: `calc(100% - ${paddingImages}px)`
          }}
        >
          <img src={photos[0]?.url} width='100%' height='100%' style={{ objectFit: 'cover' }} />
        </div>
      </div>
      :
      <img style={{ objectFit: "cover" }} width='100%' height='100%' src={ImageConfig.thumbnailDefault} />
  )
}

MobileSwipeableViewsImages.propTypes = {
  //
};

export default MobileSwipeableViewsImages;
