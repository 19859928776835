import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ShowVr360State, Vr360State } from '../../../appState';
import { LinkIconConfig } from '../../../config';
import { Resource } from '../../../resource';
import { DataVr360State } from './../../../appState/dataVr360State';
import './vr360InputV2.scss';

function Vr360InputV2(props) {
  const { value, onChange, subTitle } = props

  const [valueState, setValueState] = useState([])

  const [vr360State, setVr360State] = useRecoilState(Vr360State)
  const setShowVr360State = useSetRecoilState(ShowVr360State)
  const setDataVr360State = useSetRecoilState(DataVr360State)

  useEffect(() => {
    return () => {
      setVr360State(null)
      setDataVr360State(null)
    }
  }, [])

  useEffect(() => {
    setValueState(value)
    setDataVr360State(value)
  }, [value])

  useEffect(() => {
    if (vr360State) {
      setValueState(vr360State)
      onChange && onChange(vr360State)
    }
  }, [vr360State])

  const onAddNewItem = () => {
    setShowVr360State({
      show: true,
      subTitle: subTitle || ''
    })
  }

  const onRemoveItem = (index) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setValueState(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      onChange && onChange(list)
      setDataVr360State(list)
      return list
    })
  }

  return (
    <div className='vr360InputCpn'>
      <div className='boxContainer'>
        <div
          className='boxAddNew'
          onClick={onAddNewItem}
        >
          <img src={LinkIconConfig.metadata.vr360Add} width={24} height={24} />
          <div className='textCaption'>{Resource.virtual.add}</div>
        </div>
        {
          valueState?.length > 0 &&
          <div className='boxListItem'>
            {
              valueState?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='item'
                    title={item?.name}
                  >
                    <div className='symbolIcon'>
                      <img src={LinkIconConfig.metadata.vr360} alt='symbolIcon' />
                    </div>
                    <div className='content'>{item?.name}</div>
                    <div
                      onClick={onRemoveItem(index)}
                      className='closeIcon'
                      title={Resource.common.delete}
                    >
                      <img src={LinkIconConfig.metadata.remove} alt='closeIcon' />
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

    </div>
  )
}

Vr360InputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  subTitle: PropTypes.string
};

export default Vr360InputV2;
