import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CommonConfig, ImageConfig, LinkIconConfig } from '../../../config';
import { AppData } from '../../../data';
import { AppTool, MapTool } from '../../../tool';
import { useRef } from 'react';
import { Resource } from '../../../resource';
import { useRecoilState } from 'recoil';
import { DisableMyLocationState } from '../../../appState';
import { AlertTypeEnum, CodeEnum } from '../../../enum';
const useStyles = makeStyles({
  root: {
    minWidth: '56px',
    height: '56px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 3px rgb(0 0 0 / 30%)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    }
  },
  rootSmall: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    boxShadow: '0 1px 3px rgb(0 0 0 / 30%)',
    height: '30px',
    padding: 0,
    border: 'none',
    '& span': {
      margin: '8px',
      textTransform: 'initial'
    },
    '& img': {
      marginRight: '10px'
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    }
  }
})
function MobileMyLocationButton({ isSmall }) {
  const classes = useStyles()
  const [disable, setDisable] = useRecoilState(DisableMyLocationState)

  const markerRef = useRef()

  const handleLocate = (e) => {
    AppTool.getMyLocation(res => {
      if (res?.code == CodeEnum.ok) {
        if (!markerRef.current && res?.accuracy <= CommonConfig.maxAccuracy) {
          markerRef.current = new map4d.Marker({
            position: res?.result,
            anchor: [0.5, 0.5],
            icon: new map4d.Icon(50, 50, ImageConfig.myLocation),
            zIndex: 1000
          })
          markerRef.current.setMap(AppData.map)
        }
        MapTool.moveToLocation(res?.result)
      }
      else {
        AppTool.alert(Resource.myLocation.alert.error, AlertTypeEnum.warning)
        setDisable(true)
      }
    })
  }

  useEffect(() => {
    return () => {
      markerRef.current?.setMap(null)
    }
  }, [])

  return (
    isSmall ?
      <Button className={classes.rootSmall} onClick={handleLocate} disabled={disable} variant='contained'>
        <Typography variant='caption'>{Resource.common.yourLocation}</Typography>
        <img src={LinkIconConfig.location} alt='icon location' width={18} height={18} />
      </Button>
      :
      <Button className={classes.root} onClick={handleLocate} disabled={disable} variant='contained'>
        <img src={LinkIconConfig.location} alt='icon location' width={24} height={24} />
      </Button>
  )
}

MobileMyLocationButton.propTypes = {
  //
};

export default MobileMyLocationButton;
