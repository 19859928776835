import {
  Box,
  Button,
  Divider,
  Paper,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import { CommonConfig } from "../../config";
import { CookieEnum } from "../../enum";
import { Resource } from "../../resource";
import { SidebarTool, UserTool } from "../../tool";
import PersonalAddress from "../common/personalAddress/personalAddress";
import SuggestPlace from "../common/suggestPlace";
import { CookieTool } from "./../../tool";
import { useRecoilValue } from "recoil";
import { FocusInputState, ShowSuggestPlaceState } from "../../appState";
import ShowPositionSelect from "./../showPositionSelect/showPositionSelect";
import ShowSuggestPlace from "../showSuggestPlace/showSuggestPlace";
import './home.css'

const heightBtn = 55;
const useStyles = makeStyles((theme) => ({
  expandBtn: {
    marginTop: `${CommonConfig.padding}px`,
    zIndex: "0",
    paddingTop: "16px",
    textTransform: "inherit",
    backgroundColor: "#FFFFFF",
    fontWeight: "normal",
  },
  expandLess: {
    bottom: 0,
    position: "absolute",
    height: `${heightBtn}px`,
    display: "flex",
    alignItems: "center",
    textTransform: "inherit",
    borderRadius: 0,
    borderTop: "1px solid #E4E4E4",
    fontWeight: "normal",
  },
  showMore: {
    textTransform: "inherit",
    height: `${heightBtn}px`,
    fontWeight: "normal",
  },
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});

const duration = 300;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
};

const transitionStyles = {
  entering: {
    left: "0px",
    top: "0px",
    width: `${CommonConfig.widthSearchBar + CommonConfig.padding * 2}px`,
    height: "100%",
    position: "fixed",
  },
  entered: {
    left: "0px",
    top: "0px",
    width: `${CommonConfig.widthSearchBar + CommonConfig.padding * 2}px`,
    height: "100%",
    position: "absolute",
  },
  exiting: {
    position: "fixed",
    left: `${CommonConfig.padding}px`,
    top: `${CommonConfig.padding}px`,
    width: `${CommonConfig.widthSearchBar}px`,
    height: "94px",
  },
  exited: {
    position: "fixed",
    left: `${CommonConfig.padding}px`,
    top: `${CommonConfig.padding}px`,
    width: `${CommonConfig.widthSearchBar}px`,
    height: "0",
  },
};
function Home(props) {
  const classes = useStyles();
  const showSuggestPlace = useRecoilValue(ShowSuggestPlaceState)

  const focusInputState = useRecoilValue(FocusInputState);

  const [isFull, setIsFull] = useState(() => {
    let isFullCheck =
      CookieTool.get(CookieEnum.isFullSearch)?.toLowerCase() == "true";
    return isFullCheck;
  });
  const [isShowAll, setIsShowAll] = useState(false);

  useEffect(() => {
    if (isFull) {
      setTimeout(() => {
        SidebarTool.setFullBar(true);
        setIsShowAll(true);
      }, 300);
    } else {
      SidebarTool.setFullBar(false);
      setIsShowAll(false);
    }
  }, [isFull]);

  useEffect(() => {
    return () => {
      SidebarTool.setFullBar(true);
    };
  }, []);

  const handleClickHideAll = (e) => {
    setIsFull(false);
  };
  const handleClickExpand = (e) => {
    setIsFull(true);
  };
  const handleClickSeeMore = (e) => {
    setIsFull(true);
  };

  const user = UserTool.getUser();
  return (
    <>
      <ShowPositionSelect />
      <ShowSuggestPlace />
    </>


    // <>
    //   <Transition appear in={isFull} timeout={duration}>
    //     {(state) => (
    //       <Box
    //         zIndex={1}
    //         bgcolor="background.paper"
    //         style={{
    //           ...defaultStyle,
    //           ...transitionStyles[state],
    //         }}
    //       >
    //         {isShowAll && (
    //           <>
    //             <Box
    //               height="calc(100% - 109px)"
    //               overflow="auto"
    //               mt={`${CommonConfig.heightSearchBar + CommonConfig.padding
    //                 }px`}
    //               px={CommonConfig.padding + "px"}
    //               pt={CommonConfig.padding + "px"}
    //             >
    //               {user && (
    //                 <Box className={classes.borderShadow}>
    //                   <PersonalAddress />
    //                 </Box>
    //               )}

    //               {/* <Box className={classes.borderShadow} mt={1} px={2} pt={"10px"}>
    //                 <Typography variant="h6">{Resource.common.classService}</Typography>
    //                 <Box padding='15px 8px' bgcolor='background.paper'>
    //                   <ClassService />
    //                 </Box>
    //               </Box> */}

    //               <Box
    //                 className={classes.borderShadow}
    //                 mt={1}
    //                 px={2}
    //                 pt={"10px"}
    //               >
    //                 <Typography variant="h6">
    //                   {Resource.common.suggestPlace}
    //                 </Typography>
    //                 <Box pt="24px" px="6px" bgcolor="background.paper">
    //                   <SuggestPlace isShowMore />
    //                 </Box>
    //               </Box>
    //             </Box>
    //             <ThemeProvider theme={theme}>
    //               <Button
    //                 onClick={handleClickHideAll}
    //                 fullWidth
    //                 className={classes.expandLess}
    //                 endIcon={<ExpandLess style={{ color: "#0000008A" }} />}
    //               >
    //                 <Typography variant="body2" style={{ color: "#0000008A" }}>
    //                   {Resource.common.expandLess}
    //                 </Typography>
    //               </Button>
    //             </ThemeProvider>
    //           </>
    //         )}
    //       </Box>
    //     )}
    //   </Transition>
    //   {!isFull && (
    //     <Box marginX={CommonConfig.padding + "px"} height={0}>
    //       {focusInputState && (
    //         <>
    //           {/* <Box clone mt={CommonConfig.padding + "px"} overflow="hidden">
    //             <Paper elevation={3}>
    //               <Box paddingX='20px' pt="16px">
    //                 <Typography variant="h6">{Resource.common.classService}</Typography>
    //                 <Box mt="16px" pb="16px">
    //                   <ClassService />
    //                 </Box>
    //               </Box>
    //             </Paper>
    //           </Box> */}

    //           <Box clone mt={CommonConfig.padding + "px"} overflow="hidden">
    //             <Paper elevation={3}>
    //               <Box paddingX="20px" pt="16px">
    //                 <Typography variant="h6">
    //                   {Resource.common.suggestPlace}
    //                 </Typography>
    //                 <Box mt="24px" pb="16px">
    //                   <SuggestPlace />
    //                 </Box>
    //               </Box>
    //               <Divider />
    //               <Button
    //                 onClick={handleClickSeeMore}
    //                 endIcon={<ExpandMore style={{ color: "#0000008A" }} />}
    //                 fullWidth
    //                 className={classes.showMore}
    //               >
    //                 <Typography variant="body2" style={{ color: "#0000008A" }}>
    //                   {Resource.common.seeMore}
    //                 </Typography>
    //               </Button>
    //             </Paper>
    //           </Box>
    //         </>
    //       )}
    //       {!focusInputState && (
    //         <ThemeProvider theme={theme}>
    //           <Button
    //             onClick={handleClickExpand}
    //             variant="contained"
    //             className={classes.expandBtn}
    //             fullWidth
    //             endIcon={<ExpandMore style={{ color: "#0000008A" }} />}
    //           >
    //             <Typography variant="body2" style={{ color: "#0000008A" }}>
    //               {Resource.common.expandMore}
    //             </Typography>
    //           </Button>
    //         </ThemeProvider>
    //       )}
    //     </Box>
    //   )}
    //   {/* <ShowPositionSelect /> */}
    // </>
  );
}
Home.propTypes = {
  //
};

export default Home;
