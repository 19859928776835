import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { StringTool } from '../../tool';
import styles from './chip.css';

export interface ButtonProps extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "onDelete"> {
  icon?: ReactNode,
  onDelete?: (event: any, value: any) => void,
  value?: string,
}

const ICON_DELETE = <svg id="Component_791_8" data-name="Component 791 – 8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<path id="Path_12306" data-name="Path 12306" d="M0,0H24V24H0Z" fill="none"/>
<path id="Path_12307" data-name="Path 12307" d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-11.414L9.172,7.757,7.757,9.172,10.586,12,7.757,14.828l1.415,1.415L12,13.414l2.828,2.829,1.415-1.415L13.414,12l2.829-2.828L14.828,7.757Z" fill="#869195"/>
</svg>

const Chip: React.FC<ButtonProps> = ({
  icon,
  onDelete,
  value,
  children,
  ...props
}) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.container, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.container, props.className as string))
  }, [props.className])

  const handleDelete = (value: any) => (e: any) => {
    e.stopPropagation();
    onDelete && onDelete(value, e)
  }

  return (
    <button {...props} className={StringTool.mergeClassName(className, onDelete ? styles.deleteContainer : styles.iconContainer)}>
      {
        icon && <span className={styles.icon}>{icon}</span>
      }
      {children}
      {
        onDelete && <div className={styles.delete} onClick={handleDelete(value)}>{ICON_DELETE}</div>
      }
    </button>
  );
};

export default Chip;
