import React, {
  useEffect, useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RatingInput } from "ui";
import { PlaceTypeState } from "../../appState";
import { ImageConfig, LinkIconConfig, SvgIconConfig } from "../../config";
import { RouterConfig } from "../../config/routerConfig";
import { UrlConfig } from "../../config/urlConfig";
import { AppData } from "../../data";
import { AppEventEnum, CodeEnum, MapEventEnum, WeightingEnum } from "../../enum";
import { Resource } from "../../resource";
import { ApiTool, AppEventTool, AppTool, UrlTool } from "../../tool";
import { useQuery } from "../../useHooks";
import BusinessHoursStatus from "../businessHoursStatus/businessHoursStatus";
import IsLoading from "../isLoading/isLoading";
import SearchBarSuggestAlongDirection from "../searchBarSuggestAlongDirection/searchBarSuggestAlongDirection";
import TextView from "../textView/textView";
import "./suggestAlongDirection.scss";
import HideSidebarShadow from "../hideSidebarShadow/hideSidebarShadow";
import PoiDetail from "../poiDetail/poiDetail";

const RADIUS = 1000;
const countPoiShow = 20

const WeightingOption = {
  [WeightingEnum.fastest]: "fastest",
  [WeightingEnum.shortest]: "shortest",
  [WeightingEnum.balance]: "balance"
}

const getListDraw = (list) => {
  let result = []
  if (list?.length > countPoiShow) {
    let index = list?.length - countPoiShow
    result = list?.slice((index), (list?.length + 1))
  }
  else {
    result = list
  }
  return result
}

function SuggestAlongDirection(props) {
  const { searchKey, onClose, onBack, bodyRoute, type, onAddPoi } = props;

  const router = useHistory();
  const placeMarker = useRef([]);
  const topRef = useRef(null);
  const currentPlace = useRef(null);
  const pageNumberRef = useRef(0)
  const indexRef = useRef()

  const [places, setPlace] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [textState, setTextState] = useState('');
  const [typeState, setTypeState] = useState('');
  const [count, setCount] = useState(0)

  const placeTypeState = useRecoilValue(PlaceTypeState);
  const [detailId, setDetailId] = useState(null)
  const { id } = useQuery()
  const textCurrent = useRef()
  const [textNotFound, setTextNotFound] = useState('')

  // useEffect(()=>{
  //   setDetailId(id)
  // },[id])

  useEffect(() => {
    setTextState(decodeURIComponent(searchKey || ""));
  }, [searchKey]);
  useEffect(() => {
    setTypeState(type);
  }, [type]);
  useEffect(() => {
    textCurrent.current = textState
  }, [textState])

  useEffect(() => {
    const clickMarker = AppData.map.addListener(MapEventEnum.click, (args) => {
      let data = args.poi?.getUserData()
      setDetailId(data?.id)
    }, { poi: true })
    return () => {
      clickMarker?.remove()
      removePlaceMarker();
    };
  }, []);

  useEffect(() => {
    let route = {
      mode: bodyRoute?.mode,
      language: bodyRoute?.language,
      weighting: WeightingOption[bodyRoute?.weighting],
      avoid: null,
      avoidRoads: bodyRoute?.avoidRoads || null,
      departureTime: bodyRoute?.departureTime || null,
      origin: bodyRoute?.origin,
      destination: bodyRoute?.destination,
      waypoints: bodyRoute?.points || null,
      isOptimize: bodyRoute?.isOptimize
    }
    topRef.current?.scrollIntoView()
    if (textState && bodyRoute) {
      getListPlace(textState, typeState, route, pageNumberRef?.current, { isReset: true });
      pageNumberRef.current = 0
    }
  }, [textState, bodyRoute]);

  useEffect(() => {
    if (places?.length > 0) {
      let listDraw = getListDraw(places)
      drawMarker(listDraw)
    }
  }, [places]);

  // useEffect(()=>{
  //   let search = UrlTool.createSearch({ id: detailId }, { extendOldQuery: true })
  //   window.history.replaceState(null, null, RouterConfig.direction + search);
  // },[detailId])

  const removePlaceMarker = () => {
    placeMarker?.current?.forEach((place) => {
      place?.setMap(null);
    });
    placeMarker.current = null
  };

  const handleOnclickItem = (item) => (e) => {
    setDetailId(item?.id)
  };

  const onMouseEnterItem = (item, index) => (e) => {
    indexRef.current = index
    currentPlace.current = new map4d.Marker({
      position: item?.location,
      iconView:
        `
    <div class="markerHover">
      <svg width="32" height="39.385" viewBox="0 0 32 39.385">
        <g>
          <g transform="translate(3.08 1.816)">
            <path d="M78.491,37.135a13.279,13.279,0,0,0-5.844-4.877A12.989,12.989,0,0,0,67.485,31.2h-.026A12.989,12.989,0,0,0,62.3,32.259a13.317,13.317,0,0,0-5.831,4.877,13.6,13.6,0,0,0-2.266,7.53v.026h0v.065h0a13.479,13.479,0,0,0,2.291,7.465c.438.706,2.37,2.628,4.584,5.138a18.081,18.081,0,0,1,3.708,6.06l.359.837a36.878,36.878,0,0,1,1.364,4.19,1.025,1.025,0,0,0,1.957,0,26.406,26.406,0,0,1,1.208-4.014l.3-.772a19.428,19.428,0,0,1,3.833-6.3c2.214-2.51,4.233-4.432,4.67-5.138a13.511,13.511,0,0,0,2.291-7.465h0v-.065h0v-.026A13.711,13.711,0,0,0,78.491,37.135Z" transform="translate(-54.2 -31.2)" fill="#e40000" stroke="#b00020" stroke-width="1" />
          </g>
          <ellipse cx="4.5" cy="4.57" rx="4.5" ry="4.57" transform="translate(12 10.156)" fill="#b00020" />
        </g>
      </svg>
    </div>
    `,
      anchor: [0.5, 1]
    });
    placeMarker.current && placeMarker.current[index]?.setMap(null)
    currentPlace?.current.setMap(AppData.map);
  }

  const onMouseLeaveItem = () => {
    placeMarker?.current[indexRef.current]?.setMap(AppData.map)
    currentPlace?.current.setMap(null);
  }

  const fitBoundCamera = (pois) => {
    let bounds = new map4d.LatLngBounds();

    pois?.forEach(poi => {
      let pos = poi?.location
      bounds.extend([pos.lng, pos.lat]);
    })

    let paddingOptions = {
      top: 20,
      bottom: 20,
      left: 440,
      right: 20,
    };
    let camera = AppData.map.getCameraWithBounds(bounds, paddingOptions);
    if (pois?.length > 2) {
      AppTool.moveCamera(camera, { animate: true });
    }
  }
  const getListPlace = (text, type, route, pageNumber, option = { isReset: false }) => {
    const { isReset } = option
    let body = {
      types: type ? [type] : [],
      searchKey: type ? "" : text,
      pageSize: 10,
      radius: RADIUS,
      route: route,
      pageNumber: isReset ? 0 : pageNumber
    };
    let prevTime = new Date().getTime()
    ApiTool.post(
      UrlConfig.place.alongRoute,
      body,
      (res) => {
        if (res?.code == CodeEnum.ok) {
          removePlaceMarker();
          let newCount = res?.result?.count
          if (count !== newCount) {
            setCount(newCount)
          }
          let data = res?.result?.data?.filter(item => item?.id) || [];
          fitBoundCamera(data)
          if (isReset) {
            setPlace(data)
          }
          else {
            let time = new Date().getTime()
            let realDelta = time - prevTime
            let timeOut = 0
            if (realDelta < 1000) {
              timeOut = 1000 - realDelta
            }
            setTimeout(() => {
              setLoadingData(false)
              setPlace((prev) => {
                let newValue = prev || []
                newValue = newValue?.concat(data || []);
                return newValue
              });
            }, timeOut)
          }
        }
      }
    );
  };

  const drawMarker = (data) => {
    removePlaceMarker();
    if (data?.length > 0) {
      let listMarker = []
      data?.forEach((place, index) => {
        let marker = new map4d.POI({
          type: place?.types?.length > 0 ? place?.types[0] : "nocat",
          position: place?.location,
          title: place?.name,
        });
        marker.setMap(AppData.map);
        marker.setUserData({
          id: place?.id || "",
          name: place?.name,
          address: place?.address,
          location: place?.location,
        });
        listMarker.push(marker)
        placeMarker.current = listMarker
      });
    }
  }

  const handleScroll = (event) => {
    let route = {
      mode: bodyRoute?.mode,
      language: bodyRoute?.language,
      weighting: WeightingOption[bodyRoute?.weighting],
      avoidRoads: bodyRoute?.avoidRoads || null,
      departureTime: bodyRoute?.departureTime || null,
      origin: bodyRoute?.origin,
      destination: bodyRoute?.destination,
      waypoints: bodyRoute?.points || null,
      isOptimize: bodyRoute?.isOptimize
    }
    if (
      Math.round(
        event.currentTarget.scrollTop + event.currentTarget.offsetHeight + 1
      ) >= Math.round(event.currentTarget.scrollHeight) && places?.length !== count
    ) {
      setLoadingData(true);
      setLoadingData(true)
      pageNumberRef.current = pageNumberRef.current + 1
      getListPlace(textState, typeState, route, pageNumberRef?.current);
    }
    setScrollTop(event.currentTarget.scrollTop);
  };
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeInput = (value) => {
    let text = value?.isDefaultType ? value?.text : value?.name || value
    let type = value?.type || null
    setTextState(text)
    setTypeState(type)
    if (value) {
      AppEventTool.triggerEvent(AppEventEnum.changeSearchInput, { text: text, type: type })
      setDetailId(null)
    }
    if (!text) {
      setTextNotFound(textCurrent.current)
    }
  };

  const handleClose = () => {
    setDetailId(null)
    // if (detailId) {
    //   router.push({
    //     pathname: window.location.pathname,
    //     search: UrlTool.createSearch({ id: undefined }, { extendOldQuery: true })
    //   })
    // }
    onClose && onClose()
  };

  const handleBack = () => {
    onBack && onBack();
  };

  const handleToAddPoi = (location) => (e) => {
    e.stopPropagation();
    onAddPoi && onAddPoi(location)
  }

  const handleCloseDetail = () => {
    setDetailId(null)
  }
  return (
    <div className="suggestAlongDirectionContainer" >
      <div className="suggestAlongDirectionCpn">
        <SearchBarSuggestAlongDirection
          value={textState}
          onChange={handleChangeInput}
          onClose={handleClose}
          onBack={handleBack}
        />
        <div className={`boxContent borderShadow ${detailId ? 'showDetailIdContent' : ''}`}>
          <div className="boxContent_body" onScroll={handleScroll}>
            <span ref={topRef}></span>
            {
              places?.length > 0 &&
              places?.map((item, index) => {
                let photos = item?.photos || [];
                return (
                  <div
                    className={`boxContent_list_item ${index > 0 ? "borderTop" : ""
                      }`}
                    id={item?.id}
                    key={item?.id}
                    onClick={handleOnclickItem(item)}
                    onMouseEnter={onMouseEnterItem(item, index)}
                    onMouseLeave={onMouseLeaveItem}
                  >
                    <div className="boxContent_list_item_text">
                      <TextView
                        style={{
                          fontSize: "1rem",
                          lineHeight: 1,
                          fontWeight: "500",
                          marginBottom: "0.5rm",
                        }}
                      >
                        {item.name}
                      </TextView>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "0.5rem",
                        }}
                      >
                        <TextView
                          variant="body1"
                          style={{
                            marginBottom: "0.5rem",
                            lineHeight: "1",
                            color: "#727272",
                          }}
                        >
                          {placeTypeState[item?.types[0]]?.name || item?.id}
                        </TextView>
                        {item?.ratingReport && (
                          <div className="rating">
                            <TextView variant="body1" style={{ lineHeight: "1" }}>
                              {item?.ratingReport.avgStars}
                            </TextView>
                            <RatingInput
                              size={13}
                              avgRating={item?.ratingReport.avgStars}
                            />
                            <TextView variant="body1" style={{ lineHeight: "1" }}>
                              {"(" + item?.ratingReport.totalComments + ")"}
                            </TextView>
                          </div>
                        )}
                        <TextView
                          variant="body1"
                          style={{
                            marginBottom: "0.5rem",
                            lineHeight: "1",
                            color: "#727272",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.address}
                        </TextView>
                        <TextView
                          style={{
                            lineHeight: "1",
                            marginTop: "0.25rem",
                            color: "#727272",
                          }}
                        >
                          <BusinessHoursStatus value={item?.businessHours} />
                        </TextView>
                      </div>
                    </div>
                    {/* <div className="boxContent_list_item_button" onClick={handleToAddPoi(item?.location)}>
                    {SvgIconConfig.direction.addDirection}
                    <p>{Resource.common.addRestingPoi}</p>
                  </div> */}
                    <div className="boxContent_list_item_avatar">
                      <img
                        style={{ borderRadius: "4px" }}
                        src={
                          photos?.length > 0
                            ? photos[0].url
                            : ImageConfig.avaterDefaultGray
                        }
                        height={80}
                        width={80}
                        alt="logo"
                      />
                    </div>
                  </div>
                );
              })
            }
            {
              places?.length > 6 && places?.length == count && <div style={{ textAlign: "center" }}>{Resource.common.endData}</div>
            }
            {places && places?.length == 0 &&
              <div
                style={{ margin: "1rem" }}
                className="borderShadow"
              >
                <TextView variant="body1">
                  {Resource.formatString(
                    Resource.message.resultsNotFoundInTheCurrentRegion + " " + Resource.message.pleaseCheckYourSearchKeywords,
                    { text: `"${textState || textNotFound}"` }
                  )}
                </TextView>
              </div>
            }
            <div
              className="boxContent_list_item"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: '5rem'
              }}
            >
              {loadingData && <IsLoading />}
            </div>
          </div>
        </div>
        {scrollTop > 100 && (
          <div className="moveToTop_btn" onClick={scrollToTop}>
            {SvgIconConfig.direction.verticalArrow}
            <TextView variant="body1" style={{ fontWeight: 600 }}>
              {Resource.common.backToTop}
            </TextView>
          </div>
        )}
      </div>
      {detailId && <>
        <HideSidebarShadow />
        <div className='detailPoiCpn'>
          <PoiDetail id={detailId} onClose={handleCloseDetail} hiddenDirection/>
        </div>
      </>
      }
    </div>
  );
}

SuggestAlongDirection.prototype = {
  searchKey: PropTypes.string,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  bodyRoute: PropTypes.any,
  type: PropTypes.string,
  onAddPoi: PropTypes.func
}
export default SuggestAlongDirection;
