import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { LinkIconConfig } from '../../../config';
import { MediaTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';

const useStyles = makeStyles({
  addPhotoBtn: { 
    textTransform: 'inherit',
  },
  addPhotoBtnItem: {
    height: '50px',
    width: '50px',
    minWidth: '50px',
    margin: '10px 10px 0 0',
  },
  closeBtn: {
    position: 'absolute',
    top: '3px',
    right: '3px',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    padding: 0,
    '&:hover': {
      backgroundColor: '#a29c9c',
    }
  },
  photoItem: {
    border: '1px solid #839ef5',
    borderRadius: '4px',
    margin: '10px 10px 0 0',
  },
  closeIcon: {
    width: '15px',
    height: '15px'
  }
})
function PhotosInput(props) {
  const { defaultValue, onChange } = props
  const classes = useStyles()
  const [photos, setPhotos] = useState(() => {
    return defaultValue || []
  })
  const [photosFile, setPhotosFile] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    setPhotos((defaultValue || [])?.filter(m=>m?.typeFile==MediaTypeEnum.image))
  }, [defaultValue])

  useEffect(() => {
    let imageFiles = []
    photosFile?.forEach(element => {
      imageFiles.push(element.file)
    })
    onChange && onChange(photos, imageFiles)
  }, [photos, photosFile])

  const onChangePhotosFile = (e) => {
    let arrFiles = e.target.files
    setPhotosFile(prev => {
      let list = [...(prev || [])]
      for (let i = 0; i < arrFiles?.length; i++) {
        let file = arrFiles[i]
        let newFile = {
          url: URL.createObjectURL(file),
          file: file,
        }
        list.push(newFile)
      }
      return list
    })
    inputRef.current.value = ""
  }
  const handleRemovePhotos = (index) => (e) => {
    setPhotos(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      return list
    })

  }

  const handleRemovePhotosFile = (index) => (e) => {
    setPhotosFile(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      return list
    })
  }

  return (
    <Box width='100%'>
      {(photos?.length > 0 || photosFile?.length > 0) ?
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <Button className={classes.addPhotoBtnItem} component="label" color='primary' variant='outlined' >
            <img src={LinkIconConfig.requestUpdatePlace.photo} />
            <input
              ref={inputRef}
              name="images"
              id="imgid"
              className="imgcls"
              type="file"
              hidden
              accept="image/*"
              onChange={onChangePhotosFile}
              multiple
            />
          </Button>
          {
            photos?.map((photo, index) => {
              return (
                <Box key={photo?.url} width='50px' height='50px' position='relative' className={classes.photoItem}>
                  <img src={photo?.url} alt={photo?.name} width='100%' height='100%'
                    style={
                      {
                        // objectFit: 'cover',
                        borderRadius: '4px',
                        backgroundColor: '#000000'
                      }
                    } />
                  <IconButton onClick={handleRemovePhotos(index)} size='small' className={classes.closeBtn}><CloseIcon className={classes.closeIcon} /></IconButton>
                </Box>
              )
            })
          }

          {
            photosFile?.map((photoFile, index) => {
              return (
                <Box key={photoFile?.url} maxWidth='50px' maxHeight='50px' width='50px' height='50px' position='relative' className={classes.photoItem}>
                  <img src={photoFile?.url} alt={photoFile?.name} width='100%' height='100%'
                    style={
                      {
                        // objectFit: 'cover',
                        borderRadius: '4px',
                        backgroundColor: '#000000'
                      }
                    } />
                  <IconButton onClick={handleRemovePhotosFile(index)} size='small' className={classes.closeBtn}><CloseIcon className={classes.closeIcon} /></IconButton>
                </Box>
              )
            })
          }
        </Box>
        :
        <Button fullWidth className={classes.addPhotoBtn} component="label" color='primary' variant='outlined' startIcon={<img src={LinkIconConfig.requestUpdatePlace.photo} />}>
          {Resource.updatePlace.addPhoto}
          <input
            accept="image/*"
            ref={inputRef}
            name="images"
            id="imgid"
            className="imgcls"
            type="file"
            hidden
            onChange={onChangePhotosFile}
            multiple
          />
        </Button>

      }
    </Box>
  )
}

PhotosInput.propTypes = {
  defaultValue: PropTypes.any
};

export default PhotosInput;
