import React, { useEffect, useState } from 'react';
import { StringTool } from './../../tool';
import "./dialogAction.scss"
const DialogAction = ({ children, ...props }) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName("containerAction", props.className)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName("containerAction", props.className))
  }, [props.className])

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};

DialogAction.propTypes = {
  //
};

export default DialogAction;
