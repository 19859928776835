"use client";
import React from "react";

import { ErrorBoundary } from "react-error-boundary";

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log(error, resetErrorBoundary)
  return (
    <div role="alert" style={{width: "100%", overflow:"auto"}}>
      <p>Something went wrong:</p>
      <span style={{ color: "red" }}>{error.message}</span>
      <pre style={{ color: "red" }}>{error.stack}</pre>
    </div>
  );
}

const ErrorControl = (props) => {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {
        props.children
      }
    </ErrorBoundary>
  )
}
export default ErrorControl

