import * as moment from "moment";
import { DayOfWeekEnum, TypeBusinessHourEnum } from "../enum";
import { Resource } from "../resource";
export class BusinessHourTool {
    static generateToText = (businessHours) => {
        let textAreaHeight = "42px";
        let weekBusinessHours = [];
        if (businessHours && businessHours.length > 0) {
            weekBusinessHours = businessHours.slice(0);
        }
        let closes = [];
        if (businessHours && businessHours.length > 0) {
            for (let i = 0; i < 7; i++) {
                let day = i;
                let index = businessHours.findIndex((item) => {
                    return item.open.day == day;
                });
                if (index == -1) {
                    closes.push(day);
                }
            }
        } else {
            return Resource.common.add_hours;
        }

        let businessMatrix = [];
        while (weekBusinessHours && weekBusinessHours.length > 0) {
            let group = [];
            let item = weekBusinessHours[0];
            let j = 1;
            while (j > -1) {
                j = weekBusinessHours.findIndex((a) => {
                    if (item.open.time == "0000" && item.close == null && a.open.time == "0000" && a.close == null) {
                        return true;
                    }
                    if (item.close && a.close && item.open.time == a.open.time && item.close.time == a.close.time) {
                        return true;
                    }
                    return false;
                });
                if (j > -1) {
                    group.push(weekBusinessHours[j]);
                    weekBusinessHours.splice(j, 1);
                }
            }
            businessMatrix.push(group);
        }
        let lines = [];
        for (let l = 0; l < businessMatrix.length; l++) {
            let days = [];
            for (let h = 0; h < businessMatrix[l].length; h++) {
                let textDay;
                if (businessMatrix[l].length == 1) {
                    switch (businessMatrix[l][h].open.day) {
                        case DayOfWeekEnum.sunday:
                            textDay = Resource.common.sunday;
                            break;
                        case DayOfWeekEnum.monday:
                            textDay = Resource.common.monday;
                            break;
                        case DayOfWeekEnum.tuesday:
                            textDay = Resource.common.tuesday;
                            break;
                        case DayOfWeekEnum.wednesday:
                            textDay = Resource.common.wednesday;
                            break;
                        case DayOfWeekEnum.thursday:
                            textDay = Resource.common.thursday;
                            break;
                        case DayOfWeekEnum.friday:
                            textDay = Resource.common.friday;
                            break;
                        case DayOfWeekEnum.saturday:
                            textDay = Resource.common.saturday;
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (businessMatrix[l][h].open.day) {
                        case DayOfWeekEnum.sunday:
                            textDay = Resource.common.sunday_acronym;
                            break;
                        case DayOfWeekEnum.monday:
                            textDay = Resource.common.monday_acronym;
                            break;
                        case DayOfWeekEnum.tuesday:
                            textDay = Resource.common.tuesday_acronym;
                            break;
                        case DayOfWeekEnum.wednesday:
                            textDay = Resource.common.wednesday_acronym;
                            break;
                        case DayOfWeekEnum.thursday:
                            textDay = Resource.common.thursday_acronym;
                            break;
                        case DayOfWeekEnum.friday:
                            textDay = Resource.common.friday_acronym;
                            break;
                        case DayOfWeekEnum.saturday:
                            textDay = Resource.common.saturday_acronym;
                            break;
                        default:
                            break;
                    }
                }
                days.push(textDay);
            }
            let line = days.join(", ");
            line += ": ";
            if (businessMatrix[l][0].open.time == "0000" && businessMatrix[l][0].close == null) {
                line += Resource.common.datetime_24h;
            } else if (businessMatrix[l][0].close) {
                let hopen = parseInt(businessMatrix[l][0].open.time.slice(0, 2));
                let mopen = parseInt(businessMatrix[l][0].open.time.slice(2, 4));
                let dateOpen = new Date();
                dateOpen.setHours(hopen);
                dateOpen.setMinutes(mopen);
                let open = moment(dateOpen).format("hh:mm A");
                line += open + " - ";
                let hclosed = parseInt(businessMatrix[l][0].close.time.slice(0, 2));
                let mclosed = parseInt(businessMatrix[l][0].close.time.slice(2, 4));
                let dateClosed = new Date();
                dateClosed.setHours(hclosed);
                dateClosed.setMinutes(mclosed);
                let closed = moment(dateClosed).format("hh:mm A");
                line += closed;
            }
            lines.push(line);
        }
        let lineClosed = [];
        for (let g = 0; g < closes.length; g++) {
            let textDay;
            if (closes.length == 1) {
                switch (closes[g]) {
                    case DayOfWeekEnum.sunday:
                        textDay = Resource.common.sunday;
                        break;
                    case DayOfWeekEnum.monday:
                        textDay = Resource.common.monday;
                        break;
                    case DayOfWeekEnum.tuesday:
                        textDay = Resource.common.tuesday;
                        break;
                    case DayOfWeekEnum.wednesday:
                        textDay = Resource.common.wednesday;
                        break;
                    case DayOfWeekEnum.thursday:
                        textDay = Resource.common.thursday;
                        break;
                    case DayOfWeekEnum.friday:
                        textDay = Resource.common.friday;
                        break;
                    case DayOfWeekEnum.saturday:
                        textDay = Resource.common.saturday;
                        break;
                    default:
                        break;
                }
            } else {
                switch (closes[g]) {
                    case DayOfWeekEnum.sunday:
                        textDay = Resource.common.sunday_acronym;
                        break;
                    case DayOfWeekEnum.monday:
                        textDay = Resource.common.monday_acronym;
                        break;
                    case DayOfWeekEnum.tuesday:
                        textDay = Resource.common.tuesday_acronym;
                        break;
                    case DayOfWeekEnum.wednesday:
                        textDay = Resource.common.wednesday_acronym;
                        break;
                    case DayOfWeekEnum.thursday:
                        textDay = Resource.common.thursday_acronym;
                        break;
                    case DayOfWeekEnum.friday:
                        textDay = Resource.common.friday_acronym;
                        break;
                    case DayOfWeekEnum.saturday:
                        textDay = Resource.common.saturday_acronym;
                        break;
                    default:
                        break;
                }
            }
            lineClosed.push(textDay);
        }
        let lineTextClosed = lineClosed.join(", ");
        let num = 0;
        if (!lineTextClosed) {
            lineTextClosed += ": " + Resource.common.datetime_closed;
            num = 1;
        }
        lines.push(lineTextClosed);
        textAreaHeight = ((num + businessMatrix.length) * 22 + 20).toString() + "px";
        return lines.join("\r\n");
    };


    static convertBusinessHour = (businessHours) => {
        let kq = []
        businessHours?.forEach(item => {
            if (item?.type == TypeBusinessHourEnum.openAllDay) {
                let timeOpenAllDay = {
                    open: {
                        day: item.dayOfWeek,
                        time: "0000"
                    }
                }
                kq.push(timeOpenAllDay)
            }
            else if (item?.type == TypeBusinessHourEnum.opened) {
                let texts = item?.text?.split(" - ")
                let textOpen = texts[0].replace(":", "")
                let textClose = texts[1].replace(":", "")
                if ((item.close.hour < item.open.hour) || (item.close.hour == item.open.hour && item.close.minute < item.open.minute)) {
                    let next = item.dayOfWeek + 1
                    if (next > DayOfWeekEnum.saturday) {
                        next = DayOfWeekEnum.sunday
                    }
                    let timeOpened = {
                        open: {
                            day: item.dayOfWeek,
                            time: textOpen
                        },
                        close: {
                            day: next,
                            time: textClose
                        }
                    }
                    kq.push(timeOpened)
                }
                else {
                    let time = {
                        open: {
                            day: item.dayOfWeek,
                            time: textOpen
                        },
                        close: {
                            day: item.dayOfWeek,
                            time: textClose
                        }
                    }
                    kq.push(time)
                }

            }
        });
        return kq?.length > 0 ? kq : null
    }

    //cách quả chỉ đúng khi đầu vào thật sự gần nhau hoặc cách nhau một ngày
    //so sánh 2 ngày cách nhau 1 ngày trở lại  trong tuần, trả về true nếu ngày đầu nhỏ hơn ngày sau, chủ nhật=>thứ 7 <=>0-6
    static isSmallerDay = (day1, day2) => {
        if (day1 < 0 || day1 > 6 || day2 < 0 || day2 > 6) {
            throw new Error("dữ liệu đầu vào không phải ngày trong tuần");
        }
        else {
            if (Math.abs(day1 - day2) <= 2) {
                return day1 < day2
            }
            else {
                return day1 > day2
            }
        }
    }
    //so sánh 2 ngày liền kề trong tuần, trả về true nếu ngày đầu lớn hơn ngày sau, chủ nhật=>thứ 7 <=>0-6
    static isBiggerDay = (day1, day2) => {
        if (day1 < 0 || day1 > 6 || day2 < 0 || day2 > 6) {
            throw new Error("dữ liệu đầu vào không phải ngày trong tuần");
        }
        else {
            if (Math.abs(day1 - day2) <= 2) {
                return day1 > day2
            }
            else {
                return day1 < day2
            }
        }
    }

    //so sánh 2 ngày liền kề trong tuần, trả về true nếu ngày đầu = ngày sau, chủ nhật-T7 <=> 0-6
    static isEqualDay = (day1, day2) => {
        if (day1 < 0 || day1 > 6 || day2 < 0 || day2 > 6) {
            throw new Error("dữ liệu đầu vào không phải ngày trong tuần");
        }
        else {
            return day1 == day2
        }
    }

    //so sánh đối tượng thời gian bình thường trong tuần {date, time} (loại trừ đóng cửa/ mở cửa cả ngày) đúng nếu time1<timer2
    static isSmallerTimer = (timer1, timer2) => {
        if (!timer1?.time || !timer2.time) {
            throw new Error("dữ liệu đầu vào không phải thời gian làm liệc bình thường trong tuần");
        }
        else {
            if (BusinessHourTool.isSmallerDay(timer1.day, timer2.day)) {
                return true
            }
            else if (BusinessHourTool.isEqualDay(timer1.day, timer2.day)) {
                return timer1.time < timer2.time
            }
            else {
                return false
            }
        }
    }

    //so sánh đối tượng thời gian bình thường trong tuần {date, time} (loại trừ đóng cửa/ mở cửa cả ngày) đúng nếu time1>timer2
    static isBiggerTimer = (timer1, timer2) => {
        if (!timer1?.time || !timer2.time) {
            throw new Error("dữ liệu đầu vào không phải thời gian làm liệc bình thường trong tuần");
        }
        else {
            if (BusinessHourTool.isBiggerDay(timer1.day, timer2.day)) {
                return true
            }
            else if (BusinessHourTool.isEqualDay(timer1.day, timer2.day)) {
                return timer1.time > timer2.time
            }
            else {
                return false
            }
        }
    }


    //so sánh đối tượng thời gian bình thường trong tuần {date, time} (loại trừ đóng cửa/ mở cửa cả ngày) đúng nếu time1>timer2
    static isEqualTimer = (timer1, timer2) => {
        if (!timer1?.time || !timer2.time) {
            throw new Error("dữ liệu đầu vào không phải thời gian làm liệc bình thường trong tuần");
        }
        else {
            return (BusinessHourTool.isEqualDay(timer1.day, timer2.day) && (timer1.time == timer2.time))
        }
    }

    //so sánh đối tượng thời gian bình thường trong tuần {date, time} (loại trừ đóng cửa/ mở cửa cả ngày) đúng nếu time1>timer2
    static isEqualTime = (time1, time2) => {
        return BusinessHourTool.isEqualTimer(time1.open, time2.open) && BusinessHourTool.isEqualTimer(time1.close, time2.close)
    }

    //kiểm tra xem có thể merge 2 đổi tượng khoảng thời gian làm việc trong một ngày lại ko
    static canMergeTimeIn1Day = (time1, time2) => {
        if (!time1.open?.time || !time1?.close.time || !time2.open?.time || !time2?.close.time) {
            throw new Error("dữ liệu đầu vào không phải khoảng thời gian làm việc bình thuòng");
        }
        else return (!BusinessHourTool.isBiggerTimer(time2.open, time1.close) && !BusinessHourTool.isSmallerTimer(time2.close, time1.open))
    }

    static minTimer(timer1, timer2) {
        return (BusinessHourTool.isSmallerTimer(timer1, timer2) ? timer1 : timer2)
    }

    static maxTimer(timer1, timer2) {
        return (BusinessHourTool.isBiggerTimer(timer1, timer2) ? timer1 : timer2)
    }

    static mergeTimeIn1Day(time1, time2) {
        return {
            open: BusinessHourTool.minTimer(time1.open, time2.open),
            close: BusinessHourTool.maxTimer(time1.close, time2.close)
        }
    }

    //merge các giờ làm việc trong cùng một ngày
    static mergeBusinessHoursOfDay = (arr) => {
        arr?.sort((a, b) => {
            if (BusinessHourTool.isSmallerTimer(a.open, b.open)) {
                return -1
            }
            else return 1
        })
        let newArr = []
        arr?.forEach((timeC, indexC) => {
            if (!timeC.merged) {
                let mergeByTimeC = { ...timeC }
                let i = indexC + 1
                let stop = false
                while (i < arr?.length && !stop) {
                    let next = arr[i]
                    if (!next?.merged) {
                        if (BusinessHourTool.canMergeTimeIn1Day(mergeByTimeC, next)) {
                            mergeByTimeC = BusinessHourTool.maxTimer(merge, next)
                            next.merged = true
                        }
                        else {
                            stop = true
                        }
                    }
                    i = i + 1
                }
                newArr.push(mergeByTimeC)
            }
        })
        return newArr
    }

    //kiểm tra nếu có phải là 2 ngày liền kề trong tuần
    static isAdjacent2Day = (day1, day2) => {
        if (day1 == 0 && day2 == 6) {
            return true
        }
        else if (day1 == 6 && day2 == 0) {
            return true
        }
        else return (Math.abs(day1 - day2) == 1)
    }

    //kiểm tra có thể merge time của ngày thứ nhất với time của ngày thứ 2 không
    static canMergeTimerOf2AdjacentDay = (time1, time2) => {
        return !BusinessHourTool.isSmallerTimer(time1.close, time2.open)
    }

    //nối 2 thời gian làm viêc của 2 ngày liền kề mà có thể nối với nhau
    static mergeTimeOf2AdjacentDay = (time1, time2) => {
        return {
            open: time1.open,
            close: time2.close
        }
    }

    static nextDay = (day) => {
        let next = day + 1
        if (next == 7) {
            next = 0
        }
        if (next < 0 || next > 6) {
            throw new Error("đầu vào không đúng 1 ngày trong tuần")
        }
        else {
            return next
        }
    }

    static compactBusinessHours = (businessHours) => {
        // danh sách những giờ làm việc đóng cửa
        let closeBusinessHours = businessHours?.filter(time => {
            return !time?.open?.time
        })

        // danh sách những giờ làm việc không đóng cửa
        let notCloseBusinessHours = businessHours?.filter(time => {
            return time?.open?.time
        }) || []
        let compactNotCloseBusinessHours = BusinessHourTool.compactNotCloseBusinessHours(notCloseBusinessHours)
        let closes = closeBusinessHours.filter(c => {
            return !compactNotCloseBusinessHours.some(t => t?.open?.day == c?.open?.day)
        })
        return compactNotCloseBusinessHours.concat(closes)
    }

    static tryMerge2Time = (time1, time2) => {
        let canMerge = false
        let isAdjacentOrEqual = BusinessHourTool.isAdjacent2Day(time1?.open?.day, time2?.open?.day) || (time1?.open?.day == time2?.open?.day)
        let newTimes = []
        if (isAdjacentOrEqual) {
            let leftTime = time1
            let rightTime = time2
            if (BusinessHourTool.isSmallerTimer(time2?.open, time1?.open)) {
                leftTime = time2
                rightTime = time1
            }
            if (!BusinessHourTool.isSmallerTimer(leftTime?.close, rightTime?.open)) {
                canMerge = true
                let merged = {
                    open: leftTime?.open,
                    close: BusinessHourTool.maxTimer(leftTime?.close, rightTime?.close)
                }
                let distance = Math.abs(merged?.open?.day - merged?.close?.day)
                if (distance == 0) {
                    newTimes = [merged]
                }
                else if (distance == 1 || distance == 6) {
                    if (merged?.open?.time < merged?.close?.time || merged?.close?.time >= "1200") {
                        let first = {
                            open: merged?.open,
                            close: {
                                day: merged?.close?.day,
                                time: "0000"
                            }
                        }
                        let second = {
                            open: {
                                day: merged?.close?.day,
                                time: "0000"
                            },
                            close: merged?.close
                        }
                        if (BusinessHourTool.isEqualTime(leftTime, first) && BusinessHourTool.isEqualTime(rightTime, second)) {
                            canMerge = false
                        }
                        else {
                            newTimes = [first, second]
                        }
                    }
                    else {
                        newTimes = [merged]
                    }
                }
                else {
                    let middleDay = BusinessHourTool.nextDay(merged?.open?.day)
                    newTimes = [
                        {
                            open: merged?.open,
                            close: {
                                day: middleDay,
                                time: "0000"
                            }
                        },
                        {
                            open: {
                                day: middleDay,
                                time: "0000"
                            },
                            close: {
                                day: merged?.close?.day,
                                time: "0000"
                            }
                        }
                    ]
                    if (merged?.close?.time != "0000") {
                        newTimes.push({
                            open: {
                                day: merged?.close?.day,
                                time: "0000"
                            },
                            close: merged?.close
                        })
                    }
                    else {
                        if (BusinessHourTool.isEqualTime(leftTime, newTimes[0]) && BusinessHourTool.isEqualTime(rightTime, newTimes[1])) {
                            canMerge = false
                            newTimes = [[]]
                        }
                    }
                }
            }
        }
        return {
            canMerge,
            newTimes
        }
    }
    static compactNotCloseBusinessHours = (businessHours = []) => {
        if (businessHours?.length <= 1) {
            return businessHours
        }
        else {
            let first = businessHours[0]
            let tryMerge = {
            }
            let nextIndex = 0
            while ((nextIndex < (businessHours.length - 1)) && !tryMerge.canMerge) {
                nextIndex = nextIndex + 1
                tryMerge = BusinessHourTool.tryMerge2Time(first, businessHours[nextIndex])
            }
            if (tryMerge.canMerge) {
                let newArr = businessHours.filter((t, i) => (i != 0 && i != nextIndex))
                newArr = tryMerge.newTimes.concat(newArr)
                return BusinessHourTool.compactNotCloseBusinessHours(newArr)
            }
            else {
                return [first].concat(BusinessHourTool.compactNotCloseBusinessHours(businessHours.slice(1)))
            }
        }
    }
    //đổi day 1,2,3,4,5,6,0 => 2->8
    static convertDay = (day) => {
        if (day > 0) {
            return day + 1
        }
        else return 8
    }

    // đổi day ngược lại với convertDay
    static revertDay = (day) => {
        if (day < 8) {
            return day - 1
        }
        else return 0
    }

    static generateToText2 = (businessHours = []) => {
        let result = {}
        const days = [0, 1, 2, 3, 4, 5, 6]
        days.forEach(day => {
            let arr = businessHours.filter(time => {
                return time?.open?.day == day
            }) || []
            arr.sort((a, b) => {
                if (BusinessHourTool.isSmallerTimer(a?.open, b?.open)) {
                    return -1
                }
                else if (BusinessHourTool.isEqualTimer(a?.open, b?.open)) {
                    return 0
                }
                else {
                    return 1
                }
            })

            let t = {
                type: TypeBusinessHourEnum.none,
                text: "",
                texts: [],
            }

            if (arr?.length == 0) {
                t.type == TypeBusinessHourEnum.none
                t.text = ""
            }
            else {
                t.type = TypeBusinessHourEnum.opened
                let texts = []

                arr.forEach(time => {
                    let text = ""
                    let timeO = time?.open?.time
                    let timeC = time?.close?.time
                    if (!timeO) {
                        t.type = TypeBusinessHourEnum.closed
                    }
                    else if (timeO == "0000" && timeC == "0000") {
                        t.type = TypeBusinessHourEnum.openAllDay
                    }
                    else {

                        text = `${timeO?.slice(0, 2)}:${timeO?.slice(2)} - ${timeC?.slice(0, 2)}:${timeC?.slice(2)}`
                    }
                    texts.push(text)
                })
                t.text = texts.join(", ")
                t.texts = texts
            }
            result[BusinessHourTool.convertDay(day)] = t
        })
        return result
    }

    static generateBusinessHourObject = (businessHours) => {
        let arrBusinessHours = []
        businessHours?.map((businessHour, index) => {
            if (businessHour?.open?.time) {
                let hourOpenStr = businessHour?.open?.time?.slice(0, 2)
                let minuteOpenStr = businessHour?.open?.time?.slice(2, 4)
                let hourCloseStr = ""
                let minuteCloseStr = ""
                let timeObj = {
                    dayOfWeek: businessHour?.open?.day,
                    open: {
                        hour: parseInt(hourOpenStr),
                        minute: parseInt(minuteOpenStr),
                        time: businessHour?.open?.time
                    },
                }
                if (businessHour?.close?.time) {
                    hourCloseStr = businessHour?.close?.time.slice(0, 2)
                    minuteCloseStr = businessHour?.close?.time.slice(2, 4)
                    timeObj.close = {
                        dayOfWeek: businessHour?.close?.day,
                        hour: parseInt(hourCloseStr),
                        minute: parseInt(minuteCloseStr),
                        time: businessHour?.close?.time
                    }
                }
                if (timeObj.open.hour == 0 && timeObj.open.minute == 0 && !timeObj.close) {
                    timeObj.type = TypeBusinessHourEnum.openAllDay
                }
                else {
                    timeObj.type = TypeBusinessHourEnum.opened
                    timeObj.text = `${hourOpenStr}:${minuteOpenStr} - ${hourCloseStr}:${minuteCloseStr}`
                }
                arrBusinessHours.push(timeObj)
            }

        })

        for (let i = 0; i < 7; i++) {
            let isNodata = !businessHours?.some((item) => { return (item?.open?.day == i || item?.close?.day == i) })
            let isClose = businessHours?.some((item) => { return (item?.open?.day == i && !item?.open?.time) || (!item?.open && item?.close?.time == "0000" && item?.close?.day == i) })

            if (isClose) {
                let obj = {
                    dayOfWeek: i,
                    type: TypeBusinessHourEnum.closed
                }
                arrBusinessHours.push(obj)
            }
            else if (isNodata) {
                let obj = {
                    dayOfWeek: i,
                }
                arrBusinessHours.push(obj)
            }

        }
        return arrBusinessHours.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
    }

    static mergeDataBusinessHour = (data) => {
        let result = {}
        if (data?.length > 0) {
            data?.forEach((dataItem, index) => {
                let arr = result[dataItem?.dayOfWeek] || []
                arr.push(dataItem)
                result[dataItem?.dayOfWeek] = arr
            })
        }

        Object?.values(result)?.forEach((buss, index) => {
            buss?.sort((a, b) => {
                return (a?.open?.time - b?.open?.time)
            })
        })

        return result
    }

    static findOpening = (businessHours) => {
        let arrBusinessHours = BusinessHourTool.generateBusinessHourObject(businessHours)

        let currentDay = new Date()
        let currentDayOfWeek = currentDay.getDay()
        let yesterdayOfWeek = currentDay == 0 ? 6 : currentDay - 1

        let yesterday = arrBusinessHours[yesterdayOfWeek]
        let today = arrBusinessHours[currentDayOfWeek]

        //check yesterday
        if (yesterday?.type == TypeBusinessHourEnum.opened && yesterday?.close?.dayOfWeek == currentDayOfWeek) {
            let close = new Date()
            close.setHours(yesterday?.close?.hour)
            close.setMinutes(yesterday?.close?.minute)
            if (close >= currentDay) {
                return yesterday
            }
        }
        //check today
        if (today?.type == TypeBusinessHourEnum.openAllDay) {
            return today
        }
        if (today?.type == TypeBusinessHourEnum.opened) {
            let open = new Date()
            open.setHours(today?.open?.hour)
            open.setMinutes(today?.open?.minute)

            let close = new Date()
            close.setHours(today?.close?.hour)
            close.setMinutes(today?.close?.minute)

            if (open <= currentDay && currentDay <= close) {
                return today
            }
        }
        return {
            type: TypeBusinessHourEnum.closed
        }
    }

    static findCurrentOpening = (bussinessHours) => {
        let arrBusinessHours = BusinessHourTool.generateBusinessHourObject(bussinessHours)

        let date = new Date()
        let currentDay = date.getDay()
        let currentHour = date?.getHours()?.toString()
        let currentMinute = date?.getMinutes()?.toString()

        let textHour = currentHour?.length < 2 ? `0${currentHour}` : currentHour
        let textMinute = currentMinute?.length < 2 ? `0${currentMinute}` : currentMinute
        let currentTime = `${textHour}${textMinute}`

        let currentTimer = {
            day: currentDay,
            time: currentTime
        }

        let isOpenAllDay = bussinessHours.some(time => {
            if (time?.open?.time) {
                return (
                    BusinessHourTool.isEqualTimer({ day: currentDay, time: "0000" }, time?.open) &&
                    (!time?.close || BusinessHourTool.isEqualTimer({ day: BusinessHourTool.nextDay(currentDay), time: "0000" }, time?.close))
                )
            }
        })

        if (isOpenAllDay) {
            return arrBusinessHours[currentDay]
        }

        else {
            let arr = bussinessHours?.filter((buss, index) => {
                return buss?.open?.day == currentDay || BusinessHourTool.nextDay(buss?.open?.day) == currentDay
            })

            let find = arr?.find(time => {
                if (time?.open?.time && time?.close?.time) {
                    return (
                        (!BusinessHourTool.isSmallerTimer(currentTimer, time?.open) && !BusinessHourTool.isBiggerTimer(currentTimer, time?.close))
                    )
                }
            })

            if (find) {
                return {
                    ...find,
                    type: TypeBusinessHourEnum.opened
                }
            }

            else {
                let hasCurrentInfo = bussinessHours?.find((buss, index) => {
                    return buss?.open?.day == currentDay
                })
                if (hasCurrentInfo) {
                    return {
                        type: TypeBusinessHourEnum.closed
                    }
                }
                else {
                    return {
                        type: TypeBusinessHourEnum.none
                    }
                }

            }
        }
    }
    static findNextOpen = (currentTimer, businessHours) => {
        let arr = businessHours?.filter(time => (time?.open?.time)) || []
        if (arr?.length > 0) {
            let day = currentTimer?.day
            let result = null
            let count = 1
            while (count <= 7 && !result) {
                // tìm những time của ngày này
                let futureArr = arr?.filter(time => time?.open?.day == day) || []

                if (day == currentTimer.day) {
                    //nếu ngày bằng ngày hiện tại thì chỉ lấy nhưng time lơn hơn
                    futureArr = futureArr?.filter(time => BusinessHourTool.isBiggerTimer(time?.open, currentTimer)) || []
                }

                // sort theo thứ tự lớn dần
                futureArr?.sort((a, b) => {
                    if (BusinessHourTool.isSmallerTimer(a?.open, b?.open)) {
                        return -1
                    }
                    else if (BusinessHourTool.isEqualTimer(a?.open, b?.open)) {
                        return 0
                    }
                    else {
                        return 1
                    }
                })
                result = futureArr[0]
                count = count + 1
            }
            return result
        }
        else {
            return null
        }
    }
    static getCurrentDate = () => {
        let current = new Date();
        let hours = current.getHours()
        let minutes = current.getMinutes()
        let time = ("0" + (hours * 100 + minutes)).slice(-4)
        return {
            day: current.getDay(),
            hours: hours,
            minutes: minutes,
            time: time
        };
    }
}
