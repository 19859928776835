import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "./popover.scss";

const anchorEnum = {
  top: "top",
  bottom: "bottom",
  left: "left",
  right: "right",
};

const MARGIN = 6;
const Popover = ({ open, onClose, actionButton, children, onOpen }) => {
  const [openState, setOpenState] = useState(false);
  const [closeAnimation, setCloseAnimation] = useState(false);
  const [anchorX, setAnchorX] = useState(anchorEnum.left);
  const [anchorY, setAnchorY] = useState(anchorEnum.bottom);
  const [distanceX, setDistanceX] = useState(0);
  const [distanceY, setDistanceY] = useState(0);
  const actionRef = useRef();
  const popupRef = useRef();

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      const { width, height, x, y } =
        actionRef?.current?.getBoundingClientRect() || {};
      const centerWindow = { x: innerWidth / 2, y: innerHeight / 2 };
      const centerAction = { x: x + width / 2, y: y + height / 2 };

      const isBottom = centerAction.y > centerWindow.y;
      const isRight = centerAction.x > centerWindow.x;

      let anchorXNew = anchorEnum.left;
      let anchorYNew = anchorEnum.top;
      let distanceXNew = x;
      let distanceYNew = y + height;

      if (isBottom) {
        anchorYNew = anchorEnum.bottom;
        distanceYNew = innerHeight - y;
      }
      if (isRight) {
        anchorXNew = anchorEnum.right;
        distanceXNew = innerWidth - x - width;
      }

      setAnchorX(anchorXNew);
      setAnchorY(anchorYNew);
      setDistanceX(distanceXNew);
      setDistanceY(distanceYNew);
    };

    let observer;

    if (openState) {
      handleResize();
      observer = new MutationObserver(handleResize);
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      window.addEventListener("resize", handleResize);
      document.addEventListener("scroll", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("scroll", handleResize);
      observer?.disconnect();
    };
  }, [openState]);

  const handleOpen = () => {
    if (openState) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }
  };

  useEffect(() => {
    const clickOut = (event) => {
      var isClickButton = actionRef?.current?.contains(event.target);
      var isClickPopup = popupRef?.current?.contains(event.target);
      if (!isClickPopup && !isClickButton) {
        onClose && onClose();
      }
    };
    window.addEventListener("mousedown", clickOut);
    return () => window.removeEventListener("mousedown", clickOut);
  }, []);

  const handleAnimationEnd = (e) => {
    if (e.animationName === "closePopup") {
      setCloseAnimation(false);
    }
  };

  useEffect(() => {
    openState && setCloseAnimation(true);
  }, [openState]);

  return (
    <div className="popoverMap4d">
      <div ref={actionRef} className="actionButtonWrap" onClick={handleOpen}>
        {actionButton}
      </div>
      {(openState || closeAnimation) && (
        ReactDOM.createPortal(<div
          onAnimationEnd={handleAnimationEnd}
          className={openState ? "popup" : "closePopup"}
          ref={popupRef}
          style={{
            [anchorX]: distanceX,
            [anchorY]: distanceY + MARGIN,
            transformOrigin: `${anchorX} ${anchorY}`,
            maxHeight: `calc(100% - ${distanceY + 2 * MARGIN}px)`,
            maxWidth: `calc(100% - ${distanceX}px)`,
          }}
        >
          {children}
        </div>, document.body)
      )}
    </div>
  );
};

Popover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actionButton: PropTypes.any,
  onOpen: PropTypes.func,
};

export default Popover;
