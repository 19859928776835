import React, { useEffect, useRef } from 'react';
import { MFMap } from 'react-map4d-map';
import { MapEventEnum } from './../../enum/mapEventEnum';
import "./streetInputV2.scss";

function getProjection(line1, line2, pt) {
  let isValid = false;

  let r = {};
  if (line1.lat == line2.lat && line1.lng == line2.lng) line1.lat -= 0.00001;

  let U = ((pt.lat - line1.lat) * (line2.lat - line1.lat)) + ((pt.lng - line1.lng) * (line2.lng - line1.lng));

  let Udenom = Math.pow(line2.lat - line1.lat, 2) + Math.pow(line2.lng - line1.lng, 2);

  U /= Udenom;

  r.lat = line1.lat + (U * (line2.lat - line1.lat));
  r.lng = line1.lng + (U * (line2.lng - line1.lng));

  let minx, maxx, miny, maxy;

  minx = Math.min(line1.lat, line2.lat);
  maxx = Math.max(line1.lat, line2.lat);

  miny = Math.min(line1.lng, line2.lng);
  maxy = Math.max(line1.lng, line2.lng);

  isValid = (r.lat >= minx && r.lat <= maxx) && (r.lng >= miny && r.lng <= maxy);

  return isValid ? r : null;
}

const mapOptions = {
  center: [108.2171547, 16.0561334],
  zoom: 18,
  mapType: "satellite"
}

const TypeGeoJsonEnum = {
  way: "way",
  node: "node",
  lineOfWay: "lineOfWay",
  virtualNode: "virtualNode",
  location: "location",
  firstNodeOfNewWay: "firstNodeOfNewWay"
}

const StepTypeEnum = {
  dragNode: "dragNode",
  addNode: "addNode",
  removeNode: "removeNode"
}

const StatusEnum = {
  none: "none",
  addFirstNodeOfNewWay: "addFirstPointOfNewWay",
  addSecondNodeOfNewWay: "addSecondNodeOfNewWay",
  addNewNodeToNewWay: "addNewNodeToNewWay",
}


const NODE_RADIUS = 0.3

let stepModel = {
  nodeId: "",
  fromPosition: null,
  toPosition: null,
  type: StepTypeEnum.dragNode
}

const StreetInputV2 = () => {
  const idNewRef = useRef(0)
  const dialogHtml = useRef()
  const nodeDictRef = useRef({})
  const wayDictRef = useRef({})
  const mapRef = useRef()
  const virtualNodeRef = useRef({})
  const oldNodeDictRef = useRef({})
  const statusRef = useRef(StatusEnum.none)
  const oldWayDictRef = useRef({})
  const firstNodeOfNewWayRef = useRef({})
  const hightlightRef = useRef()
  const stepsRef = useRef([])

  const createId = () => {
    idNewRef.current = idNewRef.current - 1
    return idNewRef.current
  }

  const makeStep = {
    [StepTypeEnum.dragNode]: (step = stepModel) => {
      let { nodeId, toPosition } = step

      let node = nodeDictRef.current[nodeId]
      saveOldNode(node)

      let position = toPosition
      node.lat = position.lat
      node.lon = position.lng

      let ways = node.ways
      ways.forEach((wayId) => {
        let way = wayDictRef.current[wayId]
        saveOldWay(way)
        createOldWayToMap(way)

        let line = way.lineDict[id]
        let backLine = way.lineDict[line.backLineId]

        if (line.line) {
          let path = line.line.getPath()
          path[0] = position
          line.line.setPath(path)
        }

        if (backLine?.line) {
          let path = backLine.line.getPath()
          path[1] = position
          backLine.line.setPath(path)
        }
      })
    }
  }

  const getDistanceScreen = (a, b) => {
    let projection = new map4d.Projection(mapRef.current)
    let px1 = projection.fromLatLngToScreen(a)
    let px2 = projection.fromLatLngToScreen(b)
    return Math.sqrt(Math.pow((px1.x - px2.x), 2) + Math.pow((px1.y - px2.y), 2))
  }

  const getDistance = (a, b) => {
    let measure = new map4d.Measure([a, b])
    return measure.length
  }

  const createNodeToMap = (node) => {
    let position = {
      lat: node.lat,
      lng: node.lon
    }
    node.node = new map4d.Circle({
      center: position,
      draggable: true,
      radius: NODE_RADIUS,
      zIndex: 3,
      strokeColor: "#000000",
      fillColor: "#ffffff",
      strokeWidth: 2
    })
    node.node.setMap(mapRef.current)
    node.node.setUserData({ id: node.id, type: TypeGeoJsonEnum.node })
  }

  const saveOldWay = (way) => {
    if (!way?.isEdited) {
      way.isEdited = true
      let oldWay = { ...way }
      delete oldWay.lineDict
      oldWay = JSON.parse(JSON.stringify(oldWay))
      oldWayDictRef.current[oldWay.id] = oldWay
    }
  }

  const createOldWayToMap = (way) => {
    let oldWay = oldWayDictRef.current[way.id]
    if (!oldWay?.lineDict && !way.isNew) {
      oldWay.lineDict = {
      }
      let nodes = oldWay.nodes
      nodes.forEach((nodeId, i) => {
        let line = {
          id: nodeId
        }
        if (i < (nodes.length - 1)) {
          let position1 = nodeDictRef.current[nodeId].node.getCenter()
          let position2 = nodeDictRef.current[nodes[i + 1]].node.getCenter()
          line.line = new map4d.Polyline({
            path: [position1, position2],
            strokeWidth: 10,
            strokeColor: "#ffffff",
            zIndex: 1,
            userInteractionEnabled: false,
            strokePattern: new map4d.DotPattern()
          })
          line.nextLineId = nodes[i + 1]
          line.line.setUserData({ id: line.id, type: TypeGeoJsonEnum.lineOfWay, wayId: way.id })
          line.line.setMap(mapRef.current)
        }
        if (i > 0) {
          line.backLineId = nodes[i - 1]
        }
        oldWay.lineDict[line.id] = line
      })
    }

  }

  const createWayToMap = (way) => {
    way.lineDict = {
    }
    let nodes = way.nodes
    let isClosed = (nodes[0] == nodes?.[nodes?.length - 1]) && nodes.length > 2
    nodes.forEach((nodeId, i) => {
      if ((i == (nodes.length - 1)) && isClosed) {
        let line1 = way.lineDict[nodes[0]]
        let line2 = way.lineDict[nodes[nodes.length - 2]]
        line.backLineId = line2.id
        line2.nextLineId = line1.id
      }
      else {
        let line = {
          id: nodeId,
          wayId: way.id
        }
        if (i < (nodes.length - 1)) {
          let position1 = nodeDictRef.current[nodeId].node.getCenter()
          let position2 = nodeDictRef.current[nodes[i + 1]].node.getCenter()
          line.line = new map4d.Polyline({
            path: [position1, position2],
            strokeWidth: 10,
            strokeColor: "#ffffff",
            zIndex: 2
          })
          line.nextLineId = nodes[i + 1]
          line.line.setUserData({ id: line.id, type: TypeGeoJsonEnum.lineOfWay, wayId: way.id })
          line.line.setMap(mapRef.current)
        }
        if (i > 0) {
          line.backLineId = nodes[i - 1]
        }
        way.lineDict[line.id] = line
      }

    })
  }

  const saveOldNode = (node) => {
    if (!node?.isEdited) {
      node.isEdited = true
      let oldNode = { ...node }
      oldNode.node = null
      oldNode = JSON.parse(JSON.stringify(oldNode))
      oldNodeDictRef.current[oldNode.id] = oldNode
    }
  }

  //show hightlight cho node thứ 2 khi thêm mới đường
  const showHightlighForAddSecondNodeOfNewWay = (data) => {
    let { position } = data || {}
    hightlightRef.current.setPath([firstNodeOfNewWayRef.current.nodeData.position, position])
    hightlightRef.current.setVisible(true)
  }

  const updateDrawLineForNode = (nodeId) => {
    let node = nodeDictRef.current[nodeId]
    let position = node.node?.getCenter()
    let ways = node.ways
    ways.forEach((wayId) => {
      let way = wayDictRef.current[wayId]

      let line = way.lineDict[node.id]
      let backLine = way.lineDict[line.backLineId]

      if (line.line) {
        let path = line.line.getPath()
        path[0] = position
        line.line.setPath(path)
      }

      if (backLine?.line) {
        let path = backLine.line.getPath()
        path[1] = position
        backLine.line.setPath(path)
      }
    })
  }

  // kéo node
  const dragNode = (data) => {
    let { id } = data || {}

    if (!id) {
      id = virtualNodeRef.current.nodeData.id
    }

    let node = nodeDictRef.current[id]
    // saveOldNode(node)
    let position = node.node?.getCenter()
    if (!position) {
      position = virtualNodeRef.current.node.getCenter()
    }
    let ways = node.ways
    ways.forEach((wayId) => {
      let way = wayDictRef.current[wayId]
      // saveOldWay(way)
      // createOldWayToMap(way)

      let line = way.lineDict[id]
      let backLine = way.lineDict[line.backLineId]

      if (line.line) {
        let path = line.line.getPath()
        path[0] = position
        line.line.setPath(path)
      }

      if (backLine?.line) {
        let path = backLine.line.getPath()
        path[1] = position
        backLine.line.setPath(path)
      }
    })

  }

  //tách line từ điểm ảo 
  const splitLineFromVirtualNode = (node) => {
    let { lineId, wayId } = node.nodeData || {}

    let position = node.node?.getCenter()
    let way = wayDictRef.current[wayId]
    let line = way.lineDict[lineId]

    saveOldWay(way)
    createOldWayToMap(way)

    //create new node
    let nodeId = createId()
    let newNode = {
      id: nodeId,
      lat: position.lat,
      lon: position.lng,
      ways: [wayId],
      isEdited: true,
      isNew: true
    }
    nodeDictRef.current[newNode.id] = newNode

    //update nextLine
    let nextLine = way.lineDict[line.nextLineId]
    nextLine.backLineId = newNode.id

    //create new line
    let newLine = {
      id: newNode.id,
      backLineId: line.id,
      nextLineId: nextLine.id,
      wayId: way.id,
      line: new map4d.Polyline({
        path: [position, nodeDictRef.current[nextLine.id].node.getCenter()],
        strokeWidth: 10,
        strokeColor: "#ffffff",
        zIndex: 2
      })
    }
    newLine.line.setUserData({ id: newLine.id, type: TypeGeoJsonEnum.lineOfWay, wayId: way.id })
    newLine.line.setMap(mapRef.current)
    way.lineDict[newLine.id] = newLine

    //update line
    line.nextLineId = newLine.id
    let path = line.line.getPath()
    path[1] = position
    line.line.setPath(path)

    return newNode
  }

  const createFirstNodeOfNewWay = () => {
    let { id, lineId, position, wayId } = firstNodeOfNewWayRef.current.nodeData || {}
    let node
    if (id) {
      node = nodeDictRef.current[id]
    }
    else {
      if (lineId && wayId) {
        node = splitLineFromVirtualNode(firstNodeOfNewWayRef.current)
      }
      else {
        node = {
          id: createId(),
          lat: firstNodeOfNewWayRef.current.nodeData.position.lat,
          lon: firstNodeOfNewWayRef.current.nodeData.position.lng,
          ways: [],
          isEdited: true,
          isNew: true
        }
        nodeDictRef.current[node.id] = node
      }
      createNodeToMap(node)
      node.node.setZIndex(4)
    }
    return node
  }

  //kiểm tra 2 node thật bất kỳ có chung một line ko
  const check2NodeInOneLine = (id1, id2) => {
    if (id1 == id2 && id1 && id2) {
      return true
    }
    else {
      let node1 = nodeDictRef.current[id1]
      let node2 = nodeDictRef.current[id2]
      let wayId = node1.ways.find(wayId1 => {
        return node2.ways.includes(wayId1)
      })
      if (!wayId) {
        return false
      }
      else {
        let way = wayDictRef.current[wayId]
        let index1 = way.nodes.findIndex(id => id == id1)
        let index2 = way.nodes.findIndex(id => id == id2)
        return (Math.abs(index1 - index2) == 1)
      }
    }
  }
  //kiểm tra 1 node thật và một node ảo có nằm trên 1 line ko
  const checkNodeInline = (id1, lineId2, wayId2) => {
    let way2 = wayDictRef.current[wayId2]
    let indexOfNode1 = way2.nodes.findIndex(id => id == id1)
    if (id1 == lineId2) {
      return true
    }
    else if (indexOfNode1 == -1) {
      return false
    }
    else {
      let indexOfNode2 = way2.nodes.findIndex(id => id == lineId2)
      return (Math.abs(indexOfNode1 - indexOfNode2) == 1)
    }
  }
  //kiểm tra 2 điểm ảo có chung 1 line ko
  const check2VirtualNodeInLine = (lineId1, wayId1, lineId2, wayId2) => {
    return lineId1 == lineId2 && wayId1 == wayId2
  }

  const checkCanCreateNewWay = (secondNodeData) => {
    let { id, lineId, wayId } = firstNodeOfNewWayRef.current.nodeData || {}
    let id2 = secondNodeData?.id
    let lineId2 = secondNodeData?.lineId
    let wayId2 = secondNodeData?.wayId
    if (id && id2) {
      return !check2NodeInOneLine(id, id2)
    }
    else if (id && lineId2 && wayId2) {
      return !checkNodeInline(id, lineId2, wayId2)
    }
    else if (lineId && wayId && id2) {
      return !checkNodeInline(id2, lineId, wayId)
    }
    else if (lineId && wayId && lineId2 && wayId2) {
      return !check2VirtualNodeInLine(lineId, wayId, lineId2, wayId2)
    }
    else {
      return true
    }
  }

  //Tạo đường mới từ 2 node
  const createNewWayFrom2Node = (node1, node2) => {
    let way = {
      id: createId(),
      nodes: [node1.id, node2.id],
      isEdited: true,
      isNew: true
    }

    node1.ways.push(way.id)
    node2.ways.push(way.id)

    createWayToMap(way)
    wayDictRef.current[way.id] = way

    statusRef.current = StatusEnum.none
    firstNodeOfNewWayRef.current.node.setVisible(false)
    hightlightRef.current.setPath([[0, 0], [0, 0]])
    hightlightRef.current.setMap(null)
  }

  const actionMapEvent = {
    //kéo node thật trên map
    [TypeGeoJsonEnum.node + MapEventEnum.drag + StatusEnum.none]: dragNode,

    [TypeGeoJsonEnum.node + MapEventEnum.dragEnd + StatusEnum.none]: (data) => {
      let { id } = data
      let node = nodeDictRef.current[id]
      let step = {
        type: StepTypeEnum.dragNode,
        nodeId: id,
        fromPosition: { lat: node?.lat, lng: node?.lon },
        toPosition: node?.node?.getCenter()
      }
      makeStep[step.type]
    },

    //kéo node ảo trên map
    [TypeGeoJsonEnum.virtualNode + MapEventEnum.drag + StatusEnum.none]: dragNode,

    //tách một line thành 2 line từ điểm ảo
    [TypeGeoJsonEnum.virtualNode + MapEventEnum.dragStart + StatusEnum.none]: (data) => {
      let newNode = splitLineFromVirtualNode(virtualNodeRef.current)
      virtualNodeRef.current.nodeData = {
        id: newNode.id,
        isDrag: true
      }
    },

    //vẽ node thật lên map sao khi kết thúc kéo node ảo
    [TypeGeoJsonEnum.virtualNode + MapEventEnum.dragEnd + StatusEnum.none]: (data) => {
      let { id } = virtualNodeRef.current.nodeData || {}
      let position = virtualNodeRef.current.node.getCenter()
      let node = nodeDictRef.current[id]
      if (!node.node) {
        node.lat = position.lat
        node.lon = position.lng
        createNodeToMap(node)
        node.node.setZIndex(4)
        virtualNodeRef.current.nodeData = {}
      }
    },

    //show marker ảo lần đầu khi hover vô một line of way
    [TypeGeoJsonEnum.lineOfWay + MapEventEnum.hover]: (data) => {
      let { id, wayId, position } = data || {}
      let way = wayDictRef.current[wayId]
      let line = way.lineDict[id]
      let dataVirtual = virtualNodeRef.current.node.nodeData || {}
      if (id != dataVirtual.lineId || wayId != dataVirtual.wayId) {
        let path = line.line.getPath()
        let centerPoint = getProjection(path[0], path[1], position) || position
        virtualNodeRef.current.node.setCenter(centerPoint)
        virtualNodeRef.current.nodeData = { lineId: id, wayId: wayId }
        virtualNodeRef.current.node.setVisible(true)
      }
    },

    //Xoá một node
    [TypeGeoJsonEnum.node + MapEventEnum.click + StatusEnum.none]: (data) => {
      let { id } = data

      let node = nodeDictRef.current[id]
      if (node?.ways?.length == 1) {

        let way = wayDictRef.current[node.ways[0]]
        let line = way.lineDict[node.id]
        let nextLine = way.lineDict[line.nextLineId]
        let backLine = way.lineDict[line.backLineId]

        if (nextLine && backLine) {
          saveOldWay(way)
          createOldWayToMap(way)

          saveOldNode(node)

          //update back line
          backLine.nextLineId = nextLine.id
          let path = backLine.line.getPath()
          path[1] = nodeDictRef.current[nextLine.id].node.getCenter()
          backLine.line.setPath(path)

          //update next line
          nextLine.backLineId = backLine.id

          //update line
          line.line.setMap(null)
          delete way.lineDict[line.id]

          //remove node
          node.node.setMap(null)
          delete nodeDictRef.current[node.id]

          //update way
          way.nodes = way?.nodes?.filter(nodeId => nodeId != id)
        }

      }
    },

    //Tạo first node of new way từ location
    [TypeGeoJsonEnum.location + MapEventEnum.click + StatusEnum.addFirstNodeOfNewWay]: (data) => {
      let { position } = data || {}
      firstNodeOfNewWayRef.current.nodeData = {
        position: position
      }
      firstNodeOfNewWayRef.current.node.setCenter(position)
      firstNodeOfNewWayRef.current.node.setVisible(true)

      statusRef.current = StatusEnum.addSecondNodeOfNewWay
      hightlightRef.current.setMap(mapRef.current)

    },

    //Tạo first node of new way từ node ảo
    [TypeGeoJsonEnum.virtualNode + MapEventEnum.click + StatusEnum.addFirstNodeOfNewWay]: (data) => {
      let position = virtualNodeRef.current.node.getCenter()
      let { lineId, wayId } = virtualNodeRef.current.nodeData || {}
      firstNodeOfNewWayRef.current.nodeData = {
        position: position,
        lineId: lineId,
        wayId: wayId
      }
      firstNodeOfNewWayRef.current.node.setCenter(position)
      firstNodeOfNewWayRef.current.node.setVisible(true)

      statusRef.current = StatusEnum.addSecondNodeOfNewWay
      hightlightRef.current.setMap(mapRef.current)
    },

    //Tạo first node of new way từ node thật
    [TypeGeoJsonEnum.node + MapEventEnum.click + StatusEnum.addFirstNodeOfNewWay]: (data) => {
      let { id } = data || {}
      let node = nodeDictRef.current[id]
      let position = node.node.getCenter()
      firstNodeOfNewWayRef.current.nodeData = {
        id: id,
        position: position,
        ways: node.ways
      }
      firstNodeOfNewWayRef.current.node.setCenter(position)
      firstNodeOfNewWayRef.current.node.setVisible(true)

      statusRef.current = StatusEnum.addSecondNodeOfNewWay
      hightlightRef.current.setMap(mapRef.current)
    },

    //tạo mới đường nếu nút thứ 2 click vào node ảo
    [TypeGeoJsonEnum.virtualNode + MapEventEnum.click + StatusEnum.addSecondNodeOfNewWay]: (data) => {
      let nodeData2 = virtualNodeRef.current.nodeData || {}
      if (checkCanCreateNewWay(nodeData2)) {//check hơi sai chỗ này, bữa khác check tiếp
        let firstNode = createFirstNodeOfNewWay()
        let secondNode = splitLineFromVirtualNode(virtualNodeRef.current)

        createNodeToMap(secondNode)
        secondNode.node.setZIndex(4)

        createNewWayFrom2Node(firstNode, secondNode)
      }
    },

    //tạo mới đường nếu nút thứ 2 click vào node thật
    [TypeGeoJsonEnum.node + MapEventEnum.click + StatusEnum.addSecondNodeOfNewWay]: (data) => {
      let nodeData2 = data || {}
      if (checkCanCreateNewWay(nodeData2)) {
        let { id } = nodeData2
        let secondNode = nodeDictRef.current[id]
        let firstNode = createFirstNodeOfNewWay()

        createNewWayFrom2Node(firstNode, secondNode)
      }

    }

  }

  const onMapReady = (map) => {

    //tạo một point ảo để xuất hiện khi hover vào line
    virtualNodeRef.current.node = new map4d.Circle({
      center: { lat: 0, lng: 0 },
      visible: false,
      zIndex: 4,
      // userInteractionEnabled: false,
      radius: NODE_RADIUS,
      strokeColor: "#000111",
      fillColor: "#fffeee",
      strokeWidth: 2,
      draggable: true
    })
    virtualNodeRef.current.node.setUserData({ type: TypeGeoJsonEnum.virtualNode })
    virtualNodeRef.current.node.setMap(map)

    //tạo một point ảo tạo mới con đường
    firstNodeOfNewWayRef.current.node = new map4d.Circle({
      center: { lat: 0, lng: 0 },
      visible: false,
      zIndex: 4,
      // userInteractionEnabled: false,
      radius: NODE_RADIUS,
      strokeColor: "#000111",
      fillColor: "#fffeee",
      strokeWidth: 2
    })
    firstNodeOfNewWayRef.current.node.setUserData({ type: TypeGeoJsonEnum.firstNodeOfNewWay })
    firstNodeOfNewWayRef.current.node.setMap(map)

    //tạo hightlight
    hightlightRef.current = new map4d.Polyline({
      path: [{ lat: 0, lng: 0 }, { lat: 1, lng: 1 }],
      strokeWidth: 10,
      strokeColor: "#ffffff",
      zIndex: 2,
      visible: false,
      userInteractionEnabled: false,
      strokePattern: new map4d.DotPattern()
    })
    hightlightRef.current.setMap(map)

    mapRef.current = map
    map.setPOIsEnabled(false)

    fetch("/mapAppRoot/json/map.json").then(res => res.json()).then((data) => {
      (data)
      data?.elements?.filter(e => e?.type == "node")?.forEach(node => {
        if (!nodeDictRef.current[node.id]) {
          node.ways = []
          createNodeToMap(node)
          nodeDictRef.current[node.id] = node
        }
      });
      data?.elements?.filter(e => e?.type == "way")?.forEach(way => {
        if (!wayDictRef.current[way.id]) {
          let nodes = way?.nodes
          nodes.forEach((nodeId, i) => {
            let node = nodeDictRef.current[nodeId]
            node.ways.push(way.id)
          })
          createWayToMap(way)
          wayDictRef.current[way.id] = way
        }
      });
    })

    mapRef.current.addListener(MapEventEnum.drag, (args) => {
      let data = args.circle?.getUserData() || {}
      actionMapEvent[data.type + MapEventEnum.drag + statusRef.current] && actionMapEvent[data.type + MapEventEnum.drag + statusRef.current](data)
    }, { circle: true })

    mapRef.current.addListener(MapEventEnum.dragStart, (args) => {
      let data = args.circle?.getUserData() || {}
      actionMapEvent[data.type + MapEventEnum.dragStart + statusRef.current] && actionMapEvent[data.type + MapEventEnum.dragStart + statusRef.current](data)
    }, { circle: true })

    mapRef.current.addListener(MapEventEnum.dragEnd, (args) => {
      let data = args.circle?.getUserData() || {}
      actionMapEvent[data.type + MapEventEnum.dragEnd + statusRef.current] && actionMapEvent[data.type + MapEventEnum.dragEnd + statusRef.current](data)
    }, { circle: true })

    mapRef.current.addListener(MapEventEnum.hover, (args) => {
      let data = args.polyline.getUserData() || {}
      data.position = args.location
      actionMapEvent[data.type + MapEventEnum.hover] && actionMapEvent[data.type + MapEventEnum.hover](data)
    }, { polyline: true })

    mapRef.current.addListener(MapEventEnum.hover, (args) => {
      if (args.circle !== virtualNodeRef.current.node) {
        virtualNodeRef.current.node.setVisible(false)
      }
    }, { circle: true })

    //xóa node
    mapRef.current.addListener(MapEventEnum.click, (args) => {
      let data = args.circle?.getUserData() || {}
      actionMapEvent[data.type + MapEventEnum.click + statusRef.current] && actionMapEvent[data.type + MapEventEnum.click + statusRef.current](data)
    }, { circle: true })

    mapRef.current.addListener(MapEventEnum.click, (args) => {
      let data = {
        position: args.location
      }
      actionMapEvent[TypeGeoJsonEnum.location + MapEventEnum.click + statusRef.current] && actionMapEvent[TypeGeoJsonEnum.location + MapEventEnum.click + statusRef.current](data)
    }, { location: true })
  }


  useEffect(() => {
    dialogHtml.current?.showModal()
  }, [])

  const onClickAddStreet = () => {
    statusRef.current = StatusEnum.addFirstNodeOfNewWay
  }

  //handle to draw the virtual marker
  const onMouseMove = (e) => {
    let x = e?.nativeEvent?.offsetX
    let y = e?.nativeEvent?.offsetY
    if (mapRef.current) {
      let projection = new map4d.Projection(mapRef.current)
      let position = projection.fromScreenToLatLng({ x, y })
      let { lineId, wayId, isDrag } = virtualNodeRef.current.nodeData || {}
      let check = false
      let way = wayDictRef.current[wayId]
      let line = way?.lineDict[lineId]
      if (line) {
        let path = line.line.getPath()
        let projection = getProjection(path[0], path[1], position)
        if (projection) {
          let distance = getDistance(position, projection)
          if (distance <= NODE_RADIUS && !isDrag) {
            check = true
            virtualNodeRef.current.node.setCenter(projection)
            virtualNodeRef.current.node.setVisible(true)
            if (statusRef.current == StatusEnum.addSecondNodeOfNewWay) {
              showHightlighForAddSecondNodeOfNewWay({ position: projection })
            }
          }
        }
      }
      if (!check && !isDrag) {
        virtualNodeRef.current.node.setVisible(false)
        if (statusRef.current == StatusEnum.addSecondNodeOfNewWay) {
          showHightlighForAddSecondNodeOfNewWay({ position })
        }
      }

    }


  }

  return (
    <dialog ref={dialogHtml} className='editStreet'>
      <div className="toolBar">
        <button onClick={onClickAddStreet}>Thêm đường</button>
      </div>
      <div className='mapView' onMouseMove={onMouseMove}>
        <MFMap
          environment='dev'
          version='2.4'
          accessKey='208e1c99aa440d8bc2847aafa3bc0669'
          options={mapOptions}
          onMapReady={onMapReady}
        >
        </MFMap>
      </div>
    </dialog>
  )
}

export default StreetInputV2;
