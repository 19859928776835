import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import "./rangeInput.scss"

const RangeInput = (props) => {
  const { value, onChange, step = 1, min = 0, max, width } = props
  const _inputRange = useRef()
  const [stateValue, setStateValue] = useState(value || min)
  const [showInfoNumber, setShowInfoNumber] = useState(false)
  const [leftSliderLine, setLeftSliderLine] = useState(false)
  useEffect(() => {
    setStateValue(value)
  }, [value])

  const onChangeInput = (e) => {
    let newValue = e?.target?.value
    setStateValue(newValue)
    onChange && onChange(newValue)
  }

  const handleShowInfoNumber = () => {
    setShowInfoNumber(true)
  }

  const handleHiddenInfoNumber = () => {
    setShowInfoNumber(false)
  }

  let left = (((stateValue - min) / (max - min)) * (_inputRange.current?.offsetWidth - 16)) / 16 || 0;
  
  return (
    <div className='map4dInputRange' style={{ width }}>
      <div className='rangeSlider'>
        <div className='rangeSliderLine' style={{ width: `${(stateValue - min) / ((max - min) / 100)}%` }}></div>
        <div className="boxNow"></div>
        <div className='boxMax'></div>
      </div>
      <div className='rangeThumb' style={{ left: `${left}rem` }} ></div>
      <input
        onMouseLeave={handleHiddenInfoNumber}
        onMouseEnter={handleShowInfoNumber}
        ref={_inputRange}
        className='inputRange'
        {...{ value: stateValue, onChange: onChangeInput, step, min, max }}
        type={"range"}
      />
      {showInfoNumber && <div className='boxNumberInfo' style={{ left: `${left - 1}rem` }}>
        {stateValue}
        <div className='boxDeco'></div>
      </div>}
    </div>
  )
};

RangeInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default RangeInput;
