export class DateTimeTool{
    static getDateWithStartMonth=(date)=>{
        let kq = null
        if(date){
            kq = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
        }
        return kq
        
    }

    static getDateWithStartTime=(date)=>{
        let kq = null
        if(date){
            kq = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        }
        return kq
        
    }

    static getDateWithEndTime=(date)=>{
        let kq = null
        if(date){
            kq = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        }
        return kq
    }


}