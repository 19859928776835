import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AppData } from '../../../data';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum, MapEventEnum } from '../../../enum';
import { ApiTool, UrlTool } from '../../../tool';
import { useHistory, useParams } from 'react-router-dom';
import { RouterConfig } from '../../../config/routerConfig';
import { useQuery } from '../../../useHooks';

function PoiControl() {
  const { text, type } = useQuery()
  const history = useHistory()
  const textRef = useRef(null)
  const typeRef = useRef(null)

  useEffect(() => {
    textRef.current = text
    typeRef.current = type
  }, [text, type])

  useEffect(() => {
    const eventClickMapPoi = AppData.map.addListener(MapEventEnum.click, (args) => {
      let objMapPoi = args?.poi;
      if (textRef.current) {
        history.push({
          pathname: RouterConfig.place.search,
          search: UrlTool.createSearch({ text: textRef.current, type:  typeRef.current, id: objMapPoi?.id}),
          state: {
            disableMoveMap: true
          }
        })
      }
      else {
        history.push({
          pathname: RouterConfig.place.detail.replace(":id", objMapPoi?.id),
          state: {
            disableMoveMap: true
          }
        })
      }
    }, { mappoi: true })

    return () => {
      eventClickMapPoi?.remove()
    }
  }, [])

  useEffect(() => {
    const eventClickPoi = AppData.map.addListener(MapEventEnum.click, (args) => {
      let objPoi = args?.poi.getUserData();
      if (objPoi) {
        if (objPoi?.id) {
          if (textRef.current) {
            history.push({
              pathname: RouterConfig.place.search,
              search: UrlTool.createSearch({ text: textRef.current, type:  typeRef.current, id: objPoi?.id}),
              state: {
                disableMoveMap: true
              }
            })
          }
          else {
            history.push({
              pathname: RouterConfig.place.detail.replace(":id", objPoi?.id),
              state: {
                disableMoveMap: true
              }
            })
          }
        }
        else {
          let data = encodeURIComponent(JSON.stringify(objPoi))
          if (textRef.current) {
            history.push({
              pathname: RouterConfig.place.search,
              search: UrlTool.createSearch({ text: textRef.current,  type: typeRef.current, data: data}),
              state: {
                disableMoveMap: true
              }
            })
          }
          else {
            history.push({
              pathname: RouterConfig.place.detailCustom.replace(":data", data),
              state: {
                disableMoveMap: true
              }
            })
          }
        }
      }
    }, { poi: true })
    return () => {
      eventClickPoi?.remove()
    }
  }, [])
  return (
    null
  )
}

PoiControl.propTypes = {
  //
};

export default PoiControl;
