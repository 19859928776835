import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';
import './timePickerV2.scss';

const ICON_CLOCK =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <g>
      <path d="M10.333,18.667a8.333,8.333,0,1,1,8.333-8.333A8.333,8.333,0,0,1,10.333,18.667Zm0-1.667a6.667,6.667,0,1,0-6.667-6.667A6.667,6.667,0,0,0,10.333,17Zm.833-6.667H14.5V12h-5V6.167h1.667Z" transform="translate(-0.333 -0.333)" fill="#505050" />
    </g>
  </svg>

const ARROW_LEFT =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M0,20H20V0H0Z" fill="none" />
    <path d="M10,12l4,4V8Z" transform="translate(-2 -2)" fill="#505050" />
  </svg>

const ARROW_LEFT_DISABLE =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M0,20H20V0H0Z" fill="none" />
    <path d="M10,12l4,4V8Z" transform="translate(-2 -2)" fill="#9C9C9C" />
  </svg>

const ARROW_RIGHT =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(-1718 -337)">
      <path d="M0,20H20V0H0Z" transform="translate(1718 337)" fill="none" />
      <path d="M14,12l-4,4V8Z" transform="translate(1716 335)" fill="#505050" />
    </g>
  </svg>

const ARROW_RIGHT_DISABLE =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(-1718 -337)">
      <path d="M0,20H20V0H0Z" transform="translate(1718 337)" fill="none" />
      <path d="M14,12l-4,4V8Z" transform="translate(1716 335)" fill="#9C9C9C" />
    </g>
  </svg>

const convertTimeToMinute = (value) => {
  let totalMinute = 0
  if (value) {
    let hour = value?.hour
    let minute = value?.minute
    totalMinute = (hour * 60) + minute
  }
  else {
    let current = new Date()
    let hour = current?.getHours()
    let minute = current?.getMinutes()
    totalMinute = (hour * 60) + minute
  }
  return totalMinute
}

const convertMinuteToObject = (totalMinutes) => {
  let timeObject = { hour: 0, minute: 0 }
  if (totalMinutes != MAX_LENGTH_MINUTE) {
    timeObject.hour = Math.floor(totalMinutes / 60),
      timeObject.minute = totalMinutes % 60
  }
  return timeObject
}

const padToTwoDigits = (num) => {
  return num?.toString().padStart(2, '0')
}

const toHoursAndMinutes = (totalMinutes) => {
  let text = ''
  let hours = Math.floor(totalMinutes / 60)
  let minutes = totalMinutes % 60
  if (totalMinutes == MAX_LENGTH_MINUTE) {
    text = '00:00'
  }
  else {
    text = `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`
  }
  return text
}

const OPTION_HEIGHT = 26
const DISTANCE_DEFAULT = 30
const MAX_LENGTH_MINUTE = 1440
const AnchorEnum = {
  top: "top",
  bottom: "bottom",
}
function TimePickerV2(props) {
  const { value, onChange, distance, maxLengthOfScroll, minTime, maxTime, readOnly } = props

  const optionRef = useRef()
  const timeInputRef = useRef()
  const timePopupRef = useRef()

  const [showTime, setShowTime] = useState(false)
  const [anchorY, setAnchorY] = useState(AnchorEnum.bottom)
  const [distanceY, setDistanceY] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [left, setLeft] = useState(0)

  const [dataTimeState, setDataTimeState] = useState([])
  const [itemSelected, setItemSelected] = useState(0)

  const [minTimeState, setMinTimeState] = useState(null)
  const [maxTimeState, setMaxTimeState] = useState(null)

  const [valueState, setValueState] = useState(() => {
    let time = convertTimeToMinute()
    return time
  })

  useEffect(() => {
    if (minTime) {
      let totalMinute = convertTimeToMinute(minTime)
      setMinTimeState(totalMinute)
    }
    else {
      setMinTimeState(null)
    }
  }, [minTime])

  useEffect(() => {
    if (maxTime) {
      let totalMinute = convertTimeToMinute(maxTime)
      setMaxTimeState(totalMinute)
    }
    else {
      setMaxTimeState(null)
    }
  }, [maxTime])

  useEffect(() => {
    if (value) {
      let totalMinute = convertTimeToMinute(value)
      setValueState(totalMinute)
    }
  }, [value])

  useEffect(() => {
    if (valueState) {
      setItemSelected(valueState)
    }
  }, [valueState])

  useEffect(() => {
    if (showTime) {
      optionRef.current?.scrollIntoView()
    }
  }, [showTime])

  useEffect(() => {
    let arrTimeMinute = []
    let minute = 0
    do {
      minute = minute + (distance ? distance : DISTANCE_DEFAULT)
      if (minute == MAX_LENGTH_MINUTE) {
        arrTimeMinute?.unshift(minute)
      }
      else {
        arrTimeMinute?.push(minute)
      }
    }
    while (minute < MAX_LENGTH_MINUTE)

    setDataTimeState(arrTimeMinute)
  }, [distance])

  useEffect(() => {
    const handleResize = () => {
      let innerHeight = window.innerHeight
      let actionCpn = timeInputRef.current?.getBoundingClientRect() || {}

      // Mặc định actionCpn ở nửa dưới màn hình (Lúc này sẽ set popup lên trên)
      let anchorYNew = AnchorEnum.bottom
      let distanceYNew = innerHeight - actionCpn?.top
      let maxHeight = actionCpn?.top

      //Nếu actionCpn ở nửa trên màn hình (Lúc này sẽ set popup xuống dưới)
      if ((actionCpn?.top + actionCpn.height / 2) < (innerHeight / 2)) {
        anchorYNew = AnchorEnum.top
        distanceYNew = actionCpn?.bottom
        maxHeight = innerHeight - (actionCpn?.top + actionCpn?.height)
      }
      setAnchorY(anchorYNew)
      setDistanceY(distanceYNew)
      setMaxHeight(maxHeight)
      setLeft(actionCpn?.left)
    }

    let observer
    handleResize()
    observer = new MutationObserver(handleResize)
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    })
    window.addEventListener("resize", handleResize)
    document.addEventListener("scroll", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      document.removeEventListener("scroll", handleResize)
      observer?.disconnect()
    }
  }, [showTime])

  useEffect(() => {
    const clickOut = (event) => {
      var isClickButton = timeInputRef?.current?.contains(event.target);
      var isClickPopup = timePopupRef?.current?.contains(event.target);
      if (!isClickPopup && !isClickButton) {
        setShowTime(false)
      }
    }
    window.addEventListener("mousedown", clickOut);
    return () => window.removeEventListener("mousedown", clickOut);
  }, [])

  const onShowTime = () => {
    setShowTime(true)
  }

  const onPrevTime = () => {
    setValueState((prev) => {
      let time = (prev == 0) ? MAX_LENGTH_MINUTE : (prev - 1)
      let value = convertMinuteToObject(time)
      onChange && onChange(value)
      return time
    })
  }

  const onNextTime = () => {
    setValueState((prev) => {
      let time = (prev == MAX_LENGTH_MINUTE) ? 0 : (prev + 1)
      let value = convertMinuteToObject(time)
      onChange && onChange(value)
      return time
    })
  }

  const onSelectItemTime = (totalMinutes, isDisable) => (e) => {
    if (!isDisable) {
      let value = convertMinuteToObject(totalMinutes)
      setValueState(totalMinutes)
      setShowTime(false)
      onChange && onChange(value)
    }
  }

  const onRefOption = (isActive) => (r) => {
    if (isActive) {
      optionRef.current = r
    }
  }

  let maxHeightOfList = maxHeight - 9

  if (maxLengthOfScroll) {
    let max2 = OPTION_HEIGHT * maxLengthOfScroll
    if (max2 < maxHeightOfList) {
      maxHeightOfList = max2
    }
  }

  let isNext = maxTime ? maxTimeState > valueState : true
  let isPrev = minTime ? (minTimeState < valueState || valueState == MAX_LENGTH_MINUTE) : true

  return (
    <div className='timePickerV2Cpn'>
      {
        readOnly ?
          <div className='timeInputReadOnly'>
            <div className='prefixIcon'>
              {ICON_CLOCK}
            </div>

            <div className='textInput'>
              {toHoursAndMinutes(valueState)}
            </div>

          </div>
          :
          <>
            <div className='timeInput' ref={timeInputRef}>
              <div className='prefixIcon'>
                {ICON_CLOCK}
              </div>

              <div className='textInput' onClick={onShowTime}>
                {toHoursAndMinutes(valueState)}
              </div>

              <div className='suffixIcon'>

                {
                  isPrev ?
                    <div className='prevTime' onPointerDown={onPrevTime} title={Resource.common.previous}>
                      {ARROW_LEFT}
                    </div>
                    :
                    <div className='prevTime' style={{ cursor: 'default' }}>
                      {ARROW_LEFT_DISABLE}
                    </div>
                }

                {
                  isNext ?
                    <div className='nextTime' onPointerDown={onNextTime} title={Resource.common.next}>
                      {ARROW_RIGHT}
                    </div>
                    :
                    <div className='nextTime' style={{ cursor: 'default' }}>
                      {ARROW_RIGHT_DISABLE}
                    </div>
                }

              </div>
            </div>

            <dialog
              open={showTime}
              className='dialogTimePicker'
              ref={timePopupRef}
              style={{
                [anchorY]: distanceY + 6,
                maxHeight: maxHeightOfList,
                left: left
              }}
            >
              <div className='listTimeContainer'>
                {
                  dataTimeState?.length > 0 &&
                  dataTimeState?.map((minute, index) => {
                    let isActive = minute == itemSelected
                    let disable = minTimeState ? (minute < minTimeState || minute == MAX_LENGTH_MINUTE) : (maxTimeState ? minute > maxTimeState : false)
                    return (
                      <div
                        ref={onRefOption(isActive)}
                        className={StringTool.mergeClassName('timeItem', isActive ? 'selectItem' : '', disable ? 'disabledTime' : '')}
                        onClick={onSelectItemTime(minute, disable)}
                        key={minute}
                      >
                        {toHoursAndMinutes(minute)}
                      </div>
                    )
                  })
                }
              </div>
            </dialog>
          </>
      }
    </div>
  )
};

TimePickerV2.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  distance: PropTypes.number,
  maxLengthOfScroll: PropTypes.number,
  minTime: PropTypes.any,
  maxTime: PropTypes.any,
  readOnly: PropTypes.bool,
};

export default TimePickerV2;
