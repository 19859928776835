import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, Collapse } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommonConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum, MapEventEnum, MapTypeEnum } from '../../enum';
import TypeInfoObjectEnum from '../../enum/typeInfoObjectEnum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, DateTimeTool, SidebarTool } from '../../tool';
import BearingInput from '../common/bearingInput/bearingInput';
import Building from '../common/building/building';
import ElevationInput from '../common/elevationInput/elevationInput';
import Field from '../common/field/field';
import ModelInput from '../common/modelInput/modelInput';
import ScaleInput from '../common/scaleInput/scaleInput';
import SidebarHeader from '../common/sidebarHeader/sidebarHeader';
import Tree from '../common/tree/tree';
import Confirm from './../common/confirm/confirm';
import { useSetRecoilState } from 'recoil';
import { MapTypeAppState } from './../../appState/mapTypeAppState';
import MetaDataInput from './../common/metaDataInput/metaDataInput';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const marginField = 24
const marginText = 10

const useStyles = makeStyles({
  header: {
    boxShadow: '0px 0px 2px #00000029'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: '4px',
    transform: 'translateY(50%)',
  },
  marginBottomField: {
    marginBottom: `${marginField}px`
  },
  marginBottomText: {
    marginBottom: `${marginText}px`
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: 'inherit',
    fontWeight: 500
  }
})

const defaultInfoBuilding = {
  location: null,
  scale: 1,
  bearing: 0,
  elevation: 0,
  minZoom: 17,
  maxZoom: 19,
  startDate: null,
  endDate: null,
  model: null,
  metadata: [],
  types: [CommonConfig.building.defaultType],
  source: CommonConfig.source
}

function AddMultiBuilding() {
  const history = useHistory()
  const classes = useStyles()

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)

  const buildingInfosRef = useRef([])
  const buildingsRef = useRef([])
  const bottomSurfacesRef = useRef([])
  const currentInfoBuildingRef = useRef(defaultInfoBuilding)
  const currentBuildingRef = useRef(null)
  const currentBottomSurfaceRef = useRef(null)
  const [showExtend, setShowExtend] = useState(false)
  const [currentInfoBuilding, setCurrentInfoBuilding] = useState(defaultInfoBuilding)

  const updateCurrentBottomSurface = () => {
    currentBuildingRef.current?.getBoundsCoordinates((result) => {
      if (result && result.length > 0) {
        result.push(result[0])
        let coordinateTransformer = new map4d.CoordinateTransformer(result)
        let latLngScaled = coordinateTransformer.scale(1.1)
        currentBottomSurfaceRef.current.setPaths([latLngScaled])
      }
    })
  }

  useEffect(() => {
    SidebarTool.setOpen(true)
    setMapTypeAppState(MapTypeEnum.map3d)
    const eventClickMap = AppData.map.addListener(MapEventEnum.click, (args) => {
      if (currentInfoBuildingRef.current?.model) {
        buildingInfosRef.current.push({
          ...currentInfoBuildingRef.current,
          location: args.location
        })

        let building = new map4d.Building({
          position: args.location,
          scale: currentInfoBuildingRef.current?.scale,
          bearing: currentInfoBuildingRef.current?.bearing,
          elevation: currentInfoBuildingRef.current?.elevation,
          model: currentInfoBuildingRef.current?.model?.objUrl,
          texture: currentInfoBuildingRef.current?.model?.textureUrl,
          draggable: true,
          zIndex: 1000
        })
        building.setMap(AppData.map)
        currentBuildingRef.current?.setSelected(false)
        building.setSelected(true)
        currentBuildingRef.current = building
        buildingsRef.current.push(building)

        building.getBoundsCoordinates((result) => {
          if (result && result.length > 0) {
            result.push(result[0])
            let coordinateTransformer = new map4d.CoordinateTransformer(result)
            let latLngScaled = coordinateTransformer.scale(1.1)
            let polygonOption = {
              paths:
                [latLngScaled],
              fillColor: "#e83e8c",
              fillOpacity: 0.3,
              strokeColor: "#e83e8c94",

            }
            let polygon = new map4d.Polygon(polygonOption)
            polygon.setMap(AppData.map)
            bottomSurfacesRef.current.push(polygon)
            currentBottomSurfaceRef.current = polygon
          }
        })
        setCurrentInfoBuilding({
          ...currentInfoBuildingRef.current,
          location: args.location
        })
      }
    })

    const eventRightClickBuilding = AppData.map.addListener(MapEventEnum.rightClick, (args) => {
      let index = buildingsRef.current.findIndex((building) => {
        return building == args.building
      })
      if (index > -1) {
        buildingsRef.current[index].setMap(null)
        bottomSurfacesRef.current[index].setMap(null)
        buildingsRef.current.splice(index, 1)
        buildingInfosRef.current.splice(index, 1)
        bottomSurfacesRef.current.splice(index, 1)
        if (currentBuildingRef.current == args.building) {
          if (buildingsRef.current.length > 0) {
            currentBuildingRef.current = buildingsRef.current[buildingsRef.current.length - 1]
            currentBuildingRef.current.setSelected(true)
            currentBottomSurfaceRef.current = currentBottomSurfaceRef.current[currentBottomSurfaceRef.current.length - 1]
          }
        }
      }
    }, { building: true })

    const eventClickBuilding = AppData.map.addListener(MapEventEnum.click, (args) => {
      let index = buildingsRef.current.findIndex((building) => {
        return building == args.building
      })
      if (index > -1) {
        currentBuildingRef.current?.setSelected(false)
        currentBuildingRef.current = buildingsRef.current[index]
        currentBottomSurfaceRef.current = bottomSurfacesRef.current[index]
        currentInfoBuildingRef.current = buildingInfosRef.current[index]
        currentBuildingRef.current?.setSelected(true)
        setCurrentInfoBuilding({ ...buildingInfosRef.current[index] })
      }
    }, { building: true })

    const eventDragBuilding = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      let index = buildingsRef.current.findIndex((building) => {
        return building == args.building
      })
      if (index > -1) {
        buildingInfosRef.current[index].location = args.building.getPosition()
        currentBuildingRef.current?.setSelected(false)
        currentBuildingRef.current = buildingsRef.current[index]
        currentBottomSurfaceRef.current = bottomSurfacesRef.current[index]
        currentInfoBuildingRef.current = buildingInfosRef.current[index]

        currentBuildingRef.current?.setSelected(true)

        updateCurrentBottomSurface()

        setCurrentInfoBuilding({ ...buildingInfosRef.current[index] })
      }
    }, { building: true })


    return () => {
      eventClickMap?.remove()
      eventRightClickBuilding?.remove()
      eventClickBuilding?.remove()
      eventDragBuilding?.remove()
    }
  }, [])

  useEffect(() => {
    currentInfoBuildingRef.current = { ...currentInfoBuilding }
    let index = buildingsRef.current.findIndex((building) => {
      return building == currentBuildingRef.current
    })
    if (index > -1) {
      buildingInfosRef.current[index] = {
        ...buildingInfosRef.current[index],
        ...currentInfoBuilding
      }
    }
  }, [currentInfoBuilding])

  const handleClickClose = () => {
    history.push({
      pathname: RouterConfig.home
    })
  }

  const onChangeStartDate = (date) => {
    setCurrentInfoBuilding(prev => ({
      ...prev,
      startDate: DateTimeTool.getDateWithStartTime(date)
    }))
  }

  const onChangeEndDate = (date) => {
    setCurrentInfoBuilding(prev => ({
      ...prev,
      endDate: DateTimeTool.getDateWithEndTime(date)
    }))
  }

  const onChangeElevation = (value) => {
    let elevation = parseFloat(value)
    if (!isNaN(elevation) && elevation >= 0) {
      currentBuildingRef.current?.setElevation(elevation)
    }
    setCurrentInfoBuilding(prev => ({
      ...prev,
      elevation: value
    }))
  }
  const onChangeBearing = (value) => {
    let bearing = parseFloat(value)
    if (!isNaN(bearing)) {
      currentBuildingRef.current?.setBearing(bearing)
      updateCurrentBottomSurface()
    }
    setCurrentInfoBuilding(prev => ({
      ...prev,
      bearing: value
    }))
  }
  const onChangeScale = (value) => {
    let scale = parseFloat(value)
    if (!isNaN(scale) && scale > 0) {
      currentBuildingRef.current?.setScale(scale)
      updateCurrentBottomSurface()
    }
    setCurrentInfoBuilding(prev => ({
      ...prev,
      scale: value
    }))
  }

  const onChangeModel = (model) => {
    setCurrentInfoBuilding(prev => ({
      ...prev,
      model: model
    }))
    currentBuildingRef.current?.setModel(model.objUrl)
    currentBuildingRef.current?.setTexture(model.textureUrl)
    updateCurrentBottomSurface()
  }
  const onChangeMinZoom = (e) => {
    let zoom = e.target.value
    setCurrentInfoBuilding(prev => ({
      ...prev,
      minZoom: zoom
    }))
  }
  const onChangeMaxZoom = (e) => {
    let zoom = e.target.value
    setCurrentInfoBuilding(prev => ({
      ...prev,
      maxZoom: zoom
    }))
  }

  const onClickSave = (e) => {
    if (buildingInfosRef.current?.length > 0) {
      getTileCover(() => {
        let count = 0
        buildingInfosRef.current?.forEach((buildingInfo, index) => {
          buildingInfo.name = `${buildingInfo.model.name}`
          buildingInfo.model.type = "Object"
          let params = {
            lat: buildingInfo?.location?.lat,
            lng: buildingInfo?.location?.lng,
          };
          ApiTool.queryGetFromJson(UrlConfig.geocode.getAddressComponents, params, (res) => {
            count += 1
            if (res?.code == CodeEnum.ok) {
              buildingInfosRef.current[index].addressComponents = res?.result;
            }
            if (count == buildingInfosRef.current.length) {
              ApiTool.post(UrlConfig.object.createMulti, buildingInfosRef.current, (res) => {
                if (res?.code == CodeEnum.ok) {
                  AppTool.alert(Resource.alert.success.addMultiBuilding, AlertTypeEnum.success)
                  location.href = RouterConfig.home + location.search
                }
                else {
                  AppTool.alert(res?.message || Resource.alert.error.addMultiBuilding, AlertTypeEnum.error)
                }
              }, true)
            }
          });
        })
      })
    }
    else {
      AppTool.alert(Resource.alert.warning.addMultiBuilding, AlertTypeEnum.warning)
    }
  }

  const getTileCover = (callback) => {
    if (buildingsRef.current?.length > 0) {
      let count = 0
      buildingsRef.current?.forEach((building, index) => {
        let buildingInfo = buildingInfosRef.current[index]
        let zoomRank = Array.from({ length: buildingInfo.maxZoom - buildingInfo.minZoom + 1 }, (_, i) => i + buildingInfo.minZoom)
        building.getTileCovers(zoomRank, (tiles) => {
          buildingInfo.tiles = tiles
          count += 1;
          if (count == buildingsRef.current.length) {
            callback()
          }
        })
      })
    }
    else {
      callback()
    }
  }

  const onChangeMetaData = (metadata) => {
    setCurrentInfoBuilding(prev => ({
      ...prev,
      metadata: metadata
    }))
  }

  const handleShowExtend = () => {
    setShowExtend(!showExtend)
  }

  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      <SidebarHeader title={Resource.object3d.addMulti} />
      <Box overflow='auto' flexGrow={1} display="flex" flexDirection="column">
        <Box p={`${marginField}px`} flexGrow={1} overflow="auto">
          <Field
            label={Resource.common.model}
            icon={<img src={LinkIconConfig.requestUpdatePlace.placeName} width={24} height={24} />}
            required
            helperText={<Typography variant="caption">Hãy chọn mô hình trước khi click vị trí trên bản đồ</Typography>}
          >
            <ModelInput disableClearable value={currentInfoBuilding.model} onChange={onChangeModel} />
          </Field>
          <Field
            label={Resource.common.minZoom}
            icon={<img src={LinkIconConfig.common.minZoom} width={24} height={24} />}
            required
          >
            <FormControl variant="outlined">
              <InputLabel id="minZoom">{Resource.minZoom.choose}</InputLabel>
              <Select
                labelId="minZoom"
                label={Resource.minZoom.choose}
                value={currentInfoBuilding.minZoom}
                onChange={onChangeMinZoom}
              >
                {
                  Array.from({ length: currentInfoBuilding.maxZoom - 17 + 1 }, (_, i) => i + 17).map((zoom, index) => (
                    <MenuItem key={zoom} value={zoom}>{zoom}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Field>
          <Field
            label={Resource.common.maxZoom}
            icon={<img src={LinkIconConfig.common.maxZoom} width={24} height={24} />}
            required
          >
            <FormControl variant="outlined">
              <InputLabel id="minZoom">{Resource.maxZoom.choose}</InputLabel>
              <Select
                labelId="minZoom"
                label={Resource.maxZoom.choose}
                value={currentInfoBuilding.maxZoom}
                onChange={onChangeMaxZoom}
              >
                {
                  Array.from({ length: 19 - currentInfoBuilding.minZoom + 1 }, (_, i) => i + currentInfoBuilding.minZoom).map((zoom, index) => (
                    <MenuItem key={zoom} value={zoom}>{zoom}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Field>
          <Field
            label={Resource.common.scale}
            icon={<img src={LinkIconConfig.common.scale} width={24} height={24} />}
            required
          >
            <ScaleInput
              value={currentInfoBuilding.scale}
              onChange={onChangeScale}
            />
          </Field>
          <Field
            label={Resource.common.bearing}
            icon={<img src={LinkIconConfig.common.bearing} width={24} height={24} />}
            required
          >
            <BearingInput
              onChange={onChangeBearing}
              value={currentInfoBuilding.bearing}
            />
          </Field>
          <Field
            label={Resource.common.elevation}
            icon={<img src={LinkIconConfig.common.elevation} width={24} height={24} />}
            required
          >
            <ElevationInput
              onChange={onChangeElevation}
              value={currentInfoBuilding.elevation}
            />
          </Field>
          <Field
            label={Resource.common.startDate}
            icon={<img src={LinkIconConfig.common.startDate} width={24} height={24} />}
          >
            <DatePicker
              label={Resource.startDate.choose}
              inputVariant="outlined"
              clearable
              value={currentInfoBuilding.startDate}
              maxDate={currentInfoBuilding.endDate || CommonConfig.maxDate}
              onChange={onChangeStartDate}
              format="dd/MM/yyyy"
            />
          </Field>
          <Field
            label={Resource.common.endDate}
            icon={<img src={LinkIconConfig.common.endDate} width={24} height={24} />}
          >
            <DatePicker
              label={Resource.endDate.choose}
              inputVariant="outlined"
              clearable
              value={currentInfoBuilding.endDate}
              minDate={currentInfoBuilding.startDate || CommonConfig.minDate}
              onChange={onChangeEndDate}
              format="dd/MM/yyyy"
            />
          </Field>

          <Box>
              <Button className={classes.extendBtn} fullWidth variant='text' onClick={handleShowExtend} endIcon={showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {Resource.updatePlace.addExtendBtn}
              </Button>
              <Collapse in={showExtend} timeout={600} >
                <MetaDataInput onChange={onChangeMetaData} />
              </Collapse>
            </Box>

        </Box>
        <Box display='flex' justifyContent='space-evenly' p={"5px"}>
          <Confirm
            onConfirm={handleClickClose}
            message={Resource.formatString(Resource.confirmMessage.cancel, { action: Resource.object3d.addMulti?.toLowerCase() })}
          >
            <Button>{Resource.button.cancel}</Button>
          </Confirm>
          <Button onClick={onClickSave} variant='contained' color='primary'>{Resource.common.save}</Button>
        </Box>
      </Box>
    </Box>

  )

}

AddMultiBuilding.propTypes = {
  //
};

export default AddMultiBuilding;
