import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { StringTool } from "../../tool";
import Dialog from "../dialog/dialog";
import DialogAction from "../dialogAction/dialogAction";
import DialogBody from "../dialogBody/dialogBody";
import { ImageConfig } from './../../config/imageConfig';
import "./congratsBanner.scss";

const ICON_CLOSE =
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Component_843_12" data-name="Component 843 – 12" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_28146" data-name="Rectangle 28146" width="16" height="16" transform="translate(4074 427)" fill="#fff" stroke="#707070" strokeWidth="1" />
      </clipPath>
    </defs>
    <g id="Mask_Group_44260" data-name="Mask Group 44260" transform="translate(-4074 -427)" clipPath="url(#clip-path)">
      <path id="Path_12963" data-name="Path 12963" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(4070 423.143)" fill="#fff" />
    </g>
  </svg>

function CongratsBanner(props) {

  const { type, title, subTitle, icon, open, onClose, action, className } = props

  const [openState, setOpenState] = useState(false)

  const [classNameCus, setClassName] = useState(() => {
    return StringTool.mergeClassName('congratsBannerCpn', className)
  })

  useEffect(() => {
    setOpenState(open)
  }, [open])

  const handleClose = (e) => {
    setOpenState(false)
    onClose && onClose(e)
  }

  useEffect(() => {
    setClassName(StringTool.mergeClassName('congratsBannerCpn', className))
  }, [className])

  return (
    <Dialog
      open={openState}
      className={classNameCus}
      onClose={handleClose}
    >
      <DialogBody>
        <div className='bannerContainer'>
          {
            onClose &&
            <div
              title="Đóng"
              onClick={handleClose}
              className={StringTool.mergeClassName('close', type ? type : '')}
            >
              {ICON_CLOSE}
            </div>
          }
          <div className={StringTool.mergeClassName('bannerContent', type ? type : '')}>
            <div className='headerImg'>
              <img src={ImageConfig.banner.bgBanner} alt='bgBanner' />
            </div>
            <div className='content'>
              {
                icon &&
                <div className='icon'>
                  {icon}
                </div>
              }
              {
                title &&
                <div className='title'>
                  {title}
                </div>
              }
              {
                subTitle &&
                <div className='subTitle'>
                  {subTitle}
                </div>
              }
            </div>
          </div>
        </div>
      </DialogBody>
      {
        action &&
        <DialogAction>
          {action}
        </DialogAction>
      }
    </Dialog>
  );
};

CongratsBanner.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.any,
  className: PropTypes.any
};

export default CongratsBanner;
