import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { ProvincesState } from "../../appState";
import { UrlConfig } from "../../config";
import { CodeEnum } from "../../enum";
import { ApiTool } from "../../tool";

const GetProvinces = () => {
  const [provinces, setProvinces] = useRecoilState(ProvincesState);
  useEffect(() => {
    let source;
    if (!provinces?.length > 0) {
      source = ApiTool.get(
        UrlConfig.country.getAllProvince,
        (res) => {
          if (res?.code === CodeEnum.ok) {
            setProvinces(res?.result || []);
          }
        }
      );
    }
    return () => {
      source?.cancel();
    };
  }, [provinces]);
  return null;
};

GetProvinces.propTypes = {
  //
};

export default GetProvinces;
