import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    createMuiTheme,
    ThemeProvider,
    useTheme
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { vi } from "date-fns/locale";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import "./app.css";
import { ShowCameraControlState, ShowCircleMenuState, ShowLocationControlState, ShowPlaceControlState, ShowPoiControlState } from "./appState";
import AddBuilding from "./components/addBuilding/addBuilding";
import AddMultiBuilding from "./components/addMultiBuilding/addMultiBuilding";
import AddPlace from "./components/addPlace";
import AddPoiControl from "./components/addPoiControl/addPoiControl";
import AlertControl from "./components/alertControl/alertControl";
import Bao from "./components/bao";
import BuildingMenu from "./components/buildingMenu/buildingMenu";
import CameraControl from "./components/cameraControl/cameraControl";
import CircleMenu from "./components/circleMenu/circleMenu";
import AddLink from './components/common/addLink/addLink';
import AddVr360 from "./components/common/addVr360/addVr360";
import EffectSetting from "./components/common/effectSetting/effectSetting";
import LanguageSetting from "./components/common/languageSetting/languageSetting";
import LayerSetting from "./components/common/layerSetting/layerSetting";
import LocationControl from "./components/common/locationControl/locationControl";
import MapControl from "./components/common/mapControl";
import MapView from "./components/common/mapView";
import PlaceControl from "./components/common/placeControl/placeControl";
import PoiControl from "./components/common/poiControl/poiControl";
import Setting from "./components/common/setting/setting";
import ShareControl from "./components/common/shareControl/shareControl";
import ShowDataExtend from "./components/common/showDataExtend";
import ShowImageGallery from "./components/common/showImageGallery/showImageGallery";
import ShowMediaGallery from "./components/common/showMediaGallery/showMediaGallery";
import ShowMetaData from "./components/common/showMetaData/showMetaData";
import ShowPreviewData from "./components/common/showPreviewData/showPreviewData";
import Sidebar from "./components/common/sidebar/sidebar";
import SidebarPopup from "./components/common/sidebarPopup/sidebarPopup";
import UserControl from "./components/common/userControl";
import CopyRight from "./components/copyRight/copyRight";
import DirectionV2 from "./components/directionV2/directionV2";
import EffectSettingV2 from "./components/effectSettingV2/effectSettingV2";
import EmbedApp from "./components/embedApp/embedApp";
import GroupDetail from "./components/groupDetail/groupDetail";
import HideLocationControl from "./components/hideLocationControl/hideLocationControl";
import Hien from "./components/hien/hien";
import Home from "./components/home/home";
import LocationMenu from "./components/locationMenu/locationMenu";
import MapPoiMenu from "./components/mapPoiMenu";
import MapTypeControl from "./components/mapTypeControl/mapTypeControl";
import MyPois from "./components/myPois/myPois";
import Nhung from "./components/nhung";
import Object3dDetail from "./components/object3dDetail/object3dDetail";
import Object3DRequestUpdate from "./components/object3DRequestUpdate/object3DRequestUpdate";
import PlaceDetail from "./components/placeDetail";
import PlaceSearch from "./components/placeSearch/placeSearch";
import PoiInfo from "./components/poiInfo/poiInfo";
import RequestUpdatePlace from "./components/requestUpdatePlace";
import ShowPositionSelect from "./components/showPositionSelect/showPositionSelect";
import ShowSearchBar from './components/showSearchBar/showSearchBar';
import ShowSuggestPlace from "./components/showSuggestPlace/showSuggestPlace";
import Tags from "./components/tags/tags";
import Toan from "./components/toan";
import UpdateGroup from "./components/updateGroup/updateGroup";
import { ColorConfig, EmbedRouterConfig } from "./config";
import { RouterConfig } from "./config/routerConfig";
import "./css/animation.css";
import { AppData } from "./data";
import PlaceSearchV2 from "./components/placeSearchV2/placeSearchV2";

function App() {
    const defaultTheme = useTheme();
    const shadows = defaultTheme.shadows;
    shadows[1] = "0px 0px 2px #00000052";

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: ColorConfig.primary,
            },
            default: {
                main: ColorConfig.default,
            },
        },
        shadows: shadows,
        typography: {
            h6: {
                fontSize: "1rem",
            },
            fontFamily: [
                "Quicksand",
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        spacing: 6,
    });

    const [isLoadedMap, setIsLoadedMap] = useState(false);
    const showCameraControl = useRecoilValue(ShowCameraControlState);
    const showPoiControl = useRecoilValue(ShowPoiControlState)
    const showPlaceControl = useRecoilValue(ShowPlaceControlState)
    const showLocationControl = useRecoilValue(ShowLocationControlState)
    const showCircleMenu = useRecoilValue(ShowCircleMenuState)

    const handleLoadedMap = (map) => {
        AppData.map = map;
        setIsLoadedMap(true);
    };

    const handeSidebarRef = (r) => {
        AppData.sidebar = r;
    };

    const handleShareRef = (r) => {
        AppData.shareControl = r;
    };

    const handleShowMediaRef = (r) => {
        AppData.media = r;
    };

    const handleAlertRef = (r) => {
        AppData.alert = r;
    };

    const refMapTypeControl = (r) => {
        AppData.mapTypeControl = r;
    };

    const handleSidebarRef = (r) => {
        AppData.sidebarPopup = r;
    };


    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={vi}>
                <CssBaseline />
                <div className="appCpn">
                    <MapView onLoadedMap={handleLoadedMap} width="100%" height="100%" />
                    {isLoadedMap && (
                        <>
                            <Route exact path={"/"}>
                                <Redirect to={RouterConfig.home + window.location.search} />
                            </Route>
                            <Route path={RouterConfig.home}>
                                <Sidebar ref={handeSidebarRef}>
                                    {/* Page by router */}
                                    <Switch>
                                        <Route exact path={RouterConfig.home}>
                                            <Home />
                                        </Route>

                                        <Route exact path={RouterConfig.object3d.detail}>
                                            <Object3dDetail />
                                        </Route>

                                        <Route
                                            exact
                                            path={[
                                                RouterConfig.place.search,
                                            ]}
                                        >
                                            <PlaceSearchV2 />
                                        </Route>

                                        <Route exact path={RouterConfig.placeGroup.groupDetail}>
                                            <GroupDetail />
                                        </Route>

                                        <Route exact path={RouterConfig.placeGroup.updateGroup}>
                                            <UpdateGroup />
                                        </Route>

                                        <Route exact path={RouterConfig.my.place}>
                                            <MyPois />
                                        </Route>

                                        <Route exact path={RouterConfig.tags}>
                                            <Tags />
                                        </Route>

                                        <Route
                                            exact
                                            path={[
                                                RouterConfig.place.detail,
                                                RouterConfig.place.detailWithLocation,
                                                RouterConfig.place.detailCustom,
                                            ]}
                                        >
                                            <PlaceDetail />
                                            <ShowPositionSelect />
                                            <ShowSuggestPlace />
                                        </Route>
                                        <Route exact path={RouterConfig.place.add}>
                                            <AddPlace />
                                        </Route>

                                        <Route exact path={RouterConfig.direction}>
                                            <DirectionV2 />
                                        </Route>

                                        <Route exact path={RouterConfig.place.requestUpdate}>
                                            <RequestUpdatePlace />
                                        </Route>

                                        <Route exact path={RouterConfig.object3d.addMulti}>
                                            <AddMultiBuilding />
                                            <HideLocationControl/>
                                        </Route>

                                        <Route exact path={RouterConfig.object3d.add}>
                                            <AddBuilding />
                                            <HideLocationControl/>
                                        </Route>

                                        <Route exact path={RouterConfig.object3d.requestUpdate}>
                                            <Object3DRequestUpdate />
                                            <HideLocationControl/>
                                        </Route>
                                    </Switch>
                                </Sidebar>

                                <Route
                                    exact
                                    path={[
                                        RouterConfig.home,
                                        RouterConfig.place.detail,
                                        // RouterConfig.place.search,
                                        RouterConfig.place.detailWithLocation,
                                        RouterConfig.place.detailCustom,
                                        RouterConfig.object3d.detail,
                                    ]}
                                >
                                    <ShowSearchBar />
                                </Route>


                                <AddPoiControl />
                                <MapControl />
                                {showCameraControl && <CameraControl />}
                                <ShareControl ref={handleShareRef} />
                                <Setting />
                                <PoiInfo />
                                {window.top == window.self && <UserControl />}
                                <LayerSetting />
                                <LanguageSetting />
                                <EffectSetting />
                                <EffectSettingV2 />
                                <BuildingMenu />
                                <LocationMenu />
                                <MapPoiMenu />
                                <ShowPreviewData />
                                <ShowMetaData />
                                <ShowImageGallery />
                                {showLocationControl && <LocationControl />}
                                {showPoiControl && <PoiControl />}
                                {showPlaceControl && <PlaceControl />}
                                {showCircleMenu && <CircleMenu />}
                                <ShowMediaGallery ref={handleShowMediaRef} />
                                <ShowDataExtend />
                                <AlertControl ref={handleAlertRef} />
                                <MapTypeControl ref={refMapTypeControl} />
                                <CopyRight />
                                <SidebarPopup ref={handleSidebarRef} />
                                <AddVr360 />
                                <AddLink />
                            </Route>

                            {/* dành cho nhúng */}
                            <Route path={EmbedRouterConfig.home}>
                                <EmbedApp />
                            </Route>

                            {/* dành cho dev */}
                            <Route exact path={"/dev/toan"}>
                                <div
                                    style={{
                                        zIndex: 10000,
                                        position: "fixed",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "#ffffff",
                                    }}
                                >
                                    <Toan />
                                </div>
                            </Route>
                            <Route exact path={"/dev/bao"}>
                                <div
                                    style={{
                                        zIndex: 10000,
                                        position: "fixed",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "#ffffff",
                                    }}
                                >
                                    <Bao />
                                </div>
                            </Route>
                            <Route exact path={"/dev/nhung"}>
                                <div
                                    style={{
                                        zIndex: 1,
                                        position: "fixed",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "#FFFFFF",
                                        overflow: 'auto'
                                    }}
                                >
                                    <Nhung />
                                </div>
                            </Route>
                            <Route exact path={"/dev/hien"}>
                                <Hien />
                            </Route>
                            <Route exact path={"/dev/bao"}>
                                <div
                                    style={{
                                        zIndex: 10000,
                                        position: "fixed",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "#FFFFFF",
                                        overflow: 'auto'
                                    }}
                                >
                                    <Bao />
                                </div>
                            </Route>
                        </>
                    )}
                </div>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
}
export default App;
