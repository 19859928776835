import { Box, createMuiTheme, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { CommonConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { MyPoisTypeEnum } from '../../enum';
import MobileHeader from '../../mobileComponents/common/mobileHeader/mobileHeader';
import { Resource } from '../../resource';
import MyLabels from '../myLabels/myLabels';
import MySavedGroups from './../mySavedGroups/mySavedGroups';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { LocationCanBackState, ShowSettingState } from '../../appState';

const useStyles = makeStyles(theme => ({
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
  tab: {
    padding: 0
  }
}))

const iconTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})
function MyPois() {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const { type } = useParams()

  const stackRef = useRef(history.length)

  const setShowSettingState = useSetRecoilState(ShowSettingState)
  const locationCanBackState = useRecoilValue(LocationCanBackState)

  const handleGoBack = () => {
    setShowSettingState(true)
    history?.push({
      pathname: RouterConfig.home
    })
  }

  const handleChange = (event, newValue) => {
    history.push({
      pathname: RouterConfig.my.place.replace(":type", newValue)
    })
  };

  return (
    <Box
      maxWidth={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      <MobileHeader isBack title={Resource.common.myPlace} onComeBack={handleGoBack} />
      <TabContext value={type}>
        <Tabs
          value={type}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label={Resource.myPoi.labeled} value={MyPoisTypeEnum.label} />
          <Tab label={Resource.myPoi.saved} value={MyPoisTypeEnum.saved} />
        </Tabs>
        <Box flexGrow={1} overflow="auto">
          <TabPanel classes={{ root: classes.tab }} value={MyPoisTypeEnum.label} index={0}>
            <MyLabels />
          </TabPanel>
          <TabPanel classes={{ root: classes.tab }} value={MyPoisTypeEnum.saved} index={1}>
            <MySavedGroups />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}

MyPois.propTypes = {
  //
};

export default MyPois;
