import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApiTool } from '../../../tool';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { CommonConfig } from '../../../config';


function TagInput(props) {
  const { defaultValue, value, onChange } = props
  const [tagsOptions, setTagsOptions] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [tags, setTags] = useState(defaultValue || []);
  const [text, setText] = useState('')

  useEffect(() => {
    if (!("value" in props)) {
      onChange && onChange(tags)
    }
  }, [tags, props])


  const getTags = (tagSearch) => {
    let sourceTags
    let body = {
      Keyword: tagSearch,
      RecordNumber: CommonConfig.pageSizeSearch,
    };
    setLoadingTags(true);
    sourceTags = ApiTool.queryGetFromJson(UrlConfig.tag.filter, body, (res) => {
      if (res?.code == CodeEnum.ok && res?.result) {
        let options = (res?.result || [])?.filter((option) => {
          return !tags?.find((tag) => tag.name === option?.name);
        });
        setTagsOptions(options);
      }
      setLoadingTags(false);
    }, true);
    setText(tagSearch)
  }

  const handleChangeTags = (e, newValue, reason) => {
    let createValue = newValue?.find((tag) => tag.inputValue);
    if (createValue?.inputValue) {
      setLoadingTags(true);
      ApiTool.post(`${UrlConfig.tag.create}/${createValue?.inputValue}`, [], (res) => {
        setLoadingTags(false);
        if (res?.code == CodeEnum.ok) {
          let tagsNew = (newValue || [])?.filter((t) => {
            {
              return !t.inputValue;
            }
          });
          tagsNew.push(res?.result);

          if ("value" in props) {
            onChange && onChange(tagsNew)
          }
          else {
            setTags(tagsNew)
          }
        }
      }, true);
    }
    else {
      if ("value" in props) {
        onChange && onChange(newValue)
      }
      else {
        setTags(newValue)
      }
    }
  };

  return (
    <Autocomplete
      closeText={Resource.common.close}
      openText={Resource.common.open}
      clearText={Resource.common.clear}
      multiple
      fullWidth
      value={(("value" in props) ? value : tags) || []}
      getOptionLabel={(option) => {
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const tagsCheck = tags.find((tag) => tag.name === params.inputValue);
        const optionsCheck = options.find((o) => o.name === params.inputValue);
        if (
          (options.length === 0 && params?.inputValue && !tagsCheck) ||
          (!optionsCheck && params.inputValue !== "")
        ) {
          options.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }
        return options;
      }}
      renderOption={(option) => option.name}
      options={tagsOptions}
      loading={loadingTags}
      onChange={handleChangeTags}
      onInputChange={(e, val) => getTags(val)}
      renderInput={(params) =>
        <TextField
          {...params}
          id=""
          label={Resource.updatePlace.addTag}
          variant="outlined"
        />
      }
      noOptionsText={
        text ? Resource.common.noOption : Resource.common.enterKeyword
      }
    />


  )
}

TagInput.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default TagInput;
