import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { LinkIconConfig } from '../../config';
import { DirectionOptionTypeEnum, ManeuverEnum } from '../../enum';
import { Resource } from '../../resource';
import { DirectionTool, StringTool } from '../../tool';
import { AppTool } from './../../tool/appTool';
import './routeDetailV2.scss';
import TooltipV2 from './../tooltipV2/tooltipV2';
import { LanguageState } from './../../appState/settingState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DirectionDataState, SummaryDirectionState } from '../../appState';
import { AppData } from '../../data';
import { ImageConfig } from './../../config/imageConfig';

const SHARE_ICON =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M18.912,16.95a3.087,3.087,0,0,0-2.079.818L9.269,13.362a3.479,3.479,0,0,0,.1-.743,3.479,3.479,0,0,0-.1-.743l7.479-4.364A3.179,3.179,0,1,0,15.73,5.185a3.479,3.479,0,0,0,.1.743L8.346,10.293a3.185,3.185,0,1,0,0,4.651L15.9,19.361a3,3,0,0,0-.085.69,3.1,3.1,0,1,0,3.1-3.1Zm0-12.827a1.062,1.062,0,1,1-1.061,1.062A1.064,1.064,0,0,1,18.912,4.124ZM6.182,13.68a1.062,1.062,0,1,1,1.061-1.062A1.064,1.064,0,0,1,6.182,13.68Zm12.73,7.454a1.062,1.062,0,1,1,1.061-1.062A1.064,1.064,0,0,1,18.912,21.134Z" transform="translate(-0.547 -0.576)" fill="#505050" />
  </svg>

const ICON_MANEUVER = {
  [ManeuverEnum.keepLeft]: LinkIconConfig.maneuver.keepLeft,
  [ManeuverEnum.keepRight]: LinkIconConfig.maneuver.keepRight,
  [ManeuverEnum.roundaboutLeft]: LinkIconConfig.maneuver.roundaboutLeft,
  [ManeuverEnum.roundaboutRight]: LinkIconConfig.maneuver.roundaboutRight,
  [ManeuverEnum.straight]: LinkIconConfig.maneuver.straight,
  [ManeuverEnum.turnLeft]: LinkIconConfig.maneuver.turnLeft,
  [ManeuverEnum.turnRight]: LinkIconConfig.maneuver.turnRight,
  [ManeuverEnum.turnSharpLeft]: LinkIconConfig.maneuver.turnSharpLeft,
  [ManeuverEnum.turnSharpRight]: LinkIconConfig.maneuver.turnSharpRight,
  [ManeuverEnum.turnSlightLeft]: LinkIconConfig.maneuver.turnSlightLeft,
  [ManeuverEnum.turnSlightRight]: LinkIconConfig.maneuver.turnSlightRight,
  [ManeuverEnum.finish]: LinkIconConfig.maneuver.finish,
  [ManeuverEnum.reachedVia]: LinkIconConfig.maneuver.reachedVia
}

function RouteDetailV2(props) {

  const LabelOptionType = {
    [DirectionOptionTypeEnum.yourPosition]: Resource.common.yourLocation,
    [DirectionOptionTypeEnum.home]: Resource.common.homePrivate,
    [DirectionOptionTypeEnum.work]: Resource.common.office
  }

  const { route, points, onBack, vehicle } = props

  const stepMarker = useRef()


  const [indexStepActive, setIndexStepActive] = useState()
  const [indexLegActive, setIndexLegActive] = useState()
  const languageState = useRecoilValue(LanguageState)
  const setSumDirecState = useSetRecoilState(SummaryDirectionState)
  const setDataShare = useSetRecoilState(DirectionDataState)

  useEffect(() => {
    return () => {
      setSumDirecState(null)
      setDataShare(null)
    }
  }, [])

  useEffect(() => {
    route && setSumDirecState(route?.summary)
  }, [route])

  useEffect(() => {
    points && setDataShare((prev) => ({
      ...JSON.parse(JSON.stringify(prev || {})),
      points: JSON.parse(JSON.stringify(points || []))
    }))
  }, [points])

  useEffect(() => {
    vehicle && setDataShare((prev) => ({
      ...prev,
      mode: vehicle
    }))
  }, [vehicle])

  useEffect(() => {
    stepMarker.current = new map4d.Marker({
      position: { lat: 0, lng: 0 },
      icon: new map4d.Icon(25, 30, ImageConfig.marker),
      anchor: [0.5, 1],
      zIndex: 99999
    })
    return () => {
      stepMarker.current?.setMap(null)
    }
  }, [])

  const handleShareDirection = () => {
    AppTool.share(window.location.href)
  }

  const handleGoBack = () => {
    onBack && onBack()
  }

  const onClickStep = (indexLeg, indexStep, step) => (e) => {
    setIndexStepActive(indexStep)
    setIndexLegActive(indexLeg)
    stepMarker.current?.setPosition(step?.startLocation)
    stepMarker.current?.setTitle(step?.htmlInstructions)
    stepMarker.current?.setMap(AppData.map)
    moveCamera(step?.startLocation)
  }

  const moveCamera = (location) => {
    let camera = AppData.map.getCamera()
    camera.setTarget(location)
    AppData.map.moveCamera(camera, { animate: true });
  }

  const getPointLabelAddress = (point) => {
    let positionText = point?.position ? `${point?.position?.lat?.toFixed(6)}, ${point?.position?.lng?.toFixed(6)}` : ''
    let text = point?.address || point?.name || positionText || Resource.common.notYetAddress
    let labelOptionType = LabelOptionType[point?.optionType]
    if (labelOptionType) {
      text = labelOptionType
    }
    return text
  }

  const getPointLabelName = (point) => {
    let positionText = point?.position ? `${point?.position?.lat?.toFixed(6)}, ${point?.position?.lng?.toFixed(6)}` : ''
    let text = point?.name || point?.address || positionText || Resource.common.notYetAddress
    let labelOptionType = LabelOptionType[point?.optionType]
    if (labelOptionType) {
      text = labelOptionType
    }
    return text
  }


  let fromAddress = getPointLabelAddress(points[0]) || Resource.common.notYetAddress
  let toAddress = getPointLabelAddress(points[points?.length - 1]) || Resource.common.notYetAddress
  let textDistance = DirectionTool.convertDistanceLocalize(route?.distance?.value, languageState)

  return (
    <div className='routeDetailV2Cpn'>
      <div className='routeHeader'>

        <TooltipV2 description={Resource.common.back} anchor='bottom'>
          <div className='backIcon' onClick={handleGoBack} >
            <img src={LinkIconConfig.common.back} alt='back' />
          </div>
        </TooltipV2>

        <div className='title'>
          <div className='from'>
            <span className='text caption'>{Resource.common.from.toLocaleLowerCase()}</span>
            <span className='text'>{` ${fromAddress} `}</span>
          </div>
          <div className='to'>
            <span className='text caption'>{Resource.common.to.toLocaleLowerCase()}</span>
            <span className='text'>{` ${toAddress} `}</span>
          </div>
        </div>
      </div>

      <div className='routeContent'>
        <div className='routeSummary'>
          <div className='sumContent'>
            <div className='totalTime'>
              <div className='textDuration'>{route?.duration?.text}</div>
              <div className='textDistance'>{` (${textDistance}) `}</div>
            </div>
            {
              route?.summary &&
              <div className='milestone'>
                {Resource.common.vie + ` ${route?.summary} `}
              </div>
            }
          </div>

          <TooltipV2 description={Resource.common.share} anchor='bottom'>
            <div className='shareIcon' onClick={handleShareDirection}>
              {SHARE_ICON}
            </div>
          </TooltipV2>

        </div>

        <div className='routeDetail'>
          {
            route?.legs?.map((leg, index) => {
              let start = points[index]
              let end = points[index + 1]
              let startName = getPointLabelName(start)
              let endName = getPointLabelName(end)
              let startAddress = start?.address
              let endAddress = end?.address
              let isLegActive = indexLegActive == index

              return (
                <div className='routeItem' key={index}>
                  <div className='fromPoint'>
                    <div className='name'>{startName}</div>
                    {
                      startAddress &&
                      <div className='address'>{startAddress}</div>
                    }
                  </div>

                  <div className='totalTime'>
                    <div className='texTotalTime'>{leg?.duration?.text}</div>
                    <div className='lineHorizontal'></div>
                  </div>

                  <div className='listStep'>
                    {
                      leg?.steps?.map((step, idx) => {
                        let duration = step?.duration?.text
                        let distance = step?.distance?.value
                        let textDistance = DirectionTool.convertDistanceLocalize(distance, languageState)
                        let instruction = step?.htmlInstructions
                        let description = duration ? `${textDistance} (${duration})` : `${textDistance}`
                        let isStepActive = indexStepActive == idx

                        return (
                          <div
                            onClick={onClickStep(index, idx, step)}
                            className={StringTool.mergeClassName('stepItem', (isStepActive && isLegActive) ? 'stepActive' : '')}
                            key={idx}
                          >
                            <div className='maneuverIcon'>
                              <img src={ICON_MANEUVER[step?.maneuver]} width={18} height={18} alt='icon maneuver' />
                            </div>
                            <div className='stepDetail'>
                              <div className='textDirection'>
                                {instruction}
                              </div>
                              <div className='durationDetail'>
                                <div className='text'>
                                  {description}
                                </div>
                                <div className='lineHorizontal'></div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                    (index == route?.legs?.length - 1) &&
                    <div className='toPoint'>
                      <div className='name'>{endName}</div>
                      {
                        endAddress &&
                        <div className='address'>{endAddress}</div>
                      }
                    </div>
                  }
                </div>
              )
            })
          }

        </div>
      </div>

    </div >
  )
};
RouteDetailV2.propTypes = {
  route: PropTypes.any,
  points: PropTypes.array,
  onBack: PropTypes.func,
  vehicle: PropTypes.string
};
export default RouteDetailV2;
