import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import "./textView.scss"
import { ThemeContext } from '../../context';

const TextView = ({ children, noWrap, paragraph, variant, color, ellipsis, title, style }) => {
  const theme = useContext(ThemeContext)
  return (
    <span title={title || null} style={{ color: color || theme.textView.color, ...style}} className={'textView textView-' + `${variant ? variant + ' ' : 'body1 '}${noWrap ? 'noWrap ' : ''}${ellipsis ? 'ellipsis ' : ''}${paragraph ? 'paragraph ' : ''}`}>
      {children}
    </span>
  )
}

TextView.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['h1', 'h2', 'h3', 'body2', 'menu', 'body1', 'caption']),
  ]),

  color: PropTypes.string,
  noWrap: PropTypes.bool,
  paragraph: PropTypes.bool,
  children: PropTypes.node,
  ellipsis: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object
}

export default TextView;
