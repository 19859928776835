import { Box, makeStyles } from '@material-ui/core';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { CurrentHeightDraggableBarState, DraggingDraggableBarState, FinalHeightDraggableBarState } from '../../appState';
import { CommonConfig } from '../../config';

const overDrag = 20
const transitionDuration = 300
const overMap = 8

const useStyles = makeStyles({
  borderShadow: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    boxShadow: '0 0 20px rgb(0 0 0 / 30%)'
  }
})
const isScrollableY = (ele) => {
  // Compare the height to see if the element has scrollable content
  if (ele && ele.nodeType === Node.ELEMENT_NODE) {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

    // It's not enough because the element's `overflow-y` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
  }
  else {
    return false
  }

};
const isScrollableX = (ele) => {
  // Compare the height to see if the element has scrollable content
  if (ele && ele.nodeType === Node.ELEMENT_NODE) {
    const hasScrollableContent = ele.scrollWidth > ele.clientWidth;

    // It's not enough because the element's `overflow-y` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowXStyle = window.getComputedStyle(ele).overflowX;
    const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
  }
  else {
    return false
  }

};
const isScrolling = (element, dx, dy) => {
  if (!element) {
    return false
  }
  else {
    let absDy = Math.abs(dy)
    let absDx = Math.abs(dx)
    return (
      (
        absDy > absDx &&
        element.scrollHeight > element.clientHeight &&
        (
          (element.scrollTop < (element.scrollHeight - element.offsetHeight) && dy < 0) ||
          (element.scrollTop > 0 && dy > 0)
        )
      ) ||
      (
        absDx > absDy &&
        element.scrollWidth > element.clientWidth &&
        (
          (element.scrollLeft < (element.scrollWidth - element.offsetWidth) && dx < 0) ||
          (element.scrollLeft > 0 && dx > 0)
        )
      )
    )
  }

}

function MobileDraggableBar(props, ref) {

  const classes = useStyles()

  const [virtualHeight, setVirtualHeight] = useState(CommonConfig.mobile.draggableBar.minHeight.home)

  const touchPositionTopStartRef = useRef(0)
  const heightStartRef = useRef(0)
  const minHeightRef = useRef(CommonConfig.mobile.draggableBar.minHeight.home)
  const middleHeightRef = useRef(CommonConfig.mobile.draggableBar.middleHeight.home)
  const maxHeightRef = useRef(CommonConfig.mobile.draggableBar.middleHeight.home)

  const isDraggingRef = useRef(false)
  const newHeightRef = useRef(CommonConfig.mobile.draggableBar.minHeight.home)
  const heightRef = useRef(CommonConfig.mobile.draggableBar.minHeight.home)
  const isDraggableRef = useRef(true)

  const isScrollingRef = useRef(false)
  const scrollableTarget = useRef()
  const currentTouchRef = useRef()

  const resizeDetector = useResizeDetector()

  const dragRef = resizeDetector.ref

  const [currentHeightDraggableBar, setCurrentHeightDraggableBar] = useRecoilState(CurrentHeightDraggableBarState)

  const setDraggingDraggableBarState = useSetRecoilState(DraggingDraggableBarState)
  const setFinalHeightDraggableBarState = useSetRecoilState(FinalHeightDraggableBarState)

  const getScrollParent = useCallback((node) => {
    if (node == null || node == dragRef.current) {
      return null;
    }

    if (isScrollableX(node) || isScrollableY(node)) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }, [])

  const onTouchStart = useCallback((e) => {
    if (e?.touches?.length == 1) {
      scrollableTarget.current = getScrollParent(e.target)
      let touch = e.touches[0]
      touchPositionTopStartRef.current = touch.clientY
      currentTouchRef.current = {
        x: touch.clientX,
        y: touch.clientY
      }
      heightStartRef.current = heightRef.current
    }
  }, []);
  const onTouchMove = useCallback((e) => {

    if (isDraggableRef.current) {
      if (e?.touches?.length == 1) {
        let touch = e.touches[0]
        let dx = touch.clientX - currentTouchRef.current.x
        let dy = touch.clientY - currentTouchRef.current.y
        if (!isScrollingRef.current && !isDraggingRef.current) {
          isScrollingRef.current = isScrolling(scrollableTarget.current, dx, dy)
        }
        if (!isScrollingRef.current || isDraggingRef.current) {
          setDraggingDraggableBarState(true)
          isDraggingRef.current = true
          let newHeight = heightStartRef.current + touchPositionTopStartRef.current - touch.clientY
          if (newHeight > maxHeightRef.current) {
            newHeight = maxHeightRef.current
          }
          if (newHeight < minHeightRef.current) {
            newHeight = minHeightRef.current
          }
          if (
            minHeightRef.current <= heightStartRef.current
            && heightStartRef.current < middleHeightRef.current
            && (middleHeightRef.current + overDrag) < newHeight
          ) {
            newHeight = middleHeightRef.current + overDrag
          }
          if (newHeight < newHeightRef.current) {
            setVirtualHeight(0)
          }
          newHeightRef.current = newHeight
          dragRef.current.style.transition = null
          dragRef.current.style.height = newHeight + "px"
        }

      }
    }
  }, []);
  const onTouchEnd = useCallback((e) => {
    if (isDraggingRef.current && isDraggableRef.current) {
      if (e?.changedTouches?.length == 1) {
        let newHeight = newHeightRef.current
        if (
          newHeight < heightStartRef.current &&
          newHeight > middleHeightRef.current
        ) {
          newHeight = middleHeightRef.current
        }

        if (
          newHeight < heightStartRef.current &&
          newHeight < middleHeightRef.current
        ) {
          newHeight = minHeightRef.current
        }
        if (
          newHeight > heightStartRef.current &&
          minHeightRef.current <= heightStartRef.current &&
          heightStartRef.current < middleHeightRef.current
        ) {
          newHeight = middleHeightRef.current
        }
        newHeightRef.current = newHeight
        heightRef.current = newHeight
        dragRef.current.style.transition = `height ${transitionDuration}ms`
        dragRef.current.style.height = newHeight + "px"
        let newVirtualHeight = newHeight || 0
        newVirtualHeight -= overMap
        setVirtualHeight(newVirtualHeight >= 0 ? newVirtualHeight : 0)
        setFinalHeightDraggableBarState(newHeight || 0)
      }
    }
    isDraggingRef.current = false
    isScrollingRef.current = false
    setDraggingDraggableBarState(false)
  }, [])
  const onTransitionEnd = (e) => {
    dragRef.current.style.transition = null
  }

  useImperativeHandle(ref, () => ({
    setHeight: (newHeight) => {
      dragRef.current.style.transition = `height ${transitionDuration}ms`
      dragRef.current.style.height = newHeight + "px"
      heightRef.current = newHeight
      let newVirtualHeight = newHeight || 0
      newVirtualHeight -= overMap
      setVirtualHeight(newVirtualHeight >= 0 ? newVirtualHeight : 0)
      setFinalHeightDraggableBarState(newHeight || 0)
      setCurrentHeightDraggableBar(newHeight || 0)
    },
    setDraggable: (draggable) => {
      isDraggableRef.current = draggable
    },
    setMinHeight: (minHeight) => {
      minHeightRef.current = minHeight
    },
    setMiddleHeight: (middleHeight) => {
      middleHeightRef.current = middleHeight
    },
    setMaxHeight: (maxHeight) => {
      maxHeightRef.current = maxHeight
    }
  }));
  useEffect(() => {
    setCurrentHeightDraggableBar(resizeDetector.height || 0)
  }, [resizeDetector.height])

  return (
    <>
      <Box
        maxHeight={CommonConfig.mobile.draggableBar.middleHeight.home}
        height={virtualHeight + "px"}
      // style={
      //   {
      //     transition: height.animation ? `height ${transitionDuration}ms` : null
      //   }
      // }
      >
      </Box>
      <Box
        ref={resizeDetector.ref}
        overflow="hidden"
        bgcolor="background.paper"
        className={currentHeightDraggableBar < maxHeightRef.current ? classes.borderShadow : ''}
        onTransitionEnd={onTransitionEnd}
        bottom={0}
        left={0}
        position="absolute"
        width="100%"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        zIndex={100}
      // onMouseMove={testMouseMove}
      >
        {props.children}
      </Box>
    </>
  )
}
MobileDraggableBar = forwardRef(MobileDraggableBar)
MobileDraggableBar.propTypes = {
  // ref: PropTypes.f
};
export default MobileDraggableBar;
