import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SvgIconConfig } from '../../config';
import { VehicleEnum } from '../../enum';
import { Resource } from '../../resource';
import { StringTool, getObjTimeRound } from '../../tool';
import TooltipV2 from '../tooltipV2/tooltipV2';
import "./vehicleSelect.scss";

const VehicleSelect = (props) => {

  const vehicleOptions = [
    {
      name: Resource.vehicle.car,
      value: VehicleEnum.car,
      icon: SvgIconConfig.direction.car,
      activeIcon: SvgIconConfig.direction.carActive,
    },
    {
      name: Resource.vehicle.motorbike,
      value: VehicleEnum.motorbike,
      icon: SvgIconConfig.direction.motorbike,
      activeIcon: SvgIconConfig.direction.motorbikeActive,
    },
    {
      name: Resource.vehicle.bicycle,
      value: VehicleEnum.bicycle,
      icon: SvgIconConfig.direction.bicycle,
      activeIcon: SvgIconConfig.direction.bicycleActive
    },
    {
      name: Resource.vehicle.walk,
      value: VehicleEnum.walk,
      icon: SvgIconConfig.direction.walk,
      activeIcon: SvgIconConfig.direction.walkActive,
    }
  ]

  const displayTextTime = (timeObject) => {
    if (timeObject) {
      let text = ''
      if (timeObject?.isDays) {
        text = `${timeObject?.days} ${Resource.common.day?.toLocaleLowerCase()}`
        if (timeObject?.hour > 0) {
          text += ` ${timeObject?.hour} ${Resource.common.hour?.toLocaleLowerCase()}`
        }
      }
      else if (timeObject?.isHour) {
        text = `${timeObject?.hour} ${Resource.common.hour?.toLocaleLowerCase()}`
        if (timeObject?.minutes > 0) {
          text += ` ${timeObject?.minutes} ${Resource.common.minutes?.toLocaleLowerCase()}`
        }
      }
      else if (timeObject?.isMinutes) {
        text = `${timeObject?.minutes} ${Resource.common.minutes?.toLocaleLowerCase()}`
      }
      else {
        text = `${timeObject?.seconds} ${Resource.common.seconds?.toLocaleLowerCase()}`
      }
      return text
    }
  }

  const { onChange, value, durationByVehicles } = props

  const [stateValue, setStateValue] = useState(VehicleEnum.car)
  const [optionState, setOptionState] = useState(vehicleOptions)

  useEffect(() => {
    setStateValue(value)
  }, [value])

  useEffect(() => {
    if (durationByVehicles?.length > 0) {
      let distVehicleCus = {}
      durationByVehicles?.map((dataWithVehicle, index) => {
        distVehicleCus[dataWithVehicle?.mode?.toLocaleLowerCase()] = dataWithVehicle?.duration
      })

      let option = vehicleOptions?.map((item) => {
        let info = distVehicleCus[item?.value]
        let timeObj = getObjTimeRound(info?.value)
        return {
          ...item,
          timeDuration: timeObj,
          unAvailable: !(info?.value)
        }
      })
      setOptionState(option)
    }
    else {
      setOptionState(vehicleOptions)
    }
  }, [durationByVehicles])

  const onClickVehicle = (option) => (e) => {
    if (!option?.unAvailable) {
      onChange && onChange(option?.value)
      setStateValue(option?.value)
    }
  }
  return (
    <div className='vehicleSelect'>
      {
        optionState?.map((option) => {
          let active = option.value == stateValue
          let unAvailable = option?.unAvailable
          let description = unAvailable ? `${option.name} ${Resource.message.notAvailable?.toLocaleLowerCase()}` : option?.name
          let caption = displayTextTime(option?.timeDuration)
          return (
            <div
              key={option.value}
              className={StringTool.mergeClassName('vehicleItem', active && "active", unAvailable && 'unAvailable')}
              onClick={onClickVehicle(option)}
            >
              <TooltipV2
                description={description}
                anchor={"bottom"}
                isWap
              >
                <button className='vehicleBtn'>
                  {active ? option.activeIcon : option.icon}
                </button>
              </TooltipV2>

              <div className='vehicleCaption'>
                {
                  (caption && !unAvailable) ? // text time nếu có
                    caption
                    :
                    null
                }
              </div>


            </div>


          )
        })
      }
    </div>
  )
};

VehicleSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  durationByVehicles: PropTypes.array
};

export default VehicleSelect;
