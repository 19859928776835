import React, { useEffect, useRef, useState } from "react";
import { StringTool } from "../../tool";
import TextInput, { TextInputProps } from "../textInput/textInput";
import styles from "./monthPicker.css";

export interface MonthPickerProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value?: any,
  label?: any,
  onChange: (event: any) => void,
  onBlur?: (event: any) => void,
  onClick?: (event: any) => void,
  className?: string,
  inputProps?: TextInputProps
}

const REGEX_NUMBER = /^\d+$/
const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const createCurrentTime = () => {
  let currentDate = new Date()
  let month = currentDate?.getMonth() + 1
  let year = currentDate?.getFullYear()
  return {
    month: month < 10 ? `0${month}` : month,
    year: year,
  }
}
const createValueTime = (time: any) => {
  let arrTime = time?.split("/")
  let month = arrTime[0]
  let year = arrTime[1]
  return {
    month: (month?.length < 2) ? `0${month}` : month,
    year: year
  }
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  onClick,
  value,
  onChange,
  onBlur,
  className,
  ...props
}) => {

  const [open, setOpen] = useState(false)
  const [stateValue, setStateValue] = useState(() => {
    let currentTime = createCurrentTime()
    if (value) {
      return `${value?.month}/${value?.year}`
    }
    else return `${currentTime?.month}/${currentTime?.year}`
  })
  const [loadingEnd, setLoadingEnd] = useState(false)
  const [data, setData] = useState(() => {
    let currentTime = createCurrentTime()
    let list = []
    for (let i = currentTime.year - 100; i <= currentTime.year + 100; i++) list.push(i)
    return {
      listYear: list
    }
  })
  const [isShow, setIsShow] = useState(false)
  const [timeValue, setTimeValue] = useState(() => {
    let currentTime = createCurrentTime()
    return {
      month: currentTime?.month,
      year: currentTime?.year
    }
  })
  const [picking, setPicking] = useState(false)
  const [classNameCustom, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.timeInputCpn, className as string)
  })
  const showCurrentYear = () => {
    const e = document.getElementById(`${timeValue?.year}`)
    e?.scrollIntoView()
  }
  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.timeInputCpn, className as string))
  }, [className])

  useEffect(() => {
    if (value) {
      let valueTime = createValueTime(value)
      if (valueTime?.month?.match(REGEX_NUMBER) && valueTime?.year?.match(REGEX_NUMBER)) {
        let valueCustom = `${valueTime?.month}/${valueTime?.year}`
        setTimeValue({
          month: valueTime?.month,
          year: valueTime?.year
        })
        setStateValue(valueCustom)
      }
    }
    else {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          month: currentTime?.month,
          year: currentTime?.year
        }
      })
    }
  }, [value])
  const onClickInput = () => {
    setOpen(true)
    setLoadingEnd(false)
  }
  useEffect(() => {
    showCurrentYear()
  }, [isShow, open])

  const onSave = () => {
    let value = `${timeValue?.month}/${timeValue?.year}`
    setStateValue(value)
    setOpen(false)
    onChange && onChange(value)
  }

  const onCancel = (e: any) => {
    setTimeout(() => {
      setOpen(false)
    }, 300)
    setLoadingEnd(true)
  }
  return (
    <div className={classNameCustom}>
      <TextInput
        {...props}
        value={stateValue}
        readOnly
        onClick={onClickInput}
        label={props?.label}
        suffix={
          <svg id="Component_785_5" width="24" height="24" viewBox="0 0 24 24">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_15466" data-name="Rectangle 15466" width="24" height="24" transform="translate(167 248)" fill="#fff" stroke="#707070" stroke-width="1" />
              </clipPath>
            </defs>
            <g id="Mask_Group_629" data-name="Mask Group 629" transform="translate(-167 -248)" clip-path="url(#clip-path)">
              <g id="Group_43933" data-name="Group 43933" transform="translate(167.75 248.75)">
                <path id="Path_11972" data-name="Path 11972" d="M8,5.75A.755.755,0,0,1,7.25,5V2a.75.75,0,0,1,1.5,0V5A.755.755,0,0,1,8,5.75Z" transform="translate(-0.417)" fill="#869195" />
                <path id="Path_11973" data-name="Path 11973" d="M16,5.75A.755.755,0,0,1,15.25,5V2a.75.75,0,0,1,1.5,0V5A.755.755,0,0,1,16,5.75Z" transform="translate(-1.083)" fill="#869195" />
                <path id="Path_11974" data-name="Path 11974" d="M8.5,14.5a1,1,0,0,1-.38-.08.96.96,0,0,1-.62-.92,1,1,0,0,1,.08-.38,1.155,1.155,0,0,1,.21-.33,1.032,1.032,0,0,1,.33-.21,1.021,1.021,0,0,1,1.09.21,1.052,1.052,0,0,1,.29.71,1.5,1.5,0,0,1-.02.2.636.636,0,0,1-.06.18.757.757,0,0,1-.09.18,1.576,1.576,0,0,1-.12.15A1.052,1.052,0,0,1,8.5,14.5Z" transform="translate(-0.45 -0.866)" fill="#869195" />
                <path id="Path_11975" data-name="Path 11975" d="M12,14.5a1,1,0,0,1-.38-.08A.96.96,0,0,1,11,13.5a1,1,0,0,1,.08-.38,1.155,1.155,0,0,1,.21-.33,1.032,1.032,0,0,1,.33-.21,1,1,0,0,1,1.09.21,1.052,1.052,0,0,1,.29.71,1.5,1.5,0,0,1-.02.2.636.636,0,0,1-.06.18.757.757,0,0,1-.09.18,1.576,1.576,0,0,1-.12.15A1.052,1.052,0,0,1,12,14.5Z" transform="translate(-0.75 -0.865)" fill="#869195" />
                <path id="Path_11976" data-name="Path 11976" d="M15.5,14.5a1,1,0,0,1-.38-.08,1.032,1.032,0,0,1-.33-.21l-.12-.15a.757.757,0,0,1-.09-.18.636.636,0,0,1-.06-.18,1.5,1.5,0,0,1-.02-.2,1.052,1.052,0,0,1,.29-.71,1.032,1.032,0,0,1,.33-.21,1,1,0,0,1,1.09.21,1.052,1.052,0,0,1,.29.71,1.5,1.5,0,0,1-.02.2.636.636,0,0,1-.06.18.757.757,0,0,1-.09.18,1.576,1.576,0,0,1-.12.15A1.052,1.052,0,0,1,15.5,14.5Z" transform="translate(-1.05 -0.865)" fill="#869195" />
                <path id="Path_11977" data-name="Path 11977" d="M8.5,18a1,1,0,0,1-.38-.08,1.155,1.155,0,0,1-.33-.21A1.052,1.052,0,0,1,7.5,17a1,1,0,0,1,.08-.38.933.933,0,0,1,.21-.33A1,1,0,1,1,8.5,18Z" transform="translate(-0.45 -1.135)" fill="#869195" />
                <path id="Path_11978" data-name="Path 11978" d="M12,18a.99.99,0,0,1-1-1,1,1,0,0,1,.08-.38.933.933,0,0,1,.21-.33,1.047,1.047,0,0,1,1.42,0,.933.933,0,0,1,.21.33A1,1,0,0,1,13,17a.99.99,0,0,1-1,1Z" transform="translate(-0.75 -1.135)" fill="#869195" />
                <path id="Path_11979" data-name="Path 11979" d="M15.5,18a1.052,1.052,0,0,1-.71-.29.933.933,0,0,1-.21-.33.942.942,0,0,1,0-.76.933.933,0,0,1,.21-.33,1,1,0,0,1,.9-.27.6.6,0,0,1,.19.06.757.757,0,0,1,.18.09,1.575,1.575,0,0,1,.15.12A1,1,0,0,1,15.5,18Z" transform="translate(-1.05 -1.135)" fill="#869195" />
                <path id="Path_11980" data-name="Path 11980" d="M19.061,9.84H3.439a.753.753,0,0,1,0-1.5H19.061a.753.753,0,0,1,0,1.5Z" transform="translate(0 -0.532)" fill="#869195" />
                <path id="Path_11981" data-name="Path 11981" d="M14.942,21.25H7.558c-3.369,0-5.308-1.943-5.308-5.319V8.069c0-3.376,1.938-5.319,5.308-5.319h7.385c3.369,0,5.308,1.943,5.308,5.319v7.863C20.25,19.307,18.312,21.25,14.942,21.25ZM7.558,4.138c-2.64,0-3.923,1.286-3.923,3.931v7.863c0,2.645,1.283,3.931,3.923,3.931h7.385c2.64,0,3.923-1.286,3.923-3.931V8.069c0-2.646-1.283-3.931-3.923-3.931Z" fill="#869195" />
              </g>
            </g>
          </svg>
        }
      />
      {
        open &&
        <div className={StringTool.mergeClassName(styles.selectTime, loadingEnd ? styles.hideAnimation : '')}>
          <div className={styles.backdrop}></div>
          <div className={styles.selectTimePopup}>
            <div id={styles.selectTimeContainer} className={styles.selectTimeContainer} style={{top: '25vh'}}>
              <div className={styles.year}>
                <div
                  className={styles.listYear}>
                  <div style={{ display: 'flex' }}>
                    <div
                      className={styles.currentYear}
                    >
                      {timeValue.year}
                    </div>
                    <div>
                      {
                        isShow ?
                          <div
                            className={styles.arrow}
                            onClick={() => { setIsShow(!isShow) }}
                          >
                            <svg id="Component_758_7" data-name="Component 758 – 7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <path id="Path_2780" data-name="Path 2780" d="M0,24H24V0H0Z" fill="none" />
                              <path id="Path_2781" data-name="Path 2781" d="M7.41,16,12,11.42,16.59,16,18,14.59l-6-6-6,6Z" transform="translate(0 -0.59)" fill="#869195" />
                            </svg>
                          </div>
                          :
                          <div
                            className={styles.arrow}
                            onClick={() => {
                              setIsShow(!isShow)
                            }}
                          >
                            <svg id="Component_2" data-name="Component 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <path id="Path_2780" data-name="Path 2780" d="M0,0H24V24H0Z" fill="none" />
                              <path id="Path_2781" data-name="Path 2781" d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z" fill="#869195" />
                            </svg>
                          </div>

                      }
                    </div>
                  </div>
                  <div>
                    {isShow &&
                      <div className={styles.optionsYear}>
                        {
                          data?.listYear?.map(value =>
                            <div
                              id={`${value}`}
                              className={styles.optionYear}
                              style={value == timeValue.year ? { backgroundColor: '#1C75BC', color: '#FFFFFF' } : {}}
                              key={value}
                              onClick={() => {
                                setTimeValue(prev => ({
                                  ...prev,
                                  year: value
                                }))
                                setIsShow(!isShow)
                              }}
                            >{value}</div>
                          )
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.month}>
                {
                  listMonth.map(value =>
                    timeValue?.month == value ?
                      <button
                        className={styles.optionMonth + ' active'}
                        key={value}
                        style={{ background: '#1C75BC', color: '#FFFFFF' }}
                        onClick={() => {
                          setTimeValue(prev => ({
                            ...prev,
                            month: value
                          }))
                        }}
                      >
                        Tháng {value}
                      </button> :
                      <button
                        key={value}
                        className={styles.optionMonth}
                        onClick={() => {
                          setTimeValue(prev => ({
                            ...prev,
                            month: value
                          }))
                        }}
                      >
                        Tháng {value}
                      </button>
                  )
                }
              </div>
              <div className={styles.action}>
                <button onClick={onCancel} className={StringTool.mergeClassName(styles.button)}>
                  Đóng
                </button>
                <button onClick={onSave} className={StringTool.mergeClassName(styles.button, styles.primary)}>
                  Lưu
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );

}

export default MonthPicker;
