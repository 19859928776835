import { AppData } from "../data"

export class MapTool {
    static moveToLocation = (loc) => {
        let camera = AppData.map?.getCamera()
        camera?.setTarget(loc)
        AppData.map?.moveCamera(camera, { animate: true })
    }

    static moveWithPadding = (map, target, padding = { top: 0, left: 0, right: 0, bottom: 0 }, option) => {

        const { left = 0, top = 0, right = 0, bottom = 0 } = padding
        let dx = (left - right) / 2
        let dy = (bottom - top) / 2
        let projection = new map4d.Projection(map)
        let camera = map?.getCamera()
        let center = camera?.getTarget()
        let centerScreen = projection.fromLatLngToScreen(center)
        let newCenterScreen = {
            x: centerScreen.x - dx,
            y: centerScreen.y + dy
        }
        let newCenter = projection.fromScreenToLatLng(newCenterScreen)
        let symmetry = {
            lat: center.lat + center.lat - newCenter.lat,
            lng: center.lng + center.lng - newCenter.lng
        }

        let coordinateTransformer = new map4d.CoordinateTransformer([
            newCenter,
            symmetry
        ])
        let translates = coordinateTransformer.translate(target)

        camera.setTarget(translates?.[0])
        map?.moveCamera(camera, option)
    }
}