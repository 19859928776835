import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AlertTypeEnum } from '../../enum';
import './alert.scss';

const ICON_SUCCESS = <svg id="Component_101_12" data-name="Component 101 – 12" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_4067" data-name="Rectangle 4067" width="24" height="24" transform="translate(2090 113)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
    </defs>
    <g id="Mask_Group_49" data-name="Mask Group 49" transform="translate(-2090 -113)" clipPath="url(#clip-path)">
        <path id="Path_8153" data-name="Path 8153" d="M12,2A10,10,0,1,0,22,12,10.016,10.016,0,0,0,12,2Zm4.78,7.7-5.67,5.67a.748.748,0,0,1-1.06,0L7.22,12.54a.75.75,0,0,1,1.06-1.06l2.3,2.3,5.14-5.14A.75.75,0,1,1,16.78,9.7Z" transform="translate(2090 113)" fill="#fff" />
    </g>
</svg>

const ICON_WARNING = <svg id="Component_100_8" data-name="Component 100 – 8" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_4066" data-name="Rectangle 4066" width="24" height="24" transform="translate(2091 78)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
    </defs>
    <g id="Mask_Group_48" data-name="Mask Group 48" transform="translate(-2091 -78)" clipPath="url(#clip-path)">
        <path id="Path_8151" data-name="Path 8151" d="M21.76,15.92,15.36,4.4A3.987,3.987,0,0,0,12,2,3.987,3.987,0,0,0,8.64,4.4L2.24,15.92a3.96,3.96,0,0,0-.25,3.99A3.936,3.936,0,0,0,5.6,21.63H18.4a3.936,3.936,0,0,0,3.61-1.72A3.959,3.959,0,0,0,21.76,15.92ZM11.25,9a.75.75,0,0,1,1.5,0v5a.75.75,0,0,1-1.5,0Zm1.46,8.71-.15.12a.757.757,0,0,1-.18.09.6.6,0,0,1-.19.06A1.225,1.225,0,0,1,12,18a1.5,1.5,0,0,1-.2-.02.636.636,0,0,1-.18-.06.757.757,0,0,1-.18-.09l-.15-.12a1.014,1.014,0,0,1,0-1.42l.15-.12a.757.757,0,0,1,.18-.09.636.636,0,0,1,.18-.06.856.856,0,0,1,.39,0,.6.6,0,0,1,.19.06.757.757,0,0,1,.18.09l.15.12a1.014,1.014,0,0,1,0,1.42Z" transform="translate(2091.44 78)" fill="#fff" />
    </g>
</svg>

const ICON_ERROR = <svg id="Component_87_2" data-name="Component 87 – 2" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_4065" data-name="Rectangle 4065" width="24" height="24" transform="translate(1985 35)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
    </defs>
    <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(-1985 -35)" clipPath="url(#clip-path)">
        <path id="Path_8141" data-name="Path 8141" d="M12,2A10,10,0,1,0,22,12,10.016,10.016,0,0,0,12,2Zm-.75,6a.75.75,0,0,1,1.5,0v5a.75.75,0,0,1-1.5,0Zm1.67,8.38a1.032,1.032,0,0,1-.21.33,1.155,1.155,0,0,1-.33.21.943.943,0,0,1-.76,0,1.155,1.155,0,0,1-.33-.21,1.032,1.032,0,0,1-.21-.33.942.942,0,0,1,0-.76.9.9,0,0,1,.54-.54,1,1,0,0,1,.76,0,.9.9,0,0,1,.54.54.942.942,0,0,1,0,.76Z" transform="translate(1985 35)" fill="#fff" />
    </g>
</svg>

const ICON_INFORMATION = <svg id="Component_103_3" data-name="Component 103 – 3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_4065" data-name="Rectangle 4065" width="24" height="24" transform="translate(1985 35)" fill="#fff" stroke="#707070" strokeWidth="1" />
        </clipPath>
    </defs>
    <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(-1985 -35)" clipPath="url(#clip-path)">
        <path id="Path_8141" data-name="Path 8141" d="M12,22A10,10,0,1,1,22,12,10.016,10.016,0,0,1,12,22Zm-.75-6a.75.75,0,0,0,1.5,0V11a.75.75,0,0,0-1.5,0Zm1.67-8.38a1.032,1.032,0,0,0-.21-.33,1.155,1.155,0,0,0-.33-.21.943.943,0,0,0-.76,0,1.155,1.155,0,0,0-.33.21,1.032,1.032,0,0,0-.21.33.942.942,0,0,0,0,.76,1.155,1.155,0,0,0,.21.33,1.155,1.155,0,0,0,.33.21,1,1,0,0,0,.76,0,1.155,1.155,0,0,0,.33-.21,1.155,1.155,0,0,0,.21-.33.942.942,0,0,0,0-.76Z" transform="translate(1985 35)" fill="#fff" />
    </g>
</svg>

const Alert = ({ type, timeAutoClose, onClose, message, open }) => {
    const [openState, setOpenState] = useState(false)

    useEffect(() => {
        setOpenState(open)
    }, [open])

    useEffect(() => {
        const handleAlert = () => {
            if (openState) {
                setOpenState(false)
                onClose && onClose()
            }
        }
        const timeoutID = window.setTimeout(handleAlert, timeAutoClose ? timeAutoClose : 2000)
        return () => window.clearTimeout(timeoutID)
    }, [timeAutoClose, openState])

    const IconEnum = {
        [AlertTypeEnum.success]: ICON_SUCCESS,
        [AlertTypeEnum.error]: ICON_ERROR,
        [AlertTypeEnum.warning]: ICON_WARNING,
        [AlertTypeEnum.info]: ICON_INFORMATION,
    }
    return (
        <div className='map4dAlertRoot'>
            {
                openState &&
                <div className={`map4dAlert map4dAlert_${type}`}>
                    <div className={`map4dAlert_icon`}>
                        {
                            IconEnum[type]
                        }
                    </div>
                    <div className={`map4dAlert_text map4dAlert_text--${type}`}>
                        {message}
                    </div>
                    <div className='hiden' />
                </div>
            }
        </div>
    );
};
Alert.propTypes = {
    type: PropTypes.oneOf(['error', 'warning', "info", "success"]).isRequired,
    message: PropTypes.string.isRequired,
    timeAutoClose: PropTypes.number,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
export default Alert;