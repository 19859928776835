import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MobileShowMediaGallery from '../../../components/mobileShowMediaGallery/mobileShowMediaGallery';
import { MediaTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { StringTool } from '../../../tool';
import './mobileTabListMedia.scss';

const ICON_BACK =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#505050'>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
  </svg>

function MobileTabListMedia(props, ref) {

  const _menuTabHtml = useRef()
  const contentHtml = useRef()
  const _isChange = useRef()

  const [title, setTitle] = useState('')

  const [photos, setPhotos] = useState([])
  const [videos, setVideos] = useState([])
  const [allMedia, setAllMedia] = useState([])

  const [currentTab, setCurrentTab] = useState(0)
  const [indexSelect, setIndexSelect] = useState(0)
  const [showMedia, setShowMedia] = useState(false)
  const [showGallery, setShowGallery] = useState(false)

  useImperativeHandle(ref, () => ({
    showMediaWithVideos: (show, photosData, videosData, title) => {
      setShowMedia(show)
      setPhotos(photosData)
      setVideos(videosData)
      setTitle(title)
      setCurrentTab(1)
    },
    showMediaWithPhotos: (show, photosData, videosData, title) => {
      setShowMedia(show)
      setPhotos(photosData)
      setVideos(videosData)
      setTitle(title)
      setCurrentTab(0)
    },
    showMedia: (allMedia, type, title) => {
      let video = allMedia?.filter((item) => { return item?.typeFile == MediaTypeEnum.video })
      let photo = allMedia?.filter((item) => { return item?.typeFile == MediaTypeEnum.image })
      setAllMedia(allMedia)
      setPhotos(photo)
      setVideos(video)
      setShowMedia(true)
      setTitle(title)
      setCurrentTab(type == MediaTypeEnum.image ? 1 : (type == MediaTypeEnum.video ? 2 : 0))
    }
  }))

  const handleChangeTab = (indexTab) => (e) => {
    setIndexSelect(0)
    setCurrentTab(indexTab)
  }

  const handleOnClickItemMedia = (index) => (e) => {
    setIndexSelect(index)
    setShowGallery(true)
  }

  const handleOnBackDetail = () => {
    setShowMedia(false)
  }

  const handleOnBackListMedia = () => {
    setShowGallery(false)
  }

  const onScrollContent = (e) => {
    let scrollTop = e?.currentTarget?.scrollTop
    let offsetHeight = e?.currentTarget?.offsetHeight
    let scrollHeight = e?.currentTarget?.scrollHeight
    console.log(offsetHeight, scrollHeight);
    if ((scrollHeight - offsetHeight) >= 200) {
      if (!_isChange.current) {
        _isChange.current = true
        if (scrollTop > 0) {
          _menuTabHtml.current.style.transition = 'height 0.3s'
          _menuTabHtml.current.style.height = '6rem'
        }
        else {
          _menuTabHtml.current.style.transition = 'none'
          _menuTabHtml.current.style.height = '11.375rem'
        }
      }
      _isChange.current = false
      // setTimeout(() => {
      //   _isChange.current = false
      // }, 200)
    }
  }

  const LabelHeader = {
    0: Resource.common.all,
    1: Resource.common.images,
    2: Resource.common.video
  }

  let dataMediaGallery = (currentTab == 0) ? allMedia : (currentTab == 1) ? photos : videos

  return (
    showMedia &&
    <div className='mobileTabListMediaCpn' onScroll={onScrollContent} >
      <div className='mediaTop'>
        <div className='galleryHeader'>
          <div className='backIcon' onClick={handleOnBackDetail}>
            {ICON_BACK}
          </div>
          <div className='title'>
            {title || ''}
          </div>
        </div>
        <div className='menuTab' ref={_menuTabHtml}>
          {
            allMedia?.length > 0 &&
            <div className={StringTool.mergeClassName('menuItem', (currentTab == 0) ? 'menuSelect' : '')} >
              <div className='container'>
                {
                  allMedia[0]?.typeFile == MediaTypeEnum.image ?
                    <img width='100%' height='100%' src={allMedia[0]?.url} alt={allMedia[0].name} className='itemImg' />
                    :
                    <video width="100%" height='100%' preload='metadata' src={`${allMedia[0]?.url}#t=0.001`} className='itemImg' />
                }
                <div className='backgroundOpacity' onClick={handleChangeTab(0)}>
                  <div className='title'>{Resource.common.all}</div>
                </div>
              </div>
            </div>
          }

          {
            photos?.length > 0 &&
            <div className={StringTool.mergeClassName('menuItem', (currentTab == 1) ? 'menuSelect' : '')} >
              <div className='container'>
                <img width='100%' height='100%' src={photos[0].url} alt={photos[0].name} className='itemImg' />
                <div className='backgroundOpacity' onClick={handleChangeTab(1)}>
                  <div className='title'>{Resource.common.images}</div>
                </div>
              </div>
            </div>
          }

          {
            videos?.length > 0 &&
            <div className={StringTool.mergeClassName('menuItem', (currentTab == 2) ? 'menuSelect' : '')} >
              <div className='container'>
                <video width="100%" height='100%' preload='metadata' src={`${videos[0]?.url}#t=0.001`} className='itemImg' />
                <div className='backgroundOpacity' onClick={handleChangeTab(2)}>
                  <div className='title'>{Resource.common.video}</div>
                </div>
              </div>

            </div>
          }
        </div>
        <div className='header'>
          {LabelHeader[currentTab]}
        </div>
      </div>

      <div className='galleryContent' style={{ position: 'relative' }} ref={contentHtml}>
        {/* <div ref={absoluteHtml} onTouchMove={onTouchMoveContent} onTouchStart={onTouchStartContent} style={{
          position: 'absolute',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: '1000',
          height: '100%',
        }}></div> */}
        <div className='listGallery'>
          {
            currentTab == 0 &&
            <>
              {
                allMedia?.length > 0 ?
                  allMedia?.map((item, index) => {
                    return (
                      <div className='itemMedia' key={index} onClick={handleOnClickItemMedia(index)}>
                        {
                          item?.typeFile == MediaTypeEnum.image ?
                            <img src={item?.url} alt={item?.name} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                            :
                            <video width="100%" height='100%' preload='metadata' src={item?.url + '#t=0.001'} style={{ objectFit: 'cover' }} />
                        }
                      </div>
                    )
                  })
                  :
                  <div className='noData'>
                    {Resource.common.noMediaFiles}
                  </div>
              }
            </>

          }

          {
            (currentTab == 1) &&
            <>
              {
                photos?.length > 0 ?
                  photos?.map((photo, index) => {
                    return (
                      <div className='itemMedia' key={index} onClick={handleOnClickItemMedia(index)}>
                        <img src={photo?.url} alt={photo?.name} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                      </div>
                    )
                  })
                  :
                  <div className='noData'>
                    {Resource.common.noImages}
                  </div>
              }
            </>
          }

          {
            (currentTab == 2) &&
            <>
              {
                videos?.length > 0 ?
                  videos?.map((video, index) => (
                    <div className='itemMedia' key={index}>
                      <video width="100%" height='100%' controls preload='metadata' src={video?.url + '#t=0.001'} style={{ objectFit: 'cover' }} />
                      <div className='itemVideoAbsolute' onClick={handleOnClickItemMedia(index)}></div>
                    </div>
                  ))
                  :
                  <div className='noData'>
                    {Resource.common.noVideos}
                  </div>
              }
            </>
          }
        </div>
      </div>
      {
        showGallery &&
        <div className='galleryMediaFiles'>
          <MobileShowMediaGallery
            mediaFiles={dataMediaGallery}
            indexSelect={indexSelect}
            onBack={handleOnBackListMedia}
          />
        </div>
      }
    </div>
  )
}

MobileTabListMedia = forwardRef(MobileTabListMedia)
MobileTabListMedia.propTypes = {
  //
};

export default MobileTabListMedia;
