export const PlaceExtensionTypeEnum = Object.freeze({
    article: "article",
    vr360: "vr360",
    link: "link",
    image: "image",
    video: "video",
    popup: "popup",
    none: "none",
    videoLink : "videoLink",
    videoEmbed : "videoEmbed",
    photo:"photo",
    none:'none'
})