import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BusinessHourTool } from '../../tool';
import { Resource } from '../../resource';
import { Typography } from '@material-ui/core';


const TIME_ADS = 60
const BusinessHoursStatus = (props) => {

  const { value } = props
  const [currentTime, setCurrentTime] = useState(BusinessHourTool.getCurrentDate);

  const status = [
    {
      curr: Resource.common.datetime_24h,
      des: '',
      color: '#059000'
    },
    {
      curr: Resource.common.opening,
      des: ' - ' + `${Resource.businessHours.closedAt} `,
      color: '#059000'
    },
    {
      curr: Resource.businessHours.openingSoon,
      des: ' - ' + `${Resource.businessHours.openAt} `,
      color: '#FF6207'
    },
    {
      curr: Resource.businessHours.closed,
      des: ' - ' + `${Resource.businessHours.openAt} `,
      color: '#D93535'
    },
    {
      curr: Resource.businessHours.closingSoon,
      des: ' - ' + `${Resource.businessHours.closedAt} `,
      color: '#FF6207'
    },
    {
      curr: "",
      des: "",
      color: '#727272'
    },
  ]

  const dayLabel = {
    0: Resource.common.sunday,
    1: Resource.common.monday,
    2: Resource.common.tuesday,
    3: Resource.common.wednesday,
    4: Resource.common.thursday,
    5: Resource.common.friday,
    6: Resource.common.saturday
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(BusinessHourTool.getCurrentDate());
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  let index
  let time = ''

  let currentTimer = {
    day: currentTime?.day,
    time: currentTime?.time
  }

  let currentMinutes = currentTime.hours * 60 + currentTime.minutes

  // tìm những time có khả năng chứa current time
  let arr = value?.filter((time) => (time?.open?.day == currentTime.day || time?.close?.day == currentTime.day)) || []

  let isOpen24h = false

  //kiểm tra hiện tại có đang hoạt động không
  let openTime = arr?.find(time => {
    //mở của 24h
    if (time?.open?.day == currentTime.day && time?.open?.time == "0000" && (!time?.close?.day || time?.close?.time == "0000")) {
      isOpen24h = true
      return true
    }
    //mở cửa bình thường
    else if (
      time?.open?.time && time?.close?.time &&
      (!BusinessHourTool.isBiggerTimer(time?.open, currentTimer))
      && (!BusinessHourTool.isBiggerTimer(currentTimer, time?.close))
    ) {
      return true
    }
    else {
      return false
    }
  })

  if (openTime) {
    // mở cửa cả ngày
    if (isOpen24h) {
      index = 0
    }
    // mở cửa
    else {
      let closeMinutes = parseInt(openTime?.close?.time?.slice(0, 2)) * 60 + parseInt(openTime?.close?.time?.slice(2, 4))
      if (openTime?.close?.day != currentTime?.day) {
        closeMinutes == closeMinutes + 1440
      }
      // sắp đóng cửa - đóng cửa lúc
      if ((closeMinutes - currentMinutes) <= TIME_ADS) {
        index = 4
      }
      // đang mở cửa - đóng cửa lúc
      else {
        index = 1
      }
      time = openTime?.close?.time?.slice(0, 2) + ":" + openTime?.close?.time?.slice(2, 4)
    }
  }
  else { //đóng cửa hoặc đóng cửa
    let currentDay = new Date().getDay()
    let current = value?.find((buss, index) => {
      return buss?.open?.day == currentDay
    })
    if (current) { //đóng cửa
      let nextTime = BusinessHourTool.findNextOpen(currentTime, value)
      //có giờ mở cửa tiếp theo
      if (nextTime) {
        let nextTimeMinutes = parseInt(nextTime?.open?.time?.slice(0, 2)) * 60 + parseInt(nextTime?.open?.time?.slice(2, 4))
        let isNextDay = false
        if (nextTime?.open?.day == BusinessHourTool.nextDay(currentTime.day)) {
          nextTimeMinutes = nextTimeMinutes + 1440
          isNextDay = true
        }
        //sắp mở cửa - mở cửa lúc
        if (((nextTimeMinutes - currentMinutes) <= TIME_ADS) && (nextTime?.open?.day == currentTime.day || isNextDay)) {
          index = 2
        }
        //đóng cửa - mở cửa lúc
        else {
          index = 3
        }
        time = nextTime?.open?.time?.slice(0, 2) + ":" + nextTime?.open?.time?.slice(2, 4) + " " + dayLabel[nextTime?.open?.day]
      }
      //Đóng cửa, không có giờ mở cửa
      else {
        index = 3
      }
    }
    else { //Không có thông tin
      index = 5
    }

  }
  let title = status[index].curr + (time && status[index]?.des + time)
  return (
    value?.length > 0 &&
    <span title={title}>
      <Typography variant='body2' component='span' style={{ color: `${status[index]?.color}` }}>{status[index].curr}</Typography>
      {
        time && <Typography variant='body2' component='span' style={{ color: '#121212' }}>{status[index]?.des + time}</Typography>
      }
    </span>
  )
};

BusinessHoursStatus.propTypes = {
  value: PropTypes.any
};

export default BusinessHoursStatus;
