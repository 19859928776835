import React, { Fragment, useEffect, useState } from 'react';
import PopoverV2 from '../../../components/popoverV2/popoverV2';
import { MobileDirectionMenuEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { useRecoilValue } from 'recoil';
import { CanAddDestinationState, UserState } from '../../../appState';
import { StringTool } from '../../../tool';
import PropTypes from 'prop-types';
import './mobileDirectionMenu.scss'

const MENU_ICON =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-16 -114)">
      <path d="M0,0H24V24H0Z" transform="translate(16 114)" fill="none" />
      <path d="M12,3a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,3Zm0,14a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z" transform="translate(16 114)" fill="#505050" />
    </g>
  </svg>

function MobileDirectionMenu(props) {
  const { onChange, disableDepartAt } = props

  const [isOpen, setIsOpen] = useState(false)
  const [canAddDes, setCanAddDes] = useState()
  const [disDepartAt, setDisDepartAt]= useState(false)

  const canAddDestinationState = useRecoilValue(CanAddDestinationState)

  useEffect(()=>{
    setDisDepartAt (disableDepartAt)
  },[disableDepartAt])

  useEffect(() => {
    setCanAddDes(canAddDestinationState)
  }, [canAddDestinationState])

  const handleOpenPopover = () => {
    setIsOpen(true)
  }

  const handleClosePopover = () => {
    setIsOpen(false)
  }

  const onSelectMenuOption = (value) => (e) => {
    onChange && onChange(value)
    setIsOpen(false)
  }

  const OPTION_MENU = [
    { value: MobileDirectionMenuEnum.addPoi, name: Resource.common.addPointTo },
    { value: MobileDirectionMenuEnum.settingTime, name: Resource.common.settingTime },
    { value: MobileDirectionMenuEnum.moreOption, name: Resource.common.moreOption }
  ]

  return (
    <div className='mobileDirectionMenuCpn'>
      <PopoverV2
        className='mobileDirectionMenuDialog'
        open={isOpen}
        onClose={handleClosePopover}
        onOpen={handleOpenPopover}
        actionButton={
          <div className='menuBtn'>
            {MENU_ICON}
          </div>
        }
      >
        <div className='listMenuOption'>
          {
            OPTION_MENU?.map((itemMenu, idx) => {
              let disableAddPoi = (idx == 0 && !canAddDes)
              let disableDepartAt = (idx == 1 && disDepartAt)
              return (
                <Fragment key={itemMenu?.value}>
                  {
                    (disableAddPoi || disableDepartAt) ?
                      <div className={StringTool.mergeClassName('itemMenu', 'itemMenuDisable')}>
                        {itemMenu?.name}
                      </div>
                      :
                      <div className='itemMenu' onClick={onSelectMenuOption(itemMenu?.value)}>
                        {itemMenu?.name}
                      </div>
                  }
                </Fragment>
              )
            })
          }
        </div>
      </PopoverV2>
    </div>
  )
};

MobileDirectionMenu.propTypes = {
  onChange: PropTypes.func,
  disableDepartAt: PropTypes.bool
}

export default MobileDirectionMenu;
