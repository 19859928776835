import { default as React, useEffect, useState } from "react";
import { TextAreaInput, TextInput, TimeInput, Button, Card, CongratsBanner } from "ui";
import TagInputV2 from "./common/tagInputV2/tagInputV2";
import { Resource } from './../resource';
import AddressInputV2 from './addressInputV2/addressInputV2';
import NearByLocationOptions from "./nearByLocationOptions/nearByLocationOptions";
import DatePickerV2 from './datePickerV2/datePickerV2';
import TimePickerV2 from './timePickerV2/timePickerV2';
import ListPoiNearByLocation from './listPoiNearByLocation/listPoiNearByLocation';
import { DirectionTool } from "../tool";
import { AvoidEnum, DirectionDepartEnum, VehicleEnum, WeightingEnum } from "../enum";
import TooltipV2 from "./tooltipV2/tooltipV2";
import Dialog from "./dialog/dialog";
import MobileRouteDetailV2 from "../mobileComponents/common/mobileRouteDetailV2/mobileRouteDetailV2";
import MobileDirectionOption from "../mobileComponents/common/mobileDirectionOption/mobileDirectionOption";
import MobileDirectionMenu from "../mobileComponents/common/mobileDirectionMenu/mobileDirectionMenu";
import MobileDepartAtView from "../mobileComponents/common/mobileDepartAtView/mobileDepartAtView";

const ICON =
    <svg
        id="Component_3_2"
        data-name="Component 3 – 2"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
    >
        <path
            id="Path_6362"
            data-name="Path 6362"
            d="M0,0H24V24H0Z"
            fill="none"
        />
        <path
            id="Path_6363"
            data-name="Path 6363"
            d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
            fill="#869195"
        />
    </svg>

const data = {
    positions: [],
    vehicle: VehicleEnum.car,
    avoidRoads: [AvoidEnum.ferryRoute, AvoidEnum.highWay],
    weighting: WeightingEnum.balance,
    routeIndex: 0,
    depart: DirectionDepartEnum.now,
    departAt: '',
    showNearByDestination: true,
    showNearByDirection: true,
    keyword: 'avc'
}

const Nhung = () => {

    const [showBanner, setShowBanner] = useState({
        info: false,
        success: false,
        warning: false,
        error: false
    })

    useEffect(() => {
        let dataEndCode = DirectionTool.encodeDirection(data)
        // console.log(dataEndCode)
        // console.log(DirectionTool.decodeDirection(dataEndCode))
    }, [])

    const onShowBanner = (type) => (e) => {
        setShowBanner({
            [type]: true
        })
    }

    const onCloseBanner = (type) => (e) => {
        setShowBanner({
            [type]: false
        })
    }

    const onChangeText = (e) => {
        let a = e?.target?.value
        console.log(a, 'valueTextInput');
    }

    const onChangeTextArea = (e) => {
        let a = e?.target?.value
        console.log(a, 'valueTextAreaInput');
    }

    const onChangeTime = (value) => {
        console.log(value, 'valueTimeInput')
    }

    const onChangeTag = (value) => {
        console.log(value, 'tag');
    }

    const onChangeDatePicker = (value) => {
        console.log(value);
    }

    const onChangeTimePicker = (value) => {
        console.log(value);
    }

    const onChangeOption = (isOptimize, weighting, avoids) => {
        console.log(isOptimize, 'isOptimize');
        console.log(weighting, 'weighting');
        console.log(avoids, 'avoids');
    }
    const onCloseOption = () => {
        // console.log('close');

    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                // justifyContent: 'flex-end',
                // marginTop:'5rem',
            }}>
            <div>
                {/* <MobileRouteDetailV2 route={route} routeIndex={0} weighting={WeightingEnum.balance}/> */}
                {/* <MobileDirectionOption isOptimize weighting={1} onChange={onChangeOption} onClose={onCloseOption} /> */}
                <MobileDirectionMenu />
                {/* <MobileDepartAtView value={null}/> */}
            </div>
            {/* <TooltipV2 description='Button here' anchor='right'>
                <div style={{ width: 'fit-content', height: 'fit-content' }}>
                    <Button>Tooltip</Button>
                </div>
            </TooltipV2> */}


            {/* <ListPoiNearByLocation text='Nhà hàng' /> */}
            {/* <div style={{ width: '300px' }}>
                <DatePickerV2
                    isShowYear
                    minDate={new Date()}
                    value={new Date()}
                    onChange={onChangeDatePicker}
                    // maxDate={new Date(2023, 5, 10)}
                />
            </div> */}

            {/* <div style={{ width: '300px'}}>
                <TimePickerV2
                    distance={30}
                    value={null}
                    onChange={onChangeTimePicker}
                    maxLengthOfScroll={10}
                    minTime={{ hour: 10, minute: 10 }}
                    maxTime={{ hour: 15, minute: 10 }}
                />
            </div> */}

            {/* <AddressInputV2
                label={Resource.common.address}
                placeholder={Resource.address.add}
                value='nhung'
            />

            <TextInput
                label="TextInput"
                value={'This is TextInput'}
                onChange={onChangeText}
            />

            <TimeInput
                label="TimeInput"
                value={"22:06"}
                className='classTest'
                onChange={onChangeTime}
            />

            <TextAreaInput
                label="TextAreaInput"
                value={'This is TextAreaInput'}
                onChange={onChangeTextArea}
            /> */}

            {/* <div style={{
                display: 'flex',
                gap: '24px',
                flexWrap: 'wrap'
            }}>
                <Button>Default</Button>
                <Button variant="done">Done</Button>
                <Button variant="add">Add</Button>
                <Button variant="cancel">Cancel</Button>
                <Button variant="close">Close</Button>
                <Button variant="save">Save</Button>
                <Button disabled>Disabled</Button>
                <Button prefixIcon={ICON}>Prefix Icon Button</Button>
                <Button suffixIcon={ICON}>Suffix Icon Button</Button>
            </div> */}

            {/* <Avatar
                firstName="Nhung"
                lastName="Nguyen"
            /> */}

            {/* <Card
                leftCaption='Nhung'
                rightCaption='Nguyen'
                title="Trung tâm Hán Ngữ"
                subTitle="123 Nguyễn Văn Thoại, Phường Nại Hiên Đông, Quận Sơn Trà, Đà Nẵng"
                icon={ICON}
            /> */}

            {/* <div style={{ display: 'flex', gap: '20px' }}>
                <Button variant="done" onClick={onShowBanner('info')}>
                    Congrats Banner Info
                </Button>
                <Button variant="done" onClick={onShowBanner('warning')}>
                    Congrats Banner Warning
                </Button>
                <Button variant="done" onClick={onShowBanner('success')}>
                    Congrats Banner Success
                </Button>
                <Button variant="done" onClick={onShowBanner('error')}>
                    Congrats Banner Error
                </Button>
            </div> */}

            <CongratsBanner
                type="info"
                title="Cảm ơn bạn đã đánh giá!"
                subTitle="Mọi người sẽ dựa vào bài đánh giá tuyệt vời của bạn để tìm những địa điểm tốt nhất để đến."
                open={showBanner.info}
                icon={<img src={'/mapAppRoot/image/like.png'} alt='like' />}
                onClose={onCloseBanner('info')}
                action={
                    <>
                        <Button variant="save">XEM CÁC BÀI ĐÁNH GIÁ CỦA BẠN</Button>
                        <Button variant="close" onClick={onCloseBanner('info')}>XONG</Button>
                    </>
                }
            />

            <CongratsBanner
                type="warning"
                title="Cảm ơn bạn đã đánh giá!"
                subTitle="Mọi người sẽ dựa vào bài đánh giá tuyệt vời của bạn để tìm những địa điểm tốt nhất để đến."
                open={showBanner.warning}
                icon={<img src={'/mapAppRoot/image/like.png'} alt='like' />}
                onClose={onCloseBanner('warning')}
                action={
                    <>
                        <Button variant="save">XEM CÁC BÀI ĐÁNH GIÁ CỦA BẠN</Button>
                        <Button variant="close" onClick={onCloseBanner('warning')}>XONG</Button>
                    </>
                }
            />

            <CongratsBanner
                type="success"
                title="Cảm ơn bạn đã đánh giá!"
                subTitle="Mọi người sẽ dựa vào bài đánh giá tuyệt vời của bạn để tìm những địa điểm tốt nhất để đến."
                open={showBanner.success}
                icon={<img src={'/mapAppRoot/image/like.png'} alt='like' />}
                onClose={onCloseBanner('success')}
                action={
                    <>
                        <Button variant="save">XEM CÁC BÀI ĐÁNH GIÁ CỦA BẠN</Button>
                        <Button variant="close" onClick={onCloseBanner('success')}>XONG</Button>
                    </>
                }
            />

            <CongratsBanner
                type="error"
                title="Cảm ơn bạn đã đánh giá!"
                subTitle="Mọi người sẽ dựa vào bài đánh giá tuyệt vời của bạn để tìm những địa điểm tốt nhất để đến."
                open={showBanner.error}
                icon={<img src={'/mapAppRoot/image/like.png'} alt='like' />}
                onClose={onCloseBanner('error')}
                action={
                    <>
                        <Button variant="save">XEM CÁC BÀI ĐÁNH GIÁ CỦA BẠN</Button>
                        <Button variant="close" onClick={onCloseBanner('error')}>XONG</Button>
                    </>
                }
            />

            {/* <TagInputV2 value={['abc', 'def']} onChange={onChangeTag} /> */}


        </div>
    );
};
export default Nhung;
