import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BusinessHourTool } from '../../tool';
import { Resource } from '../../resource';
import { TypeBusinessHourEnum } from '../../enum';
import TextView from '../textView/textView';


const nameTypeBusinessHours = {
  [TypeBusinessHourEnum.closed]: Resource.common.closeTime,
  [TypeBusinessHourEnum.openAllDay]: Resource.common.openAllTime
}
const ViewBusinessHour = (props) => {
  const { businessHours } = props
  const [openingDay, setOpeningDay] = useState(null)
  useEffect(() => {
    let open = BusinessHourTool.findCurrentOpening(businessHours)
    setOpeningDay(open)
  }, [businessHours])

  return (
    businessHours?.length > 0 &&
    <>
    {
          openingDay?.type == TypeBusinessHourEnum.opened &&
          <>
              <TextView variant='caption' color='#059000' style={{ fontWeight: '500' }}>{Resource.common.opening}</TextView>
            <span style={{ padding: '0 6px' }}>-</span>
            <TextView variant='caption'> {Resource.businessHours.closedAt + " " + openingDay?.close?.time}</TextView>
          </>
        }

        {
          openingDay?.type == TypeBusinessHourEnum.openAllDay &&
            <TextView variant='caption' color='#059000' style={{ fontWeight: '500' }}>
            {nameTypeBusinessHours[openingDay?.type]}
            </TextView>
        }

        {
          openingDay?.type == TypeBusinessHourEnum.closed &&
          <TextView variant='caption' style={{ fontWeight: '500' }}>{nameTypeBusinessHours[openingDay?.type]}</TextView>
        }
    </>
  );
};

ViewBusinessHour.propTypes = {
  //
};

export default ViewBusinessHour;
