import { createContext } from 'react';

export const defaultCt = {
    color: {
        error: "#D93535",
        warning: "#FFA407",
        text: {
            primary: "#121212",
            secondary: "#727272",
            disable: "#D5D5D5"
        },
        border: {
            primary: "#869195"
        }
    },
    textView: {
        color: "#121212"
    },
    paginationTable: {
        images: {
            loading: "",
            empty: "",
        },
    },

    loading: {
        image: ''
    },

    rowInfo: {
        minWidth: '',
        direction: ''
    }

}
export const ThemeContext = createContext(defaultCt)