import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import BearingControl from '../bearingControl/bearingControl';
import BtnControl from '../btnControl/btnControl';
import MyLocation from '../myLocation/myLocation';
import TiltControl from '../tiltControl/tiltControl';
import ZoomControl from '../zoomControl/zoomControl';
import TimeLine from '../../timeLine/timeLine';
import { useRecoilValue } from 'recoil';
import { MapTypeAppState } from '../../../appState';
import { MapTypeEnum } from '../../../enum';
const useStyles = makeStyles({
  style: {
    display: "flex",
    flexDirection: "column"
  },
  box1: {
    position: "absolute",
    right: "100%",
    bottom: "0"
  },
  box2: {
    position: "absolute",
    right: "100%",
    bottom: "0"
  }
});
function MapControl(props) {

  const mapType = useRecoilValue(MapTypeAppState)

  const classes = useStyles();
  return (
    <Box position="fixed" right={10} bottom={30}>
      <Box className={classes.style} >
        {/* <TimeLine /> */}
        <MyLocation />
        <Box mt={'5px'}>
          <BtnControl />
        </Box>
        <Box mt={'5px'}>
          <ZoomControl />
        </Box>
      </Box>
      {
        mapType == MapTypeEnum.map3d &&
        <Box className={classes.box1} >
          <Box mr={'5px'}>
            <TiltControl />
          </Box>
          <Box className={classes.box2}>
            <Box mr={'5px'}>
              <BearingControl />
            </Box>
          </Box>
        </Box>
      }



    </Box>
  )
}

MapControl.propTypes = {
};

export default MapControl;
