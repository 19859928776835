import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { CanBackToResultState, DataExtendState, PlaceRelatedState, UserState } from '../../appState';
import { CommonConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { CodeEnum, GeometryTypeEnum, MapTypeEnum, PlaceExtensionTypeEnum, TypeRequestUpdateEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, MapTool, PlaceTool, SidebarTool, StringTool, UrlTool, UserTool } from '../../tool';
import DescriptionDisplay from '../common/descriptionDisplay/descriptionDisplay';
import DirectionButton from '../common/directionButton/directionButton';
import DisplayBusinessHour from '../common/displayBusinessHour/displayBusinessHour';
import DisplayLink from '../common/displayLink/displayLink';
import DisplayMedia from '../common/displayMedia/displayMedia';
import DisplayVr360 from '../common/displayVr360/displayVr360';
import Error from '../common/error/error';
import RequestUpdateBtn from '../common/requestUpdateBtn/requestUpdateBtn';
import SaveButton from '../common/saveButton/saveButton';
import ShareButton from '../common/shareButton/shareButton';
import SwipeableViewsImages from '../common/swipeableViewsImages';
import ListItemLink from '../listItemLink/listItemLink';
import ListPlaceRelated from '../listPlaceRelated/listPlaceRelated';
import PlaceRelatedV2 from '../placeRelatedV2/placeRelatedV2';
import { MapTypeAppState } from './../../appState/mapTypeAppState';
import { GeoJsonTool } from './../../tool/geoJsonTool';
import DisplayGroup from './../common/displayGroup/displayGroup';
import './poiDetail.scss';
import TooltipV2 from '../tooltipV2/tooltipV2';

const worldCoords = [[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]

const PADDING_DEFAULT = 420

function PoiDetail(props) {
  const { id, text, type, lat, lng, data, closeEnable = true, onClose, hiddenDirection, disableTextSearch } = props
  const userState = useRecoilValue(UserState)
  const history = useHistory()
  const location = useLocation()
  const [place, setPlace] = useState(null)
  const polygonRef = useRef(null)
  const polylineRef = useRef(null)
  const polygonsRef = useRef([])
  const polylinesRef = useRef([])

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)
  const [dataExtendState, setDataExtendState] = useRecoilState(DataExtendState)
  const [placeRelatedState, setPlaceRelatedState] = useRecoilState(PlaceRelatedState)
  const setCanBackToResultState = useSetRecoilState(CanBackToResultState)

  const polygonHoleRef = useRef(null)

  const currentPlace = useRef(null)
  const [keySaved, setKeySaved] = useState(() => {
    return v4()
  })

  const [loading, setLoading] = useState(false)
  const [links, setLinks] = useState([])
  const [vr360, setVr360] = useState([])

  useEffect(() => {
    if ((type || text) && id) {
      setCanBackToResultState({
        show: true,
        text: text,
        type: type
      })
    }
    return () => {
      setCanBackToResultState(null)
    }
  }, [type, text, id])

  useEffect(() => {
    if (!disableTextSearch) {
      AppTool.setSearchBarText(place?.name || "")
    }
  }, [place?.name])

  useEffect(() => {
    var sourcePlaces
    if (id) {
      AppTool.setSearchBarLoading(true)
      SidebarTool.setOpen(true)
      setLoading(true)
      sourcePlaces = ApiTool.get(UrlConfig.poi.detail.replace("{id}", id), (res) => {
        setLoading(false)
        AppTool.setSearchBarLoading(false)
        if (res?.code == CodeEnum.ok) {
          let typeCheck = PlaceTool.isAreaOrStreet(res?.result)
          setMarkerAndShowInfo(res?.result)
          drawPoly(res?.result)
          setPlace(res?.result)
          res?.result?.object?.id && AppData.map.setSelectedBuildings([res?.result?.object?.id])
          if (!typeCheck && !location.state?.disableMoveMap) {
            moveCamera(res?.result)
          }
          let classifyMetaData = PlaceTool.classifyMetaData(res?.result?.metadata)
          let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
          let links = placeExtend?.link.sort((a, b) => a.order - b.order)
          let vr360 = placeExtend?.vr360.sort((a, b) => a.order - b.order)
          setLinks(links)
          setVr360(vr360)
        }
        else {
          setPlace(null)
        }
      })
    }

    return () => {
      currentPlace.current?.setMap(null)
      polylineRef.current?.setMap(null)
      polygonRef.current?.setMap(null)
      polygonsRef.current?.forEach(polygon => {
        polygon?.setMap(null)
      })
      polylinesRef.current?.forEach(polyline => {
        polyline?.setMap(null)
      })
      polygonHoleRef.current?.setMap(null)
      sourcePlaces?.cancel()
      AppData.map.setSelectedBuildings([])
    }
  }, [id])

  useEffect(() => {
    let api
    if (id && userState?.id) {
      let body = {
        placeId: id
      }
      api = ApiTool.post(UrlConfig.searchHistory.save, body, (res) => {
      }, true)
    }
    return () => {
      api?.cancel()
    }
  }, [[id, userState?.id]])

  useEffect(() => {
    var sourceLocation
    if (StringTool.isNumeric(lat) && StringTool.isNumeric(lng)) {
      let numLat = parseFloat(lat)
      let numLng = parseFloat(lng)
      if (-90 <= numLat && numLat <= 90 && -180 <= numLng && numLng <= 180) {
        let placeTemp = {
          location: {
            lat: numLat,
            lng: numLng
          },
          name: `${numLat.toFixed(6)}, ${numLng.toFixed(6)}`
        }
        let body = {
          lat: lat,
          lng: lng
        }
        setMarkerAndShowInfo(placeTemp)
        moveCamera(placeTemp)
        setPlace(placeTemp)
        AppTool.setSearchBarLoading(true)
        SidebarTool.setOpen(true)
        setLoading(true)
        sourceLocation = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          setLoading(false)
          AppTool.setSearchBarLoading(false)
          if (res?.code == CodeEnum.ok) {
            let newPlace = {
              ...placeTemp,
              address: res?.result.address
            }
            setPlace(newPlace)
          }
          else {
            setPlace(null)
          }
        })

      }
    }
    return () => {
      sourceLocation?.cancel()
    }
  }, [lat, lng])

  useEffect(() => {
    setDataExtendState({
      show: false,
      dataExtend: [],
      indexSelect: 0,
      typeData: PlaceExtensionTypeEnum.none
    })
  }, [id])

  useEffect(() => {
    let placeCustom = null
    try {
      placeCustom = JSON.parse(decodeURIComponent(data))
    } catch (error) {

    }
    setMarkerAndShowInfo(placeCustom)
    moveCamera(placeCustom)
    setPlace(placeCustom)
    SidebarTool.setOpen(true)
  }, [data])

  const setMarkerAndShowInfo = (place) => {
    if (place) {
      if (place?.location) {
        currentPlace.current = new map4d.POI({
          position: place?.location,
          icon: LinkIconConfig.placeDetail.marker,
          title: place?.name,
          zIndex: 99999
        })
        currentPlace.current.setUserData({
          id: place?.id || "",
          name: place?.name,
          address: place?.address,
          location: place?.location,
        });
        currentPlace?.current.setMap(AppData.map)
      }
    }
  }

  const moveCamera = (place) => {
    if (place && place?.location) {
      let camera = AppData.map.getCamera()
      if (place?.object?.camera) {
        let cameraObj = place?.object?.camera
        camera.setTarget(place?.location)
        camera.setZoom(cameraObj.zoom)
        camera.setTilt(cameraObj.tilt)
        camera.setBearing(cameraObj.bearing)
        let is3D = (AppData.map?.getMapType() == MapTypeEnum.map3d)
        !is3D && setMapTypeAppState(MapTypeEnum.map3d)
        AppData.map.moveCamera(camera, { animate: true })
      }
      else {
        let padding = {
          top: 10,
          left: PADDING_DEFAULT * 2,
          right: 10,
          bottom: 10
        }
        MapTool.moveWithPadding(AppData.map, place?.location, padding, { animate: true })
      }
    }
  }

  const drawPolyline = (data) => {
    polylineRef.current = new map4d.Polyline({
      path: data || [],
      strokeColor: "#3335DF",
      strokeOpacity: 1.0,
      strokeWidth: 5,
      userInteractionEnabled: false,
    })
    polylineRef.current.setMap(AppData.map)
  }

  const drawPolygon = (data) => {
    polygonHoleRef.current = new map4d.Polygon({
      paths: [worldCoords, data[0]],
      fillColor: "#000000",
      fillOpacity: 0.2,
      strokeWidth: 1,
      userInteractionEnabled: false,
    })
    polygonHoleRef.current.setMap(AppData.map)
  }

  const drawMultiPolyline = (data) => {
    if (data && data.length > 0) {
      polylinesRef.current = []
      for (let i = 0; i < data.length; i++) {
        let polyline = new map4d.Polyline({
          path: data[i],
          strokeColor: CommonConfig.strokeBlue,
          strokeOpacity: 1,
          strokeWidth: 7,
          userInteractionEnabled: false,
        })
        polyline.setMap(AppData.map)
        polylinesRef.current.push(polyline)
      }
    }
  }

  const drawMultiPolygon = (data) => {
    if (data && data.length > 0) {
      let holePaths = []
      for (let i = 0; i < data.length; i++) {
        holePaths.push(data[i][0])
      }
      polygonHoleRef.current = new map4d.Polygon({
        paths: [worldCoords, ...holePaths],
        fillColor: "#000000",
        fillOpacity: 0.2,
        strokeWidth: 1,
        userInteractionEnabled: false,
      })
      polygonHoleRef.current.setMap(AppData.map)
    }
  }

  const drawPoly = (place) => {
    if (place) {
      if (place?.geometry?.type) {
        let geometry = place?.geometry
        switch (geometry.type) {
          case GeometryTypeEnum.lineString:
            drawPolyline(geometry.coordinates)
            break
          case GeometryTypeEnum.polygon:
            drawPolygon(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiLineString:
            drawMultiPolyline(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiPolygon:
            drawMultiPolygon(geometry.coordinates || [])
            break
        }
        let bound = new map4d.LatLngBounds()
        let points = GeoJsonTool.getPointForBound(geometry.coordinates, geometry.type)
        if (points?.length > 0) {
          for (let i = 0; i < points.length; i++) {
            bound.extend(points[i])
          }
          let paddingOptions = {
            top: 10,
            bottom: 10,
            left: CommonConfig.widthSearchBar + (CommonConfig.padding * 2) + 10,
            right: 10
          }
          let camera = AppData.map.getCameraWithBounds(bound, paddingOptions)
          if (camera.getZoom() > 19) {
            camera.setZoom(19)
          }
          AppTool.moveCamera(camera, { animate: true })
        }
      }
    }
  }

  const handleBackToResult = () => {
    history.push({
      pathname: RouterConfig.place.search.replace("/:type?", type ? "/" + type : ""),
      search: UrlTool.createSearch({ text: text })
    })
  }

  const onChangeCountSaveGroup = () => {
    setKeySaved(v4())
  }

  const handleOnClickWebsite = (link) => (e) => {
    window.open(link, '_blank')
  }

  const handleOnBack = (item) => (e) => {
    setDataExtendState({
      show: false, dataExtend: [],
      indexSelect: 0,
      typeData: PlaceExtensionTypeEnum.none
    })
  }

  const user = UserTool.getUser()

  let urlWebSite = place?.website || ""
  if (!/^(http|https):/.test(urlWebSite)) {
    urlWebSite = 'http://' + urlWebSite
  }

  const onCloseDetail = () => {
    onClose && onClose()
  }

  let urlShare = window.location.origin + RouterConfig.place.detail.replace(":id", place?.id)

  return (
    <div className='poiDetailCpn'>
      <div className='boxContainer' key={place?.id || v4()}>
        <div className='boxInfo'>
          {
            !loading &&
            (
              !place ?
                <div className='boxDataNotFound'>
                  <Error message={Resource.message.dataNotFound} subMessage={Resource.message.dataDoesNotExist} />
                </div>
                :
                <>
                  <div className='boxAvatarPlace'>
                    <SwipeableViewsImages disableSwitch place={place} paddingImages={0} sizeButton={30} />
                  </div>

                  <div className='mainInfo'>
                    <div className='textName' title={place?.name}>
                      {place?.name}
                    </div>
                    {
                      place?.placeTypes?.length > 0 &&
                      <div className='textDescription'>
                        {place?.placeTypes[0].name || ''}
                      </div>
                    }
                  </div>

                  <div className='boxLine'></div>

                  <div className='boxButtonOption'>
                    {
                      !hiddenDirection && <div className='itemOption'>
                        <DirectionButton latlng={place?.location} />
                      </div>
                    }
                    {
                      place?.id &&
                      <div className='itemOption'>
                        <SaveButton id={place?.id} onChange={onChangeCountSaveGroup} />
                      </div>
                    }
                    <div className='itemOption'>
                      <ShareButton url={urlShare} />
                    </div>
                  </div>

                  <div className='boxLine'></div>

                  <DisplayGroup id={place?.id} placeName={place?.name} key={keySaved} />

                  <div className='extraInfo'>
                    <div className="listInformation">
                      {
                        place?.address &&
                        <div className='listItemInfo'>
                          <div className='listItemIcon'>
                            <img src={LinkIconConfig.placeDetail.location} alt='icon location' width={24} height={24} />
                          </div>
                          <div className='listItemText'>
                            {place?.address}
                          </div>
                        </div>
                      }

                      {
                        place?.businessHours?.length > 0 &&
                        <div className='listItemInfo'>
                          <div className='listItemIcon positionClock'>
                            <img src={LinkIconConfig.placeDetail.clock} alt='icon location' width={24} height={24} />
                          </div>
                          <div className='listItemText'>
                            <DisplayBusinessHour businessHours={place?.businessHours} />
                          </div>
                        </div>
                      }

                      {
                        place?.phoneNumber &&
                        <div className='listItemInfo'>
                          <div className='listItemIcon'>
                            <img src={LinkIconConfig.placeDetail.phone} alt='icon location' width={24} height={24} />
                          </div>
                          <div className='listItemText'>
                            {place?.phoneNumber}
                          </div>
                        </div>
                      }

                      {
                        place?.website &&
                        <div className='listItemInfo'>
                          <div className='listItemIcon'>
                            <img src={LinkIconConfig.placeDetail.global} alt='icon location' width={24} height={24} />
                          </div>
                          <div className='listItemText'>
                            <a href={urlWebSite} target='_blank'>{place?.website}</a>
                          </div>
                        </div>
                      }

                      {
                        user && place?.id &&
                        <div className='boxRequestUpdate'>
                          <RequestUpdateBtn id={place?.id} type={TypeRequestUpdateEnum.poi} placeName={place?.name} isButton typeGeo={place?.geometry?.type} />
                        </div>
                      }
                    </div>
                  </div>

                  {
                    place?.description &&
                    <>
                      <div className='boxLine'></div>
                      <div className='boxItemInfo'>
                        <div className='boxHeader'>
                          {Resource.common.introduce.toUpperCase()}
                        </div>
                        <div className='boxContent'>
                          <DescriptionDisplay value={place?.description} />
                        </div>
                      </div>
                    </>

                  }

                  {
                    place?.mediaFiles?.length > 0 &&
                    <>
                      <div className='boxLine'></div>
                      <div className='boxInfoMediaFiles'>
                        <div className='boxHeader'>
                          {Resource.placeDetail.photoVideo}
                        </div>
                        <div className='boxContent'>
                          <DisplayMedia place={place} />
                        </div>
                      </div>
                    </>
                  }

                  {
                    links?.length > 0 &&
                    <>
                      <div className='boxLine'></div>
                      <div className='boxInfoCus'>
                        <DisplayLink data={links} />
                      </div>
                    </>
                  }

                  {
                    vr360?.length > 0 &&
                    <>
                      <div className='boxLine'></div>
                      <div className='boxContent'>
                        <DisplayVr360 data={vr360} />
                      </div>
                    </>
                  }

                  {
                    place?.object &&
                    <>
                      <div className='boxLine'></div>
                      <div className='boxItemInfo boxDetailPlaceRelated'>
                        <PlaceRelatedV2 data={place?.otherPlaceInObjects} object={place?.object} />
                      </div>
                    </>

                  }
                </>
            )
          }
        </div>
        {
          closeEnable &&
          <TooltipV2 description={Resource.common.close} anchor='top'>
            <div className='closeIcon' onClick={onCloseDetail}>
              <img src={LinkIconConfig.common.closeBlack} alt="close" />
            </div>
          </TooltipV2>
        }
      </div>

      {
        dataExtendState.typeData == PlaceExtensionTypeEnum.link &&
        <ListItemLink />
      }

      {
        placeRelatedState.show &&
        <ListPlaceRelated />
      }
    </div>
  )
}

PoiDetail.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  data: PropTypes.any,
  closeEnable: PropTypes.bool,
  onClose: PropTypes.func,
  hiddenDirection: PropTypes.bool,
  disableTextSearch: PropTypes.bool
};

export default PoiDetail;
