import { useEffect, useRef } from "react";
import { AppData } from "../../data";
import { EndCodeMapTypeEnum, MapEventEnum } from "../../enum";
import { UrlTool } from "../../tool";
import { useRecoilValue } from 'recoil';
import { MapTypeAppState } from './../../appState/mapTypeAppState';

function CameraControl() {

    const mapTypeAppState = useRecoilValue(MapTypeAppState)
    const _timeOut = useRef()

    const changeUrlFromMapEvent = () => {
        const camera = AppData.map.getCamera();
        const target = camera.getTarget();
        let cameraParams =
            `${target?.lat?.toFixed(6)},${target?.lng.toFixed(6)},` +
            `${camera.getZoom()?.toFixed(2)},` +
            `${camera.getTilt()?.toFixed(1)},` +
            `${camera.getBearing()?.toFixed(1)},` +
            `${EndCodeMapTypeEnum[mapTypeAppState]}`;

        let search = { ...UrlTool.queryToJson(location.search) || {} };
        search.camera = cameraParams;
        search.mapid = AppData.mapid
        let url = location.pathname + UrlTool.jsonToQuery(search);
        _timeOut.current && clearTimeout(_timeOut.current)
        _timeOut.current = setTimeout(() => {
            history.replaceState(null, null, url)
        }, 31)

    };

    useEffect(() => {
        changeUrlFromMapEvent();
    }, [mapTypeAppState])

    useEffect(() => {
        const event = AppData.map.addListener(MapEventEnum.idle, () => {
            changeUrlFromMapEvent();
        });
        return () => {
            event?.remove()
        }
    }, [mapTypeAppState]);

    return null;
}

export default CameraControl;
