import { AppData } from "../data";
import {
  CodeEnum,
  EnvironmentEnum,
  OperatingSystemEnum,
  StorageEnum,
} from "../enum";
import { LocalStorageEnum } from "./../enum/localStorageEnum";

export class AppTool {
  static isLocal = () => {
    let env = false;
    if (/3001/.test(location.origin)) {
      env = true;
    }
    return env;
  };
  static isVimap = () => {
    if (/vimap/.test(location.origin)) {
      return true;
    }
    return false;
  };
  static getOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return OperatingSystemEnum.windowsPhone;
    } else if (/android/i.test(userAgent)) {
      return OperatingSystemEnum.android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return OperatingSystemEnum.ios;
    }

    return OperatingSystemEnum.unknown;
  };
  static isAppLink = () => {
    return /app/.test(location.origin);
  };
  static share = (url = null) => {
    AppData.shareControl?.share(url);
  };

  static showMedia = (mediaFiles, type, title) => {
    AppData.media.showMedia(mediaFiles, type, title)
  }

  static showMediaWithVideos = (show, photosData, videosData, title) => {
    AppData.media.showMediaWithVideos(show, photosData, videosData, title);
  };
  static showMediaWithPhotos = (show, photosData, videosData, title) => {
    AppData.media.showMediaWithPhotos(show, photosData, videosData, title);
  };
  static alert = (message, type) => {
    AppData.alert?.alert?.(message, type);
  };
  static showSidebarPopup = (children) => {
    AppData.sidebarPopup?.show(children);
  };
  static hideSidebarPopup = () => {
    AppData.sidebarPopup?.hide();
  };
  static setSearchBarText = (text) => {
    AppData.searchBar?.setText(text);
    AppData.mobile.searchBar?.setText(text);
  };
  static setShowAutoComplete = (show) => {
    AppData.mobile.searchBar?.setShowAutoComplete(show);
  };
  static setSearchBarLoading = (isLoading) => {
    AppData.searchBar?.setLoading(isLoading);
  };
  static search = (text, type, pageNumber, position) => {
    AppData.placeSearch?.search(text, type, pageNumber, position);
  };

  static logout = () => {
    localStorage.setItem(StorageEnum.logout, "logout-" + Math.random());
    let url = encodeURI(window.location.href);
    window.location.href = `${location.origin}/auth/logout?returnUrl=${url}`;
  };

  static reload = () => {
    AppData.app?.reload();
  };
  static moveCamera = (camera, option = null) => {
    AppData.mapTypeControl?.moveCamera(camera, option);
  };
  static getMyLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude, accuracy } = location.coords;
          let newLocation = { lat: latitude, lng: longitude };
          localStorage.setItem(LocalStorageEnum.myLastLat, newLocation.lat);
          localStorage.setItem(LocalStorageEnum.myLastLng, newLocation.lng);
          callback({
            code: CodeEnum.ok,
            result: newLocation,
            accuracy: accuracy,
          });
        },
        (error) => {
          callback({
            code: CodeEnum.error,
            result: error,
          });
        },
        {
          enableHighAccuracy: true,
          // timeout: Infinity,
          // maximumAge: 0,
        }
      );
    } else {
      callback({
        code: CodeEnum.error,
      });
    }
  };
  static getSdkEnvironment = () => {
    let env = "";
    if (/3001/.test(location.origin)) {
      env = "dev";
    }
    if (/dev/.test(location.origin)) {
      env = "dev";
    }
    if (/test/.test(location.origin)) {
      env = "test";
    }
    if (/viettel/.test(location.origin)) {
      env = "viettel";
    }
    return env;
  };
}
