import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import "./popoverV2.scss"
import { StringTool } from "../../tool"
import dialogPolyfill from 'dialog-polyfill'

const PopoverV2 = ({ open, onClose, actionButton, children, onOpen, className }) => {
  const actionRef = useRef()
  const popupRef = useRef()

  const [openState, setOpenState] = useState(false)
  const [closeAnimation, setCloseAnimation] = useState(false)
  const [classNameCus, setClassNameCus] = useState(() => {
    return StringTool.mergeClassName('popupV2', className)
  })

  const [styleDialog, setStyleDialog] = useState({
    left: '-1000px',
    top: '-1000px',
  })

  useEffect(() => {
    setClassNameCus(StringTool.mergeClassName('popupV2', className))
  }, [className])

  useEffect(() => {
    setOpenState(open)
  }, [open])

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      const { top, bottom, left, right } = actionRef.current?.getBoundingClientRect() || {}

      const anchorXObj = {
        left,
        right: innerWidth - right
      }
      const anchorYObj = {
        top,
        bottom: innerHeight - bottom,
      }

      const sortAnchorX = Object.keys(anchorXObj).sort((a, b) => {
        return (anchorXObj[a] - anchorXObj[b])
      })

      const sortAnchorY = Object.keys(anchorYObj).sort((a, b) => {
        return (anchorYObj[a] - anchorYObj[b])
      })

      let posAnchorY = sortAnchorY?.[sortAnchorY?.length - 1]
      let posAnchorX = sortAnchorX?.[sortAnchorX?.length - 1]

      let posTransformX = sortAnchorX?.[0]
      let posTransformY = sortAnchorY?.[0]

      const styleWithAnchorX = {
        "right": {
          left: left + "px",
        },
        "left": {
          right: (innerWidth - right) + "px",
          left: "unset"
        },
      }

      const styleWithAnchorY = {
        "top": {
          bottom: `calc(100% - ${top}px)`,
        },
        "bottom": {
          top: bottom + "px",
        },
      }

      setStyleDialog({
        ...styleWithAnchorX[posAnchorX],
        ...styleWithAnchorY[posAnchorY],
        transformOrigin: `${posTransformX} ${posTransformY}`
      })
    }

    let observer

    if (openState) {
      handleResize()
      observer = new MutationObserver(handleResize)
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      })
      window.addEventListener("resize", handleResize)
      document.addEventListener("scroll", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
      document.removeEventListener("scroll", handleResize)
      observer?.disconnect()
    }
  }, [openState])

  useEffect(() => {
    if (!openState) {
      setTimeout(() => {
        popupRef.current?.open && popupRef.current?.close?.()
        setCloseAnimation(false)
      }, 200)
      setCloseAnimation(true)
    }
    else {
      popupRef.current?.show()
    }
  }, [openState])

  const handleOpen = () => {
    if (openState) {
      setOpenState(false)
      onClose?.()
    }
    else {
      setOpenState(true)
      onOpen && onOpen()
    }
  }

  useEffect(() => {
    const clickOut = (event) => {
      var isClickButton = actionRef?.current?.contains(event.target)
      var isClickPopup = popupRef?.current?.contains(event.target)
      if (!isClickPopup && !isClickButton) {
        onClose && onClose()
      }
    }
    window.addEventListener("touchstart", clickOut)
    window.addEventListener("pointerdown", clickOut)
    return () => {
      window.removeEventListener("touchstart", clickOut)
      window.addEventListener("pointerdown", clickOut)
    }
  }, [])

  const handleRef = (r) => {
    popupRef.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    <div className="popoverMap4dV2">
      <div ref={actionRef} className="actionButtonWrap" onClick={handleOpen}>
        {actionButton}
      </div>
      {
        ReactDOM.createPortal(
          <dialog
            className={StringTool.mergeClassName(classNameCus, closeAnimation ? "closePopupV2" : "")}
            ref={handleRef}
            style={styleDialog}
          >
            {children}
          </dialog>,
          document.body)
      }
    </div>
  )
}

PopoverV2.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actionButton: PropTypes.any,
  onOpen: PropTypes.func,
}

export default PopoverV2
