import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MyPois from '../../components/myPois/myPois';
import { MobileDraggableBarTool } from '../../tool';
import './mobileMyPois.scss'

const MobileMyPois = () => {

  useEffect(() => {
    MobileDraggableBarTool.setHeight(0)
  }, [])
  return (
    <div className='mobileMyPoisCpn'>
      <MyPois />
    </div>
  );
};

MobileMyPois.propTypes = {
  //
};

export default MobileMyPois;
