import { Box, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LinkIconConfig } from '../../config';
import { UrlConfig } from '../../config/urlConfig';
import { AlertTypeEnum, CodeEnum } from '../../enum';
import { ApiTool, AppTool } from '../../tool';
import Confirm from '../common/confirm/confirm';
import { RouterConfig } from './../../config/routerConfig';
import { Resource } from './../../resource';
import AddIcon from '@material-ui/icons/Add';
import AddList from '../common/addList/addList';


const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#508FF4',
    justifyContent: 'space-between',
    padding: '12px'
  },
  padding: {
    padding: '0 16px',
  },
  borderTop: {
    borderTop: '1px solid #c1c1c16e',
  },
  imgBorder: {
    borderRadius: '4px'
  },
  fontWeight: {
    fontWeight: 500
  },
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
  buttonInherit: {
    textTransform: 'inherit',
    padding: 0
  },
  icon: {
    width: "48px",
    height: "48px",
    backgroundColor: "#00D1B433",
    boxShadow: "0px 3px 6px #00D1B433",
    borderRadius: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 0
  }
}))

function MenuGroup({ id, onDeleted }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickShare = () => {
    handleClose()
    AppTool.share(window.location.origin + RouterConfig.placeGroup.groupDetail.replace(":id", id))
  }

  const onClickEdit = () => {
    handleClose()
    history.push({
      pathname: RouterConfig.placeGroup.updateGroup.replace(":id", id)
    })
  }

  const onConfirmDelete = () => {
    handleClose()
    ApiTool.delete(UrlConfig.placeGroup.deleteGroup.replace("{id}", id), (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.delete, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        onDeleted && onDeleted()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.delete, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const onCancelDelete = () => {
    handleClose()
  }


  return (
    <>
      <Tooltip title={Resource.common.otherOption} placement='bottom'>
        <IconButton aria-controls="group-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreHoriz />
        </IconButton>
      </Tooltip>

      <Menu
        id="group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onClickEdit}>{Resource.placeGroup.edit}</MenuItem>
        <MenuItem onClick={onClickShare}>{Resource.placeGroup.share}</MenuItem>
        <MenuItem>
          <Confirm message={Resource.formatString(Resource.confirmMessage.delete, {
            object: Resource.common.list.toLowerCase()
          })} closeOnConfirm onConfirm={onConfirmDelete} onCancel={onCancelDelete}>
            <span>{Resource.placeGroup.delete}</span>
          </Confirm>
        </MenuItem>
      </Menu>
    </>
  );
}

function MySavedGroups() {

  const classes = useStyles()

  const history = useHistory()

  const [groups, setGroups] = useState(null)
  const [showAddList, setShowAddList] = useState(false)

  useEffect(() => {
    let source = ApiTool.get(UrlConfig.placeGroup.getByUser, (res) => {
      if (res?.code == CodeEnum.ok) {
        setGroups(res?.result)
      }
    }, true)
    return () => {
      source?.cancel()
    }
  }, [])

  const handleClickGroup = (group) => (e) => {
    history.push({
      pathname: RouterConfig.placeGroup.groupDetail.replace(":id", group?.id)
    })
  }

  const onDeleted = (id) => (e) => {
    setGroups(prev => {
      let newGroups = [...(prev || [])]
      let kq = newGroups?.filter(t => t?.id != id)
      return kq
    })
  }

  const handleShowAddList = () => {
    setShowAddList(true)
  }

  const handleCloseAddList = () => {
    setShowAddList(false)
  }

  const onAddList = (group) => {
    setGroups((prev) => {
      return [...prev || [], group]
    })
    setShowAddList(false)
  }

  return (
    <Box>
      <List component='div' style={{ flexGrow: '1', overflow: 'auto' }}>
        {
          groups?.map((item, index) => {
            return (
              <Box key={item.id || v4()}>
                <ListItem button onClick={handleClickGroup(item)} classes={
                  {
                    root: index > 0 ? classes.borderTop : ""
                  }
                } >
                  <ListItemAvatar className={classes.icon}>
                    <img className={classes.imgBorder} src={LinkIconConfig.common.list} height={24} width={24} alt='logo' />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={classes.fontWeight} variant='body1' noWrap>{item.name}</Typography>
                    }
                    title={item.name}
                    secondary={
                      <Typography variant='body2' component='span'>{((item?.locationInfos?.length || 0) + (item?.places?.length || 0)) + ` ${Resource.common.place}`}</Typography>
                    }
                    primaryTypographyProps={
                      {
                        noWrap: true,
                      }
                    }
                    secondaryTypographyProps={
                      {
                        noWrap: true,
                      }
                    }
                    classes={
                      {
                        root: classes.padding
                      }
                    }
                  />
                  <ListItemSecondaryAction>
                    <MenuGroup id={item?.id} onDeleted={onDeleted(item?.id)} />
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>
            )
          })
        }
      </List>
      <Box position='absolute' right='20px' bottom='20px'>
        <Tooltip title={Resource.placeGroup.addList}>
          <Fab color="primary" aria-label="add" onClick={handleShowAddList}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <AddList show={showAddList} onAdded={onAddList} onClose={handleCloseAddList} />
      </Box>
    </Box>
  )
}

MySavedGroups.propTypes = {
  //
};

export default MySavedGroups;
