import React, { Component, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Resource } from "../../../resource";
import { Box, Button, Dialog, DialogContent, DialogTitle, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ApiTool, AppTool } from "../../../tool";
import { UrlConfig } from "../../../config/urlConfig";
import { AlertTypeEnum, CodeEnum } from "../../../enum";

const useStyles = makeStyles({
  dialogAddList: {
    maxWidth: '100%',
    width: '352px'
  },
  dialogTitle: {
    textAlign: 'center'
  },
})

function AddList({onAdded, onClose, show}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [listName, setListName] = useState('')
  const groupsRef = useRef([])

  const schema = yup.object().shape({
    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.name
      }))
  })
  const form = useForm({
    resolver: yupResolver(schema)
  })

  const { register, handleSubmit, errors, formState } = form

  const onChangeListName = (event) => {
    let value = event.target.value
    setListName(value)
  }

  const onSubmit = (data) => {
    let params = {
      name: data?.name,
      description: ''
    }
    ApiTool.post(UrlConfig.placeGroup.create, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        onAdded&&onAdded(res?.result)
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  return (
    
    <Dialog
      fullScreen={fullScreen}
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth='xl'
      classes={
        {
          paper: classes.dialogAddList
        }
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.placeGroup.newList}</DialogTitle>
        <DialogContent dividers>
          <Box textAlign='right' clone>
            <Typography variant='caption' component='p' gutterBottom>{`${listName?.length || 0}/50`}</Typography>
          </Box>

          <TextField
            label={Resource.placeGroup.listName}
            variant='outlined'
            fullWidth
            onChange={onChangeListName}
            name='name'
            inputRef={register}
            inputProps={{ maxLength: 50 }}
            error={errors?.name ? true : false}
            helperText={errors?.name?.message}
          />
        </DialogContent>
        <Box p={1} display='flex' alignItems='center' justifyContent='center'>
          <Button variant='contained' color='primary' type='submit'>{Resource.button.create}</Button>
        </Box>
      </form>
    </Dialog>
  )
}

AddList.propTypes = {
  //
};

export default AddList;
