import { makeStyles } from '@material-ui/core';
import { Cancel, InsertDriveFile } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { Resource } from '../../../resource';

const useStyles = makeStyles({

    borderSLfile: {
        height: 126,
        position: 'relative',
        backgroundColor: '#508FF433',
        borderRadius: 5,
        width: '100%',
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: 'absolute',
            border: '8px dashed #508FF4',
            height: 'calc(126px + 12px)',
            width: 'calc(100% + 11.5px)',
            top: '-6px',
            left: '-6px',
        },
        '&:hover': {
            "& $ButtonSLfileChild": {
                backgroundColor: '#3864aa',
            }
        }
    },

    SLfileChild: {
        position: 'absolute',
        top: 25,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },

    ButtonSLfileChild: {
        width: 100,
        height: 40,
        backgroundColor: '#508FF4',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 25,
        transition: '.2s ease-out'
    },

    InputSLfileChild: {
        width: '100%',
        height: '100%',
        cursor: "pointer",
        opacity: 0
    },

    showSelectedFile: {
        padding: "15px 10px",
        borderRadius: 4,
        marginTop: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#f2f6fa',
        color: '#6b6d6f '
    },

    showFileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        flexGrow: 1
    },
    cancel :{
        cursor: "pointer",
        fill:'#00000042',
        "&:hover" :{
            fill:'#00000066 ',
        }
    }
})

function SelectFile({ label, dragtext, onChange, value }) {

    const ref = useRef()
    const classes = useStyles()
    const [selectedFile, setSelectedFile] = useState(null)


    const HanldeSelectfiles = (event) => {
        let fileSelected = event?.target?.files[0]
        if (fileSelected) {
            setSelectedFile(fileSelected)
            onChange(fileSelected)
        }
    }

    const RemoveFile = (event) => {
        setSelectedFile(null);
        ref.current.value = "";
        onChange(null)
    }

    return (
        <React.Fragment>

            <div className={classes.borderSLfile} >
                <div className={classes.SLfileChild}>
                    <span style={{ color: '#508ff4' }}>{dragtext} </span>
                    <label htmlFor='imgid' className={classes.ButtonSLfileChild}> {label}</label>
                </div>
                <input
                    className={classes.InputSLfileChild}
                    type="file"
                    onChange={HanldeSelectfiles}
                    accept=".geojson"
                    name="images"
                    id="imgid"
                    ref={ref}
                />

            </div>
            {
                selectedFile &&
                <div className={classes.showSelectedFile} >
                    <InsertDriveFile />
                    <div className={classes.showFileName} title={selectedFile.name}>{selectedFile.name}</div>
                    <Cancel onClick={RemoveFile}
                     className={classes.cancel}
                      titleAccess={Resource.common.removeFile} />
                </div>
            }
        </React.Fragment>
    );
}
export default SelectFile;