import React, { useEffect, useRef, useState } from "react";
import { StringTool } from "../../tool";
import styles from './dragToScroll.css';

const ICON_NEXT =
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_79913" data-name="Group 79913" transform="translate(-739 -335)">
      <path id="Path_8067" data-name="Path 8067" d="M0,24H24V0H0Z" transform="translate(739 335)" fill="none" />
      <path id="Path_8068" data-name="Path 8068" d="M12,9.343,7.757,13.586,9.172,15,12,12.171,14.828,15l1.415-1.414Z" transform="translate(763.172 334.828) rotate(90)" fill="#121212" />
    </g>
  </svg>

const ICON_PREV =
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_79914" data-name="Group 79914" transform="translate(-793 -335)">
      <path id="Path_8067" data-name="Path 8067" d="M0,24H24V0H0Z" transform="translate(793 335)" fill="none" />
      <path id="Path_8068" data-name="Path 8068" d="M4.242,5.657,0,1.414,1.415,0,4.242,2.829,7.07,0,8.484,1.414Z" transform="translate(807.829 342.586) rotate(90)" fill="#121212" />
    </g>
  </svg>

export interface DragToScrollProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  distance?: any,
  count?: any
};

const DragToScroll: React.FC<DragToScrollProps> = ({
  distance = 285,
  count,
  ...props
}) => {

  const containerRef = useRef<any>()
  const isDownRef = useRef<any>()
  const startX = useRef<any>()
  const startScrollLeft = useRef<any>()

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.dragToScrollCpn, props.className as string)
  })

  const [isHover, setIsHover] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [isPrev, setIsPrev] = useState(false)

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.dragToScrollCpn, props.className as string))
  }, [props.className])

  useEffect(() => {
    if (containerRef.current?.clientWidth < containerRef.current?.scrollWidth) {
      setIsNext(true)
    }
    const handleMouseUp = () => {
      if (isDownRef.current) {
        isDownRef.current = false
      }
    }
    document.addEventListener("mouseup", handleMouseUp)
    return () => {
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])

  const onHover = () => {
    setIsHover(true)
  }

  const onLeave = () => {
    setIsHover(false)
  }

  const handleMouseDown = (e: any) => {
    let position = e?.clientX
    isDownRef.current = true
    startX.current = position
    startScrollLeft.current = containerRef.current.scrollLeft
  }

  const handleMouseMove = (e: any) => {
    if (isDownRef.current) {
      let delta = e?.clientX - startX.current
      containerRef.current.scrollLeft = (startScrollLeft.current - delta)
    }
  }

  const onNext = () => {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
      containerRef.current.scrollLeft += 10
      scrollAmount += 10
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, 10)
  }

  const onPrev = () => {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
      containerRef.current.scrollLeft -= 10
      scrollAmount += 10
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
      }
    }, 10)
  }

  const onScroll = () => {
    let width = containerRef.current?.scrollLeft + containerRef.current?.clientWidth
    let next = width < containerRef.current?.scrollWidth
    let pre = containerRef.current?.scrollLeft > 0
    setIsNext(next)
    setIsPrev(pre)
  }

  return (
    <div
      className={className}
      onMouseLeave={onLeave}
      onMouseMove={onHover}
      {...props}
    >
      <div
        style={{
          overflowX: 'hidden',
          userSelect: 'none',
        }}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        className={styles.dragContainer}
        onScroll={onScroll}
      >
        {props.children}
      </div>

      {
        isHover && isPrev &&
        <div
          className={StringTool.mergeClassName(styles.btnCircle, styles.prev)}
          onClick={onPrev}
        >
          {ICON_PREV}
        </div>
      }

      {
        isHover && isNext &&
        <div
          className={StringTool.mergeClassName(styles.btnCircle, styles.next)}
          onClick={onNext}
        >
          {ICON_NEXT}
        </div>
      }
    </div>

  )
};

export default DragToScroll;
