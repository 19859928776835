import LocalizedStrings from 'react-localization';
import { CookieEnum, LanguageCodeEnum } from './enum';
import { vi, en } from './language';
import { CookieTool } from './tool';


let lang = CookieTool.get(CookieEnum.language) || LanguageCodeEnum.vi
const Resource2 = new LocalizedStrings({
    [LanguageCodeEnum.vi]: vi,
    [LanguageCodeEnum.en]: en,
});
Resource2.setLanguage(lang)
export const Resource = Resource2
