import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import { Resource } from '../../../resource';

const paddingX = 48
const paddingY = 16

const useStyles = makeStyles({
  header: {
    boxShadow: '0px 0px 2px #00000029',
    backgroundColor: '#508ff4',
    color: '#FFFFFF'
  },
  closeIcon: {
    position: 'absolute',
    top: "50%",
    right: '4px',
    transform: 'translateY(-50%)',
    color: '#FFFFFF'
  },
  comeBackIcon: {
    position: 'absolute',
    top: "50%",
    left: '4px',
    transform: 'translateY(-50%)',
    color: '#FFFFFF'
  },
})

function MobileHeader({ title, subTitle, onClose, onComeBack, isClose, isBack }) {

  const classes = useStyles()

  const onClickClose = (e) => {
    onClose && onClose()
  }

  const onClickComeBack = (e) => {
    onComeBack && onComeBack()
  }

  return (
    <Box className={classes.header} position="relative">
      {
        isBack &&
        <Tooltip title={Resource.common.back} placement='bottom'>
          <IconButton className={classes.comeBackIcon} onClick={onClickComeBack}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
      }
      <Box textAlign='center' px={`${paddingX}px`} py={`${paddingY}px`}>
        <Typography variant="h6">
          {title}
        </Typography>
        {
          subTitle &&
          <Typography variant="caption">
            {subTitle}
          </Typography>
        }
      </Box>
      {
        isClose &&
        <Tooltip title={Resource.common.close} placement='bottom'>
          <IconButton className={classes.closeIcon} onClick={onClickClose}>
            <Close />
          </IconButton>
        </Tooltip>
      }
      <Divider />
    </Box>
  )
}

MobileHeader.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  onClose: PropTypes.func,
  onComeBack: PropTypes.func,
  isClose: PropTypes.bool,
  isBack: PropTypes.bool
};

export default MobileHeader;
