import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from 'ui';
import { AvoidEnum } from '../../enum';
import { Resource } from '../../resource';
import './roadAvoid.scss'

const options = [
  {
    name: Resource.direction.avoid.highWay,
    value: AvoidEnum.highWay,
    key: 'highWay'
  },
  {
    name: Resource.direction.avoid.ferryRoute,
    value: AvoidEnum.ferryRoute,
    key: 'ferryRoute'
  },
  {
    name: Resource.direction.avoid.tunnel,
    value: AvoidEnum.tunnel,
    key: 'tunnel'
  },
  {
    name: Resource.direction.avoid.toll,
    value: AvoidEnum.toll,
    key: 'toll'
  },
]

const RoadAvoid = (props) => {
  const { onChange, value } = props
  const [checked, setChecked] = useState([])

  const handleChecked = (value) => (e) => {
    const indexInStateValue = checked?.indexOf(value);
    if (indexInStateValue === -1) {
      setChecked(prev => {
        let list = [...prev, value].sort()
        onChange && onChange(list)
        return list
      });
    } else {
      setChecked(prev => {
        let list = [...prev]
        list.splice(indexInStateValue, 1)
        onChange && onChange(list)
        return list
      });
    }
  }

  useEffect(() => {
    setChecked(value || [])
  }, [value])


  return (
    <div className='RoadAvoidCpn'>
      <div className='title'>
        {Resource.common.avoid}
      </div>
      <div className='groupButton'>
        {
          options?.map((item) => {
            return (
              <div key={item?.key}>
                <CheckBox
                  onChange={handleChecked(item?.value)}
                  checked={(checked?.some(value => value == item?.value))}
                  label={item?.name}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

RoadAvoid.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any
};

export default RoadAvoid;
