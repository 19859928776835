export const ManeuverEnum = Object.freeze({
    keepLeft: "keep-left",
    keepRight: "keep-right",
    roundaboutLeft: "roundabout-left",
    roundaboutRight: "roundabout-right",
    straight: "straight",
    turnLeft: "turn-left",
    turnRight: "turn-right",
    turnSharpLeft: "turn-sharp-left",
    turnSharpRight: "turn-sharp-right",
    turnSlightLeft: "turn-slight-left",
    turnSlightRight: "turn-slight-right",
    finish: "finish",
    reachedVia: "reached-via"
})