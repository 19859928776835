import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, HorizontalLine, TextInput } from 'ui';
import { LinkIconConfig, UrlConfig } from '../../config';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool } from '../../tool';
import MultiInput from '../common/multiInput/multiInput';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import DialogHeader from '../dialogHeader/dialogHeader';
import "./belongBuilding.scss";

const RemoveUnikey = (str) => {
  if (str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\W+/g, " ");
  }
  return str;
};

const Highligh = ({ text, valueInput }) => {
  let position = RemoveUnikey(text).indexOf(RemoveUnikey(valueInput));
  return (
    <div className="wrapHighligh">
      {position === -1 || valueInput === "" ? (
        <div className="name">{text}</div>
      ) : (
        <div className="nameHighligh">
          <span className="notHighligh">
            {text.substring(0, position)}
          </span>
          <span className="isHighligh">
            {text.substring(position, position + valueInput.length)}
          </span>
          <span className="notHighligh">
            {text.substring(position + valueInput.length)}
          </span>
        </div>
      )}
    </div>
  );
};
const BelongBuilding = (props) => {
  const { value, onChange, location } = props
  const timeOut = useRef()
  const apiBuilding = useRef()
  const [buildingValue, setBuildingValue] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [searchKey, setSearchKey] = useState("")
  const [listBuilding, setListBuilding] = useState([])
  const centerLocation = AppData.map.getBounds().getCenter();

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSearchKey("")
  }

  const getDetailBuilding = () => {
    ApiTool.get(UrlConfig.object.detail.replace('{id}', value), (res) => {
      let name = ''
      if (res?.code == CodeEnum.ok) {
        name = res?.result?.name
      }
      else {
        AppTool.alert(res?.message, AlertTypeEnum.error)
      }
      setBuildingValue(name)
    }, true)
  }

  useEffect(() => {
    if (value) {
      const auto = getDetailBuilding()
      return () => auto?.cancel()
    }
  }, [value])

  const getBuilding = (search = "") => {
    let param = {
      keyword: search?.trim() || "",
      recordNumber: 10,
      lat: location ? location?.lat : centerLocation?.lat,
      lng: location ? location?.lng : centerLocation?.lng,
      radius: 500,
      isBuildingObject: true
    }
    apiBuilding.current = ApiTool.queryGetFromJson(UrlConfig.object.search, param, (res) => {
      if (res?.code == CodeEnum.ok) {
        setListBuilding(res?.result)
      }
      else {
        AppTool.alert(res?.message, AlertTypeEnum.error)
      }
    }, true)
  }

  useEffect(() => {
    if (openDialog) {
      getBuilding()
      return () => apiBuilding.current?.cancel()
    }
  }, [openDialog])

  const handleSelectBuilding = (item) => (e) => {
    setBuildingValue(item?.name)
    onChange && onChange(item?.id)
    setOpenDialog(false)
    setSearchKey("")
  }

  const handleChangeSearchKey = (e) => {
    let search = e?.target.value
    setSearchKey(search)
    timeOut.current && clearTimeout(timeOut.current)
    timeOut.current == setTimeout(() => {
      let value = e?.target.value
      getBuilding(value)
    }, 300)
  }


  return (
    <div>
      <MultiInput
        label={Resource.building.belong}
        value={buildingValue}
        placeholder={Resource.building.input}
        onClick={handleOpenDialog}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        onBack={handleCloseDialog}
        disableCloseOnClickBackdrop
      >
        <DialogHeader showBack hideClose>{Resource.building.select}</DialogHeader>
        <div style={{ width: "28.125rem" }}><DialogBody><div className='listBuilding'>
          <TextInput
            placeholder={Resource.building.search}
            suffix={<img src={LinkIconConfig.common.searchGray} alt="icon-search" />}
            className='searchInput'
            onChange={handleChangeSearchKey}
            value={searchKey}
          />
          <HorizontalLine className='horizontalLine' />
          {
            listBuilding?.length == 0 ? <div className='notFounData'>{Resource.building.notFoundBuilding}</div>

              : <div className='listContainer'>
                {
                  listBuilding?.map((item, index) => {
                    return (
                      <div className='itemCpn' key={index} onClick={handleSelectBuilding(item)}>
                        <div className='itemContainer'>
                          <img className='icon' src={LinkIconConfig.common.building} alt='icon-building' />
                          <div className='text'>
                            <Highligh text={item?.name} valueInput={searchKey} />
                            <div className='address'>{item?.address}</div>
                          </div>
                        </div>
                        <div className='itemLine'><HorizontalLine style={{ backgroundColor: "#EBEBEB" }} /></div>
                      </div>
                    )
                  })
                }
              </div>
          }
        </div></DialogBody></div>
        <DialogAction><Button variant='cancel' onClick={handleCloseDialog}>{Resource.button.cancel}</Button></DialogAction>
      </Dialog>
    </div>
  );
};

BelongBuilding.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  location: PropTypes.object
};

export default BelongBuilding;
