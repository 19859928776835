import React, { useEffect, useState } from 'react';
import { PlaceTool } from '../../../tool';
import ArticleInput from '../articleInput/articleInput';
import LinkInput from '../linkInput/linkInput';
import PopupInput from '../popupInput/popupInput';
import VideoInput from '../videoInput/videoInput';
import Vr360Input from '../vr360Input/vr360Input';
import PropTypes from "prop-types";
import { v4 } from 'uuid';

function MetaDataInput(props) {
  const {value, onChange } = props
  const [stateValue, setStateValue] = useState([]);
  const [key, setKey] = useState(() => {
    return v4()
  })

  useEffect(() => {
    let classifyMetaData = PlaceTool.classifyMetaData(value)
    let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
    setKey(v4)
    setStateValue(placeExtend)
  }, [value])

  useEffect(() => {
    let newValue = []
    Object.values(stateValue)?.forEach(list => {
      newValue = newValue.concat(list || [])
    })
    onChange && onChange(newValue)
  }, [stateValue])


  const onChangeVr360 = (list) => {
    setStateValue((prev) => {
      prev.vr360 = list
      return { ...prev }
    })
  }

  const onChangeArticle = (list) => {
    setStateValue((prev) => {
      prev.article = list
      return { ...prev }
    })
  }
  const onChangePopup = (list) => {
    setStateValue((prev) => {
      prev.popup = list
      return { ...prev }
    })
  }
  const onChangeLink = (list) => {
    setStateValue((prev) => {
      prev.link = list
      return { ...prev }
    })
  }
  const onChangeVideo = (list) => {
    setStateValue((prev) => {
      prev.video = list
      return { ...prev }
    })
  }

  return (
    <>
      <Vr360Input key={key} defaultValue={stateValue?.vr360} onChange={onChangeVr360} />
      {/* <ArticleInput key={key + '2'} defaultValue={stateValue?.article} onChange={onChangeArticle} /> */}
      {/* <PopupInput key={key + '3'} defaultValue={stateValue?.popup} onChange={onChangePopup} /> */}
      <LinkInput key={key + '4'} defaultValue={stateValue?.link} onChange={onChangeLink} />
      {/* <VideoInput key={key + '5'} defaultValue={stateValue?.video} onChange={onChangeVideo} /> */}
    </>
  )
}

MetaDataInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default MetaDataInput;
