import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { LanguageState, TimeDurationState } from '../../appState';
import { LinkIconConfig } from '../../config';
import { AppData } from '../../data';
import { ModeEnum } from '../../enum';
import { DirectionTool, MarkerTool } from '../../tool';
import { useTime } from '../../useHooks';
import './routesInfor.scss';

const getLocationCenter = (locs) => {
  let center = null
  if (locs?.length <= 2) {
    center = {
      lng: Math.round((locs[0]?.lng + locs[1]?.lng) / 2 + "e+5") + "e-5",
      lat: Math.round((locs[0]?.lat + locs[1]?.lat) / 2 + "e+5") + "e-5",
    }
  }
  else {
    let index = Math.round(locs?.length / 2)
    center = locs[index - 1]
  }
  return center
}

const TypeIconModeEnum = {
  [ModeEnum.bike]: LinkIconConfig.routes.bike,
  [ModeEnum.car]: LinkIconConfig.routes.car,
  [ModeEnum.foot]: LinkIconConfig.routes.foot,
  [ModeEnum.motorcycle]: LinkIconConfig.routes.motorcycle
}

const RoutesInfor = (props) => {
  const { vehicle, routes, onChange, activeRoute } = props
  const listMarkerRef = useRef()
  const markerActiveRef = useRef()
  const zoomRef = useRef()
  const [routePositions, setRoutePositions] = useState(null)
  const [activeRouteState, setActiveRouteState] = useState(0)

  const language = useRecoilValue(LanguageState)
  const setTimeValue = useSetRecoilState(TimeDurationState)
  const valueTime = useTime()

  useEffect(() => {
    setActiveRouteState(activeRoute)
  }, [activeRoute])

  useEffect(() => {
    if (routes) {
      let list = []
      let listTime = []
      routes?.forEach((route, index) => {
        let listRoute = []
        route?.legs?.forEach(leg => {
          let listStep = leg?.steps?.map(p => p?.startLocation)
          listRoute.push(...listStep)
        })
        list.push(listRoute)
        let timeRoute = route?.duration?.value
        listTime.push({
          id: index,
          value: timeRoute
        }
        )
      })
      setRoutePositions(list)
      setTimeValue(listTime)
    }
    return () => setActiveRouteState(0)
  }, [routes])

  useEffect(() => {
    if (routePositions) {
      let listLoc = []
      let center
      routePositions?.forEach(locs => {
        center = getLocationCenter(locs)
        if (!listLoc?.find((item) => item?.lat == center?.lat)) {
          listLoc?.push(center)
        }
        else {
          let newLocs = locs.slice(1)
          center = getLocationCenter(newLocs)
          listLoc?.push(center)
        }
      })
      drawMarkerInfor(listLoc)
      return () => removePOI()
    }
  }, [valueTime, activeRouteState, language])

  useEffect(() => {
    const clickMarkerEvent = AppData.map.addListener("click", (args) => {
      markerActiveRef.current = args.marker
      let id = markerActiveRef.current?.getUserData()?.idInfor
      if (id) {
        let indexActive = markerActiveRef.current?.getUserData().index
        onChange && onChange(indexActive)
        setActiveRouteState(indexActive)
      }
    }, { marker: true })
    const changeZoom = AppData.map.addListener("zoomChanged", (args) => {
      let zoom = AppData.map.getCamera().getZoom()
      if (zoomRef.current - zoom > 2) {
        MarkerTool.hideMarkers(listMarkerRef.current)
      }
      else {
        MarkerTool.showMarkers(listMarkerRef.current)
      }
    })

    return () => {
      clickMarkerEvent?.remove()
      changeZoom?.remove()
      removePOI()
    }
  }, [])

  const drawMarkerInfor = (data) => {
    if (data?.length > 0) {
      let listMarker = []
      data?.forEach((poi, index) => {
        let marker = new map4d.Marker({
          position: poi,
          iconView: `
          <div class="routeInforCpn">
            <div class= "routeInforContainer" style=\"background-color: ${activeRouteState == index ? "#ffffff" : "#EBEBEB"};\">
              <div class="routeInforBody">
                <div class="bodyContent">
                  <img src=${TypeIconModeEnum[vehicle]} width={24} height={24}/>
                  <span class="duration">${valueTime[index]}</span>
                </div>
                <div class="distance">${DirectionTool.convertDistanceLocalize(routes[index]?.distance?.value, language)}</div>
              </div>
            </div>
            <div class="poiInfor"></div>
            <div class="poiInforBorder" style=\"border-top-color: ${activeRouteState == index ? "#ffffff" : "#EBEBEB"};\">
          </div>
      `,
          anchor: [1.0, 1.0],
          zIndex: activeRouteState == index ? 99 : 0
        })
        marker.setMap(AppData.map)
        marker.setUserData({
          index: index,
          idInfor: v4()
        })
        listMarker.push(marker)
        listMarkerRef.current = listMarker
        zoomRef.current = AppData.map.getCamera().getZoom()
      })
    }
  }

  const removePOI = () => {
    listMarkerRef?.current?.forEach((marker) => {
      marker?.setMap(null)
    })
  }

  return (<></>);
};

RoutesInfor.propTypes = {
  vehicle: PropTypes.string,
  routes: PropTypes.array,
  onChange: PropTypes.func,
  activeRoute: PropTypes.number
};

export default RoutesInfor;
