import React, { useEffect, useRef, useState } from 'react';
import { GeometryTypeEnum } from '../../enum';
import { Checkbox, FormControlLabel, makeStyles, Box } from '@material-ui/core';
import { AppData } from '../../data';
import { Resource } from '../../resource';

const useStyles = makeStyles({
  showHideBtn: {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0px 3px 6px #00000029',
    '& .MuiFormControlLabel-root': {
      marginBottom: 'unset',
      marginLeft: 'unset',
      '& .MuiFormControlLabel-label': {
        fontSize: '14px'
      },
      '& .MuiButtonBase-root': {
        padding: '6px'
      }
    }
  }
})

const OldPlaceBoundaryEdit = ({ geometry, isEditBoundaries }) => {
  const boundaryColor = isEditBoundaries ? "#3335DF" : '#FF0000'
  const classes = useStyles()

  const label = (geometry?.type == GeometryTypeEnum.lineString || geometry?.type == GeometryTypeEnum.multiLineString) ?
    Resource.common.showOrHideOldStreetBoundary :
    Resource.common.showOrHideOldAreaBoundary

  const [isShowBoundary, setIsShowBoundary] = useState(true)
  const [geometryState, setGeometryState] = useState({})

  const polylineRef = useRef(null)
  const multiPolylineRef = useRef([])
  const polygonRef = useRef(null)
  const multiPolygonRef = useRef([])

  useEffect(() => {
    if (geometry) {
      setGeometryState(geometry)
    }
  }, [geometry])

  useEffect(() => {
    if (geometryState.type) {
      drawPoly()
    }

    return () => {
      hidePoly()
    }
  }, [geometryState])

  const hidePoly = () => {
    polylineRef?.current?.setMap(null)
    multiPolylineRef?.current?.map(
      polyline => polyline?.setMap(null)
    )
    polygonRef?.current?.setMap(null)
    multiPolygonRef?.current?.forEach(
      polygon => polygon?.setMap(null)
    )
  }

  const showPoly = () => {
    polylineRef?.current?.setMap(AppData.map)
    multiPolylineRef?.current?.map(
      polyline => polyline?.setMap(AppData.map)
    )
    polygonRef?.current?.setMap(AppData.map)
    multiPolygonRef?.current?.forEach(
      polygon => polygon?.setMap(AppData.map)
    )
  }

  const handleHideBoundary = (e) => {
    const { checked } = e.target
    setIsShowBoundary(checked)
    if (checked) {
      showPoly()
    } else {
      hidePoly()
    }
  }

  const drawPolyline = (data) => {
    polylineRef.current = new map4d.Polyline({
      path: data || [],
      strokeColor: boundaryColor,
      strokeOpacity: 1.0,
      strokeWidth: 5,
      userInteractionEnabled: false,
    })
    polylineRef.current.setMap(AppData.map)
  }

  const drawMultiPolyline = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let polyline = new map4d.Polyline({
          path: data[i],
          strokeOpacity: 1,
          strokeWidth: 7,
          strokeColor: boundaryColor,
          userInteractionEnabled: false,
        })
        polyline.setMap(AppData.map)
        multiPolylineRef.current.push(polyline)
      }
    }
  }

  const drawPolygon = (data) => {
    polygonRef.current = new map4d.Polygon({
      paths: [data[0]],
      fillOpacity: 0.1,
      strokeWidth: 1,
      strokeColor: boundaryColor,
      userInteractionEnabled: true,
    })
    polygonRef.current.setMap(AppData.map)
  }

  const drawMultiPolygon = (data) => {
    if (data && data.length > 0) {
      let paths = []

      data.map(
        polygons => polygons.map(
          polygon => paths.push(polygon)
        )
      )

      data.forEach(polygons => {
        const polygon = new map4d.Polygon({
          paths: polygons,
          strokeColor: boundaryColor,
          fillOpacity: 0.1,
          strokeWidth: 1,
          userInteractionEnabled: true,
        })
        polygon.setMap(AppData.map)
        multiPolygonRef.current.push(polygon)
      });
    }
  }

  const drawPoly = () => {
    if (geometryState?.type) {
      switch (geometryState.type) {
        case GeometryTypeEnum.lineString:
          drawPolyline(geometryState.coordinates)
          break
        case GeometryTypeEnum.polygon:
          drawPolygon(geometryState.coordinates || [])
          break
        case GeometryTypeEnum.multiLineString:
          drawMultiPolyline(geometryState.coordinates || [])
          break
        case GeometryTypeEnum.multiPolygon:
          drawMultiPolygon(geometryState.coordinates || [])
          break
      }
    }
  }

  return (
    <>
      {
        isEditBoundaries ?
          <Box
            position="fixed"
            right={153}
            bottom={30}
            className={classes.showHideBtn
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  color='primary'
                  onChange={handleHideBoundary}
                  checked={isShowBoundary}
                />
              }
              label={label}
            />
          </Box >
          : ""
      }
    </>
  );
};

export default OldPlaceBoundaryEdit;
