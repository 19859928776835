import { DayOfWeekEnum } from "../enum";
import { Resource } from "../resource";

export const timeToMinutes = (text) => {
    let h = parseInt(text.substring(0, 2));
    let m = parseInt(text.substring(2, 4));
    return h * 60 + m;
};

export const roundTimeNumber = (num, distance = 30) => {
    let numTime = num > distance ? num + 1 : num
    return numTime
}

export const secondsToDhms = (seconds) => {
    let dayNum = Math.floor(seconds / (3600 * 24))
    let hourNum = Math.floor(seconds % (3600 * 24) / 3600)
    let minuteNum = Math.floor(seconds % 3600 / 60)
    let secondNum = Math.floor(seconds % 60)
    return {
        days: dayNum,
        hours: hourNum,
        minutes: minuteNum,
        seconds: secondNum
    }
};

const actionConvert = {
    "day": (seconds) => {
        const day = Math.floor(seconds / 86400)
        const hour = Math.round((seconds % 86400) / 3600)
        return {
            isDays: true,
            days: day,
            hour: hour
        }
    },
    "hour": (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minuteObj = actionConvert["minute"](seconds % 3600)
        return {
            isHour: true,
            hour: hours,
            minutes: minuteObj?.minutes
        }
    },
    "minute": (seconds) => {
        const minutes = Math.round(seconds / 60)
        return {
            isMinutes: true,
            minutes: minutes
        }
    },
    "second": (seconds) => {
        return {
            isSeconds: true,
            seconds: seconds
        }
    }
}

export const getObjTimeRound = (seconds) => {
    let time = ''
    if (seconds >= 86400) {
        time = actionConvert["day"](seconds)
    }
    else if (seconds >= 3600) {
        time = actionConvert["hour"](seconds)
    }
    else if (seconds >= 30) {
        time = actionConvert["minute"](seconds)
    }
    else {
        time = actionConvert["second"](seconds)
    }
    return time
}


export const timeToString = (date) => {
    let h = date.getHours();
    let m = date.getMinutes();
    let text1 = "0" + h.toString();
    text1 = text1.substring(text1.length - 2, text1.length);
    let text2 = "0" + m.toString();
    text2 = text2.substring(text2.length - 2, text2.length);
    return text1 + text2;
};

export const dayOfWeekText = (day) => {
    let dayText = "";
    switch (day) {
        case DayOfWeekEnum.sunday:
            dayText = Resource.common.sunday;
            break;
        case DayOfWeekEnum.monday:
            dayText = Resource.common.monday;
            break;
        case DayOfWeekEnum.tuesday:
            dayText = Resource.common.tuesday;
            break;
        case DayOfWeekEnum.wednesday:
            dayText = Resource.common.wednesday;
            break;
        case DayOfWeekEnum.thursday:
            dayText = Resource.common.thursday;
            break;
        case DayOfWeekEnum.friday:
            dayText = Resource.common.friday;
            break;
        case DayOfWeekEnum.saturday:
            dayText = Resource.common.saturday;
            break;
        default:
            break;
    }
    return dayText;
};
