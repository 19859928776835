import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import PropTypes from 'prop-types';
import { Resource } from '../../../resource';
import { LinkIconConfig, UrlConfig } from '../../../config';
import { ApiTool, AppTool, StringTool, UserTool } from '../../../tool';
import { AlertTypeEnum, CodeEnum } from '../../../enum';
import TooltipV2 from '../../../components/tooltipV2/tooltipV2';
import ActionButton from '../../../components/common/actionButton/actionButton';
import './mobileSaveButton.scss'

const borderColor = '#07B9AE'
const useStyles = makeStyles({
  root: {
    borderRadius: '50%',
    border: '1px solid #1C75BC',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  display: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textColor: {
    color: '#1C75BC'
  },
  dialogShowList: {
    maxWidth: '100%',
    width: '392px'
  },
  dialogAddList: {
    maxWidth: '100%',
    width: '352px'
  },
  dialogTitle: {
    textAlign: 'center'
  },

  btnSmall: {
    border: '1px solid #BBBBBB',
    borderRadius: '18px',
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    marginRight: '10px'
  },
  textColor: {
    color: '#1C75BC',
    margin: '0 10px'
  },
  icon: {
    marginLeft: '10px'
  }
})

const SIZE = 36

const ICON_SAVE =
  <svg width="15" height="15" viewBox="0 0 18 18">
    <path fill='none' d="M0,0H18V18H0Z" />
    <path fill='#1c75bc' d="M14,3H6.5A1.5,1.5,0,0,0,5,4.5v12l5.25-2.25L15.5,16.5V4.5A1.5,1.5,0,0,0,14,3Zm0,11.25-3.75-1.635L6.5,14.25V4.5H14Z" transform="translate(-1.25 -0.75)" />
  </svg>

const ICON_SAVE_BORDER =
  <svg width="15" height="15" viewBox="0 0 18 18">
    <path fill='none' d="M0,0H18V18H0Z" />
    <path fill='#07b9ae' d="M14,3H6.5A1.5,1.5,0,0,0,5,4.5v12l5.25-2.25L15.5,16.5V4.5A1.5,1.5,0,0,0,14,3Zm0,11.25-3.75-1.635L6.5,14.25V4.5H14Z" transform="translate(-1.25 -0.75)" />
  </svg>

function MobileSaveButton({ id, size = SIZE, onChange, isEllipse }) {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showAddList, setShowAddList] = useState(false)
  const [listName, setListName] = useState('')
  const groupsRef = useRef([])
  const [checks, setChecks] = useState([])
  const [count, setCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(true)

  const schema = yup.object().shape({
    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.name
      }))
  })
  const form = useForm({
    resolver: yupResolver(schema)
  })
  const { register, handleSubmit, errors, formState } = form

  useEffect(() => {
    var sourceList
    groupsRef.current = []
    if (UserTool.getUser()) {
      sourceList = ApiTool.get(UrlConfig.placeGroup.getByUser, (res) => {
        if (res?.code == CodeEnum.ok) {
          let list = res?.result || []
          let checksNew = []
          list.forEach((group) => {
            let find = group?.places?.find((place) => {
              return place.placeId == id
            })
            groupsRef.current.push({
              id: group.id,
              name: group.name,
              isSaved: find ? true : false
            })
            checksNew.push(find ? true : false)
          })
          let newCount = 0
          checksNew?.forEach(check => {
            if (check) {
              newCount++
            }
          })
          setCount(newCount)
          setChecks(checksNew)
        }
      }, true)
    }

    return () => {
      sourceList?.cancel()
    }
  }, [id])

  useEffect(() => {
    let isChange = false
    checks?.forEach((item, index) => {
      if (item != groupsRef.current[index].isSaved) {
        isChange = true
      }
    })
    setDisableBtn(!isChange)
  }, [checks])

  const handleShowList = () => {
    if (UserTool.getUser()) {
      setShowDialog(true)
    }
    else {
      let returnUrl = location.href;
      location.href = UrlConfig.auth.login + "?returnUrl=" + returnUrl;
    }
  }

  const handleShowAddList = () => {
    setShowAddList(true)
  }

  const handleCloseAddList = () => {
    setShowAddList(false)
  }

  const onChangeListName = (event) => {
    let value = event.target.value
    setListName(value)
  }

  const onSubmit = (data) => {
    let params = {
      name: data?.name,
      description: ''
    }
    ApiTool.post(UrlConfig.placeGroup.create, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        groupsRef.current.push({
          name: res?.result?.name,
          id: res?.result.id,
          isSaved: false
        })
        setChecks(prev => {
          prev.push(true)
          return [...prev]
        })
        AppTool.alert(
          Resource.formatString(Resource.alert.success.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
    setShowAddList(false)
  }

  const onChangeCheck = (index) => (e) => {
    setChecks(prev => {
      prev[index] = e.target.checked
      return [...prev]
    })
  }

  const handleDone = () => {
    let addList = []
    let newCount = 0
    groupsRef.current?.forEach((group, index) => {
      if (checks[index]) {
        addList.push({
          groupId: group.id,
        })
        newCount++
      }
    })

    let params = {
      groupInfos: addList,
      placeId: id
    }
    ApiTool.post(UrlConfig.placeGroup.saveToGroup, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        setCount(newCount)
        AppTool.alert(
          Resource.formatString(Resource.alert.success.edit, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        groupsRef.current?.forEach((group, index) => {
          group.isSaved = checks[index]
        })
        setDisableBtn(true)
        setShowDialog(false)
        onChange && onChange()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.edit, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const handleCancel = () => {
    let checkNew = []
    groupsRef.current.map((group) => {
      checkNew.push(group.isSaved ? true : false)
    })
    setChecks(checkNew)
    setShowDialog(false)
  }

  let isSaved = count > 0

  return (
    <>
      <div className={StringTool.mergeClassName('mobileSaveButtonCpn', isEllipse ? 'isEllipse' : '')}>
        <div
          className={StringTool.mergeClassName('circleBtn', isSaved ? 'savedBtn' : '')}
          onClick={handleShowList}
          style={{
            borderRadius: size / 2
          }}

        >
          <div
            className='icon'
            style={{
              width: size,
              height: size,
            }}>
            {isSaved ? ICON_SAVE_BORDER : ICON_SAVE}
          </div>

          <div className='caption2'>
            <div>
              {isSaved ? `${Resource.common.saved} (${count})` : Resource.common.bookmark}
            </div>

          </div>
        </div>
        <div className='caption'>
          <div>
            {isSaved ? `${Resource.common.saved} (${count})` : Resource.common.bookmark}
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: classes.dialogShowList
          }
        }
      >
        <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.placeGroup.saveOnYourList}</DialogTitle>
        <DialogContent dividers>
          <List disablePadding>
            {
              checks?.map((checked, index) => {
                return (
                  <ListItem button key={v4()}>
                    <ListItemIcon>
                      <img src={LinkIconConfig.placeGroupIcon.list} width={15} height={15} />
                    </ListItemIcon>
                    <ListItemText
                      title={groupsRef.current[index]?.name}
                      primary={<Typography variant='body1' noWrap>{groupsRef.current[index]?.name}</Typography>}
                      primaryTypographyProps={{
                        noWrap: true
                      }}
                    />
                    <Checkbox
                      checked={checked}
                      name="save"
                      checkedIcon={<CircleCheckedFilled />}
                      icon={<CircleUnchecked />}
                      color='primary'
                      onChange={onChangeCheck(index)}
                    />
                  </ListItem>
                )
              })
            }
            <ListItem button onClick={handleShowAddList}>
              <Button color='primary' variant='text' startIcon={<AddIcon />}>{Resource.placeGroup.newList}</Button>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Box width='100%' textAlign='center'>
            <ActionButton name={Resource.button.cancel} onClick={handleCancel} isSecondary />
            <ActionButton disabled={disableBtn} name={Resource.button.done} onClick={handleDone} />
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showAddList}
        onClose={handleCloseAddList}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: classes.dialogAddList
          }
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.placeGroup.newList}</DialogTitle>
          <DialogContent dividers>
            <Box textAlign='right' clone>
              <Typography variant='caption' component='p' gutterBottom>{`${listName?.length || 0}/50`}</Typography>
            </Box>

            <TextField
              label={Resource.placeGroup.listName}
              variant='outlined'
              fullWidth
              onChange={onChangeListName}
              name='name'
              inputRef={register}
              inputProps={{ maxLength: 50 }}
              error={errors?.name ? true : false}
              helperText={errors?.name?.message}
            />
          </DialogContent>
          <Box p={1} display='flex' alignItems='center' justifyContent='center'>
            <Button variant='contained' color='primary' type='submit'>{Resource.button.create}</Button>
          </Box>
        </form>
      </Dialog>

    </>
  )
}

MobileSaveButton.propTypes = {
  isEllipse: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.any,
  size: PropTypes.any
};

export default MobileSaveButton;
