import React, { Component, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, FormHelperText, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import FieldControl from '../fieldControl/fieldControl';
import { Resource } from '../../../resource';
import { MapEventEnum } from '../../../enum';
import { MarkerTool, PolygonTool } from '../../../tool';
import { UrlConfig } from '../../../config/urlConfig';
import { LinkIconConfig } from '../../../config';

const StatusEnum = Object.freeze({
  none: "none",
  draw: "draw",
  edit: "edit",
  haveModel: "haveModel"
});

const createPointMarker = (location) => {
  let marker = new map4d.Marker({
    position: location,
    icon: new map4d.Icon(7, 7, LinkIconConfig.polygonInput.point),
    anchor: [0.5, 0.5],
    draggable: true,
    zIndex: 1000
  });
  return marker
}
const createPolygon = () => {
  let polygonOption = {
    paths: [[]],
    fillColor: "#0000ff",
    fillOpacity: 1.0,
  }
  let polygon = new map4d.Polygon(polygonOption)
  return polygon
}
const createPolyline = () => {
  let polyline = new map4d.Polyline({
    path: [],
    strokeColor: "#ff0000",
    strokeOpacity: 1.0,
    strokeWidth: 1,
    userInteractionEnabled: false
  })
  return polyline
}

function PolygonInput(props) {
  const { defaultValue, value, onChange, map, onChangeMode } = props
  const [stateValue, setStateValue] = useState(defaultValue || []);
  const [status, setStatus] = useState(StatusEnum.none)
  const statusRef = useRef(StatusEnum.none)
  const polygonRef = useRef(null)
  const polylineRef = useRef(null)
  const highlightPolylineRef = useRef(null)
  const markersRef = useRef([])


  const reset = () => {
    polylineRef.current?.setMap(null)
    highlightPolylineRef.current?.setMap(null)
    polygonRef.current?.setMap(null)
    polygonRef.current = createPolygon()
    polylineRef.current = createPolyline()
    highlightPolylineRef.current = createPolyline()
    MarkerTool.removeMarkers(markersRef.current);
    markersRef.current = []
  }

  const endDraw = () => {
    highlightPolylineRef.current && highlightPolylineRef.current.setMap(null)
    polylineRef.current && polylineRef.current.setMap(null)
    MarkerTool.hideMarkers(markersRef.current)
    if (markersRef.current?.length >= 3) {
      let coors = MarkerTool.getCoordinatesFromListMarker(markersRef.current)
      setStateValue(coors)
    }
    else {
      setStateValue(null)
      setStatus(StatusEnum.none)
    }
  }

  useEffect(() => {
    statusRef.current = status
    onChangeMode((status == StatusEnum.draw || status == StatusEnum.edit))
  }, [status])

  useEffect(() => {
    polygonRef.current = createPolygon()
    polylineRef.current = createPolyline()
    highlightPolylineRef.current = createPolyline()
    return () => {
      polygonRef.current?.setMap(null)
      polylineRef.current?.setMap(null)
      highlightPolylineRef.current?.setMap(null)

    }
  }, [])

  useEffect(() => {
    // if (!("value" in props)) {
    if (stateValue?.length >= 3) {
      MarkerTool.removeMarkers(markersRef.current)
      markersRef.current = []
      stateValue?.forEach(coor => {
        let marker = createPointMarker(coor)
        marker.setMap(map)
        markersRef.current.push(marker)

      })
      let coors = MarkerTool.getCoordinatesFromListMarker(markersRef.current)
      let end = { ...coors[0] }
      let paths = [...coors]
      paths.push(end)
      polygonRef.current.setPaths([paths])
      MarkerTool.hideMarkers(markersRef.current)
      setStatus(StatusEnum.haveModel)
      onChange && onChange(coors)
    }
    else {
      reset()
      setStatus(StatusEnum.none)
      onChange && onChange(null)
    }
    // }
  }, [stateValue, map])

  useEffect(() => {
    if (value?.length >= 3) {
      MarkerTool.removeMarkers(markersRef.current)
      markersRef.current = []
      value?.forEach(coor => {
        let marker = createPointMarker(coor)
        marker.setMap(map)
        markersRef.current.push(marker)

      })
      let coors = MarkerTool.getCoordinatesFromListMarker(markersRef.current)
      let end = { ...coors[0] }
      let paths = [...coors]
      paths.push(end)
      polygonRef.current.setPaths([paths])
      polygonRef.current?.setMap(map)
      MarkerTool.hideMarkers(markersRef.current)
      setStatus(StatusEnum.haveModel)
    }
    else {
      reset()
      setStatus(StatusEnum.none)
    }

  }, [value, map])

  useEffect(() => {
    const eventClickMap = map.addListener(MapEventEnum.click, (args) => {
      if ((statusRef.current == StatusEnum.draw || statusRef.current == StatusEnum.edit)) {
        let marker = createPointMarker(args.location)
        marker.setMap(map)
        markersRef.current.push(marker)
        if (markersRef.current && markersRef.current.length >= 3) {
          let coors = MarkerTool.getCoordinatesFromListMarker(markersRef.current)
          let end = { ...coors[0] }
          let paths = [...coors]
          paths.push(end)
          polygonRef.current?.setPaths([paths])
          if (markersRef.current.length == 3) {
            polygonRef.current?.setMap(map)
            polylineRef.current.setMap(null)
            polylineRef.current = createPolyline()
            let coordinateTransformer = new map4d.CoordinateTransformer([...coors])
            let center = coordinateTransformer.center
          }
        }
        else {
          polylineRef.current?.setPath(MarkerTool.getCoordinatesFromListMarker(markersRef.current))
        }
      }
    }, { location: true, polygon: true })
    const eventDblClick = map.addListener(MapEventEnum.dblClick, (args) => {
      if ((statusRef.current == StatusEnum.draw || statusRef.current == StatusEnum.edit)) {
        endDraw()
      }
    }, { marker: true })
    // const eventDragStartPolygon = map.addListener(MapEventEnum.dragStart, (args) => {
    //   polylineRef.current?.setMap(null)
    //   highlightPolylineRef.current?.setMap(null)
    //   MarkerTool.hideMarkers(markersRef.current)
    // }, { polygon: true })
    // const eventDragEndPolygon = map.addListener(MapEventEnum.dragEnd, (args) => {
    //   let coors = PolygonTool.getCoordinatesFromPolygon(polygonRef.current)
    //   markersRef.current.forEach((m, index) => {
    //     m.setPosition(coors[index])
    //   })
    //   if ((statusRef.current == StatusEnum.draw || statusRef.current == StatusEnum.edit)) {
    //     polylineRef.current && polylineRef.current.setMap(map)
    //     highlightPolylineRef.current && highlightPolylineRef.current.setMap(map)
    //     MarkerTool.showMarkers(markersRef.current)
    //   }
    //   setStateValue(coors)
    //   let coordinateTransformer = new map4d.CoordinateTransformer([...coors])
    //   let center = coordinateTransformer.center
    // }, { polygon: true })

    // const eventDragEndObject3D = map.addListener(MapEventEnum.dragEnd, (args) => {
    //   let coors = objectReview.getCoordinates()
    //   markersRef.current.forEach((m, index) => {
    //     m.setPosition(coors[index])
    //   })
    //   let end = { ...coors[0] }
    //   let paths = [...coors]
    //   paths.push(end)
    //   polygonRef.current?.setPaths([paths])
    // }, { object: true })

    const eventDragMarker = map.addListener(MapEventEnum.drag, (args) => {
      if (markersRef.current && markersRef.current.length >= 3) {
        let coors = MarkerTool.getCoordinatesFromListMarker(markersRef.current)
        let end = { ...coors[0] }
        let paths = [...coors]
        paths.push(end)
        polygonRef.current?.setPaths([paths])
      }
    }, { marker: true })
    const eventMouseMove = map.addListener(MapEventEnum.mouseMove, (args) => {
      if ((statusRef.current == StatusEnum.draw || statusRef.current == StatusEnum.edit)) {
        if (markersRef.current && markersRef.current.length > 0) {
          highlightPolylineRef.current.setPath([markersRef.current[markersRef.current.length - 1].getPosition(), args.location])
        }
      }

    }, { location: true })

    const eventDragStartMarker = map.addListener(MapEventEnum.dragStart, (args) => {
      highlightPolylineRef.current?.setMap(null)
    }, { marker: true })

    const eventDragEndMarker = map.addListener(MapEventEnum.dragEnd, (args) => {
      highlightPolylineRef.current?.setMap(map)
    }, { marker: true })

    return () => {
      eventClickMap?.remove()
      eventDblClick?.remove()
      // eventDragStartPolygon?.remove()
      // eventDragEndPolygon?.remove()
      eventDragMarker?.remove()
      eventMouseMove?.remove()
      eventDragStartMarker?.remove()
      eventDragEndMarker?.remove()
    }

  }, [map])

  const handleClickStartDraw = (e) => {
    polylineRef.current?.setMap(map)
    highlightPolylineRef.current?.setMap(map)
    polygonRef.current?.setMap(map)
    MarkerTool.showMarkers(markersRef.current)
    setStatus(StatusEnum.draw)
  }
  const handleClickEndDraw = (e) => {
    endDraw()
  }
  const handleClickEdit = (e) => {
    polylineRef.current.setMap(map)
    highlightPolylineRef.current.setMap(map)
    polygonRef.current.setMap(map)
    MarkerTool.showMarkers(markersRef.current)
    setStatus(StatusEnum.edit)
  }
  const handleClickDelete = (e) => {
    reset()
    setStateValue(null)
    setStatus(StatusEnum.none)
  }

  return (
    <FieldControl label={Resource.polygon.add}>
      {
        status == StatusEnum.none &&
        <button className="btn btn-primary" type="button"
          onClick={handleClickStartDraw}
        >{Resource.common.startDraw}</button>
      }

      {
        (status == StatusEnum.draw || status == StatusEnum.edit) &&
        <button className="btn btn-success" type="button"
          onClick={handleClickEndDraw}
        >{Resource.common.endDraw}</button>

      }
      {
        status == StatusEnum.haveModel &&
        <button className="btn btn-primary" type="button"
          onClick={handleClickEdit}
        >{Resource.common.edit}</button>

      }
      {
        (status == StatusEnum.draw || status == StatusEnum.edit || status == StatusEnum.haveModel) &&
        <button className="btn btn-danger ml-1" type="button"
          onClick={handleClickDelete}
        >{Resource.common.delete}</button>
      }
    </FieldControl>
  )
}

PolygonInput.propTypes = {
  map: PropTypes.object.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onChangeMode: PropTypes.func
};

export default PolygonInput;
