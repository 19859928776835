import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowCircleMenuState } from '../../appState';

const HideCircleMenu = () => {
  const setShow = useSetRecoilState(ShowCircleMenuState)

  useEffect(() => {
    setShow(false)
    return () => {
      setShow(true)
    }
  }, [])

  return null
};

HideCircleMenu.propTypes = {
  //
};

export default HideCircleMenu;
