import { LanguageCodeEnum } from "../enum";

export const en = {
  common: {
    policy: "Policy",
    termsOfUse: "Terms of use",
    moreOption:'Options',
    settingTime:'Setting time',
    steps: 'Steps',
    start: 'Start',
    call: 'Call',
    way: 'Way',
    day: 'Day',
    hour: 'Hour',
    minutes: 'Minutes',
    seconds: 'Seconds',
    option: 'Option',
    destination: 'Destination',
    tourism: 'Tourism',
    departNow: 'Depart now',
    departAt: 'Depart at',
    backToDirection: "Back to the directions page",
    vie: 'Vie',
    avoid: 'Avoid',
    month: 'Month',
    year: 'Year',
    noInfo: 'No information',
    website: 'Website',
    noData: 'No data',
    preview: 'Preview',
    city: "City",
    all: "All",
    developerPage: "Developer page",
    govPage: "Government user page",
    change: "Change",
    street: "Street",
    type: "Type",
    noPlaceInBuilding: "No places in the same building",
    locationSameBuilding: "Related places in building",
    sameLocation: "Related places",
    introduce: "Introduction",
    managePage: "Management page",
    removeNode: "Remove node",
    area: "Area",
    removeFile: "Delete file",
    dragtext: "Drag and drop geojson file (GIS File)",
    user: "User",
    admin: "Administrator",
    verify: "Verify",
    sale: "Sale",
    confirm: "Confirm",
    chooseLanguage: "Choose language",
    showOrHideOldAreaBoundary: "Show/ hide old area boundary",
    showOrHideOldStreetBoundary: "Show/ hide old street boundary",
    file: "File",
    collapseThePanel: "Collapse the panel",
    expandThePanel: "Expand the panel",
    removeThisPlace: "Remove this place",
    reverseYourStartingPointAndDestination:
      "Reverse your starting point and destination",
    back: "Back",
    otherOption: "Other option",
    close: "Close",
    open: "Open",
    clear: "Clear",
    essentialServiceClass: "Essential service class",
    enterKeyword: "Enter keyword...",
    testVersion: "Test version",
    chatWithUs: "Chat with us",
    account: "Account",
    seeMore: "See more",
    current: "Current",
    urlOrIframe: "Url or Iframe",
    menu: "Menu",
    searchResult: "Search result",
    showResult: "Show result",
    layer: "Layer",
    phoneNumber: "Phone number",
    foliage: "Foliage area",
    typeInfo: "Information type",
    tree: "Tree",
    floorArea: "Floor area",
    numberFloor: "Number of floors",
    foliage: "Foliage area",
    chooseStartingPointOrClickOnTheMap:
      "Choose starting point or click on the map",
    chooseDestination: "Choose destination",
    savedInto: "Saved in",
    viewList: "View list",
    update: "Update",
    timeMove: "Travel time",
    totalTime: "Total Time",
    expandMore: "Expand more",
    expandLess: "Expand less",
    displayMapDataOverTime: "Show map data with selected time",
    notYetAddress: "No address yet",
    notYetName: "No name yet",
    myLocation: "My location",
    yourLocation: 'Your location',
    requestUpdate: "Suggest an edit",
    image: "Image",
    create: "Create",
    province: "Province",
    modelFile: "Model file",
    order: "Order",
    content: "Content",
    title: "Title",
    iframe: "Path",
    path: "Path",
    note: "Note",
    embed: "Embed code",
    addNew: "Add new",
    addressCode: "Address code",
    address: "Address",
    homePrivate: "Home",
    office: "Work",
    home: "Home",
    company: "Work",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    place: "Place",
    category: "Category",
    number: "Number",
    elevation: "Elevation",
    bearing: "Rotation angle",
    scale: "Scale",
    model: "Model",
    bottomSurface: "Bottom surface",
    lat: "Latitude",
    lng: "Longitude",
    name: "Name",
    noOption: "Data not found",
    businessHours: "Working hours",
    startDate: "Start date",
    endDate: "End Date",
    avatar: "Avatar",
    edit: "Edit",
    search: "Search",
    noPlaceFound: "Place not found",
    addMissingPlace: "Add missing place",
    up: "Up",
    down: "Down",
    zoomIn: "Zoom in",
    zoomOut: "Zoom Out",
    left: "Turn left",
    right: "Turn right",
    navigation: "Navigation",
    location: "Current location",
    status: "Status",
    cancel: "Cancel",
    apply: "Apply",
    login: "Login",
    copy: "Copy",
    next: "Previous",
    previous: "After",
    images: "Images",
    close: "Close",
    finish: "Finish",
    detail: "Details",
    link: "Link",
    vr360: "VR360",
    share: "Share",
    popup: "Embed code",
    video: "Video",
    direction: "Directions",
    list: "List",
    bookmark: "Save",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    datetime_24h: "Open all day",
    time: "Time",
    datetime_business_hours: "Opening hours",
    sunday_acronym: "Sun",
    monday_acronym: "Feb",
    tuesday_acronym: "Mar",
    wednesday_acronym: "Wed",
    thursday_acronym: "May",
    friday_acronym: "Jun",
    saturday_acronym: "Sat",
    datetime_closed: "Closed",
    cancel: "Close",
    apply: "Apply",
    login: "Login",
    copy: "Copy",
    next: "Next",
    previous: "Before",
    images: "Images",
    close: "Close",
    detail: "Details",
    link: "Link",
    article: "Articles",
    vr360: "VR360",
    share: "Share",
    popup: "Embed code",
    Video: "Video",
    direction: "Directions",
    list: "List",
    bookmark: "Save",
    searching: "Searching ...",
    noOptionsText: "No result",
    search: "Search",
    atm: "ATM",
    restaurant: "Restaurant",
    coffee: "Coffee",
    park: "Parking",
    BackToResult: "Back to results",
    backToTop: "Back to top",
    showMore: "Show more",
    lessThan: "Hide all",
    less: "Hide all",
    bar: "Bar",
    grocery: "Grocery",
    gasStation: "Gas station",
    hotel: "Hotel",
    hospital: "Hospital",
    school: "School",
    postOffice: "Post Office",
    bank: "Bank",
    hotelNearDestination: 'Hotels nearby',
    restaurantNearDestination: 'Restaurants nearby',
    tourismNearDestination: 'Tourisms nearby',
    adminPage: "Admin page",
    userPage: "User page",
    reportError: "Report a bug",
    logout: "Logout",
    suggestPlace: "Suggested place",
    classService: "Class service",
    object3d: "3D object",
    objectClass: "Object class",
    setting: "Settings",
    language: "Language",
    myPlace: "Your places",
    myAccessKey: "Your access key",
    building: "building",
    my3DObject: "Your 3D objects",
    showingResult: "Showing results",
    closeTime: "Close",
    openAllTime: "Open all day",
    opening: "Opening",
    save: "Save",
    shortest: "Shortest",
    fastest: "Fastest",
    balance: "Balance",
    from: "From",
    to: "To",
    buildingDetail: "Building Detail",
    directFromHere: "Directions from here",
    directToHere: "Directions to here",
    addPointTo: "Add destination",
    noMediaFiles: "There are no videos and images for this place",
    noImages: "There are no images for this place",
    noVideos: "There is no video for this place",
    minZoom: "Min zoom",
    maxZoom: "Max zoom",
    saved: "Saved",
    draw: "Draw",
    upload: "Upload",
    height: "Height",
    startDraw: "Start drawing",
    endDraw: "Finish drawing",
    texture: "Texture image",
    dragtext: "Drag and Drop geojson files(GIs Files)",
    nameFile: "Add file",
    send: "Send",
    description: "Description",
    reason: "Reason",
    [LanguageCodeEnum.vi]: "Vietnamese",
    [LanguageCodeEnum.en]: "English",
    notFound: "Data not found",
    linkShare: "Link to share",
    endData: "You've reached the end of the list.",
    hotelAlongRoute: 'Hotel along the route',
    restaurantAlongRoute: 'Restaurant along the route',
    tourismAlongRoute: 'Tourism along the route',
    selectHour: "Selected hour",
    routePreview: "Route Preview",
    total: "Total"
  },
  modelFile: {
    add: "Add model file",
  },
  file: {
    add: "Add file",
  },
  bottomSurface: {
    add: "Add bottom surface",
  },
  polygon: {
    add: "Add polygon",
  },
  startDate: {
    choose: "Choose start date",
  },
  category: {
    cate: "Category",
    add: "Add category",
    selectObjectCategory: "Select object category",
    main: "Main category",
    select: "Select category"
  },
  name: {
    add: "Add name",
  },
  startDate: {
    choose: "Choose start date",
  },
  endDate: {
    choose: "Choose an end date",
  },
  elevation: {
    add: "Add elevation",
  },
  bearing: {
    add: "Add rotation angle",
  },
  lat: {
    add: "Add latitude",
  },
  lng: {
    add: "Add longitude",
  },
  minZoom: {
    choose: "Choose zoom level",
  },
  maxZoom: {
    choose: "Choose zoom level",
  },
  model: {
    choose: "Choose model",
    type: "Model Type",
    chooseType: "Choose model type",
    available: "Models available",
  },
  scale: {
    add: "Add scale",
  },
  bearing: {
    add: "Add rotation angle",
  },
  height: {
    add: "Add height",
  },
  title: {
    add: "Add title",
  },
  object3d: {
    building: "Building",
    multi: "Multiple 3D objects",
    addMulti: "Add multiple 3D objects",
    add: "Add 3D object",
    requestUpdate: "Suggest editing 3D object",
  },
  place: {
    addPlace: "Add new place",
    editPlace: "Edit place",
    province: "Province",
    district: "District",
    subDistrict: "Commune/Ward",
    hamlet: "Hamlet/Village",
    street: "Street name",
    houseNumber: "Alley-Narrow-Alley-House numbers",
    phoneNumber: "Phone number",
    website: "Website",
    placeType: "Place type",
    object: "Object",
    search: "Search for places",
    requestUpdate: "Request update place",
    requestUpdatePlace: "Request update {place}",
    infoAboutPlace: 'Information about the place',
    captionInfoPlace: 'Give some info about this place. This location will be publicly visible if added to the map.',
    addPhoto: 'Add an image to this place (e.g. a photo of a storefront, menu, or similar). These photos are publicly available.',
    photo: 'Photos of the place',
    addOtherInfo: 'Add other information',
    addOtherInfoCaption: 'Add your building, phone number, website, or more description to help us verify this location.',
    maximumCharacters: 'Số ký tự tối đa'
  },
  object: {
    name: "Object",
    poi: "Poi",
  },
  hashTag: {
    tag: "Tag",
    add: 'Add tag',
    addForPlace: 'Add a hash tag for the place'
  },
  validate: {
    required: "{field} is required",
    number: "{field} must be numeric",
    url: "{field} is not valid",
    min: "{field} must be greater than or equal to {min}",
    max: "{field} must be less than or equal to {max}",
    moreThan: "{field} must be greater than {min}",
    lessThan: "{field} must be less than {max}",
    code: "{field} only includes the characters a-z, A-Z, 0-9",
    typeError: "{field} must be type {type}",
    duplicate: "{field} is exist",
    contentError: "Content {object} is not incorrect",
    format: "{field} is not in the correct format",
    formatImage: 'The formats are allowed to choose: {format}',
    maxCapacityImage: 'The image you choose exceeds {numberUnit}',
    maxTotalCapacityImage: 'Total image size exceeds {numberUnit}'
  },
  fieldValidate: {
    name: "Name*",
    placeType: "Place type *",
    lat: "Latitude *",
    lng: "Longitude *",
  },

  updatePlace: {
    stringJson: "Json format",
    deleteThisObject: "Delete this object",
    requestUpdate: "Suggest an edit",
    requestUpdateRoad: "Editing road information",
    requestUpdateBoundaries: 'Edit cadastral boundary information',
    name: "Name",
    category: "Category",
    location: "Location",
    timeOpen: "Opening hours",
    contact: "Contact",
    web: "Website",
    description: "Description",
    province: "Province",
    district: "District",
    subDistrict: "Commune/Ward",
    hamlet: "Hamlet/Village",
    street: "Street name",
    houseNumber: "Alley/Narrow/Alley/House Number",
    building: "Building",
    tag: "Tag",
    addPhoto: "Add photo",
    descriptionPhotoFeild:
      "Add a photo of this place (example: Front view photo of store, Menu or similar photos). These images are publicly available. Image only for format .jpg .png .jpeg ",
    article: "Articles",
    vr360: "Vr360",
    notes: "Note",
    addExtendBtn: "Add Virtural 360, Link",
    addName: "Add name",
    addCategory: "Add category",
    addTag: "Add tag",
    searchObject: "Find object",
    addOpenTime: "Add opening hours",
    addPhoneNumber: "Add phone number",
    addDescriptionForLocation: "Add description for the place",
    addWebsite: "Add a website",
    addVr360: "Add VR360",
    addNote: "Add note",
    addArticle: "Add article",
    addName: "Add name",
    addCategory: "Add category",
    addBuilding: "Add building",
    addReason: "Add reason",
    reasonUpdate: "The reason for editing",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    timeOpen: "Opening hours",
    timeClose: "Closing time",
    markClosedNonexistentOrDuplicated:
      "Mark closed, does not exist or duplicates.",
    deleteThisPlace: "Delete this place",
    changeTheNameOrOtherDetails: "Change the name or other details",
    editNameLocationTime: "Edit name, location, time, ...",
    yourRecommendation: "Your contributions",
    markAsClosedOrDeleted: "Mark as closed or deleted",
    reason: "Reason",
    addRemovalReason: "Add reason to remove",
    requestUpdateObject: "Editing building information",
    requestUpdatePoi: "Editing place information"
  },

  street: {
    photo: 'Images of the road',
    addPhoto: 'Add images of the road, which will be publicly visible on the map.',
    addOtherInfoRoadCaption: 'Describe more about the path to help us verify this path.',
    addOtherInfo: 'Expanded information',
    infoAboutStreet: 'Road information',
    captionInfoStreet: 'Please confirm some information about this road. Edited content will be publicly visible on the map.'
  },

  boundary: {
    photo: 'Images of the cadastral boundaries',
    addPhoto: 'Add images of the cadastral boundaries, which will be publicly displayed on the map.',
    addOtherInfoBoundaryCaption: 'Add virtual 360, link, website or further description to help us verify this cadastral boundary.',
    addOtherInfo: 'Expanded information',
    infoAboutBoundary: 'Information about cadastral boundaries',
    captionInfoBoundary: 'Provide some information about this cadastral boundary area. Edited content will be publicly visible on the map.'
  },

  button: {
    close: "Close",
    cancel: "Cancel",
    send: "Send",
    showList: "Show list",
    showMap: "Show map",
    create: "Create",
    done: "Done",
    viewAll: "View all",
    hideLess: "Hide less",
    save: "Save",
    ok: "OK",
    add: 'Add',
    viewMap: 'View map',
  },
  placeDetail: {
    noImages: "There are no images for this place",
    photoVideo: "Photo - Video",
  },
  placeSearch: {
    listPlace: "List of places",
  },
  placeGroup: {
    listName: "List name",
    saveOnYourList: "Save in your list",
    newList: "New list",
    showMap: "Show on your map",
    delete: "Delete list",
    share: "Share list",
    edit: "Edit list",
    addList: "Add new list",
  },
  placeDetail: {
    noImages: "There are no images for this place",
    photoVideo: "Photo - Video",
  },
  placeSearch: {
    listPlace: "List of places",
  },

  alert: {
    success: {
      addInto: "Add {object1} into {object2} successfully",
      addMultiBuilding: "Add multiple building successfully",
      add: "Add {object} successfully and is awaiting for approval",
      edit: "Update {object} successfully",
      editAddress: "Update {object} address successfully",
      delete: "Delete {object} successfully",
      request: "Request to {action} {object} successfully",
      create: "Create {object} successfully",
      save: "Save {object} successfully",
      saveAddress: "Save address {object} successfully",
      send: "Send {object} successfully and is awaiting for approval",
      update: "Update {object} successfully",
      requestUpdate: "Request update {object} successfully",
      copied: "Copied"
    },
    error: {
      addInto: "Add {object1} into {object2} failed",
      addMultiBuilding: "Add multiple building failed",
      add: "Adding {object} failed",
      edit: "Update {object} failed",
      editAddress: "Update {object} address failed",
      delete: "Deleting {object} failed",
      request: "Request to {action} {object} failed",
      create: "{object} creation failed ",
      save: "Saving {object} failed",
      saveAddress: "Save {object} address failed",
      send: "Sending {object} failed",
      find: "No matching {object} found",
      upload: "{object} upload failed",
      update: "Update {object} failed",
      requestUpdate: "Request update {object} failed, please try again",
    },
    warning: {
      addMultiBuilding: "Must add at least one building",
    },
  },
  personalAddress: {
    updateAddress: "Edit {object} address",
    setLocation: "Set location",
  },
  note: {
    add: "Add new note",
    edit: "Edit note",
    addNoteForThisPlace: "Add note for this place?",
  },
  list: {
    edit: "Edit list",
    delete: "Delete list",
  },
  confirmMessage: {
    delete: "Are you sure you want to delete {object}?",
    cancel: "Are you sure want to cancel {action}",
    cancelThis: "Are you sure you want to cancel this {action}?",
    cancelRequestUpdate: "Do you want to cancel this request to edit {object}?",
    cancelRequest: "Do you want to cancel this request to {action} {object}?",
    cancelRequestUpdatePlace: 'Are you sure you want to cancel the changes you made?'
  },
  geocode: {
    error: "Unable to locate your location",
  },
  layer: {
    venue: "Place",
    address: "Address",
    street: "Street",
    admin_level_1: "Nation",
    admin_level_2: "Province",
    admin_level_3: "District",
    admin_level_4: "Ward",
  },
  message: {
    notAvailable: 'Not available',
    errorDirection: "Route Error",
    dataNotFound: "Data not found",
    dataDoesNotExist: "Data does not exist",
    resultsNotFoundInTheCurrentRegion:
      "Results not found {text} in the current region.",
    pleaseCheckYourSearchKeywordsOrExpandTheSearch:
      "Please check your search keywords, or expand the search.",
    pleaseCheckYourSearchKeywords: "Please check your search keywords",
    notUpdatedInfo: "There are no changes",
    nameForHashtag: "The label name only includes the characters a-z,A-Z,0-9,_",
    maximumCharacters: 'Max number of characters',
    discoverPlacesNear: 'Discover places near',
    discoverPlacesNearYourDestination: 'Discover places near your destination',
    notFindLocation: "We can't find your location",
    notUsedLocation: "Map4D isn't allowed to use your location"
  },
  mode: {
    drive: "Driving a car",
    driveMotorcycle: "Driving a motobike",
    rideBicycle: "Driving a bicycle",
    walk: "On foot",
  },
  vehicle: {
    car: 'Car',
    motorbike: 'Motorbike',
    bicycle: 'Bicycle',
    walk: "Walk",
  },
  direction: {
    route: {
      fastest: "Fastest route",
      shortest: "Shortest route",
      balance: "Most optimal route",
      optimization: 'Route optimization'
    },
    avoid: {
      highWay: "HighWays",
      ferryRoute: "Ferries",
      tunnel: "Tunnels",
      toll: "Tolls"
    },
    noName: "Via unnameed roads",
    arrRouteOptimization: 'Arrange the order of stops for the best route optimization',
    maxDestinations: 'The maximum number of destinations has been reached',
    multipleDestination: 'Multiple destinations will not apply to "Depart at"',
    alreadyOptimize: 'The route you chose is optimized',
    suggestMessage: 'Did you mean',
    captionTextSearch: 'Please make sure your search is correct or you can try with another keyword.',
    errorMessage: {
      vehicle: "Sorry, your search appears to be outside of our current service area for",
      api: "Sorry, we couldn't find directions",
      poi: "Sorry, we couldn't find your way",
      textSearch: 'Sorry, we did not find',
      homeOrWork: "Sorry, we couldn't find the location. Please check the address and try again."
    }
  },

  objectLayer: {
    category: "Category",
    chooseObjectLayer: "Choose object layer",
    chooseAll: "Choose all",
  },

  effect: {
    displayOptions: "Display options",
    displaySettings: "Display Settings",
    display3D: 'DISPLAY 3D',
    weather: "Weather",
    currentTime: "Time (currently)",
    display: "Display",
    auto: "Auto",
    time: {
      morning: "Morning",
      noon: "Noon",
      afternoon: "Afternoon",
      moon: "Moon",
    },
    weatherOptions: {
      sunny: "Sunny",
      rainy: "Rainy",
      snowy: "Snowy",
    },
    options: {
      shadowEffect: "Shadow effect",
      weatherEffectsTime: "Weather, time effect",
      object3D: "3D object",
      waterSurfaceEffect: "Water surface effect",
      poi: "POI",
    },
  },

  myPoi: {
    labeled: "Labeled",
    saved: "Saved",
  },
  mapType: {
    satellite: "Satellite",
  },
  myLocation: {
    alert: {
      error: "Map are not allowed to use your location",
    },
  },
  user: {
    page: "Profile page",
    manage: "Account management",
  },
  suggestPlaceChip: {
    coffee: "Coffee",
    gasStation: "Gas",
    grocery: "Grocery",
    pharmacy: "Pharmacy",
    restaurant: "Restaurant",
    school: "School",
    more: "More",
    hotel: "Hotels",
    charging: "EV charging",
    thingsToDo: "Things to do",
    homestay: "Homestay",
    bank: "Banks",
    park: "Parks",
    museum: "Museums",
    search: "Search along the route",
    clear: "Delete"
  },
  businessHours: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    closedAt: 'Closed at',
    openAt: 'Open at',
    openingSoon: 'Opening soon',
    closed: 'Closed',
    closingSoon: 'Closing soon',
    addOpenTime: "Add open time",
    openAllDay: 'Open All Day',
    closedTime: 'Closed',
    none: 'None',
    notYetInfo: "No business hours yet"
  },
  position: {
    current: "Current location of the map",
    realtime: "Your current location",
    unknown: "Unknown",
    search: "Search position",
  },
  address: {
    add: "Add address"
  },
  metadata: {
    virtual: 'Virtual',
    link: 'Link'
  },
  virtual: {
    add: 'Add Virtual 360',
    info: 'Virtual 360 information about the place',
    list: 'List Virtual 360'
  },
  link: {
    add: 'Add link',
    info: 'Link information about the place',
    list: 'List link'
  },
  categoryList: {
    cate: "Category",
    inputType: "Enter the category name",
    inputGroup: "Enter the category group name",
    main: "Main category",
    select: "Select category",
    notFound: "Data not found",
    sub: "Sub-category",
    searchType: "Search the category"
  },
  building: {
    belong: "Belong to building",
    input: "Input or search building",
    select: "Select building",
    search: "Searching Map4D",
    notFoundBuilding: "Not found building"
  },
  phoneNumber: {
    add: "Add phone number"
  },
  website: {
    add: "Add website"
  },
  description: {
    add: "Add description",
  },
  location: {
    editMap: 'Edit location on the map',
    title: 'Location',
  },
  messageBanner: {
    thankForImproving: 'Thank you for improving !',
    updateStatusRequest: 'Your suggestion has been received, you can see it in the "My contributions" section.',
  },
  datePicker: {
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
  },
  aroundRoute: {
    title: "Search along this route",
    information: "Find stops, accommodations, food and more along your route.",
    stay: "Accommodations",
    foodAndDrink: "Food & drink",
    service: "Services",
    visit: "Attractions"
  },
  searchBar: {
    closeNearLocation: "Cancel search nearby",
    searchNearLocation: "Search nearby"
  },
  hour: {
    chosen: "Set time"
  },
  location: {
    chooseStarted: "Choose starting point",
    moveAndZoom: "Move and zoom the map under the pin"
  },
  notFound: {
    search: `Sorry, we couldn't find "{searchKey}"`,
    inforSearch: "Please make sure your search is correct or you can try with another keyword.",
    route: "Sorry, we couldn't find a {vehicle} route between these locations"
  },
  timeLine: {
    status: 'Chế độ thời gian',
    caption: 'Move the time slider to see how one or more areas in 3D space change over time'
  }
};
