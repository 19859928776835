import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Collapse, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from 'react-router-dom';
import * as yup from "yup";
import AddressInput from '../../components/common/addressInput/addressInput';
import BuildingInput from '../../components/common/buildingInput/buildingInput';
import BusinessHourInput from '../../components/common/businessHourInput/businessHourInput';
import CategoryInput from '../../components/common/categoryInput/categoryInput';
import DescriptionInput from '../../components/common/descriptionInput/descriptionInput';
import Field from '../../components/common/field/field';
import FieldControl from '../../components/common/fieldControl/fieldControl';
import LocationInput from '../../components/common/locationInput/locationInput';
import MetaDataInput from '../../components/common/metaDataInput/metaDataInput';
import PhotosInput from '../../components/common/photosInput/photosInput';
import SidebarHeader from '../../components/common/sidebarHeader/sidebarHeader';
import TagInput from '../../components/common/tagInput/tagInput';
import { AppConfig, CommonConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum, MapEventEnum, RequestTypeEnum, TypeAddressEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, BusinessHourTool, LocationTool, MobileDraggableBarTool, PlaceTool } from '../../tool';
import { useWindowSize } from '../../useHooks';

const marginField = 24
const useStyles = makeStyles({
  header: {
    boxShadow: '0px 0px 2px #00000029'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: '4px',
  },
  marginBottomField: {
    marginBottom: `${marginField}px`
  },
  marginText: {
    marginTop: `${marginField}px`
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: 'inherit',
    fontWeight: 500
  }
})

const ARR_KEY_VALUE = [
  "geometry",
  "name",
  "types",
  "addressComponents",
  "location",
  "mediaFiles",
  "objectId",
  "tags",
  "businessHours",
  "startDate",
  "endDate",
  "phoneNumber",
  "website",
  "description",
  "metadata",
];
function MobileRequestUpdatePlace() {
  const history = useHistory()
  const { id } = useParams()
  const classes = useStyles()

  const [place, setPlace] = useState(null)
  const [showExtend, setShowExtend] = useState(false)
  const [imageFiles, setImageFiles] = useState([])
  const [photos, setPhotos] = useState([])
  const [position, setPosition] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [addressComponents, setAddressComponents] = useState([])
  const windowSize = useWindowSize()
  const [loading, setLoading] = useState(true)
  const detailPlaceRef = useRef()

  const regexPattern = /^[a-zA-Z0-9 ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềếểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý]+$/u;

  const schema = yup.object().shape({
    name: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.common.name
    })),

    changeReason: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.updatePlace.reasonUpdate,
    })),
    phoneNumber: yup.string(),
    website: yup
      .string()
      .url(Resource.formatString(Resource.validate.typeError, {
        field: Resource.common.path,
        type: Resource.common.link.toLocaleLowerCase()
      })),
    addressComponents: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.place.province
      }), function (addressComponents) {
        let address = addressComponents?.find(t => {
          return t.types?.includes(TypeAddressEnum.province)
        })
        if (!address) {
          return false
        }
        return true;
      }),

    types: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.category
      }), function (category) {
        if (category?.length == 0) {
          return false
        }
        return true;
      }),
  })

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema)
  })

  register({ name: 'addressComponents', type: 'custom' })
  register({ name: 'types', type: 'custom' })
  register({ name: 'tags', type: 'custom' })
  register({ name: 'objectId', type: 'custom' })
  register({ name: 'businessHours', type: 'custom' })
  register({ name: 'description', type: 'custom' })
  register({ name: 'photos', type: 'custom' })
  register({ name: 'metadata', type: 'custom' })

  useEffect(() => {
    MobileDraggableBarTool.setMaxHeight(windowSize.height)
    MobileDraggableBarTool.setMiddleHeight(windowSize.height)
    MobileDraggableBarTool.setMaxHeight(windowSize.height)
    MobileDraggableBarTool.setHeight(windowSize.height)
    MobileDraggableBarTool.setDraggble(false)

  }, [windowSize?.height])

  useEffect(() => {
    var sourcePlace
    if (id) {
      setLoading(true)
      sourcePlace = ApiTool.get(UrlConfig.poi.detail.replace("{id}", id), (res) => {
        setLoading(false)
        if (res?.code == CodeEnum.ok) {
          let placeNew = res?.result
          detailPlaceRef.current = { ...res?.result } || {}
          let tags = []
          placeNew?.tags?.forEach(tag => {
            tags.push({
              name: tag
            })
          });
          placeNew.tags = tags
          setPlace(placeNew)
          setPosition(placeNew.location)
          placeNew?.startDate && setStartDate(new Date(placeNew?.startDate))
          placeNew?.endDate && setEndDate(new Date(placeNew?.endDate))
          setAddressComponents(placeNew.addressComponents || [])
          setValue('description', placeNew?.description)
        }
        else {
          setPlace(null)
        }
      })
    }
    return () => {
      sourcePlace?.cancel()
    };
  }, [id])

  useEffect(() => {
    if (LocationTool.isValid(position)) {
      let body = {
        lat: position.lat,
        lng: position.lng
      }
      ApiTool.queryGetFromJson(UrlConfig.geocode.getAddressComponents, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setAddressComponents(res?.result || [])
        }
      })
    }
  }, [position])

  useEffect(() => {
    setValue('addressComponents', addressComponents, { shouldValidate: formState.isSubmitted })
  }, [addressComponents])

  useEffect(() => {
    const eventDragMarker = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      setPosition(args.marker.getPosition())
    }, { marker: true })
    return () => {
      eventDragMarker?.remove()
    }
  }, [])

  const handleCloseUpdate = () => {
    history.push({
      pathname: RouterConfig.place.detail.replace(":id", place?.id)
    })
  }

  const handleShowExtend = () => {
    setShowExtend(!showExtend)
  }

  const onChangeAddress = (value) => {
    setAddressComponents(value)
  }

  const onChangeCategory = (category) => {
    let types = category?.map(t => {
      return t?.id
    })
    setValue('types', types, { shouldValidate: formState.isSubmitted })
  }

  const onChangeTag = (tags) => {
    let newTag = tags?.map(tg => {
      return tg?.name
    })
    setValue('tags', newTag, { shouldValidate: formState.isSubmitted })
  }

  const onChangeObject = (object) => {
    setValue('objectId', object?.id || null, { shouldValidate: formState.isSubmitted })
  }

  const onChangeBusinessHours = (businessHours) => {
    setValue('businessHours', businessHours, { shouldValidate: formState.isSubmitted })
  }

  const onChangeDescription = (description) => {
    // if (description == `<p></p>\n` || description == `<p></p>`) {
    //   description = null
    // }
    setValue('description', description, { shouldValidate: formState.isSubmitted })
  }

  const onChangePhoto = (photos, imageFiles) => {
    setImageFiles(imageFiles)
    setPhotos(photos)
    setValue('photos', photos, { shouldValidate: formState.isSubmitted })
  }

  const onChangeMetaData = (metadata) => {
    setValue('metadata', metadata, { shouldValidate: formState.isSubmitted })
  }

  const handleChangeLocation = (location) => {
    setPosition(location)
  }

  const onChangeStartDate = (date) => {
    setStartDate(date)
  }

  const onChangeEndDate = (date) => {
    setEndDate(date)
  }

  const sendRequest = (data) => {
    let updatedData = {
      ...data,
      geometry: place?.geometry,
      startDate: startDate?.getTime(),
      endDate: endDate?.getTime(),
      location: position,
      placeId: place?.id,
      phoneNumber: data?.phoneNumber,
      website: data?.website,
      mainType: data.types[0],
      auxiliaryTypes: data.types?.slice(1)
    }

    let body = PlaceTool.createRequestPoiV4(detailPlaceRef?.current, updatedData)

    if (body) {
      body.changeReason = data?.changeReason
      body.placeId = place?.id

      ApiTool.post(UrlConfig.poiEdit.create, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          AppTool.alert(
            Resource.formatString(Resource.alert.success.requestUpdate, {
              object: Resource.common.place.toLowerCase()
            }),
            AlertTypeEnum.success
          )
          handleCloseUpdate()
        }
        else {
          AppTool.alert(
            Resource.formatString(Resource.alert.error.requestUpdate, {
              object: Resource.common.place.toLowerCase()
            }),
            AlertTypeEnum.error
          )
        }
      }, true)
    }
    else {
      AppTool.alert(
        Resource.message.notUpdatedInfo,
        AlertTypeEnum.error
      )
    }
  }

  const onSubmit = (data) => {
    if (imageFiles?.length > 0) {
      let body = {
        files: imageFiles
      }
      ApiTool.postFormFromJson(UrlConfig.file.listMedia, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          data.mediaFiles = photos?.concat(res?.result)
          sendRequest(data)
        }
        else {
          AppTool.alert(
            Resource.formatString(Resource.alert.error.edit, {
              object: Resource.common.images.toLowerCase()
            }),
            AlertTypeEnum.error
          )
        }
      }, true)
    }
    else {
      sendRequest(data)
    }
  }


  return (
    <Box
      width='100%'
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      {
        loading ?
          <>
            <Box className={classes.header}>
              <Skeleton animation="wave" variant="rect" width="100%" height='70px' />
            </Box>
            <Divider />
            <Box overflow='auto' flexGrow='1'>
              <Box height='100%' display='flex' flexDirection='column' >
                <Box overflow='auto' flexGrow='1' style={{ overflowX: 'hidden' }}>
                  {
                    Array.from({ length: 10 }, (_, i) => i).map((item, index) => {
                      return (
                        <Box p={2} key={index}>
                          <Field
                            label=''
                            disableMarginTop
                            icon={<Skeleton animation="wave" variant="rect" width="24px" height='24px' />}
                          >
                            <Skeleton animation="wave" variant="rect" width="100%" height='50px' style={{ borderRadius: '4px' }} />
                          </Field>
                        </Box>
                      )
                    })
                  }
                </Box>
                <Box display='flex' justifyContent='space-evenly' p={3} borderTop={1} borderColor='grey.200'>
                  <Skeleton animation="wave" variant="rect" width="80px" height='40px' style={{ borderRadius: '4px' }} />
                  <Skeleton animation="wave" variant="rect" width="80px" height='40px' style={{ borderRadius: '4px' }} />
                </Box>
              </Box>
            </Box>
          </>
          :
          place ?
            <>
              <SidebarHeader subTitle={place?.name} title={Resource.common.requestUpdate} isBack onComeBack={handleCloseUpdate} />
              <Divider />
              <Box overflow='auto' flexGrow='1'>
                <form style={{ height: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
                  <Box p={`${marginField}px`} flexGrow='1' overflow='auto'>
                    <Box>
                      <Field
                        required
                        label={Resource.updatePlace.name}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.placeName} width={24} height={24} />}
                      >
                        <TextField
                          name='name'
                          label={Resource.updatePlace.addName}
                          placeholder={Resource.updatePlace.addName}
                          variant="outlined"
                          defaultValue={place?.name}
                          inputRef={register}
                          error={errors.name ? true : false}
                          helperText={errors?.name?.message}
                        />
                      </Field>

                      <Field
                        required
                        error={errors.types}
                        helperText={errors?.types?.message}
                        label={Resource.updatePlace.category}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.category} width={24} height={24} />}
                      >
                        <CategoryInput defaultValue={place?.placeTypes} onChange={onChangeCategory} />
                      </Field>

                      <Field
                        required
                        label={Resource.updatePlace.location}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.location} width={24} height={24} />}
                      >
                        <Box mb={`${marginField}px`}>
                          <LocationInput value={place?.location} onChange={handleChangeLocation} />
                        </Box>
                        <AddressInput
                          position={position}
                          value={addressComponents}
                          onChange={onChangeAddress}
                          error={errors.addressComponents ? true : false}
                          helperText={errors?.addressComponents?.message}
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.tag}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.label} width={24} height={24} />}
                      >
                        <TagInput defaultValue={place?.tags} onChange={onChangeTag} />
                      </Field>

                      <Field
                        label={Resource.updatePlace.building}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.object} width={24} height={24} />}
                      >
                        <BuildingInput defaultValue={place?.object} onChange={onChangeObject} location={position} />
                      </Field>

                      <Field
                        label={Resource.updatePlace.timeOpen}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.clock} width={24} height={24} />}
                      >
                        <BusinessHourInput defaultValue={BusinessHourTool.generateBusinessHourObject(place?.businessHours)} onChange={onChangeBusinessHours} />
                      </Field>

                      <Field
                        label={Resource.common.startDate}
                        icon={<img src={LinkIconConfig.common.startDate} width={24} height={24} />}
                      >
                        <DatePicker
                          views={["year", "month"]}
                          label={Resource.startDate.choose}
                          inputVariant="outlined"
                          InputLabelProps={
                            {
                              shrink: true
                            }
                          }
                          clearable
                          value={startDate}
                          maxDate={endDate || CommonConfig.maxDate}
                          onChange={onChangeStartDate}
                          format="MM/yyyy"
                        />
                      </Field>
                      <Field
                        label={Resource.common.endDate}
                        icon={<img src={LinkIconConfig.common.endDate} width={24} height={24} />}
                      >
                        <DatePicker
                          views={["year", "month"]}
                          label={Resource.endDate.choose}
                          inputVariant="outlined"
                          InputLabelProps={
                            {
                              shrink: true
                            }
                          }
                          clearable
                          value={endDate}
                          minDate={startDate || CommonConfig.minDate}
                          onChange={onChangeEndDate}
                          format="MM/yyyy"
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.contact}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.contact} width={24} height={24} />}
                      >
                        <TextField
                          name="phoneNumber"
                          inputRef={register}
                          label={Resource.updatePlace.addPhoneNumber}
                          variant="outlined"
                          defaultValue={place?.phoneNumber}
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.web}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.website} width={24} height={24} />}
                      >
                        <TextField
                          name="website"
                          inputRef={register}
                          error={errors.website ? true : false}
                          helperText={errors?.website?.message}
                          label={Resource.updatePlace.addWebsite}
                          variant="outlined"
                          defaultValue={place?.website}
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.description}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.description} width={24} height={24} />}
                      >
                        <DescriptionInput value={place?.description} onChange={onChangeDescription} />
                        {/* <FieldControl label={Resource.updatePlace.addDescriptionForLocation}>
                          <DescriptionInput value={place?.description} onChange={onChangeDescription} />
                        </FieldControl> */}
                      </Field>

                      <Field
                        label={Resource.updatePlace.reasonUpdate}
                        icon={<img src={LinkIconConfig.requestUpdatePlace.reason} width={24} height={24}
                        />}
                        required
                      >
                        <TextField
                          name="changeReason"
                          inputRef={register}
                          error={errors.changeReason ? true : false}
                          helperText={errors?.changeReason?.message}
                          label={Resource.updatePlace.addReason}
                          variant="outlined"
                          defaultValue={''}
                        />
                      </Field>

                      <Field
                        icon={<Box width="24px"></Box>}
                        label=''
                      >
                        <PhotosInput defaultValue={place?.mediaFiles} onChange={onChangePhoto} />
                        <Typography className={classes.marginText} variant='caption'>{Resource.updatePlace.descriptionPhotoFeild}</Typography>
                      </Field>

                    </Box>
                    <Box>
                      <Button className={classes.extendBtn} fullWidth variant='text' onClick={handleShowExtend} endIcon={showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                        {Resource.updatePlace.addExtendBtn}
                      </Button>
                      <Collapse in={showExtend} timeout={600} >
                        <MetaDataInput value={place?.metadata} onChange={onChangeMetaData} />
                      </Collapse>
                    </Box>
                  </Box>

                  <Box display='flex' justifyContent='space-evenly' p={3} borderTop={1} borderColor='grey.200'>
                    <Button onClick={handleCloseUpdate}>{Resource.button.cancel}</Button>
                    <Button variant='contained' type='submit' color='primary'>{Resource.button.send}</Button>
                  </Box>
                </form>
              </Box>

            </>
            : ''
      }
    </Box>

  )

}

MobileRequestUpdatePlace.propTypes = {
  //
};

export default MobileRequestUpdatePlace;
