import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button } from 'ui';
import { LinkState, ShowVr360State, Vr360State } from '../../../appState';
import { LinkIconConfig } from '../../../config/linkIconConfig';
import { TypeMetaDataEnum } from '../../../enum';
import { Resource } from '../../../resource';
import Dialog from '../../dialog/dialog';
import DialogAction from '../../dialogAction/dialogAction';
import DialogBody from '../../dialogBody/dialogBody';
import DialogHeader from '../../dialogHeader/dialogHeader';
import { DataLinkState } from './../../../appState/dataLinkState';
import { DataVr360State } from './../../../appState/dataVr360State';
import { ShowLinkState } from './../../../appState/showLinkState';
import { ShowMetaDataState } from './../../../appState/showMetaDataState';
import './showMetaData.scss';
import ConfirmV2 from './../../confirmV2/confirmV2';

const LinkGetIcon = 'https://s2.googleusercontent.com/s2/favicons?domain_url='
const IconType = {
  [TypeMetaDataEnum.vr360]: LinkIconConfig.metadata.vr360Add,
  [TypeMetaDataEnum.link]: LinkIconConfig.metadata.linkAdd,
}

const ShowMetaData = () => {

  const LabelType = {
    [TypeMetaDataEnum.vr360]: Resource.virtual.add,
    [TypeMetaDataEnum.link]: Resource.link.add,
  }

  const TitleType = {
    [TypeMetaDataEnum.vr360]: Resource.metadata.virtual,
    [TypeMetaDataEnum.link]: Resource.metadata.link,
  }

  const CaptionType = {
    [TypeMetaDataEnum.vr360]: Resource.virtual.list,
    [TypeMetaDataEnum.link]: Resource.link.list,
  }

  const setVr360State = useSetRecoilState(Vr360State)
  const setLinkState = useSetRecoilState(LinkState)
  const setShowVr360State = useSetRecoilState(ShowVr360State)
  const setShowLinkState = useSetRecoilState(ShowLinkState)

  const [showConfirm, setShowConfirm] = useState(false)
  const [listItemState, setListItemState] = useState([])
  const [dataLinkState, setDataLinkState] = useRecoilState(DataLinkState)
  const [dataVr360State, setDataVr360State] = useRecoilState(DataVr360State)
  const [showMetadataState, setShowMetaDataState] = useRecoilState(ShowMetaDataState)


  useEffect(() => {
    if (showMetadataState?.type == TypeMetaDataEnum.link) {
      setListItemState(dataLinkState)
    }
    if (showMetadataState?.type == TypeMetaDataEnum.vr360) {
      setListItemState(dataVr360State)
    }
  }, [showMetadataState])

  const onCloseDialog = () => {
    setShowMetaDataState(null)
    if (showMetadataState?.type == TypeMetaDataEnum.vr360) {
      setDataVr360State(null)
    }
    if (showMetadataState?.type == TypeMetaDataEnum.link) {
      setDataLinkState(null)
    }
  }

  const onSave = () => {
    if (showMetadataState?.type == TypeMetaDataEnum.vr360) {
      setVr360State(dataVr360State)
    }
    else if (showMetadataState?.type == TypeMetaDataEnum.link) {
      setLinkState(dataLinkState)
    }
    setShowMetaDataState({
      show: false,
      type: ''
    })
  }

  const onRemoveItem = (index) => (e) => {
    if (showMetadataState?.type == TypeMetaDataEnum.vr360) {
      let list = [...(dataVr360State || [])]
      list?.splice(index, 1)
      setDataVr360State(list)
      setListItemState(list)
    }

    else if (showMetadataState?.type == TypeMetaDataEnum.link) {
      let list = [...(dataLinkState || [])]
      list?.splice(index, 1)
      setDataLinkState(list)
      setListItemState(list)
    }
  }

  const onAddNew = () => {
    if (showMetadataState?.type == TypeMetaDataEnum.vr360) {
      setShowVr360State((prev) => {
        return {
          ...prev,
          show: true,
        }
      })
    }
    else if (showMetadataState?.type == TypeMetaDataEnum.link) {
      setShowLinkState((prev) => {
        return {
          ...prev,
          show: true,
        }
      })
    }
  }

  const onShowConfirm = () => {
    setShowConfirm(true)
  }

  const onCloseConfirm = () => {
    setShowConfirm(false)
  }

  return (
    <>
      <Dialog
        open={showMetadataState?.show}
        onBack={onCloseDialog}
        className='showMetaDataCpn'
      >
        <DialogHeader showBack hideClose>
          <div style={{ fontWeight: 600 }}>{TitleType[showMetadataState?.type] || ''}</div>
        </DialogHeader>

        <DialogBody>
          <div className='containerSubHeader'>
            <div className='caption textList'>
              {CaptionType[showMetadataState?.type]}
            </div>

            <div className='caption addType' onClick={onAddNew}>
              <img src={IconType[showMetadataState?.type]} alt='iconType' />
              <div className='text'>{LabelType[showMetadataState?.type]}</div>
            </div>
          </div>

          {
            listItemState?.length > 0 &&
            <div className='containerContent'>
              {
                listItemState?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className='item'
                    >
                      <div className='itemContent'>
                        <div className='textLink'>
                          <div style={{ width: 18, height: 18 }}>
                            <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                          </div>
                          <span className='text' title={item?.content}>{item?.content}</span>
                        </div>
                        <span className='textName' title={item.name}>{item.name}</span>
                      </div>
                      <div className='itemIcon' title={Resource.common.delete} onClick={onRemoveItem(index)}>
                        <img src={LinkIconConfig.metadata.remove} alt='icon remove' />
                      </div>
                    </div>
                  )
                })

              }
            </div>
          }

        </DialogBody>

        <DialogAction
          style={{
            display: 'flex',
            gap: '0.75rem'
          }}
        >
          <Button onClick={onShowConfirm} variant='cancel' size='small'>
            {Resource.button.cancel}
          </Button>
          <Button onClick={onSave} variant='add' size='small'>
            {Resource.button.done}
          </Button>
        </DialogAction>
      </Dialog>

      {
        showConfirm &&
        <ConfirmV2
          type='warning'
          open={true}
          message={Resource.confirmMessage.cancelRequestUpdatePlace}
          onConfirm={onCloseDialog}
          onClose={onCloseConfirm}
        />
      }



    </>
  )
};

export default ShowMetaData;
