import { Box, Divider, IconButton, makeStyles, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { v4 } from 'uuid';
import { MediaTypeEnum, PlaceExtensionTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { MediaTool } from '../../../tool';
import SideBarDetail from '../sideBarDetail/sideBarDetail';
import SlideGallery from '../slideGallery/slideGallery';
const padding = 56.25
const borderSelectItem = '2px solid #508FF4'
const useStyles = makeStyles({
  itemVideo: {
    paddingTop: `${padding}%`,
    "& > iframe": {
      width: '100% !important',
      height: '100% !important',
      position: 'absolute',
      top: 0,
      left: 0,
    }
  },
  itemVideoAbsolute: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    cursor: 'pointer'
  },
  tabItem: {
    minWidth: '136px',
    textTransform: 'inherit',
    color: '#121212',
    '&.Mui-selected': {
      color: '#1C75BC'
    }
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </Box>
  )
}

TabPanel.prototype = {
  children: PropTypes.component,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabContent(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function ShowMediaGallery(props, ref) {
  const [showMedia, setShowMedia] = useState(false)
  const [photos, setPhotos] = useState([])
  const [videos, setVideos] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const [title, setTitle] = useState('')
  const [indexSelect, setIndexSelect] = useState(0)
  const theme = useTheme()
  const classes = useStyles()
  const [dataGallery, setDataGallery] = useState([])
  const [allMedia, setAllMedia] = useState([])

  useImperativeHandle(ref, () => ({
    showMediaWithVideos: (show, photosData, videosData, title) => {
      setShowMedia(show)
      setPhotos(photosData)
      setVideos(videosData)
      setDataGallery(videosData)
      setTitle(title)
      setCurrentTab(1)
    },
    showMediaWithPhotos: (show, photosData, videosData, title) => {
      setShowMedia(show)
      setPhotos(photosData)
      setVideos(videosData)
      setDataGallery(photosData)
      setTitle(title)
      setCurrentTab(0)
    },
    showMedia: (mediaFiles, type, title) => {
      let video = mediaFiles?.filter((item) => { return item?.typeFile == MediaTypeEnum.video })
      let photo = mediaFiles?.filter((item) => { return item?.typeFile == MediaTypeEnum.image })
      setAllMedia(mediaFiles)
      setPhotos(photo)
      setVideos(video)
      setShowMedia(true)
      setDataGallery(type == MediaTypeEnum.image ? photo : (type == MediaTypeEnum.video ? video : mediaFiles))
      setTitle(title)
      setCurrentTab(type == MediaTypeEnum.image ? 1 : (type == MediaTypeEnum.video ? 2 : 0))
    }
  }))

  const handleChange = (event, newIndexTab) => {
    setCurrentTab(newIndexTab)
  }

  useEffect(() => {
    setDataGallery(currentTab == 0 ? allMedia : (currentTab == 1 ? photos : videos))
    setIndexSelect(0)
  }, [currentTab])

  const handleClose = () => {
    setShowMedia(false)
  }

  const handleOnClickMedia = (index) => (e) => {
    setIndexSelect(index)
  }

  const handleOnClickImg = (index) => (e) => {
    setIndexSelect(index)
  }

  const handleOnClickVideo = (index) => (e) => {
    setIndexSelect(index)
    setDataGallery(videos)
    if ((!videos[index]?.content?.includes('https')) && videos[index].type == PlaceExtensionTypeEnum.videoLink) {
      window.open(videos[index]?.content, '_blank')
    }
  }

  const handleChangeIndexSelect = (indexSelect) => {
    setIndexSelect(indexSelect)
    document.getElementsByClassName('itemMedia')[indexSelect]?.scrollIntoView()
  }

  return (
    showMedia &&
    <Box display='flex' position="fixed" left={0} top={0} width="100%" height="100%" bgcolor="#000000" zIndex={2000}>
      <SideBarDetail>
        <Box width='100%' height='100%' bgcolor='#FFFFFF'>
          <Box p={2} display='flex' alignItems='center' justifyContent='space-around'>
            <IconButton onClick={handleClose}><ArrowBack /></IconButton>
            <Box textAlign='center' width='100%'>
              <Typography noWrap variant='h6'>{title}</Typography>
            </Box>
          </Box>
          <Divider />
          <Box display='flex' flexDirection='column' height='calc(100% - 72px)' width='100%'>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs"
            >
              <Tab className={classes.tabItem} label={Resource.common.all} {...tabContent(0)} />
              <Tab className={classes.tabItem} label={Resource.common.images} {...tabContent(1)} />
              <Tab className={classes.tabItem} label={Resource.common.video} {...tabContent(2)} />
            </Tabs>

            <Box overflow='auto'>
              <TabPanel value={currentTab} index={0} dir={theme.direction}>
                {
                  allMedia?.length > 0 ?
                    allMedia?.map((item, index) => {
                      return (
                        <Box className='itemMedia' style={{ border: index == indexSelect ? `${borderSelectItem}` : 'none' }} key={v4()} width='100%' mb={1} onClick={handleOnClickMedia(index)}>
                          {
                            item?.typeFile == MediaTypeEnum.image ?
                              <img src={item?.url} alt={item?.name} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                              :
                              <video width="100%" height='100%' preload='metadata' src={item?.url} style={{ objectFit: 'cover' }} />
                          }
                        </Box>
                      )
                    })
                    :
                    <Box p={2}>
                      <Typography variant='subtitle1'>{Resource.common.noMediaFiles}</Typography>
                    </Box>
                }
              </TabPanel>

              <TabPanel value={currentTab} index={1} dir={theme.direction}>
                {
                  photos?.length > 0 ?
                    photos?.map((photo, index) => {
                      return (
                        <Box className='itemMedia' style={{ border: index == indexSelect ? `${borderSelectItem}` : 'none' }} key={v4()} width='100%' mb={1} onClick={handleOnClickImg(index)}>
                          <img src={photo?.url} alt={photo?.name} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                        </Box>
                      )
                    })
                    :
                    <Box p={2}>
                      <Typography variant='subtitle1'>{Resource.common.noImages}</Typography>
                    </Box>
                }
              </TabPanel>

              <TabPanel value={currentTab} index={2} dir={theme.direction}>
                {
                  videos?.length > 0 ?
                    videos?.map((video, index) => (
                      <Box mb={1} key={video?.id || v4()} position='relative' className='itemMedia'>
                        <video width="100%" height='100%' controls preload='metadata' src={video?.url} style={{ objectFit: 'cover' }} />
                        <Box style={{ border: index == indexSelect ? `${borderSelectItem}` : 'none' }} className={classes.itemVideoAbsolute} onClick={handleOnClickVideo(index)}></Box>
                      </Box>
                    ))
                    :
                    <Box p={2}>
                      <Typography variant='subtitle1'>{Resource.common.noVideos}</Typography>
                    </Box>
                }
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </SideBarDetail>
      <Box flexGrow='1'>
        <SlideGallery onClose={handleClose} data={dataGallery} step={indexSelect} onChangeIndex={handleChangeIndexSelect} />
      </Box>
    </Box>
  )
}

ShowMediaGallery = forwardRef(ShowMediaGallery)
ShowMediaGallery.propTypes = {
  //
};

export default ShowMediaGallery;
