import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '16px',
    border: '1px solid #DADCE0',
    borderRadius: '8px',
    cursor: 'pointer',
    width:'100%',
    '&:hover':{
      boxShadow: '0px 2px 4px #00000029',
    }
  }
})
function DisplayItemContent({ content, title, handleOnClick, index }) {
  const classes = useStyles()
  const handleOnclickItem = () => {
    handleOnClick && handleOnClick()
  }
  return (
    <Box className={classes.root} onClick={handleOnclickItem}>
      <Typography noWrap variant='subtitle2'>{index >= 0 ? `${index + 1} . ${title}` : `${title}`}</Typography>
      {content}
    </Box>
  )
}

DisplayItemContent.propTypes = {
  content: PropTypes.any,
  title: PropTypes.node,
  handleOnClick: PropTypes.func,
  index: PropTypes.number
};

export default DisplayItemContent;
