import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { StringTool } from "../../tool"
import { Resource } from './../../resource'
import dialogPolyfill from 'dialog-polyfill'
import "./suggestV3.scss"

const anchorEnum = {
  top: "bottom",
  bottom: "top",
}

const removeUniKey = (str) => {
  if (str) {
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/\W+/g, " ")
  }
  return str
}

const RenderTextForOption = ({ textOption, textInput }) => {
  let position = removeUniKey(textOption).indexOf(removeUniKey(textInput))
  return (
    <div className="textOptionDefault">
      {
        position === -1 || textInput === "" ?
          <div className="normal">
            {textOption}
          </div>
          :
          <>
            <span className="normal">
              {textOption.substring(0, position)}
            </span>
            <span className="highLight">
              {textOption.substring(position, position + textInput.length)}
            </span>
            <span className="normal">
              {textOption.substring(position + textInput.length)}
            </span>
          </>
      }
    </div>
  )
}

const SuggestV3 = ({
  options,
  value,
  onChange,
  onInputChange,
  disabled,
  helperText,
  error,
  label,
  isServer,
  isFreeSolo,
  maxLengthOfScroll,
  placeholder,
  required,
  getOptionDisabled,
  getTextForOption,
  renderOption,
  getTextForInput,
  identifierField = "id",
  clearable,
  onEnter
}) => {
  const ref = useRef(null)
  const inputRef = useRef(null)
  const scrollRef = useRef(null)
  const isFirstFocusRef = useRef(false)
  const dialogHtml = useRef()

  const keyUpDownRef = useRef({ key: null })
  const [optionsState, setOptionsState] = useState()
  const [valueState, setValueState] = useState(value)

  const [index, setIndex] = useState(-1)
  const [active, setActive] = useState()
  const [isOpen, setOpen] = useState(false)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)
  const [distance, setDistance] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [optionHeight, setOptionHeight] = useState(48)
  const [anchor, setAnchor] = useState(anchorEnum.bottom)
  const [isFocus, setIsFocus] = useState(false)

  const [textInput, setTextInput] = useState("")

  const onShowDialog = () => {
    setOpen(true)
    dialogHtml.current?.show()
    inputRef.current?.focus()
  }

  const onCloseDialog = () => {
    dialogHtml.current?.open && dialogHtml.current?.close?.()
    setOpen(false)
    inputRef.current?.blur()
  }

  ///tự đông tìm khi options chỉ đổ 1 lần
  useEffect(() => {
    if (!isOpen && !isFreeSolo && !isServer && value && (typeof value == "string")) {
      let newValue = options?.find(option => option[identifierField] == value)
      if (newValue) {
        setValueState(newValue)
      }
    }
    else {
      setValueState(value)
    }
  }, [value, isFreeSolo, options, isServer, identifierField, isOpen])

  useEffect(() => {
    if (isServer) {
      setOptionsState(options)
    }
    else {
      let search = options?.filter((option) => {
        let text = option?.name || ""
        if (getTextForOption) {
          text = getTextForOption(option)
        }
        return removeUniKey(text)?.includes(removeUniKey(textInput?.trim()))
      })
      setOptionsState(search)
    }
  }, [options, isServer, textInput, getTextForOption])

  useEffect(() => {
    if (!isServer) {
      let newValue = ""
      if (!value?.id) {
        newValue = optionsState?.find((option) => value == option?.id)
      }
      setValueState(newValue)
      if (newValue) {
        setValueState(newValue)
      }
    }

  }, [value, optionsState, isServer])

  useEffect(() => {
    setValueState(value)
  }, [value])



  const handleScrollAndResize = () => {
    let innerHeight = window.innerHeight
    let innerHeightBetween = innerHeight / 2
    let cpn = ref.current?.getBoundingClientRect() || {}
    let isTop = (innerHeightBetween + cpn?.top) / 2 > innerHeightBetween

    if (isTop) {
      setDistance(innerHeight - cpn?.top)
      setMaxHeight(cpn?.top)
      setAnchor(anchorEnum.top)
    }
    setMaxHeight(innerHeight - (cpn?.top + cpn?.height))
    setAnchor(anchorEnum.bottom)
    setDistance(cpn?.top + cpn?.height)
    setLeft(cpn?.left)
  }

  useEffect(() => {
    isOpen && handleScrollAndResize()
  }, [isOpen, disabled])

  useEffect(() => {
    handleScrollAndResize()
    let elements = ref?.current
    let observer
    let resize

    const trackWidth = (entries) => {
      setWidth(entries[0].contentRect.width)
    }

    if (elements) {
      observer = new IntersectionObserver(onIntersection)
      function onIntersection(entries, opts) {
        if (!entries[0].isIntersecting) {
          if (!isFirstFocusRef.current) {
            onCloseDialog()
          }
          else {
            ref.current?.scrollIntoView()
            isFirstFocusRef.current = false
          }
        }
      }
      resize = new ResizeObserver(trackWidth)

      observer.observe(elements)
      resize.observe(elements)
    }

    window.addEventListener("resize", handleScrollAndResize, true)
    document.addEventListener("scroll", handleScrollAndResize, true)

    return () => {
      resize?.disconnect()
      observer?.disconnect()
      window.removeEventListener("resize", handleScrollAndResize, true)
      document.removeEventListener("scroll", handleScrollAndResize, true)
    }
  }, [])

  useEffect(() => {
    let resize
    if (inputRef.current && isOpen) {
      const getHeightOption = (entries) => {
        // setOptionHeight(inputRef.current?.getBoundingClientRect()?.height || 0)
      }
      resize = new ResizeObserver(getHeightOption)
      resize.observe(inputRef.current)
    }
    return () => {
      resize?.disconnect()
    }
  }, [isOpen])

  useEffect(() => {
    const handleClose = (event) => {
      if (isOpen) {
        var isClickInsideElement = ref.current.contains(event.target)
        var isClickInsideElementListRef = dialogHtml.current.contains(
          event.target
        )
        if (!isClickInsideElement && !isClickInsideElementListRef) {
          onCloseDialog()
          setValueState('')
        }
      }
    }
    window.addEventListener("mousedown", handleClose)
    return () => {
      window.removeEventListener("mousedown", handleClose)
    }
  }, [valueState, isOpen])

  useEffect(() => {
    let text
    if (isFreeSolo && (typeof valueState == "string")) {
      text = valueState
    }
    else {
      text = valueState?.name || ""
      if (getTextForInput) {
        text = getTextForInput(valueState)
      }
      else if (getTextForOption) {
        text = getTextForOption(valueState)
      }
    }
    setTextInput(text)

  }, [valueState, getTextForOption, getTextForInput, isFreeSolo])

  const onChangeText = (event) => {
    let valueNew = event.target.value
    setTextInput(valueNew)
    if (isFreeSolo) {
      onChange(valueNew)
      setValueState(valueNew)
    }
    onInputChange && onInputChange(valueNew, event)
  }

  const handleSelected = (option) => (e) => {
    e.stopPropagation()
    setIndex(-1)
    setValueState(option)
    onChange && onChange(option)
    // let text = option?.name || ""
    // if (getTextForInput) {
    //   text = getTextForOption(option)
    // }
    // else if (getTextForOption) {
    //   text = getTextForOption(option)
    // }
    // setTextInput(text)
    onCloseDialog()
  }

  useEffect(() => {
    // inputRef.current.value = textInput
  }, [textInput])

  const handleRemoveAll = () => {
    setValueState('')
    setIndex(-1)
    onChange && onChange('')
    onCloseDialog()
  }

  useEffect(() => {
    if (optionsState) {
      setActive(optionsState[index])
    }
    let scrollCPn = scrollRef?.current
    let height = scrollCPn?.getBoundingClientRect()?.height
    let distance = scrollCPn?.getBoundingClientRect()?.top

    let children = scrollCPn?.children[index]?.getBoundingClientRect()
    let distanceChildren = (children?.top - distance).toFixed()
    let distanceOption = index * optionHeight

    if (keyUpDownRef.current.key === 38) {
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = distanceOption
      }
      if (distanceChildren > height) {
        scrollCPn.scrollTop = distanceChildren
      }
    }

    if (keyUpDownRef.current.key === 40) {
      if (distanceChildren > height - optionHeight) {
        scrollCPn.scrollTop = distanceOption - height + optionHeight
      }
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = 0
      }
    }
  }, [index, optionHeight])

  const onKeyDownEventFired = (event) => {
    if (isOpen && !disabled) {
      switch (event.which) {
        case 38:
          setIndex((prev) => getIndex(prev, true))
          keyUpDownRef.current.key = 38
          event.preventDefault()
          break
        case 40:
          setIndex((prev) => getIndex(prev))
          keyUpDownRef.current.key = 40
          event.preventDefault()
          break
        case 13:
          event.preventDefault()
          if (active && active[identifierField]) {
            setIndex(-1)
            setValueState(active)
            onChange && onChange(active)
            onCloseDialog()
          }
          break
        default:
          return
      }
    }
  }

  const onFocusInput = (e) => {
    if (/Android/i.test(navigator.userAgent)) {
      isFirstFocusRef.current = true
    }
    setIsFocus(true)
    if (!disabled) {
      onShowDialog()
    }
  }

  const onBlurInput = () => {
    setIsFocus(false)
  }

  const getIndex = (currentIndex, decrease = false) => {
    let count = optionsState?.length
    let i = 1
    let ok = false
    let result = currentIndex
    while (!(ok || i > count)) {
      let nextIndex = (currentIndex + i) % count
      if (decrease) {
        if (currentIndex > -1) {
          nextIndex = (currentIndex - i + count) % count
        }
        else {
          nextIndex = (-i + count) % count
        }
      }
      if (!getOptionDisabled || (getOptionDisabled && !getOptionDisabled(optionsState[nextIndex]))) {
        result = nextIndex
        ok = true
      }
      else {
        i = i + 1
      }
    }
    return result
  }

  let maxHeightOfList = maxHeight - 9

  if (maxLengthOfScroll) {
    let max2 = optionHeight * maxLengthOfScroll
    if (max2 < maxHeightOfList) {
      maxHeightOfList = max2
    }
  }

  const handleKeyDown = (e) => {
    let value = e?.target?.value
    if (e?.key === 'Enter' && isFreeSolo) {
      let isEnter = true
      onEnter && onEnter(value, isEnter)
      onCloseDialog()
    }
  }

  const handleRef = (r)=>{
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    <div
      ref={ref}
      onKeyDown={onKeyDownEventFired}
      tabIndex={0}
      className={StringTool.mergeClassName('suggestCpnV3', disabled ? 'disabled' : '')}
    >
      <div className="selectWrap">
        <fieldset
          className={StringTool.mergeClassName('fieldset', !label ? "fieldsetNotLabel" : "", isFocus ? "fieldsetFocus" : "fieldsetNotFocus")}
          style={{
            border: error ? "2px solid #D93535" : ""
          }}
        >
          {
            label &&
            <legend
              style={{ color: error ? " #D93535" : "" }}
              className={StringTool.mergeClassName('legend', isFocus ? "legendFocus" : "legendText")}
            >
              {label}
              {
                required &&
                <span className="required">*</span>
              }
            </legend>
          }
        </fieldset>
        <div className="search" style={{ width: width }}>
          <input
            onKeyDown={handleKeyDown}
            readOnly={disabled}
            onBlur={onBlurInput}
            onFocus={onFocusInput}
            ref={inputRef}
            onChange={onChangeText}
            autoComplete="off"
            value={textInput || ""}
            placeholder={placeholder || Resource.common.search}
            title={textInput || ""}
          />

          {
            clearable && textInput && isFocus && !disabled &&
            <div className='iconClear' onPointerDown={handleRemoveAll} title="Xóa">
              <svg
                id="Component_3_2"
                data-name="Component 3 – 2"
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_6362"
                  data-name="Path 6362"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Path_6363"
                  data-name="Path 6363"
                  d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
                  fill="#869195"
                />
              </svg>
            </div>
          }
        </div>
      </div>

      {
        error && !disabled &&
        <div className="helperText">
          {helperText}
        </div>
      }

      {
        <dialog
          ref={handleRef}
          className='listWrapDataSuggest'
          style={{
            [anchor]: distance + 6,
            width: width,
            maxHeight: maxHeightOfList,
            left: left,
            flexDirection: (anchor === "top") ? "column" : "column-reverse",
          }}
        >
          {
            optionsState?.length > 0 ?
              <div className="renderList" ref={scrollRef}>
                {
                  optionsState?.map((option, index) => {
                    let textOption = option.name || ""
                    if (getTextForOption) {
                      textOption = getTextForOption(option)
                    }
                    let disableOption = false
                    if (getOptionDisabled && getOptionDisabled(option)) {
                      disableOption = true
                    }
                    let styleActive = active && active[identifierField] == option[identifierField]

                    return (
                      <div
                        key={option[identifierField]}
                        className={StringTool.mergeClassName("option", disableOption && "disabled")}
                        onMouseDown={handleSelected(option)}
                        // tabIndex={index}
                        title={textOption}
                        style={{
                          backgroundColor: styleActive ? "#9bc4d7" : "",
                          fontWeight: styleActive ? "500" : "",
                        }}
                      >
                        {
                          renderOption ?
                            renderOption(option)
                            :
                            <RenderTextForOption
                              textInput={textInput}
                              textOption={textOption}
                            />
                        }
                      </div>
                    )
                  })}
              </div>
              :
              <div className="dataNotFound">
                <span>{textInput === "" ? Resource.common.noData : Resource.common.noOption}</span>
              </div>
          }
        </dialog>

      }
    </div>
  )
}

SuggestV3.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isServer: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFreeSolo: PropTypes.bool,
  maxLengthOfScroll: PropTypes.number,
  placeholder: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  getTextForOption: PropTypes.func,
  renderOption: PropTypes.func,
  getTextForInput: PropTypes.func,
  identifierField: PropTypes.string,
  onEnter: PropTypes.func
}

export default SuggestV3
