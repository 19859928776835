import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { AlertTypeEnum } from '../../enum';
import { AppTool } from '../../tool';
import './watchPosition.scss';

const ACCURACY = 50

const WatchPosition = (props) => {
  const { map } = props
  const _map = useRef()
  const _marker = useRef()
  const _watchId = useRef()

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: Infinity,
      maximumAge: 0,
    };
    const success = (location) => {
      const { latitude, longitude, accuracy } = location.coords;
      let newLocation = { lat: latitude, lng: longitude };
      if (accuracy <= ACCURACY) {
        _marker.current?.setPosition(newLocation)
        _marker.current?.setVisible(true)
      }
      else {
        _marker.current?.setVisible(false)
      }
    }
  
    const error = (err) => {
    }
    if (map) {
      _marker.current = new map4d.Marker({
        position: { lat: 0, lng: 0 },
        anchor: [0.5, 1],
        iconView: `<div class="circleWatchPosition"></div>`,
        visible: false,
        zIndex: 999,
      })
      _marker.current?.setMap(map)
      _map.current = map
      if (navigator.geolocation) {
        _watchId.current = navigator.geolocation.watchPosition?.(success, error, options)
        // alert(navigator.geolocation.watchPosition)
      }

    }
    return () => {
      _marker.current?.setMap(null)
      _marker.current = null
      _watchId.current && navigator?.geolocation?.clearWatch?.(_watchId.current)
    }
  }, [map])


  return (<></>);
};

WatchPosition.propTypes = {
  map: PropTypes.any
};

export default WatchPosition;
