import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { HiddenAddClickState } from '../../appState';

const HiddenAddClick = () => {
  const setHidden  = useSetRecoilState(HiddenAddClickState)

  useEffect(() => {
    setHidden(true)
    return () => {
      setHidden(false)
    }
  }, [])

  return null
};

HiddenAddClick.propTypes = {
  //
};

export default HiddenAddClick;
