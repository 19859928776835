import { useEffect, useRef, useState } from 'react';
import { CommonConfig, ImageConfig, LinkIconConfig } from '../../config';
import { AppData } from '../../data';
import { VehicleEnum } from '../../enum';
import PropTypes from 'prop-types';

function DrawDirection({ routes, vehicle, positions, onDrawEnd, currentIdx, onChange }) {

  const activeStrokePattern = {
    [VehicleEnum.walk]: new map4d.IconPattern({
      url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAQCAYAAAB3AH1ZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATRJREFUeNpiZCAR2PvOTwBS/kAcgCa1AYg3HtycuIAU8xhJsFgASK0HYgcCSg8AcSDQIR+o5gCo5feBWOAPNwfDBx05hm8ywgz/WFnA8ky//zBwPXnLIHDlEQPL1x8gIZDlisQ4gonIAAD5XOCXIDfDMw9Dhi+K4nDLQQDEBomB5EBqQGqhehgodgA0zh1APn/hpIdiMToAyYHUgNSC9ED1UhwC/uAwBQY7PsuRHQFSi6yXUgeAUzsozokFSGoDqJUGiPI9OWqZGAYYEO0AUFajiVoi1IBKOHA+JxYgqd1ADQdsBGdsYCFDjM9AakBqkfVSoyTcD8rXoEIGX1kAslxi3yUGtvdfwUUysCR0pFYaCAQVBSCDpXacZ+C5/xIlNEBskBhIDmr5B6ieYVIZ0bI6BggwAGTSeo007gGFAAAAAElFTkSuQmCC"
    }),
    [VehicleEnum.bicycle]: new map4d.SolidPattern(),
    [VehicleEnum.car]: new map4d.SolidPattern(),
    [VehicleEnum.motorbike]: new map4d.SolidPattern(),
  }

  const [currentIndex, setCurrentIndex] = useState( 0)
  const routesPolyline = useRef([])
  const lineNearPoints = useRef([])

  const directionsRendererRef = useRef()

  const stepMarker = useRef()
  const polylineNearStart = useRef()
  const polylineNearEnd = useRef()


  const removePolyline = () => {
    routesPolyline.current?.map(line => {
      line.setMap(null);
    });
  }

  const removeLineNearPoints = () => {
    lineNearPoints.current?.map(lineNear => {
      lineNear.setMap(null);
    });
  }

  const removeDirectionRenderer = () => {
    directionsRendererRef.current?.setMap(null)
  }

  const drawDirection = (routers) => {
    removeDirectionRenderer()
    let res = {
      code: "ok",
      result: {
        routes: [...routers || []]
      }
    }
    directionsRendererRef.current = new map4d.DirectionsRenderer({
      routes: JSON.stringify(res),
      activeStrokeColor: CommonConfig.strokeBlue,
      activeOutlineColor: CommonConfig.outlineBlue,
      inactiveStrokeColor: CommonConfig.strokeGrey,
      inactiveOutlineColor: CommonConfig.outlineGrey,
      activeStrokeWidth: 5,
      inactiveStrokeWidth: 5,
      activeOutlineWidth: vehicle == VehicleEnum.foot ? 0 : 1.5,
      inactiveOutlineWidth: 1.5,
      originMarkerOptions: {
        visible: false
      },
      destinationMarkerOptions: {
        visible: false
      },
      activeStrokePattern: activeStrokePattern[vehicle],
    })
    directionsRendererRef.current?.setMap(AppData.map)
    onDrawEnd && onDrawEnd(directionsRendererRef.current.getRoutes())
  }

  const drawLineNearPoints = (positions, routers) => {
    removeLineNearPoints()
    routers?.map((route, index) => {
      let legs = route?.legs
      legs?.map((leg, indexLeg) => {
        let pointStart = []
        pointStart = [leg?.startLocation, positions?.[indexLeg]?.position]
        polylineNearStart.current = new map4d.Polyline({
          path: pointStart,
          visible: true,
          strokeColor: "#aaa",
          strokeWidth: 2,
          strokeOpacity: 1,
          closed: false,
          style: "dotted"
        })
        polylineNearStart.current?.setMap(AppData.map)
        lineNearPoints.current.push(polylineNearStart.current)
      })
      let pointEnd = []
      pointEnd = [legs?.[legs.length - 1].endLocation, positions?.[positions.length - 1].position]
      polylineNearEnd.current = new map4d.Polyline({
        path: pointEnd,
        visible: true,
        strokeColor: "#aaa",
        strokeWidth: 2,
        strokeOpacity: 1,
        closed: false,
        style: "dotted"
      })
      polylineNearEnd.current?.setMap(AppData.map)
      lineNearPoints.current.push(polylineNearEnd.current)
    })
  }

  useEffect(() => {
    directionsRendererRef.current?.setActivedIndex(currentIndex)
  }, [currentIndex])
  useEffect(() => {
    directionsRendererRef.current?.setActiveStrokePattern(activeStrokePattern[vehicle])
  },[vehicle])
  useEffect(() => {
    setCurrentIndex(currentIdx)
  },[currentIdx])
  useEffect(() => {
    const clickDirectionEvent = AppData.map.addListener("click", (args) => {
      let activeIndex = args.routeIndex
      setCurrentIndex(activeIndex)
      onChange && onChange(activeIndex)
    }, { directions: true })

    return () => {
      clickDirectionEvent?.remove()
    }
  }, [])

  useEffect(() => {
    stepMarker.current = new map4d.Marker({
      position: { lat: 0, lng: 0 },
      icon: new map4d.Icon(25, 30, ImageConfig.marker),
      anchor: [0.5, 1],
      zIndex: 99999
    })
    return () => {
      stepMarker.current?.setMap(null)
      removeLineNearPoints()
      removePolyline()
      removeDirectionRenderer()
    };
  }, [])

  useEffect(() => {
    setCurrentIndex(0)
    drawDirection(routes)
  }, [routes])

  useEffect(() => {
    if (!positions?.some(pos => !pos?.position)) {
      drawLineNearPoints(positions, routes)
    }
  }, [routes])


  return null;
}

DrawDirection.propTypes = {
  routes: PropTypes.any,
  vehicle: PropTypes.any,
  positions: PropTypes.any,
  onDrawEnd: PropTypes.func
};

export default DrawDirection;
