import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { DataExtendState } from '../../appState';
import { PlaceExtensionTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import "./listItemLink.scss";

const LinkGetIcon = 'https://s2.googleusercontent.com/s2/favicons?domain_url='

const ListItemLink = () => {

  const [dataExtendState, setDataExtendState] = useRecoilState(DataExtendState)
  const [indexSelect, setIndexSelect] = useState()

  const handleOnClickItem = (item, index) => (e) => {
    window.open(item?.content, '_blank')
    setIndexSelect(index)
  }

  const handleOnBack = (e) => {
    setDataExtendState({
      show: false,
      dataExtend: [],
      indexSelect: 0,
      typeData: PlaceExtensionTypeEnum.none
    })
  }

  return (
    <div className='listItemLinksCpn'>
      <div className='headerLinks'>
        <div className='iconBack'>
          <IconButton onClick={handleOnBack}><ArrowBack /></IconButton>
        </div>
        <div className='textTitle'>
          {Resource.common.link}
        </div>
      </div>
      <div className='containerLinks'>
        {
          dataExtendState?.dataExtend?.map((item, index) => {
            return (
              <div
                className={`itemLinks ${index == indexSelect ? 'selectItem' : ''}`}
                key={v4()}
                onClick={handleOnClickItem(item, index)}
              >
                <div className='textLink'>
                  <div style={{ width: 18, height: 18 }}>
                    <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                  </div>
                  <span className='text' title={item?.content}>{item?.content}</span>
                </div>
                <span className='textName' title={item.name}>{item.name}</span>
              </div>
            )
          })

        }
      </div>
    </div>
  )
};

export default ListItemLink;
