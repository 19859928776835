export const RouterConfig = Object.freeze({
    old:{
        direction: "/map/direction/:data",
    },
    home: "/map",
    place: {
        // ?id={id}&type={type}&text={text}&lat={lat}&lng={lng}&data={data}
        search: "/map/place/search", 
        // ?id={id}&lat={lat}&lng={lng}&data={data}
        detail: "/map/place/detail/:id",
        add: "/map/place/add/:lat/:lng/:placeId?/:placeName?",
        detailWithLocation: "/map/place/detail/location/:lat/:lng",
        detailCustom: "/map/place/detail/custom/:data",
        requestUpdate: "/map/place/request-update/:id",
    },
    direction: "/map/dir",
    homeOffice: "/map/home-office",
    tags: "/map/tags/:data",
    object3d: {
        add: "/map/object3d/add/:lat/:lng/:placeId?/:placeName?",
        addMulti: "/map/object3d/add-multi",
        detail: "/map/object3d/detail/:id",
        requestUpdate: "/map/object3d/request-update/:id",
    },
    my: {
        object: "/map/my/object",
        place: "/map/my/place/:type",
    },
    setting: "/map/setting",
    language: "/map/language-setting",
    placeGroup: {
        groupDetail: "/map/group/detail/:id",
        updateGroup: "/map/update-group/:id"
    },
    essentialService: {
        detail: "/map/essential-service/detail/:id",
        search: '/map/essential-service/search',
        searchByArea: '/map/essential-service/search/by-area/:province/:district?/:ward?',
        searchByText: '/map/essential-service/search/by-text/:radius?/!t/:text?',
        detailWithSearchByArea: "/map/essential-service/search/by-area/:province/:district?/:ward?/detail/:id",
        detailWithSearchByText: "/map/essential-service/search/by-text/:radius?/!t/:text?/detail/:id",
    }
});
