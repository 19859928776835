import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { SvgIconConfig } from "../../config";
import { Resource } from "../../resource";
import "./mobileDirectionPositionsInput.scss";
import Back from '../common/back/back';
import { StringTool } from '../../tool';

const createDefaultState = () => {
    return [
        {
            id: v4(),
            textInput: ""
        },
        {
            id: v4(),
            textInput: ""
        }
    ]
}
const MobileDirectionPositionsInput = (props) => {

    const { onSearch, onEnter, onFocus, onPermutePositions, value, onSwap, onRemove, onAdd, onBlur, disableAddDestination, onBlurInput, onBack } = props

    const [valueState, setValueState] = useState(createDefaultState);

    const containerHtml = useRef()

    const [isFocusAnyInput, setIsFocusAnyInput] = useState(false)

    const [isSmallView, setIsSmallView] = useState(false)

    useEffect(() => {
        let canDirection = valueState?.every((v => {
            return (v?.poiId || v?.position)
        }))
        if (canDirection && !isFocusAnyInput && valueState?.length > 2) {
            setIsSmallView(true)
        }
        else {
            setIsSmallView(false)
        }
    }, [valueState, isFocusAnyInput])


    useEffect(() => {

        const checkBlur = (event) => {
            if (!containerHtml.current?.contains(event.target)) {
                setIsFocusAnyInput(false)
                onBlur && onBlur()
            }
            else {
                if (!event.target.tagName.toLowerCase() === 'input') {
                    setIsFocusAnyInput(false)
                    onBlur && onBlur()
                }
            }
        }
        if (!isSmallView) {
            document.addEventListener("click", checkBlur)
        }
        return () => {
            document.removeEventListener("click", checkBlur)
        }

    }, [isSmallView])


    const onFocusInput = (index) => (e) => {
        setIsFocusAnyInput(true)
        e.target.select()
        let text = e.target.value
        onFocus && onFocus(text, index)
    }
    const onBlurIndex = (index) => (e) => {
        setIsFocusAnyInput(false)
        let text = e.target.value
        onBlurInput?.(text, index)
    }
    const onChangeInput = (index) => (e) => {
        let text = e.target.value
        onSearch && onSearch(text, index)
        setValueState(prev => {
            let listNew = JSON.parse(JSON.stringify([...prev || []]))
            listNew[index].textInput = text
            return listNew
        })
    }

    const onEnterInput = (index) => (e) => {
        if (e.key.toLowerCase() == "enter") {
            let text = e.target.value
            let isEnter = true
            onEnter && onEnter(text, index, isEnter)
            setValueState(prev => {
                let listNew = JSON.parse(JSON.stringify([...prev || []]))
                listNew[index].textInput = text
                return listNew
            })
        }
    }

    useEffect(() => {
        if (value?.length >= 2) {
            setValueState(value)
        }
        else {
            setValueState(createDefaultState())
        }
    }, [value])

    const onClickSwap = () => {
        onSwap && onSwap()
        setValueState(prev => {
            let arr = Array.from(prev || [])
            arr.reverse()
            return arr
        })
    }

    const onClickRemove = (index) => () => {
        onRemove && onRemove(index)
        setValueState(prev => {
            let arr = Array.from(prev || [])
            arr.splice(index, 1)
            return arr
        })
    }

    const onClickSmallView = () => {
        setIsFocusAnyInput(true)
    }

    const length = valueState?.length || 2
    let indexFocus = valueState?.findIndex(t => !(t?.poiId || t?.position))
    const _test = useRef(0)
    _test.current = _test.current + 1

    return (
        <>
            {/* {_test.current} */}
            {
                isSmallView ?
                    <div className='mobileDirectionPositionsInput' onClick={onClickSmallView}>
                        <div className="symmetry">
                            <button className='backSmallView'>
                                {SvgIconConfig.direction.back}
                            </button>
                        </div>
                        <div className='smallPositions'>
                            <div className='left'>
                                <div className='icons'>
                                    {
                                        valueState?.map((pos, index) => {
                                            let highlight = false
                                            let ascii = pos.index + 64
                                            let char = String.fromCharCode(ascii)
                                            if (pos.index != index) {
                                                highlight = true
                                            }

                                            return (
                                                <div key={pos?.id} className='icon'>
                                                    {
                                                        (index < (length - 1)) ?
                                                            (
                                                                highlight ?
                                                                    SvgIconConfig.direction.middleHighlightSmall
                                                                    :
                                                                    SvgIconConfig.direction.startSmall
                                                            )
                                                            :
                                                            SvgIconConfig.direction.endSmall

                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div
                                    className='icons'
                                    style={{
                                        height: `calc(100% * ${length - 1} / ${length})`,
                                        top: `calc(100% / ${length * 2})`
                                    }}
                                >
                                    {
                                        valueState?.map((pos, index) => {
                                            return (
                                                (index < (length - 1)) ?
                                                    <div key={pos?.id} className='icon'>
                                                        {SvgIconConfig.direction.dotSmall}
                                                    </div>
                                                    :
                                                    null
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='positions'>
                                {
                                    valueState.map((item, index) => (
                                        <div
                                            className='position'
                                            key={item.id}
                                        >
                                            {item.textInput || ""}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="symmetry">
                        </div>
                    </div>
                    :
                    <div
                        ref={containerHtml}
                        className='mobileDirectionPositionsInput'>
                        <div className="symmetry">
                            <div className='icons'>
                                {
                                    valueState?.map((pos, index) => {
                                        let highlight = false
                                        let ascii = pos.index + 64
                                        let char = String.fromCharCode(ascii)
                                        if (pos.index != index) {
                                            highlight = true
                                        }

                                        return (
                                            <div key={pos?.id} className='icon'>
                                                {
                                                    (index == 0) ?
                                                        <Back>
                                                            {SvgIconConfig.direction.back}
                                                        </Back>
                                                        :
                                                        (
                                                            index == (length - 1) ?
                                                                (index != 1 && SvgIconConfig.direction.end)
                                                                :
                                                                <div className={StringTool.mergeClassName('char', highlight && "highlight")}>{char || ""}</div>
                                                        )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className='inputs'>
                            {
                                valueState.map((item, index) => (
                                    <div
                                        className='input'
                                        key={item.id}
                                    >
                                        <input
                                            autoFocus={index == indexFocus}
                                            className='textInput'
                                            value={item.textInput || ""}
                                            onFocus={onFocusInput(index)}
                                            onBlur={onBlurIndex(index)}
                                            onChange={onChangeInput(index)}
                                            onKeyPress={onEnterInput(index)}
                                            placeholder={(index != 0) ? Resource.common.chooseDestination : Resource.common.chooseStartingPointOrClickOnTheMap}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="symmetry">
                            <div className='icons'>
                                {
                                    length == 2 ?
                                        valueState.map((pos, index) => {
                                            return (
                                                <div key={pos.id} className='icon'>
                                                    {
                                                        index == 1 &&
                                                        <button className='swap' onClick={onClickSwap}>
                                                            {SvgIconConfig.direction.swap}
                                                        </button>
                                                    }

                                                </div>
                                            )
                                        })

                                        :
                                        valueState.map((pos, index) => {
                                            return (
                                                <div key={pos.id} className='icon'>
                                                    {
                                                        index != 0 &&
                                                        <button onClick={onClickRemove(index)} className="remove">
                                                            {SvgIconConfig.direction.remove}
                                                        </button>
                                                    }

                                                </div>
                                            )
                                        })
                                }
                            </div>

                        </div>
                    </div >
            }
        </>
    )
}

MobileDirectionPositionsInput.propTypes = {
    onSearch: PropTypes.func,
    onPermutePositions: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.array,
    onSwap: PropTypes.func,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    onBlur: PropTypes.func,
    disableAddDestination: PropTypes.bool,
    onBlurInput: PropTypes.func,
    onEnter: PropTypes.func,
    onBack: PropTypes.func
};

export default MobileDirectionPositionsInput