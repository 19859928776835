import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Resource } from "../../resource";
import { RadioButton } from "ui";
import { WeightingEnum } from "../../enum";
import "./weightingOption.scss";

const option1 = [
  {
    name: Resource.direction.route.fastest,
    value: WeightingEnum.fastest,
    key: "fastest",
  },
  {
    name: Resource.direction.route.shortest,
    value: WeightingEnum.shortest,
    key: "shortest",
  },
  // {
  //   name: Resource.direction.route.balance,
  //   value: WeightingEnum.balance,
  //   key: "balance",
  // },
];
const option2 = [
  {
    name: Resource.direction.route.fastest,
    value: WeightingEnum.fastest,
    key: "fastest",
  },
  {
    name: Resource.direction.route.shortest,
    value: WeightingEnum.shortest,
    key: "shortest",
  },
];

const WeightingOption = (props) => {
  const { onChange, value, length } = props;
  const [stateValue, setStateValue] = useState(value);

  const handleChange = (newValue) => (e) => {
    onChange && onChange(newValue);
    setStateValue(newValue);
  };
  useEffect(() => {
    setStateValue(value);
  }, [value]);

  return (
    <div className="WeightingOptionCpn">
      <div className="titleOption">Lựa chọn cho tuyến đường</div>
      {length >= 4
        ? option1?.map((item) => (
            <div key={item?.key}>
              <RadioButton
                onChange={handleChange(item?.value)}
                checked={stateValue == item?.value ? true : false}
                label={item?.name}
                value={item?.value}
              />
            </div>
          ))
        : option2?.map((item) => (
            <div key={item?.key}>
              <RadioButton
                onChange={handleChange(item?.value)}
                checked={stateValue == item?.value ? true : false}
                label={item?.name}
                value={item?.value}
              />
            </div>
          ))}
    </div>
  );
};

WeightingOption.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
  length: PropTypes.any,
};

export default WeightingOption;
