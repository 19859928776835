import React, { useEffect, useState } from 'react';
import './routeOptimizationOption.scss'
import { CheckBox } from 'ui';
import PropTypes from 'prop-types';
import TooltipV2 from '../tooltipV2/tooltipV2';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';

function RouteOptimizationOption(props) {
  const { value, onChange, disabled, isMobile } = props
  const [valueState, setValueState] = useState(false)

  useEffect(() => {
    setValueState(value)
  }, [value])

  const onChangeCheck = (e) => {
    if (!disabled) {
      let check = e?.target.checked
      setValueState(check)
      onChange && onChange(check)
    }
  }

  return (
    <div className={StringTool.mergeClassName('routeOptimizationOption', disabled ? 'disabled' : '')}>
      <TooltipV2 description={Resource.direction.arrRouteOptimization} anchor='right' isWap={isMobile}>
        <div className='contentCheckBox'>
          <CheckBox
            className='checkBoxRoute'
            disabled={disabled}
            onChange={onChangeCheck}
            checked={valueState}
            label={Resource.direction.route.optimization}
          />
        </div>
      </TooltipV2>
    </div>
  )
};

RouteOptimizationOption.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default RouteOptimizationOption;
