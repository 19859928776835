import { AppConfig, CommonConfig } from "../config";

let defaultUser = {
  id: "string",
  roles: [
    {
      id: "string",
      code: "string",
      name: "string",
      permissionGroups: ["string"],
      description: "string",
      isUsed: true,
    },
  ],
  name: "string",
  email: "string",
  username: "string",
  lastName: "string",
  firstName: "string",
  avatar: "string",
  userId: "string",
  province: "string",
  phoneNumber: "string",
  language: "string",
  isSignedContract: true,
  adminDivisions: [
    {
      provinceName: "string",
      districtName: "string",
      subDistrictName: "string",
      geometry: {
        type: "string",
        coordinates: ["string"],
      },
    },
  ],
  isActive: true,
  customerType: "Individual",
  retrySendOtpOneDay: 0,
  expiredOtpSecond: 0,
  isDeveloper: true,
  isBlockDevelop: true,
  isNotifyAds: true,
  isNotifyUpdate: true,
  isBlockKey: true,
  blockKeyInfo: {
    isBlockKey: true,
    reason: "string",
    lockUser: "string",
  },
  priceTableId: "string",
};
defaultUser = null;
export var AppData = {
  draggableDiv: null,
  map: null,
  user: defaultUser,
  app: {
    reload: () => {
      forceUpdate();
    },
  },
  sidebar: {
    setShowToggle: (show) => {},
    setFullHeight: (full) => {},
    setFullBar: (full) => {},
    setOpen: (isOpen) => {},
  },
  searchBar: {
    setText: (textInput) => {},
    setLoading: (isLoading) => {},
  },

  shareControl: {
    share: (url = null) => {},
  },
  media: {
    showMediaWithVideos: (show, photosData, videosData, title) => {},
    showMediaWithPhotos: (show, photosData, videosData, title) => {},
  },
  alert: {
    alert: (message, type) => {},
  },
  mobile: {
    searchBar: {
      setText: (textInput) => {},
      setShowAutoComplete: (show) => {},
    },
    draggableBar: {
      setHeight: (height) => {},
      setDraggable: (draggable) => {},
      setMinHeight: (minHeight) => {},
      setMiddleHeight: (middleHeight) => {},
      setMaxHeight: (maxHeight) => {},
    },
  },
  sidebarPopup: {
    show: (children) => {},
    hide: () => {},
  },
  placeSearch: {
    search: (text, type, pageNumber) => {},
  },
  mapTypeControl: {
    moveCamera: (camera, option = null) => {},
  },
  mapid: null,
};
