import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { CommonConfig, LinkIconConfig } from '../../../config';
import { MapEventEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { LocationTool } from '../../../tool';
import MapView from '../mapView/mapView';
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '90px',
    position: 'relative',
  },
  bannerColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#00000089',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    textTransform: 'inherit',
    fontWeight: 300
  }
})
const moveCamera = (mapView, position) => {
  let camera = mapView?.getCamera()
  camera.setTarget(position)
  mapView?.moveCamera(camera, { animate: true })
}
function LocationInput({ value, onChange }) {
  const marker = new map4d.Marker({
    position: CommonConfig.defaultLocation,
    icon: new map4d.Icon(30, 42, LinkIconConfig.placeDetail.marker),
    anchor: [0.5, 1],
    zIndex: 99999,
    draggable: true
  })

  const classes = useStyles()
  const [showMap, setShowMap] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [stateValue, setStateValue] = useState()
  const mapShow = useRef()
  const mapInput = useRef()
  const eventDragMarker = useRef()
  const markerRef = useRef(marker)

  useEffect(() => {
    setStateValue(value)
  }, [value])

  useEffect(() => {
    return () => {
      eventDragMarker.current?.remove()
      markerRef.current?.setMap(null)
    };
  }, [])

  useEffect(() => {
    if (showMap) {
      markerRef.current?.setMap(mapInput.current)
    }
    else {

      markerRef.current?.setMap(mapShow.current)
    }
  }, [showMap])

  const handleShowMap = () => {
    setShowMap(true)
  }

  const handleCloseMap = () => {
    mapInput.current = null
    setShowMap(false)
  }

  const onLoadedMapShow = (map) => {
    mapShow.current = map
    let loc = CommonConfig.defaultLocation
    if (value && LocationTool.isValid(value)) {
      loc = value
    }
    markerRef.current?.setPosition(loc)
    markerRef.current?.setMap(mapShow.current)
    moveCamera(mapShow?.current, loc)
  }

  const onLoadedMapInput = (map) => {
    mapInput.current = map
    markerRef.current?.setPosition(stateValue)
    markerRef.current?.setMap(mapInput.current)
    moveCamera(mapInput?.current, stateValue)
    eventDragMarker.current = mapInput.current?.addListener(MapEventEnum.dragEnd, (args) => {
      setStateValue(args.marker.getPosition())
    }, { marker: true })
  }

  const handleApply = () => {
    onChange && onChange(stateValue)
    handleCloseMap()
    markerRef.current?.setMap(mapShow.current)
    moveCamera(mapShow?.current, stateValue)
  }

  return (
    <>
      <Box className={classes.root}>
        <MapView width='100%' height='100%' onLoadedMap={onLoadedMapShow} />
        <Box className={classes.bannerColor} onClick={handleShowMap}>
          <Button variant='outlined' className={classes.button}>Cập nhật vị trí trên bản đồ</Button>
        </Box>
      </Box>
      {
        showMap &&
        <Dialog
          fullScreen={fullScreen}
          open={true}
          onClose={handleCloseMap}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Vị trí</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <MapView width='100%' height='100%' onLoadedMap={onLoadedMapInput} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMap} color="primary">
              {Resource.common.cancel}
            </Button>
            <Button color="primary" autoFocus onClick={handleApply} >
              {Resource.common.apply}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

LocationInput.propTypes = {
  //
};

export default LocationInput;
