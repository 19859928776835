import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { DayOfWeekEnum, TypeBusinessHourEnum } from '../../../enum';
import { Resource } from '../../../resource';
import './updateBusinessHour.css'
import { useIsMobileScreen } from '../../../useHooks';
const useStyles = makeStyles({
  dialog: {
    width: '369px'
  },

  dayOfWeek: {
    border: '1px solid #C4C4C460',
    borderRadius: '4px',
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
      backgroundColor: '#508ff46b',
      '& span': {
        color: '#1769ec',
      }
    }

  },

  timePicker: {
    flexBasis: '43%'
  },

  dayOfWeekSelected: {
    borderRadius: '4px',
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#508ff46b',
    '& span': {
      color: '#1769ec',
    }
  }

})

const createBusinessHourDefault = () => {
  let arrDefaultValue = []
  for (let i = 0; i < 7; i++) {
    let obj = {
      dayOfWeek: i,
      type: TypeBusinessHourEnum.closed,
    }
    arrDefaultValue.push(obj)
  }
  return arrDefaultValue
}

const arrDayOfWeek = [Resource.updatePlace.sun, Resource.updatePlace.mon, Resource.updatePlace.tue, Resource.updatePlace.wed, Resource.updatePlace.thu, Resource.updatePlace.fri, Resource.updatePlace.sat,]

function UpdateBusinessHour(props) {
  const { defaultValue, onClose , onChange} = props
  const [day, setDay] = useState(DayOfWeekEnum.sunday)
  const classes = useStyles()
  const fullScreen = useIsMobileScreen();
  const [businessHours, setBusinessHours] = useState(() => {
    return defaultValue?.length == 7 ? defaultValue : createBusinessHourDefault()
  })

  const businessHoursRef = useRef(businessHours)
  const currentDayRef = useRef(DayOfWeekEnum.sunday)

  const [openTimeCheckBox, setOpenTimeCheckBox] = useState(false)
  const [closeTimeCheckBox, setCloseTimeCheckBox] = useState(false)
  const [openTimePicker, setOpenTimePicker] = useState(new Date());
  const [closeTimePicker, setCloseTimePicker] = useState(new Date());

  const convertTimeToString = (number) => {
    let text = "0" + number;
    text = text.substring(text.length - 2, text.length)
    return text
  }

  useEffect(() => {
    if (defaultValue?.length == 7) {
      setBusinessHours(defaultValue)
    }
  }, [props])

  useEffect(() => {
    setBusinessHours(businessHoursRef.current)
  }, [businessHoursRef.current])

  useEffect(() => {
    if (openTimeCheckBox) {
      setCloseTimeCheckBox(false)
    }
  }, [openTimeCheckBox])

  useEffect(() => {
    if (closeTimeCheckBox) {
      setOpenTimeCheckBox(false)
    }
  }, [closeTimeCheckBox])

  useEffect(() => {
    currentDayRef.current = day
    let current = businessHoursRef.current[day]
    setCloseTimeCheckBox(current?.type == TypeBusinessHourEnum.closed)
    setOpenTimeCheckBox(current?.type == TypeBusinessHourEnum.openAllDay)
    if (current?.type == TypeBusinessHourEnum.opened) {
      let openDate = new Date()
      let closeDate = new Date()
      openDate.setHours(current.open.hour)
      openDate.setMinutes(current.open.minute)
      closeDate.setHours(current.close.hour)
      closeDate.setMinutes(current.close.minute)
      setOpenTimePicker(openDate)
      setCloseTimePicker(closeDate)
    }
  }, [day])

  useEffect(() => {
    if (!openTimeCheckBox && !closeTimeCheckBox) {
      businessHoursRef.current[currentDayRef.current].type = TypeBusinessHourEnum.opened
      let open = {
        hour: openTimePicker?.getHours(),
        minute: openTimePicker?.getMinutes()
      }
      let close = {
        hour: closeTimePicker?.getHours(),
        minute: closeTimePicker?.getMinutes()
      }
      if (open.hour > close.hour || (open.hour == close.hour && open.minute < close.minute)) {
        close.dayOfWeek = currentDayRef.current + 1
      }
      if (close.dayOfWeek > DayOfWeekEnum.saturday) {
        close.dayOfWeek = DayOfWeekEnum.sunday
      }
      businessHoursRef.current[currentDayRef.current].open = open
      businessHoursRef.current[currentDayRef.current].close = close

      let hourOpenStr = convertTimeToString(open.hour)
      let minuteOpenStr = convertTimeToString(open.minute)
      let hourCloseStr = convertTimeToString(close.hour)
      let minuteCloseStr = convertTimeToString(close.minute)
      businessHoursRef.current[currentDayRef.current].text = `${hourOpenStr}:${minuteOpenStr} - ${hourCloseStr}:${minuteCloseStr}`
    }
    else if (openTimeCheckBox && !closeTimeCheckBox) {
      businessHoursRef.current[currentDayRef.current].type = TypeBusinessHourEnum.openAllDay
      businessHoursRef.current[currentDayRef.current].open = {
        hour: 0,
        minute: 0
      }
      businessHoursRef.current[currentDayRef.current].close = null
      businessHoursRef.current[currentDayRef.current].text = null
    }
    else if (!openTimeCheckBox && closeTimeCheckBox) {
      businessHoursRef.current[currentDayRef.current].type = TypeBusinessHourEnum.closed
      businessHoursRef.current[currentDayRef.current].open = null
      businessHoursRef.current[currentDayRef.current].close = null
      businessHoursRef.current[currentDayRef.current].text = null
    }
  }, [openTimeCheckBox, closeTimeCheckBox, openTimePicker, closeTimePicker])

  const handleSave = () => {
    setBusinessHours(businessHoursRef.current)
    onChange && onChange(businessHoursRef.current)
    onClose()
  }

  const handleChangeOpenTimeCheckbox = (event) => {
    setOpenTimeCheckBox(event.target.checked)
  }

  const handleChangeCloseTimeCheckbox = (event) => {
    setCloseTimeCheckBox(event.target.checked)
  }

  const handleOnClickDayOfWeek = (index) => (e) => {
    setDay(index)
  }

  const handleOpenTimeChange = (value) => {
    setOpenTimePicker(value)
  }

  const handleCloseTimeChange = (value) => {
    setCloseTimePicker(value)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth='xl'
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <DialogTitle id="responsive-dialog-title">Chọn ngày và giờ</DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='space-between'>
          {businessHours.map((item, index) => (
            item?.dayOfWeek == day ?
              <Box key={v4()} className={classes.dayOfWeekSelected} onClick={handleOnClickDayOfWeek(index)} >
                <Typography variant='subtitle2' component='span'>{arrDayOfWeek[index]}</Typography>
              </Box>
              :
              <Box key={v4()} className={classes.dayOfWeek} onClick={handleOnClickDayOfWeek(index)} >
                <Typography variant='subtitle2' component='span'>{arrDayOfWeek[index]}</Typography>
              </Box>
          ))}
        </Box>

        <Box display='flex' justifyContent='space-evenly' m='16px 0'>
          <FormControlLabel
            control={
              <Checkbox
                checked={openTimeCheckBox}
                onChange={handleChangeOpenTimeCheckbox}
                name='openAllDay'
                color="primary"
              />
            }
            label={Resource.common.openAllTime}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={closeTimeCheckBox}
                onChange={handleChangeCloseTimeCheckbox}
                name='closed'
                color="primary"
              />
            }
            label={Resource.common.closeTime}
          />
        </Box>

        <Box display='flex' justifyContent='space-evenly' className='timePicker' >

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              className={classes.timePicker}
              ampm={false}
              label={Resource.updatePlace.timeOpen}
              value={openTimePicker}
              onChange={handleOpenTimeChange}
              inputVariant='outlined'
              disabled={(openTimeCheckBox || closeTimeCheckBox) ? true : false}
            />

            <TimePicker
              className={classes.timePicker}
              ampm={false}
              label={Resource.updatePlace.timeClose}
              value={closeTimePicker}
              onChange={handleCloseTimeChange}
              inputVariant='outlined'
              disabled={(openTimeCheckBox || closeTimeCheckBox) ? true : false}
            />

          </MuiPickersUtilsProvider>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {Resource.common.cancel}
        </Button>
        <Button color="primary" autoFocus onClick={handleSave}>
          {Resource.common.save}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UpdateBusinessHour.propTypes = {
  //
};

export default UpdateBusinessHour;
