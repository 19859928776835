import { Backdrop, Box, Card, CardHeader, Divider, IconButton, List, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import PersonIcon from '@material-ui/icons/Person';
import React, { Fragment, useState } from 'react';
import Avatar from '../../../components/common/avatar/avatar';
import ReportError from '../../../components/common/reportError/reportError';
import { AppConfig, CommonConfig, LinkIconConfig } from '../../../config';
import { CustomerTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AppTool, StringTool, UserTool } from '../../../tool';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${CommonConfig.padding}px`
  },
  avatar: {
    width: '36px',
    height: '36px',
    border: '1px solid #ffffff',
    fontSize: '14px'
  },
  card: {
    position: 'absolute',
    right: '10px',
    top: `${CommonConfig.heightSearchBar + (CommonConfig.padding * 2)}px`,
    width: 'calc(100% - 20px)'
  },
  userIcon: {
    backgroundColor: '#C8DDFE',
    width: '36px',
    height: '36px',
    margin: `${CommonConfig.padding}px`
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  itemHover: {
    '&:hover': {
      backgroundColor: '#F1F6FF'
    }
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    '& .subTitle .MuiTypography-body2': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%'
    }
  },
}));
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function MobileUserControl() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [showReportError, setShowReportError] = useState(false)
  const [currentHoverName, setCurrentHoverName] = useState('')

  const user = UserTool.getUser()

  const openManagePage = () => {
    window.location.href = window.location.origin + "/manager"
  }

  const handleLogout = () => {
    AppTool.logout()
  };

  const handleShowReportError = () => {
    setShowReportError(true)
  }

  const openProfilePage = () => {
    window.location.href = window.location.origin + "/user"
  }

  const openDeveloperPage = () => {
    window.location.href = window.location.origin + "/developer"
  }

  const menuUserControl = [
    {
      icon: LinkIconConfig.userControl.account,
      iconHover: LinkIconConfig.userControl.accountActive,
      name: Resource.user.manage,
      onClick: openProfilePage
    },
    {
      icon: LinkIconConfig.userControl.developer,
      iconHover: LinkIconConfig.userControl.developerActive,
      name: Resource.common.developerPage,
      isHidden: !user?.isDeveloper,
      onClick: openDeveloperPage
    },
    {
      icon: LinkIconConfig.userControl.manage,
      iconHover: LinkIconConfig.userControl.manageActive,
      name: Resource.common.managePage,
      onClick: openManagePage
    },
    // {
    //   icon: LinkIconConfig.userControl.gov,
    //   iconHover: LinkIconConfig.userControl.govActive,
    //   name: Resource.common.govPage,
    //   isHidden: !(user?.customerType == CustomerTypeEnum.government),
    //   onClick: openGovernmentPage
    // },
    {
      icon: LinkIconConfig.userControl.report,
      iconHover: LinkIconConfig.userControl.reportActive,
      name: Resource.common.reportError,
      onClick: handleShowReportError
    },
    {
      icon: LinkIconConfig.userControl.logout,
      iconHover: LinkIconConfig.userControl.logoutActive,
      name: Resource.common.logout,
      onClick: handleLogout
    }
  ]

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleCloseReportError = () => {
    setShowReportError(false)
  }

  const onMouseOver = (name) => (e) => {
    setCurrentHoverName(name)
  }

  const onMouseLeave = () => {
    setCurrentHoverName('')
  }

  return (
    user ?
      <>
        <Box className={classes.root} onClick={handleToggle}>
          <Avatar
            size='36'
            image={user.avatar}
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
          />
        </Box>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <Card className={classes.card}>
            <Box
              p={1}
              className={classes.cardHeader}
            >
              <Avatar
                size='36'
                image={user.avatar}
                firstName={user.firstName}
                lastName={user.lastName}
                email={user.email}
              />
              <Box
                ml={2}
                width='calc(100% - 70px)'
                className='subTitle'
              >
                <Typography variant='body2'>{StringTool.titleAccount(user.firstName, user.lastName, user.email)}</Typography>
                <Typography variant='body2' color='textSecondary'>{user.email}</Typography>
              </Box>
            </Box>
            <Divider />
            <List aria-label="Menu User">
              {
                menuUserControl?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {
                        index == menuUserControl?.length - 1 &&
                        <Divider />
                      }
                      {
                        !item?.isHidden &&
                        <ListItem
                          className={classes.itemHover}
                          button
                          onClick={item.onClick}
                          onMouseOver={onMouseOver(item?.name)}
                          onMouseLeave={onMouseLeave}
                        >
                          <ListItemIcon>
                            {
                              currentHoverName == item?.name ?
                                <img
                                  src={item?.iconHover}
                                  alt='icon'
                                  width={24}
                                  height={24}
                                />
                                :
                                <img
                                  src={item?.icon}
                                  alt='icon'
                                  width={24}
                                  height={24}
                                />
                            }

                          </ListItemIcon>
                          <ListItemText>
                            <Typography
                              variant='body2'
                              color={currentHoverName == item?.name ? 'primary' : 'inherit'}
                            >
                              {item?.name}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      }
                    </Fragment>

                  )
                })
              }
            </List>
          </Card>
        </Backdrop>
        {
          showReportError &&
          <ReportError onClose={handleCloseReportError} />
        }
      </> :
      <IconButton className={classes.userIcon} href={`/auth/login?returnUrl=${window.location.href}`} >
        <PersonIcon color="primary" />
      </IconButton>
  )
}

MobileUserControl.propTypes = {
  //
};

export default MobileUserControl;
