import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, makeStyles, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { v4 } from 'uuid';
import * as yup from "yup";
import { LinkIconConfig } from '../../../config';
import { TypeMetaDataEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { useIsMobileScreen } from '../../../useHooks';
import Field from '../field/field';

const useStyles = makeStyles({
  root: {
    border: '1px solid #b3b3b3',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  textPadding: {
    padding: '8px 25px 8px 14px'
  },
  dialog: {
    width: '500px'
  },
  addNew: {
    border: '1px solid #508FF4',
    cursor: 'pointer',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#508FF4',
  },
  removeIcon: {
    position: 'absolute',
    top: '3px',
    right: '3px',
    color: '#8a8e92',
    '&:hover': {
      color: '#000000'
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px'
    }
  },
  textNowrap: {
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})
function LinkInput(props) {
  const { value, defaultValue, onChange } = props

  const [stateValue, setStateValue] = useState(() => {
    let list = defaultValue || []
    let sortData = list?.sort((a, b) => a.order - b.order)
    return sortData
  })

  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(-1)
  const isMobile = useIsMobileScreen()

  const schema = yup.object().shape({
    name: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.common.name
    })),
    content: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.path

      }))
      .url(Resource.formatString(Resource.validate.typeError, {
        field: Resource.common.path,
        type: Resource.common.link.toLocaleLowerCase()
      }))
      .test("testDuplicate", Resource.formatString(Resource.validate.duplicate, { field: Resource.common.path }), function (value) {
        let check = true
        stateValue?.forEach((item, idx) => {
          if (value && value == item?.content && idx != index) {
            check = false
          }
        })
        return check;
      }),
    order: yup.number()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.order }))
      .typeError(Resource.formatString(Resource.validate.typeError, { field: Resource.common.order, type: Resource.common.number.toLowerCase() }))
      .min(1, Resource.formatString(Resource.validate.min, { field: Resource.common.order, min: 1 }))
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .test("testDuplicate", Resource.formatString(Resource.validate.duplicate, { field: Resource.common.order }), function (order) {
        let check = true
        stateValue?.forEach((item, idx) => {
          if (order && order == item?.order && idx != index) {
            check = false
          }
        })
        return check;
      }),
  })

  const { register, handleSubmit, errors, clearErrors, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  useEffect(() => {
    if ("value" in props) {
      let sortData = value?.sort((a, b) => a.order - b.order)
      setStateValue(sortData)
    }
  }, [props])

  useEffect(() => {
    if (!("value" in props)) {
      let sortData = stateValue?.sort((a, b) => a.order - b.order)
      onChange && onChange(sortData)
    }
  }, [stateValue])

  const handleOnClickItem = (index) => (e) => {
    setShow(true)
    setIndex(index)
  }

  const handleOnClickAddNew = () => {
    setIndex(-1)
    setShow(true)
    clearErrors()
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleRemoveItem = (index) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setStateValue(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      return list
    })
  }

  const onSubmit = (data) => {
    if (!("value" in props)) {
      setStateValue(prev => {
        let newValue = [...prev]
        if (index == -1) {
          newValue.push({
            ...data,
            type: TypeMetaDataEnum.link
          })
        }
        else {
          newValue[index] = {
            ...data,
            type: TypeMetaDataEnum.link
          }
        }
        newValue.sort((a, b) => a.order - b.order)
        return newValue
      })
    }
    else {
      let newValue = [...stateValue]
      if (index == -1) {
        newValue.push({
          ...data,
          type: TypeMetaDataEnum.link
        })
      }
      else {
        newValue[index] = {
          ...data,
          type: TypeMetaDataEnum.link
        }
      }
      newValue.sort((a, b) => a.order - b.order)
      onChange && onChange(newValue)
    }
    setShow(false)
  }

  const onClickSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      onSubmit(getValues())
    }
  }

  return (
    <>
      <Field label={Resource.common.link} icon={<img src={LinkIconConfig.requestUpdatePlace.link} width={24} height={24} />} isNowrapText>
        {
          stateValue?.map((item, index) => {
            return (
              <Box mb={2} key={v4()} className={classes.root} onClick={handleOnClickItem(index)} position='relative'>
                <Typography noWrap variant='subtitle2' className={classes.textPadding}>{item?.name}</Typography>
                <Icon onClick={handleRemoveItem(index)} className={classes.removeIcon}> <CloseIcon /> </Icon>
              </Box>
            )
          })
        }

        <Box mb={2} className={classes.addNew} onClick={handleOnClickAddNew}>
          <img src={LinkIconConfig.placeExtend.link} width={24} height={24} />
          <Typography variant='body1' className={classes.textPadding}>{Resource.common.addNew}</Typography>
        </Box>
      </Field>

      {
        show &&
        <Dialog
          fullScreen={isMobile}
          open={true}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          classes={
            {
              paper: (!isMobile) ? classes.dialog : ""
            }
          }
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            <DialogTitle title={index == -1 ? '' : stateValue[index]?.name} id="responsive-dialog-title" className={classes.textNowrap}>{index == -1 ? Resource.common.addNew : stateValue[index]?.name}</DialogTitle>
            <DialogContent dividers>

              <Field required label={Resource.common.name}>
                <TextField
                  key={stateValue[index]?.id}
                  id="name"
                  name='name'
                  variant="outlined"
                  defaultValue={stateValue[index]?.name}
                  inputRef={register}
                  error={errors.name ? true : false}
                  helperText={errors.name?.message}
                />
              </Field>

              <Field required label={Resource.common.path}>
                <TextField
                  key={stateValue[index]?.id}
                  id="content"
                  name='content'
                  variant="outlined"
                  defaultValue={stateValue[index]?.content}
                  inputRef={register}
                  error={errors.content ? true : false}
                  helperText={errors.content?.message}
                />
              </Field>

              <Field required label={Resource.common.order}>
                <TextField
                  key={stateValue[index]?.id}
                  type='number'
                  name='order'
                  id="order"
                  variant="outlined"
                  defaultValue={stateValue[index]?.order}
                  inputRef={register}
                  error={errors.order ? true : false}
                  helperText={errors.order?.message}
                />
              </Field>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {Resource.common.cancel}
              </Button>
              <Button color="primary" onClick={onClickSubmit}>
                {Resource.common.save}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      }
    </>
  )
}

LinkInput.propTypes = {
  //
};

export default LinkInput;
