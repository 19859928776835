import { Box, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { MapTypeAppState } from '../../../appState';
import { GeoJsonTool } from '../../../tool';
import { AppConfig, CommonConfig, LinkIconConfig, RouterConfig, UrlConfig } from '../../../config';
import { AppData } from '../../../data';
import { CodeEnum, GeometryTypeEnum, MapTypeEnum } from '../../../enum';
import { ApiTool, AppTool, PlaceTool, SidebarTool, StringTool, UserTool } from '../../../tool';
import Error from './../../common/error/error';
import DirectionButton from './../../common/directionButton/directionButton';
import { Resource } from '../../../resource';
import "./poiDetailEmbed.scss"
import { useQuery, useWindowSize } from '../../../useHooks';
import { SettingsOverscan } from '@material-ui/icons';


const worldCoords = [[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]

function PoiDetailEmbed(props) {
  const { id, type, lat, lng, data } = useParams()
  const { text } = useQuery()
  const history = useHistory()
  const location = useLocation()
  const [place, setPlace] = useState(null)
  const polygonRef = useRef(null)
  const polylineRef = useRef(null)
  const polygonsRef = useRef([])
  const polylinesRef = useRef([])
  const windowSize = useWindowSize()

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)

  const polygonHoleRef = useRef(null)

  const currentPlace = useRef(null)
  const [keySaved, setKeySaved] = useState(() => {
    return v4()
  })



  const [loading, setLoading] = useState(false)

  useEffect(() => {
    AppTool.setSearchBarText(place?.name || "")
  }, [place?.name])

  useEffect(() => {
    var sourcePlaces
    if (id) {
      AppTool.setSearchBarLoading(true)
      SidebarTool.setOpen(true)
      setLoading(true)
      sourcePlaces = ApiTool.get(UrlConfig.poi.detail.replace("{id}", id), (res) => {
        setLoading(false)
        AppTool.setSearchBarLoading(false)
        if (res?.code == CodeEnum.ok) {
          let typeCheck = PlaceTool.isAreaOrStreet(res?.result)
          setMarkerAndShowInfo(res?.result)
          drawPoly(res?.result)
          setPlace(res?.result)
          res?.result?.object?.id && AppData.map.setSelectedBuildings([res?.result?.object?.id])
          if (!typeCheck && !location.state?.disableMoveMap) {
            moveCamera(res?.result)
          }
        }
        else {
          setPlace(null)
        }
      })
    }

    return () => {
      currentPlace.current?.setMap(null)
      polylineRef.current?.setMap(null)
      polygonRef.current?.setMap(null)
      polygonsRef.current?.forEach(polygon => {
        polygon?.setMap(null)
      })
      polylinesRef.current?.forEach(polyline => {
        polyline?.setMap(null)
      })
      polygonHoleRef.current?.setMap(null)
      sourcePlaces?.cancel()
      AppData.map.setSelectedBuildings([])
    }
  }, [id])

  useEffect(() => {
    var sourceLocation
    if (StringTool.isNumeric(lat) && StringTool.isNumeric(lng)) {
      let numLat = parseFloat(lat)
      let numLng = parseFloat(lng)
      if (-90 <= numLat && numLat <= 90 && -180 <= numLng && numLng <= 180) {
        let placeTemp = {
          location: {
            lat,
            lng
          },
          name: `${numLat.toFixed(6)}, ${numLng.toFixed(6)}`
        }
        let body = {
          lat: lat,
          lng: lng
        }
        setMarkerAndShowInfo(placeTemp)
        moveCamera(placeTemp)
        setPlace(placeTemp)
        AppTool.setSearchBarLoading(true)
        SidebarTool.setOpen(true)
        setLoading(true)
        sourceLocation = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          setLoading(false)
          AppTool.setSearchBarLoading(false)
          if (res?.code == CodeEnum.ok) {
            let newPlace = {
              ...placeTemp,
              address: res?.result.address
            }
            setPlace(newPlace)
          }
          else {
            setPlace(null)
          }
        })

      }
    }
    return () => {
      sourceLocation?.cancel()
    }
  }, [lat, lng])

  useEffect(() => {
    let placeCustom = null
    try {
      placeCustom = JSON.parse(decodeURIComponent(data))
    } catch (error) {

    }
    setMarkerAndShowInfo(placeCustom)
    moveCamera(placeCustom)
    setPlace(placeCustom)
    SidebarTool.setOpen(true)
  }, [data])

  const setMarkerAndShowInfo = (place) => {
    if (place) {
      if (place?.location) {

        currentPlace.current = new map4d.POI({
          position: place?.location,
          icon: LinkIconConfig.placeDetail.marker,
          title: place?.name,
          zIndex: 99999
        })
        currentPlace?.current.setMap(AppData.map)
      }
    }
  }

  const moveCamera = (place) => {
    if (place && place?.location) {
      let camera = AppData.map.getCamera()
      if (camera.getZoom() < CommonConfig.detailPlace2DZoom) {
        camera.setZoom(CommonConfig.detailPlace2DZoom)
      }
      camera.setTarget(place?.location)
      AppData.map.moveCamera(camera)

    }
  }

  const drawPolyline = (data) => {
    polylineRef.current = new map4d.Polyline({
      path: data || [],
      strokeColor: "#3335DF",
      strokeOpacity: 1.0,
      strokeWidth: 5,
      userInteractionEnabled: false,
    })
    polylineRef.current.setMap(AppData.map)
  }

  const drawPolygon = (data) => {
    polygonHoleRef.current = new map4d.Polygon({
      paths: [worldCoords, data[0]],
      fillColor: "#000000",
      fillOpacity: 0.2,
      strokeWidth: 1,
      userInteractionEnabled: false,
    })
    polygonHoleRef.current.setMap(AppData.map)
  }

  const drawMultiPolyline = (data) => {
    if (data && data.length > 0) {
      polylinesRef.current = []
      for (let i = 0; i < data.length; i++) {
        let polyline = new map4d.Polyline({
          path: data[i],
          strokeColor: CommonConfig.strokeBlue,
          strokeOpacity: 1,
          strokeWidth: 7,
          userInteractionEnabled: false,
        })
        polyline.setMap(AppData.map)
        polylinesRef.current.push(polyline)
      }
    }
  }

  const drawMultiPolygon = (data) => {
    if (data && data.length > 0) {
      let holePaths = []
      for (let i = 0; i < data.length; i++) {
        holePaths.push(data[i][0])
      }
      polygonHoleRef.current = new map4d.Polygon({
        paths: [worldCoords, ...holePaths],
        fillColor: "#000000",
        fillOpacity: 0.2,
        strokeWidth: 1,
        userInteractionEnabled: false,
      })
      polygonHoleRef.current.setMap(AppData.map)
    }
  }

  const drawPoly = (place) => {
    if (place) {
      if (place?.geometry?.type) {
        let geometry = place?.geometry
        switch (geometry.type) {
          case GeometryTypeEnum.lineString:
            drawPolyline(geometry.coordinates)
            break
          case GeometryTypeEnum.polygon:
            drawPolygon(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiLineString:
            drawMultiPolyline(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiPolygon:
            drawMultiPolygon(geometry.coordinates || [])
            break
        }
        let bound = new map4d.LatLngBounds()
        let points = GeoJsonTool.getPointForBound(geometry.coordinates, geometry.type)
        if (points?.length > 0) {
          for (let i = 0; i < points.length; i++) {
            bound.extend(points[i])
          }
          let paddingOptions = {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10
          }
          let camera = AppData.map.getCameraWithBounds(bound, paddingOptions)
          if (camera.getZoom() > 19) {
            camera.setZoom(19)
          }
          AppTool.moveCamera(camera, { animate: true })
        }
      }
    }
  }

  const onClickLink = () => {
    window.top.open(window.location.href?.replace("/embed", "/map"), "_blank")
  }

  let maxWidth = (windowSize.width || 0) - 20
  if (maxWidth > 300) {
    maxWidth = 300
  }
  return (
    <div
      className='poiDetailEmbed'
      style={{
        maxWidth: maxWidth
      }}
    >
      {
        !loading &&
        (
          !place ?
            <Box m={1}>
              <Error message={Resource.message.dataNotFound} subMessage={Resource.message.dataDoesNotExist} />
            </Box>
            :
            <>
              <div className='left'>
                {
                  windowSize.width >= 300 && windowSize.height >= 200 &&
                  <div className='name' title={place?.name}>
                    {place?.name}
                  </div>
                }
                {
                  place?.address && windowSize.width >= 400 && windowSize.height >= 300 &&
                  <div className='address'>
                    <span title={place?.address}>
                      {place?.address}
                    </span>
                  </div>
                }
                <a
                  className='link'
                  // onClick={onClickLink}
                  href={window.location.href?.replace("/embed/", "/map/")}
                  target="_blank"
                  title="Xem bản đồ lớn hơn"
                >
                  {
                    windowSize.width >= 300 ?
                      "Xem bản đồ lớn hơn"
                      :
                      <SettingsOverscan />
                  }

                </a>
              </div>
              {
                windowSize.height >= 300 && windowSize.width >= 500 &&
                <DirectionButton latlng={place?.location} />
              }

            </>
        )
      }
    </div>
  )
}

PoiDetailEmbed.propTypes = {
  //
};

export default PoiDetailEmbed;
