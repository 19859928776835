import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { DataExtendState } from '../../appState';
import { LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { CodeEnum, MapEventEnum, MediaTypeEnum, PlaceExtensionTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, DirectionTool, EncryptionTool, PlaceTool, UrlTool } from '../../tool';
import "./circleMenu.css";

const TypeButtonEnum = Object.freeze({
  info: "info",
  share: "share",
  direction: "direction",
  article: "article",
  popup: "popup",
  link: "link",
  video: "video",
  vr360: "vr360",
  photo: "photo"
})

const useStyles = makeStyles({
  menuLoading: {
    left: '50%',
    position: 'absolute',
    zIndex: 1000,
    height: '170px',
    width: '170px',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, .83)',
    transform: 'translate(-50%, -50%)',
  },

  buttonLoading: {
    position: 'absolute',
    zIndex: 2000,
    height: '36px',
    width: '36px',
    minWidth: '36px',
    margin: '-18px',
    borderRadius: '50%',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid #f5f5f5',
    background: '#FF0000',
    top: '50%',
    left: '50%',
  }
})

function CircleMenu() {
  const classes = useStyles()
  const history = useHistory()

  const [menu, setMenu] = useState({
    show: false,
    position: {
      left: 0,
      top: 0
    },
    menuButtons: []
  })
  const [place, setPlace] = useState(null)
  const [loading, setLoading] = useState(false)
  const [object, setObject] = useState(null)

  const metaDataRef = useRef()


  const setDataExtendState = useSetRecoilState(DataExtendState)

  const handleDirectionToHere = (latlng) => {
    let data = DirectionTool.encodeDirection({ positions: [null, latlng] })
    let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
    history.push({
      pathname: RouterConfig.direction,
      search: search
    })
  }

  const showPlaceExtendDetail = (item) => (e) => {
    switch (item.type) {
      case TypeButtonEnum.info:
        history.push({
          pathname: RouterConfig.object3d.detail.replace(":id", object?.id),
          state: {
            disableMoveMap: true
          }
        });
        break;
      case TypeButtonEnum.share:
        AppTool.share(location.origin + RouterConfig.object3d.detail.replace(":id", object?.id))
        break;
      case TypeButtonEnum.direction:
        handleDirectionToHere(object?.location)
        break;
      case TypeButtonEnum.article:
        setDataExtendState({
          show: true,
          dataExtend: metaDataRef.current?.article,
          indexSelect: 0,
          typeData: PlaceExtensionTypeEnum.article
        })
        break;
      case TypeButtonEnum.link:
        if (metaDataRef.current?.link.length == 1) {
          window.open(metaDataRef.current?.link[0].content, "_blank")
        }
        else {
          setDataExtendState({
            show: true,
            dataExtend: metaDataRef.current?.link,
            indexSelect: 0,
            typeData: PlaceExtensionTypeEnum.link
          })
        }
        break;
      case TypeButtonEnum.video:
        AppTool.showMediaWithVideos(true, place?.mediaFiles, metaDataRef.current?.video, place?.name)
        break;
      case TypeButtonEnum.vr360:
        setDataExtendState({
          show: true,
          dataExtend: metaDataRef.current?.vr360,
          indexSelect: 0,
          typeData: PlaceExtensionTypeEnum.vr360
        })
        break;
      case TypeButtonEnum.popup:
        setDataExtendState({
          show: true,
          dataExtend: metaDataRef.current?.popup,
          indexSelect: 0,
          typeData: PlaceExtensionTypeEnum.popup
        })
        break;
      case TypeButtonEnum.photo:
        AppTool.showMediaWithPhotos(true, place?.mediaFiles, metaDataRef.current?.video, place?.name)
        break;
      default:

    }
    setMenu({
      show: false
    })
  }

  const defaultButtons = () => {
    return [
      {
        color: "#86c65c",
        iconLink: LinkIconConfig.circleMenu.direction,
        title: Resource.common.direction,
        type: TypeButtonEnum.direction
      },
      {
        color: "#86c65c",
        iconLink: LinkIconConfig.circleMenu.information,
        title: Resource.common.detail,
        type: TypeButtonEnum.info
      },
      {
        color: "#86c65c",
        iconLink: LinkIconConfig.circleMenu.share,
        title: Resource.common.share,
        type: TypeButtonEnum.share
      },
    ]
  }

  useEffect(() => {
    if (!menu?.show) {
      AppData.map.setSelectedBuildings([])
    }
  }, [menu?.show])

  useEffect(() => {
    var source
    const eventClickObject = AppData.map.addListener(MapEventEnum.click, (args) => {
      const point = args.pixel
      let obj = args.building
      AppData.map.setSelectedBuildings([obj.id])
      setMenu({
        show: false,
        position: {
          left: point.x,
          top: point.y
        },
      })
      setObject(obj)
      setLoading(true)
      source = ApiTool.get(UrlConfig.object.detail.replace("{id}", obj?.id), (res) => {
        setLoading(false)
        let menuButtonsNew = configMenu({}, {})
        if (res?.code == CodeEnum.ok) {
          if (res?.result?.placeDetails?.length > 0) {
            let placeNew = res?.result?.placeDetails[0]
            let photoFilter = placeNew?.mediaFiles?.filter((item) => { return item?.typeFile == MediaTypeEnum.image })
            placeNew.mediaFiles = photoFilter || []
            setPlace(placeNew)
            let classifyMetaData = PlaceTool.classifyMetaData(placeNew?.metadata)
            let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
            metaDataRef.current = placeExtend
            menuButtonsNew = configMenu(placeExtend, placeNew)
            setPlace(placeNew)
            setMenu({
              show: true,
              position: {
                left: point.x,
                top: point.y
              },
              menuButtons: menuButtonsNew
            })
          }
          else {
            setMenu({
              show: true,
              position: {
                left: point.x,
                top: point.y
              },
              menuButtons: menuButtonsNew
            })
          }
        }
        else {
          setMenu({
            show: true,
            position: {
              left: point.x,
              top: point.y
            },
            menuButtons: menuButtonsNew
          })
        }
      })
    }, { mapbuilding: true })

    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      setMenu({
        show: false,
      })
    })

    const eventBoundsChanged = AppData.map.addListener(MapEventEnum.boundsChanged, (args) => {
      setMenu({
        show: false,
      })
    })

    const eventModeChanged = AppData.map.addListener(MapEventEnum.modeChanged, (args) => {
      setMenu({
        show: false,
      })
    })

    return () => {
      eventClickObject?.remove()
      eventClick?.remove()
      eventBoundsChanged?.remove()
      eventModeChanged?.remove()
      AppData.map.setSelectedBuildings([])
      source?.cancel()
    }

  }, [])

  const handleCloseMenu = () => {
    setMenu({
      show: false,
    })
  }

  const configMenu = (placeExtend, place) => {
    let menuButtonsNew = [...defaultButtons()]
    Object.keys(placeExtend)?.forEach(key => {
      if (placeExtend[key]?.length > 0) {
        let menuItem = {
          color: "#86c65c",
          click: null,
          title: "",
          iconLink: "",
          type: ""
        }
        switch (key) {
          case PlaceExtensionTypeEnum.article:
            menuItem.iconLink = LinkIconConfig.circleMenu.news
            menuItem.title = Resource.common.article
            menuItem.type = PlaceExtensionTypeEnum.article
            break;
          case PlaceExtensionTypeEnum.link:
            menuItem.iconLink = LinkIconConfig.circleMenu.link
            menuItem.title = Resource.common.link
            menuItem.type = PlaceExtensionTypeEnum.link
            break;
          case PlaceExtensionTypeEnum.popup:
            menuItem.iconLink = LinkIconConfig.circleMenu.popup
            menuItem.title = Resource.common.popup
            menuItem.type = PlaceExtensionTypeEnum.popup
            break;
          case PlaceExtensionTypeEnum.video:
            menuItem.iconLink = LinkIconConfig.circleMenu.video
            menuItem.title = Resource.common.video
            menuItem.type = PlaceExtensionTypeEnum.video
            break;
          case PlaceExtensionTypeEnum.vr360:
            menuItem.iconLink = LinkIconConfig.circleMenu.vr360
            menuItem.title = Resource.common.vr360
            menuItem.type = PlaceExtensionTypeEnum.vr360
            break;
          default:
            break;
        }
        menuButtonsNew.push(menuItem)
      }
    })
    let menuPhoto = {
      color: "#86c65c",
      click: null,
      title: Resource.common.images,
      iconLink: LinkIconConfig.circleMenu.photo,
      type: TypeButtonEnum.photo
    }
    if (place?.mediaFiles?.length > 0) {
      menuPhoto.photos = place?.mediaFiles
      menuButtonsNew.push(menuPhoto)
    }
    if (menuButtonsNew?.length > 0) {
      const angle = 360 / (menuButtonsNew?.length);
      menuButtonsNew?.forEach((item, index) => {
        item.angle = angle
        item.rotation = angle * index
      })
    }
    return menuButtonsNew
  }

  const renderMenuButtons = (size) => {
    let duration = 1300 / menu.menuButtons.length
    const buttons = menu.menuButtons.map((item, index) => {
      let styling = {
        transform:
          `rotate(${(item.rotation)}deg)
         translateX(${size}px)
         rotate(${-(item.rotation)}deg)
         translate(-50%, -50%)`,
        // backgroundColor: item.color,
        animationDuration: `${index * duration}ms`,
      }
      return (
        <Tooltip key={index} title={item.title} aria-label={item.title} placement="right">
          <div className={"menu-item item-show"}
            style={styling}
            onClick={showPlaceExtendDetail(item)}>
            <img src={item.iconLink} alt={item.title} style={{ objectFit: 'cover' }} />
          </div>
        </Tooltip>
      );
    });

    return (
      <div className={menu.show ? "button-bg animate-menu" : "button-bg"}> {buttons}</div>
    );
  }

  const renderMenuToggleBtn = () => {
    return (
      <Tooltip title={Resource.common.close} aria-label={Resource.common.close}>
        <button className={menu.show ? "menu-toggle toggle-open tooltip-circle" : "menu-toggle toggle-closed tooltip-circle"}
          onClick={handleCloseMenu}
        > <img src={menu.show ? LinkIconConfig.circleMenu.close : " "} width={16} height={16} alt="icon" />
        </button>
      </Tooltip>
    )
  }

  const renderMenuLoading = () => {
    return (
      <Box className={classes.menuLoading} style={{
        left: menu.position?.left,
        top: menu.position?.top,
      }}>
        <button className={classes.buttonLoading}
        > <img style={{ animation: 'rotate 1s linear infinite' }} src={LinkIconConfig.circleMenu.iconLoading} width={20} height={20} alt="icon" />
        </button>
      </Box>
    )
  }

  return (

    <>
      {
        loading &&
        renderMenuLoading()

      }
      {
        menu.show &&
        <div className="circular-menu" style={{
          left: menu.position?.left,
          top: menu.position?.top,
        }}>
          {renderMenuToggleBtn()}
          {renderMenuButtons(55)}
        </div>
      }
    </>
  )
}

CircleMenu.propTypes = {
  //
};

export default CircleMenu;
