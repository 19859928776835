import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  DisableMyLocationState,
  PositionState,
  ProvincesState,
} from "../../appState";
import { AppData } from "../../data";
import { AlertTypeEnum, CodeEnum, PositionTypeEnum } from "../../enum";
import { Resource } from "../../resource";
import { AppTool, MapTool } from "../../tool";
import { useQuery } from "../../useHooks";
import Suggest from "../suggest/suggest";
import GetProvinces from "./../getProvinces/getProvinces";

const PositionSelect = () => {
  // const {province} = useQuery()

  const [disableMyLocation, setDisableMyLocation] = useRecoilState(
    DisableMyLocationState
  );
  const [positionState, setPositionState] = useRecoilState(PositionState);
  const provinces = useRecoilValue(ProvincesState);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (provinces?.length > 0) {
      let list = provinces.map((t) => {
        let a = {
          ...t,
        };
        a.positionType = PositionTypeEnum.basic;
        return a;
      });
      setOptions(list);
    }
  }, [provinces]);

  useEffect(() => {
    if (!positionState) {
      setPositionState({
        name: Resource.position.current,
        positionType: PositionTypeEnum.current,
        id: PositionTypeEnum.current,
      });
    }
  }, [positionState]);

  const getOptionLabel = (option) => {
    return option?.name;
  };

  const getCaptionLabel = (option) => {
    let caption =
      option?.description == "Tỉnh"
        ? Resource.common.province
        : Resource.common.city;
    if (option?.positionType != PositionTypeEnum.basic) {
      caption = "";
    }
    return caption;
  };

  const getOptionDisabled = (option) => {
    return option?.disabled;
  };

  const onChange = (value) => {
    if (value?.positionType == PositionTypeEnum.realtime) {
      AppTool.getMyLocation((res) => {
        if (res?.code == CodeEnum.ok) {
          MapTool.moveToLocation(res?.result);
          value.location = res?.result;
          setPositionState(value);
        } else {
          AppTool.alert(Resource.myLocation.alert.error, AlertTypeEnum.warning);
          setDisableMyLocation(true);
          setPositionState((prev) => ({ ...prev }));
        }
      });
    } else if (value?.positionType == PositionTypeEnum.basic) {
      let coords = value?.viewbox?.split(",");
      let sw = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1]),
      };
      let ne = {
        lat: parseFloat(coords[2]),
        lng: parseFloat(coords[3]),
      };
      let bounds = new map4d.LatLngBounds(sw, ne);
      let camera = AppData.map?.getCameraWithBounds(bounds);
      AppTool.moveCamera(camera, { animate: true });
      setPositionState(value);
    } else {
      setPositionState(value);
    }
  };
  let listOptions = [...(options || [])];
  listOptions = [
    {
      name: Resource.position.current,
      positionType: PositionTypeEnum.current,
      id: PositionTypeEnum.current,
    },
    {
      name: Resource.position.realtime,
      positionType: PositionTypeEnum.realtime,
      id: PositionTypeEnum.realtime,
      disabled: disableMyLocation,
    },
    {
      name: Resource.position.unknown,
      positionType: PositionTypeEnum.unknown,
      id: PositionTypeEnum.unknown,
    },
  ].concat(listOptions);

  return (
    <>
      <GetProvinces />
      <Suggest
        searchPlaceHolder={Resource.position.search}
        value={positionState}
        maxLengthOfScroll={10}
        options={listOptions}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        getCaptionLabel={getCaptionLabel}
        onChange={onChange}
      />
    </>
  );
};

PositionSelect.propTypes = {
  //
};

export default PositionSelect;
