export const LinkIconConfig = Object.freeze({
    menu: '/mapAppRoot/icon/Menu-navigation.svg',
    street: '/mapAppRoot/icon/street.svg',
    arrow_top: '/mapAppRoot/icon/arrow-top.svg',
    arrow_button: '/mapAppRoot/icon/arrow-button.svg',
    turnRight: '/mapAppRoot/icon/arrow-undo-1.svg',
    turnLeft: '/mapAppRoot/icon/arrow-undo.svg',
    zoomIn: '/mapAppRoot/icon/zoom-in.svg',
    zoomOut: '/mapAppRoot/icon/zoom-out.svg',
    map2D: '/mapAppRoot/icon/2D.svg',
    map3D: '/mapAppRoot/icon/3D.svg',
    navigation: '/mapAppRoot/icon/navigation.svg',
    location: '/mapAppRoot/icon/my_location.svg',
    pin: '/mapAppRoot/icon/pin.png',
    pinHighlight: '/mapAppRoot/icon/pin-highlight.svg',
    google: '/mapAppRoot/icon/google.svg',
    greyLocation: '/mapAppRoot/icon/grey-location.svg',
    editGrey: '/mapAppRoot/icon/editGray.svg',
    add: "/mapAppRoot/icon/add.svg",
    markerPosition: "/mapAppRoot/icon/common/markerPosition.svg",
    searchBar: {
        building: "/mapAppRoot/icon/searchBarIcon/building.svg",
        area: "/mapAppRoot/icon/searchBarIcon/area.svg",
        close: "/mapAppRoot/icon/searchBarIcon/close.svg",
        search: '/mapAppRoot/icon/searchBarIcon/search.svg',
    },
    suggestPlace: {
        restaurant: '/mapAppRoot/icon/suggestPlaceIcon/ic_restaurant_48px.svg',
        atm: '/mapAppRoot/icon/suggestPlaceIcon/ATM.svg',
        coffee: '/mapAppRoot/icon/suggestPlaceIcon/Group_1757.svg',
        parking: '/mapAppRoot/icon/suggestPlaceIcon/Path_1820.svg',
        bar: '/mapAppRoot/icon/suggestPlaceIcon/bar.svg',
        shopping: '/mapAppRoot/icon/suggestPlaceIcon/shopping.svg',
        gasStation: '/mapAppRoot/icon/suggestPlaceIcon/gas.svg',
        hotel: '/mapAppRoot/icon/suggestPlaceIcon/hotel.svg',
        hospital: '/mapAppRoot/icon/suggestPlaceIcon/hospital.svg',
        education: '/mapAppRoot/icon/suggestPlaceIcon/education.svg',
        postOffice: '/mapAppRoot/icon/suggestPlaceIcon/postOffice.svg',
        bank: '/mapAppRoot/icon/suggestPlaceIcon/bank.svg'
    },
    setting: {
        objectIcon: '/mapAppRoot/icon/settingMenuIcon/object.svg',
        settingIcon: '/mapAppRoot/icon/settingMenuIcon/setting.svg',
        language: '/mapAppRoot/icon/settingMenuIcon/language.svg',
        myLocation: '/mapAppRoot/icon/settingMenuIcon/my_location.svg',
        my3DObject: '/mapAppRoot/icon/settingMenuIcon/my3DObject.svg',
        key: '/mapAppRoot/icon/settingMenuIcon/key.svg',
        menu: '/mapAppRoot/icon/settingMenuIcon/menu.svg'
    },
    effect: {
        sun: '/mapAppRoot/icon/settingEffectIcon/sun.svg',
        rain: '/mapAppRoot/icon/settingEffectIcon/rain.svg',
        rainV2: '/mapAppRoot/icon/settingEffectIcon/rainV2.svg',
        snow: '/mapAppRoot/icon/settingEffectIcon/snow.svg',
        snowV2: '/mapAppRoot/icon/settingEffectIcon/snowV2.svg',
        adjust: '/mapAppRoot/icon/settingEffectIcon/adjust.svg',
        adjustV2: '/mapAppRoot/icon/settingEffectIcon/adjustV2.svg',
        morning: '/mapAppRoot/icon/settingEffectIcon/morning.svg',
        morningV2: '/mapAppRoot/icon/settingEffectIcon/morningV2.svg',
        noon: '/mapAppRoot/icon/settingEffectIcon/noon.svg',
        noonV2: '/mapAppRoot/icon/settingEffectIcon/noonV2.svg',
        afternoon: '/mapAppRoot/icon/settingEffectIcon/afternoon.svg',
        afternoonV2: '/mapAppRoot/icon/settingEffectIcon/afternoonV2.svg',
        moon: '/mapAppRoot/icon/settingEffectIcon/moon.svg',
        moonV2: '/mapAppRoot/icon/settingEffectIcon/moonV2.svg'
    },
    circleMenu: {
        link: '/mapAppRoot/icon/circleMenuIcon/link.png',
        share: '/mapAppRoot/icon/circleMenuIcon/share.png',
        information: '/mapAppRoot/icon/circleMenuIcon/info.png',
        close: '/mapAppRoot/icon/circleMenuIcon/x-delete.svg',
        news: '/mapAppRoot/icon/circleMenuIcon/news.png',
        photo: '/mapAppRoot/icon/circleMenuIcon/photo.png',
        direction: '/mapAppRoot/icon/circleMenuIcon/arrow.png',
        popup: '/mapAppRoot/icon/circleMenuIcon/codes.png',
        video: '/mapAppRoot/icon/circleMenuIcon/camera.png',
        vr360: '/mapAppRoot/icon/circleMenuIcon/360.png',
        iconLoading: "/mapAppRoot/icon/circleMenuIcon/iconLoading.svg"
    },
    placeExtend: {
        article: '/mapAppRoot/icon/placeExtendOptions/article.svg',
        bookmark: '/mapAppRoot/icon/placeExtendOptions/bookmark.svg',
        clock: '/mapAppRoot/icon/placeExtendOptions/clock.svg',
        edit: '/mapAppRoot/icon/placeExtendOptions/edit.svg',
        link: '/mapAppRoot/icon/placeExtendOptions/link-24px.svg',
        location: '/mapAppRoot/icon/placeExtendOptions/location.svg',
        phone: '/mapAppRoot/icon/placeExtendOptions/phone.svg',
        picture: '/mapAppRoot/icon/placeExtendOptions/picture.svg',
        share: '/mapAppRoot/icon/placeExtendOptions/share-24px.svg',
        video: '/mapAppRoot/icon/placeExtendOptions/videocam-24px.svg',
        vr360: '/mapAppRoot/icon/placeExtendOptions/vr360.svg',
        website: '/mapAppRoot/icon/placeExtendOptions/website.svg',
        direction: '/mapAppRoot/icon/placeExtendOptions/directions-24px.svg',
        popup: '/mapAppRoot/icon/placeExtendOptions/code.svg',
        bookmarkBorder: '/mapAppRoot/icon/placeExtendOptions/bookmark_border-24px.svg',
        addressCode: '/mapAppRoot/icon/placeExtendOptions/addressCode.svg'
    },
    userControl: {
        account: '/mapAppRoot/icon/userControlIcon/account.svg',
        report: '/mapAppRoot/icon/userControlIcon/report.svg',
        logout: '/mapAppRoot/icon/userControlIcon/logout.svg',
        manage: '/mapAppRoot/icon/userControlIcon/manager.svg',
        accountActive: '/mapAppRoot/icon/userControlIcon/accountActive.svg',
        reportActive: '/mapAppRoot/icon/userControlIcon/reportActive.svg',
        logoutActive: '/mapAppRoot/icon/userControlIcon/logoutActive.svg',
        manageActive: '/mapAppRoot/icon/userControlIcon/managerActive.svg',
        gov: '/mapAppRoot/icon/userControlIcon/gov.svg',
        govActive: '/mapAppRoot/icon/userControlIcon/govActive.svg',
        developer: '/mapAppRoot/icon/userControlIcon/developer.svg',
        developerActive: '/mapAppRoot/icon/userControlIcon/developerActive.svg',
    },
    placeDetail: {
        phone: '/mapAppRoot/icon/placeDetailIcon/call.svg',
        clock: '/mapAppRoot/icon/placeDetailIcon/clock.svg',
        edit: '/mapAppRoot/icon/placeDetailIcon/edit-24px.svg',
        global: '/mapAppRoot/icon/placeDetailIcon/global.svg',
        location: '/mapAppRoot/icon/placeDetailIcon/maps-and-flags.svg',
        marker: '/mapAppRoot/icon/placeDetailIcon/Icon-location-gg.png',
        markerSelected: '/mapAppRoot/image/markerSelected.png',
        pencil: '/mapAppRoot/icon/placeDetailIcon/pencil.svg',
    },
    directionPois: {
        addPlace: '/mapAppRoot/icon/directionsIcon/addplace.svg',
        arrived: '/mapAppRoot/icon/directionsIcon/arrived.svg',
        start: '/mapAppRoot/icon/directionsIcon/start.svg',
        wrap: '/mapAppRoot/icon/directionsIcon/wrap.svg',
        fromIcon: '/mapAppRoot/icon/directionsIcon/from.svg',
        toIcon: '/mapAppRoot/icon/directionsIcon/to.svg',
        middleIcon: '/mapAppRoot/icon/directionsIcon/middle.png',
        dot: '/mapAppRoot/icon/directionsIcon/dot.svg'
    },
    routes: {
        car: '/mapAppRoot/icon/modeIcon/car.svg',
        carWhite: '/mapAppRoot/icon/modeIcon/car-white.svg',
        carActive: '/mapAppRoot/icon/modeIcon/car-active.svg',
        motorcycle: '/mapAppRoot/icon/modeIcon/motorcycle.svg',
        motorcycleActive: '/mapAppRoot/icon/modeIcon/motorcycle-active.svg',
        motorcycleWhite: '/mapAppRoot/icon/modeIcon/motorcycle-white.svg',
        bike: '/mapAppRoot/icon/modeIcon/bike.svg',
        bikeWhite: '/mapAppRoot/icon/modeIcon/bike-white.svg',
        bikeActive: '/mapAppRoot/icon/modeIcon/bike-active.svg',
        foot: '/mapAppRoot/icon/modeIcon/foot.svg',
        footActive: '/mapAppRoot/icon/modeIcon/foot-active.svg',
        footWhite: '/mapAppRoot/icon/modeIcon/person-white.svg',
    },
    requestUpdatePlace: {
        contact: '/mapAppRoot/icon/requestUpdatePlaceIcon/contact.svg',
        website: '/mapAppRoot/icon/requestUpdatePlaceIcon/web.svg',
        vr360: '/mapAppRoot/icon/requestUpdatePlaceIcon/_04-360.svg',
        photo: '/mapAppRoot/icon/requestUpdatePlaceIcon/add_a_photo-24px.svg',
        placeName: '/mapAppRoot/icon/requestUpdatePlaceIcon/add_business-24px.svg',
        category: '/mapAppRoot/icon/requestUpdatePlaceIcon/category-24px.svg',
        clock: '/mapAppRoot/icon/requestUpdatePlaceIcon/clock.svg',
        article: '/mapAppRoot/icon/requestUpdatePlaceIcon/description-24px.svg',
        label: '/mapAppRoot/icon/requestUpdatePlaceIcon/local_offer.svg',
        location: '/mapAppRoot/icon/requestUpdatePlaceIcon/location_on-24px.svg',
        object: '/mapAppRoot/icon/requestUpdatePlaceIcon/assistant.svg',
        description: '/mapAppRoot/icon/requestUpdatePlaceIcon/rate_review.svg',
        popup: '/mapAppRoot/icon/requestUpdatePlaceIcon/code.svg',
        link: '/mapAppRoot/icon/requestUpdatePlaceIcon/link-24px.svg',
        video: '/mapAppRoot/icon/requestUpdatePlaceIcon/videocam-24px.svg',
        reason: '/mapAppRoot/icon/requestUpdatePlaceIcon/reason.svg',
        json: '/mapAppRoot/icon/requestUpdatePlaceIcon/json.svg',
    },
    mobilePlaceSearch: {
        showList: '/mapAppRoot/icon/mobilePlaceSearchIcon/showList.svg',
        showMap: '/mapAppRoot/icon/mobilePlaceSearchIcon/showMap.svg'
    },
    common: {
        locationWhite: '/mapAppRoot/icon/common/locationWhite.svg',
        menu: '/mapAppRoot/icon/common/menu.svg',
        back: '/mapAppRoot/icon/common/back.svg',
        arrBottom: '/mapAppRoot/icon/common/arrBottom.svg',
        arrTop: '/mapAppRoot/icon/common/arrTop.svg',
        closeWhite: '/mapAppRoot/icon/common/closeWhite.svg',
        category: '/mapAppRoot/icon/requestUpdatePlaceIcon/category-24px.svg',
        name: '/mapAppRoot/icon/requestUpdatePlaceIcon/add_business-24px.svg',
        lat: '/mapAppRoot/icon/requestUpdatePlaceIcon/location_on-24px.svg',
        lng: '/mapAppRoot/icon/requestUpdatePlaceIcon/location_on-24px.svg',
        minZoom: '/mapAppRoot/icon/common/minZoom.svg',
        maxZoom: '/mapAppRoot/icon/common/maxZoom.svg',
        scale: '/mapAppRoot/icon/common/scale.svg',
        bearing: '/mapAppRoot/icon/common/bearing.svg',
        elevation: '/mapAppRoot/icon/common/elevation.svg',
        startDate: '/mapAppRoot/icon/requestUpdatePlaceIcon/clock.svg',
        endDate: '/mapAppRoot/icon/requestUpdatePlaceIcon/clock.svg',
        model: '/mapAppRoot/icon/common/model.svg',
        bottomSurface: '/mapAppRoot/icon/common/bottomSurface.svg',
        modelFile: '/mapAppRoot/icon/common/modelFile.svg',
        textureFile: '/mapAppRoot/icon/common/textureFile.svg',
        height: '/mapAppRoot/icon/common/height.svg',
        list: '/mapAppRoot/icon/common/list.svg',
        area: "/mapAppRoot/icon/common/area.svg",
        select: "/mapAppRoot/icon/common/select.svg",
        turnLeft: "/mapAppRoot/icon/common/turnLeft.svg",
        turnRight: "/mapAppRoot/icon/common/turnRight.svg",
        selectMore: "/mapAppRoot/icon/common/selectMore.svg",
        search: "/mapAppRoot/icon/common/search.svg",
        building: "/mapAppRoot/icon/common/building.svg",
        searchGray: "/mapAppRoot/icon/common/searchGray.svg",
        share: "/mapAppRoot/icon/common/share.svg",
        twitter: "/mapAppRoot/icon/common/twitter.svg",
        facebook: "/mapAppRoot/icon/common/facebook.svg",
        closeBlack: '/mapAppRoot/icon/common/closeBlack.svg',
        deletePlace: '/mapAppRoot/icon/common/deletePlace.svg'
    },
    placeGroupIcon: {
        list: '/mapAppRoot/icon/placeGroupIcon/listIcon.svg'
    },
    polygonInput: {
        point: "/mapAppRoot/icon/polygonInputIcon/yellow-point.png"
    },
    appIcon: {
        vimap: "/mapAppRoot/icon/appIcon/icon-vimap.svg",
        map4d: "/mapAppRoot/icon/appIcon/icon-map4d.svg"
    },
    steps: {
        'keep-left': "/mapAppRoot/icon/stepsIcon/keep-left.svg",
        'keep-right': "/mapAppRoot/icon/stepsIcon/keep-right.svg",
        'roundabout-left': "/mapAppRoot/icon/stepsIcon/roundabout-left.svg",
        'roundabout-right': "/mapAppRoot/icon/stepsIcon/roundabout-right.svg",
        'straight': "/mapAppRoot/icon/stepsIcon/straight.svg",
        'turn-left': "/mapAppRoot/icon/stepsIcon/turn-left.svg",
        'turn-right': "/mapAppRoot/icon/stepsIcon/turn-right.svg",
        'turn-sharp-left': "/mapAppRoot/icon/stepsIcon/turn-sharp-left.svg",
        'turn-sharp-right': "/mapAppRoot/icon/stepsIcon/turn-sharp-right.svg",
        'turn-slight-left': "/mapAppRoot/icon/stepsIcon/turn-slight-left.svg",
        'turn-slight-right': "/mapAppRoot/icon/stepsIcon/turn-slight-right.svg",
        'finish': "/mapAppRoot/icon/stepsIcon/reached-via.svg",
        'reached-via': "/mapAppRoot/icon/stepsIcon/reached-via.svg"
    },
    classService: {
        servicePlace: '/mapAppRoot/icon/classServiceIcon/classServive.svg',
        bgMap: '/mapAppRoot/icon/classServiceIcon/bgMap.png'
    },
    suggestPlaceChip: {
        searchIcon: '/mapAppRoot/icon/suggestPlaceChip/searchIcon.svg',
        active: {
            coffee: '/mapAppRoot/icon/suggestPlaceChip/active/coffee.svg',
            gasStation: '/mapAppRoot/icon/suggestPlaceChip/active/gasStation.svg',
            grocery: '/mapAppRoot/icon/suggestPlaceChip/active/grocery.svg',
            pharmacy: '/mapAppRoot/icon/suggestPlaceChip/active/pharmacy.svg',
            restaurant: '/mapAppRoot/icon/suggestPlaceChip/active/restaurant.svg',
            school: '/mapAppRoot/icon/suggestPlaceChip/active/school.svg',
            more: '/mapAppRoot/icon/suggestPlaceChip/active/more.svg',
        },
        unActive: {
            coffee: '/mapAppRoot/icon/suggestPlaceChip/unActive/coffee.svg',
            gasStation: '/mapAppRoot/icon/suggestPlaceChip/unActive/gasStation.svg',
            grocery: '/mapAppRoot/icon/suggestPlaceChip/unActive/grocery.svg',
            pharmacy: '/mapAppRoot/icon/suggestPlaceChip/unActive/pharmacy.svg',
            restaurant: '/mapAppRoot/icon/suggestPlaceChip/unActive/restaurant.svg',
            school: '/mapAppRoot/icon/suggestPlaceChip/unActive/school.svg',
            more: '/mapAppRoot/icon/suggestPlaceChip/unActive/more.svg',
            charging: '/mapAppRoot/icon/suggestPlaceChip/unActive/charging.svg',
            hotel: '/mapAppRoot/icon/suggestPlaceChip/unActive/hotel.svg',
            museum:'/mapAppRoot/icon/suggestPlaceChip/unActive/museum.svg',
            bank: '/mapAppRoot/icon/suggestPlaceChip/unActive/bank.svg',
            search: '/mapAppRoot/icon/suggestPlaceChip/unActive/search.svg',
            park: '/mapAppRoot/icon/suggestPlaceChip/unActive/park.svg'
        }
    },
    metadata: {
        remove: '/mapAppRoot/icon/metadataIcon/remove.svg',
        vr360: '/mapAppRoot/icon/metadataIcon/vr360.svg',
        vr360Add: '/mapAppRoot/icon/metadataIcon/vr360Add.svg',
        link: '/mapAppRoot/icon/metadataIcon/link.svg',
        linkAdd: '/mapAppRoot/icon/metadataIcon/linkAdd.svg',
    },
    photosInputV2: {
        camera: "/mapAppRoot/icon/photosInputV2/camera.svg",
        remove: "/mapAppRoot/icon/photosInputV2/remove.svg"
    },
    confirm: {
        error: '/mapAppRoot/icon/confirmIcon/error.svg',
        success: '/mapAppRoot/icon/confirmIcon/success.svg',
        warning: '/mapAppRoot/icon/confirmIcon/warning.svg',
    },
    maneuver: {
        keepLeft: "/mapAppRoot/icon/maneuverIcon/keep-left.svg",
        keepRight: "/mapAppRoot/icon/maneuverIcon/keep-right.svg",
        roundaboutLeft: "/mapAppRoot/icon/maneuverIcon/roundabout-left.svg",
        roundaboutRight: "/mapAppRoot/icon/maneuverIcon/roundabout-right.svg",
        straight: "/mapAppRoot/icon/maneuverIcon/straight.svg",
        turnLeft: "/mapAppRoot/icon/maneuverIcon/turn-left.svg",
        turnRight: "/mapAppRoot/icon/maneuverIcon/turn-right.svg",
        turnSharpLeft: "/mapAppRoot/icon/maneuverIcon/turn-sharp-left.svg",
        turnSharpRight: "/mapAppRoot/icon/maneuverIcon/turn-sharp-right.svg",
        turnSlightLeft: "/mapAppRoot/icon/maneuverIcon/turn-slight-left.svg",
        turnSlightRight: "/mapAppRoot/icon/maneuverIcon/turn-slight-right.svg",
        finish: "/mapAppRoot/icon/maneuverIcon/finish.svg",
        reachedVia: "/mapAppRoot/icon/maneuverIcon/reached-via.svg"
    },
    nearByOption: {
        restaurant: '/mapAppRoot/icon/nearByOptionIcon/restaurant.svg',
        hotel: '/mapAppRoot/icon/nearByOptionIcon/hotel.svg',
        gasStation: '/mapAppRoot/icon/nearByOptionIcon/gasStation.svg',
        cafe: '/mapAppRoot/icon/nearByOptionIcon/cafe.svg',
        parking: '/mapAppRoot/icon/nearByOptionIcon/park.svg',
        bank: '/mapAppRoot/icon/nearByOptionIcon/bank.svg',
        bar: '/mapAppRoot/icon/nearByOptionIcon/bar.svg',
        store: '/mapAppRoot/icon/nearByOptionIcon/store.svg',
        postOffice: '/mapAppRoot/icon/nearByOptionIcon/postOffice.svg',
        hospital: '/mapAppRoot/icon/nearByOptionIcon/hospital.svg',
        more: '/mapAppRoot/icon/nearByOptionIcon/more.svg',
        less: '/mapAppRoot/icon/nearByOptionIcon/less.svg',
        school: '/mapAppRoot/icon/nearByOptionIcon/school.svg'
    }
})