import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, CheckBox, Chip, HorizontalLine, TextInput } from 'ui';
import { object } from 'yup';
import { GroupPlaceTypeState, MainCategoryState, PlaceTypeState } from '../../appState';
import { LinkIconConfig, UrlConfig } from '../../config';
import { AlertTypeEnum, CodeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, StringTool } from '../../tool';
import MultiInput from '../common/multiInput/multiInput';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import DialogHeader from '../dialogHeader/dialogHeader';
import "./subcategory.scss";

const isEqualArr = (arr1, arr2) => {
  let equal = false
  if ((!arr1?.length > 0) && (!arr2?.length > 0)) {
    equal = true
  }
  else if (arr1?.length > 0 && arr2?.length > 0 && arr1?.length == arr2?.length) {
    let isDiff = Object.keys(arr1)?.some(key => arr1[key != arr2[key]])
    equal = !isDiff
  }
  return equal
}

// const MAX_LENGTH_SEARCH = 256
const Subcategory = (props) => {
  const { value, onChange } = props
  const htmlInput = useRef()

  const [mainCategoryValue, setMainCategoryValue] = useRecoilState(MainCategoryState)

  const [openDialog, setOpenDialog] = useState(false)
  const allPlaceTypeDict = useRecoilValue(PlaceTypeState)
  const [placeTypeDict, setPlaceTypeDict] = useState({})
  const [stateValue, setStateValue] = useState(value || [])
  const [valueDict, setValueDict] = useState({})
  const [groupId, setGroupId] = useState("")
  const [selectIdsOfGroup, setSelectIdsOfGroup] = useState([])
  const [idsOfGroup, setIdsOfGroup] = useState([])
  const [groupDict, setGroupDict] = useState({})
  const [textInput, setTextInput] = useState("")
  const [idsOfSearchAll, setIdsOfSearchAll] = useState(null)
  const [enableApply, setEnableApply] = useState(false)
  const currentAppliedValueRef = useRef(stateValue)
  const currentValueWhenOpenGroupRef = useRef()

  useEffect(() => {
    if (allPlaceTypeDict) {
      let allDict = JSON.parse(JSON.stringify(allPlaceTypeDict))
      if (mainCategoryValue) {
        delete allDict[mainCategoryValue]
        setPlaceTypeDict(allDict)
      }
      else {
        setPlaceTypeDict(allPlaceTypeDict)
      }
    }
  }, [mainCategoryValue, allPlaceTypeDict])

  useEffect(() => {
    if (mainCategoryValue && stateValue) {
      if (stateValue?.includes(mainCategoryValue)) {
        let origin = [...stateValue]
        let valueNew = origin?.filter(id => id !== mainCategoryValue)
        setStateValue(valueNew)
        currentAppliedValueRef.current = valueNew
        onChange(valueNew)
      }
    }
  }, [mainCategoryValue, stateValue])

  useEffect(() => {
    setStateValue(value)
    currentAppliedValueRef.current = value || []
  }, [value])

  useEffect(() => {
    let valueDictNew = {}
    stateValue?.forEach(id => {
      valueDictNew[id] = true
    })
    setValueDict(valueDictNew)
  }, [stateValue])

  useEffect(() => {
    let group = groupDict[groupId]
    if (group) {
      let idsOfGroupNew = Object.values(placeTypeDict)?.filter(placeType => placeType.group == group.id)?.map(p => p.id)
      let selectIdsOfGroupNew = idsOfGroupNew?.filter(id => valueDict[id])
      setSelectIdsOfGroup(selectIdsOfGroupNew)
    }
    else {
      setSelectIdsOfGroup([])
    }

  }, [valueDict, groupId, groupDict])

  //search
  useEffect(() => {

    //search chung
    if (!groupId) {
      if (textInput) {
        let textKey = StringTool.removeUnikey(textInput)?.toLowerCase()
        let idsNew = Object.values(placeTypeDict)?.filter(placeType => {
          let nameKey = StringTool.removeUnikey(placeType?.name || "")?.toLowerCase()
          let idKey = StringTool.removeUnikey(placeType?.id || "")?.toLowerCase()
          return (nameKey?.includes(textKey) || idKey?.includes(textKey))
        })?.map(p => p.id)
        setIdsOfSearchAll(idsNew || [])
      }
      else {
        setIdsOfSearchAll(null)
      }
    }
    else {
      if (textInput) {
        let textKey = StringTool.removeUnikey(textInput)?.toLowerCase()
        let idsOfGroupNew = Object.values(placeTypeDict)?.filter(placeType => placeType?.group == groupId)?.filter(
          (type) => {
            let nameKey = StringTool.removeUnikey(type?.name || "")?.toLowerCase()
            let idKey = StringTool.removeUnikey(type?.id || "")?.toLowerCase()
            return (nameKey?.includes(textKey) || idKey?.includes(textKey))
          }
        )?.map(p => p.id)
        setIdsOfGroup(idsOfGroupNew)
      }
      else {
        let idsOfGroupNew = Object.values(placeTypeDict)?.filter(placeType => placeType.group == groupId)?.map(p => p.id)
        setIdsOfGroup(idsOfGroupNew)
      }
    }
  }, [textInput, groupId, placeTypeDict])

  useEffect(() => {
    let enable = false
    if (
      (groupId && !isEqualArr(stateValue, currentValueWhenOpenGroupRef.current))
      || (!groupId && !isEqualArr(stateValue, currentAppliedValueRef.current))
    ) {
      enable = true
    }
    setEnableApply(enable)
  }, [groupId, stateValue])

  useEffect(() => {
    return () => {
      setMainCategoryValue(null)
    }
  }, [])

  const onChangeInput = (e) => {
    let text = e?.target?.value || ""
    setTextInput(text)
  }

  const onApply = () => {
    setTextInput("")
    if (groupId) {
      setGroupId("")
    }
    else {
      currentAppliedValueRef.current = stateValue
      onChange(stateValue)
      setOpenDialog(false)
      setEnableApply(false)
    }
  }

  const getAllGroupPlaceType = () => { // lấy danh sách tất cả các nhóm danh mục
    ApiTool.get(UrlConfig.placeTypeGroup.listAll, (res) => {
      if (res?.code == CodeEnum.ok) {
        let groups = res?.result || []
        let dict = {

        }
        groups?.forEach(group => {
          dict[group?.id] = group
        })
        setGroupDict(dict)
      }
    }, true)
  };
  useEffect(() => {
    const auto = getAllGroupPlaceType()
    return () => auto?.cancel();
  }, [])

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleChangeInput = (type) => {
    let array = type.map(item => item?.id) // Thay đổi (ở đây có nghĩa là xóa bớt) danh mục phụ ra khỏi danh sách - Khi chưa mở dialog
    setStateValue(array)
    currentAppliedValueRef.current = array
    onChange && onChange(array)
  }

  const handleToBack = () => {
    setTextInput("")
    if (groupId) {
      setStateValue(currentValueWhenOpenGroupRef.current)
      setGroupId("")
    }
    else {
      setStateValue(currentAppliedValueRef.current)
      setOpenDialog(false)
    }
  }

  const handleActiveSuggestGroup = (value) => () => {
    setGroupId(value?.id || "")
    currentValueWhenOpenGroupRef.current = [...stateValue || []]
  }

  const handleChangeChecked = (id) => (e) => {
    let newchecked = e?.target?.checked
    if (newchecked) {
      setStateValue(prev => {
        let prevNew = [...prev || []]
        prevNew?.push(id)
        return prevNew
      })
    }
    else {
      setStateValue(prev => {
        return prev?.filter(idN => idN != id)
      })
    }
  }

  const handleDeleteItem = (id) => (e) => {
    setStateValue(prev => {
      return prev?.filter(idCheck => idCheck != id)
    })
  }

  const onFocusTextInput = () => {
    htmlInput?.current?.focus()
  }

  return (
    <>
      <MultiInput
        label={Resource.categoryList.sub}
        placeholder={Resource.categoryList.select}
        value={Object.values(placeTypeDict)?.filter(item => valueDict[item?.id])}
        onClick={handleOpenDialog}
        onChange={handleChangeInput}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        onBack={handleToBack}
        className="dialogSubCategory"
        disableCloseOnClickBackdrop
      >
        <DialogHeader showBack hideClose>
          {groupId ? groupDict[groupId]?.name : Resource.categoryList.cate}
        </DialogHeader>
        <div className='boxSearhSubCategory'>
          <div className='inputPlaceType' onClick={onFocusTextInput}>
            <div className='listInput'>
              {
                groupId ?
                  selectIdsOfGroup?.map((id, index) => {
                    return (
                      <Chip
                        onDelete={handleDeleteItem(id)}
                        key={id}
                        className="chipInput"
                      >{placeTypeDict[id]?.name || id}</Chip>
                    )
                  })
                  :
                  stateValue?.map((id, index) => {
                    return (
                      <Chip
                        onDelete={handleDeleteItem(id)}
                        key={id}
                        className="chipInput"
                      >{placeTypeDict[id]?.name || id}</Chip>
                    )
                  })
              }
              <div className='boxSearch'>
                <input
                  className='inputSearch'
                  value={textInput}
                  onChange={onChangeInput}
                  ref={htmlInput}
                  placeholder={((stateValue?.length > 0 && !groupId) || (groupId && selectIdsOfGroup?.length > 0)) ? "" : Resource.categoryList.inputType}
                />
                {((stateValue?.length > 0 && !groupId) || (groupId && selectIdsOfGroup?.length > 0)) ? "" : <img src={LinkIconConfig.common.searchGray} alt="icon-search" />}
              </div>
            </div>
          </div>
          <HorizontalLine className='horizontalLine' />
        </div>
        <DialogBody className='dialogBodySubCategory'>
          {
            groupId ?
              <>
                {
                  idsOfGroup?.length > 0 &&
                  <div className="listPlaceType">
                    {
                      idsOfGroup?.map((id, index) => {
                        return (
                          <Fragment key={id}>
                            <CheckBox
                              checked={valueDict[id] ? true : false}
                              label={placeTypeDict[id]?.name || id}
                              positionCheck="right"
                              className='checkBoxItem'
                              onClick={handleChangeChecked(id)}
                            />
                            <HorizontalLine className='horizontalLineItem' />
                          </Fragment>
                        )
                      })
                    }
                  </div>

                }
                {
                  idsOfGroup && idsOfGroup?.length == 0 &&
                  <div className="notFoundSearch">{Resource.categoryList.notFound}</div>
                }
              </>
              :
              //màn hình all
              <>
                {
                  textInput ?
                    //list all type
                    <div className="listPlaceType">
                      {
                        idsOfSearchAll?.length > 0 &&
                        idsOfSearchAll?.map((id, index) => {
                          return (
                            <Fragment key={id}>
                              <CheckBox
                                checked={valueDict[id] ? true : false}
                                label={placeTypeDict[id]?.name || id}
                                positionCheck="right"
                                className='checkBoxItem'
                                onClick={handleChangeChecked(id)}
                              />
                              <HorizontalLine className='horizontalLineItem' />
                            </Fragment>

                          )
                        }
                        )
                      }
                      {
                        idsOfSearchAll && idsOfSearchAll?.length == 0 &&
                        <div className="notFoundSearch">{Resource.categoryList.notFound}</div>
                      }
                    </div>
                    :
                    //list group
                    <div className="listGroupPlaceType">
                      {
                        Object?.values(groupDict)?.map((item, index) => {
                          let idsOfTheGroup = Object.values(placeTypeDict)?.filter(p => p.group == item?.id)?.map(e => e.id)
                          let count = 0
                          idsOfTheGroup?.forEach(id => {
                            if (valueDict[id]) {
                              count = count + 1
                            }
                          })
                          return (
                            <div
                              key={item?.id}
                              className="itemGroupPlaceType"
                              onClick={handleActiveSuggestGroup(item)}
                            >
                              <img className="itemIcon " src={item?.icon} alt={item?.id} />
                              <div className="itemName">
                                <div>
                                  {item?.name}
                                  <span className='numberPlaceType'> {count > 0 && `(${count})`}</span>
                                </div>
                                <img src={LinkIconConfig.common.selectMore} />
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </>
          }
        </DialogBody>
        <DialogAction>
          <Button variant='cancel' onClick={handleToBack} style={{ marginRight: "0.75rem" }}>
            {Resource.button.cancel}
          </Button>
          <Button disabled={!enableApply} variant='done' onClick={onApply}>
            {Resource.common.apply}
          </Button>
        </DialogAction>
      </Dialog>
    </>
  );
};

Subcategory.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
};

export default Subcategory;
