import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AppData } from '../../../data';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum, MapEventEnum } from '../../../enum';
import { ApiTool, UrlTool } from '../../../tool';
import { useHistory, useParams } from 'react-router-dom';
import { RouterConfig } from '../../../config/routerConfig';
import { useQuery } from '../../../useHooks';

function PlaceControl() {
  const { text , type} = useQuery()
  const history = useHistory()
  const textRef = useRef(null)
  const typeRef = useRef(null)

  useEffect(() => {
    textRef.current = text
    typeRef.current = type
  }, [text, type])

  useEffect(() => {
    let apiTextSearch
    const eventClickPlace = AppData.map.addListener(MapEventEnum.click, (args) => {
      let place = args?.place;
      let searchPara = {
        text: place?.name,
        location: `${place?.location?.lat},${place?.location?.lng}`
      }
      apiTextSearch = ApiTool.queryGetFromJson(UrlConfig.poi.searchByText, searchPara, (res) => {
        if (res?.code == CodeEnum.ok && res?.result?.length > 0) {
          let placeSearch = res?.result[0]
          if (textRef.current) {
            history.push({
              pathname: RouterConfig.place.search,
              search: UrlTool.createSearch({text: textRef.current, type: typeRef.current, id: placeSearch?.id}),
              state: {
                disableMoveMap: true
              }
            })
          }
          else {
            history.push({
              pathname: RouterConfig.place.detail.replace(":id", placeSearch?.id),
              state: {
                disableMoveMap: true
              }
            })
          }
        }
      })
    }, { place: true })

    return () => {
      eventClickPlace?.remove()
      apiTextSearch?.cancel()
    }
  }, [])

  useEffect(() => {
    const eventHoverPlace = AppData.map.addListener(MapEventEnum.hover, (args) => {
      document.getElementsByTagName("body")[0].style.cursor = "pointer"
    }, { place: true })
    return () => {
      eventHoverPlace?.remove()
    }
  }, [])

  useEffect(() => {
    const eventHoverOut = AppData.map.addListener(MapEventEnum.hover, (args) => {
      if (!args?.place) {
        document.getElementsByTagName("body")[0].style.cursor = null
      }
    })
    return () => {
      eventHoverOut?.remove()
    }
  }, [])

  useEffect((e) => {
    const eventMouseOut = AppData.map.addListener(MapEventEnum.mouseOut, (args) => {
      document.getElementsByTagName("body")[0].style.cursor = null
    })
    return () => {
      eventMouseOut?.remove()
    }
  }, [])

  return (
    null
  )
}

PlaceControl.propTypes = {
  //
};

export default PlaceControl;
