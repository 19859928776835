import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { PlaceTool } from '../../../tool';
import LinkInputV2 from '../linkInputV2/linkInputV2';
import Vr360InputV2 from '../vr360InputV2';
import "./metaDataInputV2.scss"

function MetaDataInputV2(props) {
  const { value, onChange, subTitle } = props
  const [stateValue, setStateValue] = useState([])

  useEffect(() => {
    let classifyMetaData = PlaceTool.classifyMetaData(value)
    let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
    setStateValue(placeExtend)
  }, [value])

  const onChangeVr360 = (list) => {
    setStateValue((prev) => {
      let data = {
        ...prev,
        vr360: list
      }
      let dataMerge = prev?.link?.concat(list)
      onChange && onChange(dataMerge)
      return data
    })
  }

  const onChangeLink = (list) => {
    setStateValue((prev) => {
      let data = {
        ...prev,
        link: list
      }
      let dataMerge = prev?.vr360?.concat(list)
      onChange && onChange(dataMerge)
      return data
    })
  }

  return (
    <div className="metaDataInput">
      <Vr360InputV2 value={stateValue?.vr360} onChange={onChangeVr360} subTitle={subTitle || ''} />
      <LinkInputV2 value={stateValue?.link} onChange={onChangeLink} subTitle={subTitle || ''} />
    </div>
  )
}

MetaDataInputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  subTitle: PropTypes.string
};

export default MetaDataInputV2;
