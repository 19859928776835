import { Box, ListItem, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CanAddDestinationState, CanDirectFromHereState, CanDirectToHereState, HiddenAddClickState } from '../../../appState';
import { RouterConfig } from '../../../config/routerConfig';
import { AppEventEnum, PermissionEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AppEventTool, AppTool, DirectionTool, UrlTool, UserTool } from '../../../tool';
import { DirectionDataState } from './../../../appState/directionState';
import { AlertTypeEnum } from './../../../enum/alertTypeEnum';

function RightChildMenu({ latlng, onClick }) {

  const { pathname } = useLocation()

  const history = useHistory()
  const inputRef = useRef()

  const directionDataState = useRecoilValue(DirectionDataState)
  const canAddDestination = useRecoilValue(CanAddDestinationState)
  const canDirectFromHere = useRecoilValue(CanDirectFromHereState)
  const canDirectToHere = useRecoilValue(CanDirectToHereState)
  const hiddenAdd = useRecoilValue(HiddenAddClickState)

  const handleDirectionFromHere = () => {
    if (pathname == RouterConfig.direction) {
      AppEventTool.triggerEvent(AppEventEnum.directFromHere, latlng)
    }
    else {
      let data = DirectionTool.encodeDirection({ positions: [latlng, null] })
      let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
      history.push({
        pathname: RouterConfig.direction,
        search: search
      })
    }
    onClick && onClick()
  }

  const handleDirectionToHere = () => {
    if (pathname == RouterConfig.direction) {
      AppEventTool.triggerEvent(AppEventEnum.directToHere, latlng)
    }
    else {
      let data = DirectionTool.encodeDirection({ positions: [null, latlng] })
      let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
      history.push({
        pathname: RouterConfig.direction,
        search: search
      })
    }
    onClick && onClick()
  }

  const addPointTo = () => {
    if (pathname == RouterConfig.direction) {
      AppEventTool.triggerEvent(AppEventEnum.addDestination, latlng)
    }
    onClick && onClick()
  }

  const onClickAddObject3d = (e) => {
    history.push({
      pathname: RouterConfig.object3d.add.replace(":lat", latlng.lat).replace(":lng", latlng.lng).replace("/:placeId?/:placeName?", "")
    })
  }

  const handleAddNewPlace = () => {
    // history.push({
    //   pathname: RouterConfig.place.add.replace(":lat", latlng.lat).replace(":lng", latlng.lng).replace("/:placeId?/:placeName?", "")
    // })

    history.push({
      pathname: location.pathname,
      search: UrlTool.createSearch({ cp: 1, lat: latlng.lat, lng: latlng.lng }, { extendOldQuery: true })
    })
    onClick && onClick()
  }

  const handleAddMultiObject3d = () => {
    history.push({
      pathname: RouterConfig.object3d.addMulti
    })
  }

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand('copy')
    AppTool.alert("Copied", AlertTypeEnum.info)
    onClick && onClick()
  }

  return (
    <Box>

      <ListItem button onClick={handleCopy}>
        <input readOnly style={{ cursor: "pointer", border: "none", outline: "none", backgroundColor: "transparent" }} ref={inputRef} value={`${latlng?.lat?.toFixed(6)}, ${latlng?.lng?.toFixed(6)}`} />
        {/* <Typography  ref={inputRef} variant="body2">{latlng?.lat?.toFixed(6)}, {latlng?.lng?.toFixed(6)}</Typography> */}
      </ListItem>
      {
        canDirectFromHere &&
        < ListItem button onClick={handleDirectionFromHere}>
          <Typography variant="body2">{Resource.common.directFromHere}</Typography>
        </ListItem>
      }
      {
        canDirectToHere &&
        < ListItem button onClick={handleDirectionToHere}>
          <Typography variant="body2">{Resource.common.directToHere}</Typography>
        </ListItem >
      }

      {
        canAddDestination &&
        <ListItem button onClick={addPointTo}>
          <Typography variant="body2">{Resource.common.addPointTo}</Typography>
        </ListItem>
      }

      {
        //...Thêm địa điểm
      }
      {
        !hiddenAdd && 
        <>
          {
        UserTool.getUser() &&
        <ListItem button onClick={onClickAddObject3d}>
          <Typography variant="body2">{Resource.object3d.add}</Typography>
        </ListItem>
      }

      {
        UserTool.getUser() &&
        <ListItem button onClick={handleAddNewPlace}>
          <Typography variant="body2">{Resource.place.addPlace}</Typography>
        </ListItem>
      }

      {
        UserTool.isPermission(PermissionEnum.objectCreateMulti) &&
        <ListItem button onClick={handleAddMultiObject3d}>
          <Typography variant="body2">{Resource.object3d.addMulti}</Typography>
        </ListItem>
      }
        </>
      }

    </Box >
  )
}

RightChildMenu.propTypes = {
  //
};

export default RightChildMenu;
