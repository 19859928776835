import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ShowSettingState, UserState } from "../../appState";
import {
  LinkIconConfig,
  SvgIconConfig,
  UrlConfig
} from "../../config";
import { CodeEnum, PlaceTypeEnum } from "../../enum";
import { Resource } from "../../resource";
import { ApiTool, HistoryTool } from "../../tool";
import SearchBarV3 from "../searchBarV3/searchBarV3";
import TooltipV2 from "../tooltipV2/tooltipV2";
import "./searchBarSuggestAlongDirection.scss";

const COUNT = 8;

const SearchBarSuggestAlongDirection = (props) => {
  const { value, onChange, onClose, onBack } = props;
  const [stateValue, setStateValue] = useState(null);
  const [optionPoiState, setOptionPoiState] = useState([]);
  const [optionHistoryState, setOptionHistoryState] = useState([]);
  const listPoiRef = useRef();
  const listHistoryRef = useRef();
  const setShowSettingState = useSetRecoilState(ShowSettingState);

  const user = useRecoilValue(UserState)

  const OPTION_TYPE_DEFAULT = [
    {
      id: "OPTION_TYPE_DEFAULT_1",
      isDefaultType: true,
      name: Resource.common.restaurantAlongRoute,
      icon: SvgIconConfig.search.restaurant,
      text: Resource.common.restaurant,
      type: PlaceTypeEnum.restaurant
    },
    {
      id: "OPTION_TYPE_DEFAULT_2",
      isDefaultType: true,
      name: Resource.common.hotelAlongRoute,
      icon: SvgIconConfig.search.hotel,
      text: Resource.common.hotel,
      type: PlaceTypeEnum.hotel
    },
    {
      id: "OPTION_TYPE_DEFAULT_3",
      isDefaultType: true,
      name: Resource.common.tourismAlongRoute,
      icon: SvgIconConfig.search.tourism,
      text: Resource.common.tourism,
      type: PlaceTypeEnum.touristAttract
    },
  ];

  useEffect(() => {
    setStateValue(value);
    searchAutoSuggest(value);
    return () => {
      listPoiRef.current?.cancel();
    };
  }, [value]);

  useEffect(() => {
    getHistories();
    return () => {
      listHistoryRef.current?.cancel();
    };
  }, []);

  const searchAutoSuggest = (keyword) => {
    let body = {
      text: keyword,
    };
    listPoiRef.current = ApiTool.queryGetFromJson(
      UrlConfig.poi.autosuggest,
      body,
      (res) => {
        if (res?.code == CodeEnum.ok) {
          let data = res?.result?.splice(0, 5);
          let dataCus = data?.map((poi) => {
            return {
              ...poi,
              icon: SvgIconConfig.search.poi,
            };
          });
          setOptionPoiState(dataCus);
        }
      }
    );
  };

  const getHistories = () => {
    if (user?.id) {
      let body = {
        count: COUNT,
        isPlace: true,
      };
      listHistoryRef.current = ApiTool.queryGetFromJson(
        UrlConfig.searchHistory.get,
        body,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            let data = res?.result?.splice(0, 5)
            let dataCustom = data?.map((poiHis) => {
              return {
                ...poiHis?.place,
                id: poiHis?.id,
                icon: SvgIconConfig.search.history,
              };
            });
            let dataOption = OPTION_TYPE_DEFAULT.concat(dataCustom);
            setOptionHistoryState(dataOption);
          }
        },
        true
      );
    }
    else {
      setOptionHistoryState(OPTION_TYPE_DEFAULT)
    }
  };

  const handleClickBtnSearch = (textSearch) => (e) => {
    if (textSearch) {
      HistoryTool.addSearchToHistory(textSearch);
      onChange && onChange(textSearch);
    }
  };

  const handleCloseSearch = () => {
    onClose && onClose();
  };

  const onChangeInput = (value) => {
    searchAutoSuggest(value);
    setStateValue(value);
  };

  const onChangeOption = (option) => {
    setStateValue(option?.text || option?.name || option?.address);
    onChange && onChange(option);
  };

  const onEnterPress = (value) => {
    onChange && onChange(value);
  };

  const handleBack = () => {
    onBack && onBack()
  }

  return (
    <div className="searchBarSuggestCpn">
      <SearchBarV3
        clearable
        isServer
        isFreeSolo
        value={stateValue}
        onEnter={onEnterPress}
        onChange={onChangeOption}
        onInputChange={onChangeInput}
        options={!stateValue ? optionHistoryState : optionPoiState}
        maxLengthOfScroll={COUNT}
        prefix={
          <TooltipV2 title={Resource.common.back} placement="bottom">
            <div className="iconButton" onClick={handleBack}>
              <img src={LinkIconConfig.common.back} width={24} height={24} />
            </div>
          </TooltipV2>
        }
        iconInside={
          <TooltipV2 title={Resource.common.search} placement="bottom">
            <img
              src={LinkIconConfig.common.searchGray}
              alt="icon-search"
              onClick={handleClickBtnSearch(stateValue)}
            />
          </TooltipV2>
        }
        suffix={
          <TooltipV2 title={Resource.common.close} placement="bottom">
            <img
              src={LinkIconConfig.searchBar.close}
              width={24}
              height={24}
              alt="icon-close"
              onClick={handleCloseSearch}
            />
          </TooltipV2>
        }
      />
    </div>
  );
};

SearchBarSuggestAlongDirection.propTypes = {
  //
  value: PropTypes.any,
  onChange: PropTypes.any,
  onClose: PropTypes.any,
};

export default SearchBarSuggestAlongDirection;
