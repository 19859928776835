import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { UrlConfig } from '../../../config/urlConfig';
import { AlertTypeEnum, CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AppTool } from '../../../tool';
import { ApiTool } from '../../../tool/apiTool';
import Field from '../field/field';
import PhotosInput from '../photosInput/photosInput';

const useStyles = makeStyles({
  dialog: {
    width: '600px'
  }
})

function ReportError({ onClose }) {

  const schema = yup.object().shape({
    title: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.common.name
    })),
    url: yup.string().url(Resource.formatString(Resource.validate.typeError, {
      field: Resource.common.path,
      type: Resource.common.link.toLocaleLowerCase()
    })),
    note: yup.string(),
    imageFiles: yup.mixed().nullable().test("size", Resource.formatString(Resource.validate.lessThan,
      {
        field: Resource.common.images,
        max: '10MB'
      }), function (value) {
        if (value?.length > 0) {
          let check = true
          value?.forEach(element => {
            let size = element?.size / (1024 * 1024)
            if (size > 10) {
              check = false
            }
          });
          if (!check) {
            return false
          }
        }
        return true;
      })
  });

  const form = useForm({
    resolver: yupResolver(schema)
  })
  const { register, handleSubmit, errors, formState } = form
  const setFormValue = form.setValue
  register({ name: 'imageFiles', type: 'custom' })

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const onSubmit = (data) => {
    ApiTool.postFormFromJson(UrlConfig.reportError.add, data, (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.send, {
            object: Resource.common.reportError.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        onClose && onClose()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.send, {
            object: Resource.common.reportError.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const handleChangePhoto = (photos, photosFile) => {
    setFormValue('imageFiles', photosFile, { shouldValidate: formState.isSubmitted });
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (

    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', overflow: 'auto' }}>
        <DialogTitle id="responsive-dialog-title">{Resource.common.reportError}</DialogTitle>
        <DialogContent dividers>
          <Field
            required
            label={Resource.common.title}
          >
            <TextField
              error={errors.title ? true : false}
              id="title"
              name='title'
              placeholder={Resource.title.add}
              variant="outlined"
              inputRef={register}
              helperText={errors.title?.message}
            />
          </Field>

          <Field
            label={Resource.common.path}
          >
            <TextField
              id="url"
              name='url'
              variant="outlined"
              inputRef={register}
              defaultValue={location.href}
              error={errors.url ? true : false}
              helperText={errors.url?.message}
            />
          </Field>

          <Field
            label={Resource.common.images}
            error={errors.imageFiles ? true : false}
            helperText={errors.imageFiles?.message}
          >
            <PhotosInput onChange={handleChangePhoto} />
          </Field>

          <Field
            label={Resource.common.note}
          >
            <TextField
              multiline
              rows={5}
              id="note"
              name='note'
              variant="outlined"
              inputRef={register}
            />
          </Field>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {Resource.common.cancel}
          </Button>
          <Button type="submit" color="primary" autoFocus>
            {Resource.common.apply}
          </Button>
        </DialogActions>
      </form>
    </Dialog >

  )
}

ReportError.propTypes = {
  //
};

export default ReportError;
