import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Collapse, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from "yup";
import { CommonConfig, ImageConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum, MapEventEnum, MapTypeEnum, ModelTypeEnum, RequestTypeEnum, TypeAddressEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, DateTimeTool, SidebarTool } from '../../tool';
import CategoryInput from '../common/categoryInput/categoryInput';
import ElevationInput from '../common/elevationInput/elevationInput';
import Field from '../common/field/field';
import FileInput from '../common/fileInput/fileInput';
import HeightInput from '../common/heightInput/heightInput';
import MetaDataInput from '../common/metaDataInput/metaDataInput';
import ModelInput from '../common/modelInput/modelInput';
import ScaleInput from '../common/scaleInput/scaleInput';
import SidebarHeader from '../common/sidebarHeader/sidebarHeader';
import { LocationCanBackState } from './../../appState/locationState';
import { MapTypeAppState } from './../../appState/mapTypeAppState';
import { LocationTool } from './../../tool/locationTool';
import AddressInput from './../common/addressInput/addressInput';
import BearingInput from './../common/bearingInput/bearingInput';
import Confirm from './../common/confirm/confirm';
import PolygonInput from './../common/polygonInput/polygonInput';
import { useForm } from '../../useHooks';

const marginField = 24
const marginText = 10

const useStyles = makeStyles({
  header: {
    boxShadow: '0px 0px 2px #00000029'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: '4px',
    transform: 'translateY(50%)',
  },
  marginBottomField: {
    marginBottom: `${marginField}px`
  },
  marginBottomText: {
    marginBottom: `${marginText}px`
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: 'inherit',
    fontWeight: 500
  }
})


function AddBuilding() {

  const { lat, lng } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)
  const locationCanBackState = useRecoilValue(LocationCanBackState)

  const buildingRef = useRef(null)
  const markerRef = useRef(null)
  const modelTypeRef = useRef(ModelTypeEnum.available)

  const [scale, setScale] = useState(1)
  const [height, setHeight] = useState(1)
  const [bearing, setBearing] = useState(0)
  const [elevation, setElevation] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [minZoom, setMinZoom] = useState(17)
  const [maxZoom, setMaxZoom] = useState(19)
  const [name, setName] = useState('')

  const [showExtend, setShowExtend] = useState(false)
  const [position, setPosition] = useState({ lat: "", lng: "" })
  const [coordinates, setCoordinates] = useState([])
  const [addressComponents, setAddressComponents] = useState([])
  const [modelType, setModelType] = useState(ModelTypeEnum.available)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const modelTypeLabel = {
    [ModelTypeEnum.available]: Resource.model.available,
    [ModelTypeEnum.upload]: Resource.common.upload,
    [ModelTypeEnum.draw]: Resource.common.draw
  }

  const schema = yup.object().shape({
    model: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.model
      }), function (model) {
        if (modelType == ModelTypeEnum.available) {
          if (!model || model == {}) {
            return false
          }
        }
        return true;
      }),

    coordinates: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.bottomSurface
      }), function (coordinates) {
        if (modelType == ModelTypeEnum.draw) {
          if (!coordinates || coordinates == {}) {
            return false
          }
          if (coordinates?.length < 3) {
            return false
          }
        }
        return true;
      }),

    modelFile: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.modelFile
      }), function (modelFile) {
        if (modelType == ModelTypeEnum.upload) {
          if (!modelFile) {
            return false
          }
        }
        return true;
      }),

    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.name })),

    types: yup.array().nullable(),

    lat: yup
      .number()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.lat }))
      .min(-90, Resource.formatString(Resource.validate.min, { field: Resource.common.lat, min: -90 }))
      .max(90, Resource.formatString(Resource.validate.max, { field: Resource.common.lat, max: 90 }))
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(Resource.formatString(Resource.validate.typeError, { field: Resource.common.lat, type: Resource.common.number.toLowerCase() })),

    lng: yup
      .number()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.lng }))
      .min(-180, Resource.formatString(Resource.validate.min, { field: Resource.common.lng, min: -180 }))
      .max(180, Resource.formatString(Resource.validate.max, { field: Resource.common.lng, max: 180 }))
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(Resource.formatString(Resource.validate.typeError, { field: Resource.common.lng, type: Resource.common.number.toLowerCase() })),

    addressComponents: yup.mixed()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.place.province
      }), function (addressComponents) {
        let address = addressComponents?.find(t => {
          return t.types?.includes(TypeAddressEnum.province)
        })
        if (!address) {
          return false
        }
        return true;
      }),
  });

  const { register, handleSubmit, errors, setValue, formState, isRequiredError } = useForm({
    resolver: schema
  });

  useEffect(() => {
    setValue("lat", position?.lat, { shouldValidate: formState.isSubmitted })
    setValue("lng", position?.lng, { shouldValidate: formState.isSubmitted })
  }, [position])

  useEffect(() => {
    const eventDragBuilding = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      setPosition(args.building.getPosition())
      if (modelTypeRef.current == ModelTypeEnum.draw) {
        setCoordinates(args.building.getCoordinates())
      }
    }, { building: true })

    const eventDragMarker = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      setPosition(args.marker.getPosition())
    }, { marker: true })

    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      if (modelTypeRef.current != ModelTypeEnum.draw) {
        setPosition(args.location)
      }
    })

    return () => {
      eventDragMarker?.remove()
      eventDragBuilding?.remove()
      eventClick?.remove()
    }
  }, [])

  useEffect(() => {
    modelTypeRef.current = modelType
    buildingRef.current?.setModel("")
    buildingRef.current?.setTexture("")
    buildingRef.current?.setCoordinates(null)
    buildingRef.current?.setMap(null)

    setValue("model", null, { shouldValidate: formState.isSubmitted })
    setValue("textureFile", null, { shouldValidate: formState.isSubmitted })
    setValue("modelFile", null, { shouldValidate: formState.isSubmitted })
    setValue("coordinates", null, { shouldValidate: formState.isSubmitted })
    setCoordinates(null)

    if (modelType == ModelTypeEnum.draw) {
      buildingRef.current?.setScale(1)
      buildingRef.current?.setBearing(0)
      setScale(1)
      setBearing(0)
      markerRef.current?.setMap(null)
    }
    else {
      markerRef.current?.setMap(AppData.map)
    }
  }, [modelType])

  useEffect(() => {
    setMapTypeAppState(MapTypeEnum.map3d)
    let newPosition = { lat, lng }
    if (LocationTool.isValid(newPosition)) {
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera()
        camera.setTarget(newPosition)
        AppData.map.moveCamera(camera, { animate: true })
      }
      setPosition(newPosition)
    }
  }, [lat, lng])

  useEffect(() => {
    if (LocationTool.isValid(position)) {
      if (!markerRef.current) {
        markerRef.current = new map4d.Marker({
          position: position,
          anchor: [0.5, 1],
          draggable: true,
          icon: new map4d.Icon(30, 42, ImageConfig.marker),
          zIndex: 1000
        })
        markerRef.current.setMap(AppData.map)
      }
      buildingRef.current?.setPosition(position)
      markerRef.current?.setPosition(position)
      let body = {
        lat: position.lat,
        lng: position.lng
      }
      ApiTool.queryGetFromJson(UrlConfig.geocode.getAddressComponents, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setAddressComponents(res?.result || [])
        }
      })
    }
  }, [position])

  useEffect(() => {
    buildingRef.current = new map4d.Building({
      position: { lat: 0, lng: 0 },
      draggable: true,
      zIndex: 1000
    })
    SidebarTool.setOpen(true)
    return () => {
      buildingRef.current?.setMap(null)
      markerRef.current?.setMap(null)
    }
  }, [])

  useEffect(() => {
    setValue("addressComponents", addressComponents, { shouldValidate: formState.isSubmitted })
  }, [addressComponents])

  const onChangeStartDate = (date) => {
    setStartDate(DateTimeTool.getDateWithStartTime(date))
  }

  const onChangeEndDate = (date) => {
    setEndDate(DateTimeTool.getDateWithEndTime(date))
  }

  const onChangeElevation = (value) => {
    let newValue = parseFloat(value)
    if (!isNaN(newValue) && newValue >= 0) {
      buildingRef.current?.setElevation(newValue)
    }
    setElevation(newValue)
  }

  const onChangeBearing = (value) => {
    let newValue = parseFloat(value)
    if (!isNaN(newValue)) {
      buildingRef.current?.setBearing(newValue)
    }
    setBearing(value)
  }

  const onChangeScale = (value) => {
    let newValue = parseFloat(value)
    if (!isNaN(newValue) && newValue > 0) {
      buildingRef.current?.setScale(newValue)
    }
    setScale(value)
  }

  const onChangeModel = (model) => {
    if (model) {
      markerRef.current?.setMap(null)
      buildingRef.current?.setModel(model.objUrl)
      buildingRef.current?.setTexture(model.textureUrl)
      buildingRef.current?.setMap(AppData.map)
      buildingRef.current?.setSelected(true)
    }
    else {
      markerRef.current?.setMap(AppData.map)
      buildingRef.current?.setMap(null)
    }
    setValue("model", model, { shouldValidate: formState.isSubmitted })
  }

  const onChangeMinZoom = (e) => {
    let zoom = e.target.value
    setMinZoom(zoom)
  }

  const onChangeMaxZoom = (e) => {
    let zoom = e.target.value
    setMaxZoom(zoom)
  }

  const onChangeModelType = (e) => {
    setModelType(e.target.value)
  }

  const onChangeLng = (e) => {
    let value = e.target.value
    setPosition(prev => {
      let newPosition =
      {
        ...prev,
        lng: value
      }
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera()
        camera.setTarget(newPosition)
        AppData.map.moveCamera(camera, { animate: true })
      }
      return newPosition
    })
  }

  const onChangeLat = (e) => {
    let value = e.target.value
    setPosition(prev => {
      let newPosition =
      {
        ...prev,
        lat: value
      }
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera()
        camera.setTarget(newPosition)
        AppData.map.moveCamera(camera, { animate: true })
      }
      return newPosition
    })
  }

  const getTileCover = (callback) => {
    let zoomRank = Array.from({ length: maxZoom - minZoom + 1 }, (_, i) => i + minZoom)
    buildingRef.current?.getTileCovers(zoomRank, (tiles) => {
      callback(tiles)
    })
  }

  const onChangePolygon = (coord) => {
    if (coord) {
      buildingRef.current?.setCoordinates(coord)
      buildingRef.current?.setMap(AppData.map)
      setPosition(buildingRef.current?.getPosition())
    }
    else {
      buildingRef.current?.setMap(null)
    }
    setCoordinates(coord)
    setValue("coordinates", coord, { shouldValidate: formState.isSubmitted })
  }

  const onChangeHeight = (value) => {
    let newValue = parseFloat(value)
    if (!isNaN(newValue) && newValue > 0) {
      buildingRef.current?.setHeight(newValue)
    }
    setHeight(value)
  }

  const onChangeCategory = (categories) => {
    let types = []
    types = categories?.map((item) => {
      return item?.id
    })
    setValue("types", types)
  }

  const onChangeName = (e) => {
    let value = e.target.value
    setName(value)
    setValue("name", value, { shouldValidate: formState.isSubmitted })
  }

  const onChangeAddress = (address) => {
    setAddressComponents(address)
  }

  const onChangeModelFile = (modelFile) => {
    if (modelFile) {
      markerRef.current?.setMap(null)
      buildingRef.current?.setModel(URL.createObjectURL(modelFile))
      buildingRef.current?.setMap(AppData.map)
      buildingRef.current?.setSelected(true)
    }
    else {
      markerRef.current?.setMap(AppData.map)
      buildingRef.current?.setMap(null)
    }
    setValue("modelFile", modelFile, { shouldValidate: formState.isSubmitted })
  }

  const onChangeTextureFile = (textureFile) => {
    if (textureFile) {
      buildingRef.current?.setTexture(URL.createObjectURL(textureFile))
    }
    else {
      buildingRef.current?.setTexture("")
    }
    setValue("textureFile", textureFile, { shouldValidate: formState.isSubmitted })
  }

  const onChangeModeDraw = (isDrawing) => {
    if (isDrawing) {
      buildingRef.current?.setMap(null)
    }
    else {
      buildingRef.current?.setMap(AppData.map)
    }
  }

  const onChangeMetaData = (metadata) => {
    setValue('metadata', metadata, { shouldValidate: formState.isSubmitted })
  }

  const handleShowExtend = () => {
    setShowExtend(!showExtend)
  }

  const cancel = () => {
    history.push({
      pathname: RouterConfig.home
    })
  }

  const addBuilding = (data) => {
    ApiTool.post(UrlConfig.objectEdit.create, data, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(Resource.formatString(Resource.alert.success.request, {
          action: Resource.common.create.toLowerCase(),
          object: Resource.object.name.toLowerCase() + ' 3D '
        }), AlertTypeEnum.success)
        history.push({
          pathname: RouterConfig.home
        })
      }
      else {
        AppTool.alert(Resource.formatString(Resource.alert.error.request, {
          action: Resource.common.create.toLowerCase(),
          object: Resource.object.name.toLowerCase() + ' 3D '
        }), AlertTypeEnum.error)
      }
    }, true)
  }

  const onSubmit = (data) => {
    data.scale = scale
    data.bearing = bearing
    data.elevation = elevation
    data.startDate = startDate?.getTime()
    data.endDate = endDate?.getTime()
    data.minZoom = minZoom
    data.maxZoom = maxZoom
    data.source = CommonConfig.source
    data.requestTypes = [RequestTypeEnum.create]
    data.location = position
    data.camera = AppData.map.getCamera()
    setLoadingSubmit(true)
    getTileCover((tiles) => {
      data.tiles = tiles
      if (modelType == ModelTypeEnum.upload) {
        let modelFileBody = {
          file: data.modelFile
        }
        ApiTool.postFormFromJson(UrlConfig.file.uploadModelFile, modelFileBody, (res) => {
          if (res?.code == CodeEnum.ok) {
            data.model = {
              type: "Object",
              objName: res.result?.name,
              objUrl: res.result?.url,
            }
            if (data.textureFile) {
              let textureFileBody = {
                file: data.textureFile
              }
              ApiTool.postFormFromJson(UrlConfig.file.uploadTextureFile, textureFileBody, (res) => {
                if (res?.code == CodeEnum.ok) {
                  data.model.textureName = res.result?.name
                  data.model.textureUrl = res.result?.url
                  addBuilding(data)
                }
                else {
                  AppTool.alert(Resource.formatString(Resource.alert.error.upload, {
                    object: Resource.common.texture.toLowerCase()
                  }), AlertTypeEnum.error)
                }
              }, true)
            }
            else {
              addBuilding(data)
            }
          }
          else {
            AppTool.alert(Resource.formatString(Resource.alert.error.upload, {
              object: Resource.common.modelFile.toLowerCase()
            }), AlertTypeEnum.error)
          }
        }, true)
      }
      else if (modelType == ModelTypeEnum.draw) {
        data.model = {
          coordinates: data.coordinates,
          height: height,
          type: "Polygon"
        }
        addBuilding(data)
      }
      else {
        addBuilding(data)
      }
    })
  }
  // register({ name: 'name'})
  // register({ name: 'lat', type: 'custom' })
  // register({ name: 'lng', type: 'custom' })
  // register({ name: 'metadata', type: 'custom' })
  // register({ name: 'coordinates', type: 'custom' })
  // register({ name: 'types', type: 'custom' })
  // register({ name: 'modelFile', type: 'custom' })
  // register({ name: 'textureFile', type: 'custom' })
  // register({ name: 'model', type: 'custom' })
  // register({ name: 'addressComponents', type: 'custom' })
  // register({ name: 'places', type: 'custom' })

  return (
    <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Box
        width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
        height="100%"
        bgcolor='background.paper'
        display='flex'
        flexDirection='column'
      >
        <SidebarHeader title={Resource.object3d.add} />
        <Box overflow='auto' flexGrow={1} display="flex" flexDirection="column">
          <Box p={`${marginField}px`} flexGrow={1} overflow="auto">
            <Field
              disableMarginTop
              label={Resource.common.name}
              icon={<img src={LinkIconConfig.requestUpdatePlace.placeName} width={24} height={24} />}
              required
            >
              <TextField
                name="name"
                label={Resource.name.add}
                inputRef={register({ name: "name" })}
                placeholder={Resource.name.add}
                variant="outlined"
                value={name}
                onChange={onChangeName}
                helperText={errors?.name?.message}
                error={errors?.name ? true : false}
              />
            </Field>
            <Field
              label={Resource.common.category}
              icon={<img src={LinkIconConfig.common.category} width={24} height={24} />}
            >
              <CategoryInput onChange={onChangeCategory} />
            </Field>
            <Field
              label={Resource.common.lat}
              icon={<img src={LinkIconConfig.common.lat} width={24} height={24} />}
              required
            >
              <TextField
                value={position?.lat}
                onChange={onChangeLat}
                inputProps={{
                  step: CommonConfig.lng.step
                }}
                label={Resource.lat.add}
                placeholder={Resource.lat.add}
                variant="outlined"
                type="number"
                name="lat"
                error={errors?.lat ? true : false}
                helperText={errors?.lat && errors.lat.message}
                inputRef={register({ name: "lat" })}
              />
            </Field>
            <Field
              label={Resource.common.lng}
              icon={<img src={LinkIconConfig.common.lng} width={24} height={24} />}
              required
            >
              <TextField
                value={position?.lng}
                onChange={onChangeLng}
                inputProps={{
                  step: CommonConfig.lng.step
                }}
                label={Resource.lng.add}
                placeholder={Resource.lng.add}
                variant="outlined"
                type="number"
                name="lng"
                error={errors?.lng ? true : false}
                helperText={errors?.lng && errors.lng.message}
                inputRef={register({ name: "lng" })}
              />
            </Field>
            <Field
              label={Resource.model.type}
              icon={<img src={LinkIconConfig.common.category} width={24} height={24} />}
              required
            >
              <FormControl variant="outlined">
                <InputLabel id="modelType">{Resource.model.chooseType}</InputLabel>
                <Select
                  labelId="modelType"
                  label={Resource.model.chooseType}
                  value={modelType}
                  onChange={onChangeModelType}
                >
                  {
                    Object.values(ModelTypeEnum).map((type, index) => {
                      return (
                        <MenuItem key={type} value={type}>{modelTypeLabel[type]}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Field>
            {
              modelType == ModelTypeEnum.available &&
              <Field
                label={Resource.common.model}
                icon={<img src={LinkIconConfig.common.model} width={24} height={24} />}
                required
                error={errors?.model ? true : false}
                helperText={errors?.model?.message}
              >
                <ModelInput onChange={onChangeModel} />
              </Field>
            }

            {
              modelType == ModelTypeEnum.draw &&
              <div ref={register({ name: "coordinates" })}>
                <Field
                  label={Resource.common.bottomSurface}
                  icon={<img src={LinkIconConfig.common.bottomSurface} width={24} height={24} />}
                  required
                  helperText={errors.coordinates ? <span style={{ color: "red" }}>{errors.coordinates?.message}</span> : ""}
                >
                  <PolygonInput onChangeMode={onChangeModeDraw} value={coordinates} map={AppData.map} onChange={onChangePolygon} />
                </Field>
                <Field
                  label={Resource.common.height}
                  icon={<img src={LinkIconConfig.common.height} width={24} height={24} />}
                  required
                >
                  <HeightInput value={height} onChange={onChangeHeight} />
                </Field>
              </div>

            }
            {
              modelType == ModelTypeEnum.upload &&
              <div ref={register({ name: "modelFile" })}>
                <Field
                  label={Resource.common.modelFile}
                  icon={<img src={LinkIconConfig.common.modelFile} width={24} height={24} />}
                  required
                  helperText={errors.modelFile?.message}
                  error={errors.modelFile}
                >
                  <FileInput accept=".obj" onChange={onChangeModelFile} />
                </Field>
                <Field
                  label={Resource.common.texture}
                  icon={<img src={LinkIconConfig.common.textureFile} width={24} height={24} />}
                >
                  <FileInput onChange={onChangeTextureFile} accept="image/*" />
                </Field>
              </div>
            }
            {
              modelType != ModelTypeEnum.draw &&
              <>
                <Field
                  label={Resource.common.scale}
                  icon={<img src={LinkIconConfig.common.scale} width={24} height={24} />}
                  required
                >
                  <ScaleInput
                    value={scale}
                    onChange={onChangeScale}
                  />
                </Field>
                <Field
                  label={Resource.common.bearing}
                  icon={<img src={LinkIconConfig.common.bearing} width={24} height={24} />}
                  required
                >
                  <BearingInput
                    onChange={onChangeBearing}
                    value={bearing}
                  />
                </Field>
              </>
            }
            <Field
              label={Resource.common.elevation}
              icon={<img src={LinkIconConfig.common.elevation} width={24} height={24} />}
              required
            >
              <ElevationInput
                onChange={onChangeElevation}
                value={elevation}
              />
            </Field>

            <div ref={register({ name: "addressComponents" })}>
              <Field
                label={Resource.common.address}
                icon={<img src={LinkIconConfig.common.lat} width={24} height={24} />}
                required
              >
                <AddressInput
                  value={addressComponents}
                  onChange={onChangeAddress}
                  error={errors.addressComponents ? true : false}
                  helperText={errors.addressComponents?.message}
                />
              </Field>
            </div>

            <Field
              label={Resource.common.minZoom}
              icon={<img src={LinkIconConfig.common.minZoom} width={24} height={24} />}
              required
            >
              <FormControl variant="outlined">
                <InputLabel id="minZoom">{Resource.minZoom.choose}</InputLabel>
                <Select
                  labelId="minZoom"
                  label={Resource.minZoom.choose}
                  value={minZoom}
                  onChange={onChangeMinZoom}
                >
                  {
                    Array.from({ length: maxZoom - 17 + 1 }, (_, i) => i + 17).map((zoom, index) => (
                      <MenuItem key={zoom} value={zoom}>{zoom}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Field>
            <Field
              label={Resource.common.maxZoom}
              icon={<img src={LinkIconConfig.common.maxZoom} width={24} height={24} />}
              required
            >
              <FormControl variant="outlined">
                <InputLabel id="minZoom">{Resource.maxZoom.choose}</InputLabel>
                <Select
                  labelId="minZoom"
                  label={Resource.maxZoom.choose}
                  value={maxZoom}
                  onChange={onChangeMaxZoom}
                >
                  {
                    Array.from({ length: 19 - minZoom + 1 }, (_, i) => i + minZoom).map((zoom, index) => (
                      <MenuItem key={zoom} value={zoom}>{zoom}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Field>
            <Field
              label={Resource.common.startDate}
              icon={<img src={LinkIconConfig.common.startDate} width={24} height={24} />}
            >
              <DatePicker
                label={Resource.startDate.choose}
                inputVariant="outlined"
                InputLabelProps={
                  {
                    shrink: true
                  }
                }
                clearable
                value={startDate}
                maxDate={endDate || CommonConfig.maxDate}
                onChange={onChangeStartDate}
                format="dd/MM/yyyy"
              />
            </Field>
            <Field
              label={Resource.common.endDate}
              icon={<img src={LinkIconConfig.common.endDate} width={24} height={24} />}
            >
              <DatePicker
                label={Resource.endDate.choose}
                inputVariant="outlined"
                InputLabelProps={
                  {
                    shrink: true
                  }
                }
                clearable
                value={endDate}
                minDate={startDate || CommonConfig.minDate}
                onChange={onChangeEndDate}
                format="dd/MM/yyyy"
              />
            </Field>

            <Box>
              <Button className={classes.extendBtn} fullWidth variant='text' onClick={handleShowExtend} endIcon={showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {Resource.updatePlace.addExtendBtn}
              </Button>
              <Collapse in={showExtend} timeout={600} >
                <MetaDataInput onChange={onChangeMetaData} />
              </Collapse>
            </Box>

          </Box>
          <Box display='flex' justifyContent='space-evenly' p={"20px"} borderTop={1} borderColor="grey.300">
            <Confirm
              onConfirm={cancel}
              message={Resource.formatString(Resource.confirmMessage.cancel, { action: Resource.object3d.add?.toLowerCase() })}
            >
              <Button>{Resource.button.cancel}</Button>
            </Confirm>

            <Button
              type="submit"
              variant='contained'
              color='primary'
              disabled={loadingSubmit || isRequiredError}
            >
              {Resource.common.save}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )

}

AddBuilding.propTypes = {
  //
};

export default AddBuilding;
