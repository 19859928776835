import React from 'react';
import { Resource } from '../../../resource';
import './mobileViewMapButton.scss';
import PropTypes from 'prop-types';

const VIEW_MAP_ICON =
  <svg  width="18" height="18" viewBox="0 0 24 24">
    <g transform="translate(-221 -204)">
      <path d="M2,5,9,2l6,3,6.3-2.7a.5.5,0,0,1,.7.46V19l-7,3L9,19,2.7,21.7a.5.5,0,0,1-.7-.46ZM16,19.4l4-1.714V5.033L16,6.747Zm-2-.132V6.736l-4-2V17.264ZM8,17.253V4.6L4,6.319V18.967Z" transform="translate(221 204)" fill="#1c75bc" />
    </g>
  </svg>

function MobileViewMapButton(props) {
  const { onClick } = props

  const onClickBtn = () => {
    onClick && onClick()
  }

  return (
    <div className='mobileViewMapButtonCpn' onClick={onClickBtn}>
      <div className='icon'>
        {VIEW_MAP_ICON}
      </div>
      <div className='caption'>
        {Resource.button.viewMap}
      </div>
    </div>
  )
};

MobileViewMapButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobileViewMapButton;
