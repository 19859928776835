import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from 'ui';
import { LinkIconConfig, RouterConfig, UrlConfig } from '../../config';
import { AppData } from '../../data';
import { AppEventEnum, CodeEnum, MapEventEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppEventTool, DirectionTool, EncryptionTool, UrlTool } from '../../tool';
import ShareButton from '../common/shareButton/shareButton';
import SwipeableViewsImages from '../common/swipeableViewsImages/swipeableViewsImages';
import Skeleton from '../skeleton/skeleton';
import TextView from '../textView/textView';
import ViewBusinessHour from '../viewBusinessHour/viewBusinessHour';
import './poiInfo.scss';
import { useQuery } from '../../useHooks';


function PoiInfo() {
  const { type } = useParams()
  const { text } = useQuery()
  const history = useHistory()
  const location = useLocation()

  const [place, setPlace] = useState(null)
  const [loading, setLoading] = useState(false)
  const idRef = useRef(null)
  const [show, setShow] = useState(false)
  const [point, setPoint] = useState({
    left: -1,
    top: -1
  })

  const placeInfoRef = useRef()

  useEffect(() => {
    setShow(false)
  }, [location.pathname])

  useEffect(() => {
    var sourcePlace
    const eventHoverPlace = AppData.map.addListener(MapEventEnum.hover, (args) => {
      document.getElementsByTagName("body")[0].style.cursor = "pointer"
      let location = args?.poi?.location
      let projection = new map4d.Projection(AppData.map)
      let screenCoordinate = projection.fromLatLngToScreen(location, 0)
      let pointTemp = {
        left: screenCoordinate.x,
        top: screenCoordinate.y
      }
      if (pointTemp.left + 300 >= window.innerWidth) {
        pointTemp.left -= 300
      }
      if (pointTemp.top + 250 >= window.innerHeight) {
        pointTemp.top -= 250
      }
      setPoint(pointTemp)
      setShow(true)
      if (args?.poi?.id != idRef.current) {
        setLoading(true)
        sourcePlace = ApiTool.get(UrlConfig.poi.detail.replace("{id}", args?.poi?.id), (res) => {
          idRef.current = res?.result?.id
          setLoading(false);
          if (res?.code == CodeEnum.ok) {
            setPlace(res?.result)
          }
        })
      }
    }, { mappoi: true })
    return () => {
      eventHoverPlace?.remove()
      sourcePlace?.cancel()
    }
  }, [])

  useEffect(() => {
    const eventHoverOut = AppData.map.addListener(MapEventEnum.hover, (args) => {
      document.getElementsByTagName("body")[0].style.cursor = null
      setShow(false)
    }, {
      location: true,
      mapbuilding: true,
      marker: true,
      polygon: true,
      polyline: true,
      circle: true,
      poi: true,
      building: true,
      place: true
    })
    return () => {
      eventHoverOut?.remove()
    }
  }, [])

  useEffect((e) => {
    const eventMouseOut = AppData.map.addListener(MapEventEnum.mouseOut, (args) => {
      document.getElementsByTagName("body")[0].style.cursor = null
      let elements = document.querySelectorAll(":hover")
      if (!Object.values(elements)?.includes(placeInfoRef.current)) {
        setShow(false)
      }
    })
    return () => {
      eventMouseOut?.remove()
    }
  }, [])

  const handleShowDetail = () => {
    if (text) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, id: place?.id })
      })
    }
    else {
      history.push({
        pathname: RouterConfig.place.detail.replace(":id", place?.id || "custom"),
      });
    }
    setShow(false)
  }

  const handleDirection = (e) => {
    e.stopPropagation();
    let data = DirectionTool.encodeDirection({ positions: [null, place?.location] })
    AppEventTool.triggerEvent("addDirection", data)
    let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
    let pathName = RouterConfig.direction
    if (window.top == window.self) {
      history.push({
        pathname: pathName,
        search: search
      })
    }
    else {
      let url = window.location.origin + pathName + search
      window.top.open(url, "_blank")
    }

  }

  let urlShare = window.location.origin + RouterConfig.place.detail.replace(":id", place?.id)

  return (
    show && (
      !loading ?
        <div ref={placeInfoRef}
          style={{ left: point.left + "px", top: point.top + "px" }}
          className="poiInfoCpn"
        >
          <div className="poiInfoImage">
            <SwipeableViewsImages
              place={place}
              paddingImages={place?.photos?.length > 1 ? 40 : null}
              sizeButton={40}
            />
          </div>
          <div className="poiInfoContent" onClick={handleShowDetail}>
            <div className='ellipsisPoiInfo'><TextView noWrap title={place?.name} variant="body1" style={{ fontWeight: 500 }}>
              {place?.name}
            </TextView>
            </div>
            <div className='ellipsisPoiInfo'><TextView noWrap title={place?.mainTypeInfos?.name} variant="caption" color='#1D1D1D' style={{ paddingTop: "0.5rem" }}>
              {place?.mainTypeInfos?.name}
            </TextView></div>
            <div className='ellipsisPoiInfo'><TextView noWrap title={place?.address} variant="caption" color='#1D1D1D' style={{ paddingTop: "0.25rem" }}>
              {place?.address}
            </TextView></div>
            {place?.businessHours && <div><ViewBusinessHour businessHours={place?.businessHours} /></div>}
            <div className='poiInfoButton'>
              <Button variant='direction'
                prefixIcon={
                  <img src={LinkIconConfig.placeExtend.direction} alt='icon direction' width={24} height={24} />
                }
                onClick={handleDirection}
                title={Resource.common.direction}
              >

                <span style={{ whiteSpace: "nowrap" }}>{Resource.common.direction}</span>
              </Button>
              <div className='shareButton' title={Resource.common.share}>
                <ShareButton hiddenText url={urlShare} />
              </div>
            </div>
          </div>
        </div>
        :
        <div style={{ left: point.left + "px", top: point.top + "px" }} className="poiInfoCpn">
          <div className="poiInfoImage"><Skeleton height="140px" /></div>
          <div className="poiInfoContent">
            <Skeleton width='80%' height="10px" borderRadius='5px' />
            <Skeleton height="10px" borderRadius='5px' />
          </div>
        </div>
    )
  )
}

PoiInfo.propTypes = {
  //
};

export default PoiInfo;
