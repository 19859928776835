import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { SvgIconConfig } from '../../config';
import { Resource } from '../../resource';
import { UrlConfig } from './../../config/urlConfig';
import { CodeEnum } from './../../enum/codeEnum';
import { ApiTool } from './../../tool/apiTool';
import SearchBarV3 from './../searchBarV3/searchBarV3';
import { ShowSettingState } from './../../appState/settingState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TooltipV2 from '../tooltipV2/tooltipV2';
import { UserState } from '../../appState';
import { HistoryTool } from '../../tool';
import { PlaceTypeEnum } from '../../enum';

const ICON_MENU =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(24306 22598)">
      <g transform="translate(-0.25 22.929)">
        <path d="M18.75,1.855H.75A.859.859,0,0,1,0,.927.859.859,0,0,1,.75,0h18a.859.859,0,0,1,.75.927A.859.859,0,0,1,18.75,1.855Z" transform="translate(-24303.75 -22615.928)" fill="#505050" />
        <path d="M18.75,1.856H.75A.86.86,0,0,1,0,.928.86.86,0,0,1,.75,0h18a.86.86,0,0,1,.75.928A.86.86,0,0,1,18.75,1.856Z" transform="translate(-24303.75 -22609.928)" fill="#505050" />
        <path d="M18.75,1.85H.75A.858.858,0,0,1,0,.925.858.858,0,0,1,.75,0h18a.858.858,0,0,1,.75.925A.858.858,0,0,1,18.75,1.85Z" transform="translate(-24303.75 -22603.926)" fill="#505050" />
      </g>
    </g>
  </svg>

const LOCATION_WHITE =
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g transform="translate(-1931 -142)">
      <g transform="translate(-2143 -285)" >
        <path d="M8,14.286S3,7.764,3,5A5,5,0,1,1,13,5C13,7.764,8,14.286,8,14.286ZM8,6.429A1.429,1.429,0,1,0,6.571,5,1.429,1.429,0,0,0,8,6.429Z" transform="translate(4074 428)" fill="#fff" />
      </g>
    </g>
  </svg>

const CLOSE_WHITE =
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g transform="translate(-1931 -175)">
      <g transform="translate(-2143 -252)" >
        <path data-name="Path 12963" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(4070 423.143)" fill="#fff" />
      </g>
    </g>
  </svg>

const ICON_SEARCH =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <g transform="translate(2 1.809)">
      <g transform="translate(0 0.2)">
        <path d="M19.748,18.759,14.01,13.017a7.752,7.752,0,0,0,1.812-4.984A7.911,7.911,0,0,0,0,8.037a7.919,7.919,0,0,0,12.817,6.136l5.759,5.759a.829.829,0,1,0,1.172-1.172ZM1.681,8.037a6.226,6.226,0,0,1,12.452,0,6.226,6.226,0,0,1-12.452,0Z" transform="translate(0 -0.2)" fill="#505050" />
      </g>
    </g>
  </svg>

const COUNT = 5

function SearchBarNearByLocation(props) {
  const { value, onChange, onClose, endPointName } = props

  const OPTION_TYPE_DEFAULT = [
    {
      id: 'OPTION_TYPE_DEFAULT_1',
      isDefaultType: true,
      name: Resource.common.restaurantNearDestination,
      icon: SvgIconConfig.search.restaurant,
      text: Resource.common.restaurant,
      type: PlaceTypeEnum.restaurant
    },
    {
      id: 'OPTION_TYPE_DEFAULT_2',
      isDefaultType: true,
      name: Resource.common.hotelNearDestination,
      icon: SvgIconConfig.search.hotel,
      text: Resource.common.hotel,
      type: PlaceTypeEnum.hotel
    },
    {
      id: 'OPTION_TYPE_DEFAULT_3',
      isDefaultType: true,
      name: Resource.common.tourismNearDestination,
      icon: SvgIconConfig.search.tourism,
      text: Resource.common.tourism,
      type: PlaceTypeEnum.touristAttract
    },
  ]

  const listPoiRef = useRef()
  const listHistoryRef = useRef()

  const [valueState, setValueState] = useState(null)
  const [optionPoiState, setOptionPoiState] = useState([])
  const [optionHistoryState, setOptionHistoryState] = useState(OPTION_TYPE_DEFAULT)

  const setShowSettingState = useSetRecoilState(ShowSettingState)
  const user = useRecoilValue(UserState)

  useEffect(() => {
    getHistories()
    return () => {
      listHistoryRef.current?.cancel()
    }
  }, [])

  useEffect(() => {
    setValueState(value)
    searchAutoSuggest(value)
    return () => {
      listPoiRef.current?.cancel()
    }
  }, [value])

  const searchAutoSuggest = (keyword) => {
    let body = {
      text: keyword,
    }
    listPoiRef.current = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let data = res?.result?.splice(0, 5)
        let dataCus = data?.map(poi => {
          return {
            ...poi,
            icon: SvgIconConfig.search.poi
          }
        })
        setOptionPoiState(dataCus)
      }
    })
  }

  const getHistories = () => {
    if (user?.id) {
      let body = {
        count: COUNT,
        isPlace: true
      }
      listHistoryRef.current = ApiTool.queryGetFromJson(UrlConfig.searchHistory.get, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          let data = res?.result?.splice(0, 5)
          let dataCustom = data?.map(poiHis => {
            return {
              ...poiHis?.place,
              id: poiHis?.id,
              icon: SvgIconConfig.search.history
            }
          })
          let dataOption = OPTION_TYPE_DEFAULT.concat(dataCustom)
          setOptionHistoryState(dataOption)
        }
        else {

        }

      }, true)
    }
    else {
      setOptionHistoryState(OPTION_TYPE_DEFAULT)
    }
  }

  const onChangeInput = (value) => {
    searchAutoSuggest(value)
    setValueState(value)
  }

  const onChangeOption = (option) => {
    setValueState(option)
    onChange && onChange(option)
  }

  const onEnterPress = (value) => {
    onChange && onChange(value)
  }

  const onCloseList = () => {
    onClose && onClose()
  }

  const handleShowSetting = () => {
    setShowSettingState(true)
  }

  const handleSearching = (textSearch) => () => {
    if (textSearch) {
      HistoryTool.addSearchToHistory(textSearch);
      onChange && onChange(textSearch);
    }
  }

  return (
    <div className='searchBarNearByLocation'>
      <SearchBarV3
        clearable
        placeholder={`${Resource.searchBar.searchNearLocation} ${endPointName}` || ''}
        value={valueState}
        onEnter={onEnterPress}
        onChange={onChangeOption}
        onInputChange={onChangeInput}
        optionDefault={Resource.common.backToDirection}
        options={!valueState ? optionHistoryState : optionPoiState}
        isServer
        isFreeSolo
        maxLengthOfScroll={COUNT}
        prefix={
          <TooltipV2 description={Resource.common.menu} anchor='bottom'>
            <div onClick={handleShowSetting}>
              {ICON_MENU}
            </div>
          </TooltipV2>
        }
        iconInside={
          <TooltipV2 title={Resource.common.search} placement="bottom">
            {ICON_SEARCH}
          </TooltipV2>
        }
        actionInside={handleSearching(valueState)}
        onActionDefault={onCloseList}
        suffix={
          <div
            style={{
              display: 'flex',
              backgroundColor: 'var(--primary)',
              borderRadius: '0.875rem',
              alignItems: 'center',
              padding: '0.275rem 0.625rem',
              cursor: "default",
              gap: '0.5625rem',
            }}>
            {LOCATION_WHITE}
            <div style={{
              height: '1rem',
              borderLeft: '1px solid #ffffff'
            }}>
            </div>
            <TooltipV2 description={Resource.searchBar.closeNearLocation} anchor='right'>
              <span onClick={onCloseList} style={{
                cursor: 'pointer'
              }}>
                {CLOSE_WHITE}
              </span>
            </TooltipV2>
          </div>
        }
      />
    </div>
  )
};

SearchBarNearByLocation.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  endPointName: PropTypes.any
};

export default SearchBarNearByLocation;
