import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { CanAddDestinationState, CanDirectFromHereState, CanDirectToHereState, HomeAddressState, OfficeAddressState, UserState } from '../../appState';
import { RouterConfig, SvgIconConfig, UrlConfig } from '../../config';
import { AppData } from '../../data';
import { AlertTypeEnum, AppEventEnum, CodeEnum, CookieEnum, DirectionDepartEnum, DirectionOptionTypeEnum, MapEventEnum, PositionDirectionTypeEnum, RouteStatusEnum, VehicleEnum, WeightingEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppEventTool, AppTool, CookieTool, DirectionTool, LocationTool, MapTool, StringTool, UrlTool } from '../../tool';
import { useQuery } from '../../useHooks';
import Collapse from '../collapse/collapse';
import DatePickerV2 from '../datePickerV2/datePickerV2';
import DepartSelect from '../departSelect/departSelect';
import DirectionPositionsInput from '../directionPositionsInput/directionPositionsInput';
import DrawDirection from '../drawDirection/drawDirection';
import GetInfoForPositionDirection from '../getInfoForPositionDirection/getInfoForPositionDirection';
import HiddenAddClick from '../hiddenAddClick/hiddenAddClick';
import HideCircleMenu from '../hideCircleMenu/hideCircleMenu';
import HideLocationControl from '../hideLocationControl/hideLocationControl';
import HidePlaceControl from '../hidePlaceControl/hidePlaceControl';
import HidePoiControl from '../hidePoiControl/hidePoiControl';
import ListPoiNearByLocation from '../listPoiNearByLocation/listPoiNearByLocation';
import ListRoute from '../listRoute/listRoute';
import ListSearchRoute from '../listSearchRoute/listSearchRoute';
import MarkerDirection from '../markerDirection/markerDirection';
import NearByLocationOptions from '../nearByLocationOptions/nearByLocationOptions';
import RoadAvoid from '../roadAvoid/roadAvoid';
import RouteDetailV2 from '../routeDetailV2/routeDetailV2';
import RoutesInfor from '../routesInfor/routesInfor';
import Skeleton from '../skeleton/skeleton';
import SuggestAlongDirection from '../suggestAlongDirection/suggestAlongDirection';
import SuggestPlaceDirection from '../suggestPlaceDirection/suggestPlaceDirection';
import TimePickerV2 from '../timePickerV2/timePickerV2';
import TooltipV2 from '../tooltipV2/tooltipV2';
import UserHomeAddressInput from '../userHomeAddressInput/userHomeAddressInput';
import UserWorkAddressInput from '../userWorkAddressInput/userWorkAddressInput';
import VehicleSelect from '../vehicleSelect/vehicleSelect';
import WeightingOption from '../weightingOption/weightingOption';
import { ShowSettingState } from './../../appState/settingState';
import Back from './../common/back/back';
import RouteOptimizationOption from './../routeOptimizationOption/routeOptimizationOption';
import "./directionV2.scss";
import { useWindowSize } from './../../useHooks/useWindowSize';

const IconDict = {
  [DirectionOptionTypeEnum.poi]: SvgIconConfig.direction.poi,
  [DirectionOptionTypeEnum.home]: SvgIconConfig.direction.home,
  [DirectionOptionTypeEnum.work]: SvgIconConfig.direction.office,
  [DirectionOptionTypeEnum.history]: SvgIconConfig.direction.history,
  [DirectionOptionTypeEnum.yourPosition]: SvgIconConfig.direction.yourLocation
}

const createDefaultPositions = () => {
  return [
    {
      id: v4(),
      index: 0
    },
    {
      id: v4(),
      index: 1
    }
  ]
}

const resetIndexForPositions = (positions) => {
  positions?.forEach((pos, index) => {
    pos.index = index
  })
}

const getTextInput = (poi) => {
  let text = poi?.name && poi?.address ? `${poi?.name}, ${poi?.address}` : (poi?.name || poi?.address)
  if (!text) {
    text = poi?.position ? `${poi?.position.lat?.toFixed(6)}, ${poi?.position.lng?.toFixed(6)}` : ""
  }
  return text
}

const checkAlreadyOptimize = (waypointOrder) => {
  let check = false
  if (waypointOrder?.length == 0) {
    check = true
  }
  else {
    check = !waypointOrder?.some((order, idx) => order > waypointOrder[idx + 1]) //check arr is sorted
  }
  return check
}
const getPositionsFromEncodedData = (encodedData) => {
  let dataJson = DirectionTool.decodeDirection(encodedData)
  let positionsNew = dataJson.positions?.map((pos, index) => {
    let poiId = null
    let position = null
    let optionType = DirectionOptionTypeEnum.position
    if (pos) {
      if (LocationTool.isValid(pos)) {
        position = pos
      }
      else {
        poiId = pos
        optionType = DirectionOptionTypeEnum.poi
      }
    }
    let p = {
      index: index,
      id: v4(),
      position: position,
      poiId: poiId,
      optionType: optionType
    }
    p.textInput = getTextInput(p)
    return p
  })
  if (!(positionsNew?.length >= 2)) {
    positionsNew = createDefaultPositions()
  }
  return positionsNew
}

const checkTwoDateEqual = (dateValue, current) => {
  let currentDate = new Date(current)
  let dateState = new Date(dateValue)
  let check = false

  let cuDate = currentDate?.getDate()
  let cuMonth = currentDate?.getMonth()
  let cuYear = currentDate?.getFullYear()

  let date = dateState?.getDate()
  let month = dateState?.getMonth()
  let year = dateState?.getFullYear()
  if (cuDate == date && cuMonth == month && cuYear == year) {
    check = true
  }
  return check
}

const getPositionsString = (positions) => {
  let arr = [...positions]
  let locStrings = arr?.sort((a, b) => (a.index - b.index)).map(pos => {
    let text = pos?.poiId || (pos?.position ? `${pos?.position?.lat},${pos?.position?.lng}` : "null")
    return text
  }) || []
  return locStrings?.join(";")
}

const DirectionV2 = () => {

  const { data } = useQuery()
  const windowSize = useWindowSize()

  const user = useRecoilValue(UserState)
  const home = useRecoilValue(HomeAddressState)
  const work = useRecoilValue(OfficeAddressState)

  const setCanAddDestination = useSetRecoilState(CanAddDestinationState)
  const setCanDirectFromHere = useSetRecoilState(CanDirectFromHereState)
  const setCanDirectToHere = useSetRecoilState(CanDirectToHereState)
  const setShowSettingState = useSetRecoilState(ShowSettingState)

  const [vehicle, setVehicle] = useState(VehicleEnum.car)
  const [textInput, setTextInput] = useState("")
  const [searchPOIs, setSearchPOIs] = useState([])
  const [textState, setTextState] = useState("")
  const [typeState, setTypeState] = useState("")
  const [suggests, setSuggests] = useState([])
  const [positions, setPositions] = useState(() => {
    return getPositionsFromEncodedData(data)
  })
  const [showSuggest, setShowSuggest] = useState(false)
  const [positionsString, setPositionsString] = useState(() => {
    return getPositionsString(positions)
  })
  const [canDirection, setCanDirection] = useState(false)
  const [routes, setRoutes] = useState(null)
  const [showOptions, setShowOptions] = useState(false)
  const [indexState, setIndexState] = useState(undefined)
  const [history, setHistory] = useState([])
  const [depart, setDepart] = useState(DirectionDepartEnum.now)
  const [showHomeInput, setShowHomeInput] = useState(false)
  const [showWorkInput, setShowWorkInput] = useState(false)
  const [weighting, setWeighting] = useState(WeightingEnum.fastest)
  const [avoidRoads, setAvoidRoads] = useState([])
  const [showNearbyDestination, setShowNearbyDestination] = useState(false)
  const [showNearbyAlongDirection, setShowNearbyAlongDirection] = useState()
  const [departAt, setDepartAt] = useState(new Date())
  const [disableAddDestination, setDisableAddDestination] = useState(false)
  const [showDepartAt, setShowDepartAt] = useState(false)
  const [showDepart, setShowDepart] = useState(false)
  const [showDisableAddDestination, setShowDisableAddDestination] = useState(false)
  const [disableAddDestinationText, setDisableAddDestinationText] = useState("")
  const [showMoreAlongDirection, setShowMoreAlongDirection] = useState(false)
  const [routeIndexSelect, setRouteIndexSelect] = useState(0)
  const [bodyRoute, setBodyRouteState] = useState(null)
  const [isOptimize, setIsOptimize] = useState(false)
  const [routeStatus, setRouteStatus] = useState(RouteStatusEnum.none)
  const [durationVehicle, setDurationVehicle] = useState(null)
  const [showListContainer, setShowListContainer] = useState(false)
  const [isSearchEnter, setIsSearchEnter] = useState(false)
  const [errorSearchEnter, setErrorSearchEnter] = useState(false)

  const focusIndexRef = useRef()
  const isDraggingRef = useRef()
  const initRef = useRef(false)
  const showDetailRoute = useRef(false)
  const routesDirectionRenderRef = useRef()
  const errorSearchText = useRef()
  const _timeOutSearch = useRef()
  const _apiSearch = useRef()

  const addLoc = (loc) => {
    setPositions(prev => {
      let newPositions = [...prev || []]
      let pos = newPositions?.find(pos => !pos?.position)
      if (pos) {
        delete pos.poiId
        pos.position = loc
        pos.textInput = getTextInput(pos)
        pos.optionType = DirectionOptionTypeEnum.position
        return newPositions
      }
      else {
        return prev
      }
    })
  }

  const createMinTime = (date) => {
    let current = new Date()
    let hour = current?.getHours()
    let minute = current?.getMinutes()

    let minTime = null
    if (checkTwoDateEqual(date, current)) {
      minTime = {
        hour: hour,
        minute: minute
      }
    }
    return minTime
  }

  useEffect(() => {
    let dataJson = DirectionTool.decodeDirection(data)
    if (dataJson?.avoidRoads != undefined) {
      setAvoidRoads(dataJson?.avoidRoads || [])
    }
    if (dataJson.departAt != undefined) {
      setDepart(DirectionDepartEnum.at)
      setDepartAt(new Date(dataJson.departAt))
    }
    if (dataJson.showNearByDestination != undefined) {
      setShowNearbyDestination(dataJson.showNearByDestination)
    }
    if (dataJson.showNearByDirection != undefined) {
      setShowNearbyAlongDirection(dataJson.showNearByDirection)
    }
    if (dataJson.vehicle != undefined) {
      setVehicle(dataJson.vehicle)
    }
    if (dataJson.weighting != undefined) {
      setWeighting(dataJson.weighting)
    }
    if (dataJson.routeIndex != undefined) {
      setIndexState(dataJson.routeIndex)
      dataJson.routeIndex != undefined ? (showDetailRoute.current = true) : (showDetailRoute.current = false)
    }
    if (dataJson.keyword != "" || dataJson.keyword != undefined) {
      setTextState(dataJson.keyword)
    }
    if (dataJson.type != "" || dataJson.type != undefined) {
      setTypeState(dataJson.type)
    }
    if (dataJson.isOptimize != "" || dataJson.isOptimize != undefined) {
      setIsOptimize(dataJson.isOptimize)
    }
  }, [data])

  useEffect(() => {
    let sortPositions = Array.from(positions)

    let dataJson = {
      avoidRoads: avoidRoads,
      departAt: depart == DirectionDepartEnum.at ? departAt?.getTime() : undefined,
      keyword: textState,
      positions: sortPositions.sort((a, b) => (a.index - b.index)).map(p => p?.poiId || p?.position || null),
      routeIndex: indexState,
      showNearByDestination: showNearbyDestination,
      showNearByDirection: showNearbyAlongDirection,
      vehicle: vehicle,
      weighting: weighting,
      type: typeState,
      isOptimize: isOptimize

    }
    let dataString = DirectionTool.encodeDirection(dataJson)
    let search = UrlTool.createSearch({ data: dataString }, { extendOldQuery: true })
    window.history.replaceState(null, null, RouterConfig.direction + search);

  }, [positions, avoidRoads, departAt, showNearbyDestination, showNearbyAlongDirection, vehicle, weighting, indexState, textState, isOptimize])

  // đăng ký sự kiện được trigger
  useEffect(() => {
    const event1 = AppEventTool.addListener(AppEventEnum.directFromHere, (position) => {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        let pos = arr[0]
        if (!pos?.position) {
          pos.position = position
          pos.textInput = getTextInput(pos)
          pos.optionType = DirectionOptionTypeEnum.position
        }
        return arr
      })
    })
    const event2 = AppEventTool.addListener(AppEventEnum.directToHere, (position) => {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        let pos = arr[1]
        if (!pos?.position) {
          pos.position = position
          pos.textInput = getTextInput(pos)
          pos.optionType = DirectionOptionTypeEnum.position
        }
        return arr
      })
    })
    const event3 = AppEventTool.addListener(AppEventEnum.addDestination, (position) => {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        let pos = {
          position: position,
          optionType: DirectionOptionTypeEnum.position,
          id: v4()
        }
        pos.textInput = getTextInput(pos)
        arr.push(pos)
        return arr
      })
    })
    const event4 = AppEventTool.addListener(AppEventEnum.changeSearchInput, (value) => {
      let { text, type } = value
      setTextState(text)
      setTypeState(type)
    })

    const event5 = AppEventTool.addListener("addDirection", (position) => {
      if (position) {
        let pos = getPositionsFromEncodedData(position)
        setPositions(pos)
        setRoutes(null)
        setShowNearbyDestination(false)
        setShowNearbyAlongDirection(false)
        setShowMoreAlongDirection(false)
      }
    })
    return () => {
      event1?.remove()
      event2.remove()
      event3.remove()
      event4?.remove()
      event5?.remove()
      setCanAddDestination(false)
      setCanDirectFromHere(true)
      setCanDirectToHere(true)
    }
  }, [])

  // kiểm tra xem có thể chỉ đường từ đây hay chỉ đường đến đây không
  useEffect(() => {
    if (positions?.length == 2 && !positions[0]?.position) {
      setCanDirectFromHere(true)
    }
    else {
      setCanDirectFromHere(false)
    }

    if (positions?.length == 2 && !positions[1]?.position) {
      setCanDirectToHere(true)
    }
    else {
      setCanDirectToHere(false)
    }
  }, [positions])

  useEffect(() => {
    if (disableAddDestination || !canDirection || positions?.length >= 10) {
      setCanAddDestination(false)
    }
    else {
      setCanAddDestination(true)
    }

  }, [positions, disableAddDestination, canDirection])

  useEffect(() => {
    if (showSuggest) {
      if (textInput) {
        let suggestsNew = searchPOIs.map(POI => {
          return {
            text: POI?.name && POI?.address ? `${POI?.name}, ${POI?.address}` : (POI?.name || POI?.address),
            poiId: POI?.id,
            id: POI?.id,
            optionType: DirectionOptionTypeEnum.poi,
            name: POI?.name,
            address: POI?.address,
            position: POI?.location
          }
        })
        setSuggests(suggestsNew)
      }
      else {
        let suggests = [
          {
            id: v4(),
            optionType: DirectionOptionTypeEnum.yourPosition,
            text: Resource.common.yourLocation
          }
        ]
        if (user?.id) {
          suggests = suggests.concat([
            {
              id: v4(),
              optionType: DirectionOptionTypeEnum.home,
              text: Resource.common.homePrivate,
              caption: home?.address || "",
              position: home?.location,
              buttonName: home?.location ? Resource.common.edit : Resource.personalAddress.setLocation,
              onClick: (e) => {
                e.stopPropagation()
                setShowSuggest(false)
                setShowHomeInput(true)
              }
            },
            {
              id: v4(),
              optionType: DirectionOptionTypeEnum.work,
              text: Resource.common.office,
              caption: work?.address || "",
              position: work?.location,
              buttonName: work?.location ? Resource.common.edit : Resource.personalAddress.setLocation,
              onClick: (e) => {
                e.stopPropagation()
                setShowSuggest(false)
                setShowWorkInput(true)
              }
            }
          ])
          let listHistory = history?.map(his => {
            let h = {
              ...his?.place || {},
              id: v4(),
              poiId: his?.place?.placeId,
              position: his?.place?.location
            }
            h.optionType = DirectionOptionTypeEnum.history
            h.text = h?.name && h?.address ? `${h?.name}, ${h?.address}` : (h?.name || h?.address)
            return h
          }) || []
          suggests = suggests?.concat(listHistory)
        }

        let prev = positions?.[focusIndexRef.current - 1]
        let next = positions?.[focusIndexRef.current + 1]
        let kq = suggests.filter(s => {
          return (
            (s?.optionType != DirectionOptionTypeEnum.home && s?.optionType != DirectionOptionTypeEnum.work && s?.optionType != DirectionOptionTypeEnum.yourPosition) ||
            (s?.optionType != prev?.optionType && s.optionType != next?.optionType)
          )
        })

        setSuggests(kq)
      }
    }
    else {
      setSuggests([])
    }


  }, [searchPOIs, textInput, home, user?.id, history, work, positions, showSuggest])

  //đăng ký các sự kiện
  useEffect(() => {
    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      let loc = args?.building?.location || args?.poi?.location || args?.location
      addLoc(loc)
    }, {
      location: true,
      mappoi: true,
      mapbuilding: true,
    })

    const eventDrag = AppData.map.addListener(MapEventEnum.drag, (args) => {
      isDraggingRef.current = true
      let index = args?.marker?.getUserData()?.index
      let position = args?.marker?.getPosition()
      setPositions(prev => {
        let arr = Array.from(prev || [])
        arr[index].position = position
        arr[index].name = ""
        arr[index].address = ""
        arr[index].textInput = getTextInput(arr[index])
        arr[index].optionType = DirectionOptionTypeEnum.position
        delete arr[index].poiId
        return arr
      })
    }, {
      marker: true
    })

    const eventDragEnd = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      isDraggingRef.current = false
    }, { marker: true })
    return () => {
      eventClick?.remove()
      eventDrag?.remove()
      eventDragEnd?.remove()
    }
  }, [])

  //thay đổi string position
  useEffect(() => {
    setPositionsString(getPositionsString(positions))

    let canDirectionNew = positions?.length >= 2 && !positions?.some(pos => !(pos?.position || pos?.poiId))
    setCanDirection(canDirectionNew)
  }, [positions])

  useEffect(() => {
    if (initRef.current) {
      // setIndexState(0)
      setIsOptimize(false)
    }
    else {
      initRef.current = true
    }
    setPositions((prev) => {
      let arr = Array.from(prev || [])
      resetIndexForPositions(arr)
      return arr
    })
  }, [positionsString])

  useEffect(() => {
    let timeout
    let api
    if (canDirection) {
      setShowSuggest(false)
      let lang = CookieTool.get(CookieEnum.language) || "vi";
      let positionStrings = positionsString?.split(";")
      let isDuplicateAdjacentPosition = positionStrings?.some((p, index) => {
        return (index > 0 && p == positionStrings[index - 1])
      })
      if (isDuplicateAdjacentPosition) {
        setRouteStatus(RouteStatusEnum.errorPoi)
        setRoutes(null)
        setDurationVehicle(null)
      }
      else {
        let start = positionStrings[0]
        if (!start?.includes(",")) {
          start = `placeId:${start}`
        }
        let end = positionStrings?.[positionStrings?.length - 1]
        if (!end?.includes(",")) {
          end = `placeId:${end}`
        }
        positionStrings?.shift()
        positionStrings?.pop()
        let middle = ""
        if (positionStrings?.length > 0) {
          let middles = positionStrings?.map(s => {
            let m = s;
            if (!m?.includes(",")) {
              m = `placeId:${m}`
            }
            return m
          })
          middle = middles?.join(";")
        }
        let body = {
          origin: start,
          destination: end,
          points: middle,
          language: lang,
          mode: vehicle,
          isOptimize: isOptimize,
          weighting: weighting,
          avoidRoads: avoidRoads?.join(",") || "",
          departureTime: depart == DirectionDepartEnum.at ? parseInt(departAt.getTime() / 1000) : undefined
        };
        setBodyRouteState(body)
        setRouteStatus(RouteStatusEnum.loading)
        timeout = setTimeout(() => {
          api = ApiTool.queryGetFromJson(
            UrlConfig.direction.route,
            body,
            (res) => {
              if (res?.code == CodeEnum.ok) {
                setRouteStatus(RouteStatusEnum.ok)
                let result = res?.result
                if (body.isOptimize) {
                  setPositions(prev => {
                    let arr = Array.from(prev || [])
                    arr.sort((a, b) => (a.index - b.index))
                    let kq = Array.from(arr)
                    result?.waypointOrder?.forEach((order, index) => {
                      kq[index + 1] = arr[order + 1]
                    })
                    return kq
                  })
                  let isAlreadyOptimize = checkAlreadyOptimize(result?.waypointOrder)
                  if (isAlreadyOptimize) {
                    AppTool.alert(
                      Resource.direction.alreadyOptimize,
                      AlertTypeEnum.info
                    )
                  }
                }
                if (result?.routes?.length > 0) {
                  setRoutes(result?.routes)
                  setRouteIndexSelect(0)
                }
                else {
                  setRoutes(null)
                  setRouteStatus(RouteStatusEnum.errorVehicle)
                }
                setDurationVehicle(result?.durationByVehicles)
              } else {
                setRoutes(null)
                setRouteStatus(RouteStatusEnum.errorApi)
              }
            }
          )

        }, 300)
      }
    }
    else {
      setRouteStatus(RouteStatusEnum.none)
      setDurationVehicle(null)
    }
    return () => {
      timeout != undefined && clearTimeout(timeout)
      api?.cancel()
    }
  }, [canDirection, positionsString, weighting, avoidRoads, vehicle, depart, departAt, isOptimize])

  useEffect(() => {
    if (!textInput && user?.id) {
      let body = {
        count: 5,
        isPlace: true
      }
      ApiTool.queryGetFromJson(UrlConfig.searchHistory.get, body, (res) => {
        setHistory(res?.result || [])
      }, true)
    }
  }, [textInput, user?.id])

  useEffect(() => {
    if (canDirection && positions?.length == 2 && depart == DirectionDepartEnum.at) {
      setDisableAddDestination(true)
    }
    else {
      setDisableAddDestination(false)
    }

  }, [canDirection, depart, positions])

  useEffect(() => {
    if (
      !showOptions &&
      departAt &&
      depart == DirectionDepartEnum.at &&
      positions?.length == 2
    ) {
      setShowDepartAt(true)
    }
    else {
      setShowDepartAt(false)
    }

  }, [showOptions, positions, departAt, depart])

  useEffect(() => {
    if (positions?.length == 2 && !showOptions) {
      setShowDepart(true)
    }
    else {
      setShowDepart(false)
    }
  }, [positions, showOptions])

  useEffect(() => {
    if (!showOptions && (disableAddDestination || positions?.length >= 10)) {
      setShowDisableAddDestination(true)
    }
    else {
      setShowDisableAddDestination(false)
    }
  }, [disableAddDestination, positions, showOptions])

  useEffect(() => {
    if (positions?.length >= 10) {
      setDisableAddDestinationText(Resource.direction.maxDestinations)
    }
    else {
      setDisableAddDestinationText(Resource.direction.multipleDestination)
    }
  }, [positions?.length])

  useEffect(() => {
    setShowListContainer(showNearbyDestination || showNearbyAlongDirection)

  }, [showNearbyDestination, showNearbyAlongDirection])

  const fitBoundCamera = (routes) => {

    let bounds = new map4d.LatLngBounds();
    routes?.forEach(route => {
      route?.forEach(pos => {
        bounds.extend([pos.lng, pos.lat]);
      })
    })
    positions?.forEach(pos => {
      pos?.position && bounds.extend([pos?.position.lng, pos?.position.lat]);
    })

    let paddingOptions = {
      top: 20,
      bottom: 20,
      left: 440,
      right: 20,
    };
    let camera = AppData.map.getCameraWithBounds(bounds, paddingOptions);
    if (camera.getZoom() > 19) {
      camera.setZoom(19);
    }
    AppTool.moveCamera(camera, { animate: true });

  }

  const onDrawEnd = (routes) => {
    routesDirectionRenderRef.current = routes
    if (!isDraggingRef.current && !showNearbyAlongDirection) {
      fitBoundCamera(routes)
    }
  }

  const onFinishGeoCode = (index) => (poi) => {
    setPositions(prev => {
      let newPositions = [...prev || []]
      let pos = newPositions?.find(n => n.index == index) || {}
      pos.name = poi?.name
      pos.address = poi?.address
      pos.textInput = getTextInput(pos)
      if (pos?.optionType != DirectionOptionTypeEnum.position) {
        pos.position = poi?.location
        if (poi?.id == home?.placeId) {
          pos.text = Resource.common.homePrivate
          pos.optionType = DirectionOptionTypeEnum.home
          pos.textInput = Resource.common.homePrivate
          pos.name = Resource.common.homePrivate
        }
        else if (poi?.id == work?.placeId) {
          pos.text = Resource.common.office
          pos.optionType = DirectionOptionTypeEnum.work
          pos.textInput = Resource.common.office
          pos.name = Resource.common.office
        }
      }

      return newPositions
    })
  }

  const onFocusInput = (text, index) => {
    setTextInput(text)
    focusIndexRef.current = index
    setShowSuggest(text ? false : true)
    text != errorSearchText.current ? setErrorSearchEnter(false) : setErrorSearchEnter(true)
  }

  const getAutoSuggest = (text, index, isEnter = false) => {
    setTextInput(text || "")
    setPositions(prev => {
      let newArr = Array.from(prev || [])
      delete newArr[index]?.position
      delete newArr[index]?.optionType
      delete newArr[index]?.poiId
      return newArr
    })
    setShowSuggest(true)
    if (text) {
      let center = AppData.map.getCamera().getTarget();
      let location = `${center.lat},${center.lng}`
      let body = {
        text: text || "",
        location: location,
        isEnter: isEnter
      }
      _timeOutSearch.current&& clearTimeout(_timeOutSearch.current)
      _apiSearch.current&&_apiSearch.current?.abort?.()
      _timeOutSearch.current = setTimeout(()=>{
        _apiSearch.current = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let data = res?.result
            if (isEnter) {
              selectOptionMatchTextPercent(data, text)
            }
            else {
              setErrorSearchEnter(false)
            }
            setSearchPOIs(res?.result?.slice(0, 5) || [])
          }
        })
      }, 300)

    }
  }

  const selectOptionMatchTextPercent = (suggests, text) => {
    if (suggests?.length > 0) {
      if (suggests?.length == 1) {
        let firstOption = suggests[0]
        firstOption.position = firstOption?.location
        action[DirectionOptionTypeEnum.poi](firstOption)
      }
      else {
        setIsSearchEnter(true)
      }
    }
    else {
      setErrorSearchEnter(true)
      errorSearchText.current = text
    }
  }

  const onSearch = (text, index) => {
    if (!text) {
      setRoutes(null)
      errorSearchEnter && setErrorSearchEnter(false)
    }
    getAutoSuggest(text, index)
    setIsSearchEnter(false)
  }

  const onSearchEnter = (text, index, isEnter) => {
    let position = LocationTool.checkTextIsLocation(text)
    if (position) {
      setPositions(prev => {
        let newPositions = [...prev || []]
        let pos = newPositions[index]
        if (!pos?.position) {
          pos.position = position
          pos.textInput = getTextInput(pos)
          pos.optionType = DirectionOptionTypeEnum.position
          return newPositions
        }
        else {
          return prev
        }
      })
    }
    else {
      getAutoSuggest(text, index, isEnter)
    }
  }

  const onPermutePositions = (fromIndex, toIndex) => {
    setIsOptimize(false)
    setPositions((prev) => {
      let newList = Array.from(prev);
      let [removed] = newList.splice(fromIndex, 1);
      newList.splice(toIndex, 0, removed);
      resetIndexForPositions(newList)
      return newList
    });
  }

  const onSwap = () => {
    setPositions(prev => {
      let arr = Array.from(prev || [])
      arr.reverse()
      resetIndexForPositions(arr)
      return arr
    })
  }

  const onRemove = (index) => {
    setPositions(prev => {
      let arr = Array.from(prev || [])
      arr.splice(index, 1)
      return arr
    })
  }

  const onAdd = () => {
    setPositions(prev => {
      let arr = Array.from(prev || [])
      arr.push({
        id: v4()
      })
      return arr
    })
  }

  const onChangeVehicle = (newValue) => {
    setVehicle(newValue)
  }

  const onClickOptions = () => {
    setShowOptions(prev => !prev)
  }

  const onChangeDetail = (index) => {
    setIndexState(index)
    showDetailRoute.current = true
  }

  const action = {
    [DirectionOptionTypeEnum.poi]: (data) => {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        let pos = arr[focusIndexRef.current]
        if (!pos?.position) {
          pos = {
            ...data,
            id: pos?.id
          }
          pos.textInput = getTextInput(data)
          pos.optionType = DirectionOptionTypeEnum.poi
          arr[focusIndexRef.current] = pos
        }
        if (arr?.some(p => !p?.position)) {
          MapTool.moveToLocation(data?.position)
        }
        return arr
      })
      setShowSuggest(false)
    },
    [DirectionOptionTypeEnum.history]: (data) => {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        let pos = arr[focusIndexRef.current]
        if (!pos?.position) {
          pos = {
            ...data,
            id: pos?.id
          }
          pos.textInput = getTextInput(data)
          pos.optionType = DirectionOptionTypeEnum.history
          pos.poiId = data.poiId
          arr[focusIndexRef.current] = pos
        }
        if (arr?.some(p => !p?.position)) {
          MapTool.moveToLocation(data?.position)
        }
        return arr
      })
      setShowSuggest(false)
    },
    [DirectionOptionTypeEnum.yourPosition]: (data) => {
      AppTool.getMyLocation((res) => {
        if (res?.code == CodeEnum.ok) {
          let position = res?.result
          setPositions(prev => {
            let arr = Array.from(prev || [])
            if (!arr[focusIndexRef.current]?.position) {
              let pos = arr[focusIndexRef.current]
              delete pos.poiId
              pos.position = position
              pos.optionType = DirectionOptionTypeEnum.yourPosition
              pos.textInput = Resource.common.yourLocation
              pos.name = Resource.common.yourLocation
            }
            if (arr?.some(p => !p?.position)) {
              MapTool.moveToLocation(position)
            }
            return arr
          })
          setShowSuggest(false)
        }
        else {
          AppTool.alert(
            Resource.message.notUsedLocation,
            AlertTypeEnum.warning
          )
        }
      })
      setShowSuggest(false)
    },
    [DirectionOptionTypeEnum.home]: (data) => {
      if (home?.location) {
        setPositions(prev => {
          let arr = Array.from(prev || [])
          if (!arr[focusIndexRef.current]?.position) {
            let pos = arr[focusIndexRef.current]
            pos.optionType = DirectionOptionTypeEnum.home
            pos.position = home?.location
            pos.textInput = Resource.common.homePrivate
            pos.name = Resource.common.homePrivate
            pos.poiId = home?.placeId
          }
          if (arr?.some(p => !p?.position)) {
            MapTool.moveToLocation(home?.location)
          }
          return arr
        })
        setShowSuggest(false)
      }
      else {
        setShowHomeInput(true)
      }
    },
    [DirectionOptionTypeEnum.work]: (data) => {
      if (work?.location) {
        setPositions(prev => {
          let arr = Array.from(prev || [])
          if (!arr[focusIndexRef.current]?.position) {
            let pos = arr[focusIndexRef.current]
            pos.optionType = DirectionOptionTypeEnum.work
            pos.position = work?.location
            pos.textInput = Resource.common.office
            pos.name = Resource.common.office
            pos.poiId = work?.placeId
          }
          if (arr?.some(p => !p?.position)) {
            MapTool.moveToLocation(work?.location)
          }
          return arr
        })
        setShowSuggest(false)
      }
      else {
        setShowWorkInput(true)
      }
    }
  }

  const onClickBackInDetailRoute = () => {
    setIndexState(undefined)
    showDetailRoute.current = false
  }

  const onClickSuggest = (index) => (e) => {
    let suggest = suggests[index]
    action[suggest?.optionType] && action[suggest?.optionType](suggest)
  }

  const onBlur = () => {
    setShowSuggest(false)
  }

  const onChangeDepart = (option) => {
    setDepart(option.value)
    if (option.value == DirectionDepartEnum.at) {
      setDepartAt(new Date())
    }
    else {
      setDepartAt(undefined)
    }
  }

  const onCloseHomeInput = () => {
    setShowHomeInput(false)
  }

  const onCloseWorkInput = () => {
    setShowWorkInput(false)
  }

  const onChangeHomeInput = (poi) => {
    setShowSuggest(true)
    setShowHomeInput(false)
  }
  const onChangeWorkInput = (poi) => {

    setShowSuggest(true)
    setShowHomeInput(false)
  }

  const onChangeWeighting = (newValue) => {
    setWeighting(newValue)
  }

  const onChangeAvoidRoads = (valueNew) => {
    setAvoidRoads(valueNew || [])
  }

  const onClickNearByDestintion = (option) => {
    setShowNearbyDestination(true)
    setTypeState(option?.type)
    setTextState(option?.name)
  }

  const onChangeTime = (timeNew) => {
    setDepartAt(prev => {
      let newDepartAt = new Date(prev)
      newDepartAt.setHours(timeNew?.hour)
      newDepartAt.setMinutes(timeNew?.minute)
      return newDepartAt
    })
  }

  const onChangeDate = (dateNew) => {
    let departAtNew = new Date(dateNew)
    departAtNew.setHours
    setDepartAt((prev) => {
      if (prev) {
        let departAtNew = new Date(dateNew)
        departAtNew.setHours(prev?.getHours())
        departAtNew.setMinutes(prev?.getMinutes())
        return departAtNew
      }
      else {
        return prev
      }
    })
  }

  const onClickMoreSuggestAlong = () => {
    setShowMoreAlongDirection(true)
    setShowNearbyAlongDirection(false)
  }

  const onClickSuggestAlong = (value) => {
    setShowNearbyAlongDirection(true)
    setTextState(value?.name)
    setTypeState(value?.type)
    // fitBoundCamera(routesDirectionRenderRef.current)
  }

  const handleShowSetting = () => {
    setShowSettingState(true)
  }

  const onCloseNearbyDestination = () => {
    setShowNearbyDestination(false)
    setTextState(null)
    setTypeState(null)
    fitBoundCamera(routesDirectionRenderRef.current)
  }

  const onChangeNearbyDestination = (value) => {
    setTextState(value?.text)
    setTypeState(value?.type)
  }

  const onBackSuggest = () => {
    setShowNearbyAlongDirection(false)
    setTextState(null)
    setTypeState(null)
    fitBoundCamera(routesDirectionRenderRef.current)
  }

  const onClickOptionRoute = (value) => {
    setShowNearbyAlongDirection(true)
    setTypeState(value?.type)
    setTextState(value?.name)
  }

  const onBackShowMore = () => {
    setShowMoreAlongDirection(false)
  }

  const onChangeRoute = (index) => {
    setRouteIndexSelect(index)
    setShowNearbyDestination(false)
    if (showDetailRoute.current) {
      setIndexState(index)
    }
  }
  const onChangeOptimize = (checked) => {
    if (!checked) {
      setPositions(prev => {
        let arr = Array.from(prev || [])
        arr.sort((a, b) => (a.index - b.index))
        return arr
      })
    }
    setIsOptimize(checked)
  }

  const onBlurInput = (text, index) => {
    let position = LocationTool.checkTextIsLocation(text)
    if (position) {
      setPositions(prev => {
        let newPositions = [...prev || []]
        let pos = newPositions[index]
        if (!pos?.position) {
          pos.position = position
          pos.textInput = getTextInput(pos)
          pos.optionType = DirectionOptionTypeEnum.position
          return newPositions
        }
        else {
          return prev
        }
      })
    }
  }

  const onChangeSearchShowMore = (value) => {
    let text = value?.text || value?.name || value?.address || value
    let type = value?.type
    setShowMoreAlongDirection(false)
    setShowNearbyAlongDirection(true)
    setTypeState(type)
    setTextState(text)
  }
  let isShowError = (routeStatus == RouteStatusEnum.errorApi || routeStatus == RouteStatusEnum.errorPoi || routeStatus == RouteStatusEnum.errorVehicle)
  let isShowOption = !(routeStatus == RouteStatusEnum.none || routeStatus == RouteStatusEnum.errorApi || routeStatus == RouteStatusEnum.errorPoi)

  return (
    <div className='directionV2Ctn' style={{ height: windowSize?.height }}>
      <div className={StringTool.mergeClassName('directionV2', showListContainer && "hidden")}>
        <div className='top'>
          <div className='header'>
            <TooltipV2 description={Resource.common.menu} anchor='bottom'>
              <div className='left' onClick={handleShowSetting}>
                {SvgIconConfig.common.menu}
              </div>
            </TooltipV2>

            <div className='vehicles'>
              <VehicleSelect
                value={vehicle}
                onChange={onChangeVehicle}
                durationByVehicles={durationVehicle}
              />
            </div>
            <TooltipV2 description={Resource.common.close} anchor='bottom'>
              <div>
                <Back>
                  <div className='right'>
                    {SvgIconConfig.common.close}
                  </div>
                </Back>
              </div>
            </TooltipV2>


          </div>
          <div
            className='containerPositions'>
            <DirectionPositionsInput
              onFocus={onFocusInput}
              onPermutePositions={onPermutePositions}
              onSearch={onSearch}
              value={positions}
              onSwap={onSwap}
              onRemove={onRemove}
              onAdd={onAdd}
              onBlur={onBlur}
              onBlurInput={onBlurInput}
              onEnter={onSearchEnter}
              disableAddDestination={disableAddDestination}
            />
          </div>
          {/* List position */}
        </div>
        <div className='bottom'>
          {
            isShowOption &&
            <div className='options'>
              <div className='header'>
                <div className='left'>
                  {
                    showOptions &&
                    <span className='title'>{Resource.common.option}</span>
                  }
                  {
                    showDisableAddDestination &&
                    <TooltipV2 title={disableAddDestinationText}>
                      <div className='disableAddDestination'>
                        {SvgIconConfig.direction.disableAddDestination}
                      </div>
                    </TooltipV2>
                  }
                  {
                    showDepart && !showOptions &&
                    <div className={StringTool.mergeClassName('departType', !showDisableAddDestination && "marginLeft")}>
                      <DepartSelect
                        value={depart}
                        onChange={onChangeDepart}
                      />
                    </div>
                  }

                </div>
                <button className='toggle' onClick={onClickOptions}>
                  {
                    showOptions ? Resource.common.close : Resource.common.option
                  }
                </button>
                {
                  showDepartAt &&
                  <div className='left'>
                    <div className='disableAddDestination'></div>
                    <div className='depardAt'>
                      <div className='time'>
                        <TimePickerV2
                          value={{
                            hour: departAt?.getHours(),
                            minute: departAt?.getMinutes()
                          }}
                          onChange={onChangeTime}
                          maxLengthOfScroll={10}
                          minTime={createMinTime(departAt)}
                        />
                      </div>
                      <div className='date'>
                        <DatePickerV2 value={departAt} onChange={onChangeDate} minDate={new Date()} />
                      </div>
                    </div>
                  </div>
                }
              </div>
              <Collapse open={showOptions}>
                <RouteOptimizationOption value={isOptimize} onChange={onChangeOptimize} disabled={positions?.length < 4} />
                <WeightingOption value={weighting} onChange={onChangeWeighting} length={positions?.length || 2} />
                <RoadAvoid value={avoidRoads} onChange={onChangeAvoidRoads} />
              </Collapse>
            </div>
          }

          {
            (routes?.length > 0 && routeStatus == RouteStatusEnum.ok) &&
            <ListRoute weighting={weighting} onChangeDetail={onChangeDetail} routes={routes} mode={vehicle} index={routeIndexSelect} onChangeSelect={onChangeRoute} />

          }

          {
            routeStatus == RouteStatusEnum.loading &&
            <div className='loadingContent'>
              {
                Array.from({ length: 2 }, (_, i) => i).map((item) => {
                  return (
                    <Fragment key={item}>
                      <div className='loadingRoute' >
                        <div className='left'>
                          <Skeleton width='60%' height='1rem' />
                          <Skeleton width='70%' height='0.5rem' />
                          <Skeleton width='30%' height='1rem' />
                        </div>

                        <div className='right'>
                          <Skeleton width='60%' height='1rem' />
                          <Skeleton width='40%' height='0.5rem' />
                        </div>
                      </div>
                      {
                        item == 0 &&
                        <Skeleton width='100%' height='1px' />
                      }
                    </Fragment>
                  )
                })
              }

            </div>
          }

          {
            routes?.length > 0 &&
            <>
              {
                isShowOption &&
                <div className='nearbyLocationOptionCtn'>
                  <NearByLocationOptions pointName={positions?.[positions?.length-1]?.name || ""} onChange={onClickNearByDestintion} />
                </div>
              }

              <RoutesInfor routes={routes} vehicle={vehicle} activeRoute={routeIndexSelect} onChange={onChangeRoute} />

              <DrawDirection
                currentIdx={routeIndexSelect}
                vehicle={vehicle}
                positions={positions}
                routes={routes}
                onDrawEnd={onDrawEnd}
                onChange={onChangeRoute}
              />
            </>
          }

          {
            isShowError &&
            <div className='errorBox'>
              <div className='errorTitle'>{Resource.message.errorDirection}</div>
              <div className='errorLabel'>
                {
                  routeStatus == RouteStatusEnum.errorVehicle &&
                  `${Resource.direction.errorMessage.api}.`
                }

                {
                  routeStatus == RouteStatusEnum.errorPoi &&
                  ` ${Resource.direction.errorMessage.poi}
                    ${Resource.common.from?.toLocaleLowerCase()} 
                    "${DirectionTool.getPointLabelName(positions[0])}" 
                    ${Resource.common.to.toLocaleLowerCase()} 
                    "${DirectionTool.getPointLabelName(positions[1])}".
                  `
                }

                {
                  routeStatus == RouteStatusEnum.errorApi &&
                  `${Resource.direction.errorMessage.api}.`
                }
              </div>
            </div>

          }

          {
            errorSearchEnter ?
              <div className='errorBox'>
                <div className='errorTitle'>
                  {Resource.direction.errorMessage.textSearch}
                  {` "${textInput}" `}
                </div>
                <div className='errorLabel'>{Resource.direction.captionTextSearch}</div>
              </div>
              :
              showSuggest && suggests?.length > 0 &&
              <div className='suggests'>
                {
                  isSearchEnter &&
                  <div className='textSuggest'>{Resource.direction.suggestMessage}</div>
                }
                {
                  suggests?.map((suggest, index) => {
                    return (
                      <div
                        onClick={onClickSuggest(index)}
                        key={suggest?.id}
                        className="option"
                        title={suggest.text}
                      >
                        <span className='icon'>
                          {IconDict[suggest.optionType]}
                        </span>
                        <span className='optionLabel'>
                          <span className='text'>
                            {suggest?.text}
                          </span>
                          {
                            suggest?.caption &&
                            <span className='caption'>
                              {suggest?.caption}
                            </span>
                          }
                        </span>
                        {
                          suggest?.buttonName &&
                          <span className='action' onClick={suggest?.onClick}>
                            {suggest?.buttonName}
                          </span>
                        }
                      </div>
                    )
                  })
                }
              </div>
          }
        </div>

        {
          routes?.[indexState] &&
          <div className='routeDetailCtn'>
            <RouteDetailV2
              route={routes?.[indexState]}
              points={positions}
              onBack={onClickBackInDetailRoute}
              vehicle={vehicle}
            />
          </div>
        }

        {
          showMoreAlongDirection &&
          <div className='moreAlongDirectionTCpn'>
            <ListSearchRoute onClick={onClickOptionRoute} onBack={onBackShowMore} onChange={onChangeSearchShowMore} />
          </div>
        }

        {
          routes && !showNearbyDestination && !showNearbyAlongDirection && !showMoreAlongDirection && <div className='nearbyDirectionSuggest'>
            <SuggestPlaceDirection onClick={onClickSuggestAlong} onClickMore={onClickMoreSuggestAlong} vehicle={vehicle} />
          </div>
        }

        {/* marker and get info for position */}
        {
          positions?.map((pos, index) => {
            let type = PositionDirectionTypeEnum.middle
            if (index == 0) {
              type = PositionDirectionTypeEnum.start
            }
            else if (index == (positions?.length - 1)) {
              type = PositionDirectionTypeEnum.end
            }
            return (

              <Fragment key={pos?.id}>
                {
                  pos?.position &&
                  <MarkerDirection
                    index={index}
                    type={type}
                    name={pos?.name}
                    position={pos?.position}
                    sortIndex={pos?.index}
                    disableDrag={showListContainer}
                  />
                }

                {
                  // (pos?.optionType == DirectionOptionTypeEnum.position || pos?.optionType == DirectionOptionTypeEnum.poi) &&
                  <GetInfoForPositionDirection
                    onFinishGeoCode={onFinishGeoCode(pos.index)}
                    position={pos?.position}
                    id={pos?.poiId}
                    type={pos?.optionType}
                  />
                }
              </Fragment>

            )
          })
        }
        <HidePoiControl />
        <HidePlaceControl />
        <HideLocationControl />
        <HideCircleMenu />
        <HiddenAddClick />
        {
          showHomeInput &&
          <UserHomeAddressInput onClose={onCloseHomeInput} onChange={onChangeHomeInput} />
        }
        {
          showWorkInput &&
          <UserWorkAddressInput onClose={onCloseWorkInput} onChange={onChangeWorkInput} />
        }
      </div>

      <div className={StringTool.mergeClassName('listCtn', showListContainer && "show")}>
        {
          showNearbyDestination &&
          <ListPoiNearByLocation
            onClose={onCloseNearbyDestination}
            location={positions[positions?.length - 1]?.position}
            searchKey={textState}
            type={typeState}
            onChange={onChangeNearbyDestination}
            endPointName={DirectionTool.getPointLabelName(positions[positions?.length - 1])}
          />
        }
        {
          showNearbyAlongDirection &&
          <SuggestAlongDirection
            onClose={onBackSuggest}
            onBack={onBackSuggest}
            searchKey={textState}
            type={typeState}
            bodyRoute={bodyRoute}
          />
        }
      </div>
    </div>
  )
};

DirectionV2.propTypes = {
  //
};

export default DirectionV2;
