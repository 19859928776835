import { SettingsOverscan } from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { DirectionDataState } from '../../../appState';
import { AppConfig, CommonConfig, EmbedRouterConfig, LinkIconConfig, UrlConfig } from '../../../config';
import { AppData } from '../../../data';
import { CodeEnum, CookieEnum, ModeEnum, RouteStatusEnum, WeightingEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool, CookieTool, DirectionTool, EncryptionTool, PlaceTool, UrlTool } from '../../../tool';
import { useQuery, useWindowSize } from '../../../useHooks';
import "./directionEmbed.scss";
;

let basename = "";
// if (!baseUrl.includes("3001")) {
//   basename += "/v2";
// }
const caculatorPropsPois = (arrPois) => {
  if (arrPois?.length >= 2) {
    return arrPois
  }
  if (arrPois?.length == 1) {
    return [...arrPois, null]
  }
  return [null, null]
}

const getPoiMarkerLabel = (value) => {
  return (value?.name || value?.address || (`${value?.location.lat}, ${value?.location.lng}`) || Resource.common.notYetName)
}
function DirectionEmbed(props) {
  const activeStrokePattern = {
    [ModeEnum.foot]: new map4d.IconPattern({
      url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAQCAYAAAB3AH1ZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATRJREFUeNpiZCAR2PvOTwBS/kAcgCa1AYg3HtycuIAU8xhJsFgASK0HYgcCSg8AcSDQIR+o5gCo5feBWOAPNwfDBx05hm8ywgz/WFnA8ky//zBwPXnLIHDlEQPL1x8gIZDlisQ4gonIAAD5XOCXIDfDMw9Dhi+K4nDLQQDEBomB5EBqQGqhehgodgA0zh1APn/hpIdiMToAyYHUgNSC9ED1UhwC/uAwBQY7PsuRHQFSi6yXUgeAUzsozokFSGoDqJUGiPI9OWqZGAYYEO0AUFajiVoi1IBKOHA+JxYgqd1ADQdsBGdsYCFDjM9AakBqkfVSoyTcD8rXoEIGX1kAslxi3yUGtvdfwUUysCR0pFYaCAQVBSCDpXacZ+C5/xIlNEBskBhIDmr5B6ieYVIZ0bI6BggwAGTSeo007gGFAAAAAElFTkSuQmCC"
    }),
    [ModeEnum.bike]: new map4d.SolidPattern(),
    [ModeEnum.car]: new map4d.SolidPattern(),
    [ModeEnum.motorcycle]: new map4d.SolidPattern(),
  }
  const { data } = useQuery()
  const [pois, setPois] = useState([])
  const [weightingOption, setWeightingOption] = useState(WeightingEnum.fastest)
  const [modeVehicle, setModeVehicle] = useState(ModeEnum.car)
  const [routeStatus, setRouteStatus] = useState(RouteStatusEnum.none)

  const windowSize = useWindowSize()

  const directionsRendererRef = useRef()

  const isDraggingRef = useRef(false)

  let indexOfNull = pois?.findIndex((poi) => !poi)

  const fitBound = (routes) => {
    let bounds = new map4d.LatLngBounds()
    routes?.map((r, index) => {
      let points = DirectionTool.decode(r.overviewPolyline);
      for (let i = 0; i < points.length; i++) {
        bounds.extend([points[i].lng, points[i].lat])
      }
      let paddingOptions = {
        top: (windowSize.height >= 300 && windowSize.width >= 200) ? 120 : 80,
        bottom: 10,
        left: 10,
        right: 10
      }
      let camera = AppData.map.getCameraWithBounds(bounds, paddingOptions)
      if (camera.getZoom() > 19) {
        camera.setZoom(19)
      }
      AppTool.moveCamera(camera, { animate: true })
    })
  }

  useEffect(() => {
    return () => {
      removeDirectionRenderer()
    }
  }, [])

  useEffect(() => {
    if (data) {
      let objDirection = DirectionTool.decodeDirection(data)
      if (objDirection?.positions?.length > 0) {
        DirectionTool.getWaypointsFromLocations(objDirection.positions, (poiNews) => {
          setPois(poiNews)
        })
      }
      if (objDirection?.vehicle != undefined) {
        setModeVehicle(objDirection?.vehicle)
      }
      if (objDirection?.weighting != undefined) {
        setWeightingOption(objDirection?.weighting)
      }
    }
  }, [data])

  const onMarkerDragend = (args) => {
  }
  const drawDirection = (pois, routers) => {
    removeDirectionRenderer()
    let res = {
      code: "ok",
      result: {
        routes: [...routers || []]
      }
    }
    let waypointMarkerOptions = []
    for (let i = 1; i < pois?.length - 1; i++) {
      waypointMarkerOptions.push({
        position: pois[i]?.location,
        anchor: [0.5, 0.5],
        visible: true,
        labelAnchor: [1.1, 0.5],
        label: new map4d.MarkerLabel({
          text: getPoiMarkerLabel(pois[i]),
          anchor: [0, 0.5]
        }),
        icon: new map4d.Icon(16, 16, LinkIconConfig.directionPois.fromIcon)
      })
    }

    directionsRendererRef.current = new map4d.DirectionsRenderer({
      routes: JSON.stringify(res),
      activeStrokeColor: CommonConfig.strokeBlue,
      activeOutlineColor: CommonConfig.outlineBlue,
      inactiveStrokeColor: CommonConfig.strokeGrey,
      inactiveOutlineColor: CommonConfig.outlineGrey,
      activeStrokeWidth: 5,
      inactiveStrokeWidth: 5,
      activeOutlineWidth: modeVehicle == ModeEnum.foot ? 0 : 1.5,
      inactiveOutlineWidth: 1.5,
      waypointMarkerOptions: waypointMarkerOptions,
      onMarkerDragEnd: onMarkerDragend,
      originMarkerOptions: {
        position: pois[0]?.location,
        anchor: [0.5, 0.5],
        visible: true,
        labelAnchor: [1.1, 0.5],
        label: new map4d.MarkerLabel({
          text: getPoiMarkerLabel(pois[0]),
          anchor: [0, 0.5]
        }),
        icon: new map4d.Icon(16, 16, LinkIconConfig.directionPois.fromIcon)
      },
      destinationMarkerOptions: {
        position: pois[pois?.length - 1]?.location,
        anchor: [0.5, 0.8],
        visible: true,
        labelAnchor: [1.1, 0.5],
        label: new map4d.MarkerLabel({
          text: getPoiMarkerLabel(pois[pois?.length - 1]),
          anchor: [0, 0.5]
        }),
        icon: new map4d.Icon(20, 35, LinkIconConfig.directionPois.toIcon)
      },
      activeStrokePattern: activeStrokePattern[modeVehicle],
    })
    directionsRendererRef.current?.setMap(AppData.map)
  }

  const removeDirectionRenderer = () => {
    directionsRendererRef.current?.setMap(null)
  }

  useEffect(() => {
    var source
    let locNews = pois.map((poi, index) => {
      return poi?.location || null
    })

    let dataNew = {
      locs: locNews,
      mode: modeVehicle,
      weighting: weightingOption
    }

    let data = DirectionTool.encodeDirection({
      positions: locNews,
      vehicle: modeVehicle,
      weighting: weightingOption
    })
    let search = UrlTool.createSearch({ data: data, mapid: AppData.mapid || undefined })
    let url = basename + EmbedRouterConfig.direction + search
    window.history.replaceState(null, null, url)

    let lang = CookieTool.get(CookieEnum.language) || 'vi'
    let start = pois[0]?.location?.lat + ',' + pois[0]?.location?.lng
    let end = pois[pois?.length - 1]?.location?.lat + ',' + pois[pois?.length - 1]?.location?.lng

    if (pois.length >= 2 && indexOfNull < 0) {
      let wPoints = []
      for (var i = 1; i < pois?.length - 1; i++) {
        wPoints.push(pois[i]?.location?.lat + ',' + pois[i]?.location?.lng)
      }
      wPoints = wPoints.join(';');

      let body = {
        origin: start,
        destination: end,
        points: wPoints,
        language: lang,
        mode: modeVehicle,
        weighting: weightingOption
      }

      if (DirectionTool.isValidPois(pois)) {
        setRouteStatus(RouteStatusEnum.loading)
        source = ApiTool.queryGetFromJson(UrlConfig.object.route, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let routes = res?.result?.routes
            setRouteStatus(RouteStatusEnum.ok)
            drawDirection(pois, routes)

            if (!isDraggingRef.current) {
              fitBound(res?.result?.routes)
            }
            else {
              isDraggingRef.current = false
            }
          }
          else {
            setRouteStatus(RouteStatusEnum.errorApi)
          }
        })
      }
      else {
        setRouteStatus(RouteStatusEnum.errorPoi)
      }
    }
    else {
      setRouteStatus(RouteStatusEnum.none)
    }
    return () => {
      source?.cancel()
    }
  }, [pois, modeVehicle, weightingOption])

  const onClickLink = () => {
    window.top.open(window.location.href?.replace("/embed", "/map"), "_blank")
  }

  let isNotSmall = windowSize.height >= 200 && windowSize.width >= 200
  let maxWidth = (windowSize.width || 0) - 20
  if (maxWidth > 300) {
    maxWidth = 300
  }
  return (
    <>
      <div
        className='directionEmbed'
        style={{
          maxWidth: maxWidth
        }}
      >
        {
          isNotSmall &&
          <div className='list'>
            <div className='left'>
              {
                pois?.map((icon, index) => {
                  return (
                    index == 0 ?
                      <svg className='start' key={index} fill='#000000' version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 14.4 20" }}>
                        <g transform="translate(4689 -1673)">
                          <path className="st0" d="M-4677,1697c-6.6,0-12-5.4-12-12c0-6.6,5.4-12,12-12c6.6,0,12,5.4,12,12C-4665,1691.6-4670.4,1697-4677,1697z
                    M-4677,1676.6c-4.7,0-8.4,3.8-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4c4.7,0,8.4-3.8,8.4-8.4C-4668.6,1680.3-4672.3,1676.6-4677,1676.6z"/>
                        </g>
                      </svg>
                      : (
                        (index < pois?.length - 1) ?
                          <Fragment key={index}>
                            <svg className='dot' version="1.1" fill='#ccc' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                              viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 14.4 20" }}>
                              <g transform="translate(-33 -89)">
                                <g transform="translate(-8 20)">
                                  <circle className="st0" cx="53" cy="71.8" r="2.8" />
                                  <path className="st0" d="M53,87.5c1.5,0,2.8,1.2,2.8,2.8S54.5,93,53,93s-2.8-1.2-2.8-2.8S51.5,87.5,53,87.5z" />
                                  <circle className="st0" cx="53" cy="81" r="2.8" />
                                </g>
                              </g>
                            </svg>
                            <svg className='start' key={index} fill='#000000' version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                              viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 14.4 20" }}>
                              <g transform="translate(4689 -1673)">
                                <path className="st0" d="M-4677,1697c-6.6,0-12-5.4-12-12c0-6.6,5.4-12,12-12c6.6,0,12,5.4,12,12C-4665,1691.6-4670.4,1697-4677,1697z
                    M-4677,1676.6c-4.7,0-8.4,3.8-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4c4.7,0,8.4-3.8,8.4-8.4C-4668.6,1680.3-4672.3,1676.6-4677,1676.6z"/>
                              </g>
                            </svg>
                          </Fragment>
                          :
                          <Fragment key={index}>
                            <svg className='dot' version="1.1" fill='#ccc' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                              viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 14.4 20" }}>
                              <g transform="translate(-33 -89)">
                                <g transform="translate(-8 20)">
                                  <circle className="st0" cx="53" cy="71.8" r="2.8" />
                                  <path className="st0" d="M53,87.5c1.5,0,2.8,1.2,2.8,2.8S54.5,93,53,93s-2.8-1.2-2.8-2.8S51.5,87.5,53,87.5z" />
                                  <circle className="st0" cx="53" cy="81" r="2.8" />
                                </g>
                              </g>
                            </svg>
                            <svg className='end' version="1.1" fill='red' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                              viewBox="0 0 14.4 20" style={{ enableBackground: "new 0 0 14.4 20" }}>
                              <path className="st0" d="M7.2,0c-3.9,0-7,3.1-7,7c0,0,0,0,0,0c0,1.3,0.4,2.6,1.1,3.7l5.5,9C6.8,19.9,6.9,20,7.1,20
	c0.2,0.1,0.5-0.1,0.6-0.3l5.5-9.1c0.7-1.1,1-2.3,1-3.6C14.2,3.1,11,0,7.2,0z M7.2,10.1c-1.7,0-3-1.4-3-3s1.4-3,3-3c1.7,0,3,1.4,3,3
	S8.9,10.1,7.2,10.1z"/>
                            </svg>
                          </Fragment>

                      )
                  )
                })
              }
            </div>
            <div className='right'>
              {
                pois.map(poi => {
                  return (
                    <div className='poi' key={poi?.id || v4()}>
                      <span title={PlaceTool.getPoiLabel(poi)}>
                        {PlaceTool.getPoiLabel(poi)}
                      </span>

                    </div>
                  )
                })
              }
            </div>
          </div>
        }

        <a
          style={{
            marginLeft: isNotSmall ? 24 : 0
          }}
          className='link'
          // onClick={onClickLink}
          href={window.location.href?.replace("/embed/", "/map/")}
          target="_blank"
          title="Xem bản đồ lớn hơn"
        >
          {
            isNotSmall ?
              "Tùy chọn khác"
              :
              (
                windowSize.width >= 300 ?
                  "Xem bản đồ lớn hơn"
                  :
                  <SettingsOverscan />

              )
          }

        </a>
      </div>
    </>
  )
}

DirectionEmbed.propTypes = {
  //
};

export default DirectionEmbed;
