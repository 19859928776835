import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Collapse, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from 'react-router-dom';
import * as yup from "yup";
import { CommonConfig, ImageConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum, MapEventEnum, TypeAddressEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, BusinessHourTool, DateTimeTool, LocationTool } from '../../tool';
import AddressInput from '../common/addressInput/addressInput';
import BuildingInput from '../common/buildingInput/buildingInput';
import BusinessHourInput from '../common/businessHourInput/businessHourInput';
import CategoryInput from '../common/categoryInput/categoryInput';
import DescriptionInput from '../common/descriptionInput/descriptionInput';
import Field from '../common/field/field';
import MetaDataInput from '../common/metaDataInput/metaDataInput';
import MetaDataInputV2 from '../common/metaDataInputV2/metaDataInputV2';
import PhotosInput from '../common/photosInput/photosInput';
import TagInput from '../common/tagInput/tagInput';
import Confirm from './../common/confirm/confirm';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const marginField = 24
const marginText = 10
const paddingHeader = 16

const useStyles = makeStyles({
  header: {
    boxShadow: '0px 0px 2px #00000029'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: '4px',
  },
  marginBottomField: {
    marginBottom: `${marginField}px`
  },
  marginText: {
    marginTop: `${marginField}px`
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: 'inherit',
    fontWeight: 500
  }
})

const regexPhoneNumber = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

function AddPlace() {

  const { lat, lng, placeId, placeName } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const markerRef = useRef(null)

  const [showExtend, setShowExtend] = useState(false)
  const [place, setPlace] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [position, setPosition] = useState({ lat: "", lng: "" })

  const [addressComponents, setAddressComponents] = useState([])
  const [imageFiles, setImageFiles] = useState([])

  const schema = yup.object().shape({
    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.name
      })),

    phoneNumber: yup.string()
      .test("phoneNumberFormat", Resource.formatString(Resource.validate.format, { field: Resource.common.phoneNumber }), function (phone) {
        if (phone && (!regexPhoneNumber.test(phone) || phone == '0000000000')) {
          return false
        }
        return true
      }),
    website: yup
      .string()
      .url(Resource.formatString(Resource.validate.typeError, {
        field: Resource.common.path,
        type: Resource.common.link.toLocaleLowerCase()
      })),
    addressComponents: yup.mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.place.province
      }), function (addressComponents) {
        let address = addressComponents?.find(t => {
          return t.types?.includes(TypeAddressEnum.province)
        })
        if (!address) {
          return false
        }
        return true;
      }),
    types: yup
      .mixed().nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.category
      }), function (category) {
        if (category?.length == 0) {
          return false
        }
        return true;
      }),
    lat: yup
      .number()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.lat }))
      .min(-90, Resource.formatString(Resource.validate.min, { field: Resource.common.lat, min: -90 }))
      .max(90, Resource.formatString(Resource.validate.max, { field: Resource.common.lat, max: 90 }))
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(Resource.formatString(Resource.validate.typeError, { field: Resource.common.lat, type: Resource.common.number.toLowerCase() })),
    lng: yup
      .number()
      .required(Resource.formatString(Resource.validate.required, { field: Resource.common.lng }))
      .min(-180, Resource.formatString(Resource.validate.min, { field: Resource.common.lng, min: -180 }))
      .max(180, Resource.formatString(Resource.validate.max, { field: Resource.common.lng, max: 180 }))
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(Resource.formatString(Resource.validate.typeError, { field: Resource.common.lng, type: Resource.common.number.toLowerCase() })),
  })

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema)
  })
  register({ name: 'lng', type: 'custom' })
  register({ name: 'lat', type: 'custom' })
  register({ name: 'addressComponents', type: 'custom' })
  register({ name: 'types', type: 'custom' })
  register({ name: 'tags', type: 'custom' })
  register({ name: 'objectId', type: 'custom' })
  register({ name: 'businessHours', type: 'custom' })
  register({ name: 'description', type: 'custom' })
  register({ name: 'photos', type: 'custom' })
  register({ name: 'metadata', type: 'custom' })

  const closeAdd = () => {
    history.push({
      pathname: RouterConfig.home
    })
  }

  const handleShowExtend = () => {
    setShowExtend(!showExtend)
  }

  useEffect(() => {
    setValue("lat", position?.lat, { shouldValidate: formState.isSubmitted })
    setValue("lng", position?.lng, { shouldValidate: formState.isSubmitted })
  }, [position])

  useEffect(() => {
    if (LocationTool.isValid(position)) {
      if (!markerRef.current) {
        markerRef.current = new map4d.Marker({
          position: position,
          anchor: [0.5, 1],
          draggable: true,
          icon: new map4d.Icon(30, 42, ImageConfig.marker),
          zIndex: 1000
        })
        markerRef.current.setMap(AppData.map)
      }

      markerRef.current?.setPosition(position)
      let body = {
        lat: position.lat,
        lng: position.lng
      }

      ApiTool.queryGetFromJson(UrlConfig.geocode.getAddressComponents, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setAddressComponents(res?.result || [])
        }
      })
    }
  }, [position])

  useEffect(() => {
    let newLocation = {
      lat: lat,
      lng: lng
    }

    let camera = AppData.map.getCamera()
    camera.setTarget(newLocation)
    AppData.map.moveCamera(camera, { animate: true })
    setPosition(newLocation)

  }, [lat, lng])


  useEffect(() => {
    setValue("types", [], { shouldValidate: formState.isSubmitted })
  }, [])


  useEffect(() => {
    setValue('addressComponents', addressComponents, { shouldValidate: formState.isSubmitted })
  }, [addressComponents])

  useEffect(() => {
    const eventDragMarker = AppData.map.addListener(MapEventEnum.dragEnd, (args) => {
      setPosition(args.marker.getPosition())
    }, { marker: true })

    return () => {
      eventDragMarker?.remove()
      markerRef.current?.setMap(null)
    }
  }, [])

  const onChangeAddress = (addressComponents) => {
    setAddressComponents(addressComponents)
  }

  const onChangeTypes = (types) => {
    let newType = types?.map(t => {
      return t?.id
    })
    setValue('types', newType, { shouldValidate: formState.isSubmitted })
  }

  const onChangeTag = (tags) => {
    let newTag = tags?.map(tg => {
      return tg?.name
    })
    setValue('tags', newTag, { shouldValidate: formState.isSubmitted })
  }

  const onChangeObject = (object) => {
    setValue('objectId', object?.id || null, { shouldValidate: formState.isSubmitted })
  }

  const onChangeBusinessHours = (businessHours) => {
    setValue('businessHours', BusinessHourTool.convertBusinessHour(businessHours), { shouldValidate: formState.isSubmitted })
  }

  const onChangeDescription = (description) => {
    // if (description == `<p></p>\n` || description == `<p></p>`) {
    //   description = null
    // }
    setValue('description', description, { shouldValidate: formState.isSubmitted })
  }

  const onChangePhoto = (photos, imageFiles) => {
    setImageFiles(imageFiles)
  }

  const onChangeMetaData = (metadata) => {
    setValue('metadata', metadata, { shouldValidate: formState.isSubmitted })
  }

  const onChangeLat = (e) => {
    let value = e.target.value
    setPosition(prev => {
      let newPosition =
      {
        ...prev,
        lat: value
      }
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera()
        camera.setTarget(newPosition)
        AppData.map.moveCamera(camera, { animate: true })
      }
      return newPosition
    })
  }

  const onChangeLng = (e) => {
    let value = e.target.value
    setPosition(prev => {
      let newPosition =
      {
        ...prev,
        lng: value
      }
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera()
        camera.setTarget(newPosition)
        AppData.map.moveCamera(camera, { animate: true })
      }
      return newPosition
    })
  }

  const onChangeStartDate = (date) => {
    setStartDate(DateTimeTool.getDateWithStartTime(date))
  }

  const onChangeEndDate = (date) => {
    setEndDate(DateTimeTool.getDateWithEndTime(date))
  }

  const sendRequest = (data) => {
    let body = {
      ...data,
      startDate: startDate?.getTime(),
      endDate: endDate?.getTime(),
      location: position,
      placeId: null,
    }
    ApiTool.post(UrlConfig.place.request, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.add, {
            object: Resource.common.place.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        closeAdd()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.add, {
            object: Resource.common.place.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const onSubmit = (data) => {
    if (imageFiles?.length > 0) {
      let body = {
        files: imageFiles
      }
      ApiTool.postFormFromJson(UrlConfig.file.listMedia, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          data.mediaFiles = res?.result
          sendRequest(data)
        }
        else {
          AppTool.alert(
            Resource.formatString(Resource.alert.error.add, {
              object: Resource.common.images.toLowerCase()
            }),
            AlertTypeEnum.error
          )
        }
      }, true)
    }
    else {
      sendRequest(data)
    }

  }

  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      <Box className={classes.header}>
        <Box textAlign='center' p={`${paddingHeader}px`}>
          <Typography variant="h6">
            {Resource.place.addPlace}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box overflow='auto' flexGrow='1'>
        <form style={{ height: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
          <Box p={`${marginField}px`} flexGrow='1' overflow='auto'>
            <Box>
              <Field
                required
                label={Resource.updatePlace.name}
                icon={<img src={LinkIconConfig.requestUpdatePlace.placeName} width={24} height={24} />}
              >
                <TextField
                  name='name'
                  label={Resource.updatePlace.addName}
                  placeholder={Resource.updatePlace.addName}
                  variant="outlined"
                  defaultValue={place?.name}
                  inputRef={register}
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                />
              </Field>

              <Field
                required
                error={errors.types ? true : false}
                helperText={errors?.types?.message}
                label={Resource.updatePlace.category}
                icon={<img src={LinkIconConfig.requestUpdatePlace.category} width={24} height={24} />}
              >
                <CategoryInput defaultValue={place?.placeTypes} onChange={onChangeTypes} error={errors.types ? true : false} />
              </Field>
              <Field
                label={Resource.common.lat}
                icon={<img src={LinkIconConfig.common.lat} width={24} height={24} />}
                required
              >
                <TextField
                  value={position?.lat}
                  onChange={onChangeLat}
                  inputProps={{
                    step: CommonConfig.lng.step
                  }}
                  label={Resource.lat.add}
                  placeholder={Resource.lat.add}
                  variant="outlined"
                  type="number"
                  name="lat"
                  error={errors.lat ? true : false}
                  helperText={errors.lat?.message}
                />
              </Field>
              <Field
                label={Resource.common.lng}
                icon={<img src={LinkIconConfig.common.lng} width={24} height={24} />}
                required
              >
                <TextField
                  value={position?.lng}
                  onChange={onChangeLng}
                  inputProps={{
                    step: CommonConfig.lng.step
                  }}
                  label={Resource.lng.add}
                  placeholder={Resource.lng.add}
                  variant="outlined"
                  type="number"
                  name="lng"
                  error={errors.lng ? true : false}
                  helperText={errors.lng?.message}
                />
              </Field>
              <Field
                required
                label={Resource.updatePlace.location}
                icon={<img src={LinkIconConfig.requestUpdatePlace.location} width={24} height={24} />}
              >
                <AddressInput
                  value={addressComponents}
                  onChange={onChangeAddress}
                  error={errors.addressComponents ? true : false}
                  helperText={errors?.addressComponents?.message}
                />
              </Field>

              <Field
                label={Resource.updatePlace.tag}
                icon={<img src={LinkIconConfig.requestUpdatePlace.label} width={24} height={24} />}
              >
                <TagInput defaultValue={place?.tags} onChange={onChangeTag} />
              </Field>

              <Field
                label={Resource.updatePlace.building}
                icon={<img src={LinkIconConfig.requestUpdatePlace.object} width={24} height={24} />}
              >
                <BuildingInput defaultValue={place?.object} onChange={onChangeObject} location={position} />
              </Field>

              <Field
                label={Resource.updatePlace.timeOpen}
                icon={<img src={LinkIconConfig.requestUpdatePlace.clock} width={24} height={24} />}
              >
                <BusinessHourInput onChange={onChangeBusinessHours} />
              </Field>

              <Field
                label={Resource.common.startDate}
                icon={<img src={LinkIconConfig.common.startDate} width={24} height={24} />}
              >
                <DatePicker
                  label={Resource.startDate.choose}
                  inputVariant="outlined"
                  InputLabelProps={
                    {
                      shrink: true
                    }
                  }
                  clearable
                  value={startDate}
                  maxDate={endDate || CommonConfig.maxDate}
                  onChange={onChangeStartDate}
                  format="dd/MM/yyyy"
                />
              </Field>
              <Field
                label={Resource.common.endDate}
                icon={<img src={LinkIconConfig.common.endDate} width={24} height={24} />}
              >
                <DatePicker
                  label={Resource.endDate.choose}
                  inputVariant="outlined"
                  InputLabelProps={
                    {
                      shrink: true
                    }
                  }
                  clearable
                  value={endDate}
                  minDate={startDate || CommonConfig.minDate}
                  onChange={onChangeEndDate}
                  format="dd/MM/yyyy"
                />
              </Field>

              <Field
                label={Resource.updatePlace.contact}
                icon={<img src={LinkIconConfig.requestUpdatePlace.contact} width={24} height={24} />}
              >
                <TextField
                  name="phoneNumber"
                  inputRef={register}
                  label={Resource.updatePlace.addPhoneNumber}
                  variant="outlined"
                  error={errors.phoneNumber ? true : false}
                  helperText={errors.phoneNumber?.message}
                />
              </Field>

              <Field
                label={Resource.updatePlace.web}
                icon={<img src={LinkIconConfig.requestUpdatePlace.website} width={24} height={24} />}
              >
                <TextField
                  name="website"
                  inputRef={register}
                  error={errors.website ? true : false}
                  helperText={errors?.website?.message}
                  label={Resource.updatePlace.addWebsite}
                  variant="outlined"
                  defaultValue={place?.website}
                />
              </Field>

              <Field
                label={Resource.updatePlace.description}
                icon={<img src={LinkIconConfig.requestUpdatePlace.description} width={24} height={24} />}
              >
                {/* <FieldControl label={Resource.updatePlace.addDescriptionForLocation}>
                  <DescriptionInput onChange={onChangeDescription} />
                </FieldControl> */}
                <DescriptionInput onChange={onChangeDescription} />
              </Field>
              <Field icon={<Box width="24px"></Box>} label=''>
                <PhotosInput onChange={onChangePhoto} />
                <Typography className={classes.marginText} variant='caption'>{Resource.updatePlace.descriptionPhotoFeild}</Typography>
              </Field>

            </Box>
            <Box>
              <Button className={classes.extendBtn} fullWidth variant='text' onClick={handleShowExtend} endIcon={showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {Resource.updatePlace.addExtendBtn}
              </Button>
              <Collapse in={showExtend} timeout={600} >
                <MetaDataInput value={place?.metadata} onChange={onChangeMetaData} />
              </Collapse>
            </Box>

          </Box>

          <Box display='flex' justifyContent='space-evenly' p={3} borderTop={1} borderColor='grey.200'>
            <Confirm
              message={Resource.formatString(Resource.confirmMessage.cancel, { action: Resource.place.addPlace?.toLowerCase() })}
              onConfirm={closeAdd}
            >
              <Button>{Resource.button.cancel}</Button>
            </Confirm>
            <Button variant='contained' type='submit' color='primary'>{Resource.button.send}</Button>
          </Box>
        </form>
      </Box>
    </Box>

  )

}

AddPlace.propTypes = {
  //
};

export default AddPlace;
