import { Box, Fab, Grid, Typography } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Chip } from 'ui';
import { ShowPopoverMoreState, ShowSlideBarState } from '../../../appState';
import { CommonConfig, ThemeConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { Resource } from '../../../resource';
import { useIsMobileScreen, useWindowSize } from '../../../useHooks';
import Popover from '../popover/popover';
import ShowMoreSuggestPlace from '../showMoreSuggestPlace/showMoreSuggestPlace';
import { LinkIconConfig } from './../../../config/linkIconConfig';
import './suggestPlace.scss';
import { UrlTool } from '../../../tool';

const ICON_RIGHT = <svg id="Component_761_1" data-name="Component 761 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,0H24V24H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z" transform="translate(0 24) rotate(-90)" fill="#869195" />
</svg>
const ICON_LEFT = <svg id="Component_759_2" data-name="Component 759 – 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,24H24V0H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M1.41,7.41,6,2.83l4.59,4.58L12,6,6,0,0,6Z" transform="translate(8 18) rotate(-90)" fill="#869195" />
</svg>

const themeSuggestSecondary = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#5C6E8A',
    }
  }
})

const minWidth = '70px'

const useStyle = makeStyles(theme => ({
  root: {
    opacity: 1,
  },
  grid: {
    paddingTop: `${CommonConfig.padding}px`,
    justifyContent: 'center'
  },
  borderSecondary: {
    boxShadow: 'none',
    border: '1px solid #5C6E8A',
    minWidth: '48px'
  },
  boxShadow: {
    boxShadow: 'none',
  },
  gutterBottom: {
    marginBottom: '20px',
  },
  cursor: {
    cursor: 'pointer'
  },

}))
function SuggestPlace() {
  const primarySuggestPlaces = [
    {
      name: Resource.suggestPlaceChip.restaurant,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.restaurant,
      iconActive: LinkIconConfig.suggestPlaceChip.active.restaurant,
      type: 'restaurant'
    },
    {
      name: Resource.suggestPlaceChip.coffee,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.coffee,
      iconActive: LinkIconConfig.suggestPlaceChip.active.coffee,
      type: 'cafe'
    },
    {
      name: Resource.suggestPlaceChip.gasStation,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.gasStation,
      iconActive: LinkIconConfig.suggestPlaceChip.active.gasStation,
      type: 'gas_station'
    },
    {
      name: Resource.suggestPlaceChip.grocery,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.grocery,
      iconActive: LinkIconConfig.suggestPlaceChip.active.grocery,
      type: 'grocery_store'
    },
    {
      name: Resource.suggestPlaceChip.school,
      iconUnactive: LinkIconConfig.suggestPlaceChip.unActive.school,
      iconActive: LinkIconConfig.suggestPlaceChip.active.school,
      type: 'education'
    }
  ]

  const primarySuggests = [
    {
      name: Resource.common.restaurant,
      color: "#5F9500",
      iconUnactive: LinkIconConfig.suggestPlace.restaurant,
      type: 'restaurant'
    },
    {
      name: Resource.common.atm,
      color: "#008CCB",
      iconUnactive: LinkIconConfig.suggestPlace.atm,
      type: 'atm'
    },
    {
      name: Resource.common.coffee,
      color: "#CB4500",
      iconUnactive: LinkIconConfig.suggestPlace.coffee,
      type: 'cafe'
    },
    {
      name: Resource.common.park,
      color: "#E39F00",
      iconUnactive: LinkIconConfig.suggestPlace.parking,
      type: 'parking'
    },
  ]
  const secondarySuggests = [
    {
      name: Resource.common.bar,
      iconUnactive: LinkIconConfig.suggestPlace.bar,
      type: 'bar'
    },
    {
      name: Resource.common.grocery,
      iconUnactive: LinkIconConfig.suggestPlace.shopping,
      type: 'grocery_store'
    },
    {
      name: Resource.common.gasStation,
      iconUnactive: LinkIconConfig.suggestPlace.gasStation,
      type: 'gas_station'
    },
    {
      name: Resource.common.hotel,
      iconUnactive: LinkIconConfig.suggestPlace.hotel,
      type: 'hotel'
    },
    {
      name: Resource.common.hospital,
      iconUnactive: LinkIconConfig.suggestPlace.hospital,
      type: 'hospital'
    },
    {
      name: Resource.common.school,
      iconUnactive: LinkIconConfig.suggestPlace.education,
      type: 'education'
    },
    {
      name: Resource.common.postOffice,
      iconUnactive: LinkIconConfig.suggestPlace.postOffice,
      type: 'post_office'
    },
    {
      name: Resource.common.bank,
      iconUnactive: LinkIconConfig.suggestPlace.bank,
      type: 'bank'
    },
  ]
  const location = useLocation()
  const history = useHistory()
  const classes = useStyle()
  const isMobile = useIsMobileScreen()
  const prevTrans = useRef()
  const widthCheck = 795
  const isOpenSidebar = useRecoilValue(ShowSlideBarState)
  const [isShowLeft, setIsShowLeft] = useState(false)
  const [isShowRight, setIsShowRight] = useState(false)
  const [trans, setTrans] = useState(0)
  const windowSize = useWindowSize()
  const widthSize = isOpenSidebar ? (windowSize?.width - 862) : (windowSize?.width - 448)
  const [windowCheck, setWindowCheck] = useState()
  const prevWidthSize = useRef(0)
  const [activeSuggestPlace, setActiveSuggestPlace] = useState(null)
  const [openMore, setOpenMore] = useState(false)
  const [checkPath, setCheckPath] = useState(false)
  const [isClose, setIsClose] = useRecoilState(ShowPopoverMoreState)

  useEffect(() => {
    prevTrans.current = trans
  }, [trans])

  useEffect(() => {
    setWindowCheck(widthSize)
  }, [widthSize])

  useEffect(() => {
    prevWidthSize.current = windowCheck
  }, [windowCheck])

  useEffect(() => {
    isClose && setOpenMore(false)
  }, [isClose])

  useEffect(() => {
    if (((widthCheck - trans) < widthSize + 16) || trans == (widthCheck - widthSize)) {
      setIsShowRight(false)
    }
    else {
      setIsShowRight(true)
    }
    if (trans > 0) {
      setIsShowLeft(true)
    }
    else setIsShowLeft(false)
    if (isShowRight == false && isShowLeft == true) {
      if (widthSize > prevWidthSize.current) setTrans((trans - (widthSize - prevWidthSize.current)))
    }
    if (widthSize > widthCheck) {
      setTrans(0)
    }
  }, [trans, widthSize])

  const handleClickRight = () => {
    if ((widthCheck - prevTrans.current - widthSize) > widthSize) {
      setTrans(prevTrans.current + widthSize)
    }
    else {
      setTrans(widthCheck - widthSize)
    }
  }

  const handleClickLeft = () => {
    if (trans - widthSize <= 0) {
      setTrans(0)
      setIsShowLeft(false)
    }
    else { setTrans(prevTrans?.current - widthSize) }
  }
  const handleSearchSuggestPlace = (suggest) => (e) => {
    history.push({
      pathname: RouterConfig.place.search
        .replace(":type?", suggest.type),
      search: UrlTool.createSearch({ text: suggest.name })
    })
    setCheckPath(false)
  }

  useEffect(() => {
    const activeSuggest = primarySuggestPlaces.find(item => location?.pathname.includes(item?.type))
    if (activeSuggest) setActiveSuggestPlace(activeSuggest?.type)
    else setActiveSuggestPlace(null)
  }, [location?.pathname])
  useEffect(() => {
    if (checkPath) {
      const activeSuggest = primarySuggestPlaces.find(item => location?.pathname.includes(item?.type))
      if (activeSuggest) setActiveSuggestPlace(activeSuggest?.type)
      else setActiveSuggestPlace(null)
    }
  }, [checkPath])

  const handleClosePopover = () => {
    setCheckPath(true)
    setOpenMore(false)
    setIsClose(true)
  }
  const handleOpenPopover = () => {
    setCheckPath(false)
    setOpenMore(true)
    setActiveSuggestPlace('more')
    setIsClose(false)
  }
  useEffect(() => {
    if (isShowRight == true) {
      setOpenMore(false)
    }
  }, [isShowRight])

  return (
    <>
      {!isMobile && <div className="ListSuggestPalceCpn" style={{
        display: `${(widthSize >= 110) ? "" : "none"}`
      }} >
        <div className='suggestPlaceCpn'
        >
          <div
            className='iconSuggest left'
            style={{ display: `${isShowLeft == true ? '' : "none"}` }}
            onClick={handleClickLeft}
          >
            {ICON_LEFT}
          </div>
          <div className='suggestPlace'
            style={{
              width: `${widthSize}px`
            }}>
            <div
              className='suggestPlaceContainer'
              style={{ left: `-${trans}px` }}
            >
              {
                primarySuggestPlaces.map((suggest) => {
                  return (
                    <Chip
                      key={suggest?.iconUnactive}
                      icon={<img src={activeSuggestPlace == suggest.type ? suggest?.iconActive : suggest?.iconUnactive} alt={`icon ${suggest.type}`} />}
                      onClick={handleSearchSuggestPlace(suggest)}
                      className={`itemSuggestPlace ${activeSuggestPlace == suggest.type ? 'active' : ''}`}
                    >
                      <span className='chipName'>{suggest.name}</span>
                    </Chip>
                  )
                })
              }
              <div className="popoverMore">
                <Popover
                  open={openMore}
                  onClose={handleClosePopover}
                  onOpen={handleOpenPopover}
                  actionButton={
                    <Chip
                      key="iconSugesstMore"
                      icon={<img src={openMore && activeSuggestPlace == 'more' ? LinkIconConfig.suggestPlaceChip.active.more : LinkIconConfig.suggestPlaceChip.unActive.more} />}
                      className={openMore && activeSuggestPlace == 'more' ? 'activeMoreSuggest' : 'unactive'}
                    >
                      {Resource.suggestPlaceChip.more}
                    </Chip>
                  }
                >
                  <ShowMoreSuggestPlace />
                </Popover>
              </div>
            </div>
          </div>
          <div
            className='iconSuggest right'
            style={{ display: isShowRight == true ? '' : "none" }}
            onClick={handleClickRight}
          >
            {ICON_RIGHT}
          </div>
        </div>
      </div>
      }
      {
        isMobile && <Box>
          <Grid container justify="space-between">
            {
              primarySuggests.map((suggest, index) => {
                let theme = createMuiTheme({
                  palette: {
                    primary: {
                      main: suggest.color,
                    }
                  }
                })
                return (
                  <Box
                    key={suggest.iconUnactive}
                    minWidth={minWidth}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    onClick={handleSearchSuggestPlace(suggest)}
                  >
                    <ThemeProvider theme={theme}>
                      <Fab
                        color="primary"
                        size="medium"
                        className={classes.boxShadow}
                      >
                        <img
                          src={suggest.iconUnactive}
                          height={24}
                          width={24}
                        />
                      </Fab>
                    </ThemeProvider>
                    <Typography variant="caption">
                      <Box mt={1}>
                        {suggest.name}
                      </Box>
                    </Typography>
                  </Box>
                )
              })
            }
          </Grid>

          <>
            <Grid
              className={classes.grid}
              container
            >
              {
                secondarySuggests.map((suggest, index) => {
                  return (
                    <Box
                      flexBasis="45%"
                      key={suggest.iconUnactive}
                      className={classes.cursor}
                      display="flex" p='10px'
                      alignItems="center"
                      onClick={handleSearchSuggestPlace(suggest)}
                    >
                      <ThemeProvider theme={themeSuggestSecondary}>
                        <Fab
                          color="primary"
                          size="medium"
                          className={classes.borderSecondary}
                        >
                          <img
                            src={suggest.iconUnactive}
                            height={24}
                            width={24}
                          />
                        </Fab>
                      </ThemeProvider>
                      <Typography variant="caption">
                        <Box padding={1}>
                          {suggest.name}
                        </Box>
                      </Typography>
                    </Box>
                  )
                })}
            </Grid>
          </>
        </Box>
      }
    </>
  )
}

SuggestPlace.propTypes = {
  //
};

export default SuggestPlace;