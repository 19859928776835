import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Collapse,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import { LocationCanBackState } from "../../appState";
import { CommonConfig, LinkIconConfig } from "../../config";
import { RouterConfig } from "../../config/routerConfig";
import { UrlConfig } from "../../config/urlConfig";
import { AppData } from "../../data";
import {
  AlertTypeEnum,
  CodeEnum,
  GeometryTypeEnum,
  MapEventEnum,
  PermissionEnum,
  TypeAddressEnum,
} from "../../enum";
import { Resource } from "../../resource";
import {
  ApiTool,
  AppTool,
  BusinessHourTool,
  DateTimeTool,
  LocationTool,
  PlaceTool,
  UserTool,
} from "../../tool";
import AddressInput from "../common/addressInput/addressInput";
import BuildingInput from "../common/buildingInput/buildingInput";
import BusinessHourInput from "../common/businessHourInput/businessHourInput";
import CategoryInput from "../common/categoryInput/categoryInput";
import DescriptionInput from "../common/descriptionInput/descriptionInput";
import Field from "../common/field/field";
import FieldControl from "../common/fieldControl/fieldControl";
import MetaDataInput from "../common/metaDataInput/metaDataInput";
import PhotosInput from "../common/photosInput/photosInput";
import TagInput from "../common/tagInput/tagInput";
import OldPlaceBoundaryEdit from "../oldPlaceBoundaryEdit/oldPlaceBoundaryEdit";
import { SidebarTool } from "./../../tool/sidebarTool";
import Confirm from "./../common/confirm/confirm";
import SelectFile from "./../common/selectFile/selectFile";
import EditArea from "./../editArea/editArea";
import EditRoad from "./../editRoad/editRoad";

const marginField = 24;
const paddingHeader = 16;

const ARR_KEY_VALUE = [
  "geometry",
  "name",
  "types",
  "addressComponents",
  "location",
  "mediaFiles",
  "objectId",
  "tags",
  "businessHours",
  "startDate",
  "endDate",
  "phoneNumber",
  "website",
  "description",
  "metadata",
];

const useStyles = makeStyles({
  header: {
    boxShadow: "0px 0px 2px #00000029",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: "4px",
  },
  marginBottomField: {
    marginBottom: `${marginField}px`,
  },
  marginText: {
    marginTop: `${marginField}px`,
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: "inherit",
    fontWeight: 500,
  },
});

const regexPhoneNumber = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

function RequestUpdatePlace() {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const locationCanBackState = useRecoilValue(LocationCanBackState);

  const selectFileRef = useRef();
  const detailPlaceRef = useRef();
  const markerRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [showExtend, setShowExtend] = useState(false);
  const [place, setPlace] = useState(null);
  const [position, setPosition] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disableBtnSend, setDisableBtnSend] = useState(false);

  const [imageFiles, setImageFiles] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [addressComponents, setAddressComponents] = useState([]);

  const [placeEditState, setPlaceEditState] = useState({});
  const [placeEditGeometry, setPlaceEditGeometry] = useState({});

  const schema = yup.object().shape({
    name: yup.string().required(
      Resource.formatString(Resource.validate.required, {
        field: Resource.common.name,
      })
    ),

    changeReason: yup.string().required(
      Resource.formatString(Resource.validate.required, {
        field: Resource.updatePlace.reasonUpdate,
      })
    ),

    phoneNumber: yup.string().test(
      "phoneNumberFormat",
      Resource.formatString(Resource.validate.format, {
        field: Resource.common.phoneNumber,
      }),
      function (phone) {
        if (phone && (!regexPhoneNumber.test(phone) || phone == "0000000000")) {
          return false;
        }
        return true;
      }
    ),

    inputFileComponents: yup
      .mixed().nullable()
      .test(
        "inputFileComponentsFormat",
        Resource.formatString(Resource.validate.format, {
          field: Resource.common.file,
        }),
        function (inputFileComponents) {
          let isGeojson =
            inputFileComponents?.name?.match(/([.])\w+/g)[0] === ".geojson";
          if (inputFileComponents && !isGeojson) {
            return false;
          }
          return true;
        }
      )
      .test(
        "readFileInput",
        Resource.formatString(Resource.validate.contentError, {
          field: Resource.common.file.toLocaleLowerCase(),
        }),
        async function (file) {
          if (file) {
            let json = await readFileInput(file);
            if (!json) {
              return false;
            }
          }
          return true;
        }
      ),

    website: yup.string().url(
      Resource.formatString(Resource.validate.typeError, {
        field: Resource.common.path,
        type: Resource.common.link.toLocaleLowerCase(),
      })
    ),

    addressComponents: yup.mixed().nullable().test(
      "required",
      Resource.formatString(Resource.validate.required, {
        field: Resource.place.province,
      }),
      function (addressComponents) {
        let address = addressComponents?.find((t) => {
          return t.types?.includes(TypeAddressEnum.province);
        });
        if (!address) {
          return false;
        }
        return true;
      }
    ),

    types: yup.mixed().nullable().test(
      "required",
      Resource.formatString(Resource.validate.required, {
        field: Resource.common.category,
      }),
      function (category) {
        if (category?.length == 0) {
          return false;
        }
        return true;
      }
    ),

    lat: yup
      .number()
      .required(
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.lat,
        })
      )
      .min(
        -90,
        Resource.formatString(Resource.validate.min, {
          field: Resource.common.lat,
          min: -90,
        })
      )
      .max(
        90,
        Resource.formatString(Resource.validate.max, {
          field: Resource.common.lat,
          max: 90,
        })
      )
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(
        Resource.formatString(Resource.validate.typeError, {
          field: Resource.common.lat,
          type: Resource.common.number.toLowerCase(),
        })
      ),

    lng: yup
      .number()
      .required(
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.lng,
        })
      )
      .min(
        -180,
        Resource.formatString(Resource.validate.min, {
          field: Resource.common.lng,
          min: -180,
        })
      )
      .max(
        180,
        Resource.formatString(Resource.validate.max, {
          field: Resource.common.lng,
          max: 180,
        })
      )
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(
        Resource.formatString(Resource.validate.typeError, {
          field: Resource.common.lng,
          type: Resource.common.number.toLowerCase(),
        })
      ),
  });

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema),
  });

  register({ name: "lat", type: "custom" });
  register({ name: "lng", type: "custom" });
  register({ name: "addressComponents", type: "custom" });
  register({ name: "geometry", type: "custom" });
  register({ name: "inputFileComponents", type: "custom" });
  register({ name: "types", type: "custom" });
  register({ name: "tags", type: "custom" });
  register({ name: "objectId", type: "custom" });
  register({ name: "businessHours", type: "custom" });
  register({ name: "description", type: "custom" });
  register({ name: "mediaFiles", type: "custom" });
  register({ name: "metadata", type: "custom" });

  const handleCloseUpdate = () => {
    if (locationCanBackState) {
      history.goBack();
    } else {
      history.push({
        pathname: RouterConfig.home,
      });
    }
  };

  const handleShowExtend = () => {
    setShowExtend(!showExtend);
  };

  useEffect(() => {
    setValue("lat", position?.lat, { shouldValidate: formState.isSubmitted });
    setValue("lng", position?.lng, { shouldValidate: formState.isSubmitted });
  }, [position]);

  useEffect(() => {
    var sourcePlace;
    SidebarTool.setOpen(true);
    if (id) {
      setLoading(true);
      sourcePlace = ApiTool.get(
        UrlConfig.poi.detail.replace("{id}", id),
        (res) => {
          setLoading(false);
          if (res?.code == CodeEnum.ok) {
            let placeNew = res?.result;
            detailPlaceRef.current = { ...res?.result } || {};
            let tags = [];
            placeNew?.tags?.forEach((tag) => {
              tags.push({
                name: tag,
              });
            });
            placeNew.tags = tags;
            setPlace(placeNew);
            setPosition(placeNew.location);
            placeNew?.startDate && setStartDate(new Date(placeNew?.startDate));
            placeNew?.endDate && setEndDate(new Date(placeNew?.endDate));
            placeNew?.geometry &&
              setPlaceEditGeometry(placeNew?.geometry) &&
              setValue("geometry", placeNew?.geometry, {
                shouldValidate: formState.isSubmitted,
              });
            setAddressComponents(JSON.parse(JSON.stringify(placeNew.addressComponents || [])));
            setValue("description", placeNew?.description);
            setValue("metadata", placeNew?.metadata);
            setValue("businessHours", placeNew?.businessHours);
            setValue("mediaFiles", placeNew?.mediaFiles);
            setValue("types", placeNew?.types)
          } else {
            setPlace(null);
          }
        }
      );
    }
    return () => {
      sourcePlace?.cancel();
    };
  }, [id]);

  useEffect(() => {
    if (LocationTool.isValid(position)) {
      if (!markerRef.current) {
        markerRef.current = new map4d.Marker({
          position: position,
          anchor: [0.5, 1],
          draggable: true,
          icon: new map4d.Icon(30, 42, LinkIconConfig.placeDetail.marker),
          zIndex: 1000,
          labelAnchor: [1.2, 0.5],
          label: new map4d.MarkerLabel({
            text: place?.name,
            color: "FF0000",
            fontSize: 14,
            anchor: [0, 0.5],
            fontWeight: "bold",
          }),
        });
        markerRef.current.setMap(AppData.map);
        markerRef.current.setUserData({
          type: "locationMarker",
        });
      }
      markerRef.current?.setPosition(position);
    }
  }, [position, place?.name]);

  useEffect(() => {
    const isEditBoundaries =
      PlaceTool.isAreaOrStreet(place) &&
      UserTool.isPermission(PermissionEnum.boundariesEditRequest);
    if (isEditBoundaries && place) {
      selectFileRef.current?.scrollIntoView(true);
    }
  }, [place]);

  useEffect(() => {
    setValue("addressComponents", addressComponents, {
      shouldValidate: formState.isSubmitted,
    });
  }, [addressComponents]);

  useEffect(() => {
    const eventDragMarker = AppData.map.addListener(
      MapEventEnum.dragEnd,
      (args) => {
        const { type } = args.marker.getUserData();
        if (type === "locationMarker") {
          setPosition(args.marker.getPosition());
        }
      },
      { marker: true }
    );
    return () => {
      eventDragMarker?.remove();
      markerRef.current?.setMap(null);
    };
  }, []);

  const onChangeAddress = (value) => {
    setAddressComponents(value);
  };

  const readFileInput = (file) => {
    return new Promise((resolve) => {
      var fileReader = new FileReader();
      let objContent;
      fileReader.onload = (evt) => {
        let content = evt?.target?.result;
        try {
          objContent = JSON.parse(content);
          resolve(objContent);
        } catch (error) {
          resolve(false);
        }
      };
      fileReader.readAsText(file);
    });
  };

  const onChangeFileInput = async (value) => {
    if (value) {
      let objGeometry = await readFileInput(value);
      if (objGeometry?.features) {
        setPlaceEditGeometry(objGeometry.features[0].geometry);
        setPlaceEditState(objGeometry.features[0].geometry);
      } else if (objGeometry?.coordinates) {
        setPlaceEditGeometry(objGeometry);
        setPlaceEditState(objGeometry);
      } else {
        setPlaceEditGeometry(place?.geometry || {});
        setPlaceEditState(place?.geometry || {});
        setValue("geometry", place?.geometry, {
          shouldValidate: formState.isSubmitted,
        });
      }
      setValue("geometry", objGeometry, {
        shouldValidate: formState.isSubmitted,
      });
    } else {
      setPlaceEditGeometry(place?.geometry || {});
      setPlaceEditState(place?.geometry || {});
      setValue("geometry", place?.geometry, {
        shouldValidate: formState.isSubmitted,
      });
    }
    setValue("inputFileComponents", value, { shouldValidate: true });
  };

  const onChangeCategory = (category) => {
    let types = category?.map((t) => {
      return t?.id;
    });
    setValue("types", types, { shouldValidate: formState.isSubmitted });
  };

  const onChangeTag = (tags) => {
    let newTag = tags?.map((tg) => {
      return tg?.name;
    });
    setValue("tags", newTag, { shouldValidate: formState.isSubmitted });
  };

  const onChangeObject = (object) => {
    setValue("objectId", object?.id || null, {
      shouldValidate: formState.isSubmitted,
    });
  };

  const onChangeBusinessHours = (businessHours) => {
    setValue(
      "businessHours",
      BusinessHourTool.convertBusinessHour(businessHours),
      { shouldValidate: formState.isSubmitted }
    );
  };

  const onChangeDescription = (description) => {
    // if (description == `<p></p>\n` || description == `<p></p>`) {
    //   description = null;
    // }
    setValue("description", description, {
      shouldValidate: formState.isSubmitted,
    });
  };

  const onChangePhoto = (photos, imageFiles) => {
    setImageFiles(imageFiles);
    setPhotos(photos);
    setValue("mediaFiles", photos, { shouldValidate: formState.isSubmitted });
  };

  const onChangeMetaData = (metadata) => {
    setValue("metadata", metadata, { shouldValidate: formState.isSubmitted });
  };

  const onChangeLat = (e) => {
    let value = e.target.value;
    setPosition((prev) => {
      let newPosition = {
        ...prev,
        lat: value,
      };
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera();
        camera.setTarget(newPosition);
        AppData.map.moveCamera(camera, { animate: true });
      }
      return newPosition;
    });
  };

  const onChangeLng = (e) => {
    let value = e.target.value;
    setPosition((prev) => {
      let newPosition = {
        ...prev,
        lng: value,
      };
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera();
        camera.setTarget(newPosition);
        AppData.map.moveCamera(camera, { animate: true });
      }
      return newPosition;
    });
  };

  const onChangeStartDate = (date) => {
    setStartDate(DateTimeTool.getDateWithStartTime(date));
  };

  const onChangeEndDate = (date) => {
    setEndDate(DateTimeTool.getDateWithEndTime(date));
  };

  const sendRequest = (data) => {
    let updatedData = {
      ...data,
      geometry:
        Object.keys(placeEditState).length !== 0
          ? placeEditState
          : data?.geometry
            ? data?.geometry
            : place?.geometry,
      startDate: startDate?.getTime(),
      endDate: endDate?.getTime(),
      location: position,
      placeId: place?.id,
      phoneNumber: data?.phoneNumber,
      website: data?.website,
      mainType: data.types[0],
      auxiliaryTypes: data.types?.slice(1)
    };
    let body = PlaceTool.createRequestPoiV4(
      detailPlaceRef?.current,
      updatedData,
    );
    setDisableBtnSend(false);
    if (body) {
      body.changeReason = data?.changeReason;
      body.placeId = place?.id
      ApiTool.post(
        UrlConfig.poiEdit.create,
        body,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            AppTool.alert(
              Resource.formatString(Resource.alert.success.requestUpdate, {
                object: labelType.toLowerCase(),
              }),
              AlertTypeEnum.success
            );
            handleCloseUpdate();
          } else {
            AppTool.alert(
              Resource.formatString(Resource.alert.error.requestUpdate, {
                object: labelType.toLowerCase(),
              }),
              AlertTypeEnum.error
            );
          }
        },
        true
      );
    } else {
      AppTool.alert(Resource.message.notUpdatedInfo, AlertTypeEnum.error);
    }
  };

  const onSubmit = (data) => {
    setDisableBtnSend(true);
    if (imageFiles?.length > 0) {
      let body = {
        files: imageFiles,
      };
      ApiTool.postFormFromJson(
        UrlConfig.file.listMedia,
        body,
        (res) => {
          if (res?.code == CodeEnum.ok) {
            data.mediaFiles = photos?.concat(res?.result);
            sendRequest(data);
          } else {
            setDisableBtnSend(false);
            AppTool.alert(
              Resource.formatString(Resource.alert.error.edit, {
                object: Resource.common.images.toLowerCase(),
              }),
              AlertTypeEnum.error
            );
          }
        },
        true
      );
    } else {
      sendRequest(data);
    }
  };

  const isEditBoundaries =
    PlaceTool.isAreaOrStreet(place) &&
    UserTool.isPermission(PermissionEnum.boundariesEditRequest);

  const test = UserTool.isPermission(PermissionEnum.boundariesEditRequest);
  const test2 = UserTool.isAdmin();

  const handleChangeGeometry = (value) => {
    setPlaceEditState(value);
  };

  let labelType =
    place?.geometry?.type == GeometryTypeEnum.lineString ||
      place?.geometry?.type == GeometryTypeEnum.multiLineString
      ? Resource.common.street
      : place?.geometry?.type == GeometryTypeEnum.polygon ||
        place?.geometry?.type == GeometryTypeEnum.multiPolygon
        ? Resource.common.area
        : Resource.common.place || "";

  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor="background.paper"
      display="flex"
      flexDirection="column"
    >
      {loading ? (
        <>
          <Box className={classes.header}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height="70px"
            />
          </Box>
          <Divider />
          <Box overflow="auto" flexGrow="1">
            <Box height="100%" display="flex" flexDirection="column">
              <Box overflow="auto" flexGrow="1" style={{ overflowX: "hidden" }}>
                {Array.from({ length: 10 }, (_, i) => i).map((item, index) => {
                  return (
                    <Box p={2} key={index}>
                      <Field
                        label=""
                        disableMarginTop
                        icon={
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            width="24px"
                            height="24px"
                          />
                        }
                      >
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="100%"
                          height="50px"
                          style={{ borderRadius: "4px" }}
                        />
                      </Field>
                    </Box>
                  );
                })}
              </Box>
              <Box
                display="flex"
                justifyContent="space-evenly"
                p={3}
                borderTop={1}
                borderColor="grey.200"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80px"
                  height="40px"
                  style={{ borderRadius: "4px" }}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80px"
                  height="40px"
                  style={{ borderRadius: "4px" }}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : place ? (
        <>
          <Box className={classes.header}>
            <Box textAlign="center" p={`${paddingHeader}px`}>
              <Typography variant="h6">
                {Resource.updatePlace.requestUpdate}
              </Typography>
              {place?.name && (
                <Typography variant="caption">{place?.name}</Typography>
              )}
            </Box>
            {isEditBoundaries &&
              (placeEditGeometry?.type === GeometryTypeEnum?.multiLineString ||
                placeEditGeometry?.type === GeometryTypeEnum?.lineString) && (
                <EditRoad
                  geometry={placeEditGeometry}
                  onChange={handleChangeGeometry}
                />
              )}

            {isEditBoundaries &&
              (placeEditGeometry?.type === GeometryTypeEnum?.multiPolygon ||
                placeEditGeometry?.type === GeometryTypeEnum?.polygon) && (
                <EditArea
                  geometry={placeEditGeometry}
                  onChange={handleChangeGeometry}
                />
              )}

            <OldPlaceBoundaryEdit
              geometry={place.geometry}
              isEditBoundaries={isEditBoundaries}
            />
          </Box>
          <Divider />
          <Box overflow="auto" flexGrow="1">
            <form
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                p={`${marginField}px`}
                flexGrow="1"
                overflow="auto"
                style={{ overflowX: "hidden" }}
              >
                <Box>
                  <Field
                    required
                    label={Resource.updatePlace.name}
                    icon={
                      <img
                        src={LinkIconConfig.requestUpdatePlace.placeName}
                        width={24}
                        height={24}
                      />
                    }
                  >
                    <TextField
                      name="name"
                      label={Resource.updatePlace.addName}
                      placeholder={Resource.updatePlace.addName}
                      variant="outlined"
                      defaultValue={place?.name}
                      inputRef={register}
                      error={errors.name ? true : false}
                      helperText={errors?.name?.message}
                    />
                  </Field>

                  <Field
                    required
                    error={errors.types ? true : false}
                    helperText={errors?.types?.message}
                    label={Resource.updatePlace.category}
                    icon={
                      <img
                        src={LinkIconConfig.requestUpdatePlace.category}
                        width={24}
                        height={24}
                      />
                    }
                  >
                    <CategoryInput
                      error={errors.types ? true : false}
                      defaultValue={place?.placeTypes}
                      onChange={onChangeCategory}
                    />
                  </Field>

                  {!isEditBoundaries && (
                    <>
                      <Field
                        label={Resource.common.lat}
                        icon={
                          <img
                            src={LinkIconConfig.common.lat}
                            width={24}
                            height={24}
                          />
                        }
                        required
                      >
                        <TextField
                          value={
                            position?.lat || position?.lat == 0
                              ? position?.lat
                              : ""
                          }
                          onChange={onChangeLat}
                          inputProps={{
                            step: CommonConfig.lng.step,
                          }}
                          label={Resource.lat.add}
                          placeholder={Resource.lat.add}
                          variant="outlined"
                          type="number"
                          name="lat"
                          error={errors.lat ? true : false}
                          helperText={errors.lat && errors.lat.message}
                        />
                      </Field>

                      <Field
                        label={Resource.common.lng}
                        icon={
                          <img
                            src={LinkIconConfig.common.lng}
                            width={24}
                            height={24}
                          />
                        }
                        required
                      >
                        <TextField
                          value={
                            position?.lng || position?.lng == 0
                              ? position?.lng
                              : ""
                          }
                          onChange={onChangeLng}
                          inputProps={{
                            step: CommonConfig.lng.step,
                          }}
                          label={Resource.lng.add}
                          placeholder={Resource.lng.add}
                          variant="outlined"
                          type="number"
                          name="lng"
                          error={errors.lng ? true : false}
                          helperText={errors.lng && errors.lng.message}
                        />
                      </Field>
                    </>
                  )}

                  {!isEditBoundaries && position && (
                    <Field
                      required
                      label={Resource.updatePlace.location}
                      icon={
                        <img
                          src={LinkIconConfig.requestUpdatePlace.location}
                          width={24}
                          height={24}
                        />
                      }
                    >
                      <AddressInput
                        position={position}
                        value={addressComponents}
                        onChange={onChangeAddress}
                        error={errors.addressComponents ? true : false}
                        helperText={errors?.addressComponents?.message}
                      />
                    </Field>
                  )}
                  <Field
                    label={Resource.updatePlace.tag}
                    icon={
                      <img
                        src={LinkIconConfig.requestUpdatePlace.label}
                        width={24}
                        height={24}
                      />
                    }
                  >
                    <TagInput
                      defaultValue={place?.tags}
                      onChange={onChangeTag}
                    />
                  </Field>

                  {!isEditBoundaries && (
                    <>
                      <Field
                        label={Resource.updatePlace.building}
                        icon={
                          <img
                            src={LinkIconConfig.requestUpdatePlace.object}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <BuildingInput
                          defaultValue={place?.object}
                          onChange={onChangeObject}
                          location={position}
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.timeOpen}
                        icon={
                          <img
                            src={LinkIconConfig.requestUpdatePlace.clock}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <BusinessHourInput
                          defaultValue={BusinessHourTool.generateBusinessHourObject(
                            place?.businessHours
                          )}
                          onChange={onChangeBusinessHours}
                        />
                      </Field>

                      <Field
                        label={Resource.common.startDate}
                        icon={
                          <img
                            src={LinkIconConfig.common.startDate}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <DatePicker
                          label={Resource.startDate.choose}
                          inputVariant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          clearable
                          value={startDate}
                          maxDate={endDate || CommonConfig.maxDate}
                          onChange={onChangeStartDate}
                          format="dd/MM/yyyy"
                        />
                      </Field>

                      <Field
                        label={Resource.common.endDate}
                        icon={
                          <img
                            src={LinkIconConfig.common.endDate}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <DatePicker
                          label={Resource.endDate.choose}
                          inputVariant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          clearable
                          value={endDate}
                          minDate={startDate || CommonConfig.minDate}
                          onChange={onChangeEndDate}
                          format="dd/MM/yyyy"
                        />
                      </Field>

                      <Field
                        label={Resource.updatePlace.contact}
                        icon={
                          <img
                            src={LinkIconConfig.requestUpdatePlace.contact}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <TextField
                          name="phoneNumber"
                          inputRef={register}
                          label={Resource.updatePlace.addPhoneNumber}
                          variant="outlined"
                          defaultValue={place?.phoneNumber}
                          error={errors.phoneNumber ? true : false}
                          helperText={errors.phoneNumber?.message}
                        />
                      </Field>
                      <Field
                        label={Resource.updatePlace.web}
                        icon={
                          <img
                            src={LinkIconConfig.requestUpdatePlace.website}
                            width={24}
                            height={24}
                          />
                        }
                      >
                        <TextField
                          name="website"
                          inputRef={register}
                          error={errors.website ? true : false}
                          helperText={errors?.website?.message}
                          label={Resource.updatePlace.addWebsite}
                          variant="outlined"
                          defaultValue={place?.website}
                        />
                      </Field>
                    </>
                  )}
                  {isEditBoundaries && (
                    <div ref={selectFileRef}>
                      <Field
                        label={Resource.updatePlace.stringJson}
                        icon={
                          <img
                            src={LinkIconConfig.requestUpdatePlace.json}
                            width={24}
                            height={24}
                          />
                        }
                        isNowrapText
                        error={
                          errors.inputFileComponents?.message ? true : false
                        }
                        helperText={errors?.inputFileComponents?.message}
                      >
                        <SelectFile
                          label={Resource.common.nameFile}
                          dragtext={Resource.common.dragtext}
                          onChange={onChangeFileInput}
                        />
                      </Field>
                    </div>
                  )}

                  <Field
                    label={Resource.updatePlace.description}
                    icon={
                      <img
                        src={LinkIconConfig.requestUpdatePlace.description}
                        width={24}
                        height={24}
                      />
                    }
                  >
                    <DescriptionInput
                      value={place?.description}
                      onChange={onChangeDescription}
                    />
                    {/* <FieldControl
                      label={Resource.updatePlace.addDescriptionForLocation}
                    >
                      <DescriptionInput
                        value={place?.description}
                        onChange={onChangeDescription}
                      />
                    </FieldControl> */}
                  </Field>

                  <Field
                    label={Resource.updatePlace.reasonUpdate}
                    icon={
                      <img
                        src={LinkIconConfig.requestUpdatePlace.reason}
                        width={24}
                        height={24}
                      />
                    }
                    required
                  >
                    <TextField
                      name="changeReason"
                      inputRef={register}
                      error={errors.changeReason ? true : false}
                      helperText={errors?.changeReason?.message}
                      label={Resource.updatePlace.addReason}
                      variant="outlined"
                      defaultValue={""}
                    />
                  </Field>

                  <Field
                    icon={<Box width="24px"></Box>}
                    label=""
                    disableMarginTop
                  >
                    <PhotosInput
                      defaultValue={place?.mediaFiles}
                      onChange={onChangePhoto}
                    />
                    <Typography
                      className={classes.marginText}
                      variant="caption"
                    >
                      {Resource.updatePlace.descriptionPhotoFeild}
                    </Typography>
                  </Field>
                </Box>

                <Box>
                  <Button
                    className={classes.extendBtn}
                    fullWidth
                    variant="text"
                    onClick={handleShowExtend}
                    endIcon={
                      showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                  >
                    {Resource.updatePlace.addExtendBtn}
                  </Button>
                  <Collapse in={showExtend} timeout={600}>
                    <MetaDataInput
                      value={place?.metadata}
                      onChange={onChangeMetaData}
                    />
                  </Collapse>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-evenly"
                p={3}
                borderTop={1}
                borderColor="grey.200"
              >
                <Confirm
                  message={Resource.formatString(
                    Resource.confirmMessage.cancelRequestUpdate,
                    {
                      object: labelType.toLowerCase(),
                    }
                  )}
                  onConfirm={handleCloseUpdate}
                >
                  <Button>{Resource.button.cancel}</Button>
                </Confirm>
                <Button
                  disabled={disableBtnSend}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {Resource.button.send}
                </Button>
              </Box>
            </form>
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
}

RequestUpdatePlace.propTypes = {
  //
};

export default RequestUpdatePlace;
