import { Box, Button, Dialog, DialogActions, DialogContent, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import { DatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { AppData } from '../../data';
import { CookieEnum } from '../../enum';
import { Resource } from '../../resource';
import { DateTimeTool, CookieTool } from '../../tool';
import { useIsMobileScreen } from '../../useHooks/useIsMobileScreen';

const useStyles = makeStyles({
  padding: {
    padding: '10px 24px',
  },
  dialog: {
    width: '400px'
  },
  datePicker: {
    '& > .MuiFormControl-root': {
      width: '100%'
    }
  }
})
function TimeLineSetting() {
  const classes = useStyles()
  const fullScreen = useIsMobileScreen()
  const [show, setShow] = useState(false)
  const currentDate = new Date()

  const [timeCookie, setTimeCookie] = useState(() => {
    let timeCookieString = CookieTool.get(CookieEnum.timeLine)
    let timeCookie = timeCookieString ? new Date(timeCookieString) : currentDate
    return timeCookie
  })


  const [selectedDate, setSelectedDate] = useState(timeCookie)

  const handleShowTimeLineSetting = () => {
    setSelectedDate(timeCookie)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
  }

  const handleOnClickCurrentDate = () => {
    AppData.map.setDate(null)
    setSelectedDate(currentDate)
  }

  const handleApply = () => {
    let start = DateTimeTool.getDateWithStartMonth(selectedDate)
    AppData.map.setDate(start)
    CookieTool.set(CookieEnum.timeLine, start)
    setTimeCookie(start)
    setShow(false)
  }


  let textTimeCurrent = (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear()
  let textTimeSelect = timeCookie ? (timeCookie.getMonth() + 1) + '/' + timeCookie.getFullYear() : textTimeCurrent
  return (
    <>
      <ListItem button className={classes.padding} onClick={handleShowTimeLineSetting}>
        <ListItemIcon>
          <TimelineIcon color='primary' />
        </ListItemIcon>
        <ListItemText>
          <Typography variant='subtitle2'>{`${Resource.common.time} ( ${textTimeSelect} )`}</Typography>
        </ListItemText>
      </ListItem>

      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={
          {
            paper: (!fullScreen) ? classes.dialog : ""
          }
        }
      >
        <DialogContent dividers>
          <Button fullWidth variant='contained' color='primary' onClick={handleOnClickCurrentDate}>
            <Typography variant='body2'>{`${Resource.common.current}: ( ${textTimeCurrent} )`}</Typography>
          </Button>
          <Box padding='20px 0' className={classes.datePicker}>
            <Typography variant='body2' gutterBottom>{Resource.common.displayMapDataOverTime}</Typography>
            <DatePicker
              inputVariant="standard"
              views={["year", "month"]}
              clearable
              value={selectedDate}
              InputLabelProps={
                {
                  shrink: true
                }
              }
              format="MM/yyyy"
              onChange={handleDateChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {Resource.common.cancel}
          </Button>
          <Button color="primary" onClick={handleApply}>
            {Resource.common.apply}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

TimeLineSetting.propTypes = {
  //
};

export default TimeLineSetting;
