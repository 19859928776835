import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { AppData } from '../../../data';
import { MapEventEnum } from '../../../enum';
import { RouterConfig } from '../../../config/routerConfig';

function MobileObject3dControl() {
  const history = useHistory()

  useEffect(() => {
    const eventClickPoi = AppData.map.addListener(MapEventEnum.click, (args) => {
      let obj = args.building;
      AppData.map?.setSelectedBuildings([obj.id])
      history.push({
        pathname: RouterConfig.object3d.detail.replace(":id", obj?.id),
        state: {
          disableMoveMap: true
        }
      })
    }, { mapbuilding: true })
    return () => {
      eventClickPoi?.remove()
      // AppData.map?.setSelectedBuildings([])
    }
  }, [])

  return (
    null
  )
}

MobileObject3dControl.propTypes = {
  //
};

export default MobileObject3dControl;
