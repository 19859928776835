import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'ui';
import { BusinessHourTool } from '../../tool';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import DialogHeader from '../dialogHeader/dialogHeader';
import TextView from '../textView/textView';
import UpdateBusinessHourV3 from '../updateBusinessHourV3/updateBusinessHourV3';
import './editBusinessHourV3.scss';

const arrDayOfWeek = [
  'Thứ Hai',
  'Thứ Ba',
  'Thứ Tư',
  'Thứ Năm',
  'Thứ Sáu',
  'Thứ Bảy',
  'Chủ nhật',
]


function EditBusinessHoursV3(props) {

  const { defaultValue, onClose, onChange, onSave, onBack } = props
  const [show, setShow] = useState(false)
  const [stateValue, setStateValue] = useState(defaultValue || [])
  const oldBusinessHourRef = useRef(BusinessHourTool.generateToText2(defaultValue) || {})
  const currBusinessHourRef = useRef(BusinessHourTool.generateToText2(defaultValue) || {})
  const [selectDays, setSelectDays] = useState([])
  const [edited, setEdited] = useState(false)

  useEffect(() => {
  }, [edited])

  const handleChangeBusinessHour = (value) => {
    currBusinessHourRef.current = BusinessHourTool.generateToText2(value)
    setStateValue(value)
    setEdited(!edited)
  }
  const onEditBusinessHour = (value) => {
    setShow(true)
    let day = value == 6 ? 0 : value + 1
    setSelectDays([day])
  }
  const handleCloseDialog = () => {
    setShow(false)
    setSelectDays([])
  }
  const hanldeEditAllBusinessHour = () => {
    setShow(true)
    setSelectDays([1, 2, 3, 4, 5, 6, 0])
  }
  const handleCancel = () => {
    onClose && onClose()
  }
  const handleSave = () => {
    onChange && onChange(stateValue)
    onSave && onSave()
  }

  const showText = (type, texts) => {
    if (type == 'openAllDay') {
      return <div><TextView style={{ textAlign: 'end' }}>{'Mở cửa cả ngày'}</TextView></div>
    }
    else if (type == 'closed') {
      return <div><TextView style={{ textAlign: 'end' }}>{'Đóng cửa'}</TextView></div>
    }
    else if (type == 'none') {
      return <div><TextView style={{ textAlign: 'end' }}>{'Không có thông tin'}</TextView></div>
    }
    else if (type == 'opened') {
      return <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>{texts.map((value, index) => <TextView style={{ textAlign: 'end' }} key={index}>{value}</TextView>)}</div>
    }
  }
  return (
    <>
      {
        <Dialog
          open={true}
          onClose={onClose}
          onBack={onBack}
          disableCloseOnClickBackdrop
          className='editBusinessHour'
        >
          <DialogHeader
            hideClose
            showBack
          >
            <TextView
              style={{ fontSize: '1.25rem', color: '#121212', fontWeight: 600 }}
            >
              Giờ mở cửa
            </TextView>
          </DialogHeader>

          <DialogBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: '0 1.5rem' }}>
            {Object.keys(oldBusinessHourRef.current).map((key) => {
              return (
                <div
                  key={key}
                  className='listItem'
                >
                  <TextView style={{ fontSize: '0.875rem', color: '#121212', }}>
                    {arrDayOfWeek[key - 2]}
                  </TextView>
                  <div style={{ display: 'flex', }}>
                    <div>
                      {
                        (`${currBusinessHourRef.current[key].type + currBusinessHourRef.current[key].text}` != `${oldBusinessHourRef.current[key].type + oldBusinessHourRef.current[key].text}`) &&
                        <div style={{ textDecoration: 'line-through', fontSize: '0.875rem', color: '#9C9C9C', marginBottom: '0.5rem' }}>
                          {showText(oldBusinessHourRef.current[key].type, oldBusinessHourRef.current[key].texts)}
                        </div>
                      }
                      <div style={{ display: 'flex', alignItems: 'self-end', fontSize: '0.875rem', color: '#121212', justifyContent: 'end', }}>{
                        showText(currBusinessHourRef.current[key].type, currBusinessHourRef.current[key].texts)
                      }</div>
                    </div>
                    <div onClick={() => {
                      onEditBusinessHour(key - 2)
                    }} style={{ cursor: 'pointer', marginLeft: '1.688rem', display: 'flex', alignItems: 'center' }}>
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0,0H24V24H0Z" fill="none" />
                        <path d="M15.728,9.686,14.314,8.272,5,17.586V19H6.414l9.314-9.314Zm1.414-1.414,1.414-1.414L17.142,5.444,15.728,6.858ZM7.242,21H3V16.757L16.435,3.322a1,1,0,0,1,1.414,0l2.829,2.829a1,1,0,0,1,0,1.414L7.243,21Z" fill="#869195" />
                      </svg>
                    </div>
                  </div>
                </div>
              )
            })}
            <Button variant="cancel" style={{ margin: '1.5rem 1rem 2.875rem 0' }} onClick={hanldeEditAllBusinessHour}>
              <svg style={{ marginRight: '0.75rem' }} width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(-404 -442)">
                  <path d="M5,19H6.414l9.314-9.314L14.314,8.272,5,17.586Zm16,2H3V16.757L16.435,3.322a1,1,0,0,1,1.414,0l2.829,2.829a1,1,0,0,1,0,1.414L9.243,19H21ZM15.728,6.858l1.414,1.414,1.414-1.414L17.142,5.444,15.728,6.858Z" transform="translate(404 441.985)" fill="#1c75bc" />
                </g>
              </svg>
              Chỉnh sửa tất cả các giờ
            </Button>
          </DialogBody>
          <DialogAction>
            <Button variant="cancel" onClick={handleCancel} style={{ marginRight: '0.75rem' }}>Hủy</Button>
            {
              // edited &&
              <Button variant="done" onClick={handleSave}>Xong</Button>
            }
          </DialogAction>
        </Dialog>
      }
      {
        show &&
        <UpdateBusinessHourV3 value={stateValue} selectDays={selectDays} onClose={handleCloseDialog} onChange={handleChangeBusinessHour} />
      }
    </>
  )
}
EditBusinessHoursV3.prototype = {

}

export default EditBusinessHoursV3;
