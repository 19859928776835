import PropTypes from 'prop-types';
import React from 'react';
import TooltipV2 from '../../../components/tooltipV2/tooltipV2';
import { Resource } from '../../../resource';
import { StringTool } from '../../../tool';
import './mobilePhoneButton.scss';

const ICON_PHONE =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M9.366,10.682a10.556,10.556,0,0,0,3.952,3.952L14.2,13.4a1,1,0,0,1,1.294-.3,11.422,11.422,0,0,0,4.583,1.364,1,1,0,0,1,.921,1v4.462a1,1,0,0,1-.9,1A15.509,15.509,0,0,1,3.082,3.9a1,1,0,0,1,1-.9H8.539a1,1,0,0,1,1,.921A11.422,11.422,0,0,0,10.9,8.5,1,1,0,0,1,10.6,9.8l-1.238.884Zm-2.522-.657,1.9-1.357A13.41,13.41,0,0,1,7.647,5H5.01C5,5.166,5,5.333,5,5.5A13.5,13.5,0,0,0,18.5,19c.167,0,.334,0,.5-.01V16.353a13.41,13.41,0,0,1-3.668-1.1l-1.357,1.9a12.442,12.442,0,0,1-1.588-.75l-.058-.033a12.556,12.556,0,0,1-4.7-4.7l-.033-.058a12.442,12.442,0,0,1-.75-1.588Z" fill="#1c75bc" />
  </svg>

const SIZE = 36
function MobilePhoneButton({ phoneNumber, size = SIZE, isEllipse }) {

  const handleCall = (e) => {
    window.open(`tel:${phoneNumber}`)
  }

  return (
    <TooltipV2 description={Resource.common.call} anchor='top'>
      <div className={StringTool.mergeClassName('mobilePhoneButtonCpn', isEllipse ? 'isEllipse' : '')}>
        <div
          className='circleBtn'
          onClick={handleCall}
          style={{
            borderRadius: size / 2
          }}

        >
          <div
            className='icon'
            style={{
              width: size,
              height: size,
            }}>
            {ICON_PHONE}
          </div>

          <div className='caption2'>
            <div>
              {Resource.common.call}
            </div>

          </div>
        </div>
        <div className='caption'>
          <div>
            {Resource.common.call}
          </div>
        </div>

      </div>
    </TooltipV2>
  )
}

MobilePhoneButton.propTypes = {
  isEllipse: PropTypes.bool,
  latlng: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
};

export default MobilePhoneButton;
