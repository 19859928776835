import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LinkIconConfig, RouterConfig } from '../../../config';
import { Resource } from '../../../resource';
import { UrlTool } from '../../../tool';
import "./mobileSuggestPlace.scss";

function MobileSuggestPlace() {
  const history = useHistory()

  const mainOptions = [
    {
      name: Resource.common.restaurant,
      color: "#50C1AB",
      icon: LinkIconConfig.nearByOption.restaurant,
      type: 'restaurant'
    },
    {
      name: Resource.common.hotel,
      color: "#4A9AE4",
      icon: LinkIconConfig.nearByOption.hotel,
      type: 'hotel'
    },
    {
      name: Resource.common.gasStation,
      color: "#E5383B",
      icon: LinkIconConfig.nearByOption.gasStation,
      type: 'gas_station'
    },
    {
      name: Resource.common.park,
      color: "#EBA121",
      icon: LinkIconConfig.nearByOption.parking,
      type: 'parking'
    },
  ]

  const [showMore, setShowMore] = useState(false)

  const onShowHide = () => {
    setShowMore((prev) => {
      return !prev
    })
  }

  const onClickItemOption = (suggest) => (e) => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: suggest.name, type:suggest?.type })
    })
  }

  return (
    <div className='mobileSuggestPlaceCpn'>
      <div className='mainOptions'>
        {
          mainOptions?.map((option, index) => {
            let stylesOption = {
              backgroundColor: option?.color
            }
            return (
              option?.isShowMore ?
                <div className='mainOptionItem' key={index} onClick={onShowHide}>
                  <div
                    className='fabOption'
                    style={stylesOption}
                  >
                    <img src={!showMore ? option?.icon : option?.showIcon} alt='icon type' />
                  </div>
                  <div className='nameOption'>
                    {!showMore ? option?.name : option?.showName}
                  </div>
                </div>
                :
                <div className='mainOptionItem' key={index} onClick={onClickItemOption(option)}>
                  <div
                    className='fabOption'
                    style={stylesOption}
                  >
                    <img src={option?.icon} alt='icon type' />
                  </div>
                  <div className='nameOption'>
                    {option?.name}
                  </div>
                </div>
            )
          })
        }
      </div>
    </div>
  )
};

MobileSuggestPlace.propTypes = {
  pointName: PropTypes.string,
  onChange: PropTypes.func
}

export default MobileSuggestPlace;
