import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import "./multiInput.scss"
import { Chip } from 'ui';

const ICON_OPEN = <svg id="Component_788_6" data-name="Component 788 – 6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_8067" data-name="Path 8067" d="M0,24H24V0H0Z" fill="none" />
  <path id="Path_8068" data-name="Path 8068" d="M12,9.343,7.757,13.586,9.172,15,12,12.171,14.828,15l1.415-1.414Z" transform="translate(24.172 -0.172) rotate(90)" fill="#869195" />
</svg>

const MultiInput = (props) => {
  const { label, placeholder, required, onClick, value, onChange, error, helperText, inputRef } = props
  const [valueState, setValueState] = useState();
  const [valueHeight, setValueHeight] = useState()
  const valueRef = useRef()

  useEffect(() => {
    if (valueState) {
      const boundingRect = valueRef.current.getBoundingClientRect();
      const { height } = boundingRect;
      setValueHeight(height);
    }
  }, [valueState]);
  useEffect(() => {
    if (value) {
      setValueState(value)
    }
  }, [value])

  const handleClick = (e) => {
    onClick && onClick(e);
  };
  const handleDeleteItem = (item) => (e) => {
    const newState = [...valueState].filter((value) => value.id !== item.id);
    setValueState(newState);
    onChange(newState);
  }

  const handleNotChange = (e) => {
    e.stopPropagation();
  }

  return (
    <div className='multiInputCpn' ref={inputRef}>
      <div className='multiInputContainer' onClick={handleClick} style={{ height: `${valueHeight > 48 ? `${(valueHeight + 18)}px` : ''}` }}>
        <div className='valueInputCpn' ref= {valueRef} style={{ top: `${valueHeight > 48 ? '8px' : '0px'}` }}>
          
            {
              Array.isArray(valueState) ? (
                valueState?.length > 0 ? <div className='listInput'>
                  {
                    valueState?.map((item) => {
                      return (
                        <Chip
                          onDelete={handleDeleteItem(item)}
                          key={item?.id}
                          className="chipInput"
                          onClick={handleNotChange}
                        >{item?.name}</Chip>
                      )
                    })
                  }
                </div>
                  : <div className='placeholder'>{placeholder}</div>)
                : (
                  typeof (valueState) === "string" ?
                    <div className='valueString'>{valueState}</div>
                    :
                    <div className='placeholder'>{placeholder}</div>
                )
            }
          
          <div className='icon' onClick={handleClick}>{ICON_OPEN}</div>
        </div>
        <fieldset className={`fieldset ${error ? "errorFieldset" : ''}`} style={{ height: `${valueHeight > 48 ? `${(valueHeight + 25)}px` : ''}` }}>
          <legend className={`legend ${error ? "errorLegend" : ''}`} onClick={handleNotChange}>
            {label}
            {required && <span>*</span>}
          </legend>
        </fieldset>
      </div>
      {error && <div className="helperText">{helperText}</div>}
    </div>
  );
};

MultiInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.any,
  helperText: PropTypes.string,
  inputRef:PropTypes.any
};

export default MultiInput;
