import { LanguageCodeEnum } from "../enum";

export const vi = {
  common: {
    policy: "Chính sách",
    termsOfUse: "Điều khoản",
    moreOption: 'Các lựa chọn',
    settingTime: 'Đặt thời gian',
    steps: 'Các chặng',
    start: 'Bắt đầu',
    call: 'Gọi',
    way: 'Đường đi',
    day: 'Ngày',
    hour: 'Giờ',
    minutes: 'Phút',
    seconds: 'Giây',
    option: 'Tùy chọn',
    destination: 'Điểm đến',
    tourism: 'Du lịch',
    departNow: 'Khởi hành ngay bây giờ',
    departAt: 'Khởi hành lúc',
    backToDirection: "Quay lại trang chỉ đường",
    vie: 'Qua',
    avoid: 'Tránh',
    month: 'Tháng',
    year: 'Năm',
    noInfo: 'Chưa có thông tin',
    noData: 'Không có dữ liệu',
    preview: 'Xem trước',
    city: "Thành phố",
    website: "Trang web",
    all: "Tất cả",
    developerPage: "Nhà phát triển",
    govPage: "Trang người dùng chính phủ",
    change: "Thay đổi",
    street: "Con đường",
    area: "Vùng",
    removeNode: "Gỡ đỉnh",
    removeFile: "Xóa file",
    type: "Loại",
    noPlaceInBuilding: "Không có địa điểm cùng tòa nhà",
    locationSameBuilding: "Địa điểm cùng tòa nhà",
    sameLocation: "Địa điểm cùng",
    showOrHideOldAreaBoundary: "Ẩn/ hiện vùng ranh giới cũ",
    showOrHideOldStreetBoundary: "Ẩn/ hiện tuyến đường cũ",
    file: "File",
    managePage: "Quản lý",
    user: "User",
    admin: "Administrator",
    verify: "Kiểm duyệt viên",
    sale: "Sale",
    confirm: "Xác nhận",
    chooseLanguage: "Chọn ngôn ngữ",
    close: "Đóng",
    open: "Mở",
    clear: "Xóa",
    essentialServiceClass: "Lớp dịch vụ thiết yếu",
    enterKeyword: "Nhập từ khóa...",
    testVersion: "Phiên bản thử nghiệm",
    chatWithUs: "Chat với chúng tôi",
    account: "Tài khoản",
    back: "Quay lại",
    otherOption: "Tùy chọn khác",
    collapseThePanel: "Thu gọn bảng điều khiển",
    expandThePanel: "Mở rộng bảng điều khiển",
    introduce: "Giới thiệu",
    removeThisPlace: "Xóa địa điểm này",
    reverseYourStartingPointAndDestination:
      "Đảo ngược điểm xuất phát và điểm đến",
    seeMore: "Xem nhiều hơn",
    current: "Hiện tại",
    urlOrIframe: "Liên kết hoặc mã nhúng",
    menu: "Menu",
    searchResult: "Kết quả tìm kiếm",
    showResult: "Hiển thị kết quả",
    layer: "Layer",
    phoneNumber: "Số điện thoại",
    foliage: "Diện tích tán lá",
    typeInfo: "Loại thông tin",
    tree: "Cây cối",
    floorArea: "Diện tích nền",
    numberFloor: "Số tầng",
    foliage: "Diện tích tán lá",
    chooseStartingPointOrClickOnTheMap: "Chọn điểm đi hoặc click trên map",
    chooseDestination: "Chọn điểm đến",
    savedInto: "Được lưu trong mục",
    viewList: "Xem danh sách",
    update: "Cập nhật",
    timeMove: "Thời gian di chuyển",
    totalTime: "Tổng thời gian",
    expandMore: "Mở rộng",
    expandLess: "Ẩn tất cả",
    displayMapDataOverTime: "Hiển thị dữ liệu bản đồ theo thời gian",
    notYetAddress: "Chưa có địa chỉ",
    notYetName: "Chưa có tên",
    myLocation: "Vị trí của tôi",
    yourLocation: "Vị trí của bạn",
    requestUpdate: "Đề xuất chỉnh sửa",
    image: "Ảnh",
    create: "Tạo mới",
    province: "Tỉnh",
    modelFile: "Tệp mô hình",
    order: "Thứ tự",
    content: "Nội dung",
    title: "Tiêu đề",
    path: "Đường dẫn",
    note: "Ghi chú",
    embed: "Mã nhúng",
    addNew: "Thêm mới",
    addressCode: "Mã địa chỉ",
    address: "Địa chỉ",
    home: "Nhà riêng",
    company: "Cơ quan",
    homePrivate: "Nhà riêng",
    office: "Cơ quan",
    add: "Thêm",
    edit: "Chỉnh sửa",
    delete: "Xóa",
    place: "Địa điểm",
    category: "Loại đối tượng",
    number: "Số",
    elevation: "Cao độ",
    bearing: "Góc xoay",
    scale: "Tỉ lệ",
    model: "Mô hình",
    bottomSurface: "Mặt đáy",
    lat: "Vĩ độ",
    lng: "Kinh độ",
    name: "Tên",
    noOption: "Không tìm thấy dữ liệu",
    businessHours: "Giờ làm việc",
    startDate: "Ngày bắt đầu",
    endDate: "Ngày kết thúc",
    avatar: "Ảnh đại diện",
    edit: "Chỉnh sửa",
    search: "Tìm kiếm",
    noPlaceFound: "Không tìm thấy địa điểm",
    addMissingPlace: "Thêm địa điểm bị thiếu",
    up: "Nghiêng lên",
    down: "Nghiêng xuống",
    zoomIn: "Phóng to",
    zoomOut: "Thu nhỏ",
    left: "Quay phải",
    right: "Quay trái",
    navigation: "Điều hướng",
    location: "Vị trí hiện tại",
    status: "Trạng thái",
    apply: "Áp dụng",
    login: "Đăng nhập",
    copy: "Sao chép",
    next: "Trước",
    previous: "Sau",
    images: "Hình ảnh",
    close: "Đóng",
    finish: "Hoàn thành",
    detail: "Chi tiết",
    link: "Liên kết",
    article: "Bài viết",
    vr360: "Thực tế ảo",
    share: "Chia sẻ",
    popup: "Mã nhúng",
    video: "Video",
    direction: "Chỉ đường",
    list: "Danh sách",
    bookmark: "Lưu",
    sunday: "Chủ nhật",
    monday: "Thứ 2",
    tuesday: "Thứ 3",
    wednesday: "Thứ 4",
    thursday: "Thứ 5",
    friday: "Thứ 6",
    saturday: "Thứ 7",
    datetime_24h: "Mở cửa cả ngày",
    time: "Thời gian",
    datetime_business_hours: "Giờ mở cửa",
    sunday_acronym: "CN",
    monday_acronym: "Th 2",
    tuesday_acronym: "Th 3",
    wednesday_acronym: "Th 4",
    thursday_acronym: "Th 5",
    friday_acronym: "Th 6",
    saturday_acronym: "Th 7",
    datetime_closed: "Đóng cửa",
    cancel: "Hủy",
    apply: "Áp dụng",
    login: "Đăng nhập",
    copy: "Sao chép",
    next: "Tiếp theo",
    previous: "Trước",
    images: "Hình ảnh",
    close: "Đóng",
    detail: "Chi tiết",
    link: "Liên kết",
    article: "Bài viết",
    vr360: "Thực tế ảo",
    share: "Chia sẻ",
    popup: "Mã nhúng",
    video: "Video",
    direction: "Chỉ đường",
    list: "Danh sách",
    bookmark: "Lưu",
    searching: "Đang tìm kiếm...",
    noOptionsText: "Không có kết quả",
    search: "Tìm kiếm",
    atm: "ATM",
    restaurant: "Nhà hàng",
    coffee: "Quán cà phê",
    park: "Điểm đỗ xe",
    BackToResult: "Quay lại kết quả tìm kiếm",
    backToTop: "Quay lại đầu trang",
    showMore: "Thêm",
    lessThan: "Ít hơn",
    less: "Thu gọn",
    bar: "Quán bar",
    grocery: "Cửa hàng tạp hóa",
    gasStation: "Trạm xăng",
    hotel: "Khách sạn",
    hospital: "Bệnh viện",
    school: "Trường học",
    postOffice: "Bưu điện",
    bank: "Ngân hàng",
    hotelNearDestination: 'Khách sạn lân cận',
    restaurantNearDestination: 'Nhà hàng lân cận',
    tourismNearDestination: 'Điểm du lịch lân cận',
    adminPage: "Trang quản trị",
    userPage: "Trang người dùng",
    reportError: "Báo cáo lỗi",
    logout: "Đăng xuất",
    suggestPlace: "Địa điểm gợi ý",
    classService: "Lớp dịch vụ",
    object3d: "Đối tượng 3D",
    objectClass: "Lớp đối tượng",
    setting: "Cài đặt",
    language: "Ngôn ngữ",
    myPlace: "Địa điểm của tôi",
    myAccessKey: "Access key của tôi",
    building: "Đối tượng 3D",
    my3DObject: "Đối tượng 3D của tôi",
    showingResult: "Đang hiển thị kết quả",
    closeTime: "Đóng cửa",
    openAllTime: "Mở cửa cả ngày",
    opening: "Đang mở cửa",
    save: "Lưu",
    shortest: "Ngắn nhất",
    fastest: "Nhanh nhất",
    balance: "Cân bằng",
    from: "Từ",
    to: "Đến",
    buildingDetail: "Chi tiết Đối tượng 3D",
    directFromHere: "Chỉ đường từ đây",
    directToHere: "Chỉ đường tới đây",
    addPointTo: "Thêm điểm đến",
    addRestingPoi: "Thêm điểm dừng",
    noMediaFiles: "Không có video và hình ảnh cho địa điểm này",
    noImages: "Không có hình ảnh cho địa điểm này",
    noVideos: "Không có video cho địa điểm này",
    minZoom: "Mức zoom nhỏ nhất",
    maxZoom: "Mức zoom lớn nhất",
    saved: "Đã lưu",
    draw: "Vẽ",
    upload: "Tải lên",
    height: "Chiều cao",
    startDraw: "Bắt đầu vẽ",
    endDraw: "Kết thúc vẽ",
    texture: "Ảnh texture",
    dragtext: "Kéo và thả tập tin geojson (GIS File)",
    nameFile: "Chọn tệp",
    file: "File",
    send: "Gửi",
    description: "Mô tả",
    reason: "Lý do",
    [LanguageCodeEnum.vi]: "Tiếng Việt",
    [LanguageCodeEnum.en]: "Tiếng Anh",
    notFound: "Không có dữ liệu tìm kiếm",
    linkShare: "Liên kết để chia sẻ",
    endData: "Bạn đã xem hết danh sách này.",
    hotelAlongRoute: 'Khách sạn dọc theo tuyến đường',
    restaurantAlongRoute: 'Nhà hàng dọc theo tuyến đường',
    tourismAlongRoute: 'Điểm du lịch dọc theo tuyến đường',
    selectHour: "Chọn giờ",
    routePreview: "Xem trước tuyến đường",
    total: "Tổng cộng"
  },
  objectLayer: {
    category: "Danh mục",
    chooseObjectLayer: "Chọn danh mục đối tượng",
    chooseAll: "Chọn tất cả",
  },
  modelFile: {
    add: "Thêm tệp mô hình",
  },
  file: {
    add: "Thêm tệp",
  },
  bottomSurface: {
    add: "Thêm mặt đáy",
  },
  polygon: {
    add: "Thêm đa giác",
  },
  startDate: {
    choose: "Chọn ngày bắt đầu",
  },
  category: {
    cate: "Danh mục",
    add: "Thêm loại đối tượng",
    selectObjectCategory: "Chọn danh mục đối tượng",
    main: "Danh mục chính",
    select: "Chọn danh mục"
  },
  name: {
    add: "Thêm tên",
  },
  startDate: {
    choose: "Chọn ngày bắt đầu",
  },
  endDate: {
    choose: "Chọn ngày kết thúc",
  },
  elevation: {
    add: "Thêm cao độ",
  },
  bearing: {
    add: "Thêm góc xoay",
  },
  lat: {
    add: "Thêm vĩ độ",
  },
  lng: {
    add: "Thêm kinh độ",
  },
  minZoom: {
    choose: "Chọn mức zoom",
  },
  maxZoom: {
    choose: "Chọn mức zoom",
  },
  model: {
    choose: "Chọn mô hình",
    type: "Loại mô hình",
    chooseType: "Chọn loại mô hình",
    available: "Mô hình sẵn có",
  },
  scale: {
    add: "Thêm tỉ lệ",
  },
  bearing: {
    add: "Thêm góc xoay",
  },
  height: {
    add: "Thêm chiều cao",
  },
  title: {
    add: "Thêm tiêu đề",
  },
  object3d: {
    building: "Tòa nhà",
    multi: "Nhiều đối tượng 3D",
    addMulti: "Thêm nhiều đối tượng 3D",
    add: "Thêm đối tượng 3D",
    requestUpdate: "Đề xuất chỉnh sửa đối tượng 3D",
  },
  place: {
    addPlace: "Thêm mới địa điểm",
    editPlace: "Chỉnh sửa địa điểm",
    province: "Tỉnh/Thành phố",
    district: "Huyện/Quận",
    subDistrict: "Xã/Phường",
    hamlet: "Tổ/Thôn/Xóm",
    street: "Tên đường",
    houseNumber: "Ngõ - Ngách - Hẻm kiệt - Số nhà",
    phoneNumber: "Số điện thoại",
    website: "Website",
    placeType: "Loại địa điểm",
    object: "Đối tượng",
    search: "Tìm kiếm địa điểm",
    requestUpdate: "Yêu cầu chỉnh sửa vùng",
    requestUpdatePlace: "Yêu cầu chỉnh sửa {place}",
    infoAboutPlace: 'Thông tin về địa điểm',
    captionInfoPlace: 'Cung cấp một số thông tin về địa điểm này. Địa điểm này sẽ hiển thị công khai nếu được thêm vào bản đồ.',
    addPhoto: 'Thêm hình ảnh vào địa điểm này (ví dụ: ảnh chụp mặt tiền của cửa hàng, thực đơn hoặc các ảnh tương tự). Các ảnh này hiển thị công khai.',
    photo: 'Hình ảnh về địa điểm',
    addOtherInfo: 'Thêm thông tin khác',
    addOtherInfoCaption: 'Thêm toà nhà, số điện thoại, trang web hoặc mô tả thêm để giúp chúng tôi xác minh địa điểm này.',
  },

  street: {
    photo: 'Hình ảnh về con đường',
    addPhoto: 'Thêm hình ảnh về con đường, các ảnh này sẽ hiển thị công khai trên bản đồ.',
    addOtherInfoRoadCaption: 'Mô tả thêm về con đường để giúp chúng tôi xác minh con đường này.',
    addOtherInfo: 'Thông tin mở rộng',
    infoAboutStreet: 'Thông tin về đường',
    captionInfoStreet: 'Vui lòng xác nhận một số thông tin về con đường này. Nội dung chỉnh sửa sẽ được hiển thị công khai trên bản đồ.'
  },

  boundary: {
    photo: 'Hình ảnh về vùng ranh giới địa chính',
    addPhoto: 'Thêm hình ảnh về vùng ranh giới địa chính, các ảnh này sẽ hiển thị công khai trên bản đồ.',
    addOtherInfoBoundaryCaption: 'Thêm virtual 360, liên kết, trang web hoặc mô tả thêm để giúp chúng tôi xác minh vùng ranh giới địa chính này.',
    addOtherInfo: 'Thông tin mở rộng',
    infoAboutBoundary: 'Thông tin về ranh giới địa chính',
    captionInfoBoundary: 'Cung cấp một số thông tin về vùng ranh giới địa chính này. Nội dung chỉnh sửa sẽ được hiển thị công khai trên bản đồ.'
  },

  object: {
    name: "Đối tượng",
    poi: "Địa điểm liên kết",
  },
  hashTag: {
    tag: "Nhãn",
    add: 'Thêm nhãn',
    addForPlace: 'Thêm nhãn cho địa điểm'
  },
  validate: {
    required: "{field} là bắt buộc",
    number: "{field} phải là kiểu số",
    url: "{field} không hợp lệ",
    min: "{field} phải lớn hơn hoặc bằng {min}",
    max: "{field} phải nhỏ hơn hoặc bằng {max}",
    moreThan: "{field} phải lớn hơn {min}",
    lessThan: "{field} phải nhỏ hơn {max}",
    code: "{field} chỉ bao gồm các ký tự a-z,A-Z,0-9",
    typeError: "{field} phải là kiểu {type}",
    format: "{field} không đúng định dạng",
    duplicate: "{field} này đã tồn tại",
    contentError: "Nội dung {object} không đúng",
    formatImage: 'Các định dạng được phép chọn: {format}',
    maxCapacityImage: 'Hình ảnh bạn chọn vượt quá {numberUnit}',
    maxTotalCapacityImage: 'Tổng kích thước hình ảnh vượt quá {numberUnit}'
  },
  fieldValidate: {
    name: "Tên*",
    placeType: "Loại địa điểm*",
    lat: "Vĩ độ*",
    lng: "Kinh độ*",
  },
  updatePlace: {
    requestUpdate: "Đề xuất chỉnh sửa",
    requestUpdateRoad: "Chỉnh sửa thông tin đường",
    requestUpdateBoundaries: "Chỉnh sửa thông tin ranh giới địa chính",
    name: "Tên",
    category: "Loại đối tượng",
    location: "Vị trí",
    timeOpen: "Giờ mở cửa",
    contact: "Liên hệ",
    web: "Trang web",
    description: "Mô tả",
    province: "Tỉnh/Thành phố",
    district: "Huyện/Quận",
    subDistrict: "Xã/Phường",
    hamlet: "Tổ/Thôn/Xóm",
    street: "Tên đường",
    houseNumber: "Ngõ/Ngách/Hẻm kiệt/Số nhà",
    building: "Đối tượng 3D",
    tag: "Nhãn",
    addPhoto: "Thêm ảnh",
    descriptionPhotoFeild:
      "Thêm ảnh về địa điểm này (ví dụ: ảnh chụp mặt tiền của cửa hàng, thực đơn hoặc các ảnh tương tự). Các ảnh này hiển thị công khai. Chỉ cho phép ảnh có định dạng .jpg .png .jpeg",
    article: "Bài viết",
    vr360: "Thực tế ảo",
    notes: "Ghi chú",
    addExtendBtn: "Thêm Virtural 360, Liên kết",
    addName: "Thêm tên",
    addCategory: "Thêm loại đối tượng",
    addTag: "Thêm nhãn",
    searchObject: "Tìm đối tượng",
    addOpenTime: "Thêm giờ mở cửa",
    addPhoneNumber: "Thêm số điện thoại",
    addDescriptionForLocation: "Thêm mô tả cho địa điểm",
    addWebsite: "Thêm trang web",
    addVr360: "Thêm thực tế ảo",
    addNote: "Thêm ghi chú",
    addArticle: "Thêm bài viết",
    addName: "Thêm tên",
    addCategory: "Thêm loại đối tượng",
    addBuilding: "Thêm đối tượng 3D",
    addReason: "Thêm lý do",
    reasonUpdate: "Lý do chỉnh sửa",
    mon: "T2",
    tue: "T3",
    wed: "T4",
    thu: "T5",
    fri: "T6",
    sat: "T7",
    sun: "CN",
    timeOpen: "Giờ mở cửa",
    timeClose: "Giờ đóng cửa",
    markClosedNonexistentOrDuplicated:
      "Đánh dấu đóng cửa, không tồn tại hoặc trùng lặp.",
    deleteThisPlace: "Xóa địa điểm này",
    deleteThisObject: "Xóa đối tượng này",
    changeTheNameOrOtherDetails: "Thay đổi tên hoặc các chi tiết khác",
    editNameLocationTime: "Sửa tên, vị trí, giờ,...",
    yourRecommendation: "Đề xuất của bạn",
    markAsClosedOrDeleted: "Đánh dấu là đã đóng cửa hoặc xóa",
    reason: "Lý do",
    addRemovalReason: "Thêm lý do loại bỏ",
    stringJson: "Chuỗi Json",
    requestUpdateObject: "Chỉnh sửa thông tin đối tượng",
    requestUpdatePoi: "Chỉnh sửa thông tin địa điểm"
  },

  button: {
    close: "Đóng",
    cancel: "Hủy",
    send: "Gửi",
    showList: "Hiển thị danh sách",
    showMap: "Hiển thị bản đồ",
    create: "Tạo",
    done: "Xong",
    viewAll: "Xem tất cả",
    hideLess: "Ẩn bớt",
    save: "Lưu",
    ok: "Đồng ý",
    add: 'Thêm',
    viewMap: 'Xem bản đồ',
  },
  placeDetail: {
    noImages: "Không có hình ảnh cho địa điểm này",
    photoVideo: "Ảnh - Video",
  },
  placeSearch: {
    listPlace: "Danh sách địa điểm",
  },
  placeGroup: {
    listName: "Tên danh sách",
    saveOnYourList: "Lưu vào danh sách của bạn",
    newList: "Danh sách mới",
    showMap: "Hiển thị bản đồ",
    edit: "Chỉnh sửa danh sách",
    delete: "Xóa danh sách",
    share: "Chia sẻ danh sách",
    addList: "Thêm mới danh sách",
  },
  placeDetail: {
    noImages: "Không có hình ảnh cho địa điểm này",
    photoVideo: "Ảnh - Video",
  },
  placeSearch: {
    listPlace: "Danh sách địa điểm",
  },
  alert: {
    success: {
      addInto: "Thêm {object1} vào {object2} thành công",
      addMultiBuilding: "Thêm nhiều đối tượng thành công",
      add: "Thêm {object} thành công và đang chờ duyệt",
      edit: "Cập nhật {object} thành công ",
      editAddress: "Cập nhật địa chỉ {object} thành công ",
      delete: "Xóa {object} thành công",
      request: "Yêu cầu {action} {object} thành công",
      create: "Tạo mới {object} thành công",
      save: "Lưu {object} thành công",
      saveAddress: "Lưu địa chỉ {object} thành công",
      send: "Gửi {object} thành công và đang chờ duyệt",
      update: "Chỉnh sửa {object} thành công ",
      requestUpdate: "Yêu cầu chỉnh sửa {object} thành công",
      copied: "Đã sao chép"
    },
    error: {
      addInto: "Thêm {object1} vào {object2} không thành công",
      addMultiBuilding: "Thêm nhiều đối tượng không thành công",
      add: "Thêm {object} không thành công",
      edit: "Cập nhật {object} không thành công",
      editAddress: "Cập nhật địa chỉ {object} không thành công ",
      delete: "Xóa {object} không thành công",
      request: "Đề xuất {action} {object} không thành công",
      create: "Tạo mới {object} không thành công",
      save: "Lưu {object} không thành công",
      saveAddress: "Lưu địa chỉ {object} không thành công",
      send: "Gửi {object} không thành công",
      upload: "Tải lên {object} không thành công",
      find: "Không tìm thấy {object} phù hợp",
      update: "Chỉnh sửa {object} không thành công ",
      requestUpdate: "Yêu cầu chỉnh sửa {object} thất bại, vui lòng thử lại",
    },
    warning: {
      addMultiBuilding: "Phải thêm ít nhất một đối tượng",
    },
  },
  personalAddress: {
    updateAddress: "Chỉnh sửa địa chỉ {object}",
    setLocation: "Đặt vị trí",
  },
  note: {
    add: "Thêm mới ghi chú",
    edit: "Chỉnh sửa ghi chú",
    addNoteForThisPlace: "Thêm ghi chú cho địa điểm này?",
  },
  list: {
    edit: "Chỉnh sửa danh sách",
    delete: "Xóa danh sách",
  },
  confirmMessage: {
    delete: "Bạn có chắc chắn muốn xóa {object} không?",
    cancel: "Bạn có muốn hủy {action} không?",
    cancelThis: "Bạn có muốn hủy {action} này không?",
    cancelRequestUpdate:
      "Bạn có muốn hủy yêu cầu chỉnh sửa {object} này không?",
    cancelRequest: "Bạn có muốn hủy yêu cầu {action} {object} này không?",
    cancelRequestUpdatePlace: 'Bạn có chắc chắn muốn hủy những thay đổi bạn đã thực hiện?'
  },
  geocode: {
    error: "Không thể xác định vị trí của bạn",
  },
  layer: {
    venue: "Địa điểm",
    address: "Địa chỉ",
    street: "Con đường",
    admin_level_1: "Quốc gia",
    admin_level_2: "Tỉnh/Thành phố trực thuộc trung ương",
    admin_level_3: "Quận/Huyện/Thị xã/Thành Phố thuộc tỉnh",
    admin_level_4: "Xã/Phường/Thị trấn",
  },
  message: {
    notAvailable: 'Không khả dụng',
    errorDirection: "Lỗi chỉ đường",
    dataNotFound: "Không tìm thấy kết quả",
    dataDoesNotExist: "Dữ liệu không tồn tại",
    resultsNotFoundInTheCurrentRegion:
      "Không tìm thấy kết quả {text} ở khu vực hiện tại.",
    pleaseCheckYourSearchKeywordsOrExpandTheSearch:
      "Vui lòng kiểm tra từ khóa tìm kiếm của bạn hoặc mở rộng vùng tìm kiếm.",
    pleaseCheckYourSearchKeywords:
      "Vui lòng kiểm tra từ khóa tìm kiếm của bạn.",
    notUpdatedInfo: "Chưa có thông tin thay đổi",
    nameForHashtag: "Tên nhãn chỉ bao gồm các ký tự a-z,A-Z,0-9,_",
    maximumCharacters: 'Số ký tự tối đa',
    discoverPlacesNear: 'Khám phá địa điểm gần',
    discoverPlacesNearYourDestination: 'Khám phá địa điểm gần điểm đến',
    notFindLocation: "Chúng tôi không thể tìm thấy vị trí của bạn",
    notUsedLocation: "Map4D không được phép sử dụng vị trí của bạn"
  },
  mode: {
    drive: "Lái xe",
    driveMotorcycle: "Đi xe máy",
    rideBicycle: "Đi xe đạp",
    walk: "Đi bộ",
  },
  vehicle: {
    car: 'Xe ô tô',
    motorbike: 'Xe máy',
    bicycle: 'Xe đạp',
    walk: "Đi bộ",
  },
  direction: {
    route: {
      fastest: "Tuyến đường nhanh nhất",
      shortest: "Tuyến đường ngắn nhất",
      balance: "Tuyến đường tối ưu nhất",
      optimization: 'Tối ưu lộ trình'
    },
    avoid: {
      highWay: "Đường cao tốc",
      ferryRoute: "Đường phà",
      tunnel: "Đường hầm",
      toll: "Trạm thu phí"
    },
    noName: "Thông qua đường chưa được đặt tên",
    arrRouteOptimization: 'Sắp xếp thứ tự các điểm dừng để tối ưu lộ trình tốt nhất',
    maxDestinations: 'Đã đạt đến số lượng điểm đến tối đa',
    multipleDestination: 'Nhiều điểm đến sẽ không áp dụng với "Khởi hành lúc"',
    alreadyOptimize: 'Lộ trình bạn chọn đã tối ưu',
    suggestMessage: 'Có phải ý của bạn là',
    captionTextSearch: 'Hãy đảm bảo rằng tìm kiếm của bạn là đúng hoặc bạn có thể thử bằng từ khóa khác.',
    errorMessage: {
      vehicle: 'Rất tiếc, tìm kiếm của bạn có vẻ nằm ngoài phạm vi khu vực phục vụ hiện tại của chúng tôi cho',
      api: 'Rất tiếc, chúng tôi không tìm thấy chỉ đường',
      poi: 'Rất tiếc, chúng tôi không thể tính chỉ đường',
      textSearch: 'Rất tiếc, chúng tôi không tìm thấy',
      homeOrWork: 'Không tìm thấy vị trí này. Vui lòng kiểm tra địa chỉ và thử lại.'
    },

  },
  effect: {
    displayOptions: "Tùy chọn hiển thị",
    displaySettings: "Cài đặt hiển thị",
    display3D: 'HIỂN THỊ 3D',
    weather: "Thời tiết",
    currentTime: "Thời gian (hiện tại)",
    display: "Hiển thị",
    auto: "Tự động",
    time: {
      morning: "Sáng",
      noon: "Trưa",
      afternoon: "Chiều",
      moon: "Tối",
    },
    weatherOptions: {
      sunny: "Nắng",
      rainy: "Mưa",
      snowy: "Tuyết",
    },
    options: {
      shadowEffect: "Hiệu ứng đổ bóng",
      weatherEffectsTime: "Hiệu ứng thời tiết, thời gian",
      object3D: "Đối tượng 3D",
      waterSurfaceEffect: "Hiệu ứng mặt nước",
      poi: "POI",
    },
  },
  myPoi: {
    labeled: "Đã gắn nhãn",
    saved: "Đã lưu",
  },
  mapType: {
    satellite: "Vệ tinh",
  },
  myLocation: {
    alert: {
      error: "Bản đồ không được phép sử dụng vị trí của bạn",
    },
  },
  user: {
    page: "Trang người dùng",
    manage: "Tài khoản",
  },
  suggestPlaceChip: {
    coffee: "Cà phê",
    gasStation: "Trạm xăng",
    grocery: "Cửa hàng tạp hóa",
    pharmacy: "Cửa hàng thuốc tây",
    restaurant: "Nhà hàng",
    school: "Trường học",
    more: "Xem thêm",
    hotel: "Khách sạn",
    charging: "Trạm sạc xe điện",
    thingsToDo: "Điểm du lịch",
    homestay: "Nhà nghỉ",
    bank: "Ngân hàng",
    park: "Công viên",
    museum: "Bảo tàng",
    search: "Tìm dọc theo tuyến đường",
    clear: "Xóa tìm kiếm"
  },
  businessHours: {
    sunday: "Chủ Nhật",
    monday: "Thứ Hai",
    tuesday: "Thứ Ba",
    wednesday: "Thứ Tư",
    thursday: "Thứ Năm",
    friday: "Thứ Sáu",
    saturday: "Thứ Bảy",
    closedAt: 'Đóng cửa lúc',
    openAt: 'Mở cửa lúc',
    openingSoon: 'Sắp mở cửa',
    closed: 'Đã đóng cửa',
    closingSoon: 'Sắp đóng cửa',
    addOpenTime: "Thêm giờ mở cửa",
    openAllDay: 'Mở cả ngày',
    closedTime: 'Đóng cửa',
    none: 'Chưa có thông tin',
    notYetInfo: "Chưa có thông tin giờ mở cửa"
  },
  position: {
    current: "Vị trí hiện tại của bản đồ",
    realtime: "Vị trí hiện tại của bạn",
    unknown: "Không xác định",
    search: "Tìm kiếm vị trí",
  },
  address: {
    add: "Thêm địa chỉ",
  },
  metadata: {
    virtual: 'Virtual 360',
    link: 'Liên kết'
  },
  virtual: {
    add: 'Thêm Virtual 360',
    info: 'Thông tin Virtual 360 về địa điểm',
    list: 'Danh sách Virtual 360'
  },
  link: {
    add: 'Thêm liên kết',
    info: 'Thông tin liên kết về địa điểm',
    list: 'Danh sách liên kết',
  },
  categoryList: {
    cate: "Danh mục",
    inputGroup: "Nhập tên nhóm danh mục",
    inputType: "Nhập tên danh mục",
    main: "Danh mục chính",
    select: "Chọn danh mục",
    notFound: "Không có dữ liệu",
    sub: "Danh mục phụ",
    searchType: "Tìm kiếm danh mục"
  },
  building: {
    belong: "Thuộc tòa nhà",
    input: "Nhập hoặc tìm kiếm tòa nhà",
    select: "Chọn tòa nhà",
    search: "Tìm kiếm trên Map4D",
    notFoundBuilding: "Không tìm thấy building nào"
  },
  phoneNumber: {
    add: "Thêm số điện thoại"
  },
  website: {
    add: "Thêm trang web"
  },
  description: {
    add: "Thêm mô tả",
  },
  location: {
    editMap: 'Cập nhật vị trí trên bản đồ',
    title: 'Vị trí',
  },
  messageBanner: {
    updateStatusRequest: 'Đề xuất của bạn đã được tiếp nhận, bạn có thể xem tại mục "Đóng góp của tôi"',
    thankForImproving: 'Cảm ơn bạn đã cải thiện !'
  },
  datePicker: {
    mon: "Th 2",
    tue: "Th 3",
    wed: "Th 4",
    thu: "Th 5",
    fri: "Th 6",
    sat: "Th 7",
    sun: "CN",
  },
  aroundRoute: {
    title: "Tìm dọc theo tuyến đường này",
    information: "Tìm trạm dừng, chỗ ở, quán ăn và các địa điểm khác dọc theo tuyến đường của bạn.",
    stay: "Chỗ ở",
    foodAndDrink: "Thực phẩm và đồ uống",
    service: "Dịch vụ",
    visit: "Điểm tham quan"
  },
  searchBar: {
    closeNearLocation: "Hủy tìm kiếm lân cận",
    searchNearLocation: "Tìm kiếm lân cận"
  },
  hour: {
    chosen: "Đặt thời gian"
  },
  location: {
    chooseStarted: "Chọn điểm xuất phát",
    moveAndZoom: "Di chuyển và thu phóng bản đồ dưới ghim"
  },
  notFound: {
    search: `Rất tiếc, chúng tôi không tìm thấy "{searchKey}"`,
    inforSearch: "Hãy đảm bảo rằng tìm kiếm của bạn là đúng hoặc bạn có thể thử bằng từ khóa khác.",
    route: "Rất tiếc, chúng tôi không tìm thấy đường đi bằng {vehicle} giữa các địa điểm này"
  },
  timeLine: {
    status: 'Chế độ thời gian',
    caption: "Di chuyển thanh trượt thời gian để xem sự thay đối của một hoặc nhiều khu vực trong không gian 3D theo thời gian"
  }
};
