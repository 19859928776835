import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './loadingCircle.scss';

const LoadingCircle = (props) => {
  const { size } = props
  const [sizeState, setSizeState] = useState("24px")
  useEffect(() => {
    if (typeof size == 'number') {
      let value = size.toString() + "px"
      setSizeState(value)
    }
    else {
      setSizeState(size || "24px")
    }
  }, [size])

  return (
    <div style={{ width: sizeState, height: sizeState }}>
      <div className="loadingCircleCpn" ></div>
    </div>
  );
};

LoadingCircle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default LoadingCircle;
