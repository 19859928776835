import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { ImageConfig } from '../../../config';
import { CookieTool } from "../../../tool";
import { CookieEnum, MapEventEnum, MapTypeEnum } from '../../../enum';
import { AppData } from '../../../data';
import { Resource } from '../../../resource';
import { useRecoilState } from 'recoil';
import { MapTypeAppState } from './../../../appState/mapTypeAppState';
import './mobileMapTypeSelect.scss'

const useStyles = makeStyles({
  itemTypeMap: {
    width: "56px",
    height: "56px",
  },
  item: {
    borderRadius: "10px",
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    width: "60px",
    width: "56px",
    height: "56px",
    position: "relative",
    overflow: "hidden",
    '&:after': {
      zIndex: "1",
      background: 'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000B5 100%) 0% 0% no-repeat padding-box',
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "100%",
      height: "50%"
    },
  },
  itemSelected: {
    border: "2px solid #508FF4"
  },
  text: {
    color: "#ffffff",
    position: "absolute",
    fontSize: "10px",
    lineHeight: "13px",
    bottom: "5px",
    left: "50%",
    transform: 'translateX(-50%)',
    whiteSpace: "nowrap",
    zIndex: 2
  },
  new: {
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "red",
    color: "#ffffff",
    fontSize: "8px",
    lineHeight: "10px",
    padding: "2px",
    borderRadius: "3px"
  }
});

function MobileMapTypeSelect(props) {

  const { onChange } = props

  const classes = useStyles()

  const [mapTypeAppState, setMapTypeAppState] = useRecoilState(MapTypeAppState)

  const mapTypeDict = {
    roadMap: {
      name: "Roadmap",
      value: MapTypeEnum.roadmap,
      img: ImageConfig.mapTypeSelect.roadmap,
      new: true
    },
    satellite: {
      name: "Satellite",
      value: MapTypeEnum.satellite,
      img: ImageConfig.mapTypeSelect.satellite,
      new: true
    },
    map3d: {
      name: "Map3D",
      value: MapTypeEnum.map3d,
      img: ImageConfig.mapTypeSelect.map3d
    },
    raster: {
      name: "Raster",
      value: MapTypeEnum.raster,
      img: ImageConfig.mapTypeSelect.raster
    }
  }

  const onClickTypeMap = (type) => (e) => {
    setMapTypeAppState(type.value)
    onChange && onChange()
  }

  return (
    <div className='mobileMapTypeSelectCpn'>
      {
        Object.values(mapTypeDict).map(type => {
          return (
            <div key={type.value} className='boxContainerType'>
              <div
                className={`boxItemType`}
                onClick={onClickTypeMap(type)}
              >
                <img
                  src={type.img}
                  className={`imageType ${type.value == mapTypeAppState ? "itemSelect" : ""}`}
                />
                {
                  type?.new &&
                  <div className="boxNewType">NEW</div>
                }
              </div>
              <div className='boxTextType'>
                <div className={`itemTextType itemAppear ${type.value == mapTypeAppState ? "itemTextTypeSelect" : ""}`}>{type.name}</div>
                <div className="itemTextType itemTextHidden">{type.name}</div>
              </div>
            </div>
          )
        })
      }
    </div>

  )
}

MobileMapTypeSelect.propTypes = {
  onChange: PropTypes.func
};

export default MobileMapTypeSelect;

