import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { DragToScroll } from 'ui';
import { DataExtendState } from '../../../appState';
import { PlaceExtensionTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import './displayVr360.scss';
import PropTypes from 'prop-types';

const LinkGetIcon = 'https://s2.googleusercontent.com/s2/favicons?domain_url='
function DisplayVr360({ data, isMobile }) {
  const [dataState, setDataState] = useState([])
  const setDataExtendState = useSetRecoilState(DataExtendState)

  useEffect(() => {
    if (data) {
      setDataState(data)
    }
  }, [data])

  const handleShowDetail = (index, link) => (e) => {
    setDataExtendState({
      show: true,
      dataExtend: dataState,
      indexSelect: index,
      typeData: PlaceExtensionTypeEnum.vr360
    })
    let textLink = link?.toLocaleLowerCase()

    if (!textLink?.includes('https')) {
      window.open(link, '_blank')
    }
  }

  const handleViewAll = (e) => {
    setDataExtendState({
      show: true,
      dataExtend: dataState,
      indexSelect: 0,
      typeData: PlaceExtensionTypeEnum.vr360
    })
  }


  return (
    dataState.length > 0 &&
    <div className='displayVr360Cpn'>
      <div className='headerTitle'>
        <div className='textTitle'>
          {Resource.common.vr360}
        </div>
        {
          dataState?.length > 2 &&
          <div className='textShowMore' onClick={handleViewAll}>
            {Resource.button.viewAll}
          </div>
        }
      </div>
      {
        isMobile ?
          <div className='boxOverFlowX'>
            <div className={`listItemVr360 ${dataState?.length == 1 ? 'fullWidth' : ''}`}>
              {
                dataState?.map((item, idx) => {
                  if (idx < 3) {
                    return (
                      <div title={item?.name} key={idx} className='item' onClick={handleShowDetail(idx, item?.content)}>
                        <div className='textLink'>
                          <div style={{ width: 18, height: 18 }}>
                            <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                          </div>
                          <span className='text'>{item?.content}</span>
                        </div>
                        <span className='textName'>{item.name}</span>
                      </div>
                    )
                  }
                })
              }
            </div>
          </div>
          :
          <DragToScroll>
            <div className={`listItemVr360 ${dataState?.length == 1 ? 'fullWidth' : ''}`}>
              {
                dataState?.map((item, idx) => {
                  if (idx < 3) {
                    return (
                      <div title={item?.name} key={idx} className='item' onClick={handleShowDetail(idx, item?.content)}>
                        <div className='textLink'>
                          <div style={{ width: 18, height: 18 }}>
                            <img src={LinkGetIcon + item?.content} width={18} height={18} style={{ objectFit: 'cover' }} />
                          </div>
                          <span className='text'>{item?.content}</span>
                        </div>
                        <span className='textName'>{item.name}</span>
                      </div>
                    )
                  }
                })
              }
            </div>
          </DragToScroll>
      }

    </div>
  )
}

DisplayVr360.propTypes = {
  data: PropTypes.any,
  isMobile: PropTypes.bool
};

export default DisplayVr360;
