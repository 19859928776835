import React from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowPlaceControlState } from '../../appState';
import { useEffect } from 'react';

const HidePlaceControl = () => {
  const setShow = useSetRecoilState(ShowPlaceControlState)

  useEffect(() => {
    setShow(false)
    return () => {
      setShow(true)
    }
  }, [])

  return null
};

HidePlaceControl.propTypes = {
  //
};

export default HidePlaceControl;
