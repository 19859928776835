import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ShowPositionState } from '../../appState';


const ShowPositionSelect = () => {
  const setShowPositionSelect = useSetRecoilState(ShowPositionState)
  useEffect(() => {
    setShowPositionSelect(true)
    return () => {
      setShowPositionSelect(false)
    }
  }, [])
  return null
};

ShowPositionSelect.propTypes = {
  //
};

export default ShowPositionSelect;
