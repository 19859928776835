import React from 'react';
import PropTypes from 'prop-types';
import "./skeleton.scss"

const Skeleton = ({width, height, borderRadius}) => {
  return <div className= "skeletonCpn" style={{ width: `${width ? width : '100%'}`, height: height, borderRadius: borderRadius }}></div>;
};

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string
};

export default Skeleton;
