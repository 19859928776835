import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { Resource } from '../../../resource';
import { ApiTool, LocationTool } from '../../../tool';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum } from '../../../enum';
import { TextField } from '@material-ui/core';
import { CommonConfig } from '../../../config';

function BuildingInput(props) {
  const { defaultValue, value, onChange, location } = props
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [loadingBuilding, setLoadingBuilding] = useState(false);
  const [building, setBuilding] = useState(defaultValue || null);
  const [text, setText] = useState('')

  useEffect(() => {
    if (!("value" in props)) {
      onChange && onChange(building)
    }
  }, [building, props])

  const handleChangeBuilding = (e, value) => {
    if ("value" in props) {
      onChange && onChange(building)
    }
    else {
      setBuilding(value);
    }
  };

  const getBuilding = (building) => {
    if (LocationTool.isValid(location)) {
      let body = {
        keyword: building,
        recordNumber: CommonConfig.pageSizeSearch,
        lat: location?.lat,
        lng: location?.lng,
        radius: CommonConfig.radiusSearchBuilding
      };
      setLoadingBuilding(true);
      ApiTool.queryGetFromJson(UrlConfig.object.search, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setBuildingOptions(res?.result.map((obj) => ({ id: obj.id, name: obj.name })));
        }
        setLoadingBuilding(false);
      }, true);
      setText(building)
    }
  };

  return (
    <Autocomplete
      closeText={Resource.common.close}
      openText={Resource.common.open}
      clearText={Resource.common.clear}
      fullWidth
      value={(("value" in props) ? value : building) || null}
      loading={loadingBuilding}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => true}
      options={buildingOptions}
      onChange={handleChangeBuilding}
      onInputChange={(e, value) => {
        getBuilding(value);
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          id=""
          variant="outlined"
          label={Resource.updatePlace.addBuilding}
          variant="outlined"
        />
      )}
      noOptionsText={
        text ? Resource.common.noOption : Resource.common.enterKeyword
      }
    />
  )
}

BuildingInput.propTypes = {
  //
};

export default BuildingInput;
