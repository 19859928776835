import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { UrlConfig } from '../../config';
import { Resource } from '../../resource';
import { ApiTool } from '../../tool';
import SuggestV3 from '../suggestV3/suggestV3';
import { CodeEnum } from './../../enum/codeEnum';
import './addressInputV2.scss';
import { AppData } from './../../data/appData';

const AddressInputV2 = (props) => {
  const { value, onChange, placeholder, error, helperText, label, required, position, inputRef } = props

  const [listPoiState, setListPoiState] = useState([])
  const [poiState, setPoiState] = useState(null)

  useEffect(() => {
    setPoiState(value)
  }, [value])

  useEffect(() => {
    getListPoi()
  }, [])

  const getListPoi = (keyword = "") => {
    let locationNew = position ? position : AppData.map?.getBounds().getCenter()
    let locString = `${locationNew?.lat},${locationNew?.lng}`
    let body = {
      text: keyword,
      location: locString
    }
    ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let data = res?.result?.splice(0, 5)
        setListPoiState(data)
      }
    })
  }

  const onChangeTextInput = (keyword) => {
    getListPoi(keyword)
  }

  const onChangeSelect = (value) => {
    setPoiState(value)
    onChange && onChange(value)
  }

  const getOptionLabel = (option) => {
    return option?.address
  }

  return (
    <div className='addressInputCpn' ref={inputRef}>
      <SuggestV3
        label={label || Resource.common.address}
        placeholder={placeholder || Resource.address.add}
        options={listPoiState}
        value={poiState}
        onChange={onChangeSelect}
        onInputChange={onChangeTextInput}
        isFreeSolo
        error={error}
        helperText={helperText}
        maxLengthOfScroll={5}
        isServer
        required={required}
        clearable
        getTextForOption={getOptionLabel}
      />
    </div>
  )
};

AddressInputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.any
};

export default AddressInputV2;
