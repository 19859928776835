import React, { useEffect, useRef, useState } from 'react';
import { StringTool } from './../../tool';
import "./dialogBody.scss";

const DialogBody = ({ children, ...props }) => {

  const dialogBodyHtml = useRef()

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName("containerBody", props.className)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName("containerBody", props.className))
  }, [props.className])

  return (
    <div ref={dialogBodyHtml} {...props} className={className}>
      {children}
    </div>
  )
};

DialogBody.propTypes = {
  //
};

export default DialogBody;
