import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Resource } from '../../resource';
import './notFoundSearch.scss'

const NotFoundSearch = (props) => {
  const {searchKey} = props
  const [searchKeyState, setSearchKeyState] = useState('')

  useEffect(()=>{
    setSearchKeyState(searchKey)
  },[searchKey])
  return (
    <div className='boxNotFoundSearchCpn'>
      <div className='boxTitle'>{Resource.notFound.search.replace("{searchKey}", searchKeyState)}</div>
      <div>{Resource.notFound.inforSearch}</div>
    </div>
  );
};

NotFoundSearch.propTypes = {
  searchKey: PropTypes.string
};

export default NotFoundSearch;
