import React, { useState } from "react";
import './hien.scss';
import MobileSwipStepsV2 from "../../mobileComponents/mobileSwipStepsV2/mobileSwipStepsV2";
import WatchPosition from "../watchPosition/watchPosition";
import { AppData } from "../../data";
import NotFoundSearch from "../../mobileComponents/notFoundSearch/notFoundSearch";
import NotFoundRoute from "../../mobileComponents/notFoundRoute/notFoundRoute";
import MobileLoadingRoute from "../../mobileComponents/mobileLoadingRoute/mobileLoadingRoute";
import LoadingCircle from "../../mobileComponents/loadingCircle/loadingCircle";
import { AppTool, DirectionTool } from "../../tool";
import MobileDepartAt from "../mobileDepartAt/mobileDepartAt";
import { AlertTypeEnum } from "../../enum";
import Alert from "../alert/alert";



const Hien = () => {
   
    return (
        <>
            {/* <WatchPosition map={AppData.map} /> */}
            <div style={{
                width: "100%",
                height: "100%",
                background: "white",
                position: "absolute",
                top: "0",
                left: "0"
            }}>
                <Alert type={AlertTypeEnum.warning} message="check alert dfsdfds fdsfdsf fdsfdsf dfdfdfd fdfdfd fdfdsf dsfds" open={true}/>
            </div>
        </>
        // <MobileSwipStepsV2 route={route}/>
    );
};
export default Hien;
