import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RatingInput } from 'ui';
import { PlaceRelatedState, PlaceTypeState } from '../../appState';
import { ImageConfig, RouterConfig } from '../../config';
import { Resource } from '../../resource';
import BusinessHoursStatus from '../businessHoursStatus/businessHoursStatus';
import TooltipV2 from '../tooltipV2/tooltipV2';
import "./listPlaceRelated.scss";

const ICON_BACK =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#505050" />
  </svg>

const ListPlaceRelated = () => {

  const history = useHistory()

  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const [placeRelatedState, setPlaceRelatedState] = useRecoilState(PlaceRelatedState)
  const setCanBackToPlaceRelatedState = useSetRecoilState(CanBackToPlaceRelatedState)

  const handleOnBack = (e) => {
    setPlaceRelatedState({
      show: false,
      data: []
    })
  }

  const handleOnclickItem = (place) => (e) => {
    history.push({
      pathname: RouterConfig.place.detail.replace(":id", place.id || "custom"),
    })
    setCanBackToPlaceRelatedState(true)
  };

  return (
    placeRelatedState?.show && placeRelatedState?.data?.length > 0 &&
    <div className='listPlaceRelatedCpn'>
      <div className='headerPlaceRelated'>
        <TooltipV2 description={Resource.common.back}>
          <div className='iconBack' onClick={handleOnBack}>
            {ICON_BACK}
          </div>
        </TooltipV2>
        <div className='textTitle'>
          {Resource.common.locationSameBuilding}
        </div>
      </div>
      <div className='containerPlaceRelated'>
        {
          placeRelatedState?.data?.map((poiItem, index) => {
            let avatar = poiItem?.photos?.length > 0 ? poiItem?.photos[0].url : ImageConfig.avaterDefaultGray
            let name = poiItem?.name || ''
            let address = poiItem?.address || ''
            let type = poiItem?.types?.length > 0 ? AllPlaceTypeState[poiItem?.types[0]]?.name : ""
            let rateNum = poiItem?.ratingReport?.avgStars
            let totalComment = poiItem?.ratingReport?.totalComments
            let businessHour = poiItem?.businessHours

            return (
              <div
                className='poiItem'
                key={poiItem?.id + index}
                id={poiItem?.id + index}
                title={name}
                onClick={handleOnclickItem(poiItem)}
              >
                <div className='poiItemInfo'>
                  <div className='name'>{name}</div>
                  <div className='type'>{type}</div>
                  {
                    poiItem?.ratingReport &&
                    <div className='rate'>
                      <div className='rateNumber'>{rateNum}</div>
                      <RatingInput size={13} avgRating={rateNum} />
                      <div className='totalComment'>{`(${totalComment})`}</div>
                    </div>
                  }
                  <div className='address'>{address}</div>
                  <div className='businessHourStatus'>
                    <BusinessHoursStatus value={businessHour} />
                  </div>
                </div>
                <div className='poiItemAvatar'>
                  <img src={avatar} alt='avatar' />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

export default ListPlaceRelated;
