import React from 'react';
import { Resource } from '../../../resource';
import './mobilePreviewButton.scss';
import PropTypes from 'prop-types';

const PREVIEW_ICON =
  <svg  width="18" height="18" viewBox="0 0 24 24">
    <g transform="translate(-114 -132)">
      <g transform="translate(-6 3.031)">
        <path d="M1.5,8.857A1,1,0,0,1,.8,7.141L3.583,4.429.8,1.716A1,1,0,1,1,2.2.284L5.714,3.713a1,1,0,0,1,0,1.432L2.2,8.573A1,1,0,0,1,1.5,8.857Z" transform="translate(125.5 136.969)" fill="#1c75bc" />
        <path d="M5.5,8.857a1,1,0,0,1-.7-1.716L7.583,4.429,4.8,1.716A1,1,0,1,1,6.2.284L9.714,3.713a1,1,0,0,1,0,1.432L6.2,8.573A1,1,0,0,1,5.5,8.857Z" transform="translate(127.016 136.969)" fill="#1c75bc" />
      </g>
    </g>
  </svg>

function MobilePreviewButton(props) {
  const { onClick } = props

  const onClickBtn = () => {
    onClick && onClick()
  }

  return (
    <div className='mobilePreviewButtonCpn' onClick={onClickBtn}>
      <div className='icon'>
        {PREVIEW_ICON}
      </div>
      <div className='caption'>
        {Resource.common.preview}
      </div>
    </div>
  )
};

MobilePreviewButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobilePreviewButton;
