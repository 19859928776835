import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { WeightingEnum } from '../../../enum';
import { DirectionTool, StringTool } from '../../../tool';
import MobileNavigationButton from '../mobileNavigationButton/mobileNavigationButton';
import { DirectionDataState } from './../../../appState/directionState';
import { LanguageState } from './../../../appState/settingState';
import { SummaryDirectionState } from './../../../appState/summaryDirectionState';
import { LinkIconConfig } from './../../../config/linkIconConfig';
import { DirectionOptionTypeEnum } from './../../../enum/directionOptionTypeEnum';
import { ManeuverEnum } from './../../../enum/maneuverEnum';
import { Resource } from './../../../resource';
import MobilePreviewButton from './../mobilePreviewButton/mobilePreviewButton';
import MobileViewMapButton from './../mobileViewMapButton/mobileViewMapButton';
import './mobileRouteDetailV2.scss';
import MobileDraggableButton from './../mobileDraggableButton/mobileDraggableButton';
import MobileListStepButton from '../mobileListStepButton/mobileListStepButton';
import { useTime } from '../../../useHooks';
import { MobileShowLocationButtonState } from '../../../appState';

const ICON_MANEUVER = {
  [ManeuverEnum.keepLeft]: LinkIconConfig.maneuver.keepLeft,
  [ManeuverEnum.keepRight]: LinkIconConfig.maneuver.keepRight,
  [ManeuverEnum.roundaboutLeft]: LinkIconConfig.maneuver.roundaboutLeft,
  [ManeuverEnum.roundaboutRight]: LinkIconConfig.maneuver.roundaboutRight,
  [ManeuverEnum.straight]: LinkIconConfig.maneuver.straight,
  [ManeuverEnum.turnLeft]: LinkIconConfig.maneuver.turnLeft,
  [ManeuverEnum.turnRight]: LinkIconConfig.maneuver.turnRight,
  [ManeuverEnum.turnSharpLeft]: LinkIconConfig.maneuver.turnSharpLeft,
  [ManeuverEnum.turnSharpRight]: LinkIconConfig.maneuver.turnSharpRight,
  [ManeuverEnum.turnSlightLeft]: LinkIconConfig.maneuver.turnSlightLeft,
  [ManeuverEnum.turnSlightRight]: LinkIconConfig.maneuver.turnSlightRight,
  [ManeuverEnum.finish]: LinkIconConfig.maneuver.finish,
  [ManeuverEnum.reachedVia]: LinkIconConfig.maneuver.reachedVia
}

function MobileRouteDetailV2(props) {

  const LabelOptionType = {
    [DirectionOptionTypeEnum.yourPosition]: Resource.common.yourLocation,
    [DirectionOptionTypeEnum.home]: Resource.common.homePrivate,
    [DirectionOptionTypeEnum.work]: Resource.common.office
  }

  const LabelWeightingRoute = {
    [WeightingEnum.fastest]: Resource.direction.route.fastest,
    [WeightingEnum.shortest]: Resource.direction.route.shortest,
  }

  const { route, points, vehicle, weighting, routeIndex, onClickHeader, isFullHeight, onNavigation, onViewMap, onShowSteps, onShowDetail } = props

  const [indexStepActive, setIndexStepActive] = useState()
  const [indexLegActive, setIndexLegActive] = useState()
  const languageState = useRecoilValue(LanguageState)
  const setSumDirecState = useSetRecoilState(SummaryDirectionState)
  const setDataShare = useSetRecoilState(DirectionDataState)
  const valueTime = useTime()
  const setShowMyLocationButton = useSetRecoilState(MobileShowLocationButtonState)

  useEffect(() => {
    setShowMyLocationButton(true)
    return () => {
      setSumDirecState(null)
      setDataShare(null)
      setShowMyLocationButton(false)
    }
  }, [])

  useEffect(() => {
    route && setSumDirecState(route?.summary)
  }, [route])

  useEffect(() => {
    points && setDataShare((prev) => ({
      ...JSON.parse(JSON.stringify(prev || {})),
      points: JSON.parse(JSON.stringify(points || []))
    }))
  }, [points])

  useEffect(() => {
    vehicle && setDataShare((prev) => ({
      ...prev,
      mode: vehicle
    }))
  }, [vehicle])

  const onClickStep = (indexLeg, indexStep) => (e) => {
    setIndexStepActive(indexStep)
    setIndexLegActive(indexLeg)
    onShowSteps?.(indexLeg, indexStep)
  }

  const getPointLabelName = (point) => {
    let positionText = point?.position ? `${point?.position?.lat?.toFixed(6)}, ${point?.position?.lng?.toFixed(6)}` : ''
    let text = point?.name || point?.address || positionText || Resource.common.notYetAddress
    let labelOptionType = LabelOptionType[point?.optionType]
    if (labelOptionType) {
      text = labelOptionType
    }
    return text
  }

  const handleNavigation = () => {
    onNavigation?.()
  }

  const handleViewMap = () => {
    onViewMap?.()
  }

  const handleShowListStep = () => {
    onShowDetail?.()
  }

  const handleChangeIndex = (idxLeg, idxStep) => (e) => {
    onShowSteps?.(idxLeg, idxStep)
  }

  const onClickRouteHeader = () => {
    onClickHeader?.()
  }

  let textDistance = DirectionTool.convertDistanceLocalize(route?.distance?.value, languageState)

  return (
    <div className='mobileRouteDetailV2Cpn'>
      <div className='routeHeader' onClickCapture={onClickRouteHeader}>
        <div className='dragButton'>
          <MobileDraggableButton />
        </div>
        <div className='routeSummary'>
          <div className='sumContent'>
            <div className='totalTime'>
              <div className='textDuration'>{valueTime[routeIndex]}</div>
              <div className='textDistance'>{` (${textDistance}) `}</div>
            </div>
            {/* {
              ((routeIndex == 0) && weighting) &&
              <div className='weightingLabel'>
                {LabelWeightingRoute[weighting]}
              </div>
            } */}
            {
              route?.summary &&
              <div className='milestone'>
                {Resource.common.vie + ` ${route?.summary} `}
              </div>
            }
          </div>
        </div>
        <div className='direControlBtn'>
          {/* <MobileNavigationButton onClick={handleNavigation} /> */}
          {
            isFullHeight ?
              <MobileViewMapButton onClick={handleViewMap} />
              :
              <MobileListStepButton onClick={handleShowListStep} />
          }
          <MobilePreviewButton onClick={handleChangeIndex(0, 0)} />
        </div>
      </div>

      <div className='routeContent'>
        {
          route?.legs?.map((leg, index) => {
            let start = points?.length > 0 && points[index]
            let end = points?.length > 0 && points[index + 1]
            let startName = getPointLabelName(start)
            let endName = getPointLabelName(end)
            let startAddress = start?.address
            let endAddress = end?.address
            let isLegActive = indexLegActive == index
            let keyLeg = leg?.startLocation?.lat?.toString() + leg?.startLocation?.lng?.toString()

            return (
              <div className='routeItem' key={keyLeg}>
                <div className='fromPoint'>
                  <div className='name'>{startName}</div>
                  {
                    startAddress &&
                    <div className='address'>{startAddress}</div>
                  }
                </div>

                <div className='totalTime'>
                  <div className='texTotalTime'>{leg?.duration?.text}</div>
                  <div className='lineHorizontal'></div>
                </div>

                <div className='listStep'>
                  {
                    leg?.steps?.map((step, idx) => {
                      let duration = step?.duration?.text
                      let distance = step?.distance?.value
                      let textDistance = DirectionTool.convertDistanceLocalize(distance, languageState)
                      let instruction = step?.htmlInstructions
                      let description = duration ? `${textDistance} (${duration})` : `${textDistance}`
                      let isStepActive = indexStepActive == idx
                      return (
                        <div
                          onClick={onClickStep(index, idx)}
                          className={StringTool.mergeClassName('stepItem', (isStepActive && isLegActive) ? 'stepActive' : '')}
                          key={step?.polyline}
                        >
                          <div className='maneuverIcon'>
                            <img src={ICON_MANEUVER[step?.maneuver]} width={18} height={18} alt='icon maneuver' />
                          </div>
                          <div className='stepDetail'>
                            <div className='textDirection'>
                              {instruction}
                            </div>
                            <div className='durationDetail'>
                              <div className='text'>
                                {description}
                              </div>
                              <div className='lineHorizontal'></div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                {
                  (index == route?.legs?.length - 1) &&
                  <div className='toPoint'>
                    <div className='name'>{endName}</div>
                    {
                      endAddress &&
                      <div className='address'>{endAddress}</div>
                    }
                  </div>
                }
              </div>
            )
          })
        }

      </div>

    </div >
  )
};
MobileRouteDetailV2.propTypes = {
  route: PropTypes.any,
  points: PropTypes.array,
  onBack: PropTypes.func,
  vehicle: PropTypes.string,
  routeIndex: PropTypes.any,
  weighting: PropTypes.any,
  isFullHeight: PropTypes.bool,
  onNavigation: PropTypes.func,
  onViewMap: PropTypes.func,
  onShowSteps: PropTypes.func,
  onShowDetail: PropTypes.func,
  onClickHeader: PropTypes.func
};
export default MobileRouteDetailV2;
