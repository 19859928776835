import { Box, IconButton, makeStyles, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { v4 } from 'uuid';
import { Resource } from '../../../resource';
import { useRecoilState } from 'recoil';
import { ImageGalleryState } from '../../../appState';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    zIndex: 1200,
    backgroundColor: 'rgba(0,0,0,.8)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: 'rgba(0,0,0,.9)',
    boxShadow: '0 1px 5px 0 #111',
    justifyContent: 'flex-end'
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',

  },
  iconClose: {
    color: 'white',
  },
  btnNextPre: {
    color: 'white',
    '&$disabled': {
      color: 'gray',
    },
  }
}));

function ShowImageGallery() {
  const classes = useStyles()
  const theme = useTheme()

  const [imageGalleryState, setImageGalleryState] = useRecoilState(ImageGalleryState)

  const [activeStep, setActiveStep] = useState(0)

  const maxSteps = imageGalleryState?.photosData?.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const closeLightbox = (e) => {
    setImageGalleryState({
      show: false,
      photosData: []
    })
  }

  return (
    imageGalleryState.show && imageGalleryState.photosData?.length > 0 &&
    <>
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
          <IconButton aria-label={Resource.common.close} onClick={closeLightbox}>
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </Paper>
        <SwipeableViews
          style={
            {
              flexGrow: 1,
            }
          }
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          containerStyle={
            {
              height: "100%",
            }
          }
        >
          {imageGalleryState.photosData?.map((step, index) => (
            Math.abs(activeStep - index) <= 2 ? (
              <Box width="100%" key={v4()} height="100%" display="flex" justifyContent="center" alignItems="center">
                <img className={classes.img} src={step.url} alt={step.name} />
              </Box>
            ) : <></>
          ))}
        </SwipeableViews>
        <MobileStepper
          style={{
            backgroundColor: 'rgba(0,0,0,.9)',
            color: 'white'
          }}
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button className={classes.btnNextPre} size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {Resource.common.next}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button className={classes.btnNextPre} size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              {Resource.common.previous}
            </Button>
          }
        />
      </div>
    </>
  )
}

ShowImageGallery.propTypes = {
  //
};

export default ShowImageGallery;
