import { useTheme } from '@material-ui/core';
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MediaTypeEnum } from '../../enum';
import Avatar from '../common/avatar/avatar';
import Swipeable from '../swipeable/swipeable';
import './mobileShowMediaGallery.scss';

const ICON_BACK = <svg id="Component_763_1" data-name="Component 763 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_8132" data-name="Path 8132" d="M0,0H24V24H0Z" fill="none" />
  <path id="Path_11786" data-name="Path 11786" d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#ffffff" />
</svg>

const MobileShowMediaGallery = (props) => {
  const { mediaFiles, indexSelect, onBack } = props
  const theme = useTheme()

  const [activeStep, setActiveStep] = useState(0)
  const [mobileMediaGallery, setMobileMediaGallery] = useState([])

  useEffect(() => {
    setMobileMediaGallery(mediaFiles)
  }, [mediaFiles])

  useEffect(() => {
    setActiveStep(indexSelect)
  }, [indexSelect])

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const handleToBack = () => {
    onBack && onBack()
  }
  return (
    <div className='mobileShowListMediaGallery'>
      <Swipeable length={mobileMediaGallery?.length} indexActive={activeStep} onChange={handleStepChange}>
        {
          mobileMediaGallery?.length > 0 && mobileMediaGallery?.map((item, index) => (
            <div className='itemContainer' key={item?.url}>
              <div className='boxUser'>
                <div onClick={handleToBack} >{ICON_BACK}</div>
                <div className='userItem'>
                  <Avatar
                    size='32'
                    email={item?.createdBy?.displayName || item?.createdBy?.email}
                    firstName={item?.createdBy?.firstName}
                    lastName={item?.createdBy?.lastName}
                    image={item?.createdBy?.avatar}
                  />
                  <div className='userInfor'>
                    <div className='nameUser'>
                      {
                        item?.createdBy?.displayName
                          ? item?.createdBy?.displayName
                          : (item?.createdBy?.lastName || item?.createdBy?.firstName ? `${item?.createdBy?.lastName?.concat(' ') + item?.createdBy?.firstName}` : item?.createdBy?.userId)
                      }
                    </div>
                    <div>{moment(item?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                </div>
              </div>
              <div className="boxMedia">
                {item?.typeFile == MediaTypeEnum.image
                  ? <img src={item?.url} className='mediaFile' />
                  : <video className='mediaFile' preload='metadata' controls src={item?.url+'#t=0.001'} />
                }

              </div>
              {
                item?.description &&
                <div className='boxComment'><div className='comment'>sss{item?.description}</div></div>
              }
            </div>

          ))}
      </Swipeable>
    </div>
  )
};

MobileShowMediaGallery.propTypes = {
  mediaFiles: PropTypes.array,
  indexSelect: PropTypes.number,
  onBack: PropTypes.func
};

export default MobileShowMediaGallery;
