import DateFnsUtils from "@date-io/date-fns";
import { Box, CssBaseline } from "@material-ui/core";
import { ThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { vi } from "date-fns/locale";
import React, { Suspense, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import "../../src/app.css";
import { MobileShowLocationButtonState, ShowCameraControlState, ShowPoiControlState } from "../appState";
import AlertControl from "../components/alertControl/alertControl";
import CameraControl from "../components/cameraControl/cameraControl";
import BtnControl from "../components/common/btnControl/btnControl";
import EffectSetting from "../components/common/effectSetting/effectSetting";
import LanguageSetting from "../components/common/languageSetting/languageSetting";
import LayerSetting from "../components/common/layerSetting/layerSetting";
import MapView from "../components/common/mapView/mapView";
import PlaceControl from "../components/common/placeControl/placeControl";
import Setting from "../components/common/setting/setting";
import ShareControl from "../components/common/shareControl/shareControl";
import UseInApp from "../components/common/useInApp/useInApp";
import Hien from "../components/hien/hien";
import MapTypeControl from "../components/mapTypeControl/mapTypeControl";
import MobileDirectionV2 from "../components/mobileDirectionV2/mobileDirectionV2";
import Nhung from "../components/nhung";
import { ColorConfig, EmbedRouterConfig } from "../config";
import { RouterConfig } from "../config/routerConfig";
import { AppData } from "../data/appData";
import { useWindowSize } from "../useHooks";
import PoiControl from './../components/common/poiControl/poiControl';
import EmbedApp from './../components/embedApp/embedApp';
import MobileCircleDirectionButton from "./common/mobileCircleDirectionButton/mobileCircleDirectionButton";
import MobileMyLocationButton from "./common/mobileMyLocationButton/mobileMyLocationButton";
import MobileObject3dControl from "./common/mobileObject3dControl/mobileObject3dControl";
import MobileObject3dDetail from "./common/mobileObject3dDetail/mobileObject3dDetail";
import MobileTabListMedia from './common/mobileTabListMedia/mobileTabListMedia';
import MobileDraggableBar from "./mobileDraggableBar";
import MobileHome from "./mobileHome/mobileHome";
import MobilePlaceDetail from "./mobilePlaceDetail/mobilePlaceDetail";
import MobilePlaceSearchV2 from './mobilePlaceSearchV2/mobilePlaceSearchV2';
import MobileRequestUpdatePlace from "./mobileRequestUpdatePlace/mobileRequestUpdatePlace";
import MobileSearchBarV2 from "./mobileSearchBarV2/mobileSearchBarV2";
import MyPois from "../components/myPois/myPois";
import MobileMyPois from "./mobileMyPois/mobileMyPois";

function MobileApp() {
    const defaultTheme = useTheme();
    const shadows = defaultTheme.shadows;
    shadows[1] = "0px 0px 2px #00000052";

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: ColorConfig.primary,
            },
            default: {
                main: ColorConfig.default,
            },
        },
        shadows: shadows,
        typography: {
            h6: {
                fontSize: "1rem",
            },
            fontFamily: [
                "Quicksand",
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        spacing: 6,
    });

    const { pathname } = useLocation()
    const [isMapCreated, setIsMapCreate] = useState(false)
    const windowSize = useWindowSize()
    const showCameraControl = useRecoilValue(ShowCameraControlState)
    const { width, height, ref } = useResizeDetector()
    const sourceMyLocationRef = useRef()

    const location = useLocation()

    const mobileShowStepState = useRecoilValue(MobileShowLocationButtonState)
    const showPoiControl = useRecoilValue(ShowPoiControlState)

    const onLoadedMap = (map) => {
        AppData.map = map
        setIsMapCreate(true);
    }
    const handleRef = (r) => {
        AppData.mobile.draggableBar = r
    }
    const handleShareRef = (r) => {
        AppData.shareControl = r
    }

    const handleSearchBarRef = (r) => {
        AppData.mobile.searchBar = r
    }

    const handleAlertRef = (r) => {
        AppData.alert = r
    }

    const refMapTypeControl = (r) => {
        AppData.mapTypeControl = r
    }

    const handleShowMediaRef = (r) => {
        AppData.media = r
    }

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={vi}>
                <CssBaseline />
                <Box overflow="hidden" position="relative" display="flex" flexDirection="column" width="100%" height="100%">
                    <Box
                        ref={ref}
                        width="100%"
                        flexGrow="1"
                        height="100px"
                        position='relative'
                    >
                        <MapView onLoadedMap={onLoadedMap} width="100%" height="100%" />
                        <Route path={RouterConfig.home}>
                            <Box position='absolute' top={80} right='6px'>
                                {
                                    isMapCreated &&
                                    <BtnControl />
                                }

                            </Box>
                            <Box position='absolute' bottom={mobileShowStepState ? 160 : 48} right='6px'>
                                <Box mb={2} >
                                    <MobileMyLocationButton isSmall={parseInt(height) <= (windowSize.height - 290)} />
                                </Box>
                                {
                                    pathname == RouterConfig.home && parseInt(height) > (windowSize.height - 290) &&
                                    <Box mb={2} >
                                        <MobileCircleDirectionButton />
                                    </Box>
                                }
                            </Box>
                        </Route>
                    </Box>
                    {
                        isMapCreated &&
                        <>
                            <Switch>
                                <Route exact path={RouterConfig.home}>
                                    <MobileHome />
                                </Route>
                                <Route exact path={RouterConfig.direction}>
                                    {/* <MobileDirection /> */}
                                    <MobileDirectionV2 />
                                </Route>
                                <Route exact path={[
                                    RouterConfig.place.detail,
                                    RouterConfig.place.detailWithLocation,
                                    RouterConfig.place.detailCustom,
                                ]}
                                >
                                    <MobilePlaceDetail />
                                </Route>

                                <Route exact path={RouterConfig.object3d.detail}>
                                    <MobileObject3dDetail />
                                </Route>
                                <Route exact path={[
                                    RouterConfig.place.search,
                                ]}
                                >
                                    <MobilePlaceSearchV2 />
                                </Route>
                            </Switch>
                            <Route exact path={"/"}>
                                <Redirect to={RouterConfig.home + window.location.search} />
                            </Route>

                            <Route path={RouterConfig.home}>
                                <Route
                                    exact
                                    path={[
                                        RouterConfig.home,
                                        RouterConfig.place.detail,
                                        // RouterConfig.place.search,
                                        RouterConfig.place.detailWithLocation,
                                        RouterConfig.place.detailCustom,
                                        RouterConfig.object3d.detail,
                                    ]}
                                >
                                    <MobileSearchBarV2 ref={handleSearchBarRef} />
                                </Route>

                                <MobileDraggableBar ref={handleRef}>
                                    <Switch>
                                        <Suspense fallback={null}>
                                            {/* <Route exact path={RouterConfig.home}>
                                                <MobileHome />
                                            </Route> */}
                                            {/* <Route exact path={RouterConfig.direction}>
                                                <MobileDirection />
                                            </Route> */}
                                            <Route exact path={RouterConfig.place.requestUpdate}>
                                                <MobileRequestUpdatePlace />
                                            </Route>
                                            {/* <Route exact path={[
                                                RouterConfig.place.detail,
                                                RouterConfig.place.detailWithLocation,
                                                RouterConfig.place.detailCustom,
                                            ]}
                                            >
                                                <MobilePlaceDetail />
                                            </Route> */}
                                            {/* <Route exact path={[
                                                RouterConfig.place.search,
                                            ]}
                                            >
                                                <MobilePlaceSearch />
                                            </Route> */}
                                            {/* <Route exact path={RouterConfig.object3d.detail}>
                                                <MobileObject3dDetail />
                                            </Route> */}
                                        </Suspense>
                                    </Switch>
                                </MobileDraggableBar>

                                <Route exact path={[
                                    RouterConfig.home,
                                    RouterConfig.place.detail,
                                    RouterConfig.place.search,
                                    RouterConfig.place.detailWithLocation,
                                    RouterConfig.place.detailCustom,
                                    RouterConfig.object3d.detail,
                                    RouterConfig.direction,
                                ]}
                                >
                                    <Setting />
                                    {showPoiControl && <PoiControl />}
                                    <PlaceControl />
                                    <MobileObject3dControl />
                                    {
                                        showCameraControl &&
                                        <CameraControl />
                                    }
                                    <ShareControl ref={handleShareRef} />

                                    <Suspense fallback={null}>
                                        <MobileTabListMedia ref={handleShowMediaRef} />
                                    </Suspense>

                                    <Suspense fallback={null}>
                                        <LayerSetting />
                                        <LanguageSetting />
                                        <EffectSetting />
                                    </Suspense>

                                </Route>

                                <Route
                                    exact
                                    path={[
                                        RouterConfig.home,
                                        RouterConfig.place.search,
                                        RouterConfig.place.detail,
                                        RouterConfig.place.detailWithLocation,
                                        RouterConfig.place.detailCustom,
                                        RouterConfig.object3d.detail,
                                        RouterConfig.direction
                                    ]}
                                >
                                    <UseInApp />
                                </Route>
                                <Route exact path={RouterConfig.my.place}>
                                    <MobileMyPois />
                                </Route>
                                <AlertControl ref={handleAlertRef} />
                                <MapTypeControl ref={refMapTypeControl} />
                            </Route>
                            <Route path={EmbedRouterConfig.home}>
                                <EmbedApp />
                                <MapTypeControl ref={refMapTypeControl} />
                            </Route>
                            <Route exact path={"/dev/hien"}>
                                <Hien />
                            </Route>

                            <Route exact path={"/dev/nhung"}>
                                <Nhung />
                            </Route>
                        </>
                    }

                </Box>
            </MuiPickersUtilsProvider>
        </ThemeProvider >
    )
}

export default MobileApp