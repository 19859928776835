import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { LinkIconConfig, UrlConfig } from '../../../config';
import { AlertTypeEnum, CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool, UserTool } from '../../../tool';
import ActionButton from '../actionButton/actionButton';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './saveButton.scss'
import TooltipV2 from '../../tooltipV2/tooltipV2';

const borderColor = '#07B9AE'
const useStyles = makeStyles({
  root: {
    borderRadius: '50%',
    border: '1px solid #1C75BC',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  display: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textColor: {
    color: '#1C75BC'
  },
  dialogShowList: {
    maxWidth: '100%',
    width: '392px'
  },
  dialogAddList: {
    maxWidth: '100%',
    width: '352px'
  },
  dialogTitle: {
    textAlign: 'center'
  },

  btnSmall: {
    border: '1px solid #BBBBBB',
    borderRadius: '18px',
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    marginRight: '10px'
  },
  textColor: {
    color: '#1C75BC',
    margin: '0 10px'
  },
  icon: {
    marginLeft: '10px'
  }
})

function SaveButton({ id, isSmall, onChange }) {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showAddList, setShowAddList] = useState(false)
  const [listName, setListName] = useState('')
  const groupsRef = useRef([])
  const [checks, setChecks] = useState([])
  const [count, setCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(true)

  const schema = yup.object().shape({
    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.name
      }))
  })
  const form = useForm({
    resolver: yupResolver(schema)
  })
  const { register, handleSubmit, errors, formState } = form

  useEffect(() => {
    var sourceList
    groupsRef.current = []
    if (UserTool.getUser()) {
      sourceList = ApiTool.get(UrlConfig.placeGroup.getByUser, (res) => {
        if (res?.code == CodeEnum.ok) {
          let list = res?.result || []
          let checksNew = []
          list.forEach((group) => {
            let find = group?.places?.find((place) => {
              return place.placeId == id
            })
            groupsRef.current.push({
              id: group.id,
              name: group.name,
              isSaved: find ? true : false
            })
            checksNew.push(find ? true : false)
          })
          let newCount = 0
          checksNew?.forEach(check => {
            if (check) {
              newCount++
            }
          })
          setCount(newCount)
          setChecks(checksNew)
        }
      }, true)
    }

    return () => {
      sourceList?.cancel()
    }
  }, [id])

  useEffect(() => {
    let isChange = false
    checks?.forEach((item, index) => {
      if (item != groupsRef.current[index].isSaved) {
        isChange = true
      }
    })
    setDisableBtn(!isChange)
  }, [checks])

  const handleShowList = () => {
    if (UserTool.getUser()) {
      setShowDialog(true)
    }
    else {
      let returnUrl = location.href;
      location.href = UrlConfig.auth.login + "?returnUrl=" + returnUrl;
    }
  }

  const handleShowAddList = () => {
    setShowAddList(true)
  }

  const handleCloseAddList = () => {
    setShowAddList(false)
  }

  const onChangeListName = (event) => {
    let value = event.target.value
    setListName(value)
  }

  const onSubmit = (data) => {
    let params = {
      name: data?.name,
      description: ''
    }
    ApiTool.post(UrlConfig.placeGroup.create, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        groupsRef.current.push({
          name: res?.result?.name,
          id: res?.result.id,
          isSaved: false
        })
        setChecks(prev => {
          prev.push(true)
          return [...prev]
        })
        AppTool.alert(
          Resource.formatString(Resource.alert.success.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.create, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
    setShowAddList(false)
  }

  const onChangeCheck = (index) => (e) => {
    setChecks(prev => {
      prev[index] = e.target.checked
      return [...prev]
    })
  }

  const handleDone = () => {
    let addList = []
    let newCount = 0
    groupsRef.current?.forEach((group, index) => {
      if (checks[index]) {
        addList.push({
          groupId: group.id,
        })
        newCount++
      }
    })

    let params = {
      groupInfos: addList,
      placeId: id
    }
    ApiTool.post(UrlConfig.placeGroup.saveToGroup, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        setCount(newCount)
        AppTool.alert(
          Resource.formatString(Resource.alert.success.edit, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        groupsRef.current?.forEach((group, index) => {
          group.isSaved = checks[index]
        })
        setDisableBtn(true)
        setShowDialog(false)
        onChange && onChange()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.edit, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const handleCancel = () => {
    let checkNew = []
    groupsRef.current.map((group) => {
      checkNew.push(group.isSaved ? true : false)
    })
    setChecks(checkNew)
    setShowDialog(false)
  }


  return (
    <>
      {
        isSmall ?
          <Box className={classes.btnSmall} aria-label='mobile save btn' onClick={handleShowList}>
            <img className={classes.icon} src={LinkIconConfig.placeExtend.bookmark} alt='icon save' width={15} height={15} />
            <Typography noWrap className={classes.textColor} variant="caption">{count > 0 ? `${Resource.common.saved} (${count})` : Resource.common.bookmark}</Typography>
          </Box>
          :
          <TooltipV2 description={Resource.common.save} anchor='top'>
            <div className="saveButtonCpn">
              <div className="circleBtn" onClick={handleShowList} style={{ borderColor: count > 0 ? `${borderColor}` : '#508FF4' }}>
                <img src={count > 0 ? LinkIconConfig.placeExtend.bookmarkBorder : LinkIconConfig.placeExtend.bookmark} alt='icon save' width={18} height={18} />
              </div>
              <div className="caption">{count > 0 ? `${Resource.common.saved} (${count})` : Resource.common.bookmark}</div>
            </div>
          </TooltipV2>
      }
      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: classes.dialogShowList
          }
        }
      >
        <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.placeGroup.saveOnYourList}</DialogTitle>
        <DialogContent dividers>
          <List disablePadding>
            {
              checks?.map((checked, index) => {
                return (
                  <ListItem button key={v4()}>
                    <ListItemIcon>
                      <img src={LinkIconConfig.placeGroupIcon.list} width={15} height={15} />
                    </ListItemIcon>
                    <ListItemText
                      title={groupsRef.current[index]?.name}
                      primary={<Typography variant='body1' noWrap>{groupsRef.current[index]?.name}</Typography>}
                      primaryTypographyProps={{
                        noWrap: true
                      }}
                    />
                    <Checkbox
                      checked={checked}
                      name="save"
                      checkedIcon={<CircleCheckedFilled />}
                      icon={<CircleUnchecked />}
                      color='primary'
                      onChange={onChangeCheck(index)}
                    />
                  </ListItem>
                )
              })
            }
            <ListItem button onClick={handleShowAddList}>
              <Button color='primary' variant='text' startIcon={<AddIcon />}>{Resource.placeGroup.newList}</Button>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Box width='100%' textAlign='center'>
            <ActionButton name={Resource.button.cancel} onClick={handleCancel} isSecondary />
            <ActionButton disabled={disableBtn} name={Resource.button.done} onClick={handleDone} />
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showAddList}
        onClose={handleCloseAddList}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: classes.dialogAddList
          }
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.placeGroup.newList}</DialogTitle>
          <DialogContent dividers>
            <Box textAlign='right' clone>
              <Typography variant='caption' component='p' gutterBottom>{`${listName?.length || 0}/50`}</Typography>
            </Box>

            <TextField
              label={Resource.placeGroup.listName}
              variant='outlined'
              fullWidth
              onChange={onChangeListName}
              name='name'
              inputRef={register}
              inputProps={{ maxLength: 50 }}
              error={errors?.name ? true : false}
              helperText={errors?.name?.message}
            />
          </DialogContent>
          <Box p={1} display='flex' alignItems='center' justifyContent='center'>
            <Button variant='contained' color='primary' type='submit'>{Resource.button.create}</Button>
          </Box>
        </form>
      </Dialog>

    </>
  )
}

SaveButton.propTypes = {
  //
};

export default SaveButton;
