import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { TypeBusinessHourEnum } from '../../../enum';
import { Resource } from '../../../resource';
import FieldControl from '../fieldControl/fieldControl';
import UpdateBusinessHour from '../updateBusinessHour/updateBusinessHour';

const useStyles = makeStyles({
  label: {
    position: 'absolute',
    left: '10.5px',
    top: '-10px',
    backgroundColor: '#FFFFFF',
    padding: '0 5px',
    color: 'rgba(0, 0, 0, 0.54);'
  },
  boxContent: {
    borderRadius: '4px',
    border: '1px solid #b3b3b3',
    cursor: 'text',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  textPadding: {
    padding: '10.5px'
  },
})
const arrDayOfWeek = [Resource.common.sunday, Resource.common.monday, Resource.common.tuesday, Resource.common.wednesday, Resource.common.thursday, Resource.common.friday, Resource.common.saturday]
const nameTypeBusinessHours = {
  [TypeBusinessHourEnum.closed]: Resource.common.closeTime,
  [TypeBusinessHourEnum.openAllDay]: Resource.common.openAllTime
}
function BusinessHourInput(props) {
  const { defaultValue, onChange } = props
  const [stateValue, setStateValue] = useState(defaultValue || [])
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const handleChangeBusinessHour = (value) => {
    onChange && onChange(value)
    setStateValue(value)
  }
  const handleShowDialog = () => {
    setShow(true)
  }
  const handleCloseDialog = () => {
    setShow(false)
  }

  return (
    <>
      <FieldControl label={Resource.updatePlace.addOpenTime} onClick={handleShowDialog}>
        {
          stateValue?.map((item, index) => {
            return (
              <Box key={v4()} display='flex' justifyContent='space-around' >
                <Box minWidth='90px' textAlign='left'>
                  <Typography variant='body1' component='span'>{arrDayOfWeek[index]}</Typography>
                </Box>
                {
                  item?.type == TypeBusinessHourEnum.opened ?
                    <Box textAlign='left' minWidth={120} >
                      <Typography variant='body1' component='span'>{item?.text}</Typography>
                    </Box>
                    :
                    <Box textAlign='left' minWidth={120} >
                      <Typography variant='body1' component='span'>{nameTypeBusinessHours[item?.type]}</Typography>
                    </Box>
                }
              </Box>

            )
          })
        }
      </FieldControl>
      {
        show &&
        <UpdateBusinessHour defaultValue={stateValue} onClose={handleCloseDialog} onChange={handleChangeBusinessHour} />
      }
    </>
  )
}

BusinessHourInput.propTypes = {
  //
};

export default BusinessHourInput;
