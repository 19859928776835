import PropTypes from 'prop-types';
import React, { useEffect, useId, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { SvgIconConfig } from '../../config';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';
import TooltipV2 from '../tooltipV2/tooltipV2';
import "./directionPositionsInput.scss";

const createDefaultState = () => {
  return [
    {
      id: v4(),
      textInput: ""
    },
    {
      id: v4(),
      textInput: ""
    }
  ]
}

const DirectionPositionsInput = (props) => {

  const { onSearch, onEnter, onFocus, onPermutePositions, value, onSwap, onRemove, onAdd, onBlur, disableAddDestination, onBlurInput } = props

  const id = useId()

  const [focusIndex, setFocusIndex] = useState(-1)
  const [valueState, setValueState] = useState(createDefaultState);
  const [canAddDestination, setCanAddDestination] = useState(false);

  const containerHtml = useRef()

  useEffect(() => {
    let isFullPosition = !valueState?.some(pos => !pos?.position)
    if (isFullPosition && valueState?.length >= 2 && valueState?.length < 10) {
      setCanAddDestination(true)
    }
    else {
      setCanAddDestination(false)
    }
  }, [valueState])

  useEffect(() => {
    const checkBlur = (event) => {
      if (!containerHtml.current?.contains(event.target)) {
        onBlur && onBlur()
      }
      else {
        if (!event.target.tagName.toLowerCase() === 'input') {
          onBlur && onBlur()
        }
      }
    }
    const event = document.addEventListener("click", checkBlur)
    return () => {

    }

  }, [])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (sourceIndex == focusIndex) {
      setFocusIndex(destinationIndex)
    }
    onPermutePositions && onPermutePositions(sourceIndex, destinationIndex)
    setValueState((prev) => {
      const newList = Array.from(prev);
      const [removed] = newList.splice(sourceIndex, 1);
      newList.splice(destinationIndex, 0, removed);
      return newList
    });
  };

  const onFocusInput = (index) => (e) => {
    e.target.select()
    let text = e.target.value
    onFocus && onFocus(text, index)
    setFocusIndex(index)
  }
  const onBlurIndex = (index) => (e) => {
    setFocusIndex(-2)
    let text = e.target.value
    onBlurInput(text, index)
  }
  const onChangeInput = (index) => (e) => {
    let text = e.target.value
    onSearch && onSearch(text, index)
    setValueState(prev => {
      let listNew = [...prev || []]
      listNew[index].textInput = text
      return listNew
    })
  }

  const onEnterInput = (index) => (e) => {
    if (e.key.toLowerCase() == "enter") {
      let text = e.target.value
      let isEnter = true
      onEnter && onEnter(text, index, isEnter)
      setValueState(prev => {
        let listNew = [...prev || []]
        listNew[index].textInput = text
        return listNew
      })
    }
  }

  useEffect(() => {
    if (value?.length >= 2) {
      setValueState(value)
    }
    else {
      setValueState(createDefaultState())
    }
  }, [value])

  const onClickSwap = () => {
    onSwap && onSwap()
    setValueState(prev => {
      let arr = Array.from(prev || [])
      arr.reverse()
      return arr
    })
  }

  const onClickRemove = (index) => () => {
    onRemove && onRemove(index)
    setValueState(prev => {
      let arr = Array.from(prev || [])
      arr.splice(index, 1)
      return arr
    })
  }

  const onClickAdd = () => {
    setValueState(prev => {
      let arr = Array.from(prev || [])
      arr.push({
        id: v4()
      })
      return arr
    })
    onAdd && onAdd()
  }

  const length = valueState?.length || 2

  let indexFocus = valueState?.findIndex(t => !(t?.poiId || t?.position))

  return (
    <>
      <div
        ref={containerHtml}
        className='directionPositionsInput'>
        <div className='icons'>
          {
            valueState?.map((pos, index) => {
              let highlight = false
              let ascii = pos.index + 64
              let char = String.fromCharCode(ascii)
              if (pos.index != index) {
                highlight = true
              }

              return (
                <div key={pos?.id} className='icon'>
                  {
                    (index == 0) ?
                      SvgIconConfig.direction.start
                      :
                      (
                        index == (length - 1) ?
                          SvgIconConfig.direction.end
                          :
                          <div className={StringTool.mergeClassName('char', highlight && "highlight")}>{char || ""}</div>
                      )
                  }
                </div>
              )
            })
          }
        </div>
        <div
          className='icons'
          style={{
            height: `calc(100% * ${length - 1} / ${length})`,
            top: `calc(100% / ${length * 2})`
          }}
        >
          {
            valueState?.map((pos, index) => {
              return (
                (index < (length - 1)) ?
                  <div key={pos?.id} className='icon'>
                    {SvgIconConfig.direction.treeDots}
                  </div>
                  :
                  null
              )
            })
          }
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided) => (
              <div className='dragInputs' {...provided.droppableProps} ref={provided.innerRef}>
                {
                  valueState.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          className='dragInput'
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className='dragDetectArea'>
                          </div>
                          <div className="dragIcon">
                            {SvgIconConfig.direction.drag}
                          </div>
                          <input
                            autoFocus={index == indexFocus}
                            className='textInput'
                            value={item.textInput || ""}
                            onFocus={onFocusInput(index)}
                            onBlur={onBlurIndex(index)}
                            onChange={onChangeInput(index)}
                            onKeyPress={onEnterInput(index)}
                            placeholder={(index != 0) ? Resource.common.chooseDestination : Resource.common.chooseStartingPointOrClickOnTheMap}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className='positionActions'>
          {
            length == 2 ?
              <div className='positionAction'>
                <TooltipV2 description={Resource.common.reverseYourStartingPointAndDestination} anchor='right'>
                  <button className='swap' onClick={onClickSwap}>
                    {SvgIconConfig.direction.swap}
                  </button>
                </TooltipV2>
              </div>
              :
              valueState.map((pos, index) => {
                return (
                  <div key={pos.id} className='positionAction'>
                    <button onClick={onClickRemove(index)} className={StringTool.mergeClassName('remove', (index == focusIndex) && "show")}>
                      {SvgIconConfig.direction.remove}
                    </button>
                  </div>
                )
              })
          }
        </div>
      </div>
      {
        canAddDestination && !disableAddDestination &&
        <button
          className='addDestination'
          onClick={onClickAdd}
        >
          {SvgIconConfig.direction.addDestination}
          <span className='textAdd'>{Resource.common.addPointTo}</span>
        </button>
      }

    </>
  )
};

DirectionPositionsInput.propTypes = {
  onSearch: PropTypes.func,
  onPermutePositions: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.array,
  onSwap: PropTypes.func,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onBlur: PropTypes.func,
  disableAddDestination: PropTypes.bool,
  onBlurInput: PropTypes.func,
  onEnter: PropTypes.func,
};

export default DirectionPositionsInput;
