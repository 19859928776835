import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles, Radio, RadioGroup, useMediaQuery, useTheme } from '@material-ui/core';
import { vi } from '../../../language';
import { CookieEnum, LanguageCodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { CookieTool } from "../../../tool";
import ActionButton from './../actionButton/actionButton';
import { useRecoilState } from 'recoil';
import { ShowLanguageSettingState, LanguageState } from '../../../appState';

const useStyles = makeStyles({
  dialog: {
    width: '406px'
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  labelChecked: {
    color: "#4B8DF8"
  }
})

function LanguageSetting() {

  const classes = useStyles()
  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [showLanguageSettingState, setShowLanguageSettingState] = useRecoilState(ShowLanguageSettingState)
  const [languageState, setLanguageState] = useRecoilState(LanguageState)

  const [language, setLanguageCode] = useState(languageState)

  const handleRadioChange = (event) => {
    let valueRadio = event.target.value
    setLanguageCode(valueRadio)
  }

  const handleClose = () => {
    setShowLanguageSettingState(false)
  }

  const handleApply = () => {
    CookieTool.set(CookieEnum.language, language)
    Resource.setLanguage(language)
    setLanguageState(language)
    setShowLanguageSettingState(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={showLanguageSettingState}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <DialogTitle id="responsive-dialog-title">{Resource.common.chooseLanguage}</DialogTitle>
      <DialogContent>
        <RadioGroup className={classes.radioGroup} aria-label="language" name="language" value={language} onChange={handleRadioChange}>
          {
            Object.keys(LanguageCodeEnum).map((languageCode) => {
              return (
                <FormControlLabel className={languageCode == language ? classes.labelChecked : ""} key={languageCode} value={languageCode} control={<Radio color='primary' />} label={Resource.common[languageCode]} />
              )
            })
          }
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Box width='100%' textAlign='center' p={4}>
          <ActionButton name={Resource.button.cancel} onClick={handleClose} isSecondary />
          <ActionButton name={Resource.common.apply} onClick={handleApply} />
        </Box>
      </DialogActions>
    </Dialog>
  )
}

LanguageSetting.propTypes = {
  //
};

export default LanguageSetting;
