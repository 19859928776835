import { createMuiTheme, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, ThemeProvider, Tooltip } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ColorConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum, PersonalAddressEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, DirectionTool, EncryptionTool, MobileDraggableBarTool, UrlTool, UserTool } from '../../../tool';
import PersonalAddressInput from '../personalAddressInput/personalAddressInput';
import { AppData } from './../../../data/appData';
import UserHomeAddressInput from '../../userHomeAddressInput/userHomeAddressInput';
import UserWorkAddressInput from '../../userWorkAddressInput/userWorkAddressInput';

const useStyles = makeStyles({
  itemIcon: {
    minWidth: '48px',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    backgroundColor: ColorConfig.primary
  }
})

const iconTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})

function PersonalAddress({ disableDraggable }) {
  const classes = useStyles()
  const [userLabel, setUserLabel] = useState(null)
  const history = useHistory()
  const [show, setShow] = useState(false)
  const [type, setType] = useState(PersonalAddressEnum.none)
  const [value, setValue] = useState(null)

  useEffect(() => {
    var sourceUserLabel
    if (UserTool.getUser()) {
      sourceUserLabel = ApiTool.get(UrlConfig.userLabel.get, (res) => {
        if (res?.code == CodeEnum.ok) {
          setUserLabel(res?.result)
        }
      }, true)
    }

    return () => {
      sourceUserLabel?.cancel()
    }
  }, [])

  useEffect(() => {
    if (disableDraggable) {
      if (show) {
        MobileDraggableBarTool.setDraggble(false)
      }
      else {
        MobileDraggableBarTool.setDraggble(true)
      }
    }
  }, [show])

  const handleOnChangeHome = (value) => {
    setUserLabel((prev)=>({
      ...prev,
      home: value
    }))
  }

  const handleOnChangeWork = (value) => {
    setUserLabel((prev)=>({
      ...prev,
      company: value
    }))
  }

  const handleSetOffice = (value) => (e) => {
    setShow(true)
    setType(PersonalAddressEnum.office)
    setValue(value)
  }

  const handleSetHomePrivate = (value) => (e) => {
    setShow(true)
    setType(PersonalAddressEnum.homePrivate)
    setValue(value)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleDirection = (place) => (e) => {
    let camera = AppData.map.getCamera()
    camera.setTarget(place?.location)
    AppData.map.moveCamera(camera, { animate: true })

    let data = DirectionTool.encodeDirection({ positions: [null, place?.placeId] })
    let search = UrlTool.createSearch({ data: data }, { extendOldQuery: true })
    history.push({
      pathname: RouterConfig.direction,
      search: search
    })
  }

  return (
    <>
      <List disablePadding>
        {
          userLabel?.home ?
            <ListItem button >
              <ListItemIcon className={classes.itemIcon} onClick={handleDirection(userLabel?.home)}>
                <ThemeProvider theme={iconTheme}>
                  <HomeIcon color="primary" />
                </ThemeProvider>

              </ListItemIcon>
              <ListItemText
                onClick={handleDirection(userLabel?.home)}
                primary={Resource.common.homePrivate}
                secondary={userLabel?.home?.address}
                secondaryTypographyProps={
                  {
                    noWrap: true,
                  }
                }
              />
              <Tooltip title={Resource.common.edit} placement='right'>
                <IconButton size='small' onClick={handleSetHomePrivate(userLabel?.home)}>
                  <EditIcon color='primary' />
                </IconButton>
              </Tooltip>
            </ListItem>
            :
            <ListItem button onClick={handleSetHomePrivate(userLabel?.home)}>
              <ListItemIcon className={classes.itemIcon}>
                <ThemeProvider theme={iconTheme}>
                  <HomeIcon color="primary" />
                </ThemeProvider>
              </ListItemIcon>
              <ListItemText primary={Resource.common.homePrivate} secondary={Resource.personalAddress.setLocation} />
            </ListItem>
        }

        {
          userLabel?.company ?
            <ListItem button >
              <ListItemIcon className={classes.itemIcon} onClick={handleDirection(userLabel?.company)}>
                <ThemeProvider theme={iconTheme}>
                  <BusinessCenterIcon color='primary' />
                </ThemeProvider>
              </ListItemIcon>
              <ListItemText
                onClick={handleDirection(userLabel?.company)}
                primary={Resource.common.office}
                secondary={userLabel?.company?.address}
                secondaryTypographyProps={
                  {
                    noWrap: true,
                  }
                }
              />
              <Tooltip title={Resource.common.edit} placement='right'>
                <IconButton size='small' onClick={handleSetOffice(userLabel?.company)}>
                  <EditIcon color='primary' />
                </IconButton>
              </Tooltip>
            </ListItem>
            :
            <ListItem button onClick={handleSetOffice(userLabel?.company)}>
              <ListItemIcon className={classes.itemIcon}>
                <ThemeProvider theme={iconTheme}>
                  <BusinessCenterIcon color='primary' />
                </ThemeProvider>
              </ListItemIcon>
              <ListItemText primary={Resource.common.office} secondary={Resource.personalAddress.setLocation} />
            </ListItem>
        }
      </ List>

      {
        show && type == PersonalAddressEnum.office &&
        <UserWorkAddressInput onChange={handleOnChangeWork} type={type} value={value || null} onClose={handleClose}/>
        // <PersonalAddressInput onChange={handleOnChange} type={type} value={value || null} onClose={handleClose} />
      }
      {
        show && type == PersonalAddressEnum.homePrivate &&
        <UserHomeAddressInput onChange={handleOnChangeHome} type={type} value={value || null} onClose={handleClose}/>
        // <PersonalAddressInput onChange={handleOnChange} type={type} value={value || null} onClose={handleClose} />
      }
    </>

  )
}

PersonalAddress.propTypes = {
  //
};

export default PersonalAddress;
