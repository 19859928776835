import React, { useEffect, useLayoutEffect } from 'react';
import { Route } from 'react-router-dom';
import { AppConfig, EmbedRouterConfig } from '../../config';
import { useWindowSize } from '../../useHooks';
import MapTypeSelect from '../common/mapTypeSelect/mapTypeSelect';
import ZoomControl from '../common/zoomControl/zoomControl';
import CopyRight from '../copyRight/copyRight';
import DirectionEmbed from '../embed/directionEmbed/directionEmbed';
import PoiDetailEmbed from '../embed/poiDetailEmbed/poiDetailEmbed';
import "./embedApp.scss";
import { Link } from '@material-ui/core';
import { AppData } from '../../data';
import MapTypeControl from '../mapTypeControl/mapTypeControl';

const EmbedApp = () => {
  const windowSize = useWindowSize()

  useLayoutEffect(() => {
    let link = document.createElement('link');
    link.href = "/mapAppRoot/css/embed.css"
    link.rel = "stylesheet"
    document.getElementsByTagName("head")[0].appendChild(link)
  }, [])
  let isEmbed = (window.top != window.self)
  let warning = `Mã nhúng ${AppConfig.name} phải được sử dụng trong iframe.`

  const refMapTypeControl = (r) => {
    AppData.mapTypeControl = r;
  };

  return (
    isEmbed ?
      <>
        <Route exact path={[
          EmbedRouterConfig.place.detail,
          EmbedRouterConfig.place.detailWithLocation,
          EmbedRouterConfig.place.detailCustom,
        ]}
        >
          <PoiDetailEmbed />
        </Route>
        <Route exact path={EmbedRouterConfig.direction}>
          <DirectionEmbed />
        </Route>
        <CopyRight />
        {
          windowSize.height >= 200 && windowSize.width >= 200 &&
          <>
            <div className='embedZoomControlCpn'>
              <ZoomControl />
            </div>
            <div className='embedSelecTypeCpn'>
              <MapTypeSelect size={42} disableRadius />
            </div>
          </>
        }
        <MapTypeControl ref={refMapTypeControl} />
      </>
      :
      <div className='notEmbedCpn'>
        {warning}
      </div>
  )
};

EmbedApp.propTypes = {
  //
};

export default EmbedApp;
