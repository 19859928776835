import { Box, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { CommonConfig, ImageConfig, SvgIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { AppData } from '../../data';
import { CodeEnum, MediaTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, StringTool } from '../../tool';
import TextView from '../textView/textView';
import { UrlConfig } from './../../config/urlConfig';
import IsLoading from './../isLoading/isLoading';
import './tags.css';


const useStyle = makeStyles(theme => ({
  boxList: {
    boxShadow: theme.shadows[1]
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  borderTop: {
    borderTop: '1px solid #c1c1c16e',
  },
  imgBorder: {
    borderRadius: '4px'
  },
  fontWeight: {
    fontWeight: 500
  },
  padding: {
    padding: '0 16px',
  },
  skeleton: {
    height: 10,
    marginBottom: '10px'
  },
}))

function Tags() {

  const classes = useStyle()
  const { data } = useParams()
  const history = useHistory()

  const topRef = useRef(null)
  const placesRef = useRef([])
  const markersRef = useRef([])

  const [text, setText] = useState("")
  const [loading, setLoading] = useState(true)
  const [scrollTop, setScrollTop] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  const pageNumberRef = useRef(0)

  const [places, setPlaces] = useState({
    data: [],
    count: 0
  })

  useEffect(() => {
    return () => {
      markersRef.current?.forEach(marker => {
        marker.setMap(null)
      })
    }
  }, [])

  useEffect(() => {
    topRef.current?.scrollIntoView()
    getList(text, pageNumberRef.current, { isReset: true })
    pageNumberRef.current = 0
    return () => {
      markersRef.current?.forEach(marker => {
        marker.setMap(null)
      })
      markersRef.current = []
    }
  }, [text])

  const getList = (textSearch, pageNumber, option = { isReset: false }) => {
    const { isReset } = option
    let tags = []
    if (data) {
      tags = data.split(",")
    }
    let body = {
      searchKey: textSearch,
      pageNumber: isReset ? 0 : pageNumber,
      tags: tags
    }
    let prevTime = new Date().getTime()
    ApiTool.post(UrlConfig.poi.getListByTag, body, (res) => {
      setLoading(false)
      if (res?.code == CodeEnum.ok) {
        let newPlaces = res?.result?.data || []
        if (isReset) {
          setPlaces({
            data: newPlaces,
            count: res?.result?.count
          })
          placesRef.current = newPlaces
        }
        else {
          let time = new Date().getTime()
          let realDelta = time - prevTime
          let timeOut = 0
          if (realDelta < 1000) {
            timeOut = 1000 - realDelta
          }
          setTimeout(() => {
            setLoadingData(false)
            setPlaces((prev) => {
              let newList = prev?.data?.concat(newPlaces)
              placesRef.current = newList
              return {
                data: newList,
                count: res?.result?.count
              }
            })
          }, timeOut)

        }
        let latLngBounds = new map4d.LatLngBounds()
        let icons = {

        }
        ApiTool.post(UrlConfig.tag.getByName, tags, (resTags) => {
          if (resTags?.code == CodeEnum.ok) {
            resTags.result?.forEach(element => {
              icons[element.name] = element.iconUrl
            });
          }
          for (let i = 0; i < newPlaces.length; i++) {
            let icon = "/assets/images/icon/AppData.map-marker.png"
            let tag = ""
            for (let j = 0; j < newPlaces[i].tags.length; j++) {
              let t = tags.find(t => { return t = newPlaces[i].tags[j] })
              if (t) {
                tag = t
                break;
              }
            }
            if (tag && icons[tag]) {
              icon = icons[tag]
            }
            let marker = new map4d.Marker({
              position: newPlaces[i].location,
              icon: new map4d.Icon(CommonConfig.sizeIcon, CommonConfig.sizeIcon, icon),
              anchor: [0.5, 1.0],
              title: newPlaces[i].name,
            })
            marker.setMap(AppData.map)
            markersRef.current.push(marker)
            latLngBounds = latLngBounds.extend(newPlaces[i].location)
          }
          // Khỏi tạo padding options
          let paddingOptions = {
            top: 10,
            bottom: 10,
            left: CommonConfig.widthSearchBar + CommonConfig.padding * 2 + 10,
            right: 10
          }
          if (newPlaces?.length > 0) {
            AppData.map.fitBounds(latLngBounds, paddingOptions, null)
          }
        })
      }
    })
  }

  const handleScroll = (event) => {
    let scrollTop = event.currentTarget.scrollTop
    let offsetHeight = event.currentTarget.offsetHeight
    let scrollHeight = event.currentTarget.scrollHeight
    let isLoading = ((scrollTop + offsetHeight) == scrollHeight) && (placesRef?.current?.length != places?.count)
    if (isLoading) {
      setLoadingData(true)
      pageNumberRef.current = pageNumberRef.current + 1
      getList(text, pageNumberRef.current)
    }
    setScrollTop(scrollTop)
  }

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleChangeSearch = (e) => {
    let newText = e.target.value
    setText(newText)
  }

  const handleOnclickItem = (item) => (e) => {
    history.push({
      pathname: RouterConfig.place.detail.replace(":id", item?.id)
    })
  }

  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display="flex"
      flexDirection="column"
      p={CommonConfig.padding + "px"}
    >
      <TextField onChange={handleChangeSearch} fullWidth id="outlined-basic" label="Tìm kiếm" variant="outlined" />
      <Box
        borderRadius="4px"
        className={classes.boxList}
        mt={CommonConfig.padding + "px"}
        flexGrow="1"
        overflow="auto"
        onScroll={handleScroll}
      >
        <span ref={topRef}></span>
        <List
          classes={{
            root: classes.list
          }}
        >
          {
            loading ?
              Array.from({ length: 10 }, (_, i) => i).map((item, index) => {
                return (
                  <Box key={v4()} className={classes.borderTop} display='flex' p={1} width='100%'>
                    <Skeleton animation="wave" variant="rect" width="20%" height='70px' style={{ borderRadius: '4px' }} />
                    <Box padding={1} width='80%'>
                      <Skeleton animation="wave" variant="rect" className={classes.skeleton} width="100%" />
                      <Skeleton animation="wave" variant="rect" className={classes.skeleton} width="100%" />
                      <Skeleton animation="wave" variant="rect" className={classes.skeleton} width="100%" />
                    </Box>
                  </Box>
                )
              })
              :
              places?.data?.length > 0 ?
                <>
                  {
                    places?.data?.map((item, index) => {
                      let photos = item?.mediaFiles?.filter(m => m?.typeFile == MediaTypeEnum.image)
                      return (
                        <ListItem key={item.id || v4()} onClick={handleOnclickItem(item)} button classes={
                          {
                            root: index > 0 ? classes.borderTop : ""
                          }
                        } >
                          <ListItemAvatar>
                            <img className={classes.imgBorder} src={photos?.length > 0 ? photos[0].url : ImageConfig.avaterDefault} height={80} width={80} alt='logo' />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography className={classes.fontWeight} variant='body1'>{item.name}</Typography>}
                            title={item.name + '-' + item.address}
                            secondary={<><Typography variant='body2' component='span'>{item.address}</Typography></>}
                            primaryTypographyProps={
                              {
                                noWrap: true,
                              }
                            }
                            secondaryTypographyProps={
                              {
                                noWrap: true,
                              }
                            }
                            classes={
                              {
                                root: classes.padding
                              }
                            }
                          />
                        </ListItem>
                      )
                    })
                  }

                  <div
                    className="boxContent_list_item"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "72px",
                    }}
                  >
                    {loadingData && <IsLoading />}
                  </div>
                  {scrollTop > 100 && (
                    <div className="moveToTop_btn" onClick={scrollToTop}>
                      {SvgIconConfig.direction.verticalArrow}
                      <TextView variant="body1" style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
                        {Resource.common.backToTop}
                      </TextView>
                    </div>
                  )}
                </>
                :
                <Box m={1} p={1} className={classes.borderShadow}>
                  <Typography variant='subtitle2'>{Resource.formatString(Resource.message.resultsNotFoundInTheCurrentRegion, {
                    text: `"${text}"`
                  })}</Typography>
                  <Typography variant='body2'>{Resource.message.pleaseCheckYourSearchKeywords}</Typography>
                </Box>

          }
        </List>
      </Box>
    </Box>
  )
}

Tags.propTypes = {
  //
};

export default Tags;
