import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, createMuiTheme, makeStyles, ThemeProvider, Tooltip } from '@material-ui/core';
import { Resource } from '../../../resource';
import { AppData } from '../../../data';
import { LinkIconConfig } from '../../../config';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})
const useStyles = makeStyles({
  root: {
    padding: "4px",
    height: "32px",
    minWidth: "32px",
    width:'32px'

  },
  style:{
    position: "absolute",
    display: "flex"
  }
});

function TiltControl() {
  const classes = useStyles()
  const handleUpClick = (e) => {
    let camera = AppData.map.getCamera();
    camera.setTilt(camera.getTilt() + 10);
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleDownClick = (e) => {
    let camera = AppData.map.getCamera();
    camera.setTilt(camera.getTilt() - 10);
    AppData.map.moveCamera(camera, { animate: true })
  }
  return (
      <ThemeProvider theme={theme}>
        <ButtonGroup
          orientation="vertical"
          color="primary"
        >
          <Tooltip title={Resource.common.up} >
            <Button className={classes.root} variant="contained" onClick={handleUpClick}>
              <img src={LinkIconConfig.arrow_top} height={20} width={20} />
            </Button>
          </Tooltip>

          <Tooltip title={Resource.common.down}>
            <Button className={classes.root} variant="contained"  onClick={handleDownClick}>
              <img src={LinkIconConfig.arrow_button} height={20} width={20} />
            </Button>
          </Tooltip>

        </ButtonGroup>
      </ThemeProvider>
  )
}

TiltControl.propTypes = {
  //
};

export default TiltControl;
