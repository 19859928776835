import { Box, Button, createMuiTheme, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import { LocationCanBackState } from '../../appState';
import { CommonConfig, ImageConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AlertTypeEnum, CodeEnum, MediaTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool } from '../../tool';
import Back from '../common/back/back';
import Confirm from '../common/confirm/confirm';
import UpdatePlaceNote from '../common/updatePlaceNote';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#508FF4',
    justifyContent: 'space-between',
    padding: '12px'
  },
  padding: {
    padding: '0 16px',
  },
  borderTop: {
    borderTop: '1px solid #c1c1c16e',
  },
  imgBorder: {
    borderRadius: '4px'
  },
  fontWeight: {
    fontWeight: 500
  },
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
  buttonInherit: {
    textTransform: 'inherit',
    padding: 0
  }
}))

const iconTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})
function GroupDetail() {
  const classes = useStyles()
  const { id } = useParams()

  const [groupDetail, setGroupDetail] = useState([])
  const [show, setShow] = useState(false)
  const [savedPlaceGroup, setSavedPlaceGroup] = useState(null)
  const [showPopOver, setShowPopOver] = useState(null)
  const history = useHistory()

  const locationCanBackState = useRecoilValue(LocationCanBackState)

  const open = Boolean(showPopOver)
  const idPopover = open ? 'simple-popover' : undefined
  let sourceGroupDetailRef = useRef()

  useEffect(() => {
    getDetail()
    return () => {
      sourceGroupDetailRef.current?.cancel()
    };
  }, [id])

  const getDetail = () => {
    sourceGroupDetailRef.current = ApiTool.get(UrlConfig.placeGroup.groupDetail.replace("{id}", id), (res) => {
      if (res?.code == CodeEnum.ok) {
        setGroupDetail(res?.result)
      }
    }, true)
  }

  const handleShowNote = (savedPlaceGroup) => (e) => {
    setShow(true)
    setSavedPlaceGroup(savedPlaceGroup)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleGoBack = () => {
    if (locationCanBackState) {
      history.goBack()
    }
    else {
      history?.push({
        pathname: RouterConfig.home
      })
    }
  }

  const handleShowPopup = (e) => {
    setShowPopOver(e.currentTarget)
  }

  const handleClosePopup = () => {
    setShowPopOver(null)
  }

  const handleConfirm = () => {
    ApiTool.delete(UrlConfig.placeGroup.deleteGroup.replace("{id}", groupDetail?.id), (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.delete, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        handleGoBack()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.delete, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
        handleClosePopup()
      }
    }, true)
    setShowPopOver(null)
  }

  const handleUpdateGroup = () => {
    history.push({
      pathname: RouterConfig.placeGroup.updateGroup.replace(":id", groupDetail?.id),
    })
  }

  const handleChangePlaceNote = () => {
    getDetail()
  }

  const handleShowDetailItem = (item) => (e) => {
    history.push({
      pathname: RouterConfig.place.detail.replace(":id", item?.id),
    })
  }

  const onClickShare = (e) => {
    AppTool.share(window.location.origin + RouterConfig.placeGroup.groupDetail.replace(":id", id))
  }
  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      <Box className={classes.header}>
        <Back children={
          <Tooltip title={Resource.common.back} placement='bottom'>
            <IconButton >
              <ThemeProvider theme={iconTheme}>
                <ArrowBack color='primary' />
              </ThemeProvider>
            </IconButton>
          </Tooltip>
        } />

        <Typography variant='h6' noWrap style={{ color: '#FFFFFF' }}>{groupDetail?.name}</Typography>

        <Box display='flex'>
          <Tooltip title={Resource.common.share} placement='bottom'>
            <IconButton onClick={onClickShare} >
              <ThemeProvider theme={iconTheme}>
                <ShareIcon color='primary' />
              </ThemeProvider>
            </IconButton>
          </Tooltip>

          <Tooltip title={Resource.common.otherOption} placement='bottom'>
            <IconButton onClick={handleShowPopup}>
              <ThemeProvider theme={iconTheme}>
                <MoreVertIcon color='primary' />
              </ThemeProvider>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <List disablePadding component='div' style={{ flexGrow: '1', overflow: 'auto' }}>
        {
          groupDetail?.placeInfos?.map((item, index) => {
            let savedPlaceGroup = {
              groupId: groupDetail?.id,
              placeId: item?.id,
              note: item?.placeNote,
              placeName: item?.name,
              groupName: groupDetail?.name
            }
            let photos = item?.mediaFiles?.filter(m => {
              return m?.typeFile == MediaTypeEnum.image
            })
            return (
              <Box key={item.id || v4()}>
                <ListItem button onClick={handleShowDetailItem(item)} classes={
                  {
                    root: index > 0 ? classes.borderTop : ""
                  }
                } >
                  <ListItemAvatar>
                    <img className={classes.imgBorder} src={photos?.length > 0 ? photos[0].url : ImageConfig.avaterDefault} height={80} width={80} alt='logo' />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={classes.fontWeight} variant='body1'>{item.name}</Typography>
                    }
                    title={item.name + '-' + item.address}
                    secondary={
                      <>
                        <Typography variant='body2' component='span'>{item?.typeInfos ? item?.typeInfos[(item?.types?.length > 0 ? item?.types[0] : "")] : ''}</Typography>
                        <br></br>
                        <Typography variant='body2' component='span'>{item.address}</Typography>
                      </>
                    }
                    primaryTypographyProps={
                      {
                        noWrap: true,
                      }
                    }
                    secondaryTypographyProps={
                      {
                        noWrap: true,
                      }
                    }
                    classes={
                      {
                        root: classes.padding
                      }
                    }
                  />
                </ListItem>
                <Box padding='0 16px'>
                  {
                    item?.placeNote ?
                      <>
                        <Typography style={{ whiteSpace: "pre-line" }} variant='body2'>{item?.placeNote}</Typography>
                        <Button variant='text' color='primary' onClick={handleShowNote(savedPlaceGroup)} className={classes.buttonInherit}>{Resource.note.edit}</Button>
                      </>
                      :
                      <Button variant='text' color='primary' onClick={handleShowNote(savedPlaceGroup)} className={classes.buttonInherit}>{Resource.note.add}</Button>
                  }
                </Box>
              </Box>
            )
          })
        }
      </List>
      {
        show &&
        <UpdatePlaceNote onClose={handleClose} savedPlaceGroup={savedPlaceGroup} onChange={handleChangePlaceNote} />
      }

      <Popover
        id={idPopover}
        open={open}
        anchorEl={showPopOver}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List disablePadding>
          <ListItem button onClick={handleUpdateGroup}>
            <ListItemText primary={Resource.placeGroup.edit} />
          </ListItem>
          <Confirm
            title={Resource.list.delete}
            message={Resource.formatString(Resource.confirmMessage.delete, {
              object: Resource.common.list.toLowerCase()
            })}
            children={
              <ListItem button>
                <ListItemText primary={Resource.placeGroup.delete} />
              </ListItem>
            }
            onConfirm={handleConfirm}
            onCancel={handleClosePopup}
          />

        </List>
      </Popover>
    </Box>
  )
}

GroupDetail.propTypes = {
  //
};

export default GroupDetail;
