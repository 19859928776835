import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextAreaInput } from 'ui';
import { TypeBusinessHourEnum } from '../../enum';
import { Resource } from '../../resource';
import { BusinessHourTool } from '../../tool';
import EditBusinessHoursV3 from '../editBusinessHourV3/editBusinessHourV3';
import './businessHourInputV3.scss';

const convertData = (value) => {
  let result = value?.map(val => {
    let day = {}
    if (val.open?.time == '0000' && val.close == null) {
      day = {
        open: {
          day: val.open.day,
          time: '0000'
        },
        close: {
          day: BusinessHourTool.nextDay(val.open.day),
          time: '0000'
        }
      }
      return day
    }
    else if (val.open == null && val.close?.time == '0000') {
      day = {
        open: {
          day: val.close.day,
          time: null
        },
        close: {
          day: val.close.day,
          time: null
        }
      }
    }
    else return val
  })
  return result
}

const revertData = (value) => {
  let result = value?.map(val => {
    let day = val
    if (val.open?.time == '0000' && val.close?.time == '0000') {
      day.close = null
      return day
    }
    else return val
  })
  return result
}
// Nhóm các ngày có cùng giờ mở cửa vd: ['Mở cửa cả ngày': [Th2,Th3], 'Đóng cửa': [Th5]]
const handleChangeListTime = (item) => {
  let hoursMap = {}
  let list = {}
  if (item) {
    let time = [[], [], [], [], [], [], []]
    let type = [[], [], [], [], [], [], []]
    for (const key in item) {
      let value = item[key]
      time[parseInt(key) - 2]?.push(value?.text)
      type[parseInt(key) - 2]?.push(value?.type)
    }
    type.forEach((value, index) => {
      if (value == TypeBusinessHourEnum.openAllDay) {
        if (!hoursMap['Mở cửa cả ngày']) {
          hoursMap['Mở cửa cả ngày'] = [index]
        }
        else {
          hoursMap['Mở cửa cả ngày'].push(index);
        }
      }
      else if (value == TypeBusinessHourEnum.closed) {
        if (!hoursMap['Đóng cửa']) {
          hoursMap['Đóng cửa'] = [index]
        } else {
          hoursMap['Đóng cửa'].push(index)
        }
      }
      else {
        if (time[index] != '') {
          if (!hoursMap[time[index].join(', ').toString()]) {
            hoursMap[time[index].join(', ').toString()] = [index]
          }
          else {
            hoursMap[time[index].join(', ').toString()].push(index)
          }
        }
      }
    })
    Object.keys(hoursMap)?.forEach((val) => {
      list[val] = groupDay(hoursMap[val])
    })
  }
  return list
}
// Rút gọn mảng các ngày vd : [Th2,Th3,Th4] -> [Th2-Th4]
const groupDay = (arr) => {
  const weekdays = [' Th 2 ', ' Th 3 ', ' Th 4 ', ' Th 5 ', ' Th 6 ', ' Th 7 ', ' CN ',];
  const result = [];
  let start = null;
  let end = null;

  for (let i = 0; i < arr.length; i++) {
    if (start === null) {
      start = weekdays[arr[i]];
    }

    if (i === arr.length - 1 || arr[i + 1] !== arr[i] + 1) {
      end = weekdays[arr[i]];
      result.push(start === end ? [start] : [`${start}-${end}`]);
      start = null;
      end = null;
    }
  }
  return result;
}

function BusinessHourInputV3(props) {
  const { value, onChange, placeholder } = props
  const [stateValue, setStateValue] = useState(convertData(value) || [])
  const [show, setShow] = useState(false)
  const [listTimeHasTransfer, setListTimeHasTransfer] = useState(handleChangeListTime(BusinessHourTool.generateToText2(stateValue)) || {})

  const handleChangeBusinessHour = (value) => {
    onChange && onChange(revertData(value))
    setStateValue(convertData(value))
    setListTimeHasTransfer(BusinessHourTool.generateToText2(convertData(value)))
  }
  const handleShowDialog = () => {
    setShow(true)
  }
  const handleCloseDialog = () => {
    setStateValue(stateValue)
    setShow(false)
  }
  const hanldeSaveDialog = () => {
    setShow(false)
  }

  const showValue = (time) => {
    let arr = []
    Object.keys(time)?.map((value, index) => {
      if (time[value]?.length == 1) {
        arr.push(time[value][0] + ': ' + value)
      }
      else if (time[value]?.length == 2) {
        arr.push(time[value][0] + ' và ' + time[value][1] + ': ' + value)
      }
      else if (time[value]?.length > 2) {
        let arr1 = ''
        time[value]?.map((val, idx) => {
          if (idx == 0) {
            arr1 += val
          }
          else if (idx == time[value]?.length - 1) {
            arr1 += 'và' + val + ': ' + value
          }
          else {
            arr1 += ', ' + val
          }
        })
        arr.push(arr1)
      }
    })
    return arr.join('\n')
  }
  useEffect(() => {
    setStateValue(convertData(value))
  },[value])
  useEffect(() => {
    setListTimeHasTransfer(handleChangeListTime(BusinessHourTool.generateToText2(stateValue)))
  }, [stateValue])
  
  return (
    <>
      <TextAreaInput
        placeholder={placeholder}
        label={Resource.common.datetime_business_hours}
        onClick={handleShowDialog}
        value={showValue(listTimeHasTransfer)}
        readOnly
      >
      </TextAreaInput>
      {
        show &&
        <EditBusinessHoursV3 defaultValue={stateValue} onClose={handleCloseDialog} onChange={handleChangeBusinessHour} onSave={hanldeSaveDialog} onBack={handleCloseDialog} />
      }

    </>
  )
}

BusinessHourInputV3.propTypes = {
  //
  value: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.string
};

export default BusinessHourInputV3;
