import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppConfig, LinkIconConfig } from '../../../config';
import { UrlConfig } from '../../../config/urlConfig';
import { AppData } from '../../../data';
import { AlertTypeEnum, CodeEnum, MapEventEnum, PoiTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool, LocationTool, PlaceTool } from '../../../tool';
import { useIsMobileScreen } from '../../../useHooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DisableMyLocationState, HomeAddressState, MyLocationState, OfficeAddressState } from '../../../appState';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}))

const IconPoiEnum = {
  [PoiTypeEnum.start]: LinkIconConfig.directionPois.fromIcon,
  [PoiTypeEnum.end]: LinkIconConfig.directionPois.toIcon,
  [PoiTypeEnum.middle]: LinkIconConfig.directionPois.fromIcon,
}
function AutoCompleteInput({ poi, onChange, isDirection, poiType, receiveClickMap }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(poi || null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const markerRef = useRef()
  const sourceDrag = useRef()
  const timerRef = useRef()
  const sourceTextSearch = useRef()
  const apiGeoLocationRef = useRef()

  const isMobile = useIsMobileScreen()
  const officeAddressState = useRecoilValue(OfficeAddressState)
  const homeAddressState = useRecoilValue(HomeAddressState)
  const [disableMyLocation, setDisableMyLocation] = useRecoilState(DisableMyLocationState)

  useEffect(() => {
    setValue(poi || null)
  }, [poi])

  useEffect(() => {
    var sourceLocation
    var eventClickMapPoi
    var eventClickBuilding
    var eventClickLocation
    if (receiveClickMap) {
      eventClickMapPoi = AppData.map.addListener(MapEventEnum.click, (args) => {
        let objMapPoi = args?.poi;
        setValue(objMapPoi)
        onChange && onChange(objMapPoi)
      }, { mappoi: true })

      eventClickBuilding = AppData.map.addListener(MapEventEnum.click, (args) => {
        let objBuilding = args?.building;
        setValue(objBuilding)
        onChange && onChange(objBuilding)
      }, { mapbuilding: true })

      eventClickLocation = AppData.map.addListener(MapEventEnum.click, (args) => {
        let body = {
          lat: args?.location?.lat,
          lng: args?.location?.lng
        }
        sourceLocation = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let objLocation = res?.result
            let objLocationNew = {
              id: objLocation.id,
              name: objLocation.name,
              address: objLocation.address,
              location: args?.location
            }
            setValue(objLocationNew)
            onChange && onChange(objLocationNew)
          }
          else {
            let objLocationNew = {
              location: args?.location
            }
            setValue(objLocationNew)
            onChange && onChange(objLocationNew)
            // AppTool.alert(Resource.geocode.error, AlertTypeEnum.error)
          }
        })

      }, { location: true })
    }
    return () => {
      eventClickMapPoi?.remove()
      eventClickBuilding?.remove()
      eventClickLocation?.remove()
      sourceLocation?.cancel()
    };
  }, [receiveClickMap])

  useEffect(() => {
    if (value) {
      if (!markerRef.current) {
        markerRef.current = new map4d.Marker({
          position: { lat: 0, lng: 0 },
          anchor: (poiType == PoiTypeEnum.end) ? [0.5, 0.8] : [0.5, 0.5],
          zIndex: 99999,
          labelAnchor: [1, 0.5],
          draggable: isMobile ? false : true,
        })
        markerRef.current.setUserData({ id: uuidv4() })
      }
      markerRef.current.setLabel(new map4d.MarkerLabel({
        text: value?.name || value?.address || (`${value?.location.lat}, ${value?.location.lng}`) || Resource.common.notYetName,
        anchor: [0, 0.5]
      }))
      markerRef.current.setPosition(value?.location)
      markerRef.current.setIcon(new map4d.Icon((poiType == PoiTypeEnum.end) ? 20 : 16, poiType == PoiTypeEnum.end ? 35 : 16, IconPoiEnum[poiType]))
      markerRef.current?.setMap(AppData.map)
    }
    else {
      markerRef.current?.setMap(null)
    }
  }, [value, poiType, isMobile])

  useEffect(() => {
    var eventDragMarker
    if (!isMobile) {
      eventDragMarker = AppData.map.addListener(MapEventEnum.drag, (args) => {
        if (args?.marker?.getUserData()?.id == markerRef.current?.getUserData()?.id) {
          let loc = args?.marker.getPosition()
          clearTimeout(timerRef.current)
          timerRef.current = setTimeout(() => {
            geoCode(loc, (res) => {
              if (res?.code == CodeEnum.ok) {
                let objLocation = res?.result
                let objLocationNew = {
                  id: objLocation.id,
                  name: objLocation.name,
                  address: objLocation.address,
                  location: loc
                }
                setValue(objLocationNew)
                onChange && onChange(objLocationNew, true)
              }
              else {
                let objLocationNew = {
                  location: loc
                }
                setValue(objLocationNew)
                onChange && onChange(objLocationNew, true)
              }
            })
          }, 300)

        }

      }, { marker: true })
    }
    return () => {
      eventDragMarker?.remove()
    }
  }, [isMobile])

  useEffect(() => {
    return () => {
      markerRef.current?.setMap(null)
      apiGeoLocationRef.current?.cancel()
    }
  }, [])

  useEffect(() => {
    var sourceAutocomplete
    var timer
    if (inputValue) {
      if (LocationTool.checkTextIsLocation(inputValue)) {
        let loc = LocationTool.checkTextIsLocation(inputValue)
        geoCode(loc, res => {
          if (res?.code == CodeEnum.ok) {
            setOptions([{
              ...res?.result,
              location: loc
            }])
          }
        })
      }
      else {
        timer = setTimeout(() => {
          let center = AppData.map.getBounds().getCenter();
          let location = `${center.lat},${center.lng}`
          if (AppData.map.getCamera().getZoom() < 11) {
            location = null
          }
          let body = {
            datetime: new Date().getTime(),
            text: inputValue,
            location: location

          }
          setOptions([])
          setIsLoading(true)
          sourceAutocomplete = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
            setIsLoading(false)
            if (res?.code == CodeEnum.ok) {
              setOptions(res?.result || [])
            }
          })
        }, 300)
      }
    }
    return () => {
      clearTimeout(timer)
      sourceAutocomplete?.cancel()
    }
  }, [inputValue])

  const geoCode = (loc, callback) => {
    let body = {
      lat: loc?.lat,
      lng: loc?.lng
    }
    sourceDrag.current?.cancel()
    sourceDrag.current = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
      callback(res)
    })
  }

  const handleOnchange = (event, newValue) => {
    if (newValue?.isMyLocation) {
      AppTool.getMyLocation(res1 => {
        if (res1?.code == CodeEnum.ok) {
          let myLoc = res1?.result
          let body = {
            ...myLoc
          }
          apiGeoLocationRef.current = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
            let newRes = {
              location: myLoc,
              name: Resource.common.myLocation,
            }
            if (res?.code == CodeEnum.ok) {
              newRes = {
                ...res?.result,
                name: Resource.common.myLocation,
              }
            }
            setOptions(newRes ? [newRes, ...options] : options);
            setValue(newRes);
            onChange && onChange(newRes)
          })
        }
        else {
          AppTool.alert(Resource.myLocation.alert.error, AlertTypeEnum.warning)
          setDisableMyLocation(true)
        }
      })
    }
    else {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
      onChange && onChange(newValue)

    }
  }

  const handleOnInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  }

  const onKeyPress = (e) => {
    if (e.key.toLowerCase() == "enter") {
      let text = e.target.value
      if (text) {
        if (LocationTool.checkTextIsLocation(text)) {
          let loc = LocationTool.checkTextIsLocation(text)
          geoCode(loc, res => {
            if (res?.code == CodeEnum.ok && res?.result) {
              let poi = {
                ...res?.result,
                location: loc
              }
              let camera = AppData.map.getCamera()
              camera.setTarget(poi.location)
              AppData.map.moveCamera(camera, { animate: true })
              setValue(res?.result[0]);
              onChange && onChange(poi)
            }
            else {
              AppTool.alert(
                Resource.formatString(Resource.alert.error.find, { object: Resource.common.place.toLowerCase() }),
                AlertTypeEnum.error
              )
            }
          })
        }
        else {
          let location = AppData.map.getCamera().getTarget();
          let loc
          if (location) {
            loc = `${location.lat},${location.lng}`
          }
          if (AppData.map.getCamera().getZoom() < 11) {
            loc = null
          }
          let body = {
            text: text,
            location: loc,
          }
          ApiTool.queryGetFromJson(UrlConfig.poi.searchByText, body, (res) => {
            if (res?.code == CodeEnum.ok && res?.result?.length > 0) {
              let poi = res?.result[0]
              let camera = AppData.map.getCamera()
              camera.setTarget(poi.location)
              AppData.map.moveCamera(camera, { animate: true })
              setValue(res?.result[0]);
              onChange && onChange(res?.result[0])
            }
            else {
              AppTool.alert(
                Resource.formatString(Resource.alert.error.find, { object: Resource.common.place.toLowerCase() }),
                AlertTypeEnum.error
              )
            }
          })
        }

      }
    }
  }

  let placeOptions = options || []
  if (isDirection && !inputValue) {
    if (officeAddressState) {
      placeOptions = [officeAddressState, ...placeOptions]
    }
    if (homeAddressState) {
      placeOptions = [homeAddressState, ...placeOptions]
    }
    if (!disableMyLocation) {
      let myLocation = {
        isMyLocation: true,
        name: Resource.common.myLocation
      }
      placeOptions = [myLocation, ...placeOptions]
    }
  }
  return (
    <Box width='100%'>
      <Autocomplete
        closeText={Resource.common.close}
        openText={Resource.common.open}
        clearText={Resource.common.clear}
        loading={isLoading}
        loadingText={<Typography variant='body2'>{Resource.common.searching}</Typography>}
        noOptionsText={<Typography variant='body2'>{Resource.common.noOptionsText}</Typography>}
        getOptionLabel={(option) => (typeof option === 'string' ? option : PlaceTool.getPoiLabel(option))}
        getOptionSelected={(option, value) => {
          return true
        }}
        filterOptions={(options, state) => {
          return options
        }}
        options={isLoading ? [] : placeOptions}
        value={value}
        onChange={handleOnchange}
        renderInput={(params) => (
          <TextField onKeyPress={onKeyPress} {...params} placeholder={poiType == PoiTypeEnum.start ? Resource.common.chooseStartingPointOrClickOnTheMap : Resource.common.chooseDestination} label={isDirection ? null : <Typography variant='body2'>{Resource.common.search}</Typography>} variant={isDirection ? "standard" : "outlined"} fullWidth />
        )}
        onInputChange={handleOnInputChange}
        renderOption={(option) => {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="textPrimary">
                  {option.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.address}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Box>
  )
}

AutoCompleteInput.propTypes = {
  poi: PropTypes.object,
  onChange: PropTypes.func,
  isDirection: PropTypes.bool
};

export default AutoCompleteInput;
