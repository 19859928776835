import PropTypes from 'prop-types';
import { useRef } from 'react';
import { PositionDirectionTypeEnum } from '../../enum';
import { useEffect } from 'react';
import { AppData } from '../../data';
import "./markerDirection.scss"

const IconDict = {
  [PositionDirectionTypeEnum.start]: `
    <div class="markerDirection start">
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(-1148.5 -514.5)">
          <g transform="translate(1148.5 514.5)" fill="#fff">
            <path d="M 8 15 C 4.140190124511719 15 1 11.85980987548828 1 8 C 1 4.140190124511719 4.140190124511719 1 8 1 C 11.85980987548828 1 15 4.140190124511719 15 8 C 15 11.85980987548828 11.85980987548828 15 8 15 Z" stroke="none" />
            <path d="M 8 2 C 4.691590309143066 2 2 4.691590309143066 2 8 C 2 11.30841064453125 4.691590309143066 14 8 14 C 11.30841064453125 14 14 11.30841064453125 14 8 C 14 4.691590309143066 11.30841064453125 2 8 2 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z" stroke="none" fill="#121212" />
          </g>
        </g>
      </svg>
    </div>`
  ,
  [PositionDirectionTypeEnum.middle]: `
    <div class="markerDirection middle {type}">
      <div class="char">{char}</div>
    </div>
  `,
  [PositionDirectionTypeEnum.end]: `
    <div class="markerDirection end">
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(-504 -280)" >
          <g transform="translate(504 280)" fill="#fff">
            <path d="M 8 15 C 4.140190124511719 15 1 11.85980987548828 1 8 C 1 4.140190124511719 4.140190124511719 1 8 1 C 11.85980987548828 1 15 4.140190124511719 15 8 C 15 11.85980987548828 11.85980987548828 15 8 15 Z" stroke="none" />
            <path d="M 8 2 C 4.691590309143066 2 2 4.691590309143066 2 8 C 2 11.30841064453125 4.691590309143066 14 8 14 C 11.30841064453125 14 14 11.30841064453125 14 8 C 14 4.691590309143066 11.30841064453125 2 8 2 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z" stroke="none" fill="#121212" />
          </g>
          <circle id="Ellipse_1066" data-name="Ellipse 1066" cx="3" cy="3" r="3" transform="translate(509 285)" fill="#121212" />
        </g>
      </svg>
      <div class="location">
        <svg width="24" height="31.385" viewBox="0 0 32 39.385">
          <g>
            <g transform="translate(3.08 1.816)">
              <path d="M78.491,37.135a13.279,13.279,0,0,0-5.844-4.877A12.989,12.989,0,0,0,67.485,31.2h-.026A12.989,12.989,0,0,0,62.3,32.259a13.317,13.317,0,0,0-5.831,4.877,13.6,13.6,0,0,0-2.266,7.53v.026h0v.065h0a13.479,13.479,0,0,0,2.291,7.465c.438.706,2.37,2.628,4.584,5.138a18.081,18.081,0,0,1,3.708,6.06l.359.837a36.878,36.878,0,0,1,1.364,4.19,1.025,1.025,0,0,0,1.957,0,26.406,26.406,0,0,1,1.208-4.014l.3-.772a19.428,19.428,0,0,1,3.833-6.3c2.214-2.51,4.233-4.432,4.67-5.138a13.511,13.511,0,0,0,2.291-7.465h0v-.065h0v-.026A13.711,13.711,0,0,0,78.491,37.135Z" transform="translate(-54.2 -31.2)" fill="#e40000" stroke="#b00020" stroke-width="1" />
            </g>
            <ellipse cx="4.5" cy="4.57" rx="4.5" ry="4.57" transform="translate(12 10.156)" fill="#b00020" />
          </g>
        </svg>
      </div>
    </div>
`
}

const anchorDict = {
  [PositionDirectionTypeEnum.start]: [0.5, 0.5],
  [PositionDirectionTypeEnum.middle]: [0.5, 0.5],
  [PositionDirectionTypeEnum.end]: [0.5, 0.8],
}
const MarkerDirection = (props) => {
  const { name, position, type, index, sortIndex, disableDrag } = props

  const markerRef = useRef(new map4d.Marker({
    position: position,
    draggable: true,
    anchor: anchorDict[type] || [0.5, 0.5],
    iconView: IconDict[PositionDirectionTypeEnum.start],
    labelAnchor: [1, 0.5],
  }))

  useEffect(() => {
    disableDrag ? markerRef.current.setDraggable(false) : markerRef.current.setDraggable(true)
  }, [disableDrag])

  useEffect(() => {
    markerRef.current.setMap(AppData.map)
    return () => {
      markerRef.current.setMap(null)
    }
  }, [])

  useEffect(() => {
    markerRef.current.setLabel(
      new map4d.MarkerLabel({
        text: name || `${position.lat?.toFixed(6)}, ${position.lng?.toFixed(6)}`,
        anchor: [0, 0.5]
      })
    )
  }, [name, position])
  useEffect(() => {

    const timeout = setTimeout(() => {
      markerRef.current.setPosition(position)
    }, 300)
    return () => {
      timeout != undefined && clearTimeout(timeout)
    }
  }, [position])
  useEffect(() => {
    let ascii = sortIndex + 64
    let char = String.fromCharCode(ascii)
    let typeClass = "none"
    if (sortIndex != index) {
      typeClass = "highlight"
    }
    markerRef.current.setIconView(IconDict[type]?.replace("{char}", char)?.replace("{type}", typeClass))
    markerRef.current?.setAnchor(anchorDict[type])
  }, [type, sortIndex, index])
  useEffect(() => {
    markerRef.current.setUserData({
      index: index
    })
  }, [index])
  return null
}

MarkerDirection.propTypes = {
  name: PropTypes.string,
  position: PropTypes.any,
  type: PropTypes.any,
  index: PropTypes.number,
  sortIndex: PropTypes.number,
  disableDrag: PropTypes.bool
};

export default MarkerDirection;
