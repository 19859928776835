import { Box, Button, Drawer, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppConfig, ImageConfig } from '../../../config';
import { OperatingSystemEnum, SessionEnum } from '../../../enum';
import { AppTool, JsonTool, UriTool } from '../../../tool';
import { en } from './../../../language/en';
import { LinkIconConfig } from './../../../config/linkIconConfig';
const useStyles = makeStyles({
  button: {
    fontSize: '12px'
  }
})
function UseInApp() {
  const { id, lat, lng, data } = useParams()

  const location = useLocation()

  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const aRef = useRef()


  const isAndroidOrIos = () => {
    let typeHDH = AppTool.getOperatingSystem()
    if (typeHDH == OperatingSystemEnum.android || typeHDH == OperatingSystemEnum.ios) {
      return true
    }
    else {
      return false
    }
  }

  useEffect(() => {
    let closeUseInApp = sessionStorage.getItem(SessionEnum.closeUseInApp)
    if ((!closeUseInApp || closeUseInApp?.toLowerCase() != "true") && isAndroidOrIos() && !AppTool.isAppLink()) {
      setOpen(true)
    }
  }, [])

  const getAppForIos = () => {
    let link = AppConfig.appDomain + location.pathname + location.search
    return link
  }

  const onClickClose = () => {
    sessionStorage.setItem(SessionEnum.closeUseInApp, true)
    setOpen(false)
  }

  const onClickUseInApp = (e) => {
    if (AppTool.getOperatingSystem() == OperatingSystemEnum.android) {
      // setTimeout(() => {
      //   if (window.document.visibilityState == "visible") {
      //     window.location.href = this.getLinkToApp()
      //   }
      // }, 1000);
      aRef.current.href = getAppForIos()
    }
    else if (AppTool.getOperatingSystem() == OperatingSystemEnum.ios) {
      aRef.current.href = getAppForIos()
    }
    aRef.current.click()
  }

  return (
    <Drawer anchor='bottom' open={open} transitionDuration={500}>
      <Box height='160px' width='100%'>
        <ListItem >
          <ListItemIcon >
            <img src={AppTool.isVimap() ? LinkIconConfig.appIcon.vimap : LinkIconConfig.appIcon.map4d} alt='logo' width='50px' height='50px' />
          </ListItemIcon>
          <ListItemText
            primary='Tiếp tục trong ứng dụng'
            secondary='Nhận điều hướng thời gian thực và hơn thế nữa trong ứng dụng Bản đồ'
          />
        </ListItem>
        <Box display='flex' justifyContent='flex-end' p={1}>
          <Button onClick={onClickClose} className={classes.button}>Ở lại trên web</Button>
          <Button onClick={onClickUseInApp} className={classes.button} variant='contained' color='primary'>Sử dụng ứng dụng</Button>
          <a hidden ref={aRef}></a>
        </Box>
      </Box>
    </Drawer>
  )
}

UseInApp.propTypes = {
  //
};

export default UseInApp;
