import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { TextInput } from 'ui';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';
import Dialog from '../dialog/dialog';
import DialogBody from '../dialogBody/dialogBody';
import './mobileTimePicker.scss';

const ICON_CLOCK =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <g>
      <path d="M10.333,18.667a8.333,8.333,0,1,1,8.333-8.333A8.333,8.333,0,0,1,10.333,18.667Zm0-1.667a6.667,6.667,0,1,0-6.667-6.667A6.667,6.667,0,0,0,10.333,17Zm.833-6.667H14.5V12h-5V6.167h1.667Z" transform="translate(-0.333 -0.333)" fill="#505050" />
    </g>
  </svg>

const REGEX_NUMBER = /^\d+$/

const createCurrentTime = () => {
  let currentDate = new Date()
  let hour = currentDate?.getHours()
  let minute = currentDate?.getMinutes()
  return {
    hour: hour,
    minute: minute,
  }
}

const padToTwoDigits = (num) => {
  return num?.toString().padStart(2, '0')
}

const TYPE_TIME = {
  minute: 'minute',
  hour: 'hour'
}
const MobileTimePicker = (props) => {
  const { value, onChange, disabled, minTime } = props
  const [open, setOpen] = useState(false)
  const [timeState, setTimeState] = useState(() => {
    let time = new Date()
    return {
      year: time?.getFullYear(),
      month: time?.getMonth(),
      day: time?.getDate(),
      hour: time?.getHours(),
      minute: time?.getMinutes()
    }
  })
  const [timeValue, setTimeValue] = useState(timeState)

  const _minTime = useRef(timeValue)
  const _formatDate = useRef()

  useEffect(() => {
    if (minTime) {
      let time = new Date(minTime)
      _minTime.current = {
        year: time?.getFullYear(),
        month: time?.getMonth(),
        day: time?.getDate(),
        hour: time?.getHours(),
        minute: time?.getMinutes()
      }
    }
  }, [minTime])

  useEffect(() => {
    if (value) {
      let time = {
        year: value?.getFullYear(),
        month: value?.getMonth(),
        day: value?.getDate(),
        hour: value?.getHours(),
        minute: value?.getMinutes()
      }
      setTimeValue(time)
      setTimeState(time)
      _formatDate.current = value
    }
  }, [value])

  const onClickInput = (e) => {
    if (!disabled) {
      setOpen(true)
    }
  }

  const onChangeHour = (e) => {
    let value = e?.target.value
    setTimeValue((prev) => {
      return {
        ...prev,
        hour: value
      }
    })
  }

  const onChangeMinute = (e) => {
    let value = e?.target.value
    setTimeValue((prev) => {
      return {
        ...prev,
        minute: value
      }
    })
  }

  const onSave = () => {
    setOpen(false)
    let date = _formatDate.current
    date?.setHours(timeValue?.hour)
    date?.setMinutes(timeValue?.minute)
    onChange?.(date)
    setTimeState(timeValue)
  }

  const onCancel = (e) => {
    setOpen(false)
    setTimeValue(timeState)
  }

  const onBlurHour = (e) => {
    let value = e?.target.value
    let checkMin = checkMinTime(timeValue, value, TYPE_TIME.hour, _minTime.current)
    if (value > 23 || !value?.match(REGEX_NUMBER) || checkMin) {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          hour: currentTime?.hour,
        }
      })
    }
  }

  const onBlurMinute = (e) => {
    let value = e?.target.value
    let checkMin = checkMinTime(timeValue, value, TYPE_TIME.minute, _minTime.current)
    if (value > 59 || !value?.match(REGEX_NUMBER) || checkMin) {
      let currentTime = createCurrentTime()
      setTimeValue((prev) => {
        return {
          ...prev,
          minute: currentTime?.minute,
        }
      })
    }
  }

  const checkMinTime = (newDate, valueCheck, typeTime, minTime) => {
    let result = false
    if (newDate?.year == minTime?.year && newDate?.month == minTime?.month && newDate?.day == minTime?.day) {
      if (typeTime == TYPE_TIME.hour) {
        if (valueCheck < minTime?.hour) result = true
      }
      else if (typeTime == TYPE_TIME.minute) {
        if (valueCheck < minTime?.minute) result = true
      }
    }
    return result
  }
  return (
    <div className='mobileTimePickerCpn'>
      <div className={StringTool.mergeClassName('timeInput', disabled ? 'disabled' : '')}>
        <div className='prefixIcon'>
          {ICON_CLOCK}
        </div>

        <div className='textInput' onClick={onClickInput}>
          {`${padToTwoDigits(timeState?.hour)}:${padToTwoDigits(timeState?.minute)}`}
        </div>
      </div>
      <Dialog open={open} keepAnother style={{ minWidth: "17.125rem", maxWidth: "17.125rem" }}>
        <DialogBody>
          <div className='selectTimeContainerMobile'>
            <div className='title'>
              {Resource.common.selectHour}
            </div>

            <div className='content'>
              <div className='inputHour'>
                <TextInput
                  value={timeValue?.hour}
                  onChange={onChangeHour}
                  hideClear
                  maxLength={2}
                  className='inputTime'
                  onBlur={onBlurHour}
                />
                <div className='label'>
                  {Resource.common.hour}
                </div>
              </div>

              <div className='colons'>
                :
              </div>

              <div className='inputHour'>
                <TextInput
                  value={timeValue?.minute}
                  onChange={onChangeMinute}
                  hideClear
                  maxLength={2}
                  className='inputTime'
                  onBlur={onBlurMinute}
                />
                <div className='label'>
                  {Resource.common.minutes}
                </div>
              </div>

            </div>

            <div className='action'>
              <button onClick={onCancel} className='button'>
                {Resource.button.cancel.toUpperCase()}
              </button>
              <button onClick={onSave} className={StringTool.mergeClassName('button', 'primary')}>
                {Resource.button.done.toUpperCase()}
              </button>
            </div>

          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

MobileTimePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  minTime: PropTypes.any
};

export default MobileTimePicker;
