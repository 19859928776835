import { Resource } from "../resource";
import { PlaceExtensionTypeEnum } from "./placeExtensionTypeEnum";

export const TitlePlaceExtend = Object.freeze({
    [PlaceExtensionTypeEnum.video]: Resource.common.video,
    [PlaceExtensionTypeEnum.videoEmbed]: Resource.common.video,
    [PlaceExtensionTypeEnum.videoLink]: Resource.common.video,
    [PlaceExtensionTypeEnum.article]: Resource.common.article,
    [PlaceExtensionTypeEnum.popup]: Resource.common.popup,
    [PlaceExtensionTypeEnum.link]: Resource.common.link,
    [PlaceExtensionTypeEnum.vr360]: Resource.common.vr360,
})