import React, { useState } from "react";
import ListPoiNearByLocation from "./listPoiNearByLocation/listPoiNearByLocation";
import SuggestAlongDirection from "./suggestAlongDirection/suggestAlongDirection";
import RoadAvoid from "./roadAvoid/roadAvoid";

const Bao = () => {
  const [value, setValue] = useState(0);
  const handleChange = (e) => {
    setValue(e);
  };
  return (
    <div style={{position: 'relative', width: "500px", height: "100%" }}>
      <SuggestAlongDirection/>
      {/* <ListPoiNearByLocation /> */}
    </div>
  );
};
export default Bao;
