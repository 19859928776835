import PropTypes from 'prop-types';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Chip } from 'ui';
import { OpenSideBarState } from '../../appState/openSideBar';
import { LinkIconConfig } from '../../config';
import { ModeEnum, PlaceTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { useWindowSize } from '../../useHooks';
import DragToScrollV2 from '../dragToScrollV2/dragToScrollV2';
import './suggestPlaceDirection.scss';

const SuggestPlaceDirection = (props) => {
  const carSuggestPlace = [ //car or motorcycle
    {
      name: Resource.suggestPlaceChip.gasStation,
      icon: LinkIconConfig.suggestPlaceChip.unActive.gasStation,
      type: PlaceTypeEnum.gas
    },
    {
      name: Resource.suggestPlaceChip.charging,
      icon: LinkIconConfig.suggestPlaceChip.unActive.charging,
      type: PlaceTypeEnum.charging
    },
    {
      name: Resource.suggestPlaceChip.hotel,
      icon: LinkIconConfig.suggestPlaceChip.unActive.hotel,
      type: PlaceTypeEnum.hotel
    }
  ]
  
  const bikeSuggestPlace = [// bike or foot
    {
      name: Resource.suggestPlaceChip.coffee,
      icon: LinkIconConfig.suggestPlaceChip.unActive.coffee,
      type: PlaceTypeEnum.coffee
    },
    {
      name: Resource.suggestPlaceChip.grocery,
      icon: LinkIconConfig.suggestPlaceChip.unActive.grocery,
      type: PlaceTypeEnum.grocery
    },
    {
      name: Resource.suggestPlaceChip.thingsToDo,
      icon: LinkIconConfig.suggestPlaceChip.unActive.museum,
      type: PlaceTypeEnum.touristAttract
    }
  ]
  
  const { vehicle, onClick, onClickMore } = props
  const windowSize = useWindowSize()
  const isOpen = useRecoilValue(OpenSideBarState)

  const handleSearchSuggestPlace = (suggest) => () => {
    onClick && onClick(suggest)
  }

  const handleToAllSuggest = () => {
    onClickMore && onClickMore()
  }

  return (
    <DragToScrollV2>
      <div className='suggestPlaceDirection'
        style={{
          maxWidth: isOpen ? `${windowSize?.width - 639}px` : `${windowSize?.width - 219}px`
        }}
      >
        {
          ((vehicle == ModeEnum.car || vehicle == ModeEnum.motorcycle) ? carSuggestPlace : bikeSuggestPlace)?.map((suggest, index) => {
            return (
              <Chip
                key={suggest.type + `${index}` + 'chip'}
                icon={<img src={suggest?.icon} alt={`icon ${suggest.type}`} />}
                onClick={handleSearchSuggestPlace(suggest)}
                className={`itemSuggestPlace`}
              >
                <span className='chipName'>{suggest.name}</span>
              </Chip>
            )
          })
        }
        <div className="popoverMore">
          <Chip
            key="iconSugesstMore"
            icon={<img src={LinkIconConfig.suggestPlaceChip.unActive.search} />}
            onClick={handleToAllSuggest}
          >
            {Resource.suggestPlaceChip.more}
          </Chip>
        </div>

      </div>
    </DragToScrollV2>
  );
};

SuggestPlaceDirection.propTypes = {
  vehicle: PropTypes.string,
  onClick: PropTypes.func,
  onClickMore: PropTypes.func
};

export default SuggestPlaceDirection;
