import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { MFMap } from "react-map4d-map";
import { matchPath } from "react-router";
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { MapTypeAppState, ShowCameraControlState } from "../../../appState";
import { CommonConfig } from "../../../config";
import { UrlConfig } from "../../../config/urlConfig";
import { AppData } from "../../../data";
import { CodeEnum, CookieEnum, MapEventEnum, MapTypeEnum } from "../../../enum";
import { ApiTool } from "../../../tool";
import LinearBuffer from "../linearBuffer/linearBuffer";
import { AppConfig } from './../../../config/appConfig';
import { LocalStorageEnum } from './../../../enum/localStorageEnum';
import { CookieTool } from "./../../../tool";
import { UrlTool } from './../../../tool/urlTool';
import "./mapView.css";

const newMapId = UrlTool.queryToJson(window.location.search)?.mapid
AppData.mapid = newMapId
function MapView({ onLoadedMap, height, width }) {
    const location = useLocation()

    useEffect(() => {
        let search = { ...UrlTool.queryToJson(location.search) || {} };
        search.mapid = AppData.mapid;
        let url = location.pathname + UrlTool.jsonToQuery(search);
        window.history.replaceState(null, null, url)
    }, [location.pathname])

    const setShowCameraControl = useSetRecoilState(ShowCameraControlState)
    const mapRef = useRef();
    const [onInited, setOnInited] = useState(false)
    const [mapId, setMapId] = useState("")
    const [options, setOptions] = useState(null)
    const eventBoundChangeRef = useRef()
    const isChangedRef = useRef(false)

    const setMapTypeAppState = useSetRecoilState(MapTypeAppState)

    const createMapType = () => {
        let mapParamsInit = UrlTool.getCameraInfo()
        let mapType = MapTypeEnum.map3d
        if (mapParamsInit?.mapType) {
            mapType = mapParamsInit.mapType
        }
        else {
            mapType = CookieTool.get(CookieEnum.mapType) || MapTypeEnum.roadmap
        }
        setMapTypeAppState(mapType)
    }
    const initMap = () => {
        setMapId(newMapId)
        let mapParamsInit = { ...CommonConfig.defaultMapOptions, ...UrlTool.getCameraInfo() };
        let lat = localStorage.getItem(LocalStorageEnum.myLastLat)
        let lng = localStorage.getItem(LocalStorageEnum.myLastLng)
        if (lat && lng && !UrlTool.queryToJson(window.location.search)?.camera) {
            mapParamsInit.center = [+lng, +lat]
            mapParamsInit.zoom = 15
        }
        if (matchPath(location.pathname, { path: "/embed" })) {
            mapParamsInit.cooperativeGestures = true
        }

        setOptions(mapParamsInit)
    };
    const checkAndMoveToMyLocation = () => {
        if (!UrlTool.queryToJson(window.location.search)?.camera) {
            ApiTool.get(UrlConfig.ip.info, res => {
                if (res?.code == CodeEnum.ok) {
                    if (!isChangedRef.current) {
                        let center = [+res?.result?.lng, +res?.result?.lat]
                        let zoom = 15
                        let camera = mapRef.current?.getCamera()
                        camera?.setTarget(center)
                        camera?.setZoom(zoom)
                        mapRef.current?.moveCamera(camera)
                        onLoadedMap && onLoadedMap(mapRef.current);
                    }
                }
                else {
                    onLoadedMap && onLoadedMap(mapRef.current);
                }
                setShowCameraControl(true)
            }, false, 2000)
        }
        else {
            onLoadedMap && onLoadedMap(mapRef.current);
            setShowCameraControl(true)
        }
    }

    const getBounds = () => {
        let bounds = mapRef.current?.getBounds();
        if (bounds?.getNortheast() && bounds?.getSouthwest()) {
            setOnInited(true)
            onLoadedMap && onLoadedMap(mapRef.current);
        } else {
            setTimeout(() => {
                getBounds();
            }, 100);
        }
    };
    const onMapReady = (map) => {
        mapRef.current = map
        setOnInited(true)
        createMapType()
        eventBoundChangeRef.current = map.addListener(MapEventEnum.cameraWillChange, (args) => {
            isChangedRef.current = true
        })
        checkAndMoveToMyLocation()
    }

    useEffect(() => {
        initMap();
    }, []);
    return (
        <Box position={"relative"} height={height} width={width}>
            {
                options &&
                <MFMap environment={AppConfig.environment} mapid={mapId} options={options} version="2.6" accessKey={AppConfig.mapKey} onMapReady={onMapReady} />
            }
            {
                !onInited &&
                <Box bgcolor="#ffffff" position="absolute" width="100%" height="100%" left={0} top={0}>
                    <LinearBuffer />
                </Box>
            }
        </Box>

    );
}

MapView.propTypes = {
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    onLoadedMap: PropTypes.func,
};

export default MapView;
