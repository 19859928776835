import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContent, DialogTitle, Divider, FormControl, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import { LinkIconConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { AlertTypeEnum, CodeEnum, GeometryTypeEnum, RequestTypeEnum, TypeGeoJsonEnum, TypeRequestUpdateEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool, UrlTool } from '../../../tool';
import { useIsMobileScreen } from '../../../useHooks';
import DialogBody from '../../dialogBody/dialogBody';
import Dialog from '../../dialog/dialog';
import DialogHeader from '../../dialogHeader/dialogHeader';
import PropTypes from 'prop-types';

import './requestUpdateBtn.scss'
import DialogAction from '../../dialogAction/dialogAction';
import { Button } from 'ui';
import ConfirmV2 from '../../confirmV2/confirmV2';
import { useId } from 'react';
const useStyles = makeStyles({
  suggestEdit: {
    borderRadius: '24px',
    textTransform: 'inherit',
    border: '1px solid #E4E4E4',
    padding: '11px 24px !important',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#121212',
  },
  dialogMessage: {
    width: '360px'
  },
  dialogReason: {
    maxWidth: '100%',
    width: '622px'
  },
  icon: {
    minWidth: '45px'
  },
  dialogTitle: {
    textAlign: 'center'
  },
  marginBottomText: {
    marginBottom: '10px'
  },
})

function RequestUpdateBtn({ id, type, placeName, isButton, typeGeo }) {
  const classes = useStyles()
  const history = useHistory()
  const [showDialogMessage, setShowDialogMessage] = useState(false)
  const [showDialogRequestReason, setShowDialogRequestReason] = useState(false)
  const [place, setPlace] = useState([])
  const [building, setBuilding] = useState([])
  const isMobile = useIsMobileScreen()

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const FORM_ID = useId()
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [reason, setReason] = useState('')

  useEffect(() => {
    if (id) {
      var sourceDetail
      let body = {
      }
      if (type == TypeRequestUpdateEnum.building) {
        sourceDetail = ApiTool.queryGetFromJson(UrlConfig.object.detail.replace('{id}', id), body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let buildingCustom = {
              ...res?.result,
              objectId: id,
              requestTypes: [
                RequestTypeEnum.delete
              ]
            }
            setBuilding(buildingCustom)
          }
        })

      }
      else {
        sourceDetail = ApiTool.queryGetFromJson(UrlConfig.poi.detail.replace('{id}', id), body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let placeCustom = {
              ...res?.result,
              id: null,
              placeId: id,
              requestTypes: [
                RequestTypeEnum.delete
              ]
            }
            setPlace(placeCustom)
          }
        })
      }
    }
    return () => {
      sourceDetail?.cancel()
    }
  }, [id])

  const checkTypeJson = (typeGeoJson) => {
    if (typeGeoJson == TypeGeoJsonEnum.lineString || typeGeoJson == TypeGeoJsonEnum.multiLineString) { //street
      return 1
    }
    else if (typeGeoJson == TypeGeoJsonEnum.polygon || typeGeoJson == TypeGeoJsonEnum.multiPolygon) {//boundary
      return 2
    }
    else return undefined
  }

  const handleShowPopupMessage = () => {
    setShowDialogMessage(true)

  }

  const handleClosePopupMessage = () => {
    setShowDialogMessage(false)
  }

  const handleShowPopupRequestReason = () => {
    setShowDialogRequestReason(true)
    setReason(null)
    clearErrors("changeReason")
    setValue('changeReason', null);
  }

  const handleClosePopupRequestReason = () => {
    setShowDialogRequestReason(false)
    setShowDialogMessage(false)
    setOpenConfirmDelete(false)
  }

  const handleShowUpdate = () => {

    if (type == TypeRequestUpdateEnum.building) {
      history.push({
        pathname: RouterConfig.object3d.requestUpdate.replace(":id", id)
      })
    }
    else {
      // history.push({
      //   pathname: RouterConfig.place.requestUpdate.replace(':id', id)
      // })
      history.push({
        pathname: location.pathname,
        search: UrlTool.createSearch({ ep: 1, typeGeometry: checkTypeJson(typeGeo), id: id }, { extendOldQuery: true })
      })
      setShowDialogRequestReason(false)
      setShowDialogMessage(false)
    }
  }

  const schema = yup.object().shape({
    changeReason: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.updatePlace.reason
    })),
  })

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const { register, handleSubmit, errors, formState, setValue, clearErrors } = form

  register({ name: "changeReason" })
  const onChangeReason = (e) => {
    let reason = e.target.value
    setReason(reason)
    setValue('changeReason', reason, { shouldValidate: formState.isSubmitted });
  }

  const requestBoundary = (data) => {
    let body = {
      placeId: place?.placeId,
      actionReason: data?.changeReason
    }
    setLoadingSubmit(true)
    ApiTool.post(UrlConfig.boundaryEdit.requestDelete, body, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        setShowDialogRequestReason(false)
        setShowDialogMessage(false)
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.error)
      }
    }, true)
  }

  const requestStreet = (data) => {
    let body = {
      placeId: place?.placeId,
      actionReason: data?.changeReason,
    }
    setLoadingSubmit(true)
    ApiTool.post(UrlConfig.roadEdit.requestDelete, body, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        setShowDialogRequestReason(false)
        setShowDialogMessage(false)
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.error)
      }
    }, true)
  }

  const requestBuilding = (data) => {
    let params = {
      // ...building,
      objectId: building?.id,
      reason: data?.changeReason,
    }
    setLoadingSubmit(true)
    ApiTool.post(UrlConfig.objectEdit.requestDelete, params, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.request, {
            action: Resource.common.delete.toLowerCase(),
            object: Resource.object.name.toLowerCase() + ' 3D '
          }),
          AlertTypeEnum.success
        )
        setShowDialogRequestReason(false)
        setShowDialogMessage(false)
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.request, {
            action: Resource.common.delete.toLowerCase(),
            object: Resource.object.name.toLowerCase() + ' 3D '
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const requestPoi = (data) => {
    let body = {
      placeId: place?.placeId,
      reason: data?.changeReason,
    }
    setLoadingSubmit(true)
    ApiTool.post(UrlConfig.poiEdit.requestDelete, body, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        setShowDialogRequestReason(false)
        setShowDialogMessage(false)
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.request, {
            action: Resource.common.delete.toLowerCase(),
            object: labelType.toLowerCase()
          }),
          AlertTypeEnum.error)
      }
    }, true)
  }

  const onSubmit = (data) => {
    if (type == TypeRequestUpdateEnum.boundary) {
      requestBoundary(data)
    }
    else if (type == TypeRequestUpdateEnum.street) {
      requestStreet(data)
    }
    else if (type == TypeRequestUpdateEnum.building) {
      requestBuilding(data)
    }
    else {
      requestPoi(data)
    }
  }

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true)
  }

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false)
  }

  let labelType = (place?.geometry?.type == GeometryTypeEnum.lineString || place?.geometry?.type == GeometryTypeEnum.multiLineString) ?
    Resource.common.street :
    (
      (place?.geometry?.type == GeometryTypeEnum.polygon || place?.geometry?.type == GeometryTypeEnum.multiPolygon) ?
        Resource.common.area :
        Resource.common.place || ''
    )
  const EditTypePlaceRequest = {
    [TypeRequestUpdateEnum.poi]: Resource.updatePlace.requestUpdatePoi,
    [TypeRequestUpdateEnum.building]: Resource.updatePlace.requestUpdateObject,
    [TypeRequestUpdateEnum.street]: Resource.updatePlace.requestUpdateRoad
  }

  const DeleteTypePlaceRequest = {
    [TypeRequestUpdateEnum.poi]: Resource.updatePlace.deleteThisPlace,
    [TypeRequestUpdateEnum.building]: Resource.updatePlace.deleteThisObject,
  }

  const listButton = [
    {
      value: "editButton",
      title: EditTypePlaceRequest[type],
      caption: Resource.updatePlace.changeTheNameOrOtherDetails,
      linkIcon: LinkIconConfig.placeDetail.pencil,
      onClick: handleShowUpdate,
      isShow: !isMobile
    },
    {
      value: "deleteButton",
      title: DeleteTypePlaceRequest[type],
      caption: Resource.updatePlace.markClosedNonexistentOrDuplicated,
      linkIcon: LinkIconConfig.common.deletePlace,
      onClick: handleShowPopupRequestReason,
      isShow: true
    }
  ]
  return (
    <>
      {
        isButton ?
          <div className='requestUpdateButtonCpn'>
            <Button
              onClick={handleShowPopupMessage}
              prefixIcon={<img src={LinkIconConfig.placeDetail.pencil} width={24} height={24} />}
              type='button'
              variant='cancel'
            >{Resource.updatePlace.requestUpdate}</Button>
          </div>
          :
          <ListItem button onClick={handleShowPopupMessage}>
            <Typography variant="body2">{Resource.updatePlace.requestUpdate}</Typography>
          </ListItem>
      }

      <Dialog
        fullScreen={isMobile}
        open={showDialogMessage}
        onClose={handleClosePopupMessage}
      >
        <DialogHeader subtitle={placeName}>
          {Resource.updatePlace.requestUpdate}
        </DialogHeader>
        <DialogBody>
          <div className='boxListButtonContainer'>
            {
              listButton?.map(item => {
                if (item?.isShow) {
                  return (
                    <div key={item?.value} className='boxButtonCpn' onClick={item?.onClick}>
                      <img src={item?.linkIcon} width={24} height={24} alt={item?.value} />
                      <div className='boxInfoButton'>
                        <div className='titleInfo'>{item?.title}</div>
                        <div className='captionInfo'>{item?.caption}</div>
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        </DialogBody>
      </Dialog>

      <Dialog
        fullScreen={isMobile}
        open={showDialogRequestReason}
      >
        <DialogHeader subtitle={placeName} hideClose>
          {Resource.updatePlace.requestUpdate}
        </DialogHeader>
        <DialogBody>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <DialogContent>
              <Box p='0 0 20px 0'>
                <Typography variant='caption'>{Resource.updatePlace.yourRecommendation}</Typography>
                <Typography variant='subtitle2'>{Resource.updatePlace.markAsClosedOrDeleted}</Typography>
              </Box>
              <Divider />
              <Box p='20px 0'>
                <FormControl fullWidth>
                  <Typography variant='body2' className={classes.marginBottomText}>{Resource.updatePlace.reason}<span style={{ color: 'red', paddingLeft: '6px' }}> *</span></Typography>
                  <TextField
                    size='medium'
                    name='changeReason'
                    label={Resource.updatePlace.addRemovalReason}
                    variant="outlined"
                    onChange={onChangeReason}
                    // inputRef={register({ name: "changeReason" })}
                    error={errors?.changeReason ? true : false}
                    helperText={errors?.changeReason?.message}
                    value={reason || ''}
                  />
                </FormControl>
              </Box>
            </DialogContent>
          </form>
        </DialogBody>
        <DialogAction>
          <Button variant='cancel' onClick={handleOpenConfirmDelete}>{Resource.button.cancel}</Button>
          <div className='buttonDoneDelete'>
            <Button variant='done' type='submit' disabled={loadingSubmit} form={FORM_ID} >{Resource.button.send}</Button>
          </div>
          <ConfirmV2
            open={openConfirmDelete}
            onConfirm={handleClosePopupRequestReason}
            type="warning"
            message={
              Resource.formatString(
                Resource.confirmMessage.cancelRequest,
                {
                  object: (type == TypeRequestUpdateEnum.building) ? Resource.object.name.toLowerCase() + ' 3D ' : labelType.toLowerCase(),
                  action: Resource.common.delete.toLowerCase()
                }
              )
            }
            onClose={handleCloseConfirmDelete}
          />
        </DialogAction>
      </Dialog>

      {/* <Dialog
        fullScreen={fullScreen}
        open={showDialogRequestReason}
        onClose={handleClosePopupRequestReason}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xl'
        classes={
          {
            paper: classes.dialogReason
          }
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">{Resource.updatePlace.requestUpdate} <Typography variant='body2'>{name}</Typography></DialogTitle>
          <Divider />
          <DialogContent>
            <Box p='20px 0'>
              <Typography variant='caption'>{Resource.updatePlace.yourRecommendation}</Typography>
              <Typography variant='subtitle2'>{Resource.updatePlace.markAsClosedOrDeleted}</Typography>
            </Box>
            <Divider />
            <Box p='20px 0'>
              <FormControl fullWidth>
                <Typography variant='body2' className={classes.marginBottomText}>{Resource.updatePlace.reason}<span style={{ color: 'red', paddingLeft: '6px' }}> *</span></Typography>
                <TextField
                  size='medium'
                  name='changeReason'
                  label={Resource.updatePlace.addRemovalReason}
                  variant="outlined"
                  onChange={onChangeReason}
                  inputRef={register}
                  error={errors?.changeReason ? true : false}
                  helperText={errors?.changeReason?.message}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box width='100%' textAlign='center'>
              <Confirm
                onConfirm={handleClosePopupRequestReason}
                message={
                  Resource.formatString(
                    Resource.confirmMessage.cancelRequest,
                    {
                      object: (type == TypeRequestUpdateEnum.building) ? Resource.object.name.toLowerCase() + ' 3D ' : labelType.toLowerCase(),
                      action: Resource.common.delete.toLowerCase()
                    }
                  )
                }
              >
                <ActionButton name={Resource.button.cancel} isSecondary />
              </Confirm>

              <ActionButton name={Resource.button.send}  />
            </Box>
          </DialogActions>
        </form>
      </Dialog> */}

    </>
  )
}

RequestUpdateBtn.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  placeName: PropTypes.string,
  isButton: PropTypes.bool,
  typeGeo: PropTypes.any
};

export default RequestUpdateBtn;
