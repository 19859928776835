import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PlaceRelatedState, PlaceTypeState } from '../../../appState';
import DescriptionDisplay from '../../../components/common/descriptionDisplay/descriptionDisplay';
import RequestUpdateBtn from '../../../components/common/requestUpdateBtn/requestUpdateBtn';
import ListPlaceRelated from '../../../components/listPlaceRelated/listPlaceRelated';
import MobileDragBody from '../../../components/mobileDragBody/mobileDragBody';
import PlaceRelatedV2 from '../../../components/placeRelatedV2/placeRelatedV2';
import Skeleton from '../../../components/skeleton/skeleton';
import { LinkIconConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { AppData } from '../../../data';
import { CodeEnum, MapTypeEnum, TypeRequestUpdateEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { AddressTool, ApiTool, AppTool, StringTool, UrlTool, UserTool } from '../../../tool';
import { useQuery, useWindowSize } from '../../../useHooks';
import MobileDirectionButtonV2 from '../mobileDirectionButtonV2/mobileDirectionButtonV2';
import MobileShareButton from '../mobileShareButton/mobileShareButton';
import MobileSwipeableViewsImages from '../mobileSwipeableViewsImages/mobileSwipeableViewsImages';
import { MapTypeAppState } from './../../../appState/mapTypeAppState';
import './mobileObject3dDetail.scss';

const maxHeightImg = 240
const minHeightImg = 140
const START_HEIGHT_TO_DRAG = 288

const ICON_BACK_SEARCH =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#000000'>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
  </svg>

const ICON_BACK =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#505050'>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
  </svg>

const ICON_SEARCH =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#505050'>
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
  </svg>

function MobileObject3dDetail(props) {

  const { id, type } = useParams()

  const { text } = useQuery()
  const history = useHistory()
  const location = useLocation()

  const topRef = useRef()
  const _avatarPlaceHtml = useRef(null)

  const windowSize = useWindowSize()

  const [isFull, setIsFull] = useState(false)
  const [loading, setLoading] = useState(true)

  const [object3D, setObject3D] = useState(null)
  const [showHeader, setShowHeader] = useState(false)
  const [heightView, setHeightView] = useState(START_HEIGHT_TO_DRAG)

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState)
  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const placeRelatedState = useRecoilValue(PlaceRelatedState)

  useEffect(() => {
    setMapTypeAppState(MapTypeEnum.map3d)
    return () => {
      AppData.map.setSelectedBuildings([])
    }
  }, [])

  useEffect(() => {
    var sourcePlaces
    setLoading(true)
    sourcePlaces = ApiTool.get(UrlConfig.object.detail.replace("{id}", id), (res) => {
      setLoading(false)
      if (res?.code == CodeEnum.ok) {
        AppData.map.setSelectedBuildings([res?.result.id])
        setObject3D(res?.result)
        if (!location.state?.disableMoveMap) {
          moveCamera(res?.result)
        }
      }
      else {
        setObject3D(null)
      }
    })
    return () => {
      sourcePlaces?.cancel()
    }
  }, [id])

  const moveCamera = (object3D) => {
    let camera = AppData.map.getCamera()
    if (object3D?.camera) {
      camera.setBearing(object3D?.camera?.bearing)
      camera.setTilt(object3D?.camera?.tilt)
      camera.setZoom(object3D?.camera?.zoom)
    }
    camera.setTarget(object3D?.location)
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleClickBack = () => {
    topRef.current?.scrollIntoView()
    history.push({
      pathname: RouterConfig.object3D.search.replace("/:type?", type ? "/" + type : ""),
      search: UrlTool.createSearch({ text: text })
    })
  }

  const handleSearch = () => {
    AppTool.setShowAutoComplete(true)
  }

  const handleClickBackToMinBar = () => {
    _avatarPlaceHtml.current?.scrollIntoView()
    setHeightView(START_HEIGHT_TO_DRAG)
    setIsFull(false)
    animationImageHeight(START_HEIGHT_TO_DRAG)
  }

  const dragImageHeight = (heightView) => {
    let imageHeightNew = heightView * 0.3
    if (imageHeightNew < minHeightImg) {
      imageHeightNew = minHeightImg
    }
    else if (imageHeightNew > maxHeightImg) {
      imageHeightNew = maxHeightImg
    }
    _avatarPlaceHtml.current.style.transition = "none"
    _avatarPlaceHtml.current.style.height = `${imageHeightNew}px`
  }
  const animationImageHeight = (heightView) => {
    let imageHeightNew = heightView * 0.3
    if (imageHeightNew < minHeightImg) {
      imageHeightNew = minHeightImg
    }
    else if (imageHeightNew > maxHeightImg) {
      imageHeightNew = maxHeightImg
    }
    _avatarPlaceHtml.current.style.transition = "height .3s ease-in-out"
    _avatarPlaceHtml.current.style.height = `${imageHeightNew}px`
  }

  const onChangeHeightDrag = (height) => {
    setHeightView(height)
  }

  const onScrollDetail = (e) => {
    let scrollTop = e?.currentTarget?.scrollTop
    if (scrollTop >= 200) {
      setShowHeader(true)
    }
    else {
      setShowHeader(false)
    }
  }

  const onDragViewEnd = (heightView) => {
    setIsFull(heightView != START_HEIGHT_TO_DRAG)
    animationImageHeight(heightView)
  }

  let addressFull = AddressTool.getFullAddress(object3D?.addressComponents || [])
  let disableBorder = heightView == windowSize?.height
  const user = UserTool.getUser()

  return (
    <MobileDragBody
      onDrag={dragImageHeight}
      minHeighView={START_HEIGHT_TO_DRAG}
      heightView={heightView}
      onChange={onChangeHeightDrag}
      onDragEnd={onDragViewEnd}
    >
      <div className='mobileObjectDetailCpn'>
        <div
          onScroll={onScrollDetail}
          className={StringTool.mergeClassName('boxContainer', isFull ? "boxAuto" : "", disableBorder ? 'disableBorder' : '')}
        >
          {
            isFull &&
            <div className={StringTool.mergeClassName('headerScroll', !showHeader ? 'headerTransparent' : '')}>
              <div className='iconBack' onClick={handleClickBackToMinBar}>
                {ICON_BACK}
              </div>
              <div className='title'>
                {object3D?.name}
              </div>
              <div className='iconSearch' onClick={handleSearch}>
                {ICON_SEARCH}
              </div>
            </div>
          }

          {
            text && !isFull &&
            <div className='iconBack' onClick={handleClickBack}>
              {ICON_BACK_SEARCH}
            </div>
          }
          {
            loading ?
              <div className='boxLoading'>
                <div className='avatarObject' ref={_avatarPlaceHtml} style={{ height: `${minHeightImg}px` }}>
                  <Skeleton width='100%' height='100%' />
                </div>
                <div className='mainInfo'>
                  <Skeleton width='30%' height='10px' />
                  <Skeleton width='50%' height='10px' />
                  <Skeleton width='70%' height='10px' />
                </div>
                <div className='lineBorder'></div>
                <div className='placeExtend'>
                  {
                    isFull ?
                      <div className='placeExtendCircle'>
                        {
                          Array.from({ length: 3 }, (_, i) => i).map((item) => {
                            return (
                              <div className='containerBtnCircle' key={item}>
                                <Skeleton width='3rem' height='3rem' borderRadius='50%' />
                                <Skeleton width='2.5rem' height='0.5rem' />
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      <div className='placeExtendEllipse'>
                        {
                          Array.from({ length: 3 }, (_, i) => i).map((item) => {
                            return (
                              <Skeleton key={item} width='90px' height='36px' borderRadius='20px' />
                            )
                          })
                        }
                      </div>
                  }
                </div>
              </div>
              :
              <>
                <div ref={_avatarPlaceHtml} className='avatarObject' style={{ height: `${minHeightImg}px` }}>
                  <MobileSwipeableViewsImages disableSwitch object3D={object3D} paddingImages={0} sizeButton={20} />
                </div>
                <div className='contentDetail'>
                  <div className='mainInfo'>
                    <div className='textName' title={object3D?.name}>{object3D?.name}</div>
                    {
                      object3D?.types?.length > 0 &&
                      <div className='textDescription'>{AllPlaceTypeState[object3D?.types[0]]?.name || ''}</div>
                    }
                  </div>

                  <div className='lineBorder'></div>

                  <div className={StringTool.mergeClassName('placeExtend', !isFull ? 'ellipseDistance' : '')}>
                    <div className='itemOption'>
                      <MobileDirectionButtonV2 isEllipse={!isFull} latlng={object3D?.location} />
                    </div>
                    <div className='itemOption'>
                      <MobileShareButton isEllipse={!isFull} url={location.href} />
                    </div>
                  </div>

                  <div className='lineBorder'></div>

                  <div className='detailInfo'>
                    {
                      object3D?.addressComponents?.length > 0 &&
                      <div className='listItemInfo'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.location} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          {addressFull}
                        </div>
                      </div>
                    }

                    {
                      object3D?.startDate &&
                      <div className='listItemInfo positionClock'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.clock} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          <span className='labelBold'>
                            {`${Resource.common.startDate}: `}
                          </span>
                          {moment(object3D?.startDate).format("DD/M/YYYY")}
                        </div>
                      </div>
                    }

                    {
                      object3D?.endDate &&
                      <div className='listItemInfo positionClock'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.clock} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          <span className='labelBold'>
                            {`${Resource.common.endDate}: `}
                          </span>
                          {moment(object3D?.endDate).format("DD/M/YYYY")}
                        </div>
                      </div>
                    }

                    {
                      user && object3D?.id &&
                      <div className='boxRequestUpdate'>
                        <RequestUpdateBtn id={object3D?.id} type={TypeRequestUpdateEnum.building} placeName={object3D?.name} isButton />
                      </div>
                    }
                  </div>
                  {
                    object3D?.description &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxDescription'>
                        <div className='title'>
                          {Resource.common.introduce.toUpperCase()}
                        </div>
                        <DescriptionDisplay value={object3D?.description} />
                      </div>
                    </>
                  }

                  {
                    object3D?.placeDetails.length > 0 &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxItemInfo boxDetailPlaceRelated'>
                        <PlaceRelatedV2 data={object3D?.placeDetails} object={object3D} />
                      </div>
                    </>
                  }
                </div>
              </>
          }
        </div>
        {
          placeRelatedState.show &&
          <ListPlaceRelated />
        }
      </div>
    </MobileDragBody>
  )
}

MobileObject3dDetail.propTypes = {
  //
};

export default MobileObject3dDetail;
