export class RouteInforTool {
  // static actionConvertTime = {
  //   "month": (seconds) => {
  //     const months = Math.floor(seconds / 2592000)
  //     const days = Math.round((seconds % 2592000) / 86400)
  //     return {
  //       isMonth: true,
  //       month: months,
  //       day: days
  //     }
  //   },
  //   "day": (seconds) => {
  //     const days = Math.floor(seconds / 86400)
  //     const hour = Math.round((seconds % 86400) / 3600)
  //     return {
  //       isDay: true,
  //       day: days,
  //       hour: hour
  //     }
  //   },
  //   "hour": (seconds) => {
  //     const hour = Math.floor(seconds / 3600)
  //     const minutes = Math.round((seconds % 3600) / 60)
  //     return {
  //       isHour: true,
  //       hour: hour,
  //       minute: minutes
  //     }
  //   },
  //   "minute": (seconds) => {
  //     const minutes = Math.round(seconds / 60)
  //     const s = Math.round(seconds % 60)
  //     return {
  //       isMinute: true,
  //       minutes: minutes,
  //       second: s
  //     }
  //   },
  //   "second": (seconds) => {
  //     return {
  //       isSecond: true,
  //       second: seconds
  //     }
  //   }
  // }
  // static convertDuration = (seconds) => {
  //   if (seconds > 2592000) {
  //     return RouteInforTool.actionConvertTime['month']
  //   }

  //   if (seconds >= 86400) {
  //     return RouteInforTool.actionConvertTime["day"](seconds)
  //   }
  //   else if (seconds >= 3600) {
  //     return RouteInforTool.actionConvertTime["hour"](seconds)
  //   }
  //   else if (seconds >= 60) {
  //     return RouteInforTool.actionConvertTime["minute"](seconds)
  //   }
  //   else {
  //     return RouteInforTool.actionConvertTime["second"](seconds)
  //   }
  // }
  static convertDistance = (value) => {
    let text = ''
    if (value < 1000) {
      text = Math.floor(value) + ' m'
    }
    else if (value >= 1000 && value < 10000) {
      text = Math.floor((value) / 1000) + " km " + (Math.floor((value) % 1000) !== 0 ? (Math.floor((value) % 1000) + " m") : "")
    }
    else {
      text = Math.round(value / 1000) + " km"
    }
    return text
  }
}