import { makeStyles, TextField, Box, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum, LayerTypeEnum, MapEventEnum, TypeAddressEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool } from '../../../tool';
import _ from "lodash";

const useStyles = makeStyles({
  marginBottomField: {
    marginTop: '24px'
  },
})

const createNewValue = (type, newValue, addressComponents = []) => {
  if (newValue) {
    let addressComponent = addressComponents.find(t => {
      return t?.types?.includes(type)
    })
    if (addressComponent) {
      addressComponent.name = newValue
    }
    else {
      addressComponents.push({
        types: [type],
        name: newValue
      })
    }
  }
  else {
    let index = addressComponents.findIndex(t => {
      return t?.types?.includes(type)
    })
    if (index > -1) {
      addressComponents.splice(index, 1)
    }
  }
  return [...addressComponents || []]
}

const createNewValueWithSelect = (type, newValue, addressComponents = []) => {
  if (newValue) {
    let addressComponent = addressComponents.find(t => {
      return t?.types?.includes(type)
    })
    if (addressComponent) {
      addressComponent.name = newValue?.name
      addressComponent.code = newValue?.code
    }
    else {
      addressComponents.push({
        types: [type],
        name: newValue?.name,
        code: newValue?.code
      })
    }
  }
  else {
    let index = addressComponents.findIndex(t => {
      return t?.types?.includes(type)
    })
    if (index > -1) {
      addressComponents.splice(index, 1)
    }
  }
  if (type == TypeAddressEnum.province) {
    let index = addressComponents.findIndex(t => {
      return t?.types?.includes(TypeAddressEnum.district)
    })
    if (index > -1) {
      addressComponents.splice(index, 1)
    }
    let index2 = addressComponents.findIndex(t => {
      return t?.types?.includes(TypeAddressEnum.subdistrict)
    })
    if (index2 > -1) {
      addressComponents.splice(index2, 1)
    }
  }
  if (type == TypeAddressEnum.district) {
    let index3 = addressComponents.findIndex(t => {
      return t?.types?.includes(TypeAddressEnum.subdistrict)
    })
    if (index3 > -1) {
      addressComponents.splice(index3, 1)
    }
  }
  return [...addressComponents || []]
}

function AddressInput(props) {
  const { defaultValue, value, onChange, map, position, error, helperText } = props
  const [stateValue, setStateValue] = useState(JSON.parse(JSON.stringify(defaultValue || [])));
  const classes = useStyles()
  const [provinceOptions, setProvinceOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])
  const [subdistrictOptions, setSubdistrictOptions] = useState([])
  const positionChangeRef = useRef(false)

  const [province, setProvince] = useState(null)
  const [district, setDistrict] = useState(null)
  const apiGeocodeRef = useRef()

  const geoCode = useRef(_.debounce((positionNew) => {
    let params = {
      lat: positionNew?.lat,
      lng: positionNew?.lng,
    };
    apiGeocodeRef.current?.cancel()
    apiGeocodeRef.current = ApiTool.queryGetFromJson(UrlConfig.geocode.getAddressComponents, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        let addressComponents = res?.result;
        if ("value" in props) {
          onChange && onChange(addressComponents)
        }
        else {
          setStateValue(addressComponents);
        }
      }
    });
  }), 1000).current

  const addressLabel = {
    [TypeAddressEnum.province]: Resource.updatePlace.province,
    [TypeAddressEnum.district]: Resource.updatePlace.district,
    [TypeAddressEnum.subdistrict]: Resource.updatePlace.subDistrict,
    [TypeAddressEnum.hamlet]: Resource.updatePlace.hamlet,
    [TypeAddressEnum.street]: Resource.updatePlace.street,
    [TypeAddressEnum.houseNumber]: Resource.updatePlace.houseNumber
  }

  useEffect(() => {
    const event = map?.addListener(MapEventEnum.click, (args) => {
      geoCode(args.location)
    });
    return () => {
      event?.remove()
    }
  }, [map])


  useEffect(() => {
    if (positionChangeRef.current) {
      geoCode(position)
    }
    else {
      positionChangeRef.current = true
    }
  }, [position])

  useEffect(() => {
    if (!("value" in props)) {
      onChange && onChange(stateValue)
    }
  }, [stateValue, props])

  useEffect(() => {
    var sourceProvince = ApiTool.get(UrlConfig.country.getAllProvince, (res) => {
      if (res?.code == CodeEnum.ok) {
        setProvinceOptions(res?.result)
      }
    }, true)
    return () => {
      sourceProvince?.cancel()
    }
  }, [])

  useEffect(() => {
    if ("value" in props) {
      let addressComponents = JSON.parse(JSON.stringify(value || []))
      let provinceValue = addressComponents?.find(t => {
        return t?.types?.includes(TypeAddressEnum.province)
      })
      setProvince(provinceValue ? { ...provinceValue } : null)

      let districtValue = addressComponents?.find(t => {
        return t?.types?.includes(TypeAddressEnum.district)
      })
      setDistrict(districtValue ? { ...districtValue } : null)
    }
  }, [value, props])

  useEffect(() => {
    var sourceDistrict
    if (province?.code || province?.name) {
      let body = {
        provinceCode: province?.code,
        provinceName: province?.name
      }
      sourceDistrict = ApiTool.queryGetFromJson(UrlConfig.country.getAllDistrict, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setDistrictOptions(res?.result)
        }
      }, true)
    }
    else {
      setDistrictOptions([])
    }
    return () => {
      sourceDistrict?.cancel()
    }
  }, [province?.code, province?.name])

  useEffect(() => {
    var sourceSubdistrict
    if (district?.code || district?.name) {
      let body = {
        districtCode: district?.code,
        districtName: district?.name,
        provinceName: province?.name
      }
      sourceSubdistrict = ApiTool.queryGetFromJson(UrlConfig.country.getAllSubdistrict, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setSubdistrictOptions(res?.result)
        }
      }, true)
    }
    else {
      setSubdistrictOptions([])
    }

    return () => {
      sourceSubdistrict?.cancel()
    }
  }, [district?.code, district?.name, province?.name])

  const handleChange = (type) => (e) => {
    let newValue = e.target.value
    if ("value" in props) {
      onChange && onChange(createNewValue(type, newValue, value))
    }
    else {
      setStateValue(prev => {
        return createNewValue(type, newValue, prev)
      });
    }
  }

  const handleChangeProvince = (e, newValue) => {
    if (newValue?.name != province?.name) {
      if ("value" in props) {
        onChange && onChange(createNewValueWithSelect(TypeAddressEnum.province, newValue, value))
      }
      else {
        setStateValue(prev => {
          return createNewValueWithSelect(TypeAddressEnum.province, newValue, prev)
        });
      }
      setProvince(newValue)
      setDistrict(null)
    }
  }

  const handleChangeDistrict = (e, newValue) => {
    if (newValue?.name != district?.name) {
      if ("value" in props) {
        onChange && onChange(createNewValueWithSelect(TypeAddressEnum.district, newValue, value))
      }
      else {
        setStateValue(prev => {
          return createNewValueWithSelect(TypeAddressEnum.district, newValue, prev)
        });
      }
      setDistrict(newValue)
    }
  }

  const handleChangeSubdistrict = (e, newValue) => {
    if ("value" in props) {
      onChange && onChange(createNewValueWithSelect(TypeAddressEnum.subdistrict, newValue, value))
    }
    else {
      setStateValue(prev => {
        return createNewValueWithSelect(TypeAddressEnum.subdistrict, newValue, prev)
      });
    }
  }

  let addressComponents = (("value" in props) ? value : stateValue) || []

  let subdistrictValue = addressComponents.find(t => {
    return t?.types?.includes(TypeAddressEnum.subdistrict)
  })

  return (
    <>
      <Autocomplete
        closeText={Resource.common.close}
        openText={Resource.common.open}
        clearText={Resource.common.clear}
        value={province || null}
        onChange={handleChangeProvince}
        options={provinceOptions}
        noOptionsText={Resource.common.noOption}
        getOptionLabel={(option) => {
          return option.name;
        }}
        renderInput={(params) => {
          return <TextField {...params} fullWidth variant="outlined" label={Resource.updatePlace.province} error={error} />;
        }}
      />

      {
        error &&
        <Box width="100%" >
          {
            helperText &&
            <Box color={error ? "red" : null} mt="5px" ml='15px'>
              <Typography variant='caption'> {helperText}</Typography>
            </Box>
          }
        </Box>
      }
      {
        <>
          <Autocomplete
            closeText={Resource.common.close}
            openText={Resource.common.open}
            clearText={Resource.common.clear}
            value={district || null}
            onChange={handleChangeDistrict}
            options={districtOptions}
            noOptionsText={Resource.common.noOption}
            className={classes.marginBottomField}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField {...params} fullWidth variant="outlined" label={Resource.updatePlace.district} />;
            }}
          />

          <Autocomplete
            closeText={Resource.common.close}
            openText={Resource.common.open}
            clearText={Resource.common.clear}
            value={subdistrictValue || null}
            onChange={handleChangeSubdistrict}
            options={subdistrictOptions}
            noOptionsText={Resource.common.noOption}
            className={classes.marginBottomField}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField {...params} fullWidth variant="outlined" label={Resource.updatePlace.subDistrict} />;
            }}
          />

          {
            Object.values(TypeAddressEnum)?.map((type, index) => {
              let addressComponent = addressComponents.find(t => {
                return t?.types?.includes(type)
              })
              if (type != TypeAddressEnum.province && type != TypeAddressEnum.district && type != TypeAddressEnum.subdistrict) {
                return (
                  <TextField
                    classes={{ root: index > 0 ? classes.marginBottomField : "" }}
                    id="key"
                    key={type}
                    label={addressLabel[type]}
                    variant="outlined"
                    onChange={handleChange(type)}
                    value={addressComponent?.name || ""}
                  />
                )
              }
              else {
                return null
              }
            })
          }
        </>
      }
    </>
  )
}

AddressInput.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default AddressInput;
