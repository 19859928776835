import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { SwipableContext } from '../../context/swipableContext';
import { StringTool } from '../../tool';
import styles from './swipItem.css'

export interface SwipItemProps extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  key: string
}
const SwipItem: React.FC<SwipItemProps> = ({
  children,
  ...props
}) => {
  
  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.swipItem, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.swipItem, props.className as string))
  }, [props.className])

  const value = useContext(SwipableContext)
  const indexRef = useRef(-1);
  
  if (indexRef.current === -1 && value?.getIndex) {
    indexRef.current = value?.getIndex();
  }
  useEffect(() => {
    if (indexRef.current !== -1 && value?.setChild) {
      value?.setChild(children, indexRef.current);
    }
  }, [value, props]);
  return (
  <li {...props} className={className}>
    {children}
    </li>
    );
};

export default SwipItem;
