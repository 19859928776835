import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';


const useStyles = makeStyles({
  label: {
    position: 'absolute',
    left: '10.5px',
    top: '-10px',
    backgroundColor: '#FFFFFF',
    padding: '0 5px',
    color: 'rgba(0, 0, 0, 0.54);'
  },
  boxContent: {
    position: "relative",
    borderRadius: '4px',
    border: '1px solid #b3b3b3',
    cursor: 'text',
    minHeight: "30px",
    padding: "18.5px 14px",
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
})

function FieldControl({ label, onClick, children, className }) {

  const classes = useStyles()

  return (
    <Box onClick={onClick} className={classes.boxContent + ' ' + className}>
      <Typography className={classes.label} variant='caption'>{label}</Typography>
      {
        children
      }
    </Box>
  )
}
FieldControl.propTypes = {
  label: PropTypes.string
};

export default FieldControl;
