import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useSetRecoilState } from 'recoil';
import * as yup from "yup";
import { HomeAddressState, OfficeAddressState } from '../../../appState';
import { UrlConfig } from '../../../config/urlConfig';
import { AlertTypeEnum, CodeEnum, PersonalAddressEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, AppTool } from '../../../tool';
import ActionButton from '../actionButton/actionButton';
import AutoCompleteInput from '../autoCompleteInput/autoCompleteInput';

const useStyles = makeStyles({
  dialog: {
    width: '400px',
  }
})

function PersonalAddressInput({ type, value, onChange, onClose }) {

  const schema = yup.object().shape({
    poi: yup
      .mixed()
      .nullable()
      .test("required", Resource.formatString(Resource.validate.required, {
        field: Resource.common.address
      }), function (poi) {
        if (!poi && !value) {
          return false
        }
        return true;
      }),
  })

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema)
  })

  const classes = useStyles()
  const [poi, setPoi] = useState(null)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [error, setError] = useState('')

  const setOfficeAddressState = useSetRecoilState(OfficeAddressState)
  const setHomeAddressState = useSetRecoilState(HomeAddressState)

  useEffect(() => {
    setPoi(value)
  }, [value])

  const handleOnchange = (newPoi) => {
    setValue("poi", newPoi, { shouldValidate: formState.isSubmitted })
    setPoi(newPoi);
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const personalAddress = {
    [PersonalAddressEnum.homePrivate]: Resource.common.homePrivate,
    [PersonalAddressEnum.office]: Resource.common.office
  }

  const handleSave = (data) => {
    if (poi) {
      if (poi?.location) {
        let body = {
          placeId: poi?.id ? poi?.id : '',
          address: poi?.address,
          location: poi?.location,
          updateType: type == PersonalAddressEnum.homePrivate ? PersonalAddressEnum.homePrivate : PersonalAddressEnum.office,
          name: poi?.name
        }
        ApiTool.post(UrlConfig.userLabel.update, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            let dataHome = {
              ...res?.result?.home,
              name: Resource.common.homePrivate
            }

            let dataOffice = {
              ...res?.result?.company,
              name: Resource.common.office
            }

            if (type == PersonalAddressEnum.homePrivate) {
              setHomeAddressState(dataHome)
            }
            else {
              setOfficeAddressState(dataOffice)
            }

            AppTool.alert(
              Resource.formatString(value ? Resource.alert.success.editAddress : Resource.alert.success.saveAddress, {
                object: personalAddress[type].toLowerCase() 
              }),
              AlertTypeEnum.success
            )
            onChange && onChange(res?.result)
          }
          else {
            AppTool.alert(
              Resource.formatString(value ? Resource.alert.error.editAddress : Resource.alert.error.saveAddress, {
                object: personalAddress[type].toLowerCase()
              }),
              AlertTypeEnum.error
            )
          }
          onClose && onClose()
        }, true)
      }
    }
    else {
      ApiTool.delete(UrlConfig.userLabel.delete.replace("{userLabelType}", type), (res) => {
        if (res?.code == CodeEnum.ok) {
          if (type == PersonalAddressEnum.homePrivate) {
            setHomeAddressState(null)
          }
          else {
            setOfficeAddressState(null)
          }
          AppTool.alert(
            Resource.formatString(Resource.alert.success.delete, {
              object: personalAddress[type].toLowerCase()
            }),
            AlertTypeEnum.success
          )
          onChange && onChange(res?.result)
        }
        else {
          AppTool.alert(
            Resource.formatString(Resource.alert.error.delete, {
              object: personalAddress[type].toLowerCase()
            }),
            AlertTypeEnum.error
          )
        }
        onClose && onClose()
      }, true)
    }

  }

  register({ name: "poi", type: "custom" })

  return (

    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog"
      classes={
        {
          paper: (!fullScreen) ? classes.dialog : ""
        }
      }
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle >{Resource.formatString(Resource.personalAddress.updateAddress,
          {
            object: personalAddress[type].toLowerCase()
          }
        )}
        </DialogTitle>
        <DialogContent>
          <AutoCompleteInput onChange={handleOnchange} poi={poi} />
          <Typography variant='caption' style={{ color: "red" }}>{errors?.poi?.message || null}</Typography>
        </DialogContent>
        <DialogActions>
          <Typography variant='caption'>{error}</Typography>
          <Box width='100%' textAlign='center'>
            <ActionButton name={Resource.button.cancel} onClick={handleClose} isSecondary />
            <Button type="submit" color={'primary'}>
              {Resource.button.save}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog >

  )
}

PersonalAddressInput.propTypes = {
  //
};

export default PersonalAddressInput;
