import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Resource } from '../../../resource';

function ElevationInput(props) {
  const { defaultValue, value, onChange } = props
  const [stateValue, setStateValue] = useState(defaultValue || 1);

  useEffect(() => {
    if (!("value" in props)) {
      onChange && onChange(stateValue)
    }
  }, [stateValue, props])

  const onChangeState = (e) => {
    if ("value" in props) {
      onChange && onChange(e.target?.value)
    }
    else {
      setStateValue(e.target?.value)
    }
  }
  const onBlur = (e) => {
    let value = e.target?.value
    let scale = parseFloat(value)
    if (isNaN(scale) || scale < 0) {
      if ("value" in props) {
        onChange && onChange(0)
      }
      else {
        setStateValue(0)
      }
    }
  }
  return (
    <TextField
      inputProps={{
        step: 0.5
      }}
      label={Resource.elevation.add}
      value={("value" in props) ? value : stateValue}
      onChange={onChangeState}
      onBlur={onBlur}
      placeholder={Resource.elevation.add}
      variant="outlined"
      type="number"
    />
  )
}

ElevationInput.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default ElevationInput;
