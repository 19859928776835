import { UrlConfig } from "../config/urlConfig"
import { AvoidEnum, CodeEnum, IsOptimizeEnum, VehicleEnum, WeightingEnum } from "../enum"
import { Resource } from "../resource"
import { ApiTool } from "./apiTool"
import { LocationTool } from './locationTool'

const ParamsDirectionEnum = {
    positions: "positions",
    vehicle: "vehicle",
    weighting: "weighting",
    avoidRoads: "avoidRoads",
    routeIndex: "routeIndex",
    departAt: "departAt",
    showNearByDestination: "showNearByDestination",
    showNearByDirection: "showNearByDirection",
    keyword: 'keyword',
    type: 'type',
    isOptimize: 'isOptimize'
}

const EndCodeDirectionEnum = {
    positions: "l",
    vehicle: "m",
    weighting: "w",
    avoidRoads: "r",
    routeIndex: "i",
    departAt: "t",
    showNearByDestination: "a",
    showNearByDirection: "g",
    keyword: 'k',
    type: 'c',
    isOptimize: 'z'
}

const EndCodeModeEnum = {
    [VehicleEnum.bicycle]: "b",
    [VehicleEnum.car]: "c",
    [VehicleEnum.walk]: "f",
    [VehicleEnum.motorbike]: "m"
}

const EndCodeWeightingEnum = {
    [WeightingEnum.balance]: "b",
    [WeightingEnum.fastest]: "f",
    [WeightingEnum.shortest]: "s"
}

const EndCodeAvoidRoad = {
    [AvoidEnum.highWay]: 'h',
    [AvoidEnum.ferryRoute]: 'f',
    [AvoidEnum.tunnel]: 't',
    [AvoidEnum.toll]: 'o'
}

const EndCodeIsOptimizeEnum = {
    [IsOptimizeEnum.true]: 'v',
    [IsOptimizeEnum.false]: 'x',
}

export class DirectionTool {
    static getWaypointsFromLocations = (locations, callback) => {
        let waypoints = []
        for (let i = 0; i < locations?.length; i++) {
            let loc = locations[i]
            if (loc) {
                if (typeof loc == "string") {
                    ApiTool.get(UrlConfig.poi.detail.replace("{id}", loc), (res) => {
                        if (res?.code == CodeEnum.ok) {
                            waypoints.push({
                                index: i,
                                location: res?.result?.location,
                                address: res?.result?.address,
                                name: res?.result?.name
                            })
                            if (waypoints?.length == locations?.length) {
                                let pois = waypoints.sort((a, b) => a.index - b.index)
                                let pois2 = []
                                pois.forEach((item, index) => {
                                    {
                                        if (!item?.location) {
                                            item = null
                                        }
                                        pois2.push(item)
                                    }
                                })
                                callback(pois2)
                            }
                        }
                        else {
                            waypoints.push({
                                index: i,
                                location: loc
                            })
                            if (waypoints?.length == locations?.length) {
                                let pois = waypoints.sort((a, b) => a.index - b.index)
                                let pois2 = []
                                pois.forEach((item, index) => {
                                    {
                                        if (!item?.location) {
                                            item = null
                                        }
                                        pois2.push(item)
                                    }
                                })
                                callback(pois2)
                            }
                        }
                    })
                }
                else {
                    let body = {
                        Lat: loc.lat,
                        Lng: loc.lng
                    }
                    ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
                        if (res?.code == CodeEnum.ok) {
                            waypoints.push({
                                index: i,
                                location: loc,
                                address: res?.result?.address,
                                name: res?.result?.name
                            })
                            if (waypoints?.length == locations?.length) {
                                let pois = waypoints.sort((a, b) => a.index - b.index)
                                let pois2 = []
                                pois.forEach((item, index) => {
                                    {
                                        if (!item?.location) {
                                            item = null
                                        }
                                        pois2.push(item)
                                    }
                                })
                                callback(pois2)
                            }
                        }
                        else {
                            waypoints.push({
                                index: i,
                                location: loc
                            })
                            if (waypoints?.length == locations?.length) {
                                let pois = waypoints.sort((a, b) => a.index - b.index)
                                let pois2 = []
                                pois.forEach((item, index) => {
                                    {
                                        if (!item?.location) {
                                            item = null
                                        }
                                        pois2.push(item)
                                    }
                                })
                                callback(pois2)
                            }
                        }
                    })
                }

            }
            else {
                waypoints.push({
                    index: i,
                    location: null,
                    address: null,
                    name: null
                })
                if (waypoints?.length == locations?.length) {
                    let pois = waypoints.sort((a, b) => a.index - b.index)
                    let pois2 = []
                    pois.forEach((item, index) => {
                        {
                            if (!item?.location) {
                                item = null
                            }
                            pois2.push(item)
                        }
                    })
                    callback(pois2)
                }
            }
        }
    }

    static getPointLabelName = (point) => {
        let positionText = point?.position ? `${point?.position?.lat?.toFixed(6)}, ${point?.position?.lng?.toFixed(6)}` : ''
        let text = point?.name || point?.address || positionText || Resource.common.notYetAddress
        return text
    }

    static convertDistanceLocalize = (number, language) => {
        let text = ''
        if (number || number == 0) {
            if (number < 1000) {
                let value = Math.round(number)
                text = `${value?.toLocaleString(language)}m`
            }
            else if (number < 10000) {
                let value = number / 1000
                let numRound = Math.round(value * 10) / 10
                text = `${numRound?.toLocaleString(language)}km`
            }
            else {
                let value = Math.round(number / 1000)
                text = `${value?.toLocaleString(language)}km`
            }
        }
        return text
    }

    static decode = (encoded) => {
        // array that holds the points
        var points = []
        var index = 0, len = encoded.length;
        var lat = 0, lng = 0;
        while (index < len) {
            var b, shift = 0, result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;//finds ascii                                                                                    //and substract it by 63
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            points.push({ lat: (lat / 1E5), lng: (lng / 1E5) })

        }
        return points
    }

    static isValidPois = (pois) => {
        let check = true;
        if (pois && pois.length >= 2) {
            if (JSON.stringify(pois[0]?.location) == JSON.stringify(pois[1]?.location)) {
                check = false;
            }
        }
        return check;
    };

    static isValidPoiDirection = (pois) => {
        let check = true;
        if (pois && pois.length >= 2) {
            if (JSON.stringify(pois[0]?.position) == JSON.stringify(pois[1]?.position)) {
                check = false;
            }
        }
        return check;
    };

    static encodeDirection = (data = {
        avoidRoads: undefined,
        departAt: undefined,
        keyword: undefined,
        positions: undefined,
        routeIndex: undefined,
        showNearByDestination: undefined,
        showNearByDirection: undefined,
        vehicle: undefined,
        weighting: undefined,
        type: undefined,
        isOptimize: undefined
    }) => {
        let result = ''
        let arrValue = []
        Object.keys(data)?.forEach(field => {
            let isValid = !(!data[field] || data[field]?.length == 0)
            let textFieldEncode = ''
            if (field == ParamsDirectionEnum.routeIndex && (data[field] || data[field] === 0)) {
                textFieldEncode = EndCodeDirectionEnum[field] + data[field]
            }
            else if (isValid) {
                textFieldEncode = EndCodeDirectionEnum[field]
                if (field == ParamsDirectionEnum.positions) {
                    textFieldEncode = EndCodeDirectionEnum[field]
                    let valuePosition = data[field] || []
                    let arrStringPosition = []
                    valuePosition?.forEach(item => {
                        let loc = `null`
                        if (item) {
                            if (LocationTool.isValid(item)) {
                                loc = `${item.lat?.toFixed(7)}-${item.lng?.toFixed(7)}`
                            }
                            else {
                                loc = item
                            }
                        }
                        arrStringPosition.push(loc)
                    })
                    if (arrStringPosition?.length >= 2) {
                        textFieldEncode += arrStringPosition?.join("_")
                    }
                }
                else if (field == ParamsDirectionEnum.vehicle) {
                    textFieldEncode += EndCodeModeEnum[data[field]]
                }
                else if (field == ParamsDirectionEnum.weighting) {
                    textFieldEncode += EndCodeWeightingEnum[data[field]]
                }
                else if (field == ParamsDirectionEnum.avoidRoads) {
                    let value = data[field]
                    if (value?.length > 0) {
                        value?.forEach((r) => {
                            textFieldEncode += EndCodeAvoidRoad[r]
                        })
                    }
                }
                else if (field == ParamsDirectionEnum.showNearByDestination || field == ParamsDirectionEnum.showNearByDirection) {
                    textFieldEncode += data[field] ? 1 : ''
                }
                else if (field == ParamsDirectionEnum.isOptimize) {
                    textFieldEncode += EndCodeIsOptimizeEnum[data[field]]
                }
                else if (field == ParamsDirectionEnum.departAt) {
                    textFieldEncode += data[field] instanceof Date ? data[field]?.getTime() : (data[field] || '')
                }
                else {
                    textFieldEncode += (data[field] || '')
                }
            }
            textFieldEncode && arrValue?.push(textFieldEncode)
        })
        result = arrValue?.join("!") || ''
        return result
    }

    static decodeDirection = (stringEndCode) => {
        let result = {
            avoidRoads: undefined,
            departAt: undefined,
            keyword: undefined,
            positions: undefined,
            routeIndex: undefined,
            showNearByDestination: undefined,
            showNearByDirection: undefined,
            vehicle: undefined,
            weighting: undefined,
            type: undefined,
            isOptimize: undefined
        }
        let endCodeObj = {}
        const EndCodeFieldEnum = {
            [ParamsDirectionEnum.weighting]: EndCodeWeightingEnum,
            [ParamsDirectionEnum.vehicle]: EndCodeModeEnum,
            [ParamsDirectionEnum.isOptimize]: EndCodeIsOptimizeEnum
        }
        let arrData = stringEndCode?.split("!")
        arrData?.forEach(item => {
            if (item?.length >= 2) {
                let key = item[0]
                let value = item?.substr(1, item?.length - 1)
                endCodeObj[key] = value
            }
        })

        Object?.entries(EndCodeDirectionEnum)?.map((item) => {
            let key = item[0]
            let value = endCodeObj[item[1]]
            if (value) {
                if (key == ParamsDirectionEnum.positions) {
                    let arrEndCode = value?.split("_")
                    let arrPos = []
                    if (arrEndCode?.length > 0) {
                        arrEndCode?.forEach((pos) => {
                            let item = LocationTool.checkTextIsLocation(pos?.replaceAll("-", ",")) || (pos != 'null' ? pos : null)
                            arrPos.push(item)
                        })
                        value = arrPos
                    }
                }

                else if (key == ParamsDirectionEnum.avoidRoads) {
                    let arrEndCode = value?.split('')
                    let arrRoad = []
                    if (arrEndCode?.length > 0) {
                        let decodeAvoidRoad = {}
                        Object.keys(EndCodeAvoidRoad)?.forEach(key => {
                            return decodeAvoidRoad[EndCodeAvoidRoad[key]] = key
                        })
                        arrEndCode?.forEach((r) => {
                            let item = decodeAvoidRoad[r]
                            arrRoad.push(item)
                        })
                        value = arrRoad
                    }
                }
                else if (key == ParamsDirectionEnum.vehicle || key == ParamsDirectionEnum.weighting || key == ParamsDirectionEnum.isOptimize) {
                    let endCodeEnum = EndCodeFieldEnum[key]
                    let decodeObj = {}
                    Object.keys(endCodeEnum)?.forEach(key => {
                        return decodeObj[endCodeEnum[key]] = key
                    })
                    if (key == ParamsDirectionEnum.isOptimize) {
                        value = JSON.parse(decodeObj[value])
                    }
                    else {
                        value = decodeObj[value]
                    }
                }
                else if (key == ParamsDirectionEnum.showNearByDestination || key == ParamsDirectionEnum.showNearByDirection) {
                    value = true
                }
                else if (key == ParamsDirectionEnum.departAt) {
                    value = new Date(JSON.parse(value))
                }
                else if (key == ParamsDirectionEnum.routeIndex) {
                    value = JSON.parse(value)
                }
                result[key] = value
            }
        })
        return result
    }

}