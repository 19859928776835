import { AppData } from "../data"

export class MobileDraggableBarTool {
    static setHeight = (height) => {
        AppData.mobile.draggableBar?.setHeight(height)
    }
    static setDraggble = (draggable) => {
        AppData.mobile.draggableBar?.setDraggable(draggable)
    }
    static setMinHeight = (minHeight) => {
        AppData.mobile.draggableBar?.setMinHeight(minHeight)
    }
    static setMiddleHeight = (middleHeight) => {
        AppData.mobile.draggableBar?.setMiddleHeight(middleHeight)
    }
    static setMaxHeight = (maxHeight) => {
        AppData.mobile.draggableBar?.setMaxHeight(maxHeight)
    }
}