import React from 'react';
import PropTypes from 'prop-types';
import './isLoading.scss'

const IsLoading = (props) => {
  const { size } = props
  return <div className='IsLoadingCpn'>
    <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24">
      <g transform="translate(13272 12559)">
        <circle cx="9.5" cy="9.5" r="9.5" transform="translate(-13269.5 -12556.5)" fill="none" stroke="#1c75bc" strokeLinecap="round" strokeWidth="2" strokeDasharray="20 7"/>
      </g>
    </svg>
  </div>;
};

IsLoading.propTypes = {
  //
  size: PropTypes.any,
};

export default IsLoading;
