import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DirectionDepartEnum } from '../../enum';
import ComboBox from '../comboBox/comboBox';
import { Resource } from './../../resource';
import './departSelect.scss';

const OptionMapType = [
  {
    name: Resource.common.departNow,
    value: DirectionDepartEnum.now
  },
  {
    name: Resource.common.departAt,
    value: DirectionDepartEnum.at
  },
]
function DepartSelect(props) {
  const { value, onChange } = props

  const [valueState, setValueState] = useState()

  useEffect(() => {
    setValueState(value)
  }, [value])

  const onChangeSelect = (option) => {
    onChange && onChange(option)
  }

  return (
    <div className='departSelectCpn'>
      <ComboBox
        value={valueState}
        options={OptionMapType}
        onChange={onChangeSelect}
      />
    </div>
  )
}

DepartSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default DepartSelect;
