import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, makeStyles, Typography } from '@material-ui/core';
import { CommonConfig } from './../../../config/commonConfig';
import { Resource } from './../../../resource';

const useStyle = new makeStyles({
  label: {
    marginBottom: '19px'
  }
})

function Field(props) {
  
  const classed = useStyle()
  const { icon, required = false, label, helperText, error, disableMarginTop, isNowrapText } = props

  return (
    <Box width="100%" display='flex' mt={`${disableMarginTop ? 0 : CommonConfig.field.spacingY}px`}>
      {
        icon &&
        <Box>
          {icon}
        </Box>
      }
      <Box ml={icon ? "15px" : "0px"} flexGrow={1} display="flex" flexDirection="column" overflow={isNowrapText ? 'hidden' : ''}>
        <Typography className={classed.label} variant='caption'>{label || ""}{required && <span style={{ color: 'red' }}>*</span>}</Typography>
        {props.children}
        {
          helperText &&
          <Box color={error ? "red" : null} mt="5px" ml='15px'>
            <Typography variant='caption'>{helperText}</Typography>
          </Box>
        }
      </Box>
    </Box>
  )
}

Field.propTypes = {
  disableMarginTop: PropTypes.bool,
  icon: PropTypes.node,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.node,
  error: PropTypes.any,
  isNowrapText: PropTypes.bool,
  IDmove:PropTypes.string,
  OnMove:PropTypes.func
};
export default Field;
