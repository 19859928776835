import React from 'react';
import './mobileDraggableButton.scss';

function MobileDraggableButton() {
  return (
    <div className='mobileDraggableButtonCpn'></div>
  )
}

MobileDraggableButton.propTypes = {
  //
};

export default MobileDraggableButton;
