import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../context';
import { StringTool } from '../../tool';
import "./dialogHeader.scss";
import TooltipV2 from '../tooltipV2/tooltipV2';
import { Resource } from '../../resource';

const ICON_CLOSE = <svg id="Component_842_14" data-name="Component 842 – 14" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_8100" data-name="Path 8100" d="M0,0H24V24H0Z" fill="none" />
  <path id="Path_8101" data-name="Path 8101" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" fill="#505050" />
</svg>


const ICON_BACK = <svg id="Component_763_1" data-name="Component 763 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_8132" data-name="Path 8132" d="M0,0H24V24H0Z" fill="none" />
  <path id="Path_11786" data-name="Path 11786" d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#505050" />
</svg>

const DialogHeader = ({ hideClose = false, showBack = false, subtitle, children, ...props }) => {

  const context = useContext(DialogContext)
  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName("containerDialogHeader", props.className)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName("containerDialogHeader", props.className))
  }, [props.className])

  return (
    <div {...props} className={className}>
      {showBack &&
        <TooltipV2 description={Resource.common.back}>
          <div className={StringTool.mergeClassName(
            "icon",
            "iconBack",
            subtitle && "iconSubtitle"
          )}
            onClick={context?.onBack}
          >
            {ICON_BACK}
          </div>
        </TooltipV2>
      }
      <div className={"title"}>
        {children}
        <div className={"subtitle"} title={subtitle}>
          {subtitle}
        </div>
      </div>
      {!hideClose &&
        <TooltipV2 description={Resource.common.close}>
          <div className={StringTool.mergeClassName(
            "icon",
            "iconClose",
            subtitle && "iconSubtitle"
          )}
            onClick={context?.onClose}
          >
            {ICON_CLOSE}
          </div>
        </TooltipV2>
      }
    </div>
  )
};

DialogHeader.propTypes = {
  hideClose: PropTypes.bool,
  showBack: PropTypes.bool,
  subtitle: PropTypes.string
};

export default DialogHeader;
