import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { MapTypeAppState } from '../../../appState/mapTypeAppState';
import { MapTypeEnum } from '../../../enum';
import { ImageConfig } from './../../../config/imageConfig';

const padding = 2
const gap = 3
function MapTypeSelect(props) {
  const { size, disableRadius } = props
  let theSize = size || 66
  let sizeImage = theSize - padding * 2

  const useStyles = makeStyles({
    cpn: {
      '&:hover .hoverItem': {
        left: `calc(${padding}px + ${sizeImage + gap}px * var(--i))`
      },
      '&:hover .bg': {
        width: `calc(${2 * padding}px + ${sizeImage}px * var(--i) + ${gap}px * var(--i) - ${gap}px)`
      }
    },
    bg: {
      position: "absolute",
      left: "0",
      top: "0",
      height: "100%",
      width: theSize,
      backgroundColor: "#ffffff",
      borderRadius: disableRadius ? 0 : "14px",
      transition: "width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1)"
    },
    itemTypeMap: {
      width: sizeImage,
      height: sizeImage
    },
    item: {
      '&:after': {
        zIndex: "1",
        background: 'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000B5 100%) 0% 0% no-repeat padding-box',
        content: '""',
        position: "absolute",
        left: "0",
        bottom: "0",
        width: "100%",
        height: "50%"
      },
      borderRadius: disableRadius ? 0 : "10px",
      display: 'flex',
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      width: sizeImage,
      height: sizeImage,
      position: "relative",
      overflow: "hidden",
      '&:hover:after': {
        zIndex: "1",
        background: 'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000B5 100%) 0% 0% no-repeat padding-box',
        content: '""',
        position: "absolute",
        left: "0",
        bottom: "0",
        width: "100%",
        height: "100%"
      }
    },
    item2: {
      position: "absolute",
      left: padding,
      top: padding,
      transition: "left 0.5s cubic-bezier(0.22, 0.61, 0.36, 1)"
    },
    itemSelected: {
      border: "2px solid #508FF4"
    },
    text: {
      color: "#ffffff",
      position: "absolute",
      fontSize: theSize / 7,
      lineHeight: (theSize / 5) + "px",
      bottom: "5px",
      left: "50%",
      transform: 'translateX(-50%)',
      whiteSpace: "nowrap",
      zIndex: 2
    },
    new: {
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: "red",
      color: "#ffffff",
      fontSize: theSize / 9,
      lineHeight: (theSize / 7) + "px",
      padding: "2px",
      borderRadius: disableRadius ? 0 : "3px"
    }
  });
  const { onChange } = props

  const classes = useStyles()

  const [mapTypeAppState, setMapTypeAppState] = useRecoilState(MapTypeAppState)

  const [isHover, setIsHover] = useState(false)

  const mapTypeDict = {
    roadMap: {
      name: "Roadmap",
      value: MapTypeEnum.roadmap,
      img: ImageConfig.mapTypeSelect.roadmap,
      new: true
    },
    raster: {
      name: "Raster",
      value: MapTypeEnum.raster,
      img: ImageConfig.mapTypeSelect.raster
    },
    satellite: {
      name: "Satellite",
      value: MapTypeEnum.satellite,
      img: ImageConfig.mapTypeSelect.satellite,
      new: true
    },
    map3d: {
      name: "Map3D",
      value: MapTypeEnum.map3d,
      img: ImageConfig.mapTypeSelect.map3d
    }
  }

  const onClickTypeMap = (type) => (e) => {
    setMapTypeAppState(type.value)
    onChange && onChange()
    setIsHover(false)
  }

  const onMouseOver = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const selectedType = Object.values(mapTypeDict).find(type => type.value == mapTypeAppState)
  const unselectedTypes = Object.values(mapTypeDict).filter(type => type.value != mapTypeAppState)
  return (
    <Box
      className={classes.cpn}
      // position="relative"
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseLeave}
      display="flex"
      bgcolor="#ffffff"
      p={padding + "px"}
      borderRadius={disableRadius ? 0 : "14px"}
    >
      <Box
        className={classes.item + " " + classes.itemSelected}
        zIndex={1}
      >
        <img
          // onClick={onClickTypeMap(selectedType)}
          src={selectedType.img}
          className={classes.itemTypeMap}
        />
        <Typography className={classes.text}>{selectedType.name}</Typography>
        {
          selectedType?.new &&
          <Typography className={classes.new}>NEW</Typography>
        }
      </Box>
      {
        // isHover &&
        <>
          {
            unselectedTypes.map((type, index) => {
              return (
                <Box
                  zIndex={-index}
                  style={{ "--i": index + 1 }}
                  key={type.value}
                  className={classes.item + " " + classes.item2 + " hoverItem"}
                  onClick={onClickTypeMap(type)}
                >
                  <img
                    src={type.img}
                    className={classes.itemTypeMap}
                  />
                  <Typography className={classes.text}>{type.name}</Typography>
                  {
                    type?.new &&
                    <Typography className={classes.new}>NEW</Typography>
                  }
                </Box>

              )
            })
          }
        </>
      }
      <Box style={{ "--i": unselectedTypes?.length + 1 }} zIndex={-(unselectedTypes?.length + 1)} className={classes.bg + " bg"} />

    </Box>

  )
}

MapTypeSelect.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.number,
  disableRadius: PropTypes.bool
};

export default MapTypeSelect;
