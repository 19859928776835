import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
const widthDefault = 120
const heightDefault = 48
function ActionButton(props) {
  const { onClick, isSecondary, type, name, width, height, disabled = false} = props

  const useStyles = makeStyles({
    button: {
      minWidth: `${width ? width : widthDefault}px`,
      height: `${height ? height : heightDefault}px`,
      cursor: 'pointer'
    }
  })
  const classes = useStyles()

  const onClickAction = (e) => {
    onClick && onClick()
  }

  return (
    <>
      {
        isSecondary ?
          <Button disabled={disabled} className={classes.button} onClick={onClickAction} type={type} variant='text' style={{ color: '#0000008A' }}>
            {name}
          </Button>
          :
          <Button disabled={disabled} className={classes.button} onClick={onClickAction} type={type} color='primary' variant='contained'>
            {name}
          </Button>
      }
    </>
  )
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.node.isRequired,
  isSecondary: PropTypes.bool,
  type: PropTypes.node,
  disabled:PropTypes.bool
};

export default ActionButton;
