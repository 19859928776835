import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TablePagination,
  Typography
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RatingInput } from "ui";
import { v4 as uuidv4 } from "uuid";
import { PlaceTypeState, PositionState } from "../../appState";
import { CommonConfig, ImageConfig, LinkIconConfig } from "../../config";
import { RouterConfig } from "../../config/routerConfig";
import { UrlConfig } from "../../config/urlConfig";
import { AppData } from "../../data";
import { CodeEnum } from "../../enum";
import { Resource } from "../../resource";
import { ApiTool, AppTool, PlaceTool, UrlTool } from "../../tool";
import BusinessHoursStatus from "../businessHoursStatus/businessHoursStatus";
import PlaceDetail from "../placeDetail";
import ShowPositionSelect from "../showPositionSelect/showPositionSelect";
import ShowSuggestPlace from "../showSuggestPlace/showSuggestPlace";
import { TextSearchState } from "./../../appState/searchbarState";
import { PositionTypeEnum } from "./../../enum";
import "./placeSearch.css";
import { useQuery } from "../../useHooks";

const TIME_ADS = 60;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderTop: "1px solid #e6eaee",
    bottom: 0,
    width: "100%",
    left: 0,
  },
  padding: {
    padding: "0 0.5rem",
    margin: "unset",
  },
  borderTop: {
    borderTop: "1px solid #c1c1c16e",
  },
  imgBorder: {
    borderRadius: "4px",
  },
  fontWeight: {
    fontWeight: 500,
    marginBottom: "0.5rem",
  },
  borderShadow: {
    borderRadius: theme.shape.borderRadius,
    opacity: 1,
  },
  skeleton: {
    height: 10,
    marginBottom: "10px",
  },
  skeletonPagination: {
    borderTop: "1px solid #e6eaee",
    bottom: 0,
    width: "100%",
    left: 0,
  },
  rating: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    height: "1.125rem",
    margin: "0.25rem 0",
  },
  status: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.25rem",
  },
  font: {
    fontSize: "12px",
    color: "#707070",
  },
}));
const pageSize = 10;
function PlaceSearch(props, ref) {
  const status = [
    {
      curr: "Mở cả ngày",
      des: "",
      color: "#059000",
    },
    {
      curr: "Đang mở cửa",
      des: " - Đóng cửa lúc",
      color: "#059000",
    },
    {
      curr: "Sắp mở cửa",
      des: " - Mở cửa lúc",
      color: "#FF6207",
    },
    {
      curr: "Đã đóng cửa",
      des: " - Mở cửa lúc",
      color: "#D93535",
    },
    {
      curr: "Sắp đóng cửa",
      des: " - Đóng cửa lúc",
      color: "#FF6207",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const { type, id, data } = useParams();
  const { text } = useQuery()

  const [pageNumber, setPageNumber] = useState(0);
  const [places, setPlace] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [textState, setTextState] = useState(text);
  const [typeState, setTypeState] = useState(type);
  const currentPlace = useRef(null);
  const position = useRecoilValue(PositionState);
  const placeMarker = useRef([]);
  const dataSearchRef = useRef();

  const setTextSearchState = useSetRecoilState(TextSearchState);
  const placeTypeState = useRecoilValue(PlaceTypeState);

  const fromNumber = pageNumber * pageSize + 1;
  const toNumber = (pageNumber + 1) * pageSize;

  const removePlaceMarker = () => {
    placeMarker.current.forEach((place) => {
      place?.setMap(null);
    });
  };

  const handleOnclickItem = (item) => (e) => {
    if (item?.id) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, id:  item?.id})
      });
    } else {
      let placeCustom = {
        name: item?.name,
        address: item?.address,
        location: item?.location,
      };
      let data = encodeURIComponent(JSON.stringify(placeCustom));
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, data: data })
      });
    }
    currentPlace?.current.setMap(null);
  };

  const setMarker = (place) => {
    if (place) {
      if (place?.location) {
        currentPlace.current = new map4d.POI({
          position: place?.location,
          icon: LinkIconConfig.placeDetail.marker,
          title: place?.name,
          zIndex: 99999,
        });
        currentPlace?.current.setMap(AppData.map);
      }
    } else {
      currentPlace?.current.setMap(null);
    }
  };

  useEffect(() => {
    setTextState(text);
    setTypeState(type);
  }, [text, type]);

  useEffect(() => {
    resetSearch(textState, typeState, pageNumber);
    return () => {
      dataSearchRef.current?.cancel();
    };
  }, [textState, typeState, pageNumber, position]);

  useEffect(() => {
    if (!id && !data && places?.length > 0) {
      currentPlace?.current?.setMap(null);
      AppTool.setSearchBarText(textState);
      if (places[0]?.location) {
        let camera = AppData.map.getCamera()
        camera.setZoom(13)
        camera.setTarget(places[0]?.location)
        AppData.map.moveCamera(camera, { animate: true })
      }
    }
  }, [textState, places, id, data]);

  useEffect(() => {
    return () => {
      removePlaceMarker();
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  useImperativeHandle(ref, () => ({
    search: (text, type, pageNumber, position) => {
      setTextState(text);
      setTypeState(type);
      setPageNumber(pageNumber);
      // setPosition(position);
    },
  }));

  const resetSearch = (text, type, pageNumber) => {
    setTextSearchState(text);
    let body = {
      types: [type],
      text: type ? null : text,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    if (position?.positionType == PositionTypeEnum.current) {
      let center = AppData.map?.getCamera()?.getTarget()
      body.location = `${center.lat},${center.lng}`;
    } else if (position?.positionType == PositionTypeEnum.basic) {
      body.location = `${position?.location.lat},${position?.location.lng}`
    } else if (position?.positionType == PositionTypeEnum.realtime) {
      body.location = `${position?.location.lat},${position?.location.lng}`;
    }
    setLoading(true);
    dataSearchRef.current = ApiTool.queryGetFromJson(
      UrlConfig.poi.search,
      body,
      (res) => {
        setLoading(false);
        if (res?.code == CodeEnum.ok) {
          removePlaceMarker();
          let data = res?.result?.data;
          if (data?.length > 0 && PlaceTool.isAreaOrStreet(data[0]) && pageNumber == 0) {
            history.push({
              pathname: RouterConfig.place.detail.replace(":id", data[0]?.id),
            });
          }
          else {
            data?.forEach((place, index) => {
              let marker = new map4d.POI({
                type: place?.types?.length > 0 ? place?.types[0] : "nocat",
                position: place?.location,
                title: place?.name,
              });
              marker.setMap(AppData.map);
              marker.setUserData({
                id: place?.id || "",
                name: place?.name,
                address: place?.address,
                location: place?.location,
              });
              placeMarker.current.push(marker);
            });
          }
          setPlace(data || []);
          setCount(res?.result?.count);
        }
      }
    );
  };
  return id || data ? (
    <PlaceDetail />
  ) : (
    <Box
      position="absolute"
      left={0}
      top={0}
      width="100%"
      height="100%"
      bgcolor="background.paper"
      className="placeSearch"
    >
      <Box
        overflow="hidden"
        className={classes.borderShadow}
        mx={CommonConfig.padding + "px"}
        mt={`${CommonConfig.searchBar.height + 2 * CommonConfig.padding}px`}
        height={`calc(100% - ${CommonConfig.searchBar.height + 3 * CommonConfig.padding
          }px)`}
      >
        <Box height={`calc(100% - 52px)`} overflow="auto">
          <List disablePadding>
            {loading ? (
              Array.from({ length: 10 }, (_, i) => i).map((item, index) => {
                return (
                  <Box
                    key={item.id || uuidv4()}
                    className={classes.borderTop}
                    display="flex"
                    p={1}
                    width="100%"
                  >
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="20%"
                      height="70px"
                      style={{ borderRadius: "4px" }}
                    />
                    <Box padding={1} width="80%">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        className={classes.skeleton}
                        width="100%"
                      />
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        className={classes.skeleton}
                        width="100%"
                      />
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        className={classes.skeleton}
                        width="100%"
                      />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <>
                {places?.length > 0 ? (
                  places.map((item, index) => {
                    let photos = item?.photos || []
                    return (
                      <ListItem
                        id={item?.id}
                        key={item.id || uuidv4()}
                        onClick={handleOnclickItem(item)}
                        button
                        classes={{
                          root: index > 0 ? classes.borderTop : "",
                        }}
                        style={{ padding: "1.5rem", alignItems: "start" }}
                        onMouseEnter={() => {
                          setMarker(item);
                        }}
                        onMouseLeave={() => {
                          setMarker(null);
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.fontWeight}
                              variant="body1"
                              style={{ lineHeight: "1" }}
                            >
                              {item.name}
                            </Typography>
                          }
                          title={item.name + "-" + item.address}
                          secondary={
                            <>
                              <Typography
                                variant="body2"
                                component="span"
                                style={{
                                  marginBottom: "0.5rem",
                                  lineHeight: "1",
                                }}
                              >
                                {type
                                  ? textState
                                  : item?.types?.length > 0
                                    ? placeTypeState[item?.types[0]]?.name || id
                                    : ""}
                              </Typography>
                              <br></br>
                              {item?.ratingReport && (
                                <div className={classes.rating}>
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    style={{ lineHeight: "1" }}
                                  >
                                    {item?.ratingReport.avgStars}
                                  </Typography>
                                  <RatingInput
                                    size={13}
                                    avgRating={item?.ratingReport.avgStars}
                                  />
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    style={{ lineHeight: "1" }}
                                  >
                                    {"(" +
                                      item?.ratingReport.totalComments +
                                      ")"}
                                  </Typography>
                                </div>
                              )}
                              <Typography
                                variant="body2"
                                component="span"
                                style={{
                                  marginBottom: "0.5rem",
                                  lineHeight: "1",
                                }}
                              >
                                {item.address}
                              </Typography>
                              <Typography
                                component="div"
                                style={{
                                  lineHeight: "1",
                                  marginTop: "0.25rem",
                                }}
                              >
                                <BusinessHoursStatus value={item?.businessHours} />
                              </Typography>
                            </>
                          }
                          primaryTypographyProps={{
                            noWrap: true,
                          }}
                          secondaryTypographyProps={{
                            noWrap: true,
                          }}
                          classes={{
                            root: classes.padding,
                          }}
                        />
                        <ListItemAvatar>
                          <img
                            className={classes.imgBorder}
                            src={
                              photos?.length > 0
                                ? photos[0].url
                                : ImageConfig.avaterDefaultGray
                            }
                            height={80}
                            width={80}
                            alt="logo"
                          />
                        </ListItemAvatar>
                      </ListItem>
                    );
                  })
                ) : (
                  <Box m={1} p={1} className={classes.borderShadow}>
                    <Typography variant="subtitle2">
                      {Resource.formatString(
                        Resource.message.resultsNotFoundInTheCurrentRegion,
                        {
                          text: `"${textState}"`,
                        }
                      )}
                    </Typography>
                    <Typography variant="body2">
                      {
                        Resource.message
                          .pleaseCheckYourSearchKeywordsOrExpandTheSearch
                      }
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </List>
        </Box>
        <Box
          p="1rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="placeSearch"
        >
          <Typography className={classes.font}>
            {Resource.common.showingResult + " " + fromNumber + "-" + toNumber}
          </Typography>
          <TablePagination
            component="div"
            count={count || 0}
            rowsPerPage={pageSize}
            page={pageNumber}
            labelRowsPerPage=""
            onChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <ShowPositionSelect />
      <ShowSuggestPlace />
    </Box>
  );
}

PlaceSearch = forwardRef(PlaceSearch);
export default PlaceSearch;
