import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ShowLinkState } from '../../../appState';
import { LinkIconConfig } from '../../../config';
import { Resource } from '../../../resource';
import { DataLinkState } from './../../../appState/dataLinkState';
import { LinkState } from './../../../appState/linkState';
import './linkInputV2.scss';
function LinkInputV2(props) {
  const { value, onChange, subTitle } = props

  const [valueState, setValueState] = useState([])

  const [linkState, setLinkState] = useRecoilState(LinkState)
  const setShowLinkState = useSetRecoilState(ShowLinkState)
  const setDataLinkState = useSetRecoilState(DataLinkState)

  useEffect(() => {
    return () => {
      setLinkState(null)
      setDataLinkState(null)
    }
  }, [])

  useEffect(() => {
    setValueState(value)
    setDataLinkState(value)
  }, [value])

  useEffect(() => {
    if (linkState) {
      setValueState(linkState)
      onChange && onChange(linkState)
    }
  }, [linkState])

  const onAddNewItem = () => {
    setShowLinkState({
      show: true,
      subTitle: subTitle || ''
    })
  }

  const onRemoveItem = (index) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setValueState(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)
      onChange && onChange(list)
      setDataLinkState(list)
      return list
    })
  }

  return (
    <div className='linkInputCpn'>
      <div className='boxContainer'>
        <div
          className='boxAddNew'
          onClick={onAddNewItem}
        >
          <img src={LinkIconConfig.metadata.linkAdd} width={24} height={24} />
          <div className='textCaption'>{Resource.link.add}</div>
        </div>
        {
          valueState?.length > 0 &&
          <div className='boxListItem'>
            {
              valueState?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='item'
                    title={item?.name}
                  >
                    <div className='symbolIcon'>
                      <img src={LinkIconConfig.metadata.link} alt='symbolIcon' />
                    </div>
                    <div className='content'>{item?.name}</div>
                    <div
                      onClick={onRemoveItem(index)}
                      className='closeIcon'
                      title={Resource.common.delete}
                    >
                      <img src={LinkIconConfig.metadata.remove} alt='closeIcon' />
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

    </div>
  )
}

LinkInputV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  subTitle: PropTypes.string
};

export default LinkInputV2;
