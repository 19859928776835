import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ratingInput.css'

export interface RatingProps extends React.PointerEvent<HTMLSpanElement>{
  userRating: number | undefined;
  avgRating: number
  onRatingClick: (rating: number | undefined) => void;
  size?: number
}

interface StarProps {
  selected: boolean;
  halfSelected?: boolean;
  halfValue?: number;
  sizeStar?: number 
}

const Star = ({ selected, halfSelected, halfValue, sizeStar }: StarProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={halfSelected ? "url(#grad)" : selected ? "#fbbc04" : "#E3E3E3"}
      viewBox={`0 0 ${sizeStar || 32} ${sizeStar || 32}`}
      style={{ width: sizeStar || 32, height: sizeStar || 32, transition: "all 0.2s ease" }}
    >
      {halfValue && (
        <defs>
          <linearGradient id="grad">
            <stop offset={`${halfValue * 100}%`} stopColor="#fbbc04" />
            <stop offset={`${halfValue * 100}%`} stopColor="#E3E3E3" />
          </linearGradient>
        </defs>
      )}
      <path
        style={{transform: `scale(calc(${sizeStar} / 32))`, msTransform: `scale(calc(${sizeStar} / 32))`, WebkitTransform: `scale(calc(${sizeStar} / 32))`}}
        d="M14.867,22.587,6.042,27.5l1.971-9.86L.587,10.812,10.63,9.628,14.867.5,19.1,9.628l10.043,1.184-7.426,6.825,1.971,9.86Z" transform="translate(1.133 2.001)"
      />
    </svg>
  );
};

const  RatingInput : React.FC<RatingProps> = ({
  userRating,
  avgRating,
  onRatingClick,
  size
}: RatingProps) => {
  const [hovered, setHovered] = React.useState<number | undefined>(undefined);
  const rating = hovered || userRating || avgRating;

  const handleMouseOver = (
    e: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setHovered(Number((idx + 1).toFixed(1)));
  };
  const handleMouseLeave = () => {
    setHovered(undefined);
  };

  return (
    <div className={styles.ratingInputContainer}>
      {Array.from({ length: 5 }, (_, i) => {
        return (
          <button
            key={i}
            className={styles.btn}
            onMouseMove={(e) => handleMouseOver(e, i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => onRatingClick(hovered)}
          >
            <Star
              selected={i < rating}
              halfSelected={rating - i < 1 && rating - i > 0}
              halfValue={rating % 1}
              sizeStar={size}
            />
          </button>
        );
      })}
    </div>
  );
}

export default RatingInput;
