import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './swipeable.scss'

const EPSILON = 50
const Swipeable = (props) => {
  const { length, indexActive, onChange, children } = props

  const _widthItem = useRef()
  const _isDrag = useRef()
  const _startTouch = useRef()
  const _index = useRef(0)
  const _htmlSwiperCpn = useRef()
  const _startScrollLeft = useRef()

  useEffect(() => {
    if (length > 0) {
      let scrollWidth = _htmlSwiperCpn.current.scrollWidth
      _widthItem.current = scrollWidth / length
    }
  }, [length])

  useEffect(() => {
    if (indexActive >= 0) {
      _index.current = indexActive
      _htmlSwiperCpn.current.style.scrollBehavior = "smooth"
      _htmlSwiperCpn.current.scrollLeft = (indexActive + 0.5) * _widthItem.current - _htmlSwiperCpn.current.offsetWidth / 2
    }
  }, [indexActive])

  const onTouchStart = (e) => {
    if (e.touches.length == 1) {
      let touch = e.touches[0];
      _startTouch.current = touch.clientX;
      _startScrollLeft.current = _htmlSwiperCpn.current?.scrollLeft
    }
  }
  const onTouchEnd = (e) => {
    if (e.changedTouches.length === 1) {
      _htmlSwiperCpn.current.style.scrollBehavior = "smooth"
      let touch = e.changedTouches[0]
      let currentX = touch.clientX
      let dx = currentX - _startTouch.current
      if (Math.abs(dx) >= EPSILON) {
        if (dx <= 0) {
          if (_index.current !== length - 1) {
            _index.current = _index.current + 1
          }
        }
        else {
          if (_index.current > 0) {
            _index.current = _index.current - 1
          }
        }
      }
      _htmlSwiperCpn.current.scrollLeft = (_index.current + 0.5) * _widthItem.current - _htmlSwiperCpn.current.offsetWidth / 2
      onChange?.(_index.current)
    }
  }

  const handleMove = (e) => {
    if (e.touches.length === 1) {
      let touch = e.touches[0];
      let currentX = touch.clientX;
      let dx = currentX - _startTouch.current
      _htmlSwiperCpn.current.style.scrollBehavior = "unset"
      _htmlSwiperCpn.current.scrollLeft = _startScrollLeft.current - dx
    }
  }

  const onStartDrag = (e) => {
      _isDrag.current = true
      _startTouch.current = e.clientX;
      _startScrollLeft.current = _htmlSwiperCpn.current?.scrollLeft
  }
  const onMouseMove = (e) => {
    if (_isDrag.current) {
      let currentX = e.clientX;
      let dx = currentX - _startTouch.current
      _htmlSwiperCpn.current.style.scrollBehavior = "unset"
      _htmlSwiperCpn.current.scrollLeft = _startScrollLeft.current - dx
    }
  }
  const onEndDrag = (e) => {
    if (_isDrag.current) {
      _htmlSwiperCpn.current.style.scrollBehavior = "smooth"
      let currentX = e.clientX
      let dx = currentX - _startTouch.current
      if (Math.abs(dx) >= EPSILON) {
        if (dx <= 0) {
          if (_index.current !== length - 1) {
            _index.current = _index.current + 1
          }
        }
        else {
          if (_index.current > 0) {
            _index.current = _index.current - 1
          }
        }
      }
      _htmlSwiperCpn.current.scrollLeft = (_index.current + 0.5) * _widthItem.current - _htmlSwiperCpn.current.offsetWidth / 2
      _isDrag.current = false
      onChange?.(_index.current)
    }
  }
  const onMouseLeave = (e) => {
    if (_isDrag.current) {
      _htmlSwiperCpn.current.scrollLeft = (_index.current + 0.5) * _widthItem.current - _htmlSwiperCpn.current.offsetWidth / 2
      onChange?.(_index.current)
      _isDrag.current = false
    }
  }
  return (
    <div
      className='swipeableCpn'
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={handleMove}
      onMouseDown={onStartDrag}
      onMouseMove={onMouseMove}
      onMouseUp={onEndDrag}
      onMouseLeave={onMouseLeave}
      ref={_htmlSwiperCpn}
    >
      {children}
    </div>
  );
};

Swipeable.propTypes = {
  length: PropTypes.number,
  indexActive: PropTypes.number,
  onChange: PropTypes.func
};

export default Swipeable;
