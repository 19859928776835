import { GeometryTypeEnum } from "../enum";

export class GeoJsonTool {
    static getPointForBound = (data, type) => {
        let points = []
        switch (type) {
            case GeometryTypeEnum.lineString:
                points = data || []
                break;
            case GeometryTypeEnum.multiLineString:
                data?.forEach(line => {
                    points = points.concat(line)
                })
                break;
            case GeometryTypeEnum.polygon:
                points = data[0] || []
                break;
            case GeometryTypeEnum.multiPolygon:
                data?.forEach(polygon => {
                    points = points.concat(polygon[0])
                })
                break
            default:
                break;
        }
        return points
    }

    static convertPolygonToPoints = (geometry) => {
        let multiPolygon = []
        const newGeometry = JSON.parse(JSON.stringify(geometry))

        if (newGeometry?.type === GeometryTypeEnum.multiPolygon) {
            multiPolygon = structuredClone(newGeometry.coordinates);
        }

        if (newGeometry?.type === GeometryTypeEnum.polygon) {
            multiPolygon = [structuredClone(newGeometry.coordinates)];
        }

        multiPolygon.map(
            polygon => polygon.map(
                rings => rings[0][0] === rings[rings.length -1][0] && rings[0][1] === rings[rings.length - 1][1] && rings.pop()
            )
        )
        
        return {
            type: GeometryTypeEnum.multiPolygon,
            coordinates: multiPolygon
        };
    }
}