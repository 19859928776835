import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { RouterConfig, SvgIconConfig, UrlConfig } from '../../config';
import { AppData } from '../../data';
import { TypeGeoJsonEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, HistoryTool, UrlTool } from '../../tool';
import { useQuery } from '../../useHooks';
import './mobilePlaceSearchBar.scss';
import { ShowSettingState } from '../../appState';
import { useSetRecoilState } from 'recoil';

const ICON_MENU =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(24306 22598)">
      <g transform="translate(-0.25 22.929)">
        <path d="M18.75,1.855H.75A.859.859,0,0,1,0,.927.859.859,0,0,1,.75,0h18a.859.859,0,0,1,.75.927A.859.859,0,0,1,18.75,1.855Z" transform="translate(-24303.75 -22615.928)" fill="#505050" />
        <path d="M18.75,1.856H.75A.86.86,0,0,1,0,.928.86.86,0,0,1,.75,0h18a.86.86,0,0,1,.75.928A.86.86,0,0,1,18.75,1.856Z" transform="translate(-24303.75 -22609.928)" fill="#505050" />
        <path d="M18.75,1.85H.75A.858.858,0,0,1,0,.925.858.858,0,0,1,.75,0h18a.858.858,0,0,1,.75.925A.858.858,0,0,1,18.75,1.85Z" transform="translate(-24303.75 -22603.926)" fill="#505050" />
      </g>
    </g>
  </svg>

const ICON_CLOSE =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" fill="#505050" />
  </svg>

const ICON_LOADING =
  <svg height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M18.364,5.636,16.95,7.05A7,7,0,1,0,19,12h2a9,9,0,1,1-2.636-6.364Z" fill="#1c75bc" />
  </svg>

const ICON_SEARCH =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <g transform="translate(2 1.809)">
      <g transform="translate(0 0.2)">
        <path d="M19.748,18.759,14.01,13.017a7.752,7.752,0,0,0,1.812-4.984A7.911,7.911,0,0,0,0,8.037a7.919,7.919,0,0,0,12.817,6.136l5.759,5.759a.829.829,0,1,0,1.172-1.172ZM1.681,8.037a6.226,6.226,0,0,1,12.452,0,6.226,6.226,0,0,1-12.452,0Z" transform="translate(0 -0.2)" fill="#505050" />
      </g>
    </g>
  </svg>

const ICON_BACK =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#505050" />
  </svg>

const TypeItem = {
  history: "history",
  place: "place",
  addPlace: "addPlace"
}

const IconPlaceEnum = {
  place: "place",
  home: "home",
  line: "line",
  area: "area",
  history: "history",
  building: "building"
}

const removeUniKey = (str) => {
  if (str) {
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/\W+/g, " ")
  }
  return str
}

const RenderTextForOption = ({ textOption, textInput }) => {
  let position = removeUniKey(textOption)?.indexOf(removeUniKey(textInput))
  return (
    <div className="textOptionDefault">
      {
        position === -1 || textInput === "" ?
          <div className="normal">
            {textOption}
          </div>
          :
          <>
            <span className="normal">
              {textOption?.substring(0, position)}
            </span>
            <span className="highLight">
              {textOption?.substring(position, position + textInput?.length)}
            </span>
            <span className="normal">
              {textOption?.substring(position + textInput?.length)}
            </span>
          </>
      }
    </div>
  )
}

function MobilePlaceSearchBar(props) {
  const { value, onChange, placeholder, onSelectPoi, onClose } = props

  const { text, type, id, data } = useQuery()

  const refInputBase = useRef(null)

  const history = useHistory()

  const [valueState, setValueState] = useState('')
  const [showSearchSuggest, setShowSearchSuggest] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [places, setPlaces] = useState([])
  const setShowSettingState= useSetRecoilState(ShowSettingState)

  useEffect(() => {
    setValueState(value)
  }, [value])

  useEffect(() => {
    var sourceAutocomplete
    var timer
    let dataHistory = HistoryTool.searchHistory(valueState).reverse()
    let histories = dataHistory?.map(history => {
      return {
        ...history,
        name: history?.name || history?.text,
        typeItem: TypeItem.history,
        typeIcon: IconPlaceEnum.history
      }
    })
    if (valueState) {
      setIsLoading(true)
      timer = setTimeout(() => {
        let center = AppData.map.getBounds().getCenter();
        let location = `${center.lat},${center.lng}`
        if (AppData.map.getCamera().getZoom() < 11) {
          location = null
        }
        let body = {
          datetime: new Date().getTime(),
          text: valueState,
          location: location
        }
        sourceAutocomplete = ApiTool.queryGetFromJson(UrlConfig.poi.autosuggest, body, (res) => {
          setIsLoading(false)
          let list = []
          if (res?.result?.length > 0) {
            const places = (res?.result || []).slice(0, 5)
            for (let i = 0; i < places.length; i++) {
              let iconClass = IconPlaceEnum.place
              if (places[i].objectId) {
                iconClass = IconPlaceEnum.building
              }
              else {
                if (places[i].geometry && places[i].geometry.type) {
                  switch (places[i].geometry.type) {
                    case TypeGeoJsonEnum.lineString:
                    case TypeGeoJsonEnum.multiLineString:
                      iconClass = IconPlaceEnum.line
                      break;
                    case TypeGeoJsonEnum.polygon:
                    case TypeGeoJsonEnum.multiPolygon:
                      iconClass = IconPlaceEnum.area
                      break
                    default:
                      break;
                  }
                }
              }
              let a = {
                ...places[i],
                typeIcon: iconClass,
                isPlace: true,//để phân biệt với item đề xuất địa điểm khi mãng rống
                typeItem: TypeItem.place,
              }
              list.push(a);
            }
          }
          list = histories.concat(list)
          if (list.length == 0) {
            list.push({
              name: Resource.common.noPlaceFound,
              address: Resource.common.addMissingPlace,
              typeIcon: IconPlaceEnum.place,
              isPlace: false,
              typeItem: TypeItem.addPlace
            })
          }
          setPlaces(list)
        })
      }, 300)

    }
    else {
      setPlaces(histories)
      setIsLoading(false)
    }
    return () => {
      clearTimeout(timer)
      sourceAutocomplete?.cancel()
    }
  }, [valueState])

  const onFocusSearchBar = () => {
    setShowSearchSuggest(true)
  }

  const handleShowSetting = () => {
    setShowSettingState(true)
  }

  const handleClickClose = (e) => {
    onClose?.()
  }

  const handleComeBack = () => {
    setShowSearchSuggest(false)
  }

  const onChangeSearchBar = (e) => {
    let value = e?.target?.value
    setValueState(value)
  }

  const handleKeyPress = (e) => {
    let value = e?.target?.value
    if (e?.key?.toLowerCase() == "enter") {
      if (value) {
        onChange?.(value)
        setShowSearchSuggest(false)
      }
    }
  }

  const handleClickBtnSearch = (textSearch) => (e) => {
    onChange?.(textSearch)
    setShowSearchSuggest(false)
  }

  const handleOnClickPlaceSearch = (placeSearch) => (e) => {
    onSelectPoi?.(placeSearch)
    setShowSearchSuggest(false)
  }

  const handleComeBackFromDetail = () => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: text, type: type })
    })
  }

  return (
    <>
      {
        !showSearchSuggest ?
          <div className='mobilePlaceSearchBarCpn'>
            <div className='searchContainer'>
              {
                (id || data) ?
                  <div className='iconBack' onClick={handleComeBackFromDetail}>{ICON_BACK}</div>
                  :
                  <div className='iconMenu' onClick={handleShowSetting}>{ICON_MENU}</div>
              }
              <input
                ref={refInputBase}
                className='inputSearch'
                placeholder={placeholder || ''}
                value={valueState || ''}
                onChange={onChangeSearchBar}
                onFocus={onFocusSearchBar}
              />
              <div className='iconClose' onClick={handleClickClose}>{ICON_CLOSE}</div>
            </div>
          </div>
          :
          <div className='mobilePlaceSearchBarSuggestCpn'>
            <div className='searchAutoSuggestContainer'>
              <div className='searchHeaderSuggest'>
                <div className='iconBack' onClick={handleComeBack}>{ICON_BACK}</div>
                <input
                  ref={refInputBase}
                  className='inputSearch'
                  placeholder={placeholder}
                  value={valueState || ''}
                  onChange={onChangeSearchBar}
                  onKeyPress={handleKeyPress}
                  autoFocus
                />
                {
                  isLoading ?
                    <div className='iconLoading'>{ICON_LOADING}</div>
                    :
                    <div className='iconSearch' onClick={handleClickBtnSearch(valueState)}>{ICON_SEARCH}</div>
                }
              </div>

              <div className='listPoiSearch'>
                {
                  places?.map((place, index) => {
                    let textOption = (place?.name && place?.address) ? place?.name + " - " + place?.address : (place?.name || place?.address)

                    return (
                      <div key={v4()} className='listItemPoi' onClick={handleOnClickPlaceSearch(place)}>
                        <div className='listItemIcon'>
                          {
                            place?.typeIcon == IconPlaceEnum.line &&
                            SvgIconConfig.searchBar.history
                          }
                          {
                            place?.typeIcon == IconPlaceEnum.building &&
                            SvgIconConfig.searchBar.building
                          }
                          {
                            place?.typeIcon == IconPlaceEnum.area &&
                            SvgIconConfig.searchBar.area
                          }
                          {
                            place?.typeIcon == IconPlaceEnum.history &&
                            SvgIconConfig.searchBar.history
                          }
                          {
                            place?.typeIcon == IconPlaceEnum.place &&
                            SvgIconConfig.searchBar.place
                          }
                        </div>
                        <div className='listItemText' title={textOption}>
                          {
                            place.typeItem !== "addPlace" ?
                              <RenderTextForOption textInput={valueState} textOption={textOption} />
                              :
                              <a href="#">{textOption}</a>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
      }
    </>
  )
};
MobilePlaceSearchBar.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onSelectPoi: PropTypes.func,
  onClose: PropTypes.func
}
export default MobilePlaceSearchBar;
