import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { TabsContext } from '../../context';

export interface TabProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}
const Tab: React.FC<TabProps> = ({
  children,
  ...props
}) => {
  
  const value = useContext(TabsContext)
  const indexRef = useRef(-1);
  
  if (indexRef.current === -1 && value?.getIndex) {
    indexRef.current = value?.getIndex();
  }
  useEffect(() => {
    if (indexRef.current !== -1 && value?.setChild) {
      value?.setChild(children, indexRef.current);
    }
  }, [value, props]);

  return (<React.Fragment></React.Fragment>);
};

export default Tab;