import React, { ReactNode, useEffect, useState } from "react";
import { StringTool } from "../../tool";
import styles from "./button.css";

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean,
  variant?: 'save' | 'done' | 'cancel' | 'add' | 'close' | 'send' | 'preview' | 'closeConfirm' | 'confirm' | 'direction' | undefined,
  size?: 'small' | 'large' | undefined,
  prefixIcon: ReactNode,
  suffixIcon: ReactNode,
  disabled: boolean,
  type?: 'button' | 'submit'
};

const Button: React.FC<ButtonProps> = ({
  variant,
  loading,
  size,
  prefixIcon,
  suffixIcon,
  disabled,
  type = 'button',
  ...props
}) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.buttonCpn, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.buttonCpn, props.className as string, variant ? styles[variant] as string : '', disabled ? styles.disabled : '', size ? styles[size] : ''))
  }, [props.className, variant, disabled, size])

  return (
    <button
      {...props}
      type={type}
      className={className}
    >
      {
        prefixIcon &&
        <div className={styles.prefixIcon}>
          {prefixIcon}
        </div>
      }

      {props.children}

      {
        suffixIcon &&
        <div className={styles.suffixIcon}>
          {suffixIcon}
        </div>
      }
    </button>
  )
}

export default Button;
