import React, {
  Fragment,
  useEffect,
  useRef,
  useState
} from "react"
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from "recoil"
import { RatingInput } from "ui"
import BusinessHoursStatus from "../../components/businessHoursStatus/businessHoursStatus"
import IsLoading from "../../components/isLoading/isLoading"
import MobileDragBody from "../../components/mobileDragBody/mobileDragBody"
import Skeleton from "../../components/skeleton/skeleton"
import TextView from "../../components/textView/textView"
import { ImageConfig, RouterConfig, SvgIconConfig, UrlConfig } from "../../config"
import { AppData } from "../../data"
import { CodeEnum, MapEventEnum } from "../../enum"
import { Resource } from "../../resource"
import { ApiTool, AppTool, PlaceTool, StringTool, UrlTool } from "../../tool"
import { useQuery, useWindowSize } from "../../useHooks"
import MobilePoiDetail from "../mobilePoiDetail/mobilePoiDetail"
import { PlaceTypeState } from './../../appState/placeTypeState'
import { PositionState } from './../../appState/positionState'
import { TextSearchState } from './../../appState/searchbarState'
import MobileSearchBarV2 from './../mobileSearchBarV2/mobileSearchBarV2'
import "./mobilePlaceSearchV2.scss"
import MobilePlaceSearchBar from './../mobilePlaceSearchBar/mobilePlaceSearchBar';
import { MobileShowSearchBarState } from "../../appState"

const PAGE_SIZE = 10
const COUNT_POI_SHOW = 10
const START_HEIGHT_TO_DRAG = 48

const getListDraw = (list) => {
  let result = []
  if (list?.length > COUNT_POI_SHOW) {
    let index = list?.length - COUNT_POI_SHOW
    result = list?.slice((index), (list?.length + 1))
  }
  else {
    result = list
  }
  return result
}

const LIST_ICON =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <path id="Path_6825" data-name="Path 6825" d="M0,0H24V24H0Z" fill="none" />
    <path id="Path_11856" data-name="Path 11856" d="M8,4H21V6.015H8ZM4.5,6.518A1.509,1.509,0,1,1,6,5.009,1.5,1.5,0,0,1,4.5,6.518Zm0,7.041A1.509,1.509,0,1,1,6,12.05,1.5,1.5,0,0,1,4.5,13.559Zm0,6.941A1.509,1.509,0,1,1,6,18.991,1.5,1.5,0,0,1,4.5,20.5ZM8,11.044H21v2.012H8Zm0,7.041H21V20.1H8Z" transform="translate(0 0.5)" fill="#1c75bc" />
  </svg>

const VIEW_MAP_ICON =
  <svg width="18" height="18" viewBox="0 0 24 24">
    <g transform="translate(-221 -204)">
      <path d="M2,5,9,2l6,3,6.3-2.7a.5.5,0,0,1,.7.46V19l-7,3L9,19,2.7,21.7a.5.5,0,0,1-.7-.46ZM16,19.4l4-1.714V5.033L16,6.747Zm-2-.132V6.736l-4-2V17.264ZM8,17.253V4.6L4,6.319V18.967Z" transform="translate(221 204)" fill="#1c75bc" />
    </g>
  </svg>

const HEIGHT_SEARCH_BAR = 48
const PADDING_TOP_HEADER = 6

function MobilePlaceSearchV2() {
  const { type, text, id, lat, lng, data } = useQuery()

  const history = useHistory()
  const windowSize = useWindowSize()

  const _listMarkerRef = useRef()
  const _apiGetListSearch = useRef()
  const _topRef = useRef(null)
  const _pageNumberRef = useRef(0)
  const _isLoadingMore = useRef()

  const [count, setCount] = useState(0)
  const [listPoi, setListPoi] = useState(null)
  const [textState, setTextState] = useState(text)
  const [typeState, setTypeState] = useState(type)
  const [textNotFound, setTextNotFound] = useState('')

  const [scrollTop, setScrollTop] = useState(0)
  const [loadingData, setLoadingData] = useState(true)
  const [detailId, setDetailId] = useState(null)
  const [dataState, setDataState] = useState(null)

  const [isStart, setIsStart] = useState(true)
  const [heightView, setHeightView] = useState(START_HEIGHT_TO_DRAG)
  const [maxHeightView, setMaxHeightView] = useState(windowSize?.height)

  const setTextSearchState = useSetRecoilState(TextSearchState)
  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)

  useEffect(() => {
    setTextState(text)
    AppTool.setSearchBarText(text)
  }, [text])

  useEffect(() => {
    setDataState(data)
  }, [data])

  useEffect(() => {
    setDetailId(id)
  }, [id])

  useEffect(() => {
    setTypeState(type)
  }, [type])

  useEffect(() => {
    let maxHeight = windowSize?.height - (HEIGHT_SEARCH_BAR + (PADDING_TOP_HEADER * 2))
    setMaxHeightView(maxHeight)
  }, [windowSize?.height])

  useEffect(() => {
    _topRef.current?.scrollIntoView()
    if (textState) {
      getListPoi(textState, typeState, _pageNumberRef.current, { isReset: true })
      _pageNumberRef.current = 0
    }
    return () => {
      _apiGetListSearch.current?.cancel()
    }
  }, [textState, typeState])

  useEffect(() => {
    const clickMarker = AppData.map.addListener(MapEventEnum.click, (args) => {
      let data = args.poi?.getUserData()
      history.push({
        pathname: location?.pathname,
        search: UrlTool.createSearch({ type: type, text: text, id: data?.id })
      })
    }, { poi: true })
    return () => {
      clickMarker?.remove()
      removeMarker()
    }
  }, [])

  useEffect(() => {
    if (listPoi?.length > 0) {
      let listDraw = getListDraw(listPoi)
      drawMarker(listDraw)
      moveCamera(listDraw[0]?.location)
    }
  }, [listPoi])

  const removeMarker = () => {
    _listMarkerRef?.current?.forEach((marker) => {
      marker?.setMap(null)
    })
    _listMarkerRef.current = null
  }

  const drawMarker = (data) => {
    removeMarker()
    if (data?.length > 0) {
      let listMarker = []
      data?.forEach(poi => {
        let marker = new map4d.POI({
          type: poi?.types?.length > 0 ? poi?.types[0] : "nocat",
          position: poi?.location,
          title: poi?.name
        })
        marker.setUserData({
          id: poi?.id || "",
          name: poi?.name,
          address: poi?.address,
          location: poi?.location,
        })
        marker.setMap(AppData.map)
        listMarker.push(marker)
        _listMarkerRef.current = listMarker
      })
    }
  }

  const getListPoi = (text, type, pageNumber, option = { isReset: false }) => {
    setTextSearchState(text)
    const { isReset } = option
    let body = {
      types: [type],
      text: type ? null : text,
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE,
    }
    let location = AppData.map.getCamera().getTarget()
    if (location) {
      body.location = `${location?.lat},${location?.lng}`
    }
    if (AppData.map.getCamera().getZoom() < 11) {
      body.location = null
    }
    let prevTime = new Date().getTime()
    ApiTool.queryGetFromJson(UrlConfig.poi.search, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let newCount = res?.result?.count
        let dataPOI = res?.result?.data
        if (dataPOI?.length > 0 && PlaceTool.isAreaOrStreet(dataPOI[0]) && pageNumber == 0) {
          history.push({
            pathname: RouterConfig.place.detail.replace(":id", dataPOI[0]?.id),
          })
        }
        else {
          if (count !== newCount) {
            setCount(newCount)
          }
          if (isReset) {
            setLoadingData(false)
            setListPoi(dataPOI)
          }
          else {
            let time = new Date().getTime()
            let realDelta = time - prevTime
            let timeOut = 0
            if (realDelta < 1000) {
              timeOut = 1000 - realDelta
            }
            setTimeout(() => {
              setLoadingData(false)
              setListPoi((prev) => {
                let newValue = prev || []
                newValue = newValue?.concat(dataPOI || [])
                return newValue
              })
            }, timeOut)
          }
        }
      }
      setTimeout(() => {
        _isLoadingMore.current = false
      }, 1000)
    })
  }

  const handleScroll = (event) => {
    if (Math.round(event.currentTarget.scrollTop + event.currentTarget.offsetHeight + 1) >= Math.round(event.currentTarget.scrollHeight) && listPoi?.length !== count) {
      if (!_isLoadingMore.current) {
        // alert('more')
        _isLoadingMore.current = true
        setLoadingData(true)
        _pageNumberRef.current = _pageNumberRef.current + 1
        getListPoi(textState, typeState, _pageNumberRef.current)
      }
    }
    setScrollTop(event.currentTarget.scrollTop)
  }

  const scrollToTop = () => {
    _topRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleShowDetail = (item) => (e) => {
    if (item?.id) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, id: item?.id })
      })
    }
    else {
      let placeCustom = {
        name: item?.name,
        address: item?.address,
        location: item?.location
      }
      let data = encodeURIComponent(JSON.stringify(placeCustom))
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, data: data })
      })
    }
  }

  const moveCamera = (location) => {
    let camera = AppData.map?.getCamera()
    camera.setTarget(location)
    if (camera.getZoom() > 13) {
      camera.setZoom(13)
    }
    AppData.map?.moveCamera(camera, { animate: true })
  }

  const handleCloseDetail = () => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: textState, type: typeState })
    })
  }

  const onChangeDrag = (height) => {
    setHeightView(height)
  }

  const onDragStart = (height) => {
    if (height == windowSize?.height) {
      setIsStart(false)
    }
  }
  const onDragEnd = (height) => {
    if (height == START_HEIGHT_TO_DRAG) {
      setIsStart(true)
    }
    else {
      setIsStart(false)
    }
  }

  const handleShowList = (e) => {
    setIsStart(false)
    setHeightView(maxHeightView)
  }

  const handleShowMap = () => {
    setIsStart(true)
    setHeightView(START_HEIGHT_TO_DRAG)
  }

  const onChangePlaceSearchBar = (value) => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: value })
    })
  }

  const onSelectPoiFromSearchBar = (item) => {
    if (item?.id) {
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, id: item?.id })
      })
    }
    else {
      let placeCustom = {
        name: item?.name,
        address: item?.address,
        location: item?.location
      }
      let data = encodeURIComponent(JSON.stringify(placeCustom))
      history.push({
        pathname: RouterConfig.place.search,
        search: UrlTool.createSearch({ text: text, type: type, data: data })
      })
    }
  }

  const onCloseListSearch = () => {
    history.push({
      pathname: RouterConfig.home,
    })
  }

  let isTouchTop = heightView == maxHeightView

  return (
    <Fragment>
      <div className={StringTool.mergeClassName("boxHeaderPlaceSearchV2", isTouchTop ? 'listTouchTop' : '')}>
        <MobilePlaceSearchBar
          value={textState}
          onChange={onChangePlaceSearchBar}
          onSelectPoi={onSelectPoiFromSearchBar}
          onClose={onCloseListSearch}
        />
      </div>
      {
        (detailId || dataState) ?
          <MobilePoiDetail disableTextSearch id={detailId} onClose={handleCloseDetail} data={dataState} text={text} type={type} />
          :
          <MobileDragBody
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            minHeighView={START_HEIGHT_TO_DRAG}
            heightView={heightView}
            onChange={onChangeDrag}
            maxHeightView={maxHeightView}
          >
            <div className="mobilePlaceSearchV2Cpn">
              {
                (
                  (isStart && !(detailId || dataState)) ?
                    <div className='showListBtn' onClick={handleShowList}>
                      <div className="btnContainer">
                        <div className='icon'>
                          {LIST_ICON}
                        </div>
                        <div className='label'>
                          {Resource.common.viewList}
                        </div>
                      </div>
                    </div>
                    :
                    <>
                      <div className="listPoiSearchContainer">
                        <div className='listPoiSearch' onScroll={handleScroll}>
                          <span ref={_topRef}></span>
                          {
                            (loadingData && _pageNumberRef.current == 0) ? (
                              Array.from({ length: 10 }, (_, i) => i).map((item) => {
                                return (
                                  <div className="itemLoading" key={item}>
                                    <div className="content">
                                      <Skeleton width='40%' height='10px' />
                                      <Skeleton width='60%' height='10px' />
                                      <Skeleton width='30%' height='10px' />
                                      <Skeleton width='90%' height='10px' />
                                    </div>
                                    <div className="avatar">
                                      <Skeleton width='100%' height='100%' borderRadius='10px' />
                                    </div>
                                  </div>

                                );
                              })
                            )
                              :
                              listPoi?.length > 0 && listPoi?.map((poiItem, index) => {
                                let avatar = poiItem?.photos?.length > 0 ? poiItem?.photos[0].url : ImageConfig.avaterDefaultGray
                                let name = poiItem?.name || ''
                                let address = poiItem?.address || ''
                                let type = poiItem?.types?.length > 0 ? AllPlaceTypeState[poiItem?.types[0]]?.name : ""
                                let rateNum = poiItem?.ratingReport?.avgStars
                                let totalComment = poiItem?.ratingReport?.totalComments
                                let businessHour = poiItem?.businessHours

                                return (
                                  <div
                                    className='poiItem'
                                    key={poiItem?.id + index}
                                    id={poiItem?.id + index}
                                    title={name}
                                    onClick={handleShowDetail(poiItem)}
                                  >
                                    <div className='poiItemInfo'>
                                      <div className='name'>{name}</div>
                                      <div className='type'>{type}</div>

                                      {
                                        poiItem?.ratingReport &&
                                        <div className='rate'>
                                          <div className='rateNumber'>{rateNum}</div>
                                          <RatingInput size={13} avgRating={rateNum} />
                                          <div className='totalComment'>{`(${totalComment})`}</div>
                                        </div>
                                      }
                                      <div className='address'>{address}</div>
                                      <div className='businessHourStatus'>
                                        <BusinessHoursStatus value={businessHour} />
                                      </div>
                                    </div>
                                    <div className='poiItemAvatar'>
                                      <img src={avatar} alt='avatar' />
                                    </div>
                                  </div>
                                )
                              })
                          }
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "1rem 0 5rem 0",
                            }}
                          >
                            {
                              (loadingData && _pageNumberRef.current > 0) && <IsLoading />
                            }
                            {
                              listPoi?.length > 6 && listPoi?.length === count && <div>{Resource.common.endData}</div>
                            }
                            {
                              listPoi && listPoi?.length == 0 && <div style={{ padding: "0 1rem" }}>
                                {Resource.formatString(
                                  Resource.message.resultsNotFoundInTheCurrentRegion + " " + Resource.message.pleaseCheckYourSearchKeywords,
                                  { text: `"${textState || textNotFound}"` }
                                )}</div>
                            }
                          </div>
                          {
                            (scrollTop > 100) &&
                            <div className="moveToTopBtn" onClick={scrollToTop}>
                              {SvgIconConfig.direction.verticalArrow}
                              <TextView variant="body1" style={{ fontWeight: 600 }}>
                                {Resource.common.backToTop}
                              </TextView>
                            </div>
                          }

                        </div>
                        <div className="boxViewMap">
                          <div className='showMapBtn' onClick={handleShowMap}>
                            <div className="btnContainer">
                              <div className='icon'>
                                {VIEW_MAP_ICON}
                              </div>
                              <div className='label'>
                                {Resource.button.viewMap}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                )
              }
            </div>
          </MobileDragBody>
      }
    </Fragment>


  )
}

export default MobilePlaceSearchV2
