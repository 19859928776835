import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowSuggestPlaceState } from '../../appState';



const ShowSuggestPlace = () => {
  const setShowSuggestPlace=useSetRecoilState(ShowSuggestPlaceState)
  useEffect(()=>{
    setShowSuggestPlace(true)
    return()=>{
      setShowSuggestPlace(false)
    }
  },[])
  return null
};

ShowSuggestPlace.propTypes = {
  //
};

export default ShowSuggestPlace;
