import React, { useEffect, useState } from 'react';
import { StringTool } from '../../tool';
import styles from "./radioButton.css";


export interface RadioButtonProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
  checked?: boolean,
  label?: any,
  onChange?: (event: any) => void,
  value?: any,
  name?: string,
  disabled?: boolean
}

const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  label,
  onChange,
  value,
  name,
  disabled,
  ...props
}) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.textInputCpn, props.className as string)
  })
  const [checkedState, setCheckedState] = useState(false)


  useEffect(() => {
    setCheckedState(checked || false)
  }, [checked])

  const handleChange = (e: any) => {
    const { checked } = e.target
    setCheckedState(checked)
    onChange && onChange(e)
  }

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.textInputCpn, props.className as string))
  }, [props.className])

  return (
    <div className={StringTool.mergeClassName(className, disabled ? styles.radioButtonDisable : '')}>
      <label className={styles.radioButton}>
        <input type='radio' checked={checkedState} onChange={handleChange} value={value} name={name} disabled={disabled} />
        <span>{label}</span>
      </label>
    </div>
  )
}

export default RadioButton;
