import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SidebarShadowState } from '../../appState';

const HideSidebarShadow = () => {

  const setShow  = useSetRecoilState(SidebarShadowState)

  useEffect(() => {
    setShow(false)
    return () => {
      setShow(true)
    }
  }, [])

  return null
};

HideSidebarShadow.propTypes = {
  //
};

export default HideSidebarShadow;
