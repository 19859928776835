import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, HorizontalLine, TextInput } from "ui";
import { MainCategoryState, PlaceTypeState } from '../../appState';
import { UrlConfig } from '../../config';
import { LinkIconConfig } from '../../config/linkIconConfig';
import { CodeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, StringTool } from '../../tool';
import MultiInput from '../common/multiInput/multiInput';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import DialogHeader from '../dialogHeader/dialogHeader';
import "./mainCategory.scss";


const MainCategory = (props) => {
  const { value, onChange, error, helperText, inputRef } = props

  const allType = useRecoilValue(PlaceTypeState)

  const setMainCate = useSetRecoilState(MainCategoryState) // lưu cate chính được chọn
  const [openDialog, setOpenDialog] = useState(false)
  const [selectGroup, setSelectGroup] = useState("") // nhóm được chọn id
  const [listGroupPlaceType, setListGroupPlaceType] = useState([]) // danh sách nhóm
  const [mainCategory, setMainCategory] = useState("") // value của danh mục chính
  const [textInput, setTextInput] = useState("") // state keyword search
  const [idsOfGroup, setIdsOfGroup] = useState(null) // danh sách danh mục theo nhóm
  const [idsOfAllType, setIdsOfAllType] = useState(null) // danh sách tất cả danh mục -> có search

  const handleOpenMainCategory = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectGroup("")
  }
  const handleToBack = () => {
    setTextInput("")
    if (selectGroup) { // Khi ở danh sách danh mục sẽ quay về nhóm danh mục
      setSelectGroup("")
    }
    else { // Khi ở nhóm danh mục sẽ tắt dailog qua về màn hình trước
      setOpenDialog(false)
    }
  }

  const handleChangeTextInput = (e) => {
    let text = e?.target?.value
    setTextInput(text)
  }

  useEffect(() => {
    if (value) {
      setMainCategory(value)
    }
  }, [value])

  useEffect(() => {
    if (mainCategory) {
      setMainCate(mainCategory)
    }
  }, [mainCategory])

  useEffect(() => {
    if (selectGroup) {
      if (textInput) {// search với nhóm danh mục
        let textKey = StringTool.removeUnikey(textInput)?.toLowerCase()
        let idsOfGroupNew = Object.values(allType)?.filter(placeType => placeType?.group == selectGroup?.id)?.filter(
          (type) => {
            let nameKey = StringTool.removeUnikey(type?.name || "")?.toLowerCase()
            let idKey = StringTool.removeUnikey(type?.id || "")?.toLowerCase()
            return (nameKey?.includes(textKey) || idKey?.includes(textKey))
          }
        )?.map(p => p.id)
        setIdsOfGroup(idsOfGroupNew)
      }
      else {
        let idsOfGroup = Object.values(allType)?.filter(placeType => placeType?.group == selectGroup?.id)?.map(p => p.id)
        setIdsOfGroup(idsOfGroup)
      }
    }
    else {
      if (textInput) { //search all type
        let text = StringTool.removeUnikey(textInput)?.toUpperCase()
        let idsSearchAllNew = Object.values(allType)?.filter(type => {
          let checkName = StringTool.removeUnikey(type?.name)?.toUpperCase()
          let checkId = StringTool.removeUnikey(type?.id)?.toUpperCase()
          return (checkName?.includes(text) || checkId?.includes(text))
        })?.map(p => p.id)
        setIdsOfAllType(idsSearchAllNew)
      }
      else {
        setIdsOfAllType(null)
      }
    }
  }, [textInput, selectGroup])
  //---------------------------GroupPlaceType---------------------------------------------------------
  const handleActiveSuggestGroup = (value) => {
    setSelectGroup(value)
  }

  useEffect(() => {
    const auto = getListGroupPlaceType()
    return () => auto?.cancel()
  }, [])

  const getListGroupPlaceType = () => {
    ApiTool.get(UrlConfig.placeTypeGroup.search, (res) => {
      let placeTypeGroups = [];
      if (res?.code == CodeEnum.ok) {
        placeTypeGroups = res?.result
      }
      else {
        AppTool.alert(res?.message)
      }
      setListGroupPlaceType(placeTypeGroups)
    }, true)
  }

  //---------------------------PlaceType---------------------------------------------------------

  const handleToSelectMainCategory = (id) => {
    setOpenDialog(false)
    setSelectGroup("")
    setMainCategory(id)
    onChange && onChange(id)
  }

  return (
    <>
      <MultiInput
        value={allType[mainCategory]?.name}
        label={Resource.categoryList.main}
        required
        placeholder={Resource.categoryList.select}
        onClick={handleOpenMainCategory}
        error={error}
        helperText={helperText}
        inputRef={inputRef}
      />
      <Dialog
        open={openDialog}
        disableCloseOnClickBackdrop
        onClose={handleCloseDialog}
        onBack={handleToBack}
        className="dialogMainCategory"
      >
        <DialogHeader showBack={true} hideClose={true}>{selectGroup ? selectGroup?.name : Resource.categoryList.cate}</DialogHeader>
        <div className='boxSearhMainCategory'>
          <TextInput
            value={textInput}
            onChange={handleChangeTextInput}
            className="searchPlaceType"
            suffix={<img src={LinkIconConfig.common.searchGray} alt="icon-search" />}
            placeholder={Resource.categoryList.inputType}
            maxLength={256}
            showCount
          />
          <HorizontalLine className='horizontalLine' />
        </div>

        <DialogBody className='dialogBodyMainCategory'> {
          selectGroup ?
            <>
              {
                idsOfGroup?.length == 0 &&
                <div className="notFoundSearch">{Resource.categoryList.notFound}</div>
              }
              <div className="listPlaceType">
                {
                  idsOfGroup?.map((id, index) => {
                    return (
                      <div
                        key={id}
                        className="itemPlaceType"
                        onClick={() => {
                          handleToSelectMainCategory(id)
                        }}
                      >
                        <div className="itemName">{allType[id]?.name}</div>
                      </div>
                    )
                  })
                }
              </div>
            </>
            :
            <> {
              textInput ?
                <>
                  {
                    idsOfAllType && idsOfAllType?.length == 0 &&
                    <div className="notFoundSearch">{Resource.categoryList.notFound}</div>
                  }
                  <div className="listPlaceType">
                    {idsOfAllType?.map((id, index) => {
                      return (
                        <div
                          key={id}
                          className="itemPlaceType"
                          onClick={() => {
                            handleToSelectMainCategory(id)
                          }}
                        >
                          <div className="itemName">{allType[id]?.name}</div>
                        </div>
                      )
                    })}
                  </div>
                </>
                :
                <div className="listGroupPlaceType">
                  {listGroupPlaceType?.map((item, index) => {
                    return (
                      <div
                        key={item?.id}
                        className="itemGroupPlaceType"
                        onClick={() => {
                          handleActiveSuggestGroup(item)
                        }}
                      >
                        <img className="itemIcon " src={item?.icon} alt={item?.id} />
                        <div className="itemName">
                          <div>{item?.name}</div>
                          <img src={LinkIconConfig.common.selectMore} />
                        </div>
                      </div>
                    )
                  })}
                </div>
            }
            </>
        }
        </DialogBody>
        <DialogAction><Button variant='cancel' onClick={handleToBack}>{Resource.button.cancel}</Button></DialogAction>
      </Dialog>
    </>
  );
};

MainCategory.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
  helperText: PropTypes.string,
  inputRef: PropTypes.any
};

export default MainCategory;
