import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { ObjectMetaDataTypeEnum } from "../../../enum/objectMetaDataTypeEnum";
import { Resource } from "../../../resource";

const TreePropertyEnum = {
    foliage: ObjectMetaDataTypeEnum.acreage,
    height: ObjectMetaDataTypeEnum.height,
}

const propertyLabel = {
    foliage: Resource.common.foliage,
    height: Resource.common.height,
}
const createNewValue = (property, text, oldValue = []) => {
    if (text) {
        let item = oldValue?.find(t => {
            return t.name == property
        })
        if (item) {
            item.content = text
        }
        else {
            oldValue?.push({
                name: property,
                type: TreePropertyEnum[property],
                content: text
            })
        }
    }
    else {
        let index = oldValue?.findIndex(t => {
            return t.name == property
        })
        if (index > -1) {
            oldValue.splice(index, 1)
        }
    }
    return [...oldValue || []]
}

function Tree(props) {
    const { defaultValue, onChange, value } = props
    const [stateValue, setStateValue] = useState(defaultValue)

    useEffect(() => {
        if (!("value" in props)) {
            onChange && onChange(stateValue)
        }
    }, [stateValue])

    const onChangeInput = (key) => (e) => {
        let newValue = e?.target?.value
        if ("value" in props) {
            onChange && onChange(createNewValue(key, newValue, value))
        }
        else {
            setStateValue(prev => createNewValue(key, newValue, prev))
        }
    };

    let metadata = (("value" in props) ? value : stateValue) || []
    return (
        <>
            {
                Object?.keys(TreePropertyEnum).map((key) => {
                    let item = metadata?.find(t => t.name == key)
                    return (
                        <TextField
                            key={key}
                            margin={'normal'}
                            label={propertyLabel[key]}
                            fullWidth
                            variant="outlined"
                            value={item?.content}
                            onChange={onChangeInput(key)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )
                })
            }
        </>
    );
}

Tree.propTypes = {};

export default Tree;
