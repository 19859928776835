import React, { Component, forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

function SidebarPopup(props, ref) {
  const [data, setData]=useState({
    show: false,
    children: null
  })
  useImperativeHandle(
    ref,
    () => {
      return {
        show: (children) => {
          setData({
            show: true,
            children: children
          })
        },
        hide:()=>{
          setData({
            show: false
          })
        }
      }
    }
  )
  return (
    data.show ?
      <Box position="absolute" zIndex="3" left={0} top={0} width="100%" height="100%">
        {
          data.children
        }
      </Box>
      :
      null
  )
}
SidebarPopup = forwardRef(SidebarPopup)
SidebarPopup.propTypes = {
  //
};

export default SidebarPopup;
