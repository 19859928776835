import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Resource } from '../../resource';
import "./notFoundRoute.scss"
import { ModeEnum } from '../../enum';

const ICON_WARNING = <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_30103" data-name="Rectangle 30103" width="24" height="24" transform="translate(687 159.404)" fill="#fff" stroke="#707070" strokeWidth="1" />
    </clipPath>
  </defs>
  <g id="Mask_Group_81009" data-name="Mask Group 81009" transform="translate(-687 -159.404)" clipPath="url(#clip-path)">
    <path id="Path_15106" data-name="Path 15106" d="M12.3,2.963l9.05,15.29a.907.907,0,0,1,0,.927.955.955,0,0,1-.823.463H2.424A.955.955,0,0,1,1.6,19.18a.907.907,0,0,1,0-.927l9.05-15.29a.962.962,0,0,1,1.645,0ZM10.524,15.01v1.853h1.9V15.01Zm0-6.487v4.633h1.9V8.523Z" transform="translate(687.526 160.332)" fill="#d93535" />
  </g>
</svg>


const NotFoundRoute = (props) => {
  const { vehicle } = props
  const [vehicleState, setVehicleState] = useState(Resource.vehicle.car.toLowerCase())

  const VEHICLE_NAME = {
    [ModeEnum.car]: Resource.vehicle.car.toLowerCase(),
    [ModeEnum.bike]: Resource.vehicle.bicycle.toLowerCase(),
    [ModeEnum.foot]: Resource.vehicle.walk.toLowerCase(),
    [ModeEnum.motorcycle]: Resource.vehicle.motorbike.toLowerCase()
  }
  useEffect(() => {
    vehicle && setVehicleState(VEHICLE_NAME[vehicle])
  }, [vehicle])
  return (
    <div className='boxNotFoundRouteCpn'>
      <div className='iconWarning'>{ICON_WARNING}</div>
      <div>{Resource.notFound.route.replace("{vehicle}", vehicleState)}</div>
    </div>);
};

NotFoundRoute.propTypes = {
  vehicle: PropTypes.string
};

export default NotFoundRoute;
