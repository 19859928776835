import { Box, Button, IconButton, makeStyles, MobileStepper, useTheme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { Fragment, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { v4 } from 'uuid';
import { MediaTypeEnum, PlaceExtensionTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
const padding = 56.25
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    zIndex: 1200,
    backgroundColor: 'rgba(0,0,0,.8)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 60,
    paddingLeft: theme.spacing(4),
    backgroundColor: 'rgba(0,0,0,.9)',
    justifyContent: 'flex-end'
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',

  },
  iconClose: {
    color: 'white',
  },
  btnNextPre: {
    color: 'white',
    '&$disabled': {
      color: 'gray',
    },
  },
  itemVideo: {
    paddingTop: `${padding}%`,
  },
}));
function SlideGallery({ onClose, data, step, onChangeIndex }) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = data?.length

  useEffect(() => {
    setActiveStep(step || 0)
  }, [step])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      let newActiveStep = prevActiveStep + 1
      onChangeIndex && onChangeIndex(newActiveStep)
      return newActiveStep
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      let newActiveStep = prevActiveStep - 1
      onChangeIndex && onChangeIndex(newActiveStep)
      return newActiveStep
    });
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    onChangeIndex && onChangeIndex(step)
  };

  return (
    data?.length > 0 &&
    <>
      <div className={classes.root}>
        <Box className={classes.header}>
          <IconButton aria-label={Resource.common.close} onClick={onClose}>
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </Box>
        <SwipeableViews
          style={
            {
              flexGrow: 1,
            }
          }
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          containerStyle={
            {
              height: "100%",
            }
          }
        >
          {data?.map((step, index) => {
            if (Math.abs(activeStep - index) <= 2) {
              if (step?.typeFile == MediaTypeEnum.video) {
                return (
                  <Box width="100%" key={v4()} height="100%" display="flex" justifyContent="center" alignItems="center">
                    <video width="100%" height='100%' controls preload='metadata' src={step?.url} style={{ objectFit: 'cover' }} />
                  </Box>
                )
              }
              else {
                return (
                  <Box width="100%" key={v4()} height="100%" display="flex" justifyContent="center" alignItems="center" >
                    <img className={classes.img} src={step.url} alt={step.name} />
                  </Box>
                )
              }
            }
            else {
              return <Fragment key={v4()}></Fragment>
            }
          }
          )}
        </SwipeableViews>
        <MobileStepper
          style={{
            backgroundColor: 'rgba(0,0,0,.9)',
            color: 'white'
          }}
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button className={classes.btnNextPre} size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {Resource.common.next}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button className={classes.btnNextPre} size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              {Resource.common.previous}
            </Button>
          }
        />
      </div>
    </>
  )
}

SlideGallery.propTypes = {
  //
};

export default SlideGallery;
