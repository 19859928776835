import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { StringTool } from "../../tool"
import { Resource } from './../../resource'
import "./searchBarV3.scss"
import dialogPolyfill from 'dialog-polyfill'

const ICON_CLEAR =
  <svg width={20} height={20} viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z" fill="#869195" />
  </svg>

const anchorEnum = {
  top: "bottom",
  bottom: "top",
}

const removeUniKey = (str) => {
  if (str) {
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/\W+/g, " ")
  }
  return str
}

const RenderTextForOption = ({ textOption, textInput }) => {
  let position = removeUniKey(textOption).indexOf(removeUniKey(textInput))
  return (
    <div className="textOptionDefault">
      {
        position === -1 || textInput === "" ?
          <div className="normal">
            {textOption}
          </div>
          :
          <>
            <span className="normal">
              {textOption.substring(0, position)}
            </span>
            <span className="highLight">
              {textOption.substring(position, position + textInput.length)}
            </span>
            <span className="normal">
              {textOption.substring(position + textInput.length)}
            </span>
          </>
      }
    </div>
  )
}

const SearchBarV3 = ({
  suffix,
  prefix,
  iconInside,
  options,
  value,
  onChange,
  onInputChange,
  isServer,
  isFreeSolo,
  maxLengthOfScroll,
  placeholder,
  getOptionDisabled,
  getTextForOption,
  renderOption,
  getTextForInput,
  identifierField = "id",
  clearable,
  optionDefault,
  onActionDefault,
  onActionInside,
  onEnter
}) => {
  const ref = useRef(null)
  const inputRef = useRef(null)
  const scrollRef = useRef(null)
  const isFirstFocusRef = useRef(false)
  const dialogHtml = useRef()
  const dialogOptionDefault = useRef()

  const keyUpDownRef = useRef({ key: null })
  const [optionsState, setOptionsState] = useState()
  const [valueState, setValueState] = useState(value)

  const [index, setIndex] = useState(-1)
  const [active, setActive] = useState()
  const [isOpen, setOpen] = useState(false)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)
  const [distance, setDistance] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [optionHeight, setOptionHeight] = useState(48)
  const [anchor, setAnchor] = useState(anchorEnum.bottom)
  const [isFocus, setIsFocus] = useState(false)

  const [textInput, setTextInput] = useState("")

  useEffect(() => {
    if (!isFocus && optionDefault && !isOpen) {
      dialogOptionDefault.current?.show()
    }
    else {
      dialogOptionDefault.current?.open && dialogOptionDefault.current?.close?.()
    }
  }, [isFocus, optionDefault, isOpen])

  const onShowDialog = () => {
    setOpen(true)
    dialogHtml.current?.show()
    inputRef.current?.focus()
  }

  const onCloseDialog = () => {
    dialogHtml.current?.open && dialogHtml.current?.close?.()
    setOpen(false)
    inputRef.current?.blur()
  }

  useEffect(() => {
    if (!isOpen && !isFreeSolo && !isServer && value && (typeof value == "string")) {
      let newValue = options?.find(option => option?.[identifierField] == value)
      if (newValue) {
        setValueState(newValue)
      }
    }
    else {
      setValueState(value)
    }
  }, [value, isFreeSolo, options, isServer, identifierField, isOpen])

  useEffect(() => {
    if (isServer) {
      setOptionsState(options)
    }
    else {
      let search = options?.filter((option) => {
        let text = option?.name || ""
        if (getTextForOption) {
          text = getTextForOption(option)
        }
        return removeUniKey(text)?.includes(removeUniKey(textInput?.trim()))
      })
      setOptionsState(search)
    }
  }, [options, isServer, textInput, getTextForOption])

  useEffect(() => {
    if (!isServer) {
      let newValue = ""
      if (!value?.id) {
        newValue = optionsState?.find((option) => value == option?.id)
      }
      setValueState(newValue)
      if (newValue) {
        setValueState(newValue)
      }
    }

  }, [value, optionsState, isServer])

  useEffect(() => {
    setValueState(value)
  }, [value])

  const handleScrollAndResize = () => {
    let innerHeight = window.innerHeight
    let innerHeightBetween = innerHeight / 2
    let cpn = ref.current?.getBoundingClientRect() || {}
    let isTop = (innerHeightBetween + cpn?.top) / 2 > innerHeightBetween

    if (isTop) {
      setDistance(innerHeight - cpn?.top)
      setMaxHeight(cpn?.top)
      setAnchor(anchorEnum.top)
    }
    else {
      setMaxHeight(innerHeight - (cpn?.top + cpn?.height))
      setAnchor(anchorEnum.bottom)
      setDistance(cpn?.top + cpn?.height)
    }
    setLeft(cpn?.left)
  }

  useEffect(() => {
    isOpen && handleScrollAndResize()
  }, [isOpen])

  useEffect(() => {
    handleScrollAndResize()
    let elements = ref?.current
    let observer
    let resize

    const trackWidth = (entries) => {
      setWidth(entries[0].contentRect.width)
    }

    if (elements) {
      observer = new IntersectionObserver(onIntersection)
      function onIntersection(entries, opts) {
        if (!entries[0].isIntersecting) {
          if (!isFirstFocusRef.current) {
            onCloseDialog()
          }
          else {
            ref.current?.scrollIntoView()
            isFirstFocusRef.current = false
          }
        }
      }
      resize = new ResizeObserver(trackWidth)

      observer.observe(elements)
      resize.observe(elements)
    }

    window.addEventListener("resize", handleScrollAndResize, true)
    document.addEventListener("scroll", handleScrollAndResize, true)

    return () => {
      resize?.disconnect()
      observer?.disconnect()
      window.removeEventListener("resize", handleScrollAndResize, true)
      document.removeEventListener("scroll", handleScrollAndResize, true)
    }
  }, [])

  useEffect(() => {
    let resize
    if (inputRef.current && isOpen) {
      const getHeightOption = (entries) => {
      }
      resize = new ResizeObserver(getHeightOption)
      resize.observe(inputRef.current)
    }
    return () => {
      resize?.disconnect()
    }
  }, [isOpen])

  useEffect(() => {
    const handleClose = (event) => {
      if (isOpen) {
        var isClickInsideElement = ref.current.contains(event.target)
        var isClickInsideElementListRef = dialogHtml.current.contains(
          event.target
        )
        if (!isClickInsideElement && !isClickInsideElementListRef) {
          onCloseDialog()
          setValueState('')
        }
      }
    }
    window.addEventListener("mousedown", handleClose)
    return () => {
      window.removeEventListener("mousedown", handleClose)
    }
  }, [valueState, isFreeSolo, isOpen])

  useEffect(() => {
    let text
    if (isFreeSolo && (typeof valueState == "string")) {
      text = valueState
    }
    else {
      text = valueState?.name || ""
      if (getTextForInput) {
        text = getTextForInput(valueState)
      }
      else if (getTextForOption) {
        text = getTextForOption(valueState)
      }
    }
    setTextInput(text)

  }, [valueState, getTextForOption, getTextForInput, isFreeSolo])

  const onChangeText = (event) => {
    let valueNew = event.target.value
    setTextInput(valueNew)
    if (isFreeSolo) {
      // onChange(valueNew)
      setValueState(valueNew)
    }
    onInputChange && onInputChange(valueNew, event)
  }

  const handleSelected = (option) => (e) => {
    e.stopPropagation()
    setIndex(-1)
    setValueState(option)
    onChange && onChange(option)
    onCloseDialog()
  }

  useEffect(() => {
  }, [textInput])

  const handleRemoveAll = () => {
    setValueState('')
    setIndex(-1)
    onChange && onChange('')
    onCloseDialog()
  }

  useEffect(() => {
    if (optionsState) {
      setActive(optionsState[index])
    }
    let scrollCPn = scrollRef?.current
    let height = scrollCPn?.getBoundingClientRect()?.height
    let distance = scrollCPn?.getBoundingClientRect()?.top

    let children = scrollCPn?.children[index]?.getBoundingClientRect()
    let distanceChildren = (children?.top - distance).toFixed()
    let distanceOption = index * optionHeight

    if (keyUpDownRef.current.key === 38) {
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = distanceOption
      }
      if (distanceChildren > height) {
        scrollCPn.scrollTop = distanceChildren
      }
    }

    if (keyUpDownRef.current.key === 40) {
      if (distanceChildren > height - optionHeight) {
        scrollCPn.scrollTop = distanceOption - height + optionHeight
      }
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = 0
      }
    }
  }, [index, optionHeight])

  const onKeyDownEventFired = (event) => {
    if (isOpen) {
      switch (event.which) {
        case 38:
          setIndex((prev) => getIndex(prev, true))
          keyUpDownRef.current.key = 38
          event.preventDefault()
          break
        case 40:
          setIndex((prev) => getIndex(prev))
          keyUpDownRef.current.key = 40
          event.preventDefault()
          break
        case 13:
          event.preventDefault()
          if (active && active[identifierField]) {
            setIndex(-1)
            setValueState(active)
            onChange && onChange(active)
            onCloseDialog()
          }
          break
        default:
          return
      }
    }
  }

  const onFocusInput = (e) => {
    if (/Android/i.test(navigator.userAgent)) {
      isFirstFocusRef.current = true
    }
    setIsFocus(true)
    onShowDialog()
  }

  const onBlurInput = (e) => {
    setIsFocus(false)
  }

  const onClickActionDefault = () => {
    onActionDefault && onActionDefault()
  }

  const handleKeyDown = (e) => {
    let value = e?.target?.value
    if (e?.key === 'Enter' && isFreeSolo) {
      onEnter && onEnter(value)
      onCloseDialog()
    }
  }

  const onClickIconInside = () => {
    onActionInside && onActionInside()
    onCloseDialog()
  }

  const getIndex = (currentIndex, decrease = false) => {
    let count = optionsState?.length
    let i = 1
    let ok = false
    let result = currentIndex
    while (!(ok || i > count)) {
      let nextIndex = (currentIndex + i) % count
      if (decrease) {
        if (currentIndex > -1) {
          nextIndex = (currentIndex - i + count) % count
        }
        else {
          nextIndex = (-i + count) % count
        }
      }
      if (!getOptionDisabled || (getOptionDisabled && !getOptionDisabled(optionsState[nextIndex]))) {
        result = nextIndex
        ok = true
      }
      else {
        i = i + 1
      }
    }
    return result
  }

  let maxHeightOfList = maxHeight - 9

  if (maxLengthOfScroll) {
    let max2 = (optionHeight * maxLengthOfScroll) + 1
    if (max2 < maxHeightOfList) {
      maxHeightOfList = max2
    }
  }

  const handleRefOption = (r) => {
    dialogOptionDefault.current = r
    r && dialogPolyfill.registerDialog(r)
  }
  const handleRef = (r) => {
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    <div
      ref={ref}
      onKeyDown={onKeyDownEventFired}
      tabIndex={0}
      className={StringTool.mergeClassName('searchBarCpnV3', (isOpen || optionDefault) && (anchor == anchorEnum.bottom ? 'activeBottom' : 'activeTop'))}
    >
      <div className="searchBarContainer">
        {
          prefix &&
          <div className="prefix">{prefix}</div>
        }

        <div className="searchBarInput">
          <input
            onKeyDown={handleKeyDown}
            onBlur={onBlurInput}
            onFocus={onFocusInput}
            ref={inputRef}
            onChange={onChangeText}
            autoComplete="off"
            value={textInput || ""}
            placeholder={placeholder || Resource.common.search}
          />

          {
            clearable && textInput && isFocus &&
            <div className='iconClear' onPointerDown={handleRemoveAll} title={Resource.common.delete}>
              {ICON_CLEAR}
            </div>
          }

          {
            iconInside &&
            <div className="iconFunction" onClick={onClickIconInside}>
              {iconInside}
            </div>
          }
        </div>

        {
          suffix &&
          <>
            <div className="suffixLine"></div>
            <div className="suffix">{suffix}</div>
          </>
        }
      </div>


      <dialog
        ref={handleRefOption}
        className={StringTool.mergeClassName('dialogForOptionDefault', (anchor == anchorEnum.bottom) ? 'activeBottom' : 'activeTop')}
        style={{
          [anchor]: distance,
          width: width,
          maxHeight: maxHeightOfList,
          left: left,
          flexDirection: (anchor === "top") ? "column" : "column-reverse",
        }}
      >
        <div className="optionDefault" onClick={onClickActionDefault}>
          {optionDefault}
        </div>
      </dialog>


      <dialog
        ref={handleRef}
        className={StringTool.mergeClassName('listWrapDataSuggestSearchBar', (anchor == anchorEnum.bottom) ? 'activeBottom' : 'activeTop')}
        style={{
          [anchor]: distance,
          width: width,
          maxHeight: maxHeightOfList,
          left: left,
          flexDirection: (anchor === "top") ? "column" : "column-reverse",
        }}
      >
        {
          optionsState?.length > 0 ?
            <div className="renderListOptionSearchBar" ref={scrollRef}>
              {
                optionsState?.map((option, index) => {
                  let textOption = option?.name || ""
                  if (getTextForOption) {
                    textOption = getTextForOption(option)
                  }
                  let disableOption = false
                  if (getOptionDisabled && getOptionDisabled(option)) {
                    disableOption = true
                  }
                  let isActive = active && active[identifierField] == option?.[identifierField]

                  return (
                    <div
                      key={option?.[identifierField] + index}
                      className={StringTool.mergeClassName('option', isActive ? 'optionActive' : '')}
                      onMouseDown={handleSelected(option)}
                      title={textOption}
                    >
                      {
                        option?.icon && //Hổ trợ svg
                        <div className="optionIcon">
                          {option?.icon}
                        </div>
                      }
                      {
                        renderOption ?
                          renderOption(option)
                          :
                          <RenderTextForOption
                            textInput={textInput}
                            textOption={textOption}
                          />
                      }
                    </div>
                  )
                })}
            </div>
            :
            <div className="dataNotFound">
              {textInput === "" ? Resource.common.noData : Resource.common.noOption}
            </div>
        }
      </dialog>
    </div>
  )
}

SearchBarV3.propTypes = {
  suffix: PropTypes.any,
  prefix: PropTypes.any,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  isServer: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFreeSolo: PropTypes.bool,
  maxLengthOfScroll: PropTypes.number,
  placeholder: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  clearable: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  getTextForOption: PropTypes.func,
  renderOption: PropTypes.func,
  getTextForInput: PropTypes.func,
  identifierField: PropTypes.string,
  iconInside: PropTypes.any,
  optionDefault: PropTypes.any,
  onActionDefault: PropTypes.func,
  onEnter: PropTypes.func
}

export default SearchBarV3
