import React from 'react';
import { Resource } from './../../resource';
import { LinkIconConfig } from './../../config/linkIconConfig';
import "./nearByLocationOptions.scss"
import { useState } from 'react';
import Collapse from './../collapse/collapse';
import PropTypes from 'prop-types';
import { PlaceTypeEnum } from '../../enum';

function NearByLocationOptions(props) {

  const { pointName, onChange } = props

  const mainOptions = [
    {
      name: Resource.common.restaurant,
      color: "#50C1AB",
      icon: LinkIconConfig.nearByOption.restaurant,
      type: 'restaurant'
    },
    {
      name: Resource.common.hotel,
      color: "#4A9AE4",
      icon: LinkIconConfig.nearByOption.hotel,
      type: 'hotel'
    },
    {
      name: Resource.common.gasStation,
      color: "#E5383B",
      icon: LinkIconConfig.nearByOption.gasStation,
      type: 'gas_station'
    },
    {
      name: Resource.common.park,
      color: "#EBA121",
      icon: LinkIconConfig.nearByOption.parking,
      type: 'parking'
    },

    {
      name: Resource.common.showMore,
      showName: Resource.common.lessThan,
      color: "#869195",
      icon: LinkIconConfig.nearByOption.more,
      showIcon: LinkIconConfig.nearByOption.less,
      isShowMore: true,
    },
  ]

  const moreOptions = [
    {
      name: Resource.common.bank,
      icon: LinkIconConfig.nearByOption.bank,
      type: PlaceTypeEnum.bank
    },
    {
      name: Resource.common.bar,
      icon: LinkIconConfig.nearByOption.bar,
      type: PlaceTypeEnum.bar,
    },
    {
      name: Resource.common.coffee,
      icon: LinkIconConfig.nearByOption.cafe,
      type: PlaceTypeEnum.coffee
    },
    {
      name: Resource.common.grocery,
      icon: LinkIconConfig.nearByOption.store,
      type: PlaceTypeEnum.grocery
    },
    {
      name: Resource.common.school,
      icon: LinkIconConfig.nearByOption.school,
      type: PlaceTypeEnum.school
    },
    {
      name: Resource.common.hospital,
      icon: LinkIconConfig.nearByOption.hospital,
      type: PlaceTypeEnum.hospital
    },
  ]

  const [showMore, setShowMore] = useState(false)

  const onShowHide = () => {
    setShowMore((prev) => {
      return !prev
    })
  }

  const onClickItemOption = (option) => (e) => {
    if (option?.type) {
      onChange && onChange(option)
    }
  }

  return (
    <div className='nearByLocationOptions'>
      <div className='nearByHeader'>
        {
          pointName ?
            Resource.message.discoverPlacesNear + ` "${pointName || ''}" `
            :
            Resource.message.discoverPlacesNearYourDestination
        }
      </div>
      <div className='mainOptions'>
        {
          mainOptions?.map((option, index) => {
            let stylesOption = {
              backgroundColor: option?.color
            }
            return (
              option?.isShowMore ?
                <div className='mainOptionItem' key={index} onClick={onShowHide}>
                  <div
                    className='fabOption'
                    style={stylesOption}
                  >
                    <img src={!showMore ? option?.icon : option?.showIcon} alt='icon type' />
                  </div>
                  <div className='nameOption'>
                    {!showMore ? option?.name : option?.showName}
                  </div>
                </div>
                :
                <div className='mainOptionItem' key={index} onClick={onClickItemOption(option)}>
                  <div
                    className='fabOption'
                    style={stylesOption}
                  >
                    <img src={option?.icon} alt='icon type' />
                  </div>
                  <div className='nameOption'>
                    {option?.name}
                  </div>
                </div>
            )
          })
        }
      </div>
      <Collapse open={showMore} timeout={400}>
        <div className='lineHorizontal'></div>
        <div className='moreOptions'>
          {
            moreOptions?.map((option, index) => {
              return (
                <div className='moreOptionItem' key={index} onClick={onClickItemOption(option)}>
                  <div className='fabOption' >
                    <img src={option?.icon} alt='icon type' />
                  </div>
                  <div className='nameOption'>
                    {option?.name}
                  </div>
                </div>
              )
            })
          }
        </div>
      </Collapse>
    </div>
  )
};

NearByLocationOptions.propTypes = {
  pointName: PropTypes.string,
  onChange: PropTypes.func
}

export default NearByLocationOptions;
