export const CookieEnum = Object.freeze({
    isShowToggleSidebar: "isShowToggleSidebar",
    isFullSearch: "isFullSearch",
    token: "token",
    user: "user",
    language: "languageSelected",
    weather: 'weather',
    time: 'time',
    disableEffect: 'disableEffect',
    shadow: "shadow",
    object3D: 'object3D',
    water: 'water',
    poi: 'poi',
    timeLine: 'timeLine',
    currentRole: "current_role",
    mapType: "map_type"
})