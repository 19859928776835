import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Resource } from '../../resource';
import { AppConfig } from '../../config';
import { useWindowSize } from '../../useHooks';
import "./copyRight.scss"

function CopyRight() {
  const windowSize = useWindowSize()
  const links = [
    {
      id: 1,
      url: "http://iotlink.com.vn",
      name: AppConfig.name + " data  © IOTLink",
    },
    {
      id: 2,
      name: "contributors",
      hideInSmallScreen: true
    },
    {
      id: 3,
      name: Resource.common.policy,
      url: "https://policies.map4d.vn/",
      hideInSmallScreen: true
    },
    {
      id: 4,
      name: Resource.common.termsOfUse,
      url: "https://policies.map4d.vn/rules/introduce",
      hideInSmallScreen: true
    }
  ]
  return (
    <>
      <div className='aboutCpn'>
        {
          links?.map(l => {
            if (windowSize.width < 255 && l.hideInSmallScreen) {
              return null
            }
            else if (l.url) {
              return (
                <a key={l.id} className='item' href={l.url} target='_blank'>{l.name}</a>
              )
            }
            else {
              return (
                <div key={l.id} className='item'>
                  {l.name}
                </div>
              )
            }
          })
        }
      </div>
    </>

  )
}

CopyRight.propTypes = {
  //
};

export default CopyRight;
