import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, createMuiTheme, Divider, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import * as yup from "yup";
import { LocationCanBackState } from '../../appState';
import { CommonConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AlertTypeEnum, CodeEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool } from '../../tool';
import Back from '../common/back/back';
import Confirm from './../common/confirm/confirm';

const marginField = 24
const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#508FF4',
    justifyContent: 'space-between',
    padding: '12px'
  },
  fontWeight: {
    fontWeight: 500
  },
  removeIcon: {
    backgroundColor: '#000000BC',
    width: '24px',
    height: '24px',
    padding: 0,
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#000000a8',
    }
  }
}))

const iconTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})

function UpdateGroup() {

  const classes = useStyles()
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()

  const [group, setGroup] = useState(null)
  const [placeInfos, setPlacesInfos] = useState(null)
  const [locationInfos, setLocationInfos] = useState(null)
  const [listName, setListName] = useState(null)

  const locationCanBackState = useRecoilValue(LocationCanBackState)

  useEffect(() => {
    var sourceGroup
    sourceGroup = ApiTool.get(UrlConfig.placeGroup.groupDetail.replace("{id}", id), (res) => {
      if (res?.code == CodeEnum.ok) {
        setGroup(res?.result)
        setListName(res?.result?.name)
        setPlacesInfos(res?.result?.placeInfos)
        setLocationInfos(res?.result?.locationInfos)
      }
    }, true)
    return () => {
      sourceGroup?.cancel()
    };
  }, [id])

  const schema = yup.object().shape({
    name: yup.string().required(Resource.formatString(Resource.validate.required, {
      field: Resource.common.name
    })),
  })

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema)
  })

  const onChangeListName = (event) => {
    let value = event.target.value
    setListName(value)
  }

  const onChangePlacesInfos = (index, value) => {
    setPlacesInfos(prev => {
      let newPlace = {
        ...prev[index],
        placeNote: value
      }
      prev[index] = newPlace
      return [...prev] || []
    })
  }

  const handleRemovePlacesInfos = (index) => (e) => {
    setPlacesInfos(prev => {
      let listPlacesInfos = [...(prev || [])]
      listPlacesInfos?.splice(index, 1)
      return listPlacesInfos
    })
  }

  const handleClose = () => {
    if (locationCanBackState) {
      history.goBack()
    }
    else {
      history?.push({
        pathname: RouterConfig.home
      })
    }
  }

  const onSubmit = (data) => {
    let listPlace = []
    placeInfos?.forEach(place => {
      let placeItem = {
        placeId: place?.id,
        description: place?.placeNote
      }
      listPlace.push(placeItem)
    });
    let params = {
      ...group,
      name: data.name,
      places: listPlace,
      // locationInfos : locationInfos
    }
    ApiTool.post(UrlConfig.placeGroup.updateEntirety, params, (res) => {
      if (res?.code == CodeEnum.ok) {
        AppTool.alert(
          Resource.formatString(Resource.alert.success.update, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.success
        )
        handleClose()
      }
      else {
        AppTool.alert(
          Resource.formatString(Resource.alert.error.update, {
            object: Resource.common.list.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  return (
    <Box
      width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
      height="100%"
      bgcolor='background.paper'
      display='flex'
      flexDirection='column'
    >
      {
        group ?
          <>
            <Box className={classes.header}>
              <Back children={
                <Tooltip title={Resource.common.back} placement='bottom'>
                  <IconButton >
                    <ThemeProvider theme={iconTheme}>
                      <ArrowBack color='primary' />
                    </ThemeProvider>
                  </IconButton>
                </Tooltip>
              } />
              <Typography variant='h6' noWrap style={{ color: '#FFFFFF' }}>{Resource.placeGroup.edit}</Typography>

              <Tooltip title={Resource.common.close} placement='bottom'>
                <IconButton onClick={handleClose}>
                  <ThemeProvider theme={iconTheme}>
                    <CloseIcon color='primary' />
                  </ThemeProvider>
                </IconButton>
              </Tooltip>

            </Box>

            <Box overflow='auto' flexGrow='1'>
              <form style={{ height: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
                <Box p={`${marginField}px`} flexGrow='1' overflow='auto'>
                  <Box textAlign='right' clone>
                    <Typography variant='caption' component='p' gutterBottom>{`${listName?.length || 0}/50`}</Typography>
                  </Box>
                  <TextField
                    name='name'
                    fullWidth
                    label={Resource.placeGroup.listName}
                    variant="outlined"
                    defaultValue={group?.name}
                    onChange={onChangeListName}
                    inputProps={{ maxLength: 50 }}
                    inputRef={register}
                    error={errors.name ? true : false}
                    helperText={errors?.name?.message}
                  />
                  {
                    placeInfos?.map((item, index) => {
                      return (
                        <Box key={item.id} mt={`${marginField}px`} >
                          <Divider />
                          <Box m={`${marginField}px 0`} display='flex' alignItems='center'>
                            <Box flexGrow='1'>
                              <Typography className={classes.fontWeight} variant='body1' >{item.name}</Typography>
                              <Typography variant='caption' color='textSecondary' component='p'>{(item?.typeInfos && item?.types?.length > 0) ? item?.typeInfos[item?.types[0]] : ''}</Typography>
                              <Typography variant='caption' color='textSecondary' component='p'  >{item.address}</Typography>
                            </Box>
                            <Confirm
                              onConfirm={handleRemovePlacesInfos(index)}
                              message={Resource.formatString(Resource.confirmMessage.delete, { object: Resource.common.place.toLowerCase() })}
                            >
                              <Tooltip title={Resource.common.delete}>
                                <IconButton className={classes.removeIcon}>
                                  <ThemeProvider theme={iconTheme}>
                                    <CloseIcon color='primary' />
                                  </ThemeProvider>
                                </IconButton>
                              </Tooltip>
                            </Confirm>

                          </Box>
                          <TextField
                            name='placeNote'
                            label={Resource.common.note}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            rowsMax={10}
                            value={item?.placeNote || ''}
                            onChange={e => onChangePlacesInfos(index, e.target.value)}
                          />
                        </Box>
                      )
                    })
                  }

                </Box>
                <Box display='flex' justifyContent='space-evenly' p={3} borderTop={1} borderColor='grey.200'>
                  <Button onClick={handleClose}>{Resource.button.cancel}</Button>
                  <Button variant='contained' type='submit' color='primary'>{Resource.button.send}</Button>
                </Box>
              </form>
            </Box>
          </>
          : ''
      }
    </Box>
  )
}

UpdateGroup.propTypes = {
  //
};

export default UpdateGroup;
