import React, { useEffect, useState } from 'react'
import { StringTool } from '../../tool';
import styles from "./horizontalLine.module.css";

export interface HorizontalLineProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  // 
}
const HorizontalLine: React.FC<HorizontalLineProps> = ({...props}) => {
  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.map4dUIHorizontalLine, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.map4dUIHorizontalLine, props.className as string))
  }, [props.className])
  return <div {...props} className={className} />;
};

export default HorizontalLine;
