// import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useId, useRef, useState } from 'react';
// import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { Button, TextAreaInput, TextInput } from "ui";
import * as yup from "yup";
import { UrlConfig } from '../../config';
import { AppData } from '../../data';
import { AlertTypeEnum, CodeEnum } from '../../enum';
import { ApiTool, AppTool, UrlTool } from '../../tool';
import BelongBuilding from '../belongBuilding/belongBuilding';
import MetaDataInputV2 from '../common/metaDataInputV2/metaDataInputV2';
import ConfirmV2 from '../confirmV2/confirmV2';
import CongratsBanner from '../congratsBanner/congratsBanner';
import DialogBody from '../dialogBody/dialogBody';
import MainCategory from '../mainCategory/mainCategory';
import PhotosInputV2 from '../photosInputV2/photosInputV2';
import Subcategory from '../subcategory/subcategory';
import { ImageConfig } from './../../config/imageConfig';
import { LinkIconConfig } from './../../config/linkIconConfig';
import { Resource } from './../../resource';
import { useForm, useQuery } from './../../useHooks';
import AddressInputV2 from './../addressInputV2/addressInputV2';
import BusinessHourInputV3 from './../businessHourInputV3/businessHourInputV3';
import Collapse from './../collapse/collapse';
import LocationInputV2 from './../common/locationInputV2/locationInputV2';
import TagInputV2 from './../common/tagInputV2/tagInputV2';
import Dialog from './../dialog/dialog';
import DialogAction from './../dialogAction/dialogAction';
import DialogHeader from './../dialogHeader/dialogHeader';
import "./addPoi.scss";

const MAX_LENGTH_NAME = 256
const MAX_LENGTH_DESCRIPTION = 1000
const MAX_SIZE_FILE = 10485760 //convert 10MB to Bytes
const MAX_TOTAL_SIZE_FILES = 104857600 //convert 100MB to Bytes
const ACCEPT_FILES = '.png, .jpg, .jpeg, .webp , .svg'
const REGEX_PHONE_NUMBER = /^[+]?\d+(\s?\d+)*$/
const REGEX_WEBSITE = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
function AddPoi() {

  const { lat, lng } = useQuery()
  const history = useHistory()
  const FORM_ID = useId()

  const apiSubmitRef = useRef()
  const apiUploadRef = useRef()
  const timeOutRef = useRef()

  const [showConfirm, setShowConfirm] = useState(false)
  const [showOtherInfo, setShowOtherInfo] = useState(false)
  const [poiState, setPoiState] = useState(null)

  const [isChangeLocation, setIsChangeLocation] = useState(false)
  const [isChangeAddress, setIsChangeAddress] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const schema = yup.object().shape({
    name: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.name
      })),

    address: yup.string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.common.address
      })),

    phoneNumber: yup.string()
      .test("phoneNumberFormat", Resource.formatString(Resource.validate.format, { field: Resource.common.phoneNumber }), function (phone) {
        if (phone && (!REGEX_PHONE_NUMBER.test(phone) || phone == '0000000000')) {
          return false
        }
        return true
      }),

    website: yup
      .string()
      .test("websiteFormat", Resource.formatString(Resource.validate.format, { field: Resource.common.website }), function (website) {
        if (website && (!REGEX_WEBSITE.test(website))) {
          return false
        }
        return true
      }),

    mainType: yup
      .string()
      .required(Resource.formatString(Resource.validate.required, {
        field: Resource.categoryList.main
      })),

    mediaFiles: yup
      .mixed().nullable()
      .test("formatFile", Resource.formatString(Resource.validate.formatImage, { format: ACCEPT_FILES }),
        function (mediaFiles) {
          let check = true
          let format = ['png', 'jpg', 'jpeg', 'webp', 'svg']
          if (mediaFiles?.length > 0) {
            mediaFiles?.forEach(file => {
              let typeFile = file?.name?.split('.')?.pop()
              if (!format.includes(typeFile?.toLowerCase())) {
                check = false
              }
            })
          }
          return check
        })
      .test("sizeFile", Resource.formatString(Resource.validate.maxCapacityImage, { numberUnit: '10MB' }),
        function (mediaFiles) {
          let check = true
          if (mediaFiles?.length > 0) {
            mediaFiles?.forEach(file => {
              if (file.size > MAX_SIZE_FILE) {
                check = false
              }
            })
          }
          return check
        })
      .test("totalSizeFile", Resource.formatString(Resource.validate.maxTotalCapacityImage, { numberUnit: '100MB' }),
        function (mediaFiles) {
          let check = true
          let countSize = 0
          if (mediaFiles?.length > 0) {
            mediaFiles?.forEach(file => {
              countSize += file?.size
            })
            if (countSize > MAX_TOTAL_SIZE_FILES) {
              check = false
            }
          }
          return check
        })
  })


  const { register, setValue, errors, handleSubmit, formState, isRequiredError } = useForm({
    resolver: schema,
  })

  useEffect(() => {
    if (lat && lng) {
      if (!isChangeAddress && !isChangeLocation) {
        setPoiState((prev) => {
          return {
            ...prev,
            location: {
              newValue: { lat: lat, lng: lng }
            }
          }
        })
      }
    }
  }, [lng, lat])

  const onCloseDialog = () => {
    setShowConfirm(true)
  }

  const onConfirmClose = () => {
    history.push({
      pathname: location.pathname,
      search: UrlTool.createSearch({ cp: undefined, lat: undefined, lng: undefined }, { extendOldQuery: true })
    })
    setPoiState(null)
  }

  const onCloseConfirm = () => {
    setShowConfirm(false)
  }

  const onClickOtherInfo = () => {
    setShowOtherInfo(prev => !prev)
  }

  const onChangeName = (e) => {
    let value = e?.target.value
    setPoiState((prev) => {
      return {
        ...prev,
        name: {
          newValue: value
        }
      }
    })
    setValue("name", value, { shouldValidate: formState.isSubmitted })
  }

  const onChangeTag = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        tags: {
          add: value
        }
      }
    })
  }

  const onChangeMainCategory = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        mainType: {
          newValue: value
        }
      }
    })
    setValue("mainType", value, { shouldValidate: formState.isSubmitted })
  }

  const onChangeSubCategory = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        auxiliaryTypes: {
          add: value
        }
      }
    })
  }

  const onChangeAddress = (value) => {
    let valueAddress = value?.address || value
    let locationValue = value?.location
    if (!isChangeLocation && locationValue) {
      setPoiState((prev) => {
        return {
          ...prev,
          location: {
            newValue: locationValue
          }
        }
      })
    }
    setPoiState((prev) => {
      return {
        ...prev,
        address: {
          newValue: valueAddress
        }
      }
    })
    setIsChangeAddress(true)
    setValue("address", valueAddress, { shouldValidate: formState.isSubmitted })
  }

  const onChangeLocation = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        location: {
          newValue: value
        }
      }
    })
    setIsChangeLocation(true)
  }

  const onChangeSuggestLocation = (value) => {
    if (!isChangeAddress) {
      setPoiState((prev) => {
        return {
          ...prev,
          address: {
            newValue: value
          }
        }
      })
      setValue("address", value, { shouldValidate: formState.isSubmitted })
    }
  }

  const onChangeMediaFile = (photos, imageFiles) => {
    setPoiState((prev) => {
      return {
        ...prev,
        mediaFiles: {
          add: imageFiles
        }
      }
    })
    setValue("mediaFiles", imageFiles, { shouldValidate: formState.isSubmitted })
  }

  const onChangeBusinessHour = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        businessHours: {
          newValue: value
        }
      }
    })
  }

  const onChangeWebsite = (e) => {
    let valueWebsite = e?.target.value
    setPoiState((prev) => {
      return {
        ...prev,
        website: {
          newValue: valueWebsite
        }
      }
    })
    setValue("website", valueWebsite, { shouldValidate: formState.isSubmitted })
  }

  const onChangePhoneNumber = (e) => {
    let valuePhoneNumber = e?.target.value
    setPoiState((prev) => {
      return {
        ...prev,
        phoneNumber: {
          newValue: valuePhoneNumber
        }
      }
    })
    setValue("phoneNumber", valuePhoneNumber, { shouldValidate: formState.isSubmitted })
  }

  const onChangeDescription = (e) => {
    let valueDescription = e?.target.value
    setPoiState((prev) => {
      return {
        ...prev,
        description: {
          newValue: valueDescription
        }
      }
    })
  }

  const onChangeMetaData = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        metadata: {
          add: value
        }
      }
    })
  }

  const onChangeBuilding = (value) => {
    setPoiState((prev) => {
      return {
        ...prev,
        objectId: {
          newValue: value
        }
      }
    })
  }

  const createPoi = (data) => {
    let locNew = { newValue: data?.location?.newValue || AppData.map?.getBounds().getCenter() }
    data.location = locNew
    let body = {
      ...data,
    }
    apiSubmitRef?.current?.cancel()
    apiSubmitRef.current = ApiTool.post(UrlConfig.poiEdit.create, body, (res) => {
      setLoadingSubmit(false)
      if (res?.code == CodeEnum.ok) {
        setShowBanner(true)
      }
      else {
        AppTool.alert(
          res?.message || Resource.formatString(Resource.alert.error.add, {
            object: Resource.common.place.toLowerCase()
          }),
          AlertTypeEnum.error
        )
      }
    }, true)
  }

  const onSubmit = (data) => {
    let imageFiles = poiState?.mediaFiles?.add
    setLoadingSubmit(true)
    timeOutRef.current && clearTimeout(timeOutRef.current)
    timeOutRef.current = setTimeout(() => {
      if (imageFiles?.length > 0) {
        let body = {
          files: imageFiles
        }
        apiUploadRef?.current?.cancel()
        apiUploadRef.current = ApiTool.postFormFromJson(UrlConfig.file.listMedia, body, (res) => {
          if (res?.code == CodeEnum.ok) {
            poiState.mediaFiles.add = res?.result
            createPoi(poiState)
          }
          else {
            AppTool.alert(
              res?.message || Resource.formatString(Resource.alert.error.add, {
                object: Resource.common.images.toLowerCase()
              }),
              AlertTypeEnum.error
            )
          }
        }, true)
      }
      else {
        createPoi(poiState)
      }
    }, 300)
  }

  const onCloseBanner = () => {
    setShowBanner(false)
    onConfirmClose()
  }

  return (
    <>
      <Dialog className='addPoiCpn' onClose={onCloseDialog} open={true} disableCloseOnClickBackdrop>
        <DialogHeader>{Resource.place.addPlace}</DialogHeader>

        <DialogBody>
          <form
            className='bodyDialog test'
            id={FORM_ID}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='description'>
              <div className='title'>{Resource.place.infoAboutPlace}</div>
              <div className='caption'>{Resource.place.captionInfoPlace}</div>
            </div>

            <div className="mainInfo">
              <TextInput
                inputRef={register({ name: "name" })}
                required
                label={Resource.common.name}
                placeholder={Resource.name.add}
                maxLength={MAX_LENGTH_NAME}
                showCount
                value={poiState?.name?.newValue || ''}
                onChange={onChangeName}
                error={errors?.name ? true : false}
                helperText={errors?.name?.message}
              />

              <MainCategory
                inputRef={register({ name: "mainType" })}
                value={poiState?.mainType?.newValue || ''}
                onChange={onChangeMainCategory}
                error={errors?.mainType ? true : false}
                helperText={errors?.mainType?.message}
              />

              <Subcategory
                value={poiState?.auxiliaryTypes?.add}
                onChange={onChangeSubCategory}
              />

              <AddressInputV2
                inputRef={register({ name: "address" })}
                required
                label={Resource.common.address}
                placeholder={Resource.address.add}
                value={poiState?.address?.newValue || ''}
                onChange={onChangeAddress}
                error={errors?.address ? true : false}
                helperText={errors?.address?.message}
                position={poiState?.location?.newValue}
              />

              <LocationInputV2
                value={poiState?.location?.newValue || ''}
                onChange={onChangeLocation}
                onChangeSuggest={onChangeSuggestLocation}
              />
            </div>

            <div className='image'>
              <h4 className='title'>{Resource.place.photo}</h4>
              <div className='description'>
                {Resource.place.addPhoto}
              </div>
              <PhotosInputV2
                inputRef={register({ name: "mediaFiles" })}
                // value={poiState?.mediaFiles?.add}
                onChange={onChangeMediaFile}
                error={errors?.mediaFiles ? true : false}
                helperText={errors?.mediaFiles?.message}
                accept={ACCEPT_FILES}
              />
            </div>

            <div className='otherInfo' onClick={onClickOtherInfo}>
              <div className='head'>
                <div className='title'>{Resource.place.addOtherInfo}</div>
                <div className='toggle'>
                  {
                    showOtherInfo ?
                      <img src={LinkIconConfig.common.arrTop} alt='arrTop' />
                      :
                      <img src={LinkIconConfig.common.arrBottom} alt='arrBottom' />
                  }
                </div>
              </div>
              <div className='description'>
                {Resource.place.addOtherInfoCaption}
              </div>
            </div>

            <Collapse open={showOtherInfo}>
              <div className='otherInfoContent'>
                <BusinessHourInputV3
                  value={poiState?.businessHours?.newValue || null}
                  onChange={onChangeBusinessHour}
                  placeholder={Resource.businessHours.addOpenTime}
                />

                <TextInput
                  inputRef={register({ name: "phoneNumber" })}
                  label={Resource.common.phoneNumber}
                  placeholder={Resource.phoneNumber.add}
                  value={poiState?.phoneNumber?.newValue || ''}
                  onChange={onChangePhoneNumber}
                  error={errors?.phoneNumber ? true : false}
                  helperText={errors?.phoneNumber?.message}
                />

                <TextInput
                  inputRef={register({ name: "website" })}
                  label={Resource.common.website}
                  placeholder={Resource.website.add}
                  value={poiState?.website?.newValue || ''}
                  onChange={onChangeWebsite}
                  error={errors?.website ? true : false}
                  helperText={errors?.website?.message}
                />

                <BelongBuilding
                  value={poiState?.objectId?.newValue || ''}
                  onChange={onChangeBuilding}
                  location={poiState?.location?.newValue}
                />

                <TagInputV2
                  placeholder={Resource.hashTag.add}
                  value={poiState?.tags?.add}
                  onChange={onChangeTag}
                />

                <MetaDataInputV2
                  value={poiState?.metadata?.add}
                  onChange={onChangeMetaData}
                />

                <TextAreaInput
                  label={Resource.common.description}
                  placeholder={Resource.description.add}
                  value={poiState?.description?.newValue || ''}
                  onChange={onChangeDescription}
                  maxLength={MAX_LENGTH_DESCRIPTION}
                  showCount
                />

              </div>
            </Collapse>
          </form>
        </DialogBody>

        <DialogAction>
          <Button
            onClick={onCloseDialog}
            variant='cancel'
          >
            {Resource.common.cancel}
          </Button>

          <Button
            style={{
              marginLeft: "0.75rem"
            }}
            variant='send'
            type='submit'
            form={FORM_ID}
            loading={loadingSubmit}
            disabled={loadingSubmit || isRequiredError}
          >
            {Resource.common.send}
          </Button>

        </DialogAction>

      </Dialog >

      <ConfirmV2
        type='warning'
        open={showConfirm}
        message={Resource.confirmMessage.cancelRequestUpdatePlace}
        onConfirm={onConfirmClose}
        onClose={onCloseConfirm}
      />

      <CongratsBanner
        type="info"
        title={Resource.messageBanner.thankForImproving}
        subTitle={Resource.messageBanner.updateStatusRequest}
        open={showBanner}
        icon={
          <img src={ImageConfig.banner.likeHand} alt='likeHand' />
        }
        action={
          <Button variant="close" onClick={onCloseBanner}>{Resource.button.done.toLocaleUpperCase()}</Button>
        }
        onClose={onCloseBanner}
      />
    </>
  )
};

AddPoi.propTypes = {
  //
};

export default AddPoi;
