import { Box, List, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AppData } from '../../data';
import { MapEventEnum } from '../../enum';
import RightChildMenu from '../common/rightChildMenu/rightChildMenu';

const maxHeight = 320
const width = 180

const useStyles = makeStyles({
  paper: {
    maxWidth: '100%',
    minWidth: width + "px",
    maxWidth: width + "px",
    position: 'absolute',
    backgroundColor: 'white',
  }
})

function LocationMenu() {
  const classes = useStyles()
  const [location, setLocation] = useState(null)
  const [menu, setMenu] = useState({
    show: false,
    position: {
      left: 0,
      top: 0
    },
    anchor: {
      x: "left",
      y: "top"
    }
  })
  useEffect(() => {
    const eventRightClick = AppData.map.addListener(MapEventEnum.rightClick, (args) => {
      const point = args?.pixel;
      let dataLocation = args?.location;
      setLocation(dataLocation)
      let pointTemp = {
        left: point.x,
        top: point.y
      }
      let anchorTemp = {
        x: "left",
        y: "top"
      }
      if (pointTemp.left + width >= window.innerWidth) {
        anchorTemp.x = "right"
      }
      if (pointTemp.top + maxHeight >= window.innerHeight) {
        anchorTemp.y = "bottom"
      }
      setMenu({
        show: true,
        position: pointTemp,
        anchor: anchorTemp
      })
    }, { location: true })

    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      setMenu({
        show: false,
      })
    },
      {
        location: true,
        mapbuilding: true,
        place: true
      })

    const eventBoundsChanged = AppData.map.addListener(MapEventEnum.boundsChanged, (args) => {
      setMenu({
        show: false,
      })

    })

    const eventClickBuilding = AppData.map.addListener(MapEventEnum.rightClick, (args) => {
      setMenu({
        show: false,
      })
    },
      {
        mapbuilding: true,
        mappoi: true
      })

    return () => {
      eventClickBuilding?.remove()
      eventRightClick?.remove()
      eventClick?.remove()
      eventBoundsChanged?.remove()
    }
  }, [])

  const contextMenu = (e) => {
    e.preventDefault()
  }
  const onClickCloseMenu = () => {
    setMenu({
      show: false,
    })
  }

  return (
    menu.show &&
    <Box position="absolute" left={menu.position?.left + "px"} top={menu.position?.top + "px"}>
      <Paper
        onContextMenu={contextMenu}
        style={{
          [menu.anchor.x]: "0",
          [menu.anchor.y]: "0"
        }}
        className={classes.paper}>

        <List aria-label="main mailbox folders">
          <RightChildMenu latlng={location} onClick={onClickCloseMenu} />
        </List>
      </Paper>
    </Box>
  )
}

LocationMenu.propTypes = {
  //
};

export default LocationMenu;
