import { Button, ButtonGroup, createMuiTheme, Tooltip } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { LinkIconConfig } from '../../../config';
import { AppData } from '../../../data';
import { Resource } from '../../../resource';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})

const useStyles = makeStyles({
  root: {
    padding: "4px",
    height: "32px",
    minWidth: "32px",
    width: '32px'

  },

});

function BearingControl() {
  const classes = useStyles()


  const handleTurnRight = (e) => {
    let camera = AppData.map.getCamera();
    camera.setBearing(camera.getBearing() + 10);
    AppData.map.moveCamera(camera, { animate: true })
  }

  const handleTurnLeft = (e) => {
    let camera = AppData.map.getCamera();
    camera.setBearing(camera.getBearing() - 10);
    AppData.map.moveCamera(camera, { animate: true })
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <ButtonGroup
          color="primary"
        >
          <Tooltip title={Resource.common.left} >
            <Button className={classes.root} variant="contained" onClick={handleTurnLeft}>
              <img src={LinkIconConfig.turnLeft} height={20} width={20} />
            </Button>
          </Tooltip>

          <Tooltip title={Resource.common.right}>
            <Button className={classes.root} variant="contained" onClick={handleTurnRight}>
              <img src={LinkIconConfig.turnRight} height={20} width={20} />
            </Button>
          </Tooltip>

        </ButtonGroup>
      </ThemeProvider>
    </>
  )
}

BearingControl.propTypes = {
  //
};

export default BearingControl;
