import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { ShowSearchBarState } from '../../appState';

const ShowSearchBar = () => {

  const setShowSearchBar = useSetRecoilState(ShowSearchBarState)

  useEffect(() => {
    setShowSearchBar(true)
    return () => {
      setShowSearchBar(false)
    }
  }, [])

  return null
};

ShowSearchBar.propTypes = {
  //
};

export default ShowSearchBar;
