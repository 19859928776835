import React, { Component, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Resource } from '../../../resource';
import { FileCopy } from '@material-ui/icons';

function FileInput(props) {
  const { defaultValue, value, onChange, accept } = props
  const [stateValue, setStateValue] = useState(defaultValue || "");

  const fileInputRef = useRef(null)

  // useEffect(() => {
  //   if (!("value" in props)) {
  //     onChange && onChange(stateValue)
  //   }
  // }, [stateValue, props])

  const onChangeState = (e) => {
    onChange && onChange(e.target?.files[0])
    if ("value" in props) {
      
    }
    else {
      setStateValue(e.target?.files[0])
    }
  }
  const handleTriggerUpload = () => {
    fileInputRef.current?.click()
  }
  return (
    <>
      <TextField
        label={Resource.file.add}
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onClick={handleTriggerUpload}
        InputProps={{
          endAdornment: <FileCopy></FileCopy>,
        }}
        value={("value" in props) ? value : stateValue?.name || ""}
      />
      <input accept={accept ? accept : null} type="file" ref={fileInputRef} onChange={onChangeState} hidden />
    </>
  )
}

FileInput.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  accept: PropTypes.string
};

export default FileInput;
