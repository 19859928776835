import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { useState } from 'react';
import { Resource } from '../../../resource';
import PropTypes from 'prop-types';
import { Children } from 'react';
const useStyles = makeStyles({
  dialog: {
    width: '500px'
  }
})
function Confirm({ title, onConfirm, children, message, onCancel, closeOnConfirm }) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)

  const handleCancel = () => {
    setOpen(false)
    onCancel && onCancel()
  }

  const handleShowConfirm = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    if (closeOnConfirm) {
      setOpen(false)
    }
    onConfirm && onConfirm()
  }

  return (
    <>
      {
        Children.map(children, child => {
          // checking isValidElement is the safe way and avoids a typescript error too
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { onClick: handleShowConfirm });
          }
          return child;
        })
      }

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCancel}
        classes={{
          paper: !fullScreen ? classes.dialog : ''
        }}
      >
        <DialogTitle>{title ? title : Resource.common.confirm}</DialogTitle>
        <DialogContent dividers>
          {message}
        </DialogContent>

        <DialogActions>
          <Button variant='text' onClick={handleCancel}>{Resource.button.close}</Button>
          <Button variant='contained' onClick={handleConfirm} color='primary'>{Resource.button.ok}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Confirm.propTypes = {
  children: PropTypes.any,
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default Confirm;
