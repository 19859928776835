import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker } from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import * as yup from "yup";
import { LocationCanBackState } from "../../appState";
import { CommonConfig, ImageConfig, LinkIconConfig } from "../../config";
import { RouterConfig } from "../../config/routerConfig";
import { UrlConfig } from "../../config/urlConfig";
import { AppData } from "../../data";
import {
  AlertTypeEnum,
  CodeEnum,
  MapEventEnum,
  MapTypeEnum,
  ModelObjectTypeEnum,
  ModelTypeEnum,
  RequestTypeEnum,
  TypeAddressEnum,
} from "../../enum";
import { Resource } from "../../resource";
import {
  ApiTool,
  AppTool,
  DateTimeTool,
  PlaceTool,
  SidebarTool,
} from "../../tool";
import CategoryInput from "../common/categoryInput/categoryInput";
import Confirm from "../common/confirm/confirm";
import ElevationInput from "../common/elevationInput/elevationInput";
import Field from "../common/field/field";
import FileInput from "../common/fileInput/fileInput";
import HeightInput from "../common/heightInput/heightInput";
import ModelInput from "../common/modelInput/modelInput";
import ScaleInput from "../common/scaleInput/scaleInput";
import SidebarHeader from "../common/sidebarHeader/sidebarHeader";
import { MapTypeAppState } from "./../../appState/mapTypeAppState";
import { LocationTool } from "./../../tool/locationTool";
import AddressInput from "./../common/addressInput/addressInput";
import BearingInput from "./../common/bearingInput/bearingInput";
import MetaDataInput from "./../common/metaDataInput/metaDataInput";
import PolygonInput from "./../common/polygonInput/polygonInput";
import { useForm } from "../../useHooks";

const marginField = 24;
const marginText = 10;

const useStyles = makeStyles({
  header: {
    boxShadow: "0px 0px 2px #00000029",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: "4px",
    transform: "translateY(50%)",
  },
  marginBottomField: {
    marginBottom: `${marginField}px`,
  },
  marginBottomText: {
    marginBottom: `${marginText}px`,
  },
  extendBtn: {
    margin: `${marginField}px 0`,
    textTransform: "inherit",
    fontWeight: 500,
  },
});

const ARR_KEY_VALUE = [
  "name",
  "placeDetails",
  "location",
  "scale",
  "bearing",
  "elevation",
  "heightScale",
  "camera",
  "model",
  "types",
  "minZoom",
  "maxZoom",
  "addressComponents",
  "startDate",
  "endDate",
  "source",
  "typeInfos",
  "metadata",
  "typeInfo",
];

function Object3DRequestUpdate() {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  const setMapTypeAppState = useSetRecoilState(MapTypeAppState);
  const locationCanBackState = useRecoilValue(LocationCanBackState);

  const buildingRef = useRef(null);
  const markerRef = useRef(null);
  const modelTypeRef = useRef(ModelTypeEnum.available);
  const defaultObjectRef = useRef();

  const [object, setObject] = useState(null);
  const [scale, setScale] = useState(1);
  const [height, setHeight] = useState(1);
  const [bearing, setBearing] = useState(0);
  const [elevation, setElevation] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minZoom, setMinZoom] = useState(17);
  const [maxZoom, setMaxZoom] = useState(19);

  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [coordinates, setCoordinates] = useState([]);
  const [addressComponents, setAddressComponents] = useState([]);
  const [modelType, setModelType] = useState(ModelTypeEnum.available);

  const [disableBtnSend, setDisableBtnSend] = useState(false);
  const [showExtend, setShowExtend] = useState(false);
  const [reason, setReason] = useState('');
  const [name, setName] = useState('');

  const modelTypeLabel = {
    [ModelTypeEnum.available]: Resource.model.available,
    [ModelTypeEnum.upload]: Resource.common.upload,
    [ModelTypeEnum.draw]: Resource.common.draw,
  };

  const schema = yup.object().shape({
    model: yup.mixed().nullable(),
    coordinates: yup.mixed().nullable(),
    modelFile: yup.mixed().nullable(),
    name: yup
      .string()
      .required(
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.name,
        })
      ),
    changeReason: yup.string().required(
      Resource.formatString(Resource.validate.required, {
        field: Resource.updatePlace.reasonUpdate,
      })
    ),
    types: yup.array().nullable(),
    lat: yup
      .number()
      .required(
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.lat,
        })
      )
      .min(
        -90,
        Resource.formatString(Resource.validate.min, {
          field: Resource.common.lat,
          min: -90,
        })
      )
      .max(
        90,
        Resource.formatString(Resource.validate.max, {
          field: Resource.common.lat,
          max: 90,
        })
      )
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(
        Resource.formatString(Resource.validate.typeError, {
          field: Resource.common.lat,
          type: Resource.common.number.toLowerCase(),
        })
      ),
    lng: yup
      .number()
      .required(
        Resource.formatString(Resource.validate.required, {
          field: Resource.common.lng,
        })
      )
      .min(
        -180,
        Resource.formatString(Resource.validate.min, {
          field: Resource.common.lng,
          min: -180,
        })
      )
      .max(
        180,
        Resource.formatString(Resource.validate.max, {
          field: Resource.common.lng,
          max: 180,
        })
      )
      .transform((value, originalValue) => {
        return !originalValue && !value ? undefined : value;
      })
      .typeError(
        Resource.formatString(Resource.validate.typeError, {
          field: Resource.common.lng,
          type: Resource.common.number.toLowerCase(),
        })
      ),
    addressComponents: yup.mixed().nullable().test(
      "required",
      Resource.formatString(Resource.validate.required, {
        field: Resource.place.province,
      }),
      function (addressComponents) {
        let address = addressComponents?.find((t) => {
          return t.types?.includes(TypeAddressEnum.province);
        });
        if (!address) {
          return false;
        }
        return true;
      }
    ),
  });

  const { register, handleSubmit, errors, setValue, formState, isRequiredError } = useForm({
    resolver: schema,
  });

  const setShowDefaultBuilding = () => {
    markerRef.current?.setMap(null);
    if (defaultObjectRef.current?.model?.type == ModelObjectTypeEnum.object) {
      buildingRef.current?.setModel(
        defaultObjectRef.current?.model.objUrl || ""
      );
      buildingRef.current?.setTexture(
        defaultObjectRef.current?.model.textureUrl || ""
      );
      buildingRef.current?.setMap(AppData.map);
    } else if (
      defaultObjectRef.current?.model?.type == ModelObjectTypeEnum.polygon
    ) {
      buildingRef.current?.setCoordinates(
        defaultObjectRef.current?.model?.coordinates
      );
      buildingRef.current?.setHeight(defaultObjectRef.current?.model?.height);
      buildingRef.current?.setMap(AppData.map);
    }
    buildingRef.current?.setSelected(true);
  };

  useEffect(() => {
    const eventDragBuilding = AppData.map.addListener(
      MapEventEnum.dragEnd,
      (args) => {
        setPosition(args.building.getPosition());
        if (modelTypeRef.current == ModelTypeEnum.draw) {
          setCoordinates(args.building.getCoordinates());
        }
      },
      { building: true }
    );

    const eventDragMarker = AppData.map.addListener(
      MapEventEnum.dragEnd,
      (args) => {
        setPosition(args.marker.getPosition());
      },
      { marker: true }
    );

    const eventClick = AppData.map.addListener(MapEventEnum.click, (args) => {
      if (modelTypeRef.current != ModelTypeEnum.draw) {
        setPosition(args.location);
      }
    });

    return () => {
      eventDragMarker?.remove();
      eventDragBuilding?.remove();
      eventClick?.remove();
    };
  }, []);

  useEffect(() => {
    setValue("lat", position?.lat, { shouldValidate: formState.isSubmitted });
    setValue("lng", position?.lng, { shouldValidate: formState.isSubmitted });
  }, [position]);

  useEffect(() => {
    modelTypeRef.current = modelType;

    setValue("model", null, { shouldValidate: formState.isSubmitted });
    setValue("textureFile", null, { shouldValidate: formState.isSubmitted });
    setValue("modelFile", null, { shouldValidate: formState.isSubmitted });
    setValue("coordinates", null, { shouldValidate: formState.isSubmitted });
    setCoordinates(null);

    if (modelType == ModelTypeEnum.draw) {
      buildingRef.current?.setScale(1);
      buildingRef.current?.setBearing(0);
      setScale(1);
      setBearing(0);
      markerRef.current?.setMap(null);
    } else {
      setShowDefaultBuilding();
    }
  }, [modelType]);

  useEffect(() => {
    setMapTypeAppState(MapTypeEnum.map3d);
    AppData.map.setHiddenBuilding(id);

    ApiTool.get(UrlConfig.object.detail.replace("{id}", id), (res) => {
      if (res?.code == CodeEnum.ok) {
        let newObject = res?.result || {};
        defaultObjectRef.current = newObject;

        if (newObject?.types?.length > 0) {
          setValue("types", newObject?.types);
          let categories = [];
          Object.keys(newObject?.typeInfos)?.forEach((key) => {
            categories.push({
              id: key,
              name: newObject?.typeInfos[key],
            });
          });
          newObject.categories = categories;
        }

        setPosition(newObject?.location);
        if (newObject?.model?.id) {
          setModelType(ModelTypeEnum.available);
        } else {
          if (newObject?.model?.type == ModelObjectTypeEnum.object) {
            setModelType(ModelTypeEnum.upload);
          } else {
            setModelType(ModelTypeEnum.draw);
          }
        }

        setShowDefaultBuilding();

        let camera = new map4d.CameraPosition();
        camera.setTarget(newObject?.location);
        camera.setZoom(newObject?.camera?.zoom);
        camera.setTilt(newObject?.camera?.tilt);
        camera.setBearing(newObject?.camera?.bearing);
        AppData.map.moveCamera(camera, { animate: true });

        setBearing(newObject.bearing);
        setScale(newObject.scale);
        setElevation(newObject.elevation);
        setMinZoom(newObject.minZoom);
        setMaxZoom(newObject.maxZoom);
        setStartDate(newObject.startDate ? new Date(newObject.startDate) : null);
        setEndDate(newObject.endDate ? new Date(newObject.endDate) : null);
        setAddressComponents(JSON.parse(JSON.stringify(newObject.addressComponents || [])));

        setObject(newObject);
        setName(newObject?.name)
        setValue('name', newObject?.name)
      } else {
        setObject(null);
      }
    });
    return () => {
      AppData.map.setUnhiddenBuilding(id);
    };
  }, [id]);

  useEffect(() => {
    if (LocationTool.isValid(position)) {
      if (!markerRef.current) {
        markerRef.current = new map4d.Marker({
          position: position,
          anchor: [0.5, 1],
          draggable: true,
          icon: new map4d.Icon(30, 42, ImageConfig.marker),
          zIndex: 1000,
        });
        markerRef.current.setMap(AppData.map);
      }
      buildingRef.current?.setPosition(position);
      markerRef.current?.setPosition(position);
    }
  }, [position]);

  useEffect(() => {
    buildingRef.current = new map4d.Building({
      position: { lat: 0, lng: 0 },
      draggable: true,
      zIndex: 1000,
    });
    SidebarTool.setOpen(true);
    return () => {
      buildingRef.current?.setMap(null);
      markerRef.current?.setMap(null);
    };
  }, []);

  useEffect(() => {
    setValue("addressComponents", addressComponents, {
      shouldValidate: formState.isSubmitted,
    });
  }, [addressComponents]);

  const handleClickClose = () => {
    if (locationCanBackState) {
      history.goBack();
    } else {
      history.push({
        pathname: RouterConfig.home,
      });
    }
  };

  const onChangeStartDate = (date) => {
    setStartDate(DateTimeTool.getDateWithStartTime(date));
  };
  const onChangeEndDate = (date) => {
    setEndDate(DateTimeTool.getDateWithEndTime(date));
  };

  const onChangeElevation = (value) => {
    let newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      buildingRef.current?.setElevation(newValue);
    }
    setElevation(newValue);
  };
  const onChangeBearing = (value) => {
    let newValue = parseFloat(value);
    if (!isNaN(newValue)) {
      buildingRef.current?.setBearing(newValue);
    }
    setBearing(value);
  };

  const onChangeScale = (value) => {
    let newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue > 0) {
      buildingRef.current?.setScale(newValue);
    }
    setScale(value);
  };

  const onChangeModel = (model) => {
    if (model) {
      markerRef.current?.setMap(null);
      buildingRef.current?.setModel(model.objUrl);
      buildingRef.current?.setTexture(model.textureUrl);
      buildingRef.current?.setMap(AppData.map);
      buildingRef.current?.setSelected(true);
    } else {
      setShowDefaultBuilding();
    }
    setValue("model", model, { shouldValidate: formState.isSubmitted });
  };
  const onChangeMinZoom = (e) => {
    let zoom = e.target.value;
    setMinZoom(zoom);
  };
  const onChangeMaxZoom = (e) => {
    let zoom = e.target.value;
    setMaxZoom(zoom);
  };

  const onChangeModelType = (e) => {
    setModelType(e.target.value);
  };

  const onChangeLng = (e) => {
    let value = e.target.value;
    setPosition((prev) => {
      let newPosition = {
        ...prev,
        lng: value,
      };
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera();
        camera.setTarget(newPosition);
        AppData.map.moveCamera(camera, { animate: true });
      }
      return newPosition;
    });
  };

  const onChangeLat = (e) => {
    let value = e.target.value;
    setPosition((prev) => {
      let newPosition = {
        ...prev,
        lat: value,
      };
      if (LocationTool.isValid(newPosition)) {
        let camera = AppData.map.getCamera();
        camera.setTarget(newPosition);
        AppData.map.moveCamera(camera, { animate: true });
      }
      return newPosition;
    });
  };

  const getTileCover = (callback) => {
    let zoomRank = Array.from(
      { length: maxZoom - minZoom + 1 },
      (_, i) => i + minZoom
    );
    buildingRef.current?.getTileCovers(zoomRank, (tiles) => {
      callback(tiles);
    });
  };

  const onChangePolygon = (coord) => {
    if (coord) {
      buildingRef.current?.setCoordinates(coord);
      buildingRef.current?.setMap(AppData.map);
      setPosition(buildingRef.current?.getPosition());
      buildingRef.current?.setSelected(true);
    } else {
      setShowDefaultBuilding();
    }
    setValue("coordinates", coord, { shouldValidate: formState.isSubmitted });
  };

  const onChangeHeight = (value) => {
    let newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue > 0) {
      buildingRef.current?.setHeight(newValue);
    }
    setHeight(value);
  };

  const onChangeCategory = (categories) => {
    let types = [];
    types = categories?.map((item) => {
      return item?.id;
    });
    setValue("types", types);
  };

  const onChangeAddress = (address) => {
    setAddressComponents(address);
  };

  const onChangeModelFile = (modelFile) => {
    if (modelFile) {
      markerRef.current?.setMap(null);
      buildingRef.current?.setModel(URL.createObjectURL(modelFile));
      buildingRef.current?.setMap(AppData.map);
      buildingRef.current?.setSelected(true);
    } else {
      setShowDefaultBuilding();
    }
    setValue("modelFile", modelFile, { shouldValidate: formState.isSubmitted });
  };

  const onChangeTextureFile = (textureFile) => {
    if (textureFile) {
      buildingRef.current?.setTexture(URL.createObjectURL(textureFile));
    } else {
      buildingRef.current?.setTexture("");
    }
    setValue("textureFile", textureFile, {
      shouldValidate: formState.isSubmitted,
    });
  };

  const onChangeModeDraw = (isDrawing) => {
    if (isDrawing) {
      buildingRef.current?.setMap(null);
    } else {
      buildingRef.current?.setMap(AppData.map);
    }
  };

  const onChangeMetaData = (metadata) => {
    setValue("metadata", metadata, { shouldValidate: formState.isSubmitted });
  };

  const handleShowExtend = () => {
    setShowExtend(!showExtend);
  };

  const cancel = () => {
    history.push({
      pathname: RouterConfig.object3d.detail.replace(":id", id),
    });
  };

  const onChangeReason = (e) => {
    let value = e?.target.value
    setReason(value)
    setValue('changeReason', value, { shouldValidate: formState.isSubmitted })
  }

  const onChangeName = (e) => {
    let value = e?.target.value
    setName(value)
    setValue('name', value, { shouldValidate: formState.isSubmitted })
  }

  const addBuilding = (data) => {
    let updatedData = {
      ...data,
      placeDetails: object?.placeDetails,
      heightScale: object?.heightScale,
      typeInfos: object?.typeInfos,
    };
    let body = PlaceTool.convertDataRequestUpdate(
      defaultObjectRef?.current,
      updatedData,
      ARR_KEY_VALUE
    );

    if (body) {
      body.changeReason = data?.changeReason;
      body.source = CommonConfig.source;
      (body.objectId = object?.id),
        ApiTool.post(
          UrlConfig.objectEdit.create,
          body,
          (res) => {
            setDisableBtnSend(false);
            if (res?.code == CodeEnum.ok) {
              AppTool.alert(
                Resource.formatString(Resource.alert.success.requestUpdate, {
                  object: Resource.object.name.toLowerCase() + " 3D ",
                }),
                AlertTypeEnum.success
              );
              history.push({
                pathname: RouterConfig.object3d.detail.replace(":id", id),
              });
            } else {
              AppTool.alert(
                res?.message || Resource.formatString(Resource.alert.error.requestUpdate, {
                  object: Resource.object.name.toLowerCase() + " 3D ",
                }),
                AlertTypeEnum.error
              );
            }
          },
          true
        );
    }
    else {
      AppTool.alert(Resource.message.notUpdatedInfo, AlertTypeEnum.error);
    }
  };

  const onSubmit = (data) => {
    data.scale = scale;
    data.bearing = bearing;
    data.elevation = elevation;
    data.startDate = startDate?.getTime();
    data.endDate = endDate?.getTime();
    data.requestTypes = [RequestTypeEnum.update];
    data.location = position;
    data.minZoom = minZoom;
    data.maxZoom = maxZoom;
    data.camera = AppData.map.getCamera();
    setDisableBtnSend(true);
    getTileCover((tiles) => {
      data.tiles = tiles;
      if (modelType == ModelTypeEnum.upload) {
        if (data.modelFile) {
          let modelFileBody = {
            file: data.modelFile,
          };
          ApiTool.postFormFromJson(
            UrlConfig.file.uploadModelFile,
            modelFileBody,
            (res) => {
              if (res?.code == CodeEnum.ok) {
                data.model = {
                  type: ModelObjectTypeEnum.object,
                  objName: res.result?.name,
                  objUrl: res.result?.url,
                };

                if (data.textureFile) {
                  let textureFileBody = {
                    file: data.textureFile,
                  };
                  ApiTool.postFormFromJson(
                    UrlConfig.file.uploadTextureFile,
                    textureFileBody,
                    (res) => {
                      if (res?.code == CodeEnum.ok) {
                        data.model.textureName = res.result?.name;
                        data.model.textureUrl = res.result?.url;
                        addBuilding(data);
                      } else {
                        AppTool.alert(
                          res?.message || Resource.formatString(Resource.alert.error.upload, {
                            object: Resource.common.texture.toLowerCase(),
                          }),
                          AlertTypeEnum.error
                        );
                      }
                    },
                    true
                  );
                } else {
                  addBuilding(data);
                }
              } else {
                AppTool.alert(
                  res?.message || Resource.formatString(Resource.alert.error.upload, {
                    object: Resource.common.modelFile.toLowerCase(),
                  }),
                  AlertTypeEnum.error
                );
              }
            },
            true
          );
        } else if (data.textureFile) {
          let textureFileBody = {
            file: data.textureFile,
          };
          data.model = { ...(defaultObjectRef.current?.model || {}) };
          ApiTool.postFormFromJson(
            UrlConfig.file.uploadTextureFile,
            textureFileBody,
            (res) => {
              if (res?.code == CodeEnum.ok) {
                data.model.textureName = res.result?.name;
                data.model.textureUrl = res.result?.url;
                addBuilding(data);
              } else {
                AppTool.alert(
                  res?.message || Resource.formatString(Resource.alert.error.upload, {
                    object: Resource.common.texture.toLowerCase(),
                  }),
                  AlertTypeEnum.error
                );
              }
            },
            true
          );
        } else {
          data.model = defaultObjectRef.current?.model;
          addBuilding(data);
        }
      } else if (modelType == ModelTypeEnum.draw) {
        if (data.coordinates?.length > 0) {
          data.model = {
            coordinates: data.coordinates,
            height: height,
            type: ModelObjectTypeEnum.polygon,
          };
          addBuilding(data);
        } else {
          data.model = defaultObjectRef.current?.model;
          addBuilding(data);
        }
      } else {
        addBuilding(data);
      }
    });
  };

  // register({ name: "lat", type: "custom" });
  // register({ name: "lng", type: "custom" });
  // register({ name: "metadata", type: "custom" });
  // register({ name: "coordinates", type: "custom" });
  // register({ name: "types", type: "custom" });
  // register({ name: "modelFile", type: "custom" });
  // register({ name: "textureFile", type: "custom" });
  // register({ name: "model", type: "custom" });
  // register({ name: "addressComponents", type: "custom" });
  // register({ name: "places", type: "custom" });

  return (
    <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Box
        width={`${CommonConfig.widthSearchBar + 2 * CommonConfig.padding}px`}
        height="100%"
        bgcolor="background.paper"
        display="flex"
        flexDirection="column"
      >
        <SidebarHeader
          title={Resource.object3d.requestUpdate}
          subTitle={object?.name}
          onClose={handleClickClose}
        />
        {object ? (
          <Box
            overflow="auto"
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <Box p={`${marginField}px`} flexGrow={1} overflow="auto">
              <Field
                disableMarginTop
                label={Resource.updatePlace.reasonUpdate}
                icon={
                  <img
                    src={LinkIconConfig.requestUpdatePlace.reason}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <TextField
                  variant="outlined"
                  inputRef={register({ name: "changeReason" })}
                  error={errors.changeReason ? true : false}
                  helperText={errors?.changeReason?.message}
                  label={Resource.updatePlace.addReason}
                  value={reason}
                  onChange={onChangeReason}
                />
              </Field>
              <Field
                label={Resource.common.name}
                icon={
                  <img
                    src={LinkIconConfig.requestUpdatePlace.placeName}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <TextField
                  value={name}
                  inputRef={register({ name: "name" })}
                  error={errors.name ? true : false}
                  label={Resource.name.add}
                  placeholder={Resource.name.add}
                  variant="outlined"
                  helperText={errors.name?.message}
                  onChange={onChangeName}
                />
              </Field>
              <Field
                label={Resource.common.category}
                icon={
                  <img
                    src={LinkIconConfig.common.category}
                    width={24}
                    height={24}
                  />
                }
              >
                <CategoryInput
                  defaultValue={object?.categories}
                  onChange={onChangeCategory}
                />
              </Field>
              <Field
                label={Resource.common.lat}
                icon={
                  <img src={LinkIconConfig.common.lat} width={24} height={24} />
                }
                required
              >
                <TextField
                  value={position?.lat}
                  onChange={onChangeLat}
                  inputProps={{
                    step: CommonConfig.lng.step,
                  }}
                  label={Resource.lat.add}
                  placeholder={Resource.lat.add}
                  variant="outlined"
                  type="number"
                  name="lat"
                  error={errors.lat ? true : false}
                  helperText={errors.lat && errors.lat.message}
                  inputRef={register({ name: "lat" })}
                />
              </Field>
              <Field
                label={Resource.common.lng}
                icon={
                  <img src={LinkIconConfig.common.lng} width={24} height={24} />
                }
                required
              >
                <TextField
                  value={position?.lng}
                  onChange={onChangeLng}
                  inputProps={{
                    step: CommonConfig.lng.step,
                  }}
                  label={Resource.lng.add}
                  placeholder={Resource.lng.add}
                  variant="outlined"
                  type="number"
                  name="lng"
                  error={errors.lng ? true : false}
                  helperText={errors.lng && errors.lng.message}
                  inputRef={register({ name: "lng" })}
                />
              </Field>
              <Field
                label={Resource.model.type}
                icon={
                  <img
                    src={LinkIconConfig.common.category}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <FormControl variant="outlined">
                  <InputLabel id="modelType">
                    {Resource.model.chooseType}
                  </InputLabel>
                  <Select
                    labelId="modelType"
                    label={Resource.model.chooseType}
                    value={modelType}
                    onChange={onChangeModelType}
                  >
                    {Object.values(ModelTypeEnum).map((type, index) => {
                      return (
                        <MenuItem key={type} value={type}>
                          {modelTypeLabel[type]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Field>
              {modelType == ModelTypeEnum.available && (
                <Field
                  label={Resource.common.model}
                  icon={
                    <img
                      src={LinkIconConfig.common.model}
                      width={24}
                      height={24}
                    />
                  }
                  required
                  error={errors.model ? true : false}
                  helperText={errors.model?.message}
                >
                  <ModelInput
                    defaultValue={object?.model}
                    onChange={onChangeModel}
                  />
                </Field>
              )}

              {modelType == ModelTypeEnum.draw && (
                <>
                  <Field
                    label={Resource.common.bottomSurface}
                    icon={
                      <img
                        src={LinkIconConfig.common.bottomSurface}
                        width={24}
                        height={24}
                      />
                    }
                    required
                    helperText={
                      errors.coordinates ? (
                        <span style={{ color: "red" }}>
                          {errors.coordinates?.message}
                        </span>
                      ) : (
                        ""
                      )
                    }
                  >
                    <PolygonInput
                      onChangeMode={onChangeModeDraw}
                      value={coordinates}
                      map={AppData.map}
                      onChange={onChangePolygon}
                    />
                  </Field>
                  <Field
                    label={Resource.common.height}
                    icon={
                      <img
                        src={LinkIconConfig.common.height}
                        width={24}
                        height={24}
                      />
                    }
                    required
                  >
                    <HeightInput value={height} onChange={onChangeHeight} />
                  </Field>
                </>
              )}
              {modelType == ModelTypeEnum.upload && (
                <>
                  <Field
                    label={Resource.common.modelFile}
                    icon={
                      <img
                        src={LinkIconConfig.common.modelFile}
                        width={24}
                        height={24}
                      />
                    }
                  // required
                  // helperText={errors.modelFile?.message}
                  // error={errors.modelFile}
                  >
                    <FileInput
                      accept=".obj"
                      onChange={onChangeModelFile}
                      defaultValue={{ name: object?.model?.objName }}
                    />
                  </Field>
                  <Field
                    label={Resource.common.texture}
                    icon={
                      <img
                        src={LinkIconConfig.common.textureFile}
                        width={24}
                        height={24}
                      />
                    }
                  >
                    <FileInput
                      onChange={onChangeTextureFile}
                      accept="image/*"
                      defaultValue={{ name: object?.model?.textureName }}
                    />
                  </Field>
                </>
              )}
              {modelType != ModelTypeEnum.draw && (
                <>
                  <Field
                    label={Resource.common.scale}
                    icon={
                      <img
                        src={LinkIconConfig.common.scale}
                        width={24}
                        height={24}
                      />
                    }
                    required
                  >
                    <ScaleInput value={scale} onChange={onChangeScale} />
                  </Field>
                  <Field
                    label={Resource.common.bearing}
                    icon={
                      <img
                        src={LinkIconConfig.common.bearing}
                        width={24}
                        height={24}
                      />
                    }
                    required
                  >
                    <BearingInput onChange={onChangeBearing} value={bearing} />
                  </Field>
                </>
              )}
              <Field
                label={Resource.common.elevation}
                icon={
                  <img
                    src={LinkIconConfig.common.elevation}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <ElevationInput
                  onChange={onChangeElevation}
                  value={elevation}
                />
              </Field>

              {
                position && (
                  <div ref={register({ name: "addressComponents" })}>
                    <Field
                      label={Resource.common.address}
                      icon={
                        <img
                          src={LinkIconConfig.common.lat}
                          width={24}
                          height={24}
                        />
                      }
                      required
                    >
                      <AddressInput
                        position={position}
                        value={addressComponents}
                        onChange={onChangeAddress}
                        error={errors.addressComponents ? true : false}
                        helperText={errors.addressComponents?.message}
                      />
                    </Field>
                  </div>
                )}

              <Field
                label={Resource.common.minZoom}
                icon={
                  <img
                    src={LinkIconConfig.common.minZoom}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <FormControl variant="outlined">
                  <InputLabel id="minZoom">
                    {Resource.minZoom.choose}
                  </InputLabel>
                  <Select
                    labelId="minZoom"
                    label={Resource.minZoom.choose}
                    value={minZoom}
                    onChange={onChangeMinZoom}
                  >
                    {Array.from(
                      { length: maxZoom - 17 + 1 },
                      (_, i) => i + 17
                    ).map((zoom, index) => (
                      <MenuItem key={zoom} value={zoom}>
                        {zoom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Field>
              <Field
                label={Resource.common.maxZoom}
                icon={
                  <img
                    src={LinkIconConfig.common.maxZoom}
                    width={24}
                    height={24}
                  />
                }
                required
              >
                <FormControl variant="outlined">
                  <InputLabel id="minZoom">
                    {Resource.maxZoom.choose}
                  </InputLabel>
                  <Select
                    labelId="minZoom"
                    label={Resource.maxZoom.choose}
                    value={maxZoom}
                    onChange={onChangeMaxZoom}
                  >
                    {Array.from(
                      { length: 19 - minZoom + 1 },
                      (_, i) => i + minZoom
                    ).map((zoom, index) => (
                      <MenuItem key={zoom} value={zoom}>
                        {zoom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Field>
              <Field
                label={Resource.common.startDate}
                icon={
                  <img
                    src={LinkIconConfig.common.startDate}
                    width={24}
                    height={24}
                  />
                }
              >
                <DatePicker
                  label={Resource.startDate.choose}
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  clearable
                  value={startDate}
                  maxDate={endDate || CommonConfig.maxDate}
                  onChange={onChangeStartDate}
                  format="dd/MM/yyyy"
                />
              </Field>
              <Field
                label={Resource.common.endDate}
                icon={
                  <img
                    src={LinkIconConfig.common.endDate}
                    width={24}
                    height={24}
                  />
                }
              >
                <DatePicker
                  label={Resource.endDate.choose}
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  clearable
                  value={endDate}
                  minDate={startDate || CommonConfig.minDate}
                  onChange={onChangeEndDate}
                  format="dd/MM/yyyy"
                />
              </Field>

              <Box>
                <Button
                  className={classes.extendBtn}
                  fullWidth
                  variant="text"
                  onClick={handleShowExtend}
                  endIcon={showExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {Resource.updatePlace.addExtendBtn}
                </Button>
                <Collapse in={showExtend} timeout={600}>
                  <MetaDataInput
                    value={object?.metadata}
                    onChange={onChangeMetaData}
                  />
                </Collapse>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-evenly"
              p={"20px"}
              borderTop={1}
              borderColor="grey.300"
            >
              <Confirm
                onConfirm={cancel}
                message={Resource.formatString(
                  Resource.confirmMessage.cancelRequestUpdate,
                  { object: Resource.object.name?.toLowerCase() + " 3D " }
                )}
              >
                <Button>{Resource.button.cancel}</Button>
              </Confirm>

              <Button
                disabled={disableBtnSend || isRequiredError}
                type="submit"
                variant="contained"
                color="primary"
              >
                {Resource.button.send}
              </Button>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </form>
  );
}

Object3DRequestUpdate.propTypes = {
  //
};

export default Object3DRequestUpdate;
