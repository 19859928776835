export const PlaceTypeEnum = Object.freeze({
    gas: "gas_station",
    charging: "charging_station",
    hotel: "hotel",
    coffee: "cafe",
    homestay: "guest_house",
    restaurant: "restaurant",
    grocery: "grocery_store",
    bank: "bank",
    museum: "museum",
    park: "park",
    touristAttract:"tourist_attraction",
    school: "education",
    bar: "bar",
    hospital: 'hospital'
})