import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import "./comboBox.scss"
import * as ReactDOM from "react-dom"
import { StringTool } from "../../tool"
import { Resource } from "../../resource"

const CLEAR_ICON =
  <svg width="20" height="20" viewBox="0 0 24 24">
    <path id="Path_6362" data-name="Path 6362" d="M0,0H24V24H0Z" fill="none" />
    <path d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z" fill="#869195" />
  </svg>

const OPEN_ICON =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-150 -464)" >
      <path d="M5,10,0,5,5,0Z" transform="translate(167 473) rotate(90)" fill="#505050" />
    </g>
  </svg>


const CLOSE_ICON =
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(-150 -464)" >
      <path d="M14,7,9,12l5,5Z" transform="translate(150 487) rotate(-90)" fill="#505050" />
    </g>
  </svg>

const anchorEnum = {
  top: "bottom",
  bottom: "top",
}

const keyControl = { key: null }
const HEIGHT_INPUT_SEARCH = 42

const ComboBox = ({
  options,
  value,
  getOptionLabel,
  onChange,
  disabled,
  error,
  label,
  placeholder,
  clearable,
  required,
  helperText,
}) => {
  const [optionsState, setOptionsState] = useState([])
  const [valueState, setValueState] = useState()
  const [isOpen, setOpen] = useState(false)
  const [filterOptions, setFilterOption] = useState()
  const [index, setIndex] = useState(-1)
  const [active, setActive] = useState()

  const [anchor, setAnchor] = useState(anchorEnum.bottom)
  const [distance, setDistance] = useState(0)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)

  const scrollRef = useRef(null)
  const listRef = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    if (options) {
      setOptionsState(options)
    }
  }, [options])

  useEffect(() => {
    setFilterOption(optionsState)
  }, [optionsState, valueState])

  useEffect(() => {
    let isString = typeof value === "string" || value instanceof String
    let newValue = optionsState?.find((option) => {
      if (isString) {
        return option?.value === value || option?.id === value
      }
      else {
        return (
          (option?.value && option?.value === value?.value) ||
          (option?.id && option?.id === value?.id)
        )
      }
    })
    setValueState(newValue || (clearable ? null : optionsState[0] || null))
  }, [value, optionsState, clearable])

  const handleScrollAndResize = () => {
    let cpn = ref?.current?.getBoundingClientRect() || {}
    let innerHeight = window.innerHeight
    let innerHeightBetween = innerHeight / 2
    let isTop = (innerHeightBetween + cpn?.top) / 2 > innerHeightBetween
    setMaxHeight(innerHeight - (cpn?.top + cpn?.height))
    setAnchor(anchorEnum.bottom)
    setDistance(cpn?.top + cpn?.height)
    setLeft(cpn?.left)
    if (isTop) {
      setDistance(innerHeight - cpn?.top)
      setMaxHeight(cpn?.top)
      setAnchor(anchorEnum.top)
    }
  }

  useEffect(() => {
    isOpen && handleScrollAndResize()
  }, [isOpen, disabled])

  useEffect(() => {
    handleScrollAndResize()

    let elements = ref?.current
    let observer
    let resize

    const handleTrackWidth = (entries) => {
      setWidth(entries[0]?.contentRect?.width)
    }

    if (elements) {
      observer = new IntersectionObserver(onIntersection)
      function onIntersection(entries, opts) {
        if (!entries[0]?.isIntersecting) {
          setOpen(false)
        }
      }
      resize = new ResizeObserver(handleTrackWidth)

      observer.observe(elements)
      resize.observe(elements)
    }

    window.addEventListener("resize", handleScrollAndResize, true)
    document.addEventListener("scroll", handleScrollAndResize, true)

    return () => {
      resize?.disconnect()
      observer?.disconnect()
      window.removeEventListener("resize", handleScrollAndResize)
      document.removeEventListener("scroll", handleScrollAndResize)
    }
  }, [])

  useEffect(() => {
    const handleClose = (event) => {
      var isClickInsideElement = ref?.current?.contains(event.target)
      var isClickInsideElementListRef = listRef?.current?.contains(
        event.target
      )
      if (!isClickInsideElement && !isClickInsideElementListRef) {
        setOpen(false)
      }
    }
    window.addEventListener("mousedown", handleClose)
    return () => window.removeEventListener("mousedown", handleClose)
  }, [])

  useEffect(() => {
    setIndex(-1)
  }, [isOpen])

  const handleOptionLabel = (value) => {
    return getOptionLabel && value ? getOptionLabel(value) : value?.name || ""
  }

  const handleSelected = (option) => () => {
    setValueState(option)
    onChange && onChange(option)
    setOpen(false)
  }

  const handleOpen = () => {
    if (!disabled) {
      setOpen(!isOpen)
    }
  }
  const handleClickOnInput = () => {
    if (!disabled) {
      setOpen(true)
    }
  }
  const handleRemoveAll = () => {
    setValueState()
    onChange && onChange(null)
    setOpen(false)
  }

  useEffect(() => {
    if (filterOptions) {
      setActive(filterOptions[index])
    }

    let scrollCPn = scrollRef?.current
    let height = scrollCPn?.getBoundingClientRect()?.height
    let distance = scrollCPn?.getBoundingClientRect()?.top

    const children = scrollCPn?.children[index]?.getBoundingClientRect()
    const distanceChildren = (children?.top - distance)?.toFixed()
    const distanceOption = index * HEIGHT_INPUT_SEARCH

    if (keyControl.key === 38) {
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = distanceOption
      }
      if (distanceChildren > height) {
        scrollCPn.scrollTop = distanceChildren
      }
    }
    if (keyControl.key === 40) {
      if (distanceChildren > height - HEIGHT_INPUT_SEARCH) {
        scrollCPn.scrollTop = distanceOption - height + HEIGHT_INPUT_SEARCH
      }
      if (distanceChildren <= 0) {
        scrollCPn.scrollTop = 0
      }
    }
  }, [index])

  const onKeyDownEventFired = (event) => {
    const filterOptionsLength = filterOptions?.length - 1

    if (isOpen && !disabled) {
      switch (event.which) {
        case 38:
          index <= 0
            ? setIndex(filterOptionsLength)
            : setIndex((prevCount) => prevCount - 1)
          keyControl.key = 38
          event.preventDefault()
          break
        case 40:
          index >= filterOptionsLength
            ? setIndex(0)
            : setIndex((prevCount) => prevCount + 1)
          keyControl.key = 40
          event.preventDefault()
          break
        case 13:
          if (
            active?.id != undefined ||
            handleOptionLabel(active) != undefined
          ) {
            onChange && onChange(active)
            setOpen(false)
            setValueState(active)
          }

          break
        default:
          return
      }
    }
    if (event.which === 40 || (38 && !isOpen && !disabled)) {
      setOpen(true)
    }
  }

  let isBottom = (anchor === "top")

  return (
    <div
      ref={ref}
      onKeyDown={onKeyDownEventFired}
      tabIndex={0}
      className={StringTool.mergeClassName("map4dComboBox", disabled ? "disabled" : "")}
    >
      <div className="comboBoxWrap">
        <fieldset
          className={StringTool.mergeClassName("fieldset", label ? "" : "fieldsetNotLabel", error ? 'fieldsetError' : '')}
        >
          {
            label &&
            <legend className={StringTool.mergeClassName("legend", isOpen ? "legendFocus" : "", error ? 'legendError' : '')}>
              {label}
              {
                required &&
                <span className="required">*</span>
              }
            </legend>
          }
        </fieldset>

        <div className="comboBoxRoot" onClick={handleOpen}>
          <div className="comboBox" onClick={handleClickOnInput}>
            {
              <div
                className={StringTool.mergeClassName("name", disabled ? "disabled" : "")}
                title={valueState ? handleOptionLabel(valueState) : null}
              >
                {valueState ? handleOptionLabel(valueState) : placeholder || ""}
              </div>
            }
          </div>

          <div className={StringTool.mergeClassName('selectAndClear', disabled ? "justifyContentEnd" : "")}>
            {
              !disabled && clearable && valueState &&
              <div className="clear" onClick={handleRemoveAll}>
                {CLEAR_ICON}
              </div>
            }
            <div className={StringTool.mergeClassName('open', disabled ? "disabled" : "")}>
              {isOpen ? OPEN_ICON : CLOSE_ICON}
            </div>
          </div>
        </div>
      </div>

      {
        helperText &&
        <div className="helperText">{helperText}</div>
      }

      {
        ReactDOM.createPortal(
          isOpen && (
            <div
              className="listWrapOfMap4dComboBox"
              style={{
                [anchor]: distance + 6,
                width: width,
                left: left,
                maxHeight: maxHeight - 9,
                flexDirection: isBottom ? "column" : "column-reverse",
              }}
              ref={listRef}
            >
              {
                filterOptions?.length > 0 ? (
                  <div className="renderList" ref={scrollRef}>
                    {
                      filterOptions?.map((option, index) => {
                        let isActive = option?.value ? active?.value == option?.value : active?.id == option?.id
                        let text = handleOptionLabel(option)

                        return (
                          <div
                            key={text + index}
                            className={StringTool.mergeClassName("option", isActive ? 'optionActive' : '')}
                            onClick={handleSelected(option)}
                            tabIndex={index}
                            title={text}
                          >
                            <span className="text">{text}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                ) : (
                  <div className="notRenderList">
                    <span>{Resource.common.noData}</span>
                  </div>
                )}
            </div>
          ),
          document.body
        )
      }
    </div >
  )
}

ComboBox.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  searchLength: PropTypes.number,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
}

export default ComboBox
