import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
    const { search } = useLocation()

    return useMemo(() => {
        return new Proxy(new URLSearchParams(search), {
            get: (searchParams, prop) => searchParams.get(prop),
        })
    }, [search])
}