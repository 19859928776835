import React from "react"

export const SvgIconConfig = Object.freeze({
  common: {
    menu:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(24306 22598)">
          <g transform="translate(-0.25 22.929)">
            <path d="M18.75,1.855H.75A.859.859,0,0,1,0,.927.859.859,0,0,1,.75,0h18a.859.859,0,0,1,.75.927A.859.859,0,0,1,18.75,1.855Z" transform="translate(-24303.75 -22615.928)" fill="#505050" />
            <path d="M18.75,1.856H.75A.86.86,0,0,1,0,.928.86.86,0,0,1,.75,0h18a.86.86,0,0,1,.75.928A.86.86,0,0,1,18.75,1.856Z" transform="translate(-24303.75 -22609.928)" fill="#505050" />
            <path d="M18.75,1.85H.75A.858.858,0,0,1,0,.925.858.858,0,0,1,.75,0h18a.858.858,0,0,1,.75.925A.858.858,0,0,1,18.75,1.85Z" transform="translate(-24303.75 -22603.926)" fill="#505050" />
          </g>
        </g>
      </svg>,
    close:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-1720 -218)">
          <path d="M0,0H24V24H0Z" transform="translate(1720 218)" fill="none" />
          <path d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(1720 218)" fill="#505050" />
        </g>
      </svg>
  },
  search: {
    history:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M12,2A10,10,0,1,1,2,12H4A8,8,0,1,0,5.385,7.5H8v2H2v-6H4V6A9.981,9.981,0,0,1,12,2Zm1,5v4.585l3.243,3.243-1.415,1.415L11,12.413V7Z" fill="#869195" />
      </svg>,

    poi:
      <svg width="24" height="24" viewBox="0 0 24 24">

        <g transform="translate(-429 -564)">
          <g transform="translate(402.497 425.496)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(33.836 144.669)">
              <g transform="translate(0 0)">
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="#869195" />
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="none" />
              </g>
              <circle cx="1.941" cy="1.941" r="1.941" transform="translate(2.553 2.553)" fill="#fff" />
            </g>
          </g>
        </g>
      </svg>,

    restaurant:
      <svg width="23.997" height="23.997" viewBox="0 0 23.997 23.997">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M22.565,2.874V23.3H20.391v-6.3H16.043V9.48a6.619,6.619,0,0,1,6.522-6.606ZM20.391,5.749A4.315,4.315,0,0,0,18.217,9.48v5.376h2.174V5.749ZM9.522,14.594V23.3H7.348V14.594A5.393,5.393,0,0,1,3,9.326V2.874H5.174V10.4H7.348V2.874H9.522V10.4H11.7V2.874h2.174V9.326a5.393,5.393,0,0,1-4.347,5.268Z" transform="translate(-0.745 -0.825)" fill="#869195" />
      </svg>,

    hotel:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-38 -526)">
          <path d="M23.794,11.628v9.807H21.615V18.166H4.179v3.269H2V4H4.179V14.9H12.9V7.269h6.538A4.359,4.359,0,0,1,23.794,11.628ZM21.615,14.9V11.628a2.179,2.179,0,0,0-2.179-2.179H15.077V14.9ZM8.538,11.628a1.09,1.09,0,1,0-1.09-1.09A1.09,1.09,0,0,0,8.538,11.628Zm0,2.179a3.269,3.269,0,1,1,3.269-3.269A3.269,3.269,0,0,1,8.538,13.807Z" transform="translate(37 525.282)" fill="#869195" />
        </g>
      </svg>,

    tourism:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-1969 -89)">
          <path d="M0,0H24V24H0Z" transform="translate(1969 89)" fill="none" />
          <g transform="translate(1964.823 83.904)">
            <path d="M25.272,16.222V14.4L16.136,8,7,14.4v1.827H8.827v8.222H7v1.827H25.272V24.445H23.445V16.222ZM21.758,24.34H18.947V18.717l-2.811,4.218-2.811-4.218V24.34H10.514V14.5h2.811l2.811,4.216L18.947,14.5h2.811Z" transform="translate(0 0)" fill="#869195" />
          </g>
        </g>
      </svg>,
  },

  direction: {
    start:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(4689 -1673)">
          <path d="M6.5-2707a6.508,6.508,0,0,1-6.5-6.5,6.507,6.507,0,0,1,6.5-6.5,6.507,6.507,0,0,1,6.5,6.5A6.507,6.507,0,0,1,6.5-2707Zm0-11.074a4.579,4.579,0,0,0-4.574,4.574,4.579,4.579,0,0,0,4.574,4.574,4.579,4.579,0,0,0,4.574-4.574A4.579,4.579,0,0,0,6.5-2718.074Z" transform="translate(-4684 4398)" fill="#505050" />
        </g>
      </svg>,
    startSmall:
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(1 -1)">
          <g transform="translate(2.043 4.043)">
            <g transform="translate(-0.043 -0.043)" fill="#fff" stroke="#555" strokeWidth="2">
              <circle cx="5" cy="5" r="5" stroke="none" />
              <circle cx="5" cy="5" r="4" fill="none" />
            </g>
          </g>
        </g>
      </svg>,
      middleHighlightSmall:
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(1 -1)">
          <g transform="translate(2.043 4.043)">
            <g transform="translate(-0.043 -0.043)" fill="#fff" stroke="#1C75BC" strokeWidth="2">
              <circle cx="5" cy="5" r="5" stroke="none" />
              <circle cx="5" cy="5" r="4" fill="none" />
            </g>
          </g>
        </g>
      </svg>,
    end:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-1838 -49)">
          <g transform="translate(1693 -148)" >
            <g transform="translate(195 -3205.537)" >
              <g transform="translate(-44.744 3405)">
                <path d="M10.827,19.967a.588.588,0,0,1-.49-.27L4.892,10.908a6.867,6.867,0,1,1,11.736-.093l-5.311,8.874a.574.574,0,0,1-.49.279ZM10.734,1.578a5.724,5.724,0,0,0-4.872,8.73L10.819,18.3l4.829-8.063a5.719,5.719,0,0,0-4.914-8.654Z" transform="translate(-3.87 -0.43)" fill="#e40000" />
                <circle cx="1.638" cy="1.638" r="1.638" transform="translate(5.226 5.007)" fill="#e40000" />
              </g>
            </g>
          </g>
        </g>
      </svg>,
    endSmall:
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(0.404 -1.596)">
          <g transform="translate(0 2)">
            <g>
              <g transform="translate(3.286 1.462)">
                <path d="M5.755,2.119,4.708,4.34,5.755,7.48S7.143,10.028,7.166,10.1s1.65,2.1,1.65,2.1l2.391-3.443,1.406-3.406-.25-2.016L11.208,1.448,8.649.854Z" transform="translate(-4.477 -0.565)" fill="#e40000" />
                <path d="M8.238,12.7a.369.369,0,0,1-.307-.17L4.511,7.009A4.312,4.312,0,1,1,11.88,6.95L8.546,12.522a.361.361,0,0,1-.307.175ZM8.18,1.151A3.594,3.594,0,0,0,5.121,6.632l3.112,5.015,3.032-5.063A3.591,3.591,0,0,0,8.18,1.151Z" transform="translate(-3.869 -0.43)" fill="#e40000" />
                <circle cx="1.71" cy="1.71" r="1.71" transform="translate(2.6 2.462)" fill="#fff" />
              </g>
            </g>
          </g>
        </g>
      </svg>,
    treeDots:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M12,3a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,3Zm0,14a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z" fill="#9f9f9f" />
      </svg>,
    dotSmall:
      <svg width="8" height="8" viewBox="0 0 8 8">
        <g transform="translate(-4 -1)">
          <g transform="translate(-3 -3)">
            <path d="M0,0H24V24H0Z" fill="none" />
            <path d="M12,17.643a1.43,1.43,0,1,0,1.5,1.429A1.47,1.47,0,0,0,12,17.643ZM12,11.5a1.43,1.43,0,1,0,1.5,1.429A1.47,1.47,0,0,0,12,11.5Z" transform="translate(-1 -5)" fill="#555" />
          </g>
        </g>
      </svg>,
    drag:
      <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(16052 -16790)">
          <g transform="translate(0 0.486)">
            <g transform="translate(51.5 -66.494)">
              <path d="M8,13.5H7.995a1.5,1.5,0,0,1,0-3H8a1.5,1.5,0,0,1,0,3Z" transform="translate(-16104 16878.016) rotate(-90)" fill="#848484" />
              <path d="M12,13.5H12a1.5,1.5,0,1,1,0-3H12a1.5,1.5,0,1,1,0,3Z" transform="translate(-16104 16876.008) rotate(-90)" fill="#848484" />
              <path d="M16.005,13.5H16a1.5,1.5,0,1,1,0-3h.009a1.5,1.5,0,0,1,0,3Z" transform="translate(-16104 16874) rotate(-90)" fill="#848484" />
            </g>
            <g transform="translate(44.5 -66.494)">
              <path d="M8,13.5H7.995a1.5,1.5,0,0,1,0-3H8a1.5,1.5,0,0,1,0,3Z" transform="translate(-16104 16878.016) rotate(-90)" fill="#848484" />
              <path d="M12,13.5H12a1.5,1.5,0,1,1,0-3H12a1.5,1.5,0,1,1,0,3Z" transform="translate(-16104 16876.008) rotate(-90)" fill="#848484" />
              <path d="M16.005,13.5H16a1.5,1.5,0,1,1,0-3h.009a1.5,1.5,0,0,1,0,3Z" transform="translate(-16104 16874) rotate(-90)" fill="#848484" />
            </g>
          </g>
        </g>
      </svg>,
    swap:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-1762 -11)">
          <g transform="translate(1310 -3384)">
            <g transform="translate(0 1)">
              <path d="M0,0H24V24H0Z" transform="translate(452 3394)" fill="none" />
              <g transform="translate(0 -1.318)">
                <path d="M1.95,7.95.536,9.364-2,6.828l.007,7.808h-2L-4,6.828-6.535,9.364-7.95,7.95-3,3Z" transform="translate(464 3394)" fill="#505050" />
                <path d="M1.95,7.95.536,9.364-2,6.828l.007,7.808h-2L-4,6.828-6.535,9.364-7.95,7.95-3,3Z" transform="translate(464 3420.636) rotate(180)" fill="#505050" />
              </g>
            </g>
          </g>
        </g>
      </svg>,
    remove:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-4381 -208)">
          <path d="M0,0H24V24H0Z" transform="translate(4381 208)" fill="none" />
          <path d="M11.5,20.99a9.5,9.5,0,1,1,9.5-9.5A9.495,9.495,0,0,1,11.5,20.99Zm0-1.9a7.6,7.6,0,1,0-7.6-7.6A7.6,7.6,0,0,0,11.5,19.091Zm0-8.939L14.18,7.466,15.524,8.81,12.838,11.5l2.686,2.685L14.18,15.524,11.5,12.838,8.81,15.524,7.466,14.18,10.153,11.5,7.466,8.81,8.81,7.466Z" transform="translate(4381.504 208.504)" fill="#505050" />
        </g>
      </svg>,
    car:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M18.92,6.01A1.494,1.494,0,0,0,17.5,5H6.5A1.5,1.5,0,0,0,5.08,6.01L3,12v8a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V19H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V12ZM6.5,16A1.5,1.5,0,1,1,8,14.5,1.5,1.5,0,0,1,6.5,16Zm11,0A1.5,1.5,0,1,1,19,14.5,1.5,1.5,0,0,1,17.5,16ZM5,11,6.5,6.5h11L19,11Z" fill="#505050" />
      </svg>,
    carActive:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M18.92,6.01A1.494,1.494,0,0,0,17.5,5H6.5A1.5,1.5,0,0,0,5.08,6.01L3,12v8a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V19H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V12ZM6.5,16A1.5,1.5,0,1,1,8,14.5,1.5,1.5,0,0,1,6.5,16Zm11,0A1.5,1.5,0,1,1,19,14.5,1.5,1.5,0,0,1,17.5,16ZM5,11,6.5,6.5h11L19,11Z" fill="#1c75bc" />
      </svg>,
    motorbike:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none" />
        <path d="M20,11a4.534,4.534,0,0,0-.53.05L17.41,9H20V6L16.28,7.86,13.41,5H9V7h3.59l2,2H11L7,11,5,9H0v2H4a4,4,0,1,0,4,4l2,2h3l3.49-6.1,1.01,1.01A3.956,3.956,0,0,0,16,15a4,4,0,1,0,4-4ZM4,17a2,2,0,1,1,2-2A2.006,2.006,0,0,1,4,17Zm16,0a2,2,0,1,1,2-2A2.006,2.006,0,0,1,20,17Z" fill="#505050" />
      </svg>,
    motorbikeActive:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none" />
        <path d="M20,11a4.534,4.534,0,0,0-.53.05L17.41,9H20V6L16.28,7.86,13.41,5H9V7h3.59l2,2H11L7,11,5,9H0v2H4a4,4,0,1,0,4,4l2,2h3l3.49-6.1,1.01,1.01A3.956,3.956,0,0,0,16,15a4,4,0,1,0,4-4ZM4,17a2,2,0,1,1,2-2A2.006,2.006,0,0,1,4,17Zm16,0a2,2,0,1,1,2-2A2.006,2.006,0,0,1,20,17Z" fill="#1c75bc" />
      </svg>,
    bicycle:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M5.5,21A4.5,4.5,0,1,1,10,16.5,4.5,4.5,0,0,1,5.5,21Zm0-2A2.5,2.5,0,1,0,3,16.5,2.5,2.5,0,0,0,5.5,19Zm13,2A4.5,4.5,0,1,1,23,16.5,4.5,4.5,0,0,1,18.5,21Zm0-2A2.5,2.5,0,1,0,16,16.5,2.5,2.5,0,0,0,18.5,19Zm-7.477-8.695L13,12v6H11V13L8.281,10.734A2,2,0,0,1,8,7.671l2.828-2.828a2,2,0,0,1,2.829,0l1.414,1.414a6.969,6.969,0,0,0,3.917,1.975l-.01,2.015a8.962,8.962,0,0,1-5.321-2.575l-2.634,2.633ZM16,5a2,2,0,1,1,2-2A2,2,0,0,1,16,5Z" transform="translate(0 1)" fill="#505050" />
      </svg>,
    bicycleActive:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M5.5,21A4.5,4.5,0,1,1,10,16.5,4.5,4.5,0,0,1,5.5,21Zm0-2A2.5,2.5,0,1,0,3,16.5,2.5,2.5,0,0,0,5.5,19Zm13,2A4.5,4.5,0,1,1,23,16.5,4.5,4.5,0,0,1,18.5,21Zm0-2A2.5,2.5,0,1,0,16,16.5,2.5,2.5,0,0,0,18.5,19Zm-7.477-8.695L13,12v6H11V13L8.281,10.734A2,2,0,0,1,8,7.671l2.828-2.828a2,2,0,0,1,2.829,0l1.414,1.414a6.969,6.969,0,0,0,3.917,1.975l-.01,2.015a8.962,8.962,0,0,1-5.321-2.575l-2.634,2.633ZM16,5a2,2,0,1,1,2-2A2,2,0,0,1,16,5Z" transform="translate(0 1)" fill="#1c75bc" />
      </svg>,
    walk:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_2781" data-name="Path 2781" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_2782" data-name="Path 2782" d="M13.5,5.5a2,2,0,1,0-2-2A2.006,2.006,0,0,0,13.5,5.5ZM9.8,8.9,7,23H9.1l1.8-8L13,17v6h2V15.5l-2.1-2,.6-3A7.322,7.322,0,0,0,19,13V11a4.919,4.919,0,0,1-4.3-2.4L13.7,7A2.062,2.062,0,0,0,12,6c-.3,0-.5.1-.8.1L6,8.3V13H8V9.6l1.8-.7" fill="#505050" />
      </svg>,
    walkActive:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_2781" data-name="Path 2781" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_2782" data-name="Path 2782" d="M13.5,5.5a2,2,0,1,0-2-2A2.006,2.006,0,0,0,13.5,5.5ZM9.8,8.9,7,23H9.1l1.8-8L13,17v6h2V15.5l-2.1-2,.6-3A7.322,7.322,0,0,0,19,13V11a4.919,4.919,0,0,1-4.3-2.4L13.7,7A2.062,2.062,0,0,0,12,6c-.3,0-.5.1-.8.1L6,8.3V13H8V9.6l1.8-.7" fill="#1c75bc" />
      </svg>,
    poi:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-429 -564)">
          <g transform="translate(402.497 425.496)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(33.836 144.669)">
              <g transform="translate(0 0)">
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="#869195" />
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="none" />
              </g>
              <circle cx="1.941" cy="1.941" r="1.941" transform="translate(2.553 2.553)" fill="#fff" />
            </g>
          </g>
        </g>
      </svg>,
    addDestination:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(16173 -16889)">
          <g transform="translate(-16173 16889)">
            <path d="M12,22A10,10,0,1,0,2,12,10.029,10.029,0,0,0,12,22Z" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M8,12h8" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M12,16V8" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
        </g>
      </svg>,
    disableAddDestination:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g>
          <g transform="translate(16173 -16889)">
            <g transform="translate(-16173 16889)">
              <path d="M12,22A10,10,0,1,0,2,12,10.029,10.029,0,0,0,12,22Z" fill="none" stroke="#d5d5d5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
              <path d="M8,12h8" fill="none" stroke="#d5d5d5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
              <path d="M12,16V8" fill="none" stroke="#d5d5d5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
          </g>
        </g>
      </svg>,
    suggestPoi: {
      line:
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g transform="translate(-2236 -99)">
            <g transform="translate(2209.496 -39.504)">
              <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
              <g transform="translate(14303.259 15472.259)">
                <g transform="translate(-14270.419 -15327.419)">
                  <g>
                    <g>
                      <path d="M8.8,0H6.023V1.853H5.1V0H2.317L0,11.12H11.12ZM6.023,10.193H5.1V8.34h.927Zm0-2.78H5.1V5.56h.927Zm0-2.78H5.1V2.78h.927Z" fill="#869195" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>,
      building:
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g transform="translate(-2236 -99)" clipPath="url(#clip-path)">
            <g transform="translate(2209.496 -39.504)">
              <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
              <g transform="translate(31.504 143.504)">
                <path d="M0,0H14V14H0Z" fill="none" />
                <path d="M12.659,12.31h1.166v1.164H1V12.31H2.166V3.582A.582.582,0,0,1,2.749,3H8.578a.582.582,0,0,1,.583.582V12.31h2.332V7.655H10.327V6.491h1.749a.582.582,0,0,1,.583.582ZM3.332,4.164V12.31H8V4.164ZM4.5,7.655H6.829V8.819H4.5Zm0-2.328H6.829V6.491H4.5Z" transform="translate(-0.412 -1.236)" fill="#727272" />
              </g>
            </g>
          </g>
        </svg>,
      area:
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g transform="translate(-2236 -99)" >
            <g transform="translate(2209.496 -39.504)">
              <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
              <g transform="translate(32.28 144.28)">
                <path d="M0,0H12.637V12.637H0Z" fill="none" />
                <path d="M2,2H4.633V4.633H2ZM2,9.9H4.633v2.633H2ZM9.9,2h2.633V4.633H9.9Zm0,7.9h2.633v2.633H9.9ZM5.159,3.053H9.371V4.106H5.159ZM3.053,5.159H4.106V9.371H3.053Zm7.371,0h1.053V9.371H10.424ZM5.159,10.424H9.371v1.053H5.159Z" transform="translate(-0.947 -0.947)" fill="#869195" />
              </g>
            </g>
          </g>
        </svg>,
      history:
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M0,0H24V24H0Z" fill="none" />
          <g transform="translate(-26.505 -138.504)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(31.504 143.504)">
              <pat d="M0,0H13.879V13.879H0Z" fill="none" />
              <path d="M7.783,13.566a5.783,5.783,0,1,1,5.783-5.783A5.783,5.783,0,0,1,7.783,13.566Zm0-1.157A4.626,4.626,0,1,0,3.157,7.783,4.626,4.626,0,0,0,7.783,12.409Zm.578-4.626h2.313V8.939H7.2V4.891H8.361Z" transform="translate(-0.843 -0.843)" fill="#869195" />
            </g>
          </g>
        </svg>,
      place:
        <svg width="24" height="24" viewBox="0 0 24 24">
          <g transform="translate(-429 -564)">
            <g transform="translate(402.497 425.496)">
              <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
              <g transform="translate(33.836 144.669)">
                <g transform="translate(0 0)">
                  <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="#869195" />
                  <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="none" />
                </g>
                <circle cx="1.941" cy="1.941" r="1.941" transform="translate(2.553 2.553)" fill="#fff" />
              </g>
            </g>
          </g>
        </svg>
    },
    home:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M20,20a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H1L11.327,1.612a1,1,0,0,1,1.346,0L23,11H20Zm-2-1V9.157L12,3.7,6,9.157V19Zm-6-2L8.641,13.641a2.25,2.25,0,1,1,3.182-3.182l.177.177.177-.177a2.25,2.25,0,1,1,3.182,3.182Z" fill="#869195" />
      </svg>,
    office:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <clipPath id="clip-path">
            <rect width="24" height="24" transform="translate(104.5 133.5)" fill="#fff" stroke="#707070" strokeWidth="1" />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect width="10" height="5" transform="translate(80 482)" fill="#869195" stroke="#707070" strokeWidth="1" />
          </clipPath>
        </defs>
        <g transform="translate(-104.5 -133.5)" clipPath="url(#clip-path)">
          <g transform="translate(2.5 0.4)">
            <g transform="translate(29 -345)" clipPath="url(#clip-path-2)">
              <g transform="translate(76 480.154)">
                <path d="M16.5,5.375H13.844V2.846a1,1,0,0,0-1-1H5.156a1,1,0,0,0-1,1V5.375H1.5A1.5,1.5,0,0,0,0,6.875V16.5A1.5,1.5,0,0,0,1.5,18h15A1.5,1.5,0,0,0,18,16.5V6.875A1.5,1.5,0,0,0,16.5,5.375ZM6.156,3.847h5.688v1.53H6.156Z" fill="#869195" />
              </g>
            </g>
            <g transform="translate(105 140.5)" fill="none" stroke="#869195" strokeWidth="2">
              <rect width="18" height="12.7" rx="1.6" stroke="none" />
              <rect x="1" y="1" width="16" height="10.7" rx="0.6" fill="none" />
            </g>
          </g>
        </g>
      </svg>,
    history:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M12,2A10,10,0,1,1,2,12H4A8,8,0,1,0,5.385,7.5H8v2H2v-6H4V6A9.981,9.981,0,0,1,12,2Zm1,5v4.585l3.243,3.243-1.415,1.415L11,12.413V7Z" fill="#869195" />
      </svg>,
    yourLocation:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g>
          <g transform="translate(4 4)">
            <path d="M7.04,16V14.652A6.724,6.724,0,0,1,1.348,8.96H0V7.04H1.348A6.723,6.723,0,0,1,7.04,1.348V0H8.96V1.348A6.724,6.724,0,0,1,14.652,7.04H16V8.96H14.652A6.725,6.725,0,0,1,8.96,14.652V16Z" fill="#1c75bc" />
            <g transform="translate(3 3)">
              <circle cx="5" cy="5" r="5" fill="#fff" />
            </g>
            <g transform="translate(5 5)">
              <circle cx="3" cy="3" r="3" fill="#1c75bc" />
            </g>
          </g>
        </g>
      </svg>,
    viewMap:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-221 -204)">
          <path d="M2,5,9,2l6,3,6.3-2.7a.5.5,0,0,1,.7.46V19l-7,3L9,19,2.7,21.7a.5.5,0,0,1-.7-.46ZM16,19.4l4-1.714V5.033L16,6.747Zm-2-.132V6.736l-4-2V17.264ZM8,17.253V4.6L4,6.319V18.967Z" transform="translate(221 204)" fill="#869195" />
        </g>
      </svg>,
    verticalArrow:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(1.179 6.984)">
          <path d="M6.41,1.176,5.232-.049l5.333-5.125L15.7-.038l-1.2,1.2L10.542-2.793Z" fill="#090a0a" />
          <rect width="1.7" height="18.1" transform="translate(9.61 -3.984)" fill="#090a0a" />
        </g>
      </svg>,
    addDirection:
      <svg width="36" height="36" viewBox="0 0 36 36">
        <g transform="translate(-15560 2696)">
          <g transform="translate(15560 -2696)" fill="#f5f7fa">
            <path d="M 18 35.5 C 13.32557964324951 35.5 8.930950164794922 33.67967987060547 5.625629901885986 30.37437057495117 C 2.320319890975952 27.06904983520508 0.5 22.67441940307617 0.5 18 C 0.5 13.32557964324951 2.320319890975952 8.930950164794922 5.625629901885986 5.625629901885986 C 8.930950164794922 2.320319890975952 13.32557964324951 0.5 18 0.5 C 22.67441940307617 0.5 27.06904983520508 2.320319890975952 30.37437057495117 5.625629901885986 C 33.67967987060547 8.930950164794922 35.5 13.32557964324951 35.5 18 C 35.5 22.67441940307617 33.67967987060547 27.06904983520508 30.37437057495117 30.37437057495117 C 27.06904983520508 33.67967987060547 22.67441940307617 35.5 18 35.5 Z" stroke="none" />
            <path d="M 18 1 C 13.45914077758789 1 9.190059661865234 2.768310546875 5.979190826416016 5.979190826416016 C 2.768310546875 9.190059661865234 1 13.45914077758789 1 18 C 1 22.54085922241211 2.768310546875 26.80994033813477 5.979190826416016 30.02080917358398 C 9.190059661865234 33.231689453125 13.45914077758789 35 18 35 C 22.54085922241211 35 26.80994033813477 33.231689453125 30.02080917358398 30.02080917358398 C 33.231689453125 26.80994033813477 35 22.54085922241211 35 18 C 35 13.45914077758789 33.231689453125 9.190059661865234 30.02080917358398 5.979190826416016 C 26.80994033813477 2.768310546875 22.54085922241211 1 18 1 M 18 0 C 27.94112014770508 0 36 8.058879852294922 36 18 C 36 27.94112014770508 27.94112014770508 36 18 36 C 8.058879852294922 36 0 27.94112014770508 0 18 C 0 8.058879852294922 8.058879852294922 0 18 0 Z" stroke="none" fill="#1c75bc" />
          </g>
          <g transform="translate(-35 32)" >
            <g transform="translate(15602.748 -2720)">
              <path d="M13.746,11.691H9.1A.846.846,0,0,1,9.1,10h4.65a.846.846,0,0,1,0,1.691Z" transform="translate(-1.169 -1.659)" fill="#1c75bc" />
              <path d="M11.846,13.591A.846.846,0,0,1,11,12.746V8.1a.846.846,0,0,1,1.691,0v4.65A.846.846,0,0,1,11.846,13.591Z" transform="translate(-1.593 -1.223)" fill="#1c75bc" />
              <path d="M10.25,1a8.184,8.184,0,0,1,4.742,1.483,7.415,7.415,0,0,1,2.916,4.474,9.251,9.251,0,0,1-.815,6.208,15.79,15.79,0,0,1-3.328,4.417,5.069,5.069,0,0,1-7.034,0A15.824,15.824,0,0,1,3.41,13.16,9.269,9.269,0,0,1,2.6,6.949,7.416,7.416,0,0,1,5.513,2.481,8.18,8.18,0,0,1,10.25,1Zm0,16.363A3.409,3.409,0,0,0,12.63,16.4c2.151-2.066,4.535-5.317,3.682-9.084A5.988,5.988,0,0,0,10.25,2.637,5.987,5.987,0,0,0,4.192,7.311C3.34,11.077,5.719,14.333,7.866,16.4A3.43,3.43,0,0,0,10.251,17.363Z" fill="#1c75bc" />
            </g>
          </g>
        </g>
      </svg>,
    back:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M2,7l-.707-.707L.586,7l.707.707ZM17,8a1,1,0,0,0,0-2ZM7.293.293l-6,6L2.707,7.707l6-6Zm-6,7.414,6,6,1.414-1.414-6-6ZM2,8H17V6H2Z" transform="translate(2.707 5)" fill="#505050" />
      </svg>
  },

  searchBar: {
    line:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-2236 -99)">
          <g transform="translate(2209.496 -39.504)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(14303.259 15472.259)">
              <g transform="translate(-14270.419 -15327.419)">
                <g>
                  <g>
                    <path d="M8.8,0H6.023V1.853H5.1V0H2.317L0,11.12H11.12ZM6.023,10.193H5.1V8.34h.927Zm0-2.78H5.1V5.56h.927Zm0-2.78H5.1V2.78h.927Z" fill="#869195" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>,
    building:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-2236 -99)" clipPath="url(#clip-path)">
          <g transform="translate(2209.496 -39.504)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(31.504 143.504)">
              <path d="M0,0H14V14H0Z" fill="none" />
              <path d="M12.659,12.31h1.166v1.164H1V12.31H2.166V3.582A.582.582,0,0,1,2.749,3H8.578a.582.582,0,0,1,.583.582V12.31h2.332V7.655H10.327V6.491h1.749a.582.582,0,0,1,.583.582ZM3.332,4.164V12.31H8V4.164ZM4.5,7.655H6.829V8.819H4.5Zm0-2.328H6.829V6.491H4.5Z" transform="translate(-0.412 -1.236)" fill="#727272" />
            </g>
          </g>
        </g>
      </svg>,
    area:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-2236 -99)" >
          <g transform="translate(2209.496 -39.504)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(32.28 144.28)">
              <path d="M0,0H12.637V12.637H0Z" fill="none" />
              <path d="M2,2H4.633V4.633H2ZM2,9.9H4.633v2.633H2ZM9.9,2h2.633V4.633H9.9Zm0,7.9h2.633v2.633H9.9ZM5.159,3.053H9.371V4.106H5.159ZM3.053,5.159H4.106V9.371H3.053Zm7.371,0h1.053V9.371H10.424ZM5.159,10.424H9.371v1.053H5.159Z" transform="translate(-0.947 -0.947)" fill="#869195" />
            </g>
          </g>
        </g>
      </svg>,
    history:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <g transform="translate(-26.505 -138.504)">
          <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
          <g transform="translate(31.504 143.504)">
            <pat d="M0,0H13.879V13.879H0Z" fill="none" />
            <path d="M7.783,13.566a5.783,5.783,0,1,1,5.783-5.783A5.783,5.783,0,0,1,7.783,13.566Zm0-1.157A4.626,4.626,0,1,0,3.157,7.783,4.626,4.626,0,0,0,7.783,12.409Zm.578-4.626h2.313V8.939H7.2V4.891H8.361Z" transform="translate(-0.843 -0.843)" fill="#869195" />
          </g>
        </g>
      </svg>,
    place:
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-429 -564)">
          <g transform="translate(402.497 425.496)">
            <circle cx="11" cy="11" r="11" transform="translate(27.505 139.504)" fill="#ebebeb" />
            <g transform="translate(33.836 144.669)">
              <g transform="translate(0 0)">
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="#869195" />
                <path d="M6.294,1.2A4.49,4.49,0,0,0,1.8,5.694a4.308,4.308,0,0,0,.681,2.349L5.987,13.8c.068.1.136.17.238.17A.342.342,0,0,0,6.6,13.8l3.541-5.822a4.558,4.558,0,0,0,.647-2.315A4.482,4.482,0,0,0,6.294,1.2Z" transform="translate(-1.8 -1.2)" fill="none" />
              </g>
              <circle cx="1.941" cy="1.941" r="1.941" transform="translate(2.553 2.553)" fill="#fff" />
            </g>
          </g>
        </g>
      </svg>
  },
})