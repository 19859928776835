import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DialogBody from '../../../components/dialogBody/dialogBody';
import RoadAvoid from '../../../components/roadAvoid/roadAvoid';
import RouteOptimizationOption from '../../../components/routeOptimizationOption/routeOptimizationOption';
import WeightingOption from '../../../components/weightingOption/weightingOption';
import { Resource } from '../../../resource';
import Dialog from './../../../components/dialog/dialog';
import './mobileDirectionOption.scss';
import DialogAction from '../../../components/dialogAction/dialogAction';

function MobileDirectionOption(props) {
  const { onClose, isOptimize, weighting, avoids, positions, onChange } = props

  const [weightingState, setWeightingState] = useState(false)
  const [isOptimizeState, setIsOptimizeState] = useState(false)
  const [avoidsState, setAvoidsState] = useState([])

  useEffect(() => {
    setIsOptimizeState(isOptimize)
  }, [isOptimize])

  useEffect(() => {
    setWeightingState(weighting)
  }, [weighting])

  useEffect(() => {
    setAvoidsState(avoids)
  }, [avoids])

  const resetData = ()=>{
    setIsOptimizeState(isOptimize)
    setWeightingState(weighting)
    setAvoidsState(avoids)
  }

  const onChangeOptimize = (checked) => {
    setIsOptimizeState(checked)
  }

  const onChangeWeighting = (newValue) => {
    setWeightingState(newValue)
  }

  const onChangeAvoidRoads = (valueNew) => {
    setAvoidsState(valueNew || [])
  }

  const onSave = () => {
    onChange && onChange(isOptimizeState, weightingState, avoidsState)
    onClose && onClose()
  }

  const onCloseDialog = () => {
    onClose && onClose()
    resetData()
  }

  return (
    <Dialog
      className='mobileDirectionOptionCpn'
      open={true}
      onClose={onCloseDialog}
    >
      <div className='title'>
        {Resource.common.option}
      </div>
      
      <DialogBody className='mobileDirectionOptionBody'>
        <RouteOptimizationOption value={isOptimizeState} onChange={onChangeOptimize} disabled={positions?.length < 4} isMobile/>
        <WeightingOption value={weightingState} onChange={onChangeWeighting} length={positions?.length || 2} />
        <RoadAvoid value={avoidsState} onChange={onChangeAvoidRoads} />
      </DialogBody>

      <DialogAction className='mobileDirectionOptionAction'>
        <button className='actionBtn cancelBtn' onClick={onCloseDialog}>
          {Resource.button.cancel}
        </button>
        <button className='actionBtn doneBtn' onClick={onSave}>
          {Resource.button.done}
        </button>
      </DialogAction>
    </Dialog>
  )
};

MobileDirectionOption.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  isOptimize: PropTypes.bool,
  weighting: PropTypes.any,
  avoids: PropTypes.any,
  positions: PropTypes.any
}

export default MobileDirectionOption;
