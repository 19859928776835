import React, { useCallback, useEffect, useRef, useState } from "react"
import "./mobileDragBody.scss"
import PropTypes from 'prop-types';

const recheckHeightView = (heightView) => {
    if (typeof heightView == "number") {
        return (heightView + "px")
    }
    else {
        return heightView
    }
}

const isScrollableY = (ele) => {
    // Compare the height to see if the element has scrollable content
    if (ele && ele.nodeType === Node.ELEMENT_NODE) {
        const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

        // It's not enough because the element's `overflow-y` style can be set as
        // * `hidden`
        // * `hidden !important`
        // In those cases, the scrollbar isn't shown
        const overflowYStyle = window.getComputedStyle(ele).overflowY;
        const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

        return hasScrollableContent && !isOverflowHidden;
    }
    else {
        return false
    }

};
const isScrollableX = (ele) => {
    // Compare the height to see if the element has scrollable content
    if (ele && ele.nodeType === Node.ELEMENT_NODE) {
        const hasScrollableContent = ele.scrollWidth > ele.clientWidth;

        // It's not enough because the element's `overflow-y` style can be set as
        // * `hidden`
        // * `hidden !important`
        // In those cases, the scrollbar isn't shown
        const overflowXStyle = window.getComputedStyle(ele).overflowX;
        const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;

        return hasScrollableContent && !isOverflowHidden;
    }
    else {
        return false
    }

};
const isScrollingX = (element, dx, dy) => {
    if (!element) {
        return false
    }
    else {
        let absDy = Math.abs(dy)
        let absDx = Math.abs(dx)
        return (
            (
                absDx > absDy &&
                element.scrollWidth > element.clientWidth &&
                (
                    (element.scrollLeft < (element.scrollWidth - element.offsetWidth) && dx < 0) ||
                    (element.scrollLeft > 0 && dx > 0)
                )
            )
        )
    }

}
const isScrollingY = (element, dx, dy) => {
    if (!element) {
        return false
    }
    else {
        let absDy = Math.abs(dy)
        let absDx = Math.abs(dx)
        return (
            (
                absDy > absDx &&
                element.scrollHeight > element.clientHeight &&
                (
                    (element.scrollTop < (element.scrollHeight - element.offsetHeight) && dy < 0) ||
                    (element.scrollTop > 0 && dy > 0)
                )
            )
        )
    }

}

const EPSILON = 50


const MobileDragBody = (props) => {
    const { children, heightView = 0, onDragStart, onDragEnd, onDrag, minHeighView = 0, middleHeightView = 0, onChange, maxHeightView } = props

    const dragHtml = useRef()
    const canScrollHtml = useRef()
    const canScrollHtmlY = useRef()
    const canScrollHtmlX = useRef()
    const _startY = useRef()
    const _startX = useRef()
    const _isDrag = useRef()
    const _isTouch = useRef()
    const _isCheckedCanDrag = useRef()
    const _canDrag = useRef()
    const _startHeightViewDrag = useRef()
    const [style, setStyle] = useState({ height: maxHeightView || "100%" })
    const _lastHeightView = useRef(0)

    useEffect(() => {
        setStyle({ height: maxHeightView || "100%" })
    }, [maxHeightView])

    const dragToHeightView = useCallback((theHeightView) => {
        let heightViewNew = theHeightView
        let numHeight = theHeightView
        if (typeof heightViewNew == "number") {
            let heightDrag = maxHeightView || dragHtml.current?.offsetHeight
            if (heightViewNew < minHeighView) {
                heightViewNew = minHeighView
                numHeight = minHeighView
            }
            if (heightViewNew > heightDrag) {
                heightViewNew = heightDrag
                numHeight = heightDrag
            }
            heightViewNew = heightViewNew + "px"
        }
        if (dragHtml.current) {
            dragHtml.current.style.transition = "none"
            dragHtml.current.style.transform = `translateY(-${heightViewNew})`
            if (typeof numHeight == "number") {
                onDrag?.(numHeight)
            }
        }
    }, [minHeighView])

    const animationToHeightView = useCallback((theHeightView) => {
        let heightViewNew = theHeightView
        if (typeof heightViewNew == "number") {
            let heightDrag = maxHeightView || dragHtml.current?.offsetHeight
            if (heightViewNew < minHeighView) {
                heightViewNew = minHeighView
            }
            if (heightViewNew > heightDrag) {
                heightViewNew = heightDrag
            }
            _lastHeightView.current = heightViewNew
            heightViewNew = heightViewNew + "px"
        }
        if (dragHtml.current) {
            dragHtml.current.style.transition = "transform .3s"
            dragHtml.current.style.transform = `translateY(-${heightViewNew})`
        }
    }, [minHeighView])

    const getScrollParent = useCallback((node) => {
        if (node == null || node == dragHtml.current) {
            return null;
        }

        if (isScrollableX(node) || isScrollableY(node)) {
            return node;
        } else {
            return getScrollParent(node.parentNode);
        }
    }, [])

    const getScrollParentY = useCallback((node) => {
        if (node == null || node == dragHtml.current) {
            return null;
        }

        if (isScrollableY(node)) {
            return node;
        } else {
            return getScrollParentY(node.parentNode);
        }
    }, [])

    const getScrollParentX = useCallback((node) => {
        if (node == null || node == dragHtml.current) {
            return null;
        }

        if (isScrollableX(node)) {
            return node;
        } else {
            return getScrollParentX(node.parentNode);
        }
    }, [])

    useEffect(() => {
        animationToHeightView(0)
    }, [])

    useEffect(() => {
        animationToHeightView(heightView)
    }, [heightView])

    const onStartDrag = (e) => {
        _isDrag.current = true
        _startY.current = e.clientY
    }

    const onMouseMove = (e) => {
        if (_isDrag.current) {
            let y = e.clientY
            const moveY = y - _startY.current
        }
    }

    const onTouchStart = (e) => {
        if (e?.touches?.length == 1) {
            _isTouch.current = true
            _startY.current = e?.touches?.[0]?.clientY
            _startX.current = e?.touches?.[0]?.clientX
            canScrollHtmlY.current = getScrollParentY(e?.touches?.[0]?.target)
            canScrollHtmlX.current = getScrollParentX(e?.touches?.[0]?.target)
            _isCheckedCanDrag.current = false
            _canDrag.current = false

            let dragBound = dragHtml.current?.getBoundingClientRect()
            _startHeightViewDrag.current = window?.innerHeight - dragBound?.top
        }

    }

    const onTouchMove = useCallback((e) => {
        if (_isTouch.current) {
            let y = e?.touches?.[0]?.clientY
            let dy = y - _startY.current
            let x = e?.touches?.[0]?.clientX
            let dx = x - _startX.current
            let heightViewNew = _startHeightViewDrag.current - dy
            if (!_isCheckedCanDrag.current) {
                _isCheckedCanDrag.current = true
                if (!isScrollingY(canScrollHtmlY.current, dx, dy) && !isScrollingX(canScrollHtmlX.current, dx, dy)) {
                    _canDrag.current = true
                    onDragStart?.(_startHeightViewDrag.current)
                    // onChange?.(heightViewNew)
                }
            }

            if (_canDrag.current) {
                dragToHeightView(heightViewNew)
            }

        }

    }, [onDragStart])

    const onTouchEnd = (e) => {
        if (_canDrag.current) {
            _canDrag.current = false
            _isTouch.current = false

            let dy = e?.changedTouches?.[0]?.clientY - _startY.current
            let dyAbs = Math.abs(dy)
            let heightViewNew = _startHeightViewDrag.current - dy
            if (dyAbs <= EPSILON) {
                heightViewNew = _lastHeightView.current
            }
            else {

                if (heightViewNew < _startHeightViewDrag.current) {
                    if (middleHeightView && heightViewNew > middleHeightView) {
                        heightViewNew = middleHeightView
                    }
                    else {
                        heightViewNew = minHeighView
                    }
                }
                else {
                    if (middleHeightView && heightViewNew < middleHeightView) {
                        heightViewNew = middleHeightView
                    }
                    else {
                        heightViewNew = maxHeightView || dragHtml.current?.offsetHeight
                    }
                }
            }
            animationToHeightView(heightViewNew)
            onDragEnd?.(heightViewNew)
            onChange?.(heightViewNew)
            onDrag?.(heightViewNew)
        }

    }

    return (
        <div
            ref={dragHtml}
            className="mobileDragBody"
            onMouseDown={onStartDrag}
            onMouseMove={onMouseMove}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            style={style}
        >
            {
                children
            }
        </div>
    )
}

MobileDragBody.propTypes = {
    heightView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHeighView: PropTypes.number,
    middleHeightView: PropTypes.number,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onChange: PropTypes.func,
    onDrag: PropTypes.func,
    maxHeightView: PropTypes.number
}

export default MobileDragBody