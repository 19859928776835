export const ImageConfig = Object.freeze({
    placeDefault: "https://maps.gstatic.com/tactile/pane/default_geocode-1x.png",
    avaterDefault: "/mapAppRoot/imageDefault/avater-default.png",
    avaterDefaultGray: "/mapAppRoot/imageDefault/avaterDefaultGray@2x.png",
    thumbnailDefault: "/mapAppRoot/imageDefault/thumbnail-default.svg",
    logo: "/mapAppRoot/image/logo.svg",
    marker: "/mapAppRoot/image/Icon-location-gg.png",
    essentialService: {
        markerBlue: "/mapAppRoot/image/markerServiceBlue.png",
        markerRed: "/mapAppRoot/image/markerServiceRed.png",
        markerSelected: "/mapAppRoot/image/markerSelected.png"
    },
    mapTypeSelect: {
        roadmap: "/mapAppRoot/image/mapTypeSelect/roadMap.png",
        raster: "/mapAppRoot/image/mapTypeSelect/raster.png",
        satellite: "/mapAppRoot/image/mapTypeSelect/satellite.png",
        map3d: "/mapAppRoot/image/mapTypeSelect/map3d.png",
        more: "/mapAppRoot/image/mapTypeSelect/platform.png"
    },
    myLocation: "/mapAppRoot/image/myLocation/myLocation.png",
    direction: {
        foot: "/mapAppRoot/direction/foot.png"
    },
    location: {
        marker: "/mapAppRoot/image/marker/location.svg"
    },
    banner: {
        heartHand: "/mapAppRoot/image/banner/heartHand.png",
        bgBanner: '/mapAppRoot/image/bgBanner.png',
        likeHand: "/mapAppRoot/image/banner/like.png",
    }
})