import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { RatingInput } from 'ui';
import { RouterConfig, SvgIconConfig } from '../../config';
import { AppEventEnum, CodeEnum, MapEventEnum } from '../../enum';
import { Resource } from '../../resource';
import { AppEventTool, UrlTool } from '../../tool';
import { useQuery } from '../../useHooks';
import HideSidebarShadow from '../hideSidebarShadow/hideSidebarShadow';
import IsLoading from '../isLoading/isLoading';
import PoiDetail from '../poiDetail/poiDetail';
import SearchBarNearByLocation from '../searchBarNearByLocation/searchBarNearByLocation';
import TextView from '../textView/textView';
import { PlaceTypeState } from './../../appState/placeTypeState';
import { ImageConfig } from './../../config/imageConfig';
import { UrlConfig } from './../../config/urlConfig';
import { AppData } from './../../data/appData';
import { ApiTool } from './../../tool/apiTool';
import BusinessHoursStatus from './../businessHoursStatus/businessHoursStatus';
import './listPoiNearByLocation.scss';

const RADIUS = 1000
const countPoiShow = 20

const getListDraw = (list) => {
  let result = []
  if (list?.length > countPoiShow) {
    let index = list?.length - countPoiShow
    result = list?.slice((index), (list?.length + 1))
  }
  else {
    result = list
  }
  return result
}
function ListPoiNearByLocation(props) {
  const { searchKey, type, location, onClose, endPointName } = props

  const indexRef = useRef()
  const listMarkerRef = useRef()
  const markerHoverRef = useRef()
  const topRef = useRef(null);

  const [loadingData, setLoadingData] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [listPoi, setListPoi] = useState(null)
  const [textState, setTextState] = useState('')
  const [typeState, setTypeState] = useState('')
  const [count, setCount] = useState(0)
  const pageNumberRef = useRef(0)

  const AllPlaceTypeState = useRecoilValue(PlaceTypeState)
  const router = useHistory()
  const [detailId, setDetailId] = useState(null)
  const { id } = useQuery()
  const textCurrent = useRef()
  const [textNotFound, setTextNotFound] = useState('')

  // useEffect(() => {
  //   setDetailId(id)
  // }, [id])

  useEffect(() => {
    setTextState(decodeURIComponent(searchKey));
  }, [searchKey]);
  useEffect(() => {
    setTypeState(type);
  }, [type]);

  useEffect(()=>{
    textCurrent.current = textState
  },[textState])

  useEffect(() => {
    const clickMarker = AppData.map.addListener(MapEventEnum.click, (args) => {
      let data = args.poi?.getUserData()
      setDetailId(data?.id)
    }, { poi: true })
    return () => {
      clickMarker?.remove()
      removeMarker();
    };
  }, []);

  useEffect(() => {
    topRef.current?.scrollIntoView()
    if (textState) {
      getListPoi(textState, location, typeState, pageNumberRef.current, { isReset: true })
      pageNumberRef.current = 0
    }
  }, [textState])

  useEffect(() => {
    if (listPoi) {
      let listDraw = getListDraw(listPoi)
      drawMarker(listDraw)
    }
  }, [listPoi])

  useEffect(() => {
    location && moveCamera(location)
  }, [location])

  // useEffect(() => {
  //   let search = UrlTool.createSearch({ id: detailId ? detailId : undefined }, { extendOldQuery: true })
  //   window.history.replaceState(null, null, RouterConfig.direction + search);
  // }, [detailId])

  const removeMarker = () => {
    listMarkerRef?.current?.forEach((marker) => {
      marker?.setMap(null)
    })
    listMarkerRef.current = null
  }

  const drawMarker = (data) => {
    removeMarker();
    if (data?.length > 0) {
      let listMarker = []
      data?.forEach(poi => {
        let marker = new map4d.POI({
          type: poi?.types?.length > 0 ? poi?.types[0] : "nocat",
          position: poi?.location,
          title: poi?.name
        })
        marker.setUserData({
          id: poi?.id || "",
          name: poi?.name,
          address: poi?.address,
          location: poi?.location,
        })
        marker.setMap(AppData.map)
        listMarker.push(marker)
        listMarkerRef.current = listMarker
      })
    }
  }

  const getListPoi = (textSearch, locationEnd, type, pageNumber, option = { isReset: false }) => {
    const { isReset } = option
    let body = {
      searchKey: type ? "" : (textSearch || ''),
      locations: [locationEnd],
      radius: RADIUS,
      types: type ? [type] : [],
      pageSize: 10,
      pageNumber: isReset ? 0 : pageNumber
    }
    let prevTime = new Date().getTime()
    ApiTool.post(UrlConfig.poi.aroundLocation, body, (res) => {
      if (res?.code == CodeEnum.ok) {
        let newCount = res?.result?.count
        if (count !== newCount) {
          setCount(newCount)
        }
        let data = res?.result?.data?.filter(item => item?.id) || []
        if (isReset) {
          setListPoi(data)
        }
        else {
          let time = new Date().getTime()
          let realDelta = time - prevTime
          let timeOut = 0
          if (realDelta < 1000) {
            timeOut = 1000 - realDelta
          }
          setTimeout(() => {
            setLoadingData(false)
            setListPoi((prev) => {
              let newValue = prev || []
              newValue = newValue?.concat(data || []);
              return newValue
            })
          }, timeOut)
        }
      }
    })
  }

  const onMouseEnterItem = (item, index) => (e) => {
    indexRef.current = index
    markerHoverRef.current = new map4d.Marker({
      position: item?.location,
      iconView:
        `
      <div class="markerHover">
        <svg width="32" height="39.385" viewBox="0 0 32 39.385">
          <g>
            <g transform="translate(3.08 1.816)">
              <path d="M78.491,37.135a13.279,13.279,0,0,0-5.844-4.877A12.989,12.989,0,0,0,67.485,31.2h-.026A12.989,12.989,0,0,0,62.3,32.259a13.317,13.317,0,0,0-5.831,4.877,13.6,13.6,0,0,0-2.266,7.53v.026h0v.065h0a13.479,13.479,0,0,0,2.291,7.465c.438.706,2.37,2.628,4.584,5.138a18.081,18.081,0,0,1,3.708,6.06l.359.837a36.878,36.878,0,0,1,1.364,4.19,1.025,1.025,0,0,0,1.957,0,26.406,26.406,0,0,1,1.208-4.014l.3-.772a19.428,19.428,0,0,1,3.833-6.3c2.214-2.51,4.233-4.432,4.67-5.138a13.511,13.511,0,0,0,2.291-7.465h0v-.065h0v-.026A13.711,13.711,0,0,0,78.491,37.135Z" transform="translate(-54.2 -31.2)" fill="#e40000" stroke="#b00020" stroke-width="1" />
            </g>
            <ellipse cx="4.5" cy="4.57" rx="4.5" ry="4.57" transform="translate(12 10.156)" fill="#b00020" />
          </g>
        </svg>
      </div>
      `,
      anchor: [0.5, 1]
    })
    listMarkerRef.current && listMarkerRef.current[index]?.setMap(null)
    markerHoverRef.current.setMap(AppData.map)
  }

  const onMouseLeaveItem = (e) => {
    listMarkerRef?.current[indexRef?.current]?.setMap(AppData.map)
    markerHoverRef?.current?.setMap(null)
  }

  const onChangeSearchBar = (value) => {
    let text = value?.isDefaultType ? value?.text : value?.name || value
    let type = value?.type || null
    setTextState(text)
    setTypeState(type)
    if (value) {
      AppEventTool.triggerEvent(AppEventEnum.changeSearchInput, { text: text, type: type })
      setDetailId(null)
    }
    if (!text) {
      setTextNotFound(textCurrent.current)
    }
  }

  const onCloseList = () => {
    setDetailId(null)
    // if (detailId) {
    //   router.push({
    //     pathname: window.location.pathname,
    //     search: UrlTool.createSearch({ id: undefined }, { extendOldQuery: true })
    //   })
    // }
    onClose && onClose()
  }

  const handleScroll = (event) => {
    if (
      Math.round(
        event.currentTarget.scrollTop + event.currentTarget.offsetHeight + 1
      ) >= Math.round(event.currentTarget.scrollHeight) && listPoi?.length !== count
    ) {
      setLoadingData(true)
      pageNumberRef.current = pageNumberRef.current + 1
      getListPoi(textState, location, typeState, pageNumberRef.current)
    }
    setScrollTop(event.currentTarget.scrollTop);
  }

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleShowDetail = (item) => () => {
    setDetailId(item?.id)
  }

  const moveCamera = (location) => {
    let camera = AppData.map?.getCamera()
    camera.setTarget(location)
    if (camera.getZoom() !== 15) {
      camera.setZoom(15)
    }
    AppData.map?.moveCamera(camera, { animate: true })
  }

  const handleCloseDetail = () => {
    setDetailId(null)
  }

  return (
    <div className="poiNearByLocationCpn">
      <div className='listPoiNearByLocation'>
        <div className='listPoiNearHeader'>
          <SearchBarNearByLocation
            value={textState}
            onChange={onChangeSearchBar}
            onClose={onCloseList}
            endPointName={endPointName}
          />
        </div>
        <div
          className='listPoiContent'
          onScroll={handleScroll}
        // ref={listPoiRef}
        >
          <span ref={topRef}></span>
          {
            listPoi?.length > 0 && listPoi?.map((poiItem, index) => {
              let avatar = poiItem?.photos?.length > 0 ? poiItem?.photos[0].url : ImageConfig.avaterDefaultGray
              let name = poiItem?.name || ''
              let address = poiItem?.address || ''
              let type = poiItem?.types?.length > 0 ? AllPlaceTypeState[poiItem?.types[0]]?.name : ""
              let rateNum = poiItem?.ratingReport?.avgStars
              let totalComment = poiItem?.ratingReport?.totalComments
              let businessHour = poiItem?.businessHours

              return (
                <div
                  className='poiItem'
                  key={poiItem?.id + index}
                  id={poiItem?.id + index}
                  title={name}
                  onClick={handleShowDetail(poiItem)}
                  onMouseEnter={onMouseEnterItem(poiItem, index)}
                  onMouseLeave={onMouseLeaveItem}
                >
                  <div className='poiItemInfo'>
                    <div className='name'>{name}</div>
                    <div className='type'>{type}</div>
                    {
                      poiItem?.ratingReport &&
                      <div className='rate'>
                        <div className='rateNumber'>{rateNum}</div>
                        <RatingInput size={13} avgRating={rateNum} />
                        <div className='totalComment'>{`(${totalComment})`}</div>
                      </div>
                    }
                    <div className='address'>{address}</div>
                    <div className='businessHourStatus'>
                      <BusinessHoursStatus value={businessHour} />
                    </div>
                  </div>
                  <div className='poiItemAvatar'>
                    <img src={avatar} alt='avatar' />
                  </div>
                </div>
              )
            })
          }
          <div
            className="boxContent_list_item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0 3.5rem 0",
            }}
          >
            {
              loadingData && <IsLoading />
            }
            {
              listPoi?.length > 6 && listPoi?.length === count && <div>{Resource.common.endData}</div>
            }
            {
              listPoi && listPoi?.length == 0 && <div style={{ padding: "0 1rem" }}>
                {Resource.formatString(
                  Resource.message.resultsNotFoundInTheCurrentRegion + " " + Resource.message.pleaseCheckYourSearchKeywords,
                  { text: `"${textState || textNotFound}"` }
                )}</div>
            }
          </div>
          {scrollTop > 100 && (
            <div className="moveToTop_btn" onClick={scrollToTop}>
              {SvgIconConfig.direction.verticalArrow}
              <TextView variant="body1" style={{ fontWeight: 600 }}>
                {Resource.common.backToTop}
              </TextView>
            </div>
          )}
        </div>

      </div>
      {detailId && <>
        <HideSidebarShadow />
        <div className='detailPoiCpn'>
          <PoiDetail id={detailId} onClose={handleCloseDetail} hiddenDirection />
        </div>
      </>
      }
    </div>
  )
};

ListPoiNearByLocation.propTypes = {
  searchKey: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.any,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  endPointName: PropTypes.any
};

export default ListPoiNearByLocation;
