import {
  Button,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useRecoilState } from "recoil";
import { DisableMyLocationState } from "../../../appState";
import { CommonConfig, ImageConfig, LinkIconConfig } from "../../../config";
import { AppData } from "../../../data";
import { AlertTypeEnum, CodeEnum } from "../../../enum";
import { Resource } from "../../../resource";
import { AppTool, MapTool } from "../../../tool";
import "./myLocation.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});
const useStyles = makeStyles({
  root: {
    padding: "4px",
    height: "32px",
    minWidth: "32px",
    width: "32px",
  },
});

function MyLocation() {
  const classes = useStyles();
  const [disable, setDisable] = useRecoilState(DisableMyLocationState);
  const [loading, setLoading] = useState(false);

  const markerRef = useRef();

  const handleLocate = (e) => {
    setLoading(true);
    AppTool.getMyLocation((res) => {
      setLoading(false);
      if (res?.code == CodeEnum.ok) {
        if (!markerRef.current && res?.accuracy <= CommonConfig.maxAccuracy) {
          markerRef.current = new map4d.Marker({
            position: res?.result,
            anchor: [0.5, 0.5],
            icon: new map4d.Icon(50, 50, ImageConfig.myLocation),
            zIndex: 1000,
          });
          markerRef.current.setMap(AppData.map);
        }
        MapTool.moveToLocation(res?.result);
      } else {
        AppTool.alert(Resource.myLocation.alert.error, AlertTypeEnum.warning);
        setDisable(true);
      }
    });
  };

  useEffect(() => {
    return () => {
      markerRef.current?.setMap(null);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={Resource.common.location}>
        <Button
          disabled={disable}
          color="primary"
          className={classes.root}
          variant="contained"
          onClick={handleLocate}
          style={disable ? { pointerEvents: "none" } : {}}
        >
          <img
            src={LinkIconConfig.greyLocation}
            height={20}
            width={20}
            className={loading ? "locationLoading" : ""}
          />
        </Button>
      </Tooltip>
    </ThemeProvider>
  );
}

MyLocation.propTypes = {
  //
};

export default MyLocation;
