import PropTypes from 'prop-types';
import React, { useEffect, useId, useRef, useState } from 'react';
import { LinkIconConfig } from '../../config';
import { MediaTypeEnum } from '../../enum';
import { Resource } from '../../resource';
import { DragToScroll } from "ui"
import "./photosInputV2.scss";

function PhotosInputV2({ value, onChange, className, error, helperText, accept, inputRef, ...props }) {
  const id = useId()
  const [photos, setPhotos] = useState([])
  const videosRef = useRef()
  const [photosFile, setPhotosFile] = useState([])
  const inputRefHtml = useRef(null)

  useEffect(() => {
    setPhotos((value || [])?.filter(m => m?.typeFile == MediaTypeEnum.image))
    videosRef.current = (value || [])?.filter(m => m?.typeFile != MediaTypeEnum.image)
  }, [value])

  const onChangePhotosFile = (e) => {
    let arrFiles = e.target.files
    let listNew = []
    for (let i = 0; i < arrFiles?.length; i++) {
      let file = arrFiles[i]
      let newFile = {
        url: URL.createObjectURL(file),
        file: file,
      }
      listNew.push(newFile)
    }
    setPhotosFile(prev => {
      let list = [...(prev || [])].concat(listNew)

      let imageFiles = []
      list?.forEach(element => {
        imageFiles.push(element.file)
      })
      onChange && onChange(videosRef.current.concat(photos), imageFiles)

      return list
    })
    inputRefHtml.current.value = ""
  }
  const handleRemovePhotos = (index) => (e) => {
    setPhotos(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)

      let imageFiles = []
      photosFile?.forEach(element => {
        imageFiles.push(element.file)
      })
      onChange && onChange(videosRef.current.concat(list), imageFiles)
      return list
    })

  }

  const handleRemovePhotosFile = (index) => (e) => {
    setPhotosFile(prev => {
      let list = [...(prev || [])]
      list?.splice(index, 1)

      let imageFiles = []
      list?.forEach(element => {
        imageFiles.push(element.file)
      })
      onChange && onChange(videosRef.current.concat(photos), imageFiles)

      return list
    })
  }

  return (
    <div className="photosInputV2" ref={inputRef}>
      <input
        ref={inputRefHtml}
        name="images"
        id={id}
        type="file"
        hidden
        accept={accept || "image/*"}
        onChange={onChangePhotosFile}
        multiple
      />
      {(photosFile?.length > 0) ?
        <DragToScroll>
          <div className="havePhoto">
            <label className='addPhotoBtnItem' htmlFor={id}>
              <img
                src={LinkIconConfig.photosInputV2.camera}
                className="cameraIcon"
              />
            </label>
            {/* {
              photos?.map((photo, index) => {
                return (
                  <div key={photo?.url} className={"photoItem"}>
                    <img
                      src={photo?.url}
                      alt={photo?.name}
                      width='100%'
                      height='100%'
                      style={
                        {
                          // objectFit: 'cover',
                          borderRadius: '4px',
                          backgroundColor: '#000000'
                        }
                      } />
                    <button
                      className='closeBtn'
                      onClick={handleRemovePhotos(index)}
                    >
                      <img src={LinkIconConfig.photosInputV2.remove} className={"closeIcon"} />
                    </button>
                  </div>
                )
              })
            } */}

            {
              photosFile?.map((photoFile, index) => {
                return (
                  <div
                    key={photoFile?.url}
                    className={"photoItem"}
                  >
                    <img src={photoFile?.url} alt={photoFile?.name} width='100%' height='100%'
                      style={
                        {
                          // objectFit: 'cover',
                          borderRadius: '4px',
                          backgroundColor: '#000000'
                        }
                      } />
                    <button
                      onClick={handleRemovePhotosFile(index)}
                      className={"closeBtn"}
                    >
                      <img src={LinkIconConfig.photosInputV2.remove} className={"closeIcon"} />
                    </button>
                  </div>
                )
              })
            }
          </div>
        </DragToScroll>

        :
        <label
          className={"addPhotoBtn"}
          htmlFor={id}
        >
          <img
            src={LinkIconConfig.photosInputV2.camera}
            className="cameraIcon2"
          />
          {Resource.updatePlace.addPhoto}
        </label>

      }

      {
        error &&
        <div className='helperText'>
          {helperText}
        </div>
      }
    </div>
  )
}

PhotosInputV2.propTypes = {
  defaultValue: PropTypes.any,
  inputRef: PropTypes.any
};

export default PhotosInputV2;
