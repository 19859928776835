import React, { useEffect, useState } from 'react';
import { Button, CheckBox, TimeInput } from 'ui';
import { v4 } from 'uuid';
import { TypeBusinessHourEnum } from '../../enum';
import { Resource } from '../../resource';
import { BusinessHourTool } from '../../tool';
import { useIsMobileScreen } from '../../useHooks';
import Dialog from '../dialog/dialog';
import DialogAction from '../dialogAction/dialogAction';
import DialogBody from '../dialogBody/dialogBody';
import DialogHeader from '../dialogHeader/dialogHeader';
import TextView from '../textView/textView';
import './updateBusinessHourV3.scss';

const createDefaultValue = () => {
  let id = v4()
  return [
    {
      id: id,
      open: {
        value: null
      },
      close: {
        value: null
      }
    }
  ]
}

const dayLabelDict = {
  "1": Resource.updatePlace.mon,
  "2": Resource.updatePlace.tue,
  "3": Resource.updatePlace.wed,
  "4": Resource.updatePlace.thu,
  "5": Resource.updatePlace.fri,
  "6": Resource.updatePlace.sat,
  "0": Resource.updatePlace.sun
}

function UpdateBusinessHourV3(props) {
  const { onClose, onChange, value, selectDays } = props
  const fullScreen = useIsMobileScreen()
  const [disabled, setDisabled] = useState(true)
  const [type, setType] = useState(TypeBusinessHourEnum.opened)
  const [stateValue, setStateValue] = useState(createDefaultValue)
  const [days, setDays] = useState([])

  useEffect(() => {
    let disableNew = false
    if (days?.length < 1) {
      disableNew = true
    }
    else {
      if (type == TypeBusinessHourEnum.opened) {
        if (!stateValue.some(time => (time?.open.value && time?.close?.value))) {
          disableNew = true
        }
      }
    }
    setDisabled(disableNew)

  }, [stateValue, type, days])

  useEffect(() => {
    setDays(selectDays)
    if (selectDays?.length == 1) {
      let dict = BusinessHourTool.generateToText2(value)
      let day = BusinessHourTool.convertDay(selectDays[0])
      let current = dict[day]

      //type
      let newType = current.type
      if (newType == TypeBusinessHourEnum.none) {
        newType = TypeBusinessHourEnum.opened
      }
      setType(newType)

      if (newType == TypeBusinessHourEnum.opened) {
        let newValue = []
        current.texts?.forEach(text => {
          let id = v4()
          let timeValues = text.split(" - ")
          newValue.push({
            id,
            open: {
              value: timeValues[0]
            },
            close: {
              value: timeValues[1]
            }
          })
        });
        if (newValue?.length > 0) {
          setStateValue(newValue)
        }
        else {
          setStateValue(createDefaultValue())
        }

      }
      else {
        setStateValue(createDefaultValue())
      }
    }
    else {
      setType(TypeBusinessHourEnum.opened)
      setStateValue(createDefaultValue())
    }
  }, [selectDays, value])
  const handleSave = () => {
    let newValue = [...value] || []
    newValue = newValue.filter(time => !days?.includes(time?.open?.day))
    days.forEach(day => {
      let arr = []
      stateValue.forEach(t => {
        let times = [{
          open: {
            day: day,
            time: null
          },
          close: {
            day: day,
            time: null
          }
        }]
        if (type == TypeBusinessHourEnum.opened) {
          if (t.close.value <= t.open.value && t.close.value >= "12:00") {
            times[0].open.time = t.open.value.replaceAll(":", "")
            times[0].close.time = "0000"
            times[0].close.day = BusinessHourTool.nextDay(day)

            times.push({
              open: {
                time: "0000",
                day: BusinessHourTool.nextDay(day)
              },
              close: {
                time: t.close.value?.replaceAll(":", ""),
                day: BusinessHourTool.nextDay(day)
              }
            })
          }
          else {
            times[0].open.time = t.open.value?.replaceAll(":", "")
            times[0].close.time = t.close.value?.replaceAll(":", "")
            if (t.close.value <= t.open.value) {
              times[0].close.day = BusinessHourTool.nextDay(day)
            }
          }
        }
        else if (type == TypeBusinessHourEnum.openAllDay) {
          times[0].open.time = "0000"
          times[0].close.time = "0000"
          times[0].close.day = BusinessHourTool.nextDay(day)
        }
        arr = arr.concat(times)
      })
      if (type == TypeBusinessHourEnum.closed || type == TypeBusinessHourEnum.openAllDay) {
        newValue = newValue.concat([arr[0]])
      }
      else {
        newValue = newValue.concat(arr?.filter(t => (t?.open?.time && t?.close?.time)))
      }
    })
    onChange && onChange(BusinessHourTool.compactBusinessHours(newValue))
    onClose && onClose()
  }

  const handleChangeOpenTimeCheckbox = (event) => {
    if (event.target.checked) {
      setType(TypeBusinessHourEnum.openAllDay)
    }
    else {
      setType(TypeBusinessHourEnum.opened)
    }
  }
  const handleChangeCloseTimeCheckbox = (event) => {
    if (event.target.checked) {
      setType(TypeBusinessHourEnum.closed)
    }
    else {
      setType(TypeBusinessHourEnum.opened)
    }
  }

  const handleOnClickDayOfWeek = (day) => (e) => {
    setDays(prev => {
      if (prev.includes(day)) {
        return prev.filter(d => d != day)
      }
      else {
        return prev.concat([day])
      }
    })
  }


  const handleOpenTimeChange = (index) => (value) => {
    let val = formatTime(value)
    setStateValue(prev => {
      let valueNew = [...prev || []]
      let time = valueNew[index]
      time.open.value = val
      return valueNew
    })
  }

  const handleCloseTimeChange = (index) => (value) => {
    let val = formatTime(value)
    setStateValue(prev => {
      let valueNew = [...prev || []]
      let time = valueNew[index]
      time.close.value = val
      return valueNew
    })
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }


  const handleAddBusinessHour = () => {
    setStateValue(prev => {
      let newValue = [...prev || []]
      newValue = newValue.concat(createDefaultValue())
      return newValue
    })
  }
  const handleDeletedTimeChange = (index) => (e) => {
    if (stateValue.length <= 1) {
      setType(TypeBusinessHourEnum.closed)
    }
    setStateValue(prev => {
      let newValue = [...prev || []]
      if (newValue?.length <= 1) {
        return createDefaultValue()
      }
      else {
        newValue.splice(index, 1)
        return newValue
      }
    })
  }
  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={
        {
          paper: (!fullScreen) ? 'dialog' : ""
        }
      }
      disableCloseOnClickBackdrop
    >
      <DialogHeader
        hideClose
      >
        <div style={{ margin: '0.75rem 0 1.75rem' }}>Chọn ngày và giờ</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            [1, 2, 3, 4, 5, 6, 0].map(day => {
              let className = days.includes(day) ? "dayOfWeekSelected" : "dayOfWeek"
              return (
                <div key={day} className={className} onClick={handleOnClickDayOfWeek(day)} >
                  <TextView variant='body1'>{dayLabelDict[day]}</TextView>
                </div>
              )
            })
          }
        </div>
        <div className='checkBox'>

          <CheckBox
            checked={type == TypeBusinessHourEnum.openAllDay}
            onChange={handleChangeOpenTimeCheckbox}
            name='openAllDay'
            color="primary"
            label={Resource.common.openAllTime}
          />
          <CheckBox
            checked={type == TypeBusinessHourEnum.closed}
            onChange={handleChangeCloseTimeCheckbox}
            name='closed'
            color="primary"
            label={Resource.common.closeTime}
          />
        </div>
      </DialogHeader>
      <DialogBody style={{ display: 'flex', flexDirection: 'column' }}>
        {
          type == TypeBusinessHourEnum.opened &&
          <div className='groupTimePicker' style={{ maxHeight: '21.25rem', flexGrow: 1, overflow: 'auto', scrollBehavior: 'smooth' }}>
            <div id='groupTime' style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
              {
                stateValue?.map((time, index) =>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 1.5rem' }} key={time.id}>
                    <TimeInput
                      className={'timePicker'}
                      label={Resource.updatePlace.timeOpen}
                      value={time?.open?.value || ""}
                      onChange={handleOpenTimeChange(index)}
                    />
                    <TimeInput
                      className={'timePicker'}
                      label={Resource.updatePlace.timeClose}
                      value={time?.close?.value || ""}
                      onChange={handleCloseTimeChange(index)}
                    />
                    <div onClick={handleDeletedTimeChange(index)} style={{ display: 'flex', alignItems: 'center' }}>
                      <svg id="Component_773_6" data-name="Component 773 – 6" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_8048" data-name="Path 8048" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_8049" data-name="Path 8049" d="M11.5,20.99a9.5,9.5,0,1,1,9.5-9.5A9.495,9.495,0,0,1,11.5,20.99Zm0-1.9a7.6,7.6,0,1,0-7.6-7.6A7.6,7.6,0,0,0,11.5,19.091Zm0-8.939L14.18,7.466,15.524,8.81,12.838,11.5l2.686,2.685L14.18,15.524,11.5,12.838,8.81,15.524,7.466,14.18,10.153,11.5,7.466,8.81,8.81,7.466Z" transform="translate(0.504 0.504)" fill="#869195" />
                      </svg>

                    </div>
                  </div>
                )
              }
              {type == TypeBusinessHourEnum.opened &&
                <Button variant='save' style={{ margin: '0 1.75rem 3rem', fontSize: '0.875rem', color: '#1C75BC', fontWeight: 500 }} onClick={handleAddBusinessHour}>Thêm giờ hoạt động</Button>
              }
            </div>
          </div>
        }

      </DialogBody>
      <DialogAction>
        <Button onClick={onClose} variant={'close'} style={{ marginRight: '0.5rem' }}>
          {Resource.common.close.toUpperCase()}
        </Button>
        <Button variant='save' onClick={handleSave} disabled={disabled}>
          {Resource.common.save.toUpperCase()}
        </Button>
      </DialogAction>
    </Dialog>
  )
}

UpdateBusinessHourV3.propTypes = {
  //
};

export default UpdateBusinessHourV3;
