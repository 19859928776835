import React, { useEffect, useState } from "react";
import { StringTool } from '../../tool';
import styles from "./card.css";

export interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: 'error' | 'success' | 'warning' | undefined,
  leftCaption?: any,
  rightCaption?: any,
  title?: string,
  subTitle?: string,
  icon?: any
};

const Card: React.FC<CardProps> = ({
  type,
  leftCaption,
  rightCaption,
  title,
  subTitle,
  icon,
  ...props
}) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.cardCpn, props.className as string)
  })

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.cardCpn, props.className as string))
  }, [props.className])

  return (
    <div {...props} className={className}>
      <div className={StringTool.mergeClassName(styles.type, type ? styles[type] : '')}>

      </div>

      <div className={StringTool.mergeClassName(styles.content)}>
        {
          (leftCaption || rightCaption) &&
          <div className={styles.caption}>
            {
              leftCaption &&
              <div className={styles.leftCaption}>
                {leftCaption}
              </div>
            }
            {
              rightCaption &&
              <div className={styles.rightCaption}>
                {rightCaption}
              </div>
            }
          </div>
        }

        {
          title &&
          <div className={styles.title}>
            {title}
          </div>
        }

        {
          (subTitle || icon) &&
          <div className={styles.subTitle}>
            {
              icon &&
              <div className={styles.icon}>
                {icon}
              </div>
            }

            {
              subTitle &&
              <div className={styles.text}>
                {subTitle}
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
};

export default Card;
