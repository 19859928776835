import React, { useEffect, useState } from "react";
import { StringTool } from "../../tool";
import styles from "./textInput.css";

export interface TextInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
  value?: any,
  onChange?: (event: any, value: any) => void,
  error?: boolean,
  inputRef?: any,
  disabled?: boolean,
  label?: string,
  maxLength?: number,
  prefix?: any,
  suffix?: any,
  required?: boolean,
  helperText?: string,
  hideClear?: boolean,
  readOnly?: boolean,
  onBlur?: (event: any) => void,
  onClick?: (event: any) => void,
  showCount?: boolean,
  noneBorder?: boolean
};

const SIZE_ICON = 20;

const TextInput: React.FC<TextInputProps> = ({
  showCount,
  onClick,
  hideClear,
  value,
  onChange,
  required,
  error,
  helperText,
  inputRef,
  disabled,
  label,
  maxLength,
  prefix,
  suffix,
  readOnly,
  onBlur,
  onFocus,
  noneBorder,
  ...props
}) => {

  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.textInputCpn, props.className as string)
  })

  const [stateValue, setStateValue] = useState('')
  const [isFocus, setIsFocus] = useState(false)
  const [lengthText, setLengthText] = useState(0)

  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.textInputCpn, props.className as string))
  }, [props.className])

  useEffect(() => {
    setStateValue(value)
    setLengthText(value?.length || 0)
  }, [value])

  const onChangeTextInput = (e: any) => {
    let newValue = e?.target.value
    setStateValue(newValue)
    setLengthText(newValue?.length)
    onChange && onChange(e, newValue)
  }

  const onFocusInput = (e: any) => {
    setIsFocus(true)
    onFocus && onFocus(e)
  }

  const onBlurInput = (e: any) => {
    setIsFocus(false)
    onBlur && onBlur(e)
  }

  const onClearText = (e: any) => {
    setStateValue('')
    onChange && onChange(e, "")
    setLengthText(0)
  }

  const handleClick = (e: any) => {
    onClick && onClick(e)
  }

  return (
    <div className={StringTool.mergeClassName(className, disabled ? styles.textInputDisable : '')}>
      <div className={styles.outlineTextInput}>
        {
          prefix &&
          <div className={styles.prefix}>
            {prefix}
            <div className={styles.verticalBar}></div>
          </div>
        }

        <input
          {...props}
          readOnly={readOnly}
          maxLength={maxLength}
          onBlur={onBlurInput}
          onFocus={onFocusInput}
          ref={inputRef}
          className={StringTool.mergeClassName(styles.textInput, error ? styles.textInputError : '')}
          value={stateValue || stateValue == '0' ? stateValue : ""}
          onChange={onChangeTextInput}
          onClick={handleClick}
        />
        {
          label &&
          <label className={styles.labelTextInput}>
            {label}
            {required && <span>*</span>}
          </label>
        }

        <fieldset className={StringTool.mergeClassName(styles.fieldset, error ? styles.textInputError : '')} style={{ border: `${noneBorder ? 'none' : ''}` }}>
          {label && (
            <legend className={styles.legend}>
              <span>
                {label}
                {required && <span>*</span>}
              </span>
            </legend>
          )}
        </fieldset>

        {
          !isFocus && error && (stateValue || !stateValue) && !disabled &&
          <div className={styles.iconWarning}>
            <svg
              id="Component_82_3"
              data-name="Component 82 – 3"
              xmlns="http://www.w3.org/2000/svg"
              width={SIZE_ICON}
              height={SIZE_ICON}
              viewBox="0 0 24 24"
            >
              <path
                id="Path_8139"
                data-name="Path 8139"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Path_8140"
                data-name="Path 8140"
                d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm-1-5h2v2H11Zm0-8h2v6H11Z"
                fill="#d93535"
              />
            </svg>
          </div>
        }

        {
          isFocus && (stateValue || stateValue == '0') && !disabled && !hideClear && !readOnly &&
          <div className={styles.iconClear} onPointerDown={onClearText} title="Xóa">
            <svg
              id="Component_3_2"
              data-name="Component 3 – 2"
              xmlns="http://www.w3.org/2000/svg"
              width={SIZE_ICON}
              height={SIZE_ICON}
              viewBox="0 0 24 24"
            >
              <path
                id="Path_6362"
                data-name="Path 6362"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <path
                id="Path_6363"
                data-name="Path 6363"
                d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
                fill="#869195"
              />
            </svg>
          </div>
        }

        {
          suffix &&
          <div className={styles.suffix}>
            <div className={styles.verticalBar}></div>
            {suffix}
          </div>
        }
      </div>

      {
        ((error && !disabled) || (maxLength && showCount)) &&
        <div className={styles.subMessage}>
          {
            error && !disabled &&
            <div className={styles.helperText}>
              {helperText}
            </div>
          }
          
          {
            !error && !disabled && showCount &&
            <div className={styles.numberCharText}>
              Số ký tự tối đa
            </div>
          }

          {
            maxLength && showCount &&
            <div className={styles.maxLength}>
              <span
                className={
                  lengthText == 0 || lengthText == maxLength ? "" : styles.charLength
                }
              >
                {`${lengthText}`}
              </span>
              {`/${maxLength}`}
            </div>
          }
        </div>
      }
    </div>
  );

}

export default TextInput;
