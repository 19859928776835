import React, { useEffect, useState } from 'react';
import PersonalAddress from '../.././components/common/personalAddress';
import MobileDragBody from '../../components/mobileDragBody/mobileDragBody';
import { CommonConfig } from '../../config';
import { MobileDraggableBarTool, UserTool } from '../../tool';
import MobileDraggableButton from '../common/mobileDraggableButton/mobileDraggableButton';
import MobileMapTypeSelect from '../common/mobileMapTypeSelect/mobileMapTypeSelect';
import MobileSuggestPlace from '../common/mobileSuggestPlace/mobileSuggestPlace';
import { useWindowSize } from './../../useHooks/useWindowSize';
import './mobileHome.scss';

const ICON_SHOW_MORE =
  <svg width='24px' height='24px' viewBox="0 0 24 24" aria-hidden="true">
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
  </svg>

const START_HEIGHT_TO_DRAG = 48
const MIDDLE_HEIGHT_TO_DRAG = 280

function MobileHome() {

  const windowSize = useWindowSize()

  const [heightView, setHeightView] = useState(START_HEIGHT_TO_DRAG)
  const [startState, setStartState] = useState(true)
  const [isMiddleState, setIsMiddleState] = useState(false)

  useEffect(() => {
    MobileDraggableBarTool.setHeight(0)
  }, [])

  const handleShowMore = (e) => {
    setHeightView(MIDDLE_HEIGHT_TO_DRAG)
    setStartState(false)
    setIsMiddleState(true)
  }

  const onChangeMapType = () => {
  }

  const onChangeHeightDrag = (height) => {
    setHeightView(height)
  }

  const onDragStart = (height) => {
    if (height == MIDDLE_HEIGHT_TO_DRAG || height == windowSize?.height) {
      setStartState(false)
      setIsMiddleState(true)
    }
  }
  const onDragEnd = (height) => {
    if (height == 48) {
      setStartState(true)
      setIsMiddleState(false)
    }
    else {
      setStartState(false)
      if (height == windowSize?.height) {
        setIsMiddleState(false)
      }
      else {
        setIsMiddleState(true)
      }
    }
  }
  const user = UserTool.getUser()

  let isFullScreen = heightView == windowSize?.height
  // let isMiddle = heightView == MIDDLE_HEIGHT_TO_DRAG
  // let isStart = (heightView < MIDDLE_HEIGHT_TO_DRAG) && startState

  return (
    <MobileDragBody
      minHeighView={START_HEIGHT_TO_DRAG}
      heightView={heightView}
      onChange={onChangeHeightDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <div className='mobileHomeCpn'>
        {
          startState &&
          <div className='showMoreBtn' onClick={handleShowMore}>
            <div className='iconMore'>
              {ICON_SHOW_MORE}
            </div>
            <div className='label'>
              Hãy nhấn để xem các thao tác nhanh
            </div>
          </div>
        }

        {
          isMiddleState &&
          <div className='draggableBtn'>
            <MobileDraggableButton />
          </div>
        }

        {
          user &&
          <div className='personalAddress'>
            <div className='title'>
              Mục yêu thích
            </div>
            <div className='content'>
              <PersonalAddress disableDraggable />
            </div>
          </div>
        }

        <div className='suggestPlace'>
          <div className='title'>
            Gợi ý địa điểm
          </div>
          <div className='content'>
            <MobileSuggestPlace />
          </div>
        </div>

        <div className='mapType'>
          <div className='title'>
            Loại bản đồ
          </div>
          <div className='content'>
            <MobileMapTypeSelect onChange={onChangeMapType} />
          </div>
        </div>

      </div>
    </MobileDragBody>

  )
}

MobileHome.propTypes = {
  //
};

export default MobileHome;
