import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { DataExtendState, MobileShowSearchBarState, PlaceRelatedState } from '../../appState';
import DisplayLink from '../../components/common/displayLink/displayLink';
import DisplayMedia from '../../components/common/displayMedia/displayMedia';
import RequestUpdateBtn from '../../components/common/requestUpdateBtn/requestUpdateBtn';
import ListItemLink from '../../components/listItemLink/listItemLink';
import ListItemVr360 from '../../components/listItemVr360/listItemVr360';
import ListPlaceRelated from '../../components/listPlaceRelated/listPlaceRelated';
import MobileDragBody from '../../components/mobileDragBody/mobileDragBody';
import PlaceRelatedV2 from '../../components/placeRelatedV2/placeRelatedV2';
import { CommonConfig, LinkIconConfig } from '../../config';
import { RouterConfig } from '../../config/routerConfig';
import { UrlConfig } from '../../config/urlConfig';
import { AppData } from '../../data';
import { CodeEnum, GeometryTypeEnum, PlaceExtensionTypeEnum, TypeRequestUpdateEnum } from '../../enum';
import { Resource } from '../../resource';
import { ApiTool, AppTool, GeoJsonTool, PlaceTool, StringTool, UrlTool, UserTool } from '../../tool';
import { useWindowSize } from '../../useHooks';
import MobilePhoneButton from '../common/mobilePhoneButton/mobilePhoneButton';
import MobileSaveButton from '../common/mobileSaveButton/mobileSaveButton';
import MobileShareButton from '../common/mobileShareButton/mobileShareButton';
import DescriptionDisplay from './../../components/common/descriptionDisplay/descriptionDisplay';
import DisplayBusinessHour from './../../components/common/displayBusinessHour/displayBusinessHour';
import DisplayVr360 from './../../components/common/displayVr360/displayVr360';
import Skeleton from './../../components/skeleton/skeleton';
import MobileDirectionButtonV2 from './../common/mobileDirectionButtonV2/mobileDirectionButtonV2';
import MobileSwipeableViewsImages from './../common/mobileSwipeableViewsImages/mobileSwipeableViewsImages';
import "./mobilePoiDetail.scss";

const maxHeightImg = 240
const minHeightImg = 140
const START_HEIGHT_TO_DRAG = 288

const ICON_BACK_SEARCH =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#000000'>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
  </svg>

const ICON_BACK =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#505050'>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
  </svg>

const ICON_SEARCH =
  <svg width='24px' height='24px' viewBox="0 0 24 24" fill='#505050'>
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
  </svg>

const worldCoords = [[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]

function MobilePoiDetail(props) {

  const { id, text, type, lat, lng, data, disableTextSearch } = props
  const location = useLocation()
  const history = useHistory()
  const windowSize = useWindowSize()

  const [place, setPlace] = useState(null)
  const [loading, setLoading] = useState(false)
  const dataExtendState = useRecoilValue(DataExtendState)
  const placeRelatedState = useRecoilValue(PlaceRelatedState)

  const polygonRef = useRef(null)
  const polylineRef = useRef(null)
  const polygonsRef = useRef([])
  const polylinesRef = useRef([])
  const polygonHoleRef = useRef(null)
  const _avatarPlaceHtml = useRef(null)

  const currentPlace = useRef(null)
  const [keySaved, setKeySaved] = useState(() => { return v4() })

  const [links, setLinks] = useState([])
  const [vr360, setVr360] = useState([])

  const [showHeader, setShowHeader] = useState(false)
  const [heightView, setHeightView] = useState(START_HEIGHT_TO_DRAG)
  const setMobileShowSearchBar = useSetRecoilState(MobileShowSearchBarState)

  const [isFull, setIsFull] = useState(false)

  const dragImageHeight = (heightView) => {
    let imageHeightNew = heightView * 0.3
    if (imageHeightNew < minHeightImg) {
      imageHeightNew = minHeightImg
    }
    else if (imageHeightNew > maxHeightImg) {
      imageHeightNew = maxHeightImg
    }
    _avatarPlaceHtml.current.style.transition = "none"
    _avatarPlaceHtml.current.style.height = `${imageHeightNew}px`
  }
  const animationImageHeight = (heightView) => {
    let imageHeightNew = heightView * 0.3
    if (imageHeightNew < minHeightImg) {
      imageHeightNew = minHeightImg
    }
    else if (imageHeightNew > maxHeightImg) {
      imageHeightNew = maxHeightImg
    }
    _avatarPlaceHtml.current.style.transition = "height .3s ease-in-out"
    _avatarPlaceHtml.current.style.height = `${imageHeightNew}px`
  }

  useEffect(() => {
    if (!disableTextSearch) {
      AppTool.setSearchBarText(place?.name || "")
    }
  }, [place?.name])

  useEffect(() => {
    var sourcePlace
    if (id) {
      setLoading(true)
      sourcePlace = ApiTool.get(UrlConfig.poi.detail.replace("{id}", id), (res) => {
        setLoading(false)
        if (res?.code == CodeEnum.ok) {
          let typeCheck = PlaceTool.isAreaOrStreet(res?.result)
          setMarkerAndShowInfo(res?.result)
          drawPoly(res?.result)
          if (!typeCheck && !location.state?.disableMoveMap) {
            moveCamera(res?.result)
          }
          setPlace(res?.result)
          let classifyMetaData = PlaceTool.classifyMetaData(res?.result?.metadata)
          let placeExtend = PlaceTool.convertClassifyMetaDataToPlaceExTend(classifyMetaData)
          let links = placeExtend?.link.sort((a, b) => a.order - b.order)
          let vr360 = placeExtend?.vr360.sort((a, b) => a.order - b.order)
          setLinks(links)
          setVr360(vr360)
        }
        else {
          setPlace(null)
        }
      })
    }
    return () => {
      sourcePlace?.cancel()
      currentPlace.current?.setMap(null)
      polylineRef.current?.setMap(null)
      polygonRef.current?.setMap(null)
      polygonsRef.current?.forEach(polygon => {
        polygon?.setMap(null)
      })
      polylinesRef.current?.forEach(polyline => {
        polyline?.setMap(null)
      })
      polygonHoleRef.current?.setMap(null)
    };
  }, [id])

  useEffect(() => {
    var sourceLocation
    if (StringTool.isNumeric(lat) && StringTool.isNumeric(lng)) {
      let numLat = parseFloat(lat)
      let numLng = parseFloat(lng)
      if (-90 <= numLat && numLat <= 90 && -180 <= numLng && numLng <= 180) {
        let placeTemp = {
          location: {
            lat,
            lng
          },
          name: `${numLat.toFixed(6)}, ${numLng.toFixed(6)}`
        }
        let body = {
          lat: lat,
          lng: lng
        }
        setMarkerAndShowInfo(placeTemp)
        moveCamera(placeTemp)
        setPlace(placeTemp)
        setLoading(true)
        sourceLocation = ApiTool.queryGetFromJson(UrlConfig.geocode.geocode, body, (res) => {
          setLoading(false)
          if (res?.code == CodeEnum.ok) {
            let newPlace = {
              ...placeTemp,
              address: res?.result.address
            }
            setPlace(newPlace)
          }
        })

      }
    }
    return () => {
      sourceLocation?.cancel()
    }
  }, [lat, lng])

  useEffect(() => {
    let placeCustom = null
    try {
      placeCustom = JSON.parse(decodeURIComponent(data))
    } catch (error) {

    }
    if (placeCustom) {
      setMarkerAndShowInfo(placeCustom)
      moveCamera(placeCustom)
      setPlace(placeCustom)
    }
  }, [data])

  const setMarkerAndShowInfo = (place) => {
    if (place) {
      if (place?.location) {
        currentPlace.current = new map4d.POI({
          position: place?.location,
          icon: LinkIconConfig.placeDetail.marker,
          title: place?.name,
          zIndex: 99999
        })
        currentPlace?.current.setMap(AppData.map)
      }
    }
  }

  const moveCamera = (place) => {
    if (place && place?.location) {
      let camera = new map4d.CameraPosition()
      camera.setTarget(place?.location)
      if (camera.getZoom() < CommonConfig.detailPlace2DZoom) {
        camera.setZoom(CommonConfig.detailPlace2DZoom)
      }
      AppData.map.moveCamera(camera, { animate: true })
    }
  }

  const drawPolyline = (data) => {
    polylineRef.current = new map4d.Polyline({
      path: data || [],
      strokeColor: "#3335DF",
      strokeOpacity: 1.0,
      strokeWidth: 5,
      userInteractionEnabled: false,
    })
    polylineRef.current.setMap(AppData.map)
  }
  const drawPolygon = (data) => {
    polygonHoleRef.current = new map4d.Polygon({
      paths: [worldCoords, data[0]],
      fillColor: "#000000",
      fillOpacity: 0.2,
      strokeWidth: 1,
      userInteractionEnabled: false,
    })
    polygonHoleRef.current.setMap(AppData.map)
  }

  const drawMultiPolyline = (data) => {
    if (data && data.length > 0) {
      polylinesRef.current = []
      for (let i = 0; i < data.length; i++) {
        let polyline = new map4d.Polyline({
          path: data[i],
          strokeColor: "#3335DF",
          strokeOpacity: 1.0,
          strokeWidth: 5,
          userInteractionEnabled: false,
        })
        polyline.setMap(AppData.map)
        polylinesRef.current.push(polyline)
      }
    }
  }

  const drawMultiPolygon = (data) => {
    if (data && data.length > 0) {
      let holePaths = []
      for (let i = 0; i < data.length; i++) {
        holePaths.push(data[i][0])
      }
      polygonHoleRef.current = new map4d.Polygon({
        paths: [worldCoords, ...holePaths],
        fillColor: "#000000",
        fillOpacity: 0.2,
        strokeWidth: 1,
        userInteractionEnabled: false,
      })
      polygonHoleRef.current.setMap(AppData.map)
    }
  }

  const drawPoly = (place) => {
    if (place) {
      if (place?.geometry?.type) {
        let geometry = place?.geometry
        switch (geometry.type) {
          case GeometryTypeEnum.lineString:
            drawPolyline(geometry.coordinates)
            break
          case GeometryTypeEnum.polygon:
            drawPolygon(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiLineString:
            drawMultiPolyline(geometry.coordinates || [])
            break
          case GeometryTypeEnum.multiPolygon:
            drawMultiPolygon(geometry.coordinates || [])
            break
        }
        let bound = new map4d.LatLngBounds()
        let points = GeoJsonTool.getPointForBound(geometry.coordinates, geometry.type)
        if (points?.length > 0) {
          for (let i = 0; i < points.length; i++) {
            bound.extend(points[i])
          }
          let paddingOptions = {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10
          }
          let camera = AppData.map.getCameraWithBounds(bound, paddingOptions)
          if (camera.getZoom() > 19) {
            camera.setZoom(19)
          }
          AppTool.moveCamera(camera, { animate: true })
        }
      }
    }
  }

  const handleSearch = () => {
    AppTool.setShowAutoComplete(true)
  }

  const handleClickBack = () => {
    history.push({
      pathname: RouterConfig.place.search,
      search: UrlTool.createSearch({ text: text, type: type })
    })
    setMobileShowSearchBar(true)
  }

  const onChangeCountSaveGroup = () => {
    setKeySaved(v4())
  }

  const onChangeHeightDrag = (height) => {
    setHeightView(height)
  }

  const onScrollDetail = (e) => {
    let scrollTop = e?.currentTarget?.scrollTop
    if (scrollTop >= 200) {
      setShowHeader(true)
    }
    else {
      setShowHeader(false)
    }
  }

  const onDragViewEnd = (heightView) => {
    setIsFull(heightView != START_HEIGHT_TO_DRAG)
    animationImageHeight(heightView)
  }

  const user = UserTool.getUser()

  let urlWebSite = place?.website || ""
  if (!/^(http|https):/.test(urlWebSite)) {
    urlWebSite = 'http://' + urlWebSite
  }

  let isStreet = PlaceTool.isStreet(place)
  let isBoundary = PlaceTool.isArea(place)
  let typeRequestUpdate = isStreet ? TypeRequestUpdateEnum.street : (isBoundary ? TypeRequestUpdateEnum.boundary : TypeRequestUpdateEnum.poi)
  let disableBorder = heightView == windowSize?.height

  return (
    <MobileDragBody
      onDrag={dragImageHeight}
      minHeighView={START_HEIGHT_TO_DRAG}
      heightView={heightView}
      onChange={onChangeHeightDrag}
      onDragEnd={onDragViewEnd}
    >
      <div className='mobilePoiDetailCpn'>
        <div
          onScroll={onScrollDetail}
          className={StringTool.mergeClassName('boxContainer', isFull ? "boxAuto" : "", disableBorder ? 'disableBorder' : '')}
        >
          {
            isFull &&
            <div className={StringTool.mergeClassName('headerScroll', !showHeader ? 'headerTransparent' : '')}>
              <div className='iconBack' onClick={handleClickBack}>
                {ICON_BACK}
              </div>
              <div className='title'>
                {place?.name}
              </div>
              <div className='iconSearch' onClick={handleSearch}>
                {ICON_SEARCH}
              </div>
            </div>
          }
          {
            loading ?
              <div className='boxLoading'>
                <div className='avatarPlace' ref={_avatarPlaceHtml} style={{ height: `${minHeightImg}px` }}>
                  <Skeleton width='100%' height='100%' />
                </div>
                <div className='mainInfo'>
                  <Skeleton width='30%' height='10px' />
                  <Skeleton width='50%' height='10px' />
                  <Skeleton width='70%' height='10px' />
                </div>
                <div className='lineBorder'></div>
                <div className='placeExtend'>
                  {
                    isFull ?
                      <div className='placeExtendCircle'>
                        {
                          Array.from({ length: 3 }, (_, i) => i).map((item) => {
                            return (
                              <div className='containerBtnCircle' key={item}>
                                <Skeleton width='3rem' height='3rem' borderRadius='50%' />
                                <Skeleton width='2.5rem' height='0.5rem' />
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      <div className='placeExtendEllipse'>
                        {
                          Array.from({ length: 3 }, (_, i) => i).map((item) => {
                            return (
                              <Skeleton key={item} width='90px' height='36px' borderRadius='20px' />
                            )
                          })
                        }
                      </div>
                  }
                </div>
              </div>
              :
              <>
                <div ref={_avatarPlaceHtml} className='avatarPlace' style={{ height: `${minHeightImg}px` }}>
                  <MobileSwipeableViewsImages disableSwitch place={place} paddingImages={0} sizeButton={20} />
                </div>
                <div className='contentDetail'>
                  <div className='mainInfo'>
                    <div className='textName' title={place?.name}>{place?.name}</div>
                    {
                      place?.placeTypes?.length > 0 &&
                      <div className='textDescription'>{place?.placeTypes[0].name || ''}</div>
                    }
                  </div>

                  <div className='lineBorder'></div>

                  <div className={StringTool.mergeClassName('placeExtend', !isFull ? 'ellipseDistance' : '')}>
                    <MobileDirectionButtonV2 isEllipse={!isFull} latlng={place?.location} />
                    {
                      place?.phoneNumber &&
                      <MobilePhoneButton isEllipse={!isFull} phoneNumber={place?.phoneNumber} />
                    }
                    {
                      place?.id &&
                      <MobileSaveButton isEllipse={!isFull} id={place?.id} onChange={onChangeCountSaveGroup} />
                    }
                    <MobileShareButton url={location.href} isEllipse={!isFull} />
                  </div>

                  <div className='lineBorder'></div>

                  <div className='detailInfo'>
                    {
                      place?.address &&
                      <div className='listItemInfo'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.location} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          {place?.address}
                        </div>
                      </div>
                    }

                    {
                      place?.businessHours?.length > 0 &&
                      <div className='listItemInfo positionClock'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.clock} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          <DisplayBusinessHour businessHours={place?.businessHours} />
                        </div>
                      </div>
                    }

                    {
                      place?.phoneNumber &&
                      <div className='listItemInfo'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.phone} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          {place?.phoneNumber}
                        </div>
                      </div>
                    }

                    {
                      place?.website &&
                      <div className='listItemInfo'>
                        <div className='listItemIcon'>
                          <img src={LinkIconConfig.placeDetail.global} alt='icon location' width={24} height={24} />
                        </div>
                        <div className='listItemText'>
                          <a href={urlWebSite} target='_blank'>{place?.website}</a>
                        </div>
                      </div>
                    }

                    {
                      user && place?.id &&
                      <div className='boxRequestUpdate'>
                        <RequestUpdateBtn id={place?.id} type={typeRequestUpdate} placeName={place?.name} isButton typeGeo={place?.geometry?.type} />
                      </div>
                    }
                  </div>
                  {
                    place?.description &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxDescription'>
                        <div className='title'>
                          {Resource.common.introduce.toUpperCase()}
                        </div>
                        <DescriptionDisplay value={place?.description} />
                      </div>
                    </>
                  }
                  {
                    place?.mediaFiles?.length > 0 &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxInfoMediaFiles'>
                        <div className='title'>
                          {Resource.placeDetail.photoVideo.toUpperCase()}
                        </div>
                        <div className='boxContent'>
                          <DisplayMedia isMobile place={place} />
                        </div>
                      </div>
                    </>
                  }

                  {
                    links?.length > 0 &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxInfoMetaData'>
                        <DisplayLink isMobile data={links} />
                      </div>
                    </>
                  }

                  {
                    vr360?.length > 0 &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxInfoMetaData'>
                        <DisplayVr360 isMobile data={vr360} />
                      </div>
                    </>
                  }

                  {
                    place?.object &&
                    <>
                      <div className='lineBorder'></div>
                      <div className='boxItemInfo boxDetailPlaceRelated'>
                        <PlaceRelatedV2 data={place?.otherPlaceInObjects} object={place?.object} />
                      </div>
                    </>

                  }
                </div>
              </>
          }
        </div>
        {
          dataExtendState.typeData == PlaceExtensionTypeEnum.link &&
          <ListItemLink />
        }
        {
          dataExtendState.typeData == PlaceExtensionTypeEnum.vr360 &&
          <ListItemVr360 />
        }
        {
          placeRelatedState.show &&
          <ListPlaceRelated />
        }
      </div >
    </MobileDragBody>
  )
}

MobilePoiDetail.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  data: PropTypes.any,
  closeEnable: PropTypes.bool,
  onClose: PropTypes.func,
  hiddenDirection: PropTypes.bool,
  disableTextSearch: PropTypes.bool
};

export default MobilePoiDetail;
