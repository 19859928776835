import { Box, Button, Collapse, List, ListItem, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { LinkIconConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { UrlConfig } from '../../../config/urlConfig';
import { CodeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { ApiTool, UserTool } from '../../../tool';
import UpdatePlaceNote from '../updatePlaceNote';
const useStyles = makeStyles({
  buttonInherit: {
    textTransform: 'inherit'
  },
  btnText: {
    textTransform: 'inherit',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  iconList: {
    alignSelf: 'flex-start'
  }
})

const RenderGroupSaved = ({ savedPlaceGroup, handleShowNote, handleShowList }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const handleSetShow = () => {
    setOpen(!open)
  }

  return (
    <>
      <Box flexGrow='1'>
        {
          savedPlaceGroup?.note ?
            <>
              <Button
                className={classes.btnText}
                fullWidth variant='text'
                onClick={handleSetShow}
                title={savedPlaceGroup?.groupName}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                <span style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  width: '266px'
                }}>
                  {savedPlaceGroup?.groupName}
                </span>
              </Button>

              <Collapse in={open} timeout={500} >
                <Box padding='6px 8px'>
                  <Typography style={{ whiteSpace: "pre-line" }} variant='body2'>{savedPlaceGroup?.note}</Typography>
                </Box>
              </Collapse>
            </>
            :
            <Button className={classes.btnText} fullWidth variant='text' title={savedPlaceGroup?.groupName}>
              <span style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                width: '266px'
              }}>
                {savedPlaceGroup?.groupName}
              </span>
            </Button>
        }

        <Box >
          {
            savedPlaceGroup?.note ?
              <Button variant='text' color='primary' onClick={handleShowNote(savedPlaceGroup)} className={classes.buttonInherit}>{Resource.note.edit}</Button>
              :
              <Button variant='text' color='primary' onClick={handleShowNote(savedPlaceGroup)} className={classes.buttonInherit}>{Resource.note.add}</Button>
          }
          <Button variant='text' onClick={handleShowList(savedPlaceGroup?.groupId)} color='primary' className={classes.buttonInherit}>{Resource.common.viewList}</Button>
        </Box>
      </Box>
    </>
  )
}

function DisplayGroup({ id }) {
  const classes = useStyles()
  const [savedPlace, setSavedPlace] = useState([])
  const [show, setShow] = useState(false)
  const [savedPlaceGroup, setGroup] = useState(null)
  const history = useHistory()
  const sourceRef = useRef()

  useEffect(() => {
    sourceRef.current = getData()
  }, [id])

  useEffect(() => {
    return () => {
      sourceRef.current?.cancel()
    }
  }, [])

  const getData = () => {
    let sourceList
    if (UserTool.getUser()) {
      let body = {
        placeId: id
      }
      sourceList = ApiTool.queryGetFromJson(UrlConfig.placeGroup.getSavedGroupsOfPlace, body, (res) => {
        if (res?.code == CodeEnum.ok) {
          setSavedPlace(res?.result)
        }
      }, true)
    }
    return sourceList
  }

  const handleOnChangeNote = () => {
    getData()
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShowNote = (savedPlaceGroup) => (e) => {
    setShow(true)
    setGroup(savedPlaceGroup)
  }

  const handleShowList = (groupId) => (e) => {
    history.push({
      pathname: RouterConfig.placeGroup.groupDetail.replace(":id", groupId),
      state: {
        isBack: true
      }
    })
  }

  return (
    savedPlace?.savedGroups?.length > 0 &&
    <>
      <List disablePadding>
        {
          savedPlace?.savedGroups?.map((savedItem, index) => {
            let savedPlaceGroup = {
              groupId: savedItem?.id,
              placeId: id,
              note: savedItem?.description,
              placeName: savedPlace?.name,
              groupName: savedItem?.name
            }
            return (
              <ListItem key={index} >
                <ListItemIcon className={classes.iconList}>
                  <img src={LinkIconConfig.placeGroupIcon.list} width={24} height={24} />
                </ListItemIcon>
                <RenderGroupSaved savedPlaceGroup={savedPlaceGroup} handleShowNote={handleShowNote} handleShowList={handleShowList} />
              </ListItem>
            )
          })
        }
      </List>
      {
        show &&
        <UpdatePlaceNote onClose={handleClose} savedPlaceGroup={savedPlaceGroup} onChange={handleOnChangeNote} />
      }
    </>
  )
}

DisplayGroup.propTypes = {
  //
};

export default DisplayGroup;
