import { Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { ImageConfig, LinkIconConfig } from '../../../config';
import { RouterConfig } from '../../../config/routerConfig';
import { MyPoisTypeEnum } from '../../../enum';
import { Resource } from '../../../resource';
import { UserTool } from '../../../tool';
import { ShowEffectSettingState, ShowLanguageSettingState, ShowLayerSettingState, ShowSettingState } from './../../../appState/settingState';
import { useIsMobileScreen } from './../../../useHooks/useIsMobileScreen';
import TimeLineSetting from '../../timeLineSetting/timeLineSetting';

const useStyles = makeStyles({
  root: {
    width: 320
  },
  padding: {
    padding: '10px 24px',
  }
})

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function Setting() {

  const classes = useStyles()
  const history = useHistory()

  const isMobile = useIsMobileScreen()

  const [showSettingState, setShowSettingState] = useRecoilState(ShowSettingState)

  const setShowLayerSettingState = useSetRecoilState(ShowLayerSettingState)
  const setShowLanguageSettingState = useSetRecoilState(ShowLanguageSettingState)
  const setShowEffectSettingState = useSetRecoilState(ShowEffectSettingState)

  let user = UserTool.getUser()

  const handleCloseDrawer = () => {
    setShowSettingState(false)
  }

  const handleShowLayerSetting = () => {
    setShowLayerSettingState(true)
  }

  const handleShowLanguageSetting = () => {
    setShowLanguageSettingState(true)
  }

  const handleShowEffectSetting = () => {
    setShowEffectSettingState(true)
  }

  const handleShowMy3DObjects = () => {
    window.open(location.origin + '/manager2/my-object/list/approved', "_blank")
  }

  const handleShowMyPois = () => {
    setShowSettingState(false)
    history.push({
      pathname: RouterConfig.my.place.replace(":type", MyPoisTypeEnum.label)
    })
  }

  const onClickLogo = () => {
    setShowSettingState(false)
    history.push({
      pathname: RouterConfig.home,
      search: location.search
    })
  }

  const handleShowMyAccesskey = () => {
    if (user?.isDeveloper) {
      window.open(location.origin + '/developer/key', "_blank")
    }
    else {
      window.open(location.origin + '/developer/key', "_blank")
    }
  }

  const listMenu = [
    {
      src: LinkIconConfig.setting.objectIcon,
      title: Resource.common.objectClass,
      onclick: handleShowLayerSetting
    },
    {
      src: LinkIconConfig.setting.settingIcon,
      title: Resource.common.setting,
      onclick: handleShowEffectSetting
    },
    {
      src: LinkIconConfig.setting.language,
      title: Resource.common.language,
      onclick: handleShowLanguageSetting
    }
  ]

  const listMenuUser = [
    {
      src: LinkIconConfig.setting.myLocation,
      title: Resource.common.myPlace,
      onclick: handleShowMyPois,
      hiddenMobile: isMobile
    },
    {
      src: LinkIconConfig.setting.my3DObject,
      title: Resource.common.my3DObject,
      onclick: handleShowMy3DObjects
    },
    // {
    //   src: LinkIconConfig.setting.key,
    //   title: Resource.common.myAccessKey,
    //   onclick: handleShowMyAccesskey
    // },
  ]

  let list = UserTool.getUser() ? [...listMenu, ...listMenuUser] : listMenu
  return (
    <Drawer anchor='left' open={showSettingState} onClose={handleCloseDrawer}>
      <Box className={classes.root}>
        <img onClick={onClickLogo} src={ImageConfig.logo} className={classes.padding} />
      </Box>
      <Divider />
      <List disablePadding aria-label="Menu Setting" >
        {list?.map((item, index) => {
          return (
            !item?.hiddenMobile &&
            <ListItem button key={uuidv4()} onClick={item.onclick} className={classes.padding}>
              <ListItemIcon>
                <img src={item.src} height={24} width={24} />
              </ListItemIcon>
              <ListItemText><Typography variant='subtitle2'>{item.title}</Typography></ListItemText>
            </ListItem>
          )

        }
        )}
        <TimeLineSetting />
      </List>
    </Drawer>
  )
}

Setting.propTypes = {
  //
};

export default Setting;
