import { Box, Button, Collapse, List, makeStyles, Typography } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { TypeBusinessHourEnum } from '../../../enum'
import { Resource } from '../../../resource'
import { BusinessHourTool } from '../../../tool'

const useStyles = makeStyles((theme) => ({
  textTransform: {
    textTransform: 'inherit',
    padding: '0 0',
    '& .MuiButton-label span': {
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
}))

const arrDayOfWeek = [Resource.businessHours.sunday, Resource.businessHours.monday, Resource.businessHours.tuesday, Resource.businessHours.wednesday, Resource.businessHours.thursday, Resource.businessHours.friday, Resource.businessHours.saturday]
const nameTypeBusinessHours = {
  [TypeBusinessHourEnum.closed]: Resource.common.closeTime,
  [TypeBusinessHourEnum.openAllDay]: Resource.common.openAllTime,
  [TypeBusinessHourEnum.none]: Resource.businessHours.none
}
function DisplayBusinessHour(props) {
  const { businessHours } = props
  const classes = useStyles()

  const [show, setShow] = useState(false)

  const [openingDay, setOpeningDay] = useState(null)
  const [dataState, setDataState] = useState([])

  const handleShow = () => {
    setShow(!show)
  }

  useEffect(() => {
    let open = BusinessHourTool.findCurrentOpening(businessHours)
    let arr = BusinessHourTool.generateBusinessHourObject(businessHours)
    let dataMerge = BusinessHourTool.mergeDataBusinessHour(arr)
    setOpeningDay(open)
    setDataState(dataMerge)
  }, [businessHours])


  const sort = (a, b) => {
    let day = new Date().getDay()
    let newA = a < day ? (a + 7) : a
    let newB = b < day ? (b + 7) : b
    return (newA - newB)
  }

  return (
    businessHours?.length > 0 &&
    <>
      <Button className={classes.textTransform} onClick={handleShow} variant='text' endIcon={show ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
        {
          openingDay?.type == TypeBusinessHourEnum.opened &&
          <>
            <Typography variant='subtitle2' component='span' style={{ color: '#059000', fontWeight: '500' }}>
              {Resource.common.opening}
            </Typography>
            <span style={{ padding: '0 6px' }}>-</span>
            <Typography variant='subtitle2' component='span'>
              {Resource.businessHours.closedAt + " " + openingDay?.close?.time?.slice(0, 2) + ":" + openingDay?.close?.time?.slice(2, 4)}
            </Typography>
          </>
        }

        {
          openingDay?.type == TypeBusinessHourEnum.openAllDay &&
          <Typography variant='subtitle2' component='span' style={{ color: '#059000', fontWeight: '500' }}>
            {nameTypeBusinessHours[openingDay?.type]}
          </Typography>
        }

        {
          openingDay?.type == TypeBusinessHourEnum.closed &&
          <Typography variant='subtitle2' component='span' style={{ fontWeight: '500' }}>{nameTypeBusinessHours[openingDay?.type]}</Typography>
        }
         {
          openingDay?.type == TypeBusinessHourEnum.none &&
          <Typography variant='subtitle2' component='span' style={{ fontWeight: '500' }}>{nameTypeBusinessHours[openingDay?.type]}</Typography>
        }
      </Button>

      <Collapse in={show} timeout={600}>
        <List
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}>
          {
            Object.keys(dataState)?.sort(sort).map((key, index) => {
              let bussItem = dataState[key]
              return (
                <Box key={uuidv4()} display='flex'>
                  <Box minWidth='160px' >
                    <Typography
                      style={{ fontWeight: index == 0 ? '500' : '' }}
                      variant='body2'
                      component='span'
                    >
                      {arrDayOfWeek[bussItem[0]?.dayOfWeek]}
                    </Typography>
                  </Box>
                  <Box pr={1} style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      bussItem?.map((item, idx) => {
                        return (
                          <Typography key={idx} style={{ fontWeight: index == 0 ? '500' : '' }} variant='body2' component='span'>
                            {
                              item?.type ?
                                (
                                  item?.type == TypeBusinessHourEnum.opened ? item?.text : nameTypeBusinessHours[item?.type]
                                )
                                :
                                Resource.common.noInfo
                            }
                          </Typography>
                        )
                      })
                    }
                  </Box>
                </Box>
              )
            })
          }
        </List>
      </Collapse>
    </>
  )

}

DisplayBusinessHour.propTypes = {
  //
}

export default DisplayBusinessHour
