import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import ResizeObserver from "resize-observer-polyfill";
import "ui/dist/index.css";
import AppRoot from "./appRoot";
import { AppData } from './data/appData';

window.ResizeObserver = ResizeObserver;

let basename = "";

const onAppRootRef = (r) => {
    AppData.app = r
}

const container = createRoot(document.getElementById("root"));

// const root = createRoot(container); // createRoot(container!) if you use TypeScript
container.render(
    <RecoilRoot>
        <Router basename={basename}>
            <AppRoot ref={onAppRootRef} />
        </Router>
    </RecoilRoot>
);