import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Swipeable from '../../components/swipeable/swipeable';
import { ImageConfig, LinkIconConfig } from '../../config';
import { AppData } from '../../data';
import { ManeuverEnum } from '../../enum';
import { Resource } from '../../resource';
import { DirectionTool } from '../../tool';
import { LanguageState } from './../../appState/settingState';
import './mobileSwipStepsV2.scss';
import { useTime } from '../../useHooks';
import { MobileShowLocationButtonState } from '../../appState';

const ICON_NEXT = <svg id="Component_762_5" data-name="Component 762 – 5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,24H24V0H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_15101" data-name="Path 15101" d="M1.41,0,6,4.58,10.59,0,12,1.41l-6,6-6-6Z" transform="translate(8 18) rotate(-90)" fill="#1c75bc" />
</svg>
const ICON_PRE = <svg id="Component_762_6" data-name="Component 762 – 6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_2780" data-name="Path 2780" d="M0,24H24V0H0Z" transform="translate(0 24) rotate(-90)" fill="none" />
  <path id="Path_2781" data-name="Path 2781" d="M1.41,7.41,6,2.83l4.59,4.58L12,6,6,0,0,6Z" transform="translate(8.295 18) rotate(-90)" fill="#1c75bc" />
</svg>

const ICON_MANEUVER = {
  [ManeuverEnum.keepLeft]: LinkIconConfig.maneuver.keepLeft,
  [ManeuverEnum.keepRight]: LinkIconConfig.maneuver.keepRight,
  [ManeuverEnum.roundaboutLeft]: LinkIconConfig.maneuver.roundaboutLeft,
  [ManeuverEnum.roundaboutRight]: LinkIconConfig.maneuver.roundaboutRight,
  [ManeuverEnum.straight]: LinkIconConfig.maneuver.straight,
  [ManeuverEnum.turnLeft]: LinkIconConfig.maneuver.turnLeft,
  [ManeuverEnum.turnRight]: LinkIconConfig.maneuver.turnRight,
  [ManeuverEnum.turnSharpLeft]: LinkIconConfig.maneuver.turnSharpLeft,
  [ManeuverEnum.turnSharpRight]: LinkIconConfig.maneuver.turnSharpRight,
  [ManeuverEnum.turnSlightLeft]: LinkIconConfig.maneuver.turnSlightLeft,
  [ManeuverEnum.turnSlightRight]: LinkIconConfig.maneuver.turnSlightRight,
  [ManeuverEnum.finish]: LinkIconConfig.maneuver.finish,
  [ManeuverEnum.reachedVia]: LinkIconConfig.maneuver.reachedVia
}

const MobileSwipStepsV2 = (props) => {
  const { route, indexLeg, indexStep, onBack, indexRoute } = props

  const languageState = useRecoilValue(LanguageState)
  const setShowMyLocationButton = useSetRecoilState(MobileShowLocationButtonState)

  const [indexActive, setIndexActive] = useState(0)
  const [hiddenPrev, setHiddenPrev] = useState(false)
  const [hiddenNext, setHiddenNext] = useState(false)

  const [stepsState, setStepsState] = useState([])
  const [routeState, setRouteState] = useState()

  const _stepMarker = useRef()
  const valueTime = useTime()

  useEffect(() => {
    _stepMarker.current = new map4d.Marker({
      position: { lat: 0, lng: 0 },
      icon: new map4d.Icon(25, 30, ImageConfig.marker),
      anchor: [0.5, 1],
      zIndex: 99999
    })
    _stepMarker.current?.setMap(AppData.map)
    setShowMyLocationButton(true)
    return () => {
      _stepMarker.current?.setMap(null)
      _stepMarker.current = null
      setShowMyLocationButton(false)
    }
  }, [])
  useEffect(() => {
    route && setRouteState(route)
  }, [route])

  useEffect(() => {
    if (routeState) {
      let list = []
      route?.legs?.forEach(leg => {
        leg?.steps?.forEach(step => {
          list.push(step)
        })
      });
      setStepsState(list)
    }
  }, [routeState])

  useEffect(() => {
    if (indexLeg >= 0 && indexStep >= 0 && routeState) {
      let length = 0
      route?.legs?.forEach((leg, iLeg) => {
        if (iLeg < indexLeg) {
          length = length + leg?.steps?.length
        }
      })
      let newIndex = length + indexStep
      setIndexActive(newIndex)
    }
  }, [indexLeg, indexStep, routeState])

  useEffect(() => {
    if (indexActive >= 0) {
      indexActive == 0 ? setHiddenPrev(true) : setHiddenPrev(false)
      if (stepsState?.length > 0) {
        indexActive == (stepsState?.length - 1) ? setHiddenNext(true) : setHiddenNext(false)
        _stepMarker.current?.setPosition(stepsState[indexActive]?.startLocation)
        moveCamera(stepsState[indexActive]?.startLocation)
      }
    }
  }, [indexActive, stepsState])

  const moveCamera = (location) => {
    let camera = AppData.map.getCamera()
    if (camera.getZoom() !== 16) {
      camera.setZoom(16)
    }
    camera.setTarget(location)
    AppData.map.moveCamera(camera, { animate: true });
  }

  const handleOnChangeStep = (index) => {
    setIndexActive(index)
  }

  const handleToPrev = () => {
    setIndexActive((prev) => (prev - 1))
  }

  const handleToNext = () => {
    setIndexActive((prev) => (prev + 1))
  }

  const handleToBack = () => {
    onBack?.()
  }

  return (
    <>
      <div className='mobileSwipebleCpn'>
        <img src={LinkIconConfig.common.back} alt='icon-back' onClick={handleToBack} width={24} height={24} />
        <div>{Resource.common.routePreview}</div>
      </div>
      <div className='mobileSwipeableListCpn'>
        <Swipeable length={stepsState?.length} indexActive={indexActive} onChange={handleOnChangeStep}>
          {
            stepsState?.map((step, index) => {
              let duration = step?.duration?.text
              let distance = step?.distance?.value
              let instruction = step?.htmlInstructions
              let textDistance = DirectionTool.convertDistanceLocalize(distance, languageState)
              let description = duration ? `${textDistance} (${duration})` : `${textDistance}`
              return (
                <div key={step?.polyline + `${index}`} className='boxStepContainer'>
                  <div className='boxStep'>
                    <img src={ICON_MANEUVER[step?.maneuver]} alt={step?.maneuver} width={24} height={24} />
                    <div className='boxInforStep'>
                      <div className='boxInstruction'>{instruction}</div>
                      <div>{description}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Swipeable>
      </div>
      <div className='mobileSwipeableButtonCpn'>
        <div className={`iconButtonSwipe ${hiddenPrev ? "hiddenButtonSwipe" : ""}`} onClick={handleToPrev}>
          {ICON_PRE}
        </div>
        <div className='caption'><span className='captionTotal'>{`${Resource.common.total} ${valueTime[indexRoute]} `}</span><span>{`(${DirectionTool.convertDistanceLocalize(routeState?.distance?.value, languageState)})`}</span></div>
        <div className={`iconButtonSwipe ${hiddenNext ? "hiddenButtonSwipe" : ""}`} onClick={handleToNext}>{ICON_NEXT}</div>
      </div>
    </>);
};

MobileSwipStepsV2.propTypes = {
  route: PropTypes.any,
  indexLeg: PropTypes.number,
  indexStep: PropTypes.number,
  onBack: PropTypes.func,
  indexRoute: PropTypes.number
};

export default MobileSwipStepsV2;
