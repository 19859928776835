import * as React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { TabsContext } from '../../context';
import { StringTool } from '../../tool';
import styles from './tabs.css'

export interface TabsProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "onChange"> {
  items: any,
  onChange?: (index: number, item: any) => void,
  value?: any
}

let childItem: any;
let list: any[] = []

const Tabs: React.FC<TabsProps> = ({
  items,
  onChange,
  children,
  value,
  ...props
}) => {
  const [className, setClassName] = useState(() => {
    return StringTool.mergeClassName(styles.headerTab, props.className as string)
  })
  useEffect(() => {
    setClassName(StringTool.mergeClassName(styles.headerTab, props.className as string))
  }, [props.className])

  const [listTab, setListTab] = useState(list)
  const [valueState, setValueState] = useState(0)

  childItem = listTab.filter((e: any, index: number) => {
    if (index == valueState) return e
  })

  const countRef = useRef(0);
  const valueRef = useRef({
    setChild: (child: any, index: number) => {
      setListTab((prev: any) => {
        let itemsNew = [...(prev || [])];
        itemsNew[index] = child
        return itemsNew;
      });
    },
    getIndex: () => {
      countRef.current = countRef.current + 1;
      return (countRef.current - 1);
    },
  });
  useEffect(() => {
    if (items?.length > 0 && value) {
      let index = items?.findIndex((item: any) => item == value)
      setValueState(index)
    }
  }, [value])

  const onChangeTab = (index: number, tab: any) => (e: any) => {
    onChange && onChange(index, tab)
    setValueState(index)
  }
  
  return (
    <div className={styles.tabsContainer}>
      <div {...props} className={className} >{
        items.map((item: any, index: number) => {
          return (
            <div className={styles.tab} key={index} onClick={onChangeTab(index, item)}><div className={valueState == index ? styles.text : ''}>{item}</div></div>
          )
        })
      }</div>
      <TabsContext.Provider value={valueRef.current}>
        {children}
      </TabsContext.Provider>
      {childItem}
    </div>
  );
};

export default Tabs;