
import dialogPolyfill from 'dialog-polyfill';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AlertTypeEnum } from '../../enum';
import Alert from '../alert/alert';
import "./alertControl.scss";

function AlertControl(props, ref) {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "success"
  })

  const dialogHtml = useRef()

  useEffect(() => {

  }, [])


  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setAlert(prev => {
      return {
        ...prev,
        open: false
      }
    })
  }

  useImperativeHandle(ref, () => {
    return {
      alert: (message, type) => {
        setAlert({
          open: true,
          message: message,
          type: type
        })
      }
    }
  })
  useEffect(() => {
    if (alert.open) {
      dialogHtml.current?.showModal()
    }
    return () => {
      dialogHtml.current?.open && dialogHtml.current?.close?.()
    }
  }, [alert.open])

  const handleRef = (r) => {
    dialogHtml.current = r
    r && dialogPolyfill.registerDialog(r)
  }

  return (
    <dialog
      ref={handleRef}
      className='alertControlCpn'
    >
      <Alert
        open={alert.open}
        message={alert?.message || ""}
        type={alert?.type || AlertTypeEnum.success}
        timeAutoClose={3000}
        onClose={handleClose}
      />
    </dialog>
  )

}
AlertControl = forwardRef(AlertControl)
AlertControl.propTypes = {
  //
};

export default AlertControl;
