import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Resource } from '../../resource';
import { StringTool } from '../../tool';
import './datePickerV2.scss';

const ICON_CALENDAR =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <clipPath id="clip-path-calendar">
        <rect width="20" height="20" fill="#fff" stroke="#707070" strokeWidth="1" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path-calendar)">
      <g transform="translate(-0.35 -0.166)">
        <path d="M8,2V4.45" transform="translate(-0.917)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M16,2V4.45" transform="translate(-2.384)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M3.5,9.09H17.382" transform="translate(-0.092 -1.3)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M17.7,7.583v6.941c0,2.45-1.225,4.083-4.083,4.083H7.083C4.225,18.607,3,16.974,3,14.524V7.583C3,5.133,4.225,3.5,7.083,3.5h6.533C16.474,3.5,17.7,5.133,17.7,7.583Z" transform="translate(0 -0.275)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M15.695,13.7H15.7" transform="translate(-2.328 -2.146)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M15.695,16.7H15.7" transform="translate(-2.328 -2.696)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M12,13.7H12" transform="translate(-1.65 -2.146)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M12,16.7H12" transform="translate(-1.65 -2.696)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M8.294,13.7H8.3" transform="translate(-0.971 -2.146)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M8.294,16.7H8.3" transform="translate(-0.971 -2.696)" fill="none" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      </g>
    </g>
  </svg>

const ARROW_LEFT =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M0,20H20V0H0Z" fill="none" />
    <path d="M10,12l4,4V8Z" transform="translate(-2 -2)" fill="#505050" />
  </svg>

const ARROW_RIGHT =
  <svg width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(-1718 -337)">
      <path d="M0,20H20V0H0Z" transform="translate(1718 337)" fill="none" />
      <path d="M14,12l-4,4V8Z" transform="translate(1716 335)" fill="#505050" />
    </g>
  </svg>

const TitleDayOfWeek = [
  Resource.datePicker.mon,
  Resource.datePicker.tue,
  Resource.datePicker.wed,
  Resource.datePicker.thu,
  Resource.datePicker.fri,
  Resource.datePicker.sat,
  Resource.datePicker.sun,
]

const LabelDayOfWeek = {
  '1': Resource.datePicker.mon,
  '2': Resource.datePicker.tue,
  '3': Resource.datePicker.wed,
  '4': Resource.datePicker.thu,
  '5': Resource.datePicker.fri,
  '6': Resource.datePicker.sat,
  '0': Resource.datePicker.sun,
}

const displayTimeString = (date) => {
  let text = ''
  if (date) {
    let year = new Date(date)?.getFullYear()
    let month = new Date(date)?.getMonth()
    text = Resource.common.month + ` ${month + 1} ` + Resource.common.year?.toLocaleLowerCase() + ` ${year} `
  }
  return text
}

const displayValueString = (value, isShowYear) => {
  let text = ''
  if (value) {
    let year = new Date(value)?.getFullYear()
    let date = new Date(value)?.getDate()
    let month = new Date(value)?.getMonth()

    let day = new Date(year, month, value?.getDate())?.getDay()

    text = LabelDayOfWeek[day] + `, ${date} ` + 'thg' + ` ${month + 1} ` + (isShowYear ? `, ${year}` : '')
  }
  return text
}

const checkEqualDays = (date1, date2) => {
  let newDate1 = new Date(date1)
  let newDate2 = new Date(date2)

  let day1 = newDate1?.getDate()
  let month1 = newDate1?.getMonth()
  let year1 = newDate1?.getFullYear()

  let day2 = newDate2?.getDate()
  let month2 = newDate2?.getMonth()
  let year2 = newDate2?.getFullYear()
  return (day1 == day2 && month1 == month2 && year1 == year2)
}

const isDayOfMin = (dateState, minDate) => {
  let check = false
  let min = new Date(minDate)
  let date = new Date(dateState)
  check = date?.getTime() < min?.getTime()
  return check
}

const isDayOfMax = (dateState, maxDate) => {
  let check = false
  let max = new Date(maxDate)
  let date = new Date(dateState)
  check = date?.getTime() > max?.getTime()
  return check
}

const MAX_LENGTH_DAY_OF_MONTH = 42
const AnchorEnum = {
  top: "top",
  bottom: "bottom",
}
function DatePickerV2(props) {
  const { value, onChange, isShowToday, isShowYear, minDate, maxDate, disabled = false, isMobile = false, readOnly } = props

  const dateInputRef = useRef()
  const calendarPopupRef = useRef()

  const [showCalendar, setShowCalendar] = useState(false)
  const [anchorY, setAnchorY] = useState(AnchorEnum.bottom)
  const [distanceY, setDistanceY] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [left, setLeft] = useState(0)

  const [valueState, setValueState] = useState(new Date())
  const [dateState, setDateState] = useState(new Date())
  const [dataDateState, setDataDateState] = useState([])

  const [dateSelected, setDateSelected] = useState({
    date: '',
    month: '',
    year: ''
  })

  useEffect(() => {
    if (value) {
      // let yearValue = value?.getFullYear()
      // let monthValue = value?.getMonth()
      // let dateValue = value?.getDate()
      // setDateSelected({
      //   date: dateValue,
      //   month: monthValue,
      //   year: yearValue
      // })
      setValueState(new Date(value))
    }
  }, [value])

  useEffect(() => {
    if (valueState) {
      let yearValue = valueState?.getFullYear()
      let monthValue = valueState?.getMonth()
      let dateValue = valueState?.getDate()
      setDateSelected({
        date: dateValue,
        month: monthValue,
        year: yearValue
      })
      setDateState(new Date(valueState))
    }
  }, [valueState])

  useEffect(() => {
    let year = dateState?.getFullYear()
    let month = dateState?.getMonth()

    let total = new Date(year, month + 1, 0).getDate()
    let firstDay = new Date(year, month, 1)?.getDay()
    let totalPrev = new Date(year, month, 0).getDate()

    let currentDate = new Date()?.getDate()
    let currentMonth = new Date()?.getMonth()
    let currentYear = new Date()?.getFullYear()

    let arrDayOfMonth = Array.from({ length: total }, (_, i) => {
      let dateNumber = i + 1
      let today = (dateNumber == currentDate && month == currentMonth && year == currentYear)
      let newDate = new Date(year, month, dateNumber)
      let item = {
        year: year,
        month: month,
        date: dateNumber,
        isToday: today,
      }
      if (minDate) {
        item.isMinDay = isDayOfMin(newDate, minDate) && !checkEqualDays(newDate, minDate)
      }
      if (maxDate) {
        item.isMaxDay = isDayOfMax(newDate, maxDate) && !checkEqualDays(newDate, minDate)
      }
      return item
    })

    if (firstDay > 0) {
      Array.from({ length: firstDay - 1 }, (_, i) => i)?.forEach((day) => {
        let dateNumber = totalPrev - day
        let item = {
          date: dateNumber,
          isDisableDay: true,
        }
        arrDayOfMonth?.unshift(item)
      })
    }

    if (arrDayOfMonth?.length < MAX_LENGTH_DAY_OF_MONTH) {
      let length = MAX_LENGTH_DAY_OF_MONTH - arrDayOfMonth?.length
      Array.from({ length: length }, (_, i) => i + 1)?.forEach((day) => {
        let item = {
          date: day,
          isDisableDay: true,
        }
        arrDayOfMonth?.push(item)
      })
    }
    let size = 7
    let arrWeekOfMonth = []

    for (let i = 0; i < arrDayOfMonth?.length; i += size) {
      let arrWeek = arrDayOfMonth?.slice(i, i + size);
      arrWeekOfMonth?.push(arrWeek)
    }
    setDataDateState(arrWeekOfMonth)
  }, [dateState])


  useEffect(() => {
    const handleResize = () => {
      let innerHeight = window.innerHeight
      let actionCpn = dateInputRef.current?.getBoundingClientRect() || {}

      // Mặc định actionCpn ở nửa dưới màn hình (Lúc này sẽ set popup lên trên)
      let anchorYNew = AnchorEnum.bottom
      let distanceYNew = innerHeight - actionCpn?.top
      let maxHeight = actionCpn?.top

      //Nếu actionCpn ở nửa trên màn hình (Lúc này sẽ set popup xuống dưới)
      if ((actionCpn?.top + actionCpn.height / 2) < (innerHeight / 2)) {
        anchorYNew = AnchorEnum.top
        distanceYNew = actionCpn?.bottom
        maxHeight = innerHeight - (actionCpn?.top + actionCpn?.height)
      }
      setAnchorY(anchorYNew)
      setDistanceY(distanceYNew)
      setMaxHeight(maxHeight)
      setLeft(actionCpn?.left)
    }

    let observer
    handleResize()
    observer = new MutationObserver(handleResize)
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    })
    window.addEventListener("resize", handleResize)
    document.addEventListener("scroll", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      document.removeEventListener("scroll", handleResize)
      observer?.disconnect()
    }
  }, [showCalendar])

  useEffect(() => {
    const clickOut = (event) => {
      var isClickButton = dateInputRef?.current?.contains(event.target);
      var isClickPopup = calendarPopupRef?.current?.contains(event.target);
      if (!isClickPopup && !isClickButton) {
        setShowCalendar(false)
      }
    }
    window.addEventListener("mousedown", clickOut);
    return () => window.removeEventListener("mousedown", clickOut);
  }, [])

  const onShowCalendar = () => {
    if (!disabled) {
      setShowCalendar(true)
    }
  }

  const onSelectItemTime = (item) => (e) => {
    if (!(item?.isDisableDay || item?.isMinDay || item?.isMaxDay)) {
      // setDateState((prev) => {
      //   let newDate = new Date()
      //   newDate?.setFullYear(item?.year, item?.month, item?.date)
      //   return newDate
      // })
      setValueState((prev) => {
        let newDate = new Date(prev)
        newDate?.setFullYear(item?.year, item?.month, item?.date)
        onChange && onChange(newDate)
        return newDate
      })
      // setDateSelected((prev) => {
      //   return {
      //     ...prev,
      //     month: item?.month,
      //     date: item?.date,
      //     year: item?.year
      //   }
      // })
      setShowCalendar(false)
    }
  }

  const onNextDate = (isDisable) => (e) => {
    if (!isDisable) {
      setValueState((prev) => {
        let newDate = new Date(prev)
        let newDay = newDate?.getDate()
        newDate.setDate(newDay + 1)
        onChange && onChange(newDate)
        return newDate
      })
    }
  }

  const onPrevDate = (isDisable) => (e) => {
    if (!isDisable) {
      setValueState((prev) => {
        let newDate = new Date(prev)
        let newDay = newDate?.getDate()
        newDate.setDate(newDay - 1)
        onChange && onChange(newDate)
        return newDate
      })
    }
  }

  const onNextMonth = () => {
    setDateState((prev) => {
      let newDate = new Date(prev)
      let newMonth = newDate?.getMonth()
      newDate.setDate(15)
      newDate?.setMonth(newMonth + 1)
      return newDate
    })
  }

  const onPreMonth = () => {
    setDateState((prev) => {
      let newDate = new Date(prev)
      let newMonth = newDate?.getMonth()
      newDate.setDate(15)
      newDate?.setMonth(newMonth - 1)
      return newDate
    })
  }

  const onShowToday = () => {
    let currentDate = new Date()?.getDate()
    let currentMonth = new Date()?.getMonth()
    let currentYear = new Date()?.getFullYear()

    setDateState((prev) => {
      let newDate = new Date()
      newDate?.setFullYear(currentYear, currentMonth, currentDate)
      return newDate
    })

    setValueState((prev) => {
      let newDate = new Date()
      newDate?.setFullYear(currentYear, currentMonth, currentDate)
      onChange && onChange(newDate)
      return newDate
    })

    setDateSelected((prev) => {
      return {
        ...prev,
        month: currentMonth,
        date: currentDate,
        year: currentYear
      }
    })
  }

  let monthNumber = dateState?.getMonth()
  let isNext = isShowYear || (monthNumber < 11)
  let isPre = isShowYear || (monthNumber != 0 && monthNumber <= 11)

  let isDisableNextDate = maxDate ? !isDayOfMax(maxDate, valueState) : false
  let isDisablePrevDate = minDate ? !isDayOfMin(minDate, valueState) : false

  let valueStateString = displayValueString(valueState, isShowYear) || ''
  let dateStateString = displayTimeString(dateState) || ''

  return (
    <div className='datePickerV2Cpn'>
      {
        readOnly ?
          <div className='dateInputReadOnly'>
            <div className='prefixIcon'>
              {ICON_CALENDAR}
            </div>

            <div className='textInput' title={valueStateString}>
              {valueStateString}
            </div>
          </div>
          :
          <>
            <div className={StringTool.mergeClassName('dateInput', isMobile ? 'dateInputMobile' : '', disabled ? 'disableInput' : '')} ref={dateInputRef}>
              <div className='prefixIcon'>
                {ICON_CALENDAR}
              </div>

              <div className='textInput' onClick={onShowCalendar} title={valueStateString}>
                {valueStateString}
              </div>

              {
                !isMobile && <div className='suffixIcon'>
                  <div
                    className={StringTool.mergeClassName('prevDate', isDisablePrevDate ? 'disableAction' : '')}
                    onPointerDown={onPrevDate(isDisablePrevDate)}
                    title={Resource.common.previous}
                  >
                    {ARROW_LEFT}
                  </div>

                  <div
                    className={StringTool.mergeClassName('nextDate', isDisableNextDate ? 'disableAction' : '')}
                    onPointerDown={onNextDate(isDisableNextDate)}
                    title={Resource.common.next}
                  >
                    {ARROW_RIGHT}
                  </div>

                </div>
              }

            </div>

            <dialog
              open={showCalendar}
              className='dialogDatePickerV2'
              ref={calendarPopupRef}
              style={{
                left: left,
                [anchorY]: distanceY + 6,
                maxHeight: maxHeight
              }}
            >
              <div className='divCalendar'>
                <div className='calendarHeader'>
                  <button
                    title={Resource.common.previous}
                    className={StringTool.mergeClassName('prevMonth', !isPre ? 'disableAction' : '')}
                    onClick={onPreMonth}
                  >
                    {ARROW_LEFT}
                  </button>

                  <div className='textTime' title={dateStateString}>
                    {dateStateString}
                  </div>

                  <button
                    title={Resource.common.next}
                    className={StringTool.mergeClassName('nextMonth', !isNext ? 'disableAction' : '')}
                    onClick={onNextMonth}
                  >
                    {ARROW_RIGHT}
                  </button>
                </div>

                <div className='calendarContainer'>
                  <div className='dayHeader'>
                    {
                      TitleDayOfWeek?.map((day, index) => {
                        return (
                          <div className='boxTitle' key={index}>
                            {day}
                          </div>
                        )
                      })
                    }
                  </div>

                  <div className='dayContent'>
                    {
                      dataDateState?.map((week, index) => {
                        return (
                          <div className='weekItem' key={index}>
                            {
                              week?.map((dayOfWeek, idx) => {
                                let isSelect = !dayOfWeek?.isDisableDay && (dayOfWeek?.date == dateSelected?.date && dayOfWeek?.month == dateSelected?.month && dayOfWeek?.year == dateSelected?.year)
                                let isToday = !dayOfWeek?.isDisableDay && dayOfWeek?.isToday

                                return (
                                  <div
                                    onClick={onSelectItemTime(dayOfWeek)}
                                    className={StringTool.mergeClassName(
                                      'dateItem', isToday ? 'itemToday' : '',
                                      dayOfWeek?.isDisableDay ? 'itemDisable' : '',
                                      dayOfWeek?.isMinDay ? 'itemDisable' : '',
                                      dayOfWeek?.isMaxDay ? 'itemDisable' : '',
                                      isSelect ? 'itemSelect' : ''
                                    )}
                                    key={idx}
                                  >
                                    {dayOfWeek?.date}
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  isShowToday &&
                  <div className='calendarFooter'>
                    <div className='todayBtn' onClick={onShowToday}>
                      {Resource.common.current?.toLocaleUpperCase()}
                    </div>
                  </div>
                }
              </div>
            </dialog>
          </>
      }
    </div>
  )
};

DatePickerV2.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isShowToday: PropTypes.bool,
  isShowYear: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default DatePickerV2;
